import {
  StyledMenu,
  StyledMenuItem,
} from "../../nodeView/common/NodeCard.styles";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../../context/hooks";
import { useOpenRelease } from "../../../components/releases/openRelease/useOpenRelease";
import { RELEASE_STATUSES } from "../../../static/constants/constants";
import { useModal } from "../../../context/modal/modal.provider";

export const ReleaseMenu = ({ release, r, toggleMenu, menuState, onClose }) => {
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();
  const { openRelease } = useOpenRelease();
  const { openModal, MODALS } = useModal();

  return (
    <StyledMenu
      transition
      {...menuState}
      anchorRef={r}
      onClose={() => {
        if (onClose) onClose();
        toggleMenu(false);
      }}
    >
      {isAdmin() ? (
        release.status === RELEASE_STATUSES.CLOSED ? (
          <StyledMenuItem
            onClick={() => openRelease(() => release.id)}
            data-test="close-release"
          >
            <p>{t("releases.openRelease.title")}</p>
          </StyledMenuItem>
        ) : (
          <StyledMenuItem
            onClick={() =>
              openModal(MODALS.CLOSE_RELEASE, { release: release })
            }
            data-test="close-release"
          >
            <p>{t("releases.closeRelease.title")}</p>
          </StyledMenuItem>
        )
      ) : null}
      {release.status !== RELEASE_STATUSES.CLOSED ? (
        <StyledMenuItem
          onClick={() => openModal(MODALS.EDIT_RELEASE, { release: release })}
          data-test="edit-release"
        >
          <p>{t("releases.editModal.title")}</p>
        </StyledMenuItem>
      ) : null}
      <StyledMenuItem
        onClick={() =>
          openModal(MODALS.DUPLICATE_RELEASE, { release: release })
        }
        data-test="duplicate-release"
      >
        <p>{t("releases.duplicate.title")}</p>
      </StyledMenuItem>
      {isAdmin() ? (
        <StyledMenuItem
          $red
          onClick={() => openModal(MODALS.DELETE_RELEASE, { release: release })}
          data-test="delete-release"
        >
          <p>{t("releases.deleteModal.title")}</p>
        </StyledMenuItem>
      ) : null}
    </StyledMenu>
  );
};
