import { useEffect } from "react";
import { useJiraBugs } from "../../hooks/jiraBugs/useJiraBugs";
import { BugsContainer } from "./styles";
import { Bug } from "../../../runReportView/components/bug";
import { Loading } from "../../../../components";

export const JiraBugs = ({ run }) => {
  const { jiraBugs, getJiraBugs, loading } = useJiraBugs();

  useEffect(() => {
    if (run?.id) getJiraBugs(run.id);
  }, [run.id]);

  if (loading) return <Loading />;

  return (
    <BugsContainer>
      {jiraBugs?.bug_list?.map(bug => (
        <Bug
          key={`bug-${bug.issue_key}`}
          large={true}
          base_url={jiraBugs.base_url}
          {...bug}
        />
      ))}
    </BugsContainer>
  );
};
