import { useTranslation } from "react-i18next";
import { useApp } from "../../../../context/app/app.provider";
import { runPropertiesRank } from "../../../../static/constants/constants";
import { TestTypes } from "../../../nodeView/nodeCardContainer/nodeCardTestEvolve/testTypes/testTypes";
import { StickerContainer } from "../latestRunCard/latestRunCard.styles";
import { Container } from "./runProperties.styles";

export const sortProperties = p => {
  const sortedEntries = Object.entries(p)?.sort(
    (a, b) => runPropertiesRank.indexOf(a[0]) - runPropertiesRank.indexOf(b[0])
  );
  return Object.fromEntries(sortedEntries);
};

export const RunPropertiesWidget = ({ runProperties }) => {
  const { isMobile } = useApp();
  const { t } = useTranslation();

  return (
    <Container>
      <h1>{t("runView.runProperties")}</h1>
      <StickerContainer isMobile={isMobile}>
        {runProperties &&
          Object.keys(sortProperties(runProperties))
            ?.filter(key => key !== "path" && key !== "tags" && key !== "label")
            ?.map(key =>
              runProperties[key]?.length ? (
                <TestTypes
                  key={`testTypes-${runProperties[key]}`}
                  data-test={`sticker-${runProperties[key]}`}
                  runProperties
                  heading
                  data={{ [key]: runProperties[key] }}
                />
              ) : null
            )}
        {runProperties &&
          Object.keys(sortProperties(runProperties))
            ?.filter(key => key === "path" || key === "tags")
            ?.map(key =>
              runProperties[key]?.length ? (
                <TestTypes
                  key={`double-testTypes-${runProperties[key]}`}
                  data-test={`sticker-${runProperties[key]}`}
                  runProperties
                  double
                  heading
                  data={{ [key]: runProperties[key] }}
                />
              ) : null
            )}
      </StickerContainer>
    </Container>
  );
};
