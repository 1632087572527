import { useTranslation } from "react-i18next";
import {
  CardTab,
  CardTabContainer,
  CardTitle,
} from "../../testEvolve/testEvolveRunView.styles";

export const CardTabs = ({ activeTabState, tabs }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = activeTabState;

  return (
    <CardTabContainer>
      {tabs?.map(tab => (
        <CardTab key={`${tab}-tab`} active={activeTab === tab}>
          <CardTitle
            key={`${tab}-title`}
            active={activeTab === tab}
            onClick={() => setActiveTab(tab)}
          >
            {t(`runView.tabs.${tab}`)}
          </CardTitle>
        </CardTab>
      ))}
    </CardTabContainer>
  );
};
