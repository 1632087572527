import { useTranslation } from "react-i18next";
import { Button, Modal } from "../../../../components";
import {
  CopyMessage,
  KeyContainer,
  StyledKey,
  StyledWrapper,
  SuccessMessage,
} from "./apiKeyModal.styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";

export const ApiKeyModal = ({
  closeModal,
  apiKey,
  keyId,
  getApiKeys,
  setAlias,
}) => {
  const { t } = useTranslation();
  const [textSelectedAndMessage, setTextToSelected] = useState({
    textSelected: false,
    showMessage: false,
    copied: false,
  });

  const copyToClipboard = () => {
    setTextToSelected({
      textSelected: true,
      showMessage: true,
      copied: true,
    });
  };

  return (
    <Modal
      data-test="api-key-modal"
      title={t("settings.apiKeys.createApiKey.modalTitle")}
      primary={{
        text: t("btnConfirm"),
        onClick: () => {
          setAlias("");
          closeModal();
          getApiKeys();
        },
      }}
    >
      <StyledWrapper>
        <p>{t("settings.apiKeys.createApiKey.paragraph")}</p>
        <KeyContainer>
          <StyledKey
            selected={textSelectedAndMessage.textSelected}
            data-test="api-key"
          >
            {keyId}:{apiKey}
          </StyledKey>
        </KeyContainer>
        <CopyToClipboard text={`${keyId}:${apiKey}`} onCopy={copyToClipboard}>
          <CopyMessage selected={textSelectedAndMessage.textSelected}>
            <Button data-test="copy-btn" accent>
              {t("messaging.clickToCopy")}
            </Button>
          </CopyMessage>
        </CopyToClipboard>
        {textSelectedAndMessage.showMessage && (
          <SuccessMessage>
            {t("settings.apiKeys.createApiKey.copyKeySuccess")}
          </SuccessMessage>
        )}
      </StyledWrapper>
    </Modal>
  );
};
