import styled, { css } from "styled-components";
import { ReactComponent as Icon } from "../../../static/assets/icon-cross.svg";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  overflow-y: hidden;
  width: ${({ theme, left }) => (!theme.isXL ? "100%" : left ? "70%" : "30%")};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  height: ${({ theme }) =>
    theme.isNotDesktop ? "" : theme.sizing.sectionHeight};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};

  width: 100%;
  min-height: 100%;

  ${({ isShared }) =>
    isShared &&
    css`
      height: 100%;
      min-height: 100%;
    `};
`;

export const SessionsHeader = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isDesktop ? "row" : "column")};
  gap: ${({ theme }) => theme.spacing.paddingSM};
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  height: ${({ theme }) =>
    theme.isNotDesktop ? "" : theme.sizing.runDetailMinHeight};
  min-height: ${({ theme }) =>
    theme.isNotDesktop ? "" : theme.sizing.runDetailMinHeight};

  h1 {
    opacity: ${({ closed }) => (closed ? "0.83" : "1")};
  }
`;

export const SessionRow = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.padding};
  outline: ${({ theme, isSelected }) =>
    isSelected ? `1px solid ${theme.colours.haloAccent}` : "none"};
  border: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.altForeground}` : "none"};
  gap: ${({ theme }) => theme.spacing.paddingSM};
  &:hover {
    outline: ${({ theme }) => `1px solid ${theme.colours.haloAccent}`};
  }

  opacity: ${({ closed }) => (closed ? "0.83" : "1")};
`;

export const RunDetailsContainer = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.padding};
  flex-wrap: ${({ theme }) => (theme.isXL ? "nowrap" : "wrap")};
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  height: ${({ h }) => h || "fit-content"};
  max-height: ${({ h }) => `${h}px` || "fit-content"};
  overflow: ;
`;

export const RunDetail = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${({ desc }) => (desc ? 1 : 0)};
  flex-shrink: ${({ desc, reference }) => (reference || desc ? 1 : 0)};
  gap: ${({ theme }) => theme.spacing.paddingXSM};

  z-index: 99;
  min-height: ${({ theme }) => theme.sizing.runDetailMinHeight};
  height: fit-content;

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  border: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.altForeground}` : "none"};
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};

  min-width: ${({ theme }) => theme.sizing.runDetailMinWidth};
  overflow: hidden;
  max-width: ${({ theme, reference, editMode }) =>
    editMode && reference
      ? theme.sizing.selectedRunDetailMinWidth
      : reference
      ? theme.sizing.runDetailMinWidth
      : ""};

  ${({ risk, probact }) =>
    (risk || probact) &&
    css`
      background: ${({ theme, probact, risk }) => `linear-gradient(${
        theme.colours.foreground
      }, ${theme.colours.foreground}) padding-box,
    linear-gradient(to right, ${
      (probact == 1 &&
        `${theme.gradients.passing_start}, ${theme.gradients.passing_end}`) ||
      (probact == 2 &&
        `${theme.gradients.moderate_start}, ${theme.gradients.moderate_end}`) ||
      (probact == 3 &&
        `${theme.gradients.error_start}, ${theme.gradients.error_end}`) ||
      (risk > 6 &&
        `${theme.gradients.error_start}, ${theme.gradients.error_end}`) ||
      (risk < 4 &&
        `${theme.gradients.passing_start}, ${theme.gradients.passing_end}`) ||
      `${theme.gradients.moderate_start}, ${theme.gradients.moderate_end}`
    }) border-box`};
      border: 2px solid transparent;
    `}

  ${({ editMode, editHeight }) =>
    editHeight &&
    editMode &&
    css`
      height: ${({ theme }) => theme.sizing.runDetailEditHeight};
      min-height: ${({ theme }) => theme.sizing.runDetailEditHeight};
    `}

  textarea {
    padding: ${({ theme }) => theme.spacing.paddingSM};
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    height: 60%;
    width: 100%;
    max-width: 100%;
    min-width: 100%;

    line-height: 1.5;

    &:focus {
      outline: none !important;
      border: 2px solid ${({ theme }) => theme.colours.haloAccent};
    }
    margin-bottom: ${({ theme }) => theme.spacing.paddingSM};

    border-radius: ${({ theme }) => theme.borders.radius};

    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colours.secondaryText};
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colours.secondaryText};
    }
  }

  input {
    height: ${({ theme }) => theme.sizing.inputHeight};
    margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    padding: 0 ${({ theme }) => theme.spacing.paddingSM};
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};

    &:focus {
      outline: none !important;
      border: 2px solid ${({ theme }) => theme.colours.haloAccent};
    }
  }

  button {
    margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  }
`;

export const DetailValue = styled.p`
  font-size: ${({ theme, desc, reference }) =>
    desc || reference ? theme.fontSize.mediumLarge : theme.fontSize.large};
  text-wrap: ${({ desc }) => (desc ? "wrap" : "nowrap")};
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ summary }) =>
    summary &&
    css`
      white-space: pre-wrap;
      line-height: 1.5;
    `}
`;

export const ReferenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 1;
  width: calc(100% - ${({ theme }) => theme.sizing.iconSmall});
`;

export const DetailValueIcon = styled.div`
  color: ${({ theme }) => theme.colours.mainText};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  svg {
    color: ${({ theme }) => theme.colours.mainText};
    height: ${({ theme }) => theme.sizing.iconSmall};
    width: ${({ theme }) => theme.sizing.iconSmall};
    min-width: ${({ theme }) => theme.sizing.iconSmall};
    cursor: pointer;

    &: hover {
      color: ${({ theme }) => theme.colours.bulletColour};
    }
  }
`;

export const DetailKey = styled.p`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colours.fadedMainText};
  text-wrap: nowrap;

  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const EntryRow = styled.div`
  cursor: ${({ entry }) => (entry ? "default" : "pointer")};
  width: 100%;
  height: fit-content;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.padding};
  border: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.altForeground}` : "none"};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  outline: ${({ theme, entry }) =>
    entry ? `1px solid  ${theme.colours.haloAccent}` : "none"};

  p {
    line-height: 1.5;
    white-space: pre-wrap;
  }

  ${({ newEntry }) =>
    newEntry &&
    css`
      position: relative;
      max-height: 100%;

      #editor-container {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;

        div:first-child {
          height: 100%;
        }
      }

      #rich-text-editor {
        height: 100%;
        max-height: 100%;
        overflow: hidden;
      }

      #editor-shell {
        height: 100%;
        max-height: 100%;
        overflow: hidden;
      }

      #rte-editor {
        height: 100%;
        max-height: 100%;
        overflow: hidden;
      }

      #rte-container {
        max-height: 100%;
        height: 95%;
      }

      .ContentEditable__root {
        height: 100%;
      }
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  align-items: center;
  p {
    padding-right: ${({ theme }) => theme.spacing.paddingSM};
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  svg {
    height: ${({ theme }) => theme.sizing.iconSmall};
    width: auto;
  }
`;

export const EntryHeader = styled.div`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.bulletColour};
  border: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.bulletColour}` : "none"};

  width: fit-content;

  &: hover {
    outline: ${({ isMenu, theme }) =>
      isMenu ? `1px solid ${theme.colours.haloAccent}` : "none"};
  }

  svg {
    color: ${({ theme }) => theme.colours.bulletColour};
  }

  p {
    color: ${({ theme }) => theme.colours.bulletColour};
    text-transform: capitalize;
  }
`;

export const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  height: 100%;
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  opacity: ${({ closed }) => (closed ? "0.83" : "1")};
  z-index: 96;
`;

export const SessionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  overflow-y: auto;
`;

export const AttachmentButton = styled.button`
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  color: ${({ theme }) => theme.colours.mainText};

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  border: 1px solid ${({ theme }) => theme.colours.altForeground};
  height: ${({ theme }) => theme.sizing.btnHeight};

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  svg {
    height: ${({ theme }) => theme.sizing.iconSmall};
    width: auto;
  }
`;

export const EntryTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AttachmentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const Attachment = styled.div`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  color: ${({ theme }) => theme.colours.mainText};
  border: 1px solid ${({ theme }) => theme.colours.white};

  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  gap: ${({ theme }) => theme.spacing.paddingSM};
  height: ${({ theme }) => theme.sizing.btnHeight};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colours.bulletColour};
  }

  p {
    color: inherit;
  }

  svg {
    color: inherit;
    min-height: ${({ theme }) => theme.sizing.iconSmall};
    height: ${({ theme }) => theme.sizing.iconSmall};
    width: auto;
  }

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  img {
    max-height: ${({ theme }) => theme.sizing.imgHeight};
  }
`;

export const SessionDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  min-width: 100%;
  flex-wrap: wrap;

  div {
    flex: 1;
  }
`;

export const HorizontalRunDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.padding};
  flex-grow: ${({ desc }) => (desc ? 1 : 0)};
  flex-shrink: ${({ desc, reference }) => (reference || desc ? 1 : 0)};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  color: ${({ theme }) => theme.colours.mainText};

  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};

  overflow: hidden;
  border: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.altForeground}` : "none"};
  min-width: ${({ theme }) => theme.sizing.runDetailMinWidth};

  svg {
    color: ${({ theme }) => theme.colours.mainText};
    height: ${({ theme, profile }) => (profile ? "" : theme.sizing.svgHeight)};
    width: auto;
  }
`;

export const VerticalRunDetail = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  flex-direction: column;
  overflow: hidden;
`;

export const EntriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingXSM};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};

  border: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.altForeground}` : "none"};
  color: ${({ theme }) => theme.colours.mainText};
  min-width: ${({ theme }) => theme.sizing.entryWidth};
  flex: 0 !important;
`;

export const EditRiskForm = styled.div`
  z-index: 90;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.padding};

  height: fit-content;
  min-height: fit-content;
`;

export const RiskInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const RiskInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  width: 50%;
`;

export const NumberInput = styled.input`
  height: ${({ theme }) => theme.sizing.inputHeight};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid;
  padding-left: ${({ theme }) => theme.spacing.padding};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  background-color: ${({ theme }) => theme.colours.background};
  color: ${({ theme }) => theme.colours.mainText};
  outline: none;
  ${({ probact }) =>
    probact &&
    css`
      background: ${({ theme, probact, risk }) => `linear-gradient(${
        theme.colours.foreground
      }, ${theme.colours.foreground}) padding-box,
    linear-gradient(to right, ${
      (probact == 1 &&
        `${theme.gradients.passing_start}, ${theme.gradients.passing_end}`) ||
      (probact == 2 &&
        `${theme.gradients.moderate_start}, ${theme.gradients.moderate_end}`) ||
      (probact == 3 &&
        `${theme.gradients.error_start}, ${theme.gradients.error_end}`) ||
      (risk > 6 &&
        `${theme.gradients.error_start}, ${theme.gradients.error_end}`) ||
      (risk < 4 &&
        `${theme.gradients.passing_start}, ${theme.gradients.passing_end}`) ||
      `${theme.gradients.moderate_start}, ${theme.gradients.moderate_end}`
    }) border-box`};
      border: 2px solid transparent;
    `}
`;

export const EntryDetails = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.paddingXSM}
    ${({ theme }) => theme.spacing.paddingSM};
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};

  opacity: ${({ closed }) => (closed ? "0.83" : "1")};
`;

export const InProgressImg = styled.img`
  width: ${({ theme }) => theme.sizing.svgHeight};
  height: ${({ theme }) => theme.sizing.svgHeight};
`;

export const StopwatchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    height: ${({ theme }) => theme.sizing.svgHeight};
    width: ${({ theme }) => theme.sizing.svgHeight};
  }
`;

export const CloseSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  align-items: center;
  justify-content: center;
`;

export const CrossIcon = styled(Icon)`
  width: ${({ theme }) => theme.sizing.svgHeightXSM} !important;
  height: ${({ theme }) => theme.sizing.svgHeightXSM} !important;
  color: ${({ theme }) => theme.colours.error} !important;
`;
