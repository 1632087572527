import { useEffect } from "react";
import { usePermissions } from "../../hooks";
import { useProject } from "../../project/project.provider";
import { useOrganisationUsers } from "../organisationUsers.provider";

export const useUserProjects = () => {
  const { projects } = useProject();
  const { isUserAdmin, permissions, isPartnerAdmin } = usePermissions();
  const { userProjects, initialUserProjects, users, dispatch } =
    useOrganisationUsers();

  const isProjectUser = prj =>
    prj.roles.some(roles => roles.key === permissions.PROJECT_USER);

  const setUserProjects = payload =>
    dispatch({ type: "SET_USER_PROJECTS", payload: payload });

  const setInitialUserProjects = payload =>
    dispatch({ type: "SET_INITIAL_USER_PROJECTS", payload: payload });

  const setUpdatedUserProjects = payload =>
    dispatch({ type: "SET_UPDATED_USER_PROJECTS", payload: payload });

  const compareUserProjects = (user1, user2) => {
    const user1Projects = user1
      .map(project => project.id)
      .sort()
      .join();
    const user2Projects = user2
      .map(project => project.id)
      .sort()
      .join();
    return user1Projects === user2Projects;
  };

  const extractUserProjects = () => {
    let userProjectsObj = {};

    users?.forEach(usr => {
      userProjectsObj[usr.id] = [];

      if (isUserAdmin(usr) || isPartnerAdmin(usr))
        userProjectsObj[usr.id] = projects;
      else {
        usr.project_roles.forEach(prj => {
          if (isProjectUser(prj))
            userProjectsObj[usr.id] = [...userProjectsObj[usr.id], prj.project];
        });
      }
    });

    return userProjectsObj;
  };

  const toggleUserProject = (userId, projs) =>
    setUserProjects({ ...userProjects, [userId]: projs.map(p => p.value) });

  const checkForUpdatedProjects = () => {
    let updatedProjects = {};

    Object.entries(initialUserProjects).forEach(([key, value]) => {
      const isChange = !compareUserProjects(value, userProjects[key]);
      if (isChange) updatedProjects[key] = userProjects[key];
    });

    setUpdatedUserProjects(updatedProjects);
  };

  const extractAllUsers = () => {
    if (users && users.length) {
      const extractedProjects = extractUserProjects();
      setUserProjects(extractedProjects);
      setInitialUserProjects(extractedProjects);
    }
  };

  useEffect(extractAllUsers, [users]);

  useEffect(userProjects && initialUserProjects && checkForUpdatedProjects, [
    userProjects,
    initialUserProjects,
  ]);

  return {
    userProjects,
    toggleUserProject,
    extractUserProjects,
    findUsersProjects: user => userProjects[user.id],
  };
};
