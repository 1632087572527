import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useModal } from "../../../../../context/modal/modal.provider";

export const useDeleteSession = (sessionId, getRun, getRunDetails) => {
  const { deleteApi, status } = useFetch();
  const { closeModal } = useModal();
  const { createNotification, types } = useNotifications();

  const deleteSession = () =>
    deleteApi({
      ...ENDPOINTS.DELETE_SESSION,
      path: ENDPOINTS.DELETE_SESSION.path.replace(":sessionId", sessionId),
    });

  useEffect(() => {
    if (status.isResolved) {
      createNotification(
        types.SUCCESS,
        "runView.testevolveexploratory.deleteSessionSuccess"
      );
      getRun();
      getRunDetails();
      closeModal();
    }
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.deleteSessionError"
      );
  }, [status.isResolved, status.isRejected]);

  return {
    deleteSession,
  };
};
