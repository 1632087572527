import { Scenario } from "../scenario/scenario";
import {
  FeatureBody,
  FeatureHeader,
  StyledFeatureContainer,
} from "../testevolveRunReport.styles";

export const Feature = ({
  lighthouse,
  axe,
  feature,
  isDark,
  uuid,
  JiraBugButton,
  isMobile,
  category,
  featureIndex,
  insightsSentences,
  generatingInsights,
  insightsError,
}) => {
  if (!feature?.scenarios.length && !insightsSentences.length) return null;

  return feature && feature.scenarios && !feature.scenarios.length ? null : (
    <StyledFeatureContainer
      data-test="feature"
      key={uuid()}
      id={`#${feature.name.replace(/ /g, "")}`}
    >
      <FeatureHeader isDark={isDark}>
        <h2 data-test={feature.name} name="feature-title">
          {feature.name}
        </h2>
      </FeatureHeader>
      <FeatureBody>
        {feature.scenarios?.map((scenario, scenarioIndex) => (
          <Scenario
            axe={axe}
            scenario={scenario}
            uuid={uuid}
            key={`scenario-${scenarioIndex}`}
            isDark={isDark}
            JiraBugButton={JiraBugButton}
            feature={feature}
            isMobile={isMobile}
            category={category}
            scenarioIndex={scenarioIndex}
            featureIndex={featureIndex}
            lighthouse={lighthouse}
            featureId={feature.id}
            generatingInsights={generatingInsights}
            insightsError={insightsError}
            scenarioSentences={insightsSentences
              .filter(
                scenarioSentences =>
                  scenarioSentences.scenarioName === scenario.name
              )
              .map(sentences => sentences.scenarioSentences)}
          />
        ))}
      </FeatureBody>
    </StyledFeatureContainer>
  );
};
