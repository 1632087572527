import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useNodeView } from "../../hooks/useNodeView";
import { useProjectLabels } from "../../../../context/projectLabels/projectLabels.provider";

export const useNodeLabels = () => {
  const { nodeSummary, getNodeSummary } = useNodeView();
  const { projectLabels } = useProjectLabels();
  const { nodeId } = useParams();
  const [addSuccess, setAddSuccess] = useState(false);
  const [labels, setLabels] = useState(nodeSummary.labels);

  useEffect(() => {
    setLabels(nodeSummary.labels);
  }, [nodeSummary, nodeSummary.labels]);

  const { patch, status, body, error } = useFetch();

  const modifyLabels = payload => {
    const url = ENDPOINTS.NODE_LABELS.path.replace(":nodeId", nodeSummary.id);
    patch({ ...ENDPOINTS.NODE_LABELS, path: url }, { labels: payload });
  };

  const addNewLabel = label => {
    modifyLabels([...labels, ...label.map(e => e.value)]);
  };

  const removeLabel = label => modifyLabels(labels.filter(l => l !== label));

  useEffect(() => {
    if (status.isResolved && body) {
      setAddSuccess(true);
      setLabels(body);
      if (nodeId) getNodeSummary(nodeId);
    }
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    addNewLabel,
    removeLabel,
    modifyLabels,
    setAddSuccess,
    addSuccess,
    labels,
    nodeId: nodeSummary.id,
    projectLabels,
  };
};
