import { ACTIONS } from "../../static/constants/actions";

const INITIAL_STATE = {
  username: null,
  roles: ["LOADING"],
  isAdmin: false,
  newUserCreated: false,
  requestResetPasswordSuccess: false,
  resetPasswordSuccess: false,
  changePasswordSuccess: false,
  showSplashScreen: false,
  global_roles: [],
  project_roles: [],
  guideOpen: false,
};

export const userReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.USER:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.REMOVE_USER:
      return {
        ...INITIAL_STATE,
      };
    case ACTIONS.ROLES_SUCCESS:
      return {
        ...state,
        roles: payload.result.roles,
      };
    case ACTIONS.FIRST_LOGIN:
      return {
        ...state,
        firstLoginGuide: payload,
      };
    case ACTIONS.TOGGLE_GUIDE_OPEN:
      return {
        ...state,
        guideOpen: !state.guideOpen,
      };
    default:
      return state;
  }
};
