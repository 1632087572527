import React from "react";
import { NodeCard } from "../../common/NodeCard";
import { useNodeView } from "../../hooks/useNodeView";
import { Gauge } from "../../../../components/graph/graph";
import {
  GraphContainer,
  SummariesContainer,
  Summary,
} from "./nodeCardPlaywright.styles";
import { useLatestRun } from "../../../runView/playwright/latestRunCard/hooks/useLatestRun";
import { useTranslation } from "react-i18next";

export const NodeCardPlaywright = ({
  run,
  getLabels,
  onRelease,
  frozen,
  isShared,
}) => {
  const { t } = useTranslation();
  const { nodeId } = useNodeView();
  const { data, status, runStatus, startTime, runProps } = useLatestRun(run);

  return (
    <NodeCard
      data-test="playwright-card"
      type={run.category.replace(".", "")}
      nodeId={nodeId}
      run={run}
      status={status}
      runStatus={runStatus}
      startTime={startTime}
      label={run.label}
      getLabels={getLabels}
      runProps={runProps}
      onRelease={onRelease}
      frozen={frozen}
      isShared={isShared}
      noRunProperties={true}
    >
      <GraphContainer data-test="playwright-card-graph">
        <Gauge
          data={data.filter(d => d.value !== 0)}
          passed={run.passed_percentage}
        />
      </GraphContainer>
      <SummariesContainer>
        <Summary>
          <h1 data-test="playwright-test-suites-title">
            {run.test_suite_total}
          </h1>
          <h2 data-test="playwright-test-suites-val">
            {t("runCard.playwright.testSuites")}
          </h2>
        </Summary>
        <Summary>
          <h1 data-test="playwright-tests-title">{run.tests}</h1>
          <h2 data-test="playwright-tests-val">
            {t("runCard.playwright.tests")}
          </h2>
        </Summary>
      </SummariesContainer>
    </NodeCard>
  );
};
