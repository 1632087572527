import { useEffect } from "react";
import { usePermissions } from "../../hooks";
import { findObjectDifference } from "../utils";
import { useOrganisationUsers } from "../organisationUsers.provider";

export const usePartnerAdmins = () => {
  const { isPartnerUser, isPartnerAdmin } = usePermissions();
  const { partnerUsers, initialPartnerUsers, users, dispatch } =
    useOrganisationUsers();

  const setPartnerAdmins = payload =>
    dispatch({ type: "SET_PARTNER_USERS", payload: payload });

  const setInitialPartnerUsers = payload =>
    dispatch({ type: "SET_INITIAL_PARTNER_USERS", payload: payload });

  const setInitialPartnerAdmins = payload =>
    dispatch({ type: "SET_INITIAL_PARTNER_ADMINS", payload: payload });

  const setUpdatedPartnerAdminUsers = payload =>
    dispatch({ type: "SET_UPDATED_PARTNER_USERS", payload: payload });

  const extractIsPartnerUser = (users, user) => ({
    ...users,
    [user.id]: isPartnerUser(user) || isPartnerAdmin(user),
  });

  const extractIsPartnerAdmin = (users, user) => ({
    ...users,
    [user.id]: isPartnerAdmin(user),
  });

  const extractPartnerUsers = () => {
    const extractedUsers = users?.reduce(extractIsPartnerUser, {});
    setPartnerAdmins(extractedUsers);
  };

  const extractInitialPartnerUsers = () => {
    const extractedUsers = users?.reduce(extractIsPartnerUser, {});
    setInitialPartnerUsers(extractedUsers);
  };

  const extractInitialPartnerAdmins = () => {
    const extractedUsers = users?.reduce(extractIsPartnerAdmin, {});
    setInitialPartnerAdmins(extractedUsers);
  };

  const togglePartnerUser = usr => {
    const updatedUsers = { ...partnerUsers, [usr.id]: !partnerUsers[usr.id] };
    setPartnerAdmins(updatedUsers);
  };

  const checkForUpdatedUsers = () => {
    if (users && users.length && isPartnerAdmin()) {
      const updatedUsers = findObjectDifference(
        initialPartnerUsers,
        partnerUsers
      );
      setUpdatedPartnerAdminUsers(updatedUsers);
    }
  };

  const extractAllUsers = () => {
    if (users && users.length && isPartnerAdmin()) {
      extractPartnerUsers();
      extractInitialPartnerUsers();
      extractInitialPartnerAdmins();
    }
  };

  useEffect(extractAllUsers, [users]);

  useEffect(checkForUpdatedUsers, [partnerUsers, initialPartnerUsers]);

  return {
    partnerUsers,
    togglePartnerUser,
    extractPartnerUsers,
    isUserPartnerAdmin: user => partnerUsers[user.id],
  };
};
