import React from "react";
import "./utils/i18n/i18n";
import { Router } from "./router";
import { StoreProvider } from "./context";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { NotificationContainer } from "./context/hooks/useNotifications/useNotifications";

const App = () => (
  <StoreProvider>
    <BrowserRouter>
      <NotificationContainer />
      <Router />
    </BrowserRouter>
  </StoreProvider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

reportWebVitals();
