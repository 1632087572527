import styled from "styled-components";
import { HaloLogo } from "../HaloLogo/HaloLogo";
import { ReactComponent as Profile } from "../../static/assets/icon-user-profile.svg";
import { ReactComponent as Menu } from "../../static/assets/icon-burger-menu.svg";
import { ReactComponent as Cross } from "../../static/assets/icon-cross.svg";

export const CrossIcon = styled(Cross)`
  width: ${({ theme }) => theme.sizing.svgHeight};
  color: ${({ theme }) => theme.colours.mainText};
`;

export const StyledHeader = styled.div`
  display: flex;
  height: ${({ theme }) => theme.sizing.headerHeight};
  min-height: ${({ theme }) => theme.sizing.headerHeight};
  width: 100%;
  z-index: 100;
  background-color: ${({ theme }) => theme.colours.header};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px ${({ theme }) => theme.borders.headerBorder} solid;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ isNotDesktop, theme }) =>
      isNotDesktop ? theme.spacing.paddingMD : theme.sizing.btnHeight};
`;

export const Logo = styled(HaloLogo)`
  height: ${({ $ismobile, theme }) =>
    $ismobile ? theme.sizing.haloLogoMobile : theme.sizing.haloLogo};
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  margin-right: -${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  z-index: 99;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colours.background : "none"};

  &:active {
    background-color: ${({ theme }) => theme.colours.background};
  }

  h3 {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.padding};
`;
export const Username = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const ProfileIcon = styled(Profile)`
  height: ${({ theme }) => theme.sizing.profileIconHeight};
  width: ${({ theme }) => theme.sizing.profileIconHeight};
  min-width: ${({ theme }) => theme.sizing.profileIconHeight};
`;

export const MenuIcon = styled(Menu)`
  width: ${({ theme }) => theme.sizing.svgHeight};
`;
