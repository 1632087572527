import styled from "styled-components";
import { PieChart } from "react-minimal-pie-chart";
import { ResponsiveContainer } from "recharts";
import { theme } from "../../theme/theme";

export const Container = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.graph};
  height: ${({ height, theme }) =>
    height ? height : theme.sizing.graphContainerL};
`;

export const LighthouseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ theme, large }) =>
    (large && theme.sizing.graphLG) || theme.sizing.tabLegth};
  width: ${({ theme, large }) =>
    (large && theme.sizing.graphLG) || theme.sizing.tabLegth};
  padding-top: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};

  p {
    width: 100%;
    margin-right: 0;
  }
`;

export const VisualContainer = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.graph};
  height: 80%;

  position: relative;
  top: -20px;
`;

export const LighthouseLabel = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  line-height: ${({ theme }) => theme.spacing.graph};
  margin-top: -${({ theme }) => theme.spacing.paddingXSM};
`;

export const LighthouseValue = styled.p`
  text-align: center;
  position: relative;
  top: -${({ theme, large }) => (large ? theme.sizing.graphLabelLG : theme.spacing.paddingXXL)};
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  font-size: ${({ large, theme }) =>
    large ? theme.fontSize.graphValue : theme.spacing.paddingLG};
  line-height: ${({ theme }) => theme.spacing.paddingLG};
`;

export const StyledPieChart = styled(PieChart)`
  position: relative;
  height: 100%;
  width: 100%;
  top: ${({ theme }) => theme.spacing.paddingMD};
  display: flex;
  justify-content: center;

  svg {
    height: 100%;
  }
`;

export const StyledPieChartLighthouse = styled(PieChart)`
  position: relative;
  height: ${({ theme, large }) =>
    (large && theme.sizing.graphLG) || theme.sizing.graphContainerXSM};
  width: ${({ theme, large }) =>
    (large && theme.sizing.graphLG) || theme.sizing.graphContainerXSM};
  display: flex;
  justify-content: center;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const StyledPieChartVisual = styled(PieChart)`
  position: relative;
  height: 100%;
  width: 100%;
  top: ${({ theme }) => theme.spacing.paddingLG};
  display: flex;
  justify-content: center;

  svg {
    height: 100%;
  }
`;

export const StyledLighthouseLabel = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  line-height: ${({ theme }) => theme.spacing.graph};
  margin-top: -${({ theme, large }) => (large ? theme.spacing.paddingMD : theme.spacing.padding)};
  font-size: ${({ large }) => large && theme.fontSize.larger};
  width: 100%;
`;

export const StyledLabel = styled.p`
  text-align: center;
  position: relative;
  top: ${({ top, theme }) => (top ? top : theme.spacing.labelTop)};
  font-size: ${({ theme }) => theme.fontSize.medium};
  white-space: nowrap;
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  line-height: ${({ theme }) => theme.spacing.graph};
`;

export const Value = styled.p`
  text-align: center;
  position: relative;
  top: -65%;
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  font-size: ${({ theme }) => theme.spacing.paddingLG};
  line-height: ${({ theme }) => theme.spacing.paddingLG};
`;

export const VisualValue = styled.p`
  text-align: center;
  position: relative;
  top: -60%;
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  font-size: ${({ theme }) => theme.fontSize.larger};
  line-height: ${({ theme }) => theme.spacing.paddingLG};
`;

export const VisualLabel = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
  white-space: nowrap;
  line-height: ${({ theme }) => theme.spacing.graph};
`;

export const GraphContainer = styled(ResponsiveContainer)`
  width: 100%;
  height: 100%;

  .recharts-cartesian-grid-horizontal {
    opacity: ${({ theme }) => theme.decoration.graphGridOpacity};
  }

  .recharts-legend-item {
    color: ${({ theme }) => theme.colours.mainText};
  }

  text {
    fill: ${({ theme }) => theme.colours.mainText};
    opacity: ${({ theme }) => theme.decoration.graphTextOpacity};
  }
`;

export const ToolTipDiv = styled.div`
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.nodeCardBackground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  padding: ${({ theme }) => theme.spacing.padding};
  border: none;
  outline: none;
`;

export const Dot = styled.div`
  height: ${({ size, theme }) => size || theme.spacing.padding};
  width: ${({ size, theme }) => size || theme.spacing.padding};
  background-color: ${({ colour, theme }) =>
    (colour === "passed" && theme.gradients.passing_end) ||
    (colour === "failed" && theme.gradients.critical_start) ||
    (colour === "moderate" && theme.gradients.moderate_end)};
  border-radius: 25%;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  margin: ${({ theme }) => theme.spacing.paddingSM};
`;

export const InsideLighthouseContainer = styled.div`
  height: ${({ theme, large }) =>
    (large && theme.sizing.graphLG) || theme.sizing.graphContainerXSM};
  width: ${({ theme, large }) =>
    (large && theme.sizing.graphLG) || theme.sizing.graphContainerXSM};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const InsideVisualContainer = styled.div`
  height: ${({ theme }) => theme.sizing.graphContainerXSM};
`;
