import React from "react";
import { StyledFeaturesList, StyledFeature } from "./styles";
import { Progress } from "./progress/Progress";
import { useParams } from "react-router";
import { routes } from "../../../../../router/routes";
import { useHistoryWithParams } from "../../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { STATUSES } from "../../../../../static/constants/constants";
import { useModal } from "../../../../../context/modal/modal.provider";

export const Features = ({ run, isShared }) => {
  const history = useHistoryWithParams();
  const { nodeId } = useParams();
  const { openModal, MODALS } = useModal();

  const reportUrl = `${routes.RUN_VIEW_REPORT.path
    .replace(":nodeId", nodeId)
    .replace(":runType", run.category)
    .replace(":label", encodeURI(encodeURIComponent(run.label)))
    .replace(":runId", run.id)}`;

  const processFeatures = () => {
    if (run.features && run.features.length) {
      const features = [
        ...run.features.map((feature, index) => {
          feature._id = index;
          return feature;
        }),
      ];

      let featuresInProgress = features.filter(feature => {
        return feature.progress_percentage > 0;
      });
      featuresInProgress = sortFeaturesByFailures(featuresInProgress);

      let featuresNotInProgress = features.filter(feature => {
        return feature.progress_percentage === 0;
      });
      featuresNotInProgress = sortFeaturesAlphabetically(featuresNotInProgress);
      return [...featuresInProgress, ...featuresNotInProgress];
    }
    return [];
  };

  const sortFeaturesAlphabetically = features =>
    features.sort((a, b) => a.name.localeCompare(b.name));

  const sortFeaturesByFailures = features => {
    return features.sort((a, b) => {
      if (a.pass_percentage === b.pass_percentage)
        return a.name.localeCompare(b.name);
      return a.pass_percentage - b.pass_percentage;
    });
  };

  return (
    <>
      <StyledFeaturesList>
        {processFeatures().map((item, index) => {
          const feature = item.feature ? item.feature : item;
          return (
            <StyledFeature
              key={index}
              onClick={() =>
                isShared
                  ? openModal(MODALS.SHARE_RUN, { run })
                  : history.pushWithHash(
                      reportUrl,
                      feature.name.replace(/ /g, "")
                    )
              }
            >
              <Progress
                label={feature.name}
                labelAlignLeft={true}
                percentageLabelValue={feature.pass_percentage}
                showPercentageLabel={true}
                showPercentageLabelLeft
                percent={feature.progress_percentage}
                largerLine={true}
                animate={true}
                animated={true}
                data={[
                  { type: STATUSES.PASSED, value: feature.pass_percentage },
                  {
                    type: STATUSES.FAILED,
                    value: 100 - feature.pass_percentage,
                  },
                ]}
                data-test="progress-feature"
                rounded
              />
            </StyledFeature>
          );
        })}
      </StyledFeaturesList>
    </>
  );
};
