import { useTheme } from "styled-components";
import {
  MenuContainer,
  OpenMenuIcon,
  StyledMenu,
  StyledMenuItem,
  StyledPositiveMenuItem,
} from "../../../views/nodeView/common/NodeCard.styles";
import { useRef, useState } from "react";
import { usePermissions } from "../../../context/hooks";
import { useApproveRun } from "../../../views/runView/hooks/approval/useApproveRun";
import { useAddLabelToRelease } from "../../releases/addLabelToRelease/useAddLabelToRelease";
import { useModal } from "../../../context/modal/modal.provider";
import { useTranslation } from "react-i18next";

export const NodeCardContextMenu = ({
  run,
  nodeId,
  getLabels,
  onRelease,
  isFrozen,
  freezeHandler,
  hideApproval,
  hideFreeze,
}) => {
  const ref = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const { colours } = useTheme();
  const { t } = useTranslation();

  const { openModal, MODALS } = useModal();
  const { openApproveModal } = useApproveRun();
  const { openAddLabelModal } = useAddLabelToRelease();
  const { hasPermission, permissions } = usePermissions();

  const openLabelModal = (e, runLabel, runCategory) => {
    e?.syntheticEvent?.stopPropagation();
    e?.syntheticEvent?.preventDefault();
    openModal(MODALS.UPDATE_LABEL, { run, runLabel, runCategory });
  };

  const openDeleteRunHistoryModal = e => {
    e?.syntheticEvent?.stopPropagation();
    e?.syntheticEvent?.preventDefault();
    openModal(MODALS.DELETE_RUN_HISTORY, { run, nodeId, getLabels });
  };

  return (
    <MenuContainer>
      <OpenMenuIcon
        ref={ref}
        onClick={e => {
          setMenuOpen(true);
          e.stopPropagation();
        }}
        fill={colours.mainText}
        data-test="openMenuIcon"
      />
      <StyledMenu
        state={isMenuOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setMenuOpen(false)}
        transition
        position="auto"
        align="start"
      >
        {!hideFreeze && onRelease && !isFrozen ? (
          <StyledPositiveMenuItem
            onClick={freezeHandler}
            data-test="freeze-run"
          >
            <p>{t("nodeView.freezeRun")}</p>
          </StyledPositiveMenuItem>
        ) : null}
        {hideApproval ? null : (
          <StyledPositiveMenuItem
            onClick={e => openApproveModal(e, run)}
            data-test="approve-run"
          >
            <p>{t("nodeView.approveRun")}</p>
          </StyledPositiveMenuItem>
        )}
        <StyledPositiveMenuItem
          onClick={e => openAddLabelModal(e, run.label)}
          data-test="add-label-release"
        >
          <p>{t("releases.addLabelToRelease.dropdownText")}</p>
        </StyledPositiveMenuItem>
        <StyledPositiveMenuItem
          onClick={e => openLabelModal(e, run.label, run.category)}
          data-test="edit-run-label"
        >
          <p>{t("nodeView.updateLabel")}</p>
        </StyledPositiveMenuItem>
        {hasPermission(permissions.DELETE_NODE) ? (
          <StyledMenuItem
            onClick={openDeleteRunHistoryModal}
            data-test="delete-run"
            $red
          >
            <p>{t("nodeView.deleteRun")}</p>
          </StyledMenuItem>
        ) : null}
      </StyledMenu>
    </MenuContainer>
  );
};
