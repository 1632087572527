import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../../../../context/hooks";
import { useFetch } from "../../../../../context/hooks/fetch/useFetch";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { DateTime } from "luxon";
import { DATE_FORMAT_YYYMMDD } from "../../../../../static/constants/constants";

export const useReports = () => {
  const [reportConfigs, setReportConfigs] = useState([]);
  const [deletedConfig, setDeletedConfig] = useState(null);

  const { t } = useTranslation();
  const { createNotification, types } = useNotifications();
  const { get, post, deleteApi, status, body, error, method } = useFetch();

  const getReportConfigs = () => get(ENDPOINTS.REPORT_CONFIGS);

  const deleteReportConfig = id => {
    const url = ENDPOINTS.REPORT_CONFIG.path.replace(":id", id);
    setDeletedConfig(id);
    deleteApi({ ...ENDPOINTS.REPORT_CONFIG, path: url });
  };

  const createReport = payload => {
    let startDate;
    if (payload.start_date) {
      startDate = DateTime.fromJSDate(payload.start_date);
    } else {
      startDate = new DateTime({});
    }

    const body = {
      name: payload.name,
      labels: payload.labels,
      frequency: payload.frequency,
      start_date: startDate.toFormat(DATE_FORMAT_YYYMMDD),
      time_zone: startDate.zoneName,
      distribution_emails: payload.distribution_emails,
    };
    post(ENDPOINTS.REPORT_CONFIGS, body);
  };

  useEffect(() => {
    if (status.isResolved && body && method === "GET") setReportConfigs(body);
    if (status.isResolved && body && method === "POST") {
      createNotification(types.SUCCESS, t("success.createReportConfig"));
      getReportConfigs(body);
    }
    if (status.isRejected && method === "POST") {
      createNotification(
        types.ERROR,
        t(`errors.reports.${error.code}.${error.text}`)
      );
      getReportConfigs(body);
    }
    if (status.isResolved && method === "DELETE") {
      createNotification(types.SUCCESS, t("success.deleteReportConfig"));
      setReportConfigs(configs => configs.filter(c => c.id !== deletedConfig));
    }
  }, [status.isRejected, status.isResolved, body, error]);

  return {
    reportConfigs,
    createReport,
    getReportConfigs,
    deleteReportConfig,
  };
};
