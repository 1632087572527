import styled from "styled-components";

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ theme }) => theme.sizing.graphContainerL};
  max-height: ${({ theme }) => theme.sizing.graphContainerL};

  justify-content: space-between;
`;

export const SummariesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.padding};

  position: relative;
  top: -${({ theme }) => theme.spacing.paddingMD};
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => theme.sizing.summaryWidth};

  h1 {
    font-size: ${({ theme }) => theme.fontSize.largester};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  h2 {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding-top: ${({ theme }) => theme.spacing.padding};
`;

export const TestTypeContainer = styled.div`
  gap: ${({ theme }) => theme.spacing.paddingSM};
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing.paddingSM};
`;
