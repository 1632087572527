import { useTranslation } from "react-i18next";
import { useTime } from "../../../../context/hooks/time/useTime";
import { useNodeView } from "../../../nodeView/hooks/useNodeView";
import {
  DATE_OPTIONS,
  PAGE,
  testEvolveAccessibilityRunHistoryHeaders,
  TEST_CATEGORIES,
  LATEST,
} from "../../../../static/constants/constants";
import {
  Container,
  StyledTable,
  StyledRow,
  ViolationsContainer,
  TableContainer,
} from "./runHistory.styles";
import { Violations } from "../../../nodeView/nodeCardContainer/nodeCardTestEvolveAxe/violations/violations";
import {
  FlexTd,
  RunPropertyContainer,
  TableFooter,
} from "../../testEvolve/runHistory/runHistory.styles";
import { useRunHistoryPagination } from "../../hooks/useRunHistoryPagination";
import { useApp } from "../../../../context/app/app.provider";
import { useQueryParams } from "../../../../context/hooks";
import { routes } from "../../../../router/routes";
import { TestTypes } from "../../../nodeView/nodeCardContainer/nodeCardTestEvolve/testTypes/testTypes";
import { sortProperties } from "../../testEvolve/runProperties/runProperties";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { usePermissions } from "../../../../context/hooks";
import { approvalUtils } from "../../hooks/approval/approvalUtils";
import { ApprovedContainerRunHistory } from "../../../../components/modal/modals/runApproveModal/approval.styles";
import { FreezeIcon } from "../../runDetails/runDetails.styles";
import { RunHistoryContextMenu } from "../../../../components/menus/runHistoryContextMenu/runHistoryContextMenu";
import { useParams } from "react-router-dom";

export const RunHistory = ({ runDetails, getRunDetails }) => {
  const history = useHistoryWithParams();
  const { t } = useTranslation();
  const { nodeId } = useNodeView();
  const { getReadableTimeDifference } = useTime();
  const { RunHistoryPaginationComponent } = useRunHistoryPagination(
    runDetails,
    getRunDetails
  );
  const { isNotDesktop } = useApp();
  const { getParam } = useQueryParams();
  const { isAdmin } = usePermissions();
  const { approvalIcon, formatApproved, getApprovedStatus } = approvalUtils();
  const { runId } = useParams();
  const handleRowClick = (e, run) => {
    const { cellIndex, textContent } = e.target;
    const isDeleteButton = textContent === t("runHistory.deleteRun");
    const isApproveButton = textContent === t("runHistory.approveRun");

    if (
      isApproveButton ||
      isDeleteButton ||
      cellIndex === e.currentTarget.cells.length - 1
    ) {
      return;
    }

    history.push(
      `${routes.RUN_VIEW.path}?page=${getParam(PAGE)}`
        .replace(":nodeId", nodeId)
        .replace(":runType", TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE)
        .replace(":label", encodeURI(encodeURIComponent(run.label)))
        .replace(":runId", run.id)
    );
  };

  return (
    <>
      <Container>
        <h1>{t("runHistory.title")}</h1>
        <TableContainer isNotDesktop={isNotDesktop}>
          <StyledTable data-test="run-history-table">
            <thead>
              <tr>
                {testEvolveAccessibilityRunHistoryHeaders.map(header => (
                  <th key={`th-${header}`}>{t(`runHistory.${header}`)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {runDetails?.run_history?.map((run, i) => {
                const date = new Date(
                  Date.parse(run.finish_time ?? run.updated_time)
                );
                return (
                  <StyledRow
                    key={`row-${run.id}`}
                    onClick={e => handleRowClick(e, run)}
                    isSelected={
                      run.id.toString() === runId.toString() ||
                      (runId === LATEST && i === 0 && getParam(PAGE) === "1")
                    }
                  >
                    <td>
                      <FlexTd data-test={`${run.id}-run-id`}>
                        {run.id}
                        {run?.releases?.length ? <FreezeIcon /> : null}
                      </FlexTd>
                    </td>
                    <td data-test={`${run.id}-approved`}>
                      {run.approved ? (
                        <ApprovedContainerRunHistory
                          title={formatApproved(run.approved)}
                        >
                          {approvalIcon(run.approved)}
                          {getApprovedStatus(run.approved)}
                        </ApprovedContainerRunHistory>
                      ) : null}
                    </td>
                    <td data-test={`${run.id}-checksperformed`}>
                      {run.axe_summary.checksPerformed}
                    </td>
                    <td data-test={`${run.id}-total`}>
                      {run.axe_summary.critical +
                        run.axe_summary.serious +
                        run.axe_summary.moderate +
                        run.axe_summary.minor}
                    </td>
                    <ViolationsContainer data-test={`${run.id}-violations`}>
                      <Violations data={run.axe_summary} />
                    </ViolationsContainer>
                    <td data-test={`${run.id}-run-properties`}>
                      <RunPropertyContainer>
                        {Object.keys(sortProperties(run.run_properties)).map(
                          key => (
                            <TestTypes
                              key={`${run.id}-run-properties-${key}`}
                              data-test={`${run.id}-run-properties`}
                              runTable
                              data={{ [key]: run.run_properties[key] }}
                            />
                          )
                        )}
                      </RunPropertyContainer>
                    </td>
                    <td data-test={`${run.id}-time-ago`}>
                      {getReadableTimeDifference(
                        run.finish_time ?? run.updated_time
                      )}{" "}
                      {t("time.ago")}
                    </td>
                    <td data-test={`${run.id}-last-updated`}>
                      {date.toLocaleString("en-GB", DATE_OPTIONS)}
                    </td>
                    {isAdmin() ? (
                      <td data-test={`${run.id}-options`}>
                        <RunHistoryContextMenu key={run.id} run={run} />
                      </td>
                    ) : null}
                  </StyledRow>
                );
              })}
            </tbody>
          </StyledTable>
        </TableContainer>
        <TableFooter>
          <RunHistoryPaginationComponent />
        </TableFooter>
      </Container>
    </>
  );
};
