import styled from "styled-components/";
import { mediaQuery } from "../../../../utils/theme/mediaQuery";
import { css } from "styled-components";

export const StyledCardWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing.padding}`};
  border-top-left-radius: 0;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const StyledCard = styled.div`
  padding: 0;
`;

export const NoDataMessage = styled.div`
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.paddingLG};
`;

export const StyledHeaderTableContainer = styled.div`
  display: inline-flex;
`;

export const StyledTableContainer = styled.div`
  display: inline-flex;
`;

export const StyledHeaderRowContainer = styled.div`
  display: inline-flex;
  ${mediaQuery.lessThan("MD")`display: none`};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  div {
    &:nth-child(1) {
      width: 22%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }

    &:nth-child(2) {
      width: 13%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }

    &:nth-child(3) {
      width: 15%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }

    &:nth-child(4) {
      width: 30%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }

    &:nth-child(5) {
      width: 20%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }
  }
`;

export const StyledRowContainer = styled.div`
  display: inline-flex;
  ${mediaQuery.lessThan("MD")`display: grid`};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};

  div {
    border-radius: ${({ theme }) => theme.borders.radius};
    &:nth-child(1) {
      width: 22%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }

    &:nth-child(2) {
      width: 13%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }

    &:nth-child(3) {
      width: 15%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }

    &:nth-child(4) {
      width: 30%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }

    &:nth-child(5) {
      width: 20%;
      ${mediaQuery.lessThan("MD")`width: 100%`};
    }
  }
`;

export const StyledAuditTable = styled.div`
  ${({ theme }) => css`
    border-collapse: collapse;
    ${mediaQuery.lessThan("MD")`display: inline-flex`};
    ${mediaQuery.greaterThan("MD")`text-align: -webkit-center`};

    thead {
      margin-bottom: 2rem;

      tr {
        text-align: left;
        line-height: 3rem;
        border-bottom: 1px solid ${theme.colours.foreground_med};
      }

      th {
        padding: 0 1rem;
        font-weight: ${theme.fontWeight.regular};
      }
    }

    tbody {
      tr {
        line-height: 2rem;
        width: max-content;
        border-radius: ${({ theme }) => theme.borders.radius};
      }
      tr:nth-child(even) {
        background-color: ${theme.colours.highlight};
      }
      td {
        padding: 0 1rem;
      }
    }

    tbody:before {
      content: "";
      display: block;
      height: 1rem;
    }
  `}
`;

export const StyledFieldContainer = styled.div`
  ${mediaQuery.greaterThan("MD")`display: none`};
  font-weight: bold;
`;

export const StyledTable = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border-collapse: collapse;
    color: ${theme.colours.mainText};

    thead {
      margin-bottom: 2rem;

      tr {
        text-align: left;
        line-height: 3rem;
        border-bottom: 1px solid ${theme.colours.mainText};
        border-radius: ${({ theme }) => theme.borders.radius};
      }

      th {
        padding: 0 1rem;
        font-weight: ${theme.fontWeight.regular};
      }
    }

    tbody {
      tr {
        border-radius: ${({ theme }) => theme.borders.radius};
        line-height: 2rem;
        :hover {
          background-color: ${({ theme }) => theme.colours.highlight};
        }
      }
      tr:nth-child(even) {
        border-radius: ${({ theme }) => theme.borders.radius};
        background-color: ${theme.colours.background};
        :hover {
          background-color: ${({ theme }) => theme.colours.highlight};
        }
      }
      td {
        padding: 0 1rem;
      }
    }

    tbody:before {
      content: "";
      display: block;
      height: 1rem;
    }
  `}
`;
