import { useEffect } from "react";
import { useFetch } from "../../../../context/hooks";
import { useRunSharingToken } from "./useRunSharingToken";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { CATEGORY_PROVIDER_MAP } from "../../../../static/constants/constants";

export const useQueryToken = () => {
  const { post, body, status } = useFetch();
  const { setRunSharingToken } = useRunSharingToken();

  const queryToken = (label, category) =>
    post(
      {
        ...ENDPOINTS.QUERY_RUN_SHARING_TOKEN,
        path: ENDPOINTS.QUERY_RUN_SHARING_TOKEN.path.replace(
          ":provider",
          CATEGORY_PROVIDER_MAP[category]
        ),
      },
      { label, category }
    );

  useEffect(() => {
    if (status.isResolved && body) setRunSharingToken(body);
  }, [status.isResolved, body]);

  return {
    queryToken,
  };
};
