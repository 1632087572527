import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";
import { useUpdate } from "../../../../../../context/billingUpdate/billingUpdate.provider";
import { useNotifications } from "../../../../../../context/hooks/useNotifications/useNotifications";
import { usePaymentIntent } from "../../../../../../context/admin/paymentIntent/paymentIntent.provider";
import { useModal } from "../../../../../../context/modal/modal.provider";

export const usePaymentDetails = () => {
  const [details, setDetails] = useState({});

  const { t } = useTranslation();
  const { get, deleteApi, patch, status, body, method } = useFetch();

  const { setBillingUpdate } = useUpdate();
  const { setPaymentIntentSetupNull } = usePaymentIntent();
  const { createNotification, types } = useNotifications();
  const { closeModal } = useModal();

  const getPaymentDetails = () => get(ENDPOINTS.PAYMENT_METHOD);

  const patchPaymentMethod = id =>
    patch(ENDPOINTS.PAYMENT_METHOD, { payment_method_id: id });

  const deletePaymentMethod = id =>
    deleteApi(ENDPOINTS.PAYMENT_METHOD, { payment_method_id: id });

  useEffect(() => {
    if (status.isResolved && method === "GET") {
      setDetails(body);
      setPaymentIntentSetupNull();
    }
    if (status.isResolved && method === "PATCH") {
      setPaymentIntentSetupNull();
      createNotification(types.SUCCESS, t("paymentUpdate.successMessage"));
      setBillingUpdate();
      closeModal();
    }
    if (status.isResolved && method === "DELETE") {
      createNotification(types.SUCCESS, t("paymentUpdate.deleteSuccess"));
      setBillingUpdate();
      closeModal();
    }
  }, [status.isResolved, body]);

  return {
    paymentDetails: details,
    getPaymentDetails,
    patchPaymentMethod,
    deletePaymentMethod,
  };
};
