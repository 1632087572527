import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { useReleaseSharingToken } from "./useReleaseSharingToken";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

export const useDisableReleaseSharing = () => {
  const { post, body, status } = useFetch();
  const { clearReleaseSharingToken } = useReleaseSharingToken();
  const { createNotification, types } = useNotifications();

  const disableReleaseSharing = token =>
    post({
      ...ENDPOINTS.DISABLE_RELEASE_SHARING,
      path: ENDPOINTS.DISABLE_RELEASE_SHARING.path.replace(
        ":releaseSharingToken",
        token
      ),
    });

  useEffect(() => {
    if (status.isResolved) {
      clearReleaseSharingToken(null);
      createNotification(types.SUCCESS, "messaging.unsharedRelease");
    }
  }, [status.isResolved, body]);

  return {
    disableReleaseSharing,
  };
};
