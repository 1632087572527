import styled from "styled-components";

export const StyledName = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const StyledSupportText = styled.p`
  text-wrap: wrap;
  max-width: ${({ theme }) => theme.sizing.selectWidth};
  color: ${({ theme }) => theme.colours.freezeButtonText};
`;

export const StyledErrorText = styled.p`
  text-wrap: wrap;
  max-width: ${({ theme }) => theme.sizing.selectWidth};
  color: ${({ theme }) => theme.colours.error};
`;
