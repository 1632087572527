export const API_STATUS = {
  PENDING: "pending",
  IDLE: "idle",
  RESOLVED: "resolved",
  REJECTED: "rejected",
};

export const API_CONSTANTS = {
  X_VAL_TOKEN: "X-VALIDATION-TOKEN",
  X_API_VERS: "X-API-VERSION",
  SCC: "SCC",
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const COMMON_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
