import styled from "styled-components";

export const Page = styled.div`
  width: 100%;

  min-width: 100%;

  background-color: ${({ theme }) => theme.colours.background};

  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const PageContent = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};

  width: 100%;
  height: 100%;

  min-width: 100%;
  min-height: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const WhiteContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.padding};
  width: fit-content;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
`;
