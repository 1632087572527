import React from "react";
import { useTranslation } from "react-i18next";
import { Container, GhostContainer, Row } from "./projectDropdown.styles";

export const ProjectDropdown = ({ open }) => {
  const { t } = useTranslation();

  return open ? (
    <Container>
      <Row to="/projects">{t("projectView.switchProject")}</Row>
    </Container>
  ) : (
    <GhostContainer />
  );
};
