import React from "react";
import { Violations } from "./violations/violations";
import { useNodeView } from "../../hooks/useNodeView";
import { useLatestRun } from "../../../runView/testEvolveLighthouse/latestRunCard/hooks/useLatestRun";
import { NodeCard } from "../../common/NodeCard";

export const NodeCardTestEvolveLighthouse = ({
  run,
  getLabels,
  onRelease,
  frozen,
  isShared,
}) => {
  const { status, runStatus, runProps } = useLatestRun(run);
  const { nodeId } = useNodeView();

  return (
    <NodeCard
      data-test="te-lighthouse-card"
      type={run.category.replace(".", "")}
      run={run}
      status={status}
      runStatus={runStatus}
      startTime={run.start_time}
      nodeId={nodeId}
      category={run.category}
      label={run.label}
      getLabels={getLabels}
      runProps={runProps}
      frozen={frozen}
      onRelease={onRelease}
      isShared={isShared}
    >
      <Violations run={run} />
    </NodeCard>
  );
};
