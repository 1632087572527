import { Modal } from "../../..";
import { useTranslation } from "react-i18next";
import { usePaymentDetails } from "../../../../views/organisationSettings/tabs/billing/paymentDetails/hooks/usePaymentDetails";
import { TextContainer } from "../../../../views/organisationSettings/tabs/billing/paymentDetails/modals/modals.styles";

export const DeletePaymentModal = ({ closeModal, paymentId }) => {
  const { t } = useTranslation();
  const { deletePaymentMethod } = usePaymentDetails(closeModal);

  return (
    <Modal
      data-test="deleteModal"
      title={t("paymentUpdate.deletePaymentDetails")}
      toggleModal={closeModal}
      secondary={{
        text: t("paymentUpdate.cancel"),
        onClick: closeModal,
      }}
      primary={{
        text: t("paymentUpdate.confirm"),
        onClick: () => deletePaymentMethod(paymentId),
        props: { danger: true },
      }}
      size="small"
      id="modal"
    >
      <TextContainer>
        <p>{t("paymentUpdate.deleteText1")}</p>
        <p>{t("paymentUpdate.deleteText2")}</p>
        <p>{t("paymentUpdate.deleteText3")}</p>
      </TextContainer>
    </Modal>
  );
};
