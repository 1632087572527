import { useApp } from "../../../../context/app/app.provider";
import { StyledSelect } from "./projectsPicker.styles";

export const ProjectsPicker = ({
  options,
  field,
  userId,
  handleSelect,
  disabled,
  selected,
  fullWidth,
}) => {
  const { isNotDesktop } = useApp();

  let multiOption = true;
  if (field?.content === "projects") multiOption = false;

  let optionsString = options?.map(item => JSON.stringify(item));
  let selectedString = selected?.map(item => JSON.stringify(item));
  let newOptions = selectedString
    ? optionsString
        .filter(x => !selectedString.includes(x))
        .map(item => JSON.parse(item))
    : [];

  return (
    <StyledSelect
      isNotDesktop={isNotDesktop || fullWidth}
      isMulti={multiOption}
      value={selected}
      onChange={e => handleSelect(userId, e)}
      options={newOptions}
      placeholder={field?.placeholder}
      isClearable={false}
      classNamePrefix={"Select"}
      isDisabled={disabled}
      aria-label="project-select"
      aria-labelledby="project-label"
    />
  );
};
