import styled from "styled-components";
import { ReactComponent as Icon } from "../../../static/assets/icon-open-menu.svg";
import { MenuItem, Menu } from "@szhsin/react-menu";

export const OpenMenuIcon = styled(Icon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  color: ${({ active, theme }) =>
    active ? theme.colours.mainText : theme.colours.fadedMainText};

  &:hover {
    background-color: ${({ theme }) => theme.colours.foreground};
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: end;

  .szh-menu {
    background-color: ${({ theme }) => theme.colours.dropdownBG};
    width: ${({ theme }) => theme.sizing.dropdownMenuWidth};
    border: 1px ${({ theme }) => theme.borders.dropdownBorder} solid;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    padding: ${({ theme }) => theme.spacing.paddingSM};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  }

  .szh-menu__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${({ theme }) => theme.sizing.dropdownRowHeight};
    z-index: 99;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    gap: ${({ theme }) => theme.spacing.paddingSM};

    &:hover {
      background-color: ${({ theme }) => theme.colours.dropdownHighlight};
      p {
        opacity: 100%;
      }
    }
  }

  svg {
    opacity: ${({ active }) => (active ? "100%" : "60%")};
    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme, error }) =>
    error ? theme.colours.error : theme.colours.mainText};
`;

export const StyledMenu = styled(Menu)`
  .szh-menu {
    background-color: ${({ theme }) => theme.colours.dropdownBG};
    border: 1px ${({ theme }) => theme.borders.dropdownBorder} solid;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    padding: ${({ theme }) => theme.spacing.paddingSM};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
    z-index: 99;
  }

  .szh-menu__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${({ theme }) => theme.sizing.dropdownRowHeight};
    z-index: 99;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    gap: ${({ theme }) => theme.spacing.paddingSM};

    &:hover {
      background-color: ${({ theme }) => theme.colours.dropdownHighlight};
      p {
        opacity: 100%;
      }
    }
  }

  color: ${({ theme }) => theme.colours.mainText};
  z-index: 999;
  svg {
    opacity: ${({ active }) => (active ? "100%" : "60%")};
    color: ${({ theme }) => theme.colours.mainText};
  }
`;
