import React from "react";
import { NodeCard } from "../../common/NodeCard";
import { useNodeView } from "../../hooks/useNodeView";
import { Gauge } from "../../../../components/graph/graph";
import { GraphContainer } from "./nodeCardTestEvolve.styles";
import { useLatestRun } from "../../../runView/testEvolve/latestRunCard/hooks/useLatestRun";

export const NodeCardTestEvolve = ({
  run,
  getLabels,
  onRelease,
  frozen,
  isShared,
}) => {
  const { nodeId } = useNodeView();
  const { data, status, runStatus, startTime, runProps } = useLatestRun(run);

  return (
    <NodeCard
      data-test="test-evolve-card"
      type={run.category.replace(".", "")}
      nodeId={nodeId}
      run={run}
      status={status}
      runStatus={runStatus}
      startTime={startTime}
      label={run.label}
      getLabels={getLabels}
      runProps={runProps}
      onRelease={onRelease}
      frozen={frozen}
      isShared={isShared}
    >
      <GraphContainer data-test="test-evolve-card-graph">
        <Gauge
          data={data.filter(d => d.value !== 0)}
          passed={run.passed_percentage}
        />
      </GraphContainer>
    </NodeCard>
  );
};
