import styled, { css } from "styled-components";
import { ReactComponent as ApprovedIcon } from "../../../../static/assets/icon-approved.svg";
import { ReactComponent as AutoApprovedIcon } from "../../../../static/assets/icon-auto-approved.svg";
import { ReactComponent as ReviewedIcon } from "../../../../static/assets/icon-reviewed.svg";
import { Btn } from "../../../Button/Button";

export const Approved = styled(ApprovedIcon)`
  height: ${({ theme }) => theme.sizing.svgHeight};
  width: ${({ theme }) => theme.sizing.svgHeight};
`;

export const AutoApproved = styled(AutoApprovedIcon)`
  height: ${({ theme }) => theme.sizing.svgHeight};
  width: ${({ theme }) => theme.sizing.svgHeight};
`;

export const Reviewed = styled(ReviewedIcon)`
  height: ${({ theme }) => theme.sizing.svgHeight};
  width: ${({ theme }) => theme.sizing.svgHeight};
`;

export const ApprovedLarger = styled(ApprovedIcon)`
  height: ${({ theme }) => theme.sizing.svgHeightLG};
  width: ${({ theme }) => theme.sizing.svgHeightLG};
`;

export const AutoApprovedLarger = styled(AutoApprovedIcon)`
  height: ${({ theme }) => theme.sizing.svgHeightLG};
  width: ${({ theme }) => theme.sizing.svgHeightLG};
`;

export const ReviewedLarger = styled(ReviewedIcon)`
  height: ${({ theme }) => theme.sizing.svgHeightLG};
  width: ${({ theme }) => theme.sizing.svgHeightLG};
`;

export const StyledMessage = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  padding: ${({ theme }) => theme.spacing.paddingLG};
  padding-top: ${({ theme }) => theme.spacing.paddingSM};
  gap: ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.mainText};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  background-color: ${({ theme }) => theme.colours.foreground};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  width: ${({ theme }) => theme.sizing.approvalMessageWidth};
  height: ${({ theme }) => theme.sizing.approvalMessageHeight};
  overflow: visible;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 1.5em;
`;

export const StyledMessageNoBorder = styled.div`
  position: relative;
  display: flex;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: ${({ theme }) => theme.sizing.approvalMessageWidth};
  overflow: visible;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: 1.5em;
`;

export const ApprovedContainer = styled(Btn)`
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
  color: ${({ theme }) => theme.colours.mainText};
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;
  width: fit-content;
  padding: 0 ${({ theme }) => theme.spacing.padding};

  &:hover {
    transition: transform 0.2s;
    transform: scale(1.1);
  }

  ${({ desktop }) =>
    desktop
      ? css`
          margin-right: auto;
          margin-left: ${({ theme }) => theme.spacing.paddingMD};
        `
      : css`
          margin-bottom: ${({ theme }) => theme.spacing.paddingMD};
        `}
`;

export const ApprovedContainerRunHistory = styled.div`
  cursor: pointer;
  padding: ${({ theme }) =>
    `${theme.spacing.paddingSM} ${theme.spacing.padding}`};
  width: fit-content;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  color: ${({ theme }) => theme.colours.mainText};
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const NodeApprovedContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
  color: ${({ theme }) => theme.colours.mainText};
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  width: fit-content;

  justify-content: center;
  align-items: center;
  display: flex;
`;

export const RadioContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
  font-size: ${({ theme }) => theme.fontSize.medium};
  display: inline-block;
`;

export const RadioGroupContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const RadioLabel = styled.label`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const HiddenRadio = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledRadio = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border: ${({ theme }) => theme.colours.haloAccent} 1.5px solid;
  background: ${({ theme }) =>
    props =>
      props.checked ? theme.colours.haloAccent : "transparent"};
  border-radius: 100%;
  transition: all 150ms;

  &:after {
    content: "";
    display: ${props => (props.checked ? "block" : "none")};
    width: 7px;
    height: 14px;
    border: solid ${({ theme }) => theme.colours.white};
    border-width: 0 3px 3px 0;
    position: absolute;
    top: calc(50% - 1px);
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const Link = styled.a`
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  cursor: pointer;
  bottom: ${({ theme }) => theme.spacing.paddingXSM};
  position: absolute;
  font-size: ${({ theme }) => theme.fontSize.smaller};
  text-decoration: ${({ theme }) => theme.decoration.underlined};

  ${({ accent, theme, bold }) => css`
    color: ${accent ? theme.colours.bulletColour : theme.colours.mainText};
    font-weight: ${bold && theme.fontWeight.bold};
    &:hover,
    &:focus {
      outline: ${({ theme }) => theme.borders.noBorder};
      color: ${accent ? theme.colours.mainText : theme.colours.haloAccent};
    }
  `}
`;

export const TextArea = styled.textarea`
  max-width: 100%;
  width: 100%;
  min-width: 100%;

  min-height: ${({ theme }) => theme.sizing.textAreaMin};
  max-height: ${({ theme }) => theme.sizing.textAreaMax};

  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.paddingSM};

  background-color: ${({ theme }) => theme.colours.background};
  color: ${({ theme }) => theme.colours.mainText};

  &:focus {
    border: 1px solid ${({ theme }) => theme.colours.haloAccent};
    outline: none;
  }
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;

  p {
    font-size: ${({ theme }) => theme.fontSize.medium};
    padding-left: ${({ theme }) => theme.spacing.paddingXSM};
  }
`;

export const MessageContainer = styled.div`
  cursor: pointer;
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.spacing.paddingSM};
  margin-left: -${({ theme }) => theme.spacing.paddingSM};

  svg {
    width: ${({ theme }) => theme.spacing.padding};
    min-width: ${({ theme }) => theme.spacing.padding};

    height: auto;
  }

  p {
    text-wrap: wrap;
    overflow-wrap: anywhere;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;

export const NotesContainer = styled.div`
  width: ${({ theme }) => theme.sizing.approvalMessageWidth};
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;
