import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.sizing.latestRunWidgetWidth};
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
  height: 50%;
  min-height: ${({ theme }) => theme.sizing.latestRunWidgetHeight};
  background-color: ${({ theme }) => theme.colours.foreground};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const GraphContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 75%;

  .recharts-cartesian-grid-horizontal {
    opacity: ${({ theme }) => theme.decoration.graphGridOpacity};
  }

  .recharts-label {
    tspan {
      fill: ${({ theme }) => theme.colours.white};
    }
  }

  text {
    fill: ${({ theme }) => theme.colours.mainText};
    opacity: ${({ theme }) => theme.decoration.graphTextOpacity};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;

  p {
    text-align: right;
  }
`;

export const StickersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  height: 100%;
  margin: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StickerContainerLarge = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;

  text-align: left;
  justify-content: left;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  background-color: ${({ theme }) => theme.colours.runPropertyBackground};

  border-radius: ${({ theme }) => theme.borders.radius};

  padding: ${({ theme }) => theme.spacing.paddingSM};
  margin: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StickerLabel = styled.div`
  color: ${({ theme }) => theme.colours.mainText};
  font-size: 12px;
  opacity: 60%;
  margin-bottom: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const StickerValue = styled.div`
  color: ${({ theme }) => theme.colours.mainText};
  display: flex;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  align-items: center;
`;

export const StickerLargeValue = styled.div`
  color: ${({ theme }) => theme.colours.largerHeaderText};
  display: flex;
  height: 80%;
  font-size: 60px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  align-items: center;
`;

export const StickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  text-align: left;
  justify-content: left;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  background-color: ${({ theme }) => theme.colours.runPropertyBackground};

  border-radius: ${({ theme }) => theme.borders.radius};

  padding: ${({ theme }) => theme.spacing.paddingSM};
  margin: ${({ theme }) => theme.spacing.paddingSM};
`;
