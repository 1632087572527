import { Container } from "./rightWidget.styles";
import {
  LinkIcon,
  LinkIconContainer,
  TitleContainer,
} from "../testEvolveAccessiblityRunView.styles";
import { CustomLink } from "../../../../components/Link/StyledLink";

export const Principle = ({ principle }) => {
  return (
    <Container id={principle.title} data-test="principle-detail">
      <TitleContainer>
        <h2>
          {principle.ref_id}. {principle.title}
        </h2>
        <CustomLink to={principle.url} external>
          <LinkIconContainer>
            <LinkIcon />
          </LinkIconContainer>
        </CustomLink>
      </TitleContainer>
      <p>{principle.description}</p>
    </Container>
  );
};
