import React from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../router/routes";
import { useQueryParams } from "../../../../context/hooks";
import { useApp } from "../../../../context/app/app.provider";
import { useTime } from "../../../../context/hooks/time/useTime";
import { useNodeView } from "../../../nodeView/hooks/useNodeView";
import { useRunHistoryPagination } from "../../hooks/useRunHistoryPagination";
import {
  Container,
  StyledTable,
  StyledRow,
  TableFooter,
  DurationContainer,
  TableContainer,
  BannerCell,
  StyledBanner,
  FlexContainer,
  FlexTd,
} from "./runHistory.styles";
import {
  PAGE,
  DATE_OPTIONS,
  TEST_CATEGORIES,
  NO_UPGRADE_SUBS,
  LATEST,
} from "../../../../static/constants/constants";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { usePermissions } from "../../../../context/hooks";
import { approvalUtils } from "../../hooks/approval/approvalUtils";
import { ApprovedContainerRunHistory } from "../../../../components/modal/modals/runApproveModal/approval.styles";
import { useSubscription } from "../../../../context/subscription/subscription.provider";
import { FreezeIcon } from "../../runDetails/runDetails.styles";
import { RunHistoryContextMenu } from "../../../../components/menus/runHistoryContextMenu/runHistoryContextMenu";
import { useParams } from "react-router-dom";

export const RunHistory = ({ runDetails, getRunDetails }) => {
  const { t } = useTranslation();
  const history = useHistoryWithParams();
  const { nodeId } = useNodeView();
  const { getReadableTimeDifference } = useTime();
  const { RunHistoryPaginationComponent } = useRunHistoryPagination(
    runDetails,
    getRunDetails
  );
  const { isNotDesktop } = useApp();
  const { getParam } = useQueryParams();
  const { isAdmin } = usePermissions();
  const { subscription } = useSubscription();
  const { approvalIcon, formatApproved, getApprovedStatus } = approvalUtils();
  const { runId } = useParams();

  const getDurationTime = (startTime, finishTime) => {
    const dateTimeStart = DateTime.fromISO(startTime);
    const dateTimeEnd = DateTime.fromISO(finishTime);
    const durationTime = dateTimeEnd
      .diff(dateTimeStart, ["days", "hours", "minutes", "seconds"])
      .toObject();

    return (
      <DurationContainer>
        {durationTime.days ? <p>{durationTime.days}d &nbsp; </p> : ""}
        {durationTime.hours ? <p>{durationTime.hours}h &nbsp; </p> : ""}
        {durationTime.minutes ? <p>{durationTime.minutes}m &nbsp; </p> : ""}
        {durationTime.seconds ? <p>{durationTime.seconds}s &nbsp; </p> : ""}
      </DurationContainer>
    );
  };

  const handleRowClick = (e, run) => {
    const { cellIndex, textContent } = e.target;
    const isDeleteButton = textContent === t("runHistory.deleteRun");
    const isApproveButton = textContent === t("runHistory.approveRun");

    if (
      isApproveButton ||
      isDeleteButton ||
      cellIndex === e.currentTarget.cells.length - 1
    ) {
      return;
    }

    history.push(
      `${routes.RUN_VIEW.path}?page=${getParam(PAGE)}`
        .replace(":nodeId", nodeId)
        .replace(":runType", TEST_CATEGORIES.PLAYWRIGHT_FUNCTIONAL)
        .replace(":label", encodeURI(encodeURIComponent(run.label)))
        .replace(":runId", run.id)
    );
  };

  return (
    <>
      <Container>
        <h1>{t("runHistory.title")}</h1>
        <TableContainer isNotDesktop={isNotDesktop}>
          <StyledTable>
            <thead>
              <tr>
                <th key={"th-runId"}>{t("runHistory.runId")}</th>
                <th key={"th-approved"}>{t("runHistory.approved")}</th>
                <th key={"th-passPercentage"}>
                  {t("runHistory.passPercentage")}
                </th>
                <th key={"th-pass"}>{t("runHistory.pass")}</th>
                <th key={"th-fail"}>{t("runHistory.failed")}</th>
                <th key={"th-total"}>{t("runHistory.totalTests")}</th>
                <th key={"th-timeTaken"}>{t("runHistory.timeTaken")}</th>
                <th key={"th-finishTime"}>{t("runHistory.finishTime")}</th>
                <th key={"th-timeUpdated"}>{t("runHistory.timeUpdated")}</th>
                {isAdmin() ? (
                  <th key={"th-options"}>{t("runHistory.options")}</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {runDetails?.run_history?.map((run, i) => {
                const date = new Date(Date.parse(run.updated_time));

                return (
                  <>
                    <StyledRow
                      key={`row-${run.id}`}
                      onClick={e => handleRowClick(e, run)}
                      isSelected={
                        run.id.toString() === runId.toString() ||
                        (runId === LATEST && i === 0 && getParam(PAGE) === "1")
                      }
                    >
                      <td>
                        <FlexTd data-test={`${run.id}-run-id`}>
                          {run.id}
                          {run?.releases?.length ? <FreezeIcon /> : null}
                        </FlexTd>
                      </td>
                      <td data-test={`${run.id}-approved`}>
                        {run.approved ? (
                          <ApprovedContainerRunHistory
                            title={formatApproved(run.approved)}
                          >
                            {approvalIcon(run.approved)}
                            {getApprovedStatus(run.approved)}
                          </ApprovedContainerRunHistory>
                        ) : null}
                      </td>
                      <td data-test={`${run.id}-pass-percentage`}>
                        {run.passed_percentage}%
                      </td>
                      <td data-test={`${run.id}-pass`}>{run.passed}</td>
                      <td data-test={`${run.id}-fail`}>{run.failures}</td>
                      <td data-test={`${run.id}-total`}>{run.tests}</td>
                      <td data-test={`${run.id}-duration`}>
                        {getDurationTime(
                          run.start_time,
                          run.finish_time ?? run.updated_time
                        )}
                      </td>
                      <td data-test={`${run.id}-last-updated`}>
                        {getReadableTimeDifference(
                          run.finish_time ?? run.updated_time
                        )}{" "}
                        {t("time.ago")}
                      </td>
                      <td data-test={`${run.id}-date`}>
                        {date.toLocaleString("en-GB", DATE_OPTIONS)}
                      </td>
                      {isAdmin() ? (
                        <td data-test={`${run.id}-options`}>
                          <RunHistoryContextMenu key={run.id} run={run} />
                        </td>
                      ) : null}
                    </StyledRow>
                  </>
                );
              })}
              {runDetails?.display_banner ? (
                <StyledRow>
                  <BannerCell colSpan="100">
                    <FlexContainer>
                      <StyledBanner>
                        {t(
                          NO_UPGRADE_SUBS.includes(subscription.subscription_id)
                            ? "nodeView.upgradeMessage2"
                            : "nodeView.upgradeMessage",
                          {
                            months: subscription.run_retention,
                          }
                        )}
                      </StyledBanner>
                    </FlexContainer>
                  </BannerCell>
                </StyledRow>
              ) : null}
            </tbody>
          </StyledTable>
        </TableContainer>
        <TableFooter>
          <RunHistoryPaginationComponent />
        </TableFooter>
      </Container>
    </>
  );
};
