import { useTranslation } from "react-i18next";
import {
  CypressRunTrendsGraph,
  DurationGraph,
} from "../../../../components/graph/graph";
import {
  Container,
  GraphContainer,
  RunTrendsHeaderContainer,
  TrendsTitle,
} from "./runTrends.styles";
import { useState } from "react";
import {
  DURATION,
  PASS_PERCENTAGE,
} from "../../../../static/constants/constants";
import { ComparisonSelect } from "../../testEvolve/rightWidget/runInsights/runInsights.styles";
import { useRunDurations } from "../../hooks/usePlaywrightDurations";

export const RunTrends = ({ runDetails }) => {
  const { t } = useTranslation();
  const { durationsMap } = useRunDurations();
  const [toggleValue, setToggleValue] = useState(PASS_PERCENTAGE);

  return (
    <Container>
      <RunTrendsHeaderContainer>
        <TrendsTitle>{t("runView.testevolve.runTrendsTitle")}</TrendsTitle>
        <ComparisonSelect
          value={toggleValue}
          onChange={e => setToggleValue(e.target.value)}
          title="select-testevolve-title"
          aria-label="Showing Graph:"
        >
          <option value={PASS_PERCENTAGE}>
            {t("runView.runTrends.passPercentage")}
          </option>
          <option value={DURATION}>{t("runView.runTrends.duration")}</option>
        </ComparisonSelect>
      </RunTrendsHeaderContainer>
      {toggleValue === PASS_PERCENTAGE ? (
        runDetails?.run_history?.length ? (
          <GraphContainer data-test="cypress-run-trend-graph">
            <CypressRunTrendsGraph
              data-test="duration-graph"
              data={runDetails?.run_history}
            />
          </GraphContainer>
        ) : null
      ) : (
        <DurationGraph data={durationsMap(runDetails?.run_history)} />
      )}
    </Container>
  );
};
