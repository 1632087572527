import { Textfit } from "react-textfit";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../router/routes";
import { PassPercentGraph } from "../components/passPercentGraph";
import { NodeFooter } from "../../../../views/nodeView/common/nodeFooter";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import {
  Header,
  Spacer,
  LabelContainer,
  StickerContainer,
  StyledMinimalRunCard,
} from "../minimalRunCard.styles";
import {
  RunStatusValue,
  RunStatusTitle,
  RunStatusSticker,
} from "../../../../views/runView/testEvolve/latestRunCard/latestRunCard.styles";
import {
  Label,
  NodeType,
} from "../../../../views/nodeView/common/NodeCard.styles";
import { CategoryIcon } from "../../../../views/nodeView/common/categoryIcon";

export const CypressMinimalRunCard = ({ run, inProgress }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistoryWithParams();

  const runStatus = t(`runCard.testevolve.status.${run.status}`) || "N/A";

  const handleClick = () =>
    history.push(
      routes.RUN_VIEW.path
        .replace(":nodeId", "undefined")
        .replace(":runType", run.category)
        .replace(":label", encodeURI(encodeURIComponent(run.label)))
        .replace(":runId", run.id),
      { fromHome: true }
    );

  return (
    <StyledMinimalRunCard onClick={handleClick} inProgress={inProgress}>
      <Header>
        <CategoryIcon category={run?.category} />
        <Label>
          <NodeType>
            {t(`runCard.category.${run.category.replace(".", "")}`)}
          </NodeType>
        </Label>
        <Spacer />
      </Header>
      <LabelContainer
        id={`${run.id}-${run.category}${run.label}-label-container`}
      >
        <Textfit
          mode="multi"
          max={24}
          style={{
            display: "flex",
            "align-items": "center",
            fontWeight: theme.fontWeight.medium,
            minHeight: theme.sizing.minimalRunCardLabelHeight,
            maxHeight: theme.sizing.minimalRunCardLabelHeight,
            maxWidth: theme.sizing.minimalRunCardLabelWidth,
            minWidth: theme.sizing.minimalRunCardLabelWidth,
          }}
        >
          {run.label}
        </Textfit>
      </LabelContainer>
      <PassPercentGraph
        passPercentage={run.passed_percentage}
        progressPercentage={run.progress_percentage}
      />
      <StickerContainer>
        <RunStatusSticker>
          <RunStatusTitle>{t("minimalRunCard.passPercentage")}</RunStatusTitle>
          <RunStatusValue>{run.passed_percentage}%</RunStatusValue>
        </RunStatusSticker>
        <RunStatusSticker>
          <RunStatusTitle>{t("minimalRunCard.totalTests")}</RunStatusTitle>
          <RunStatusValue>{run.tests}</RunStatusValue>
        </RunStatusSticker>
      </StickerContainer>
      <NodeFooter
        startTime={run?.start_time}
        statusText={runStatus}
        status={run.status}
      />
    </StyledMinimalRunCard>
  );
};
