import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  StyledCautionIcon,
  Tags,
  StandardsWarning,
  StandardsContainer,
  ReportSummary,
  StickerContainer,
  ReportContainer,
  LoaderContainer,
} from "./testevolveRunReport.styles";
import { Feature } from "./feature/feature";
import { Loading } from "../../../components";
import { useTranslation } from "react-i18next";
import { JiraBugButton } from "./jira/JiraBugButton";
import { useRunFilter } from "../runFilter/runFilter";
import { useUuid } from "../../../context/uuid/useUuid";
import { FilterSection } from "../runFilter/filterSection";
import { useApp } from "../../../context/app/app.provider";
import { useTheme } from "../../../context/theme/theme.provider";
import { ErrorPage } from "../../../components/errorPage/errorPage";
import { RUN_STATUSES } from "../../../static/constants/constants";

const LOADER_HEIGHT = 100;

export const TestevolveRunReport = ({
  axe,
  run,
  runType,
  runId,
  pageData,
  lighthouse,
  isJiraConfigured,
  error,
  getRunWithParams,
  runFeatures,
  isFetching,
  resetRunFeatures,
  resetPageData,
  scenariosLength,
}) => {
  const { uuid } = useUuid();
  const { isDark } = useTheme();
  const { isMobile } = useApp();
  const [position, setPosition] = useState(null);
  const [isFirstLoadFilters, setIsFirstLoadFilters] = useState(true);
  const [queue, setQueue] = useState([]);
  const isInProgress = run.status === RUN_STATUSES.IN_PROGRESS;

  const featuresRef = useRef(null);
  const timerDebounceRef = useRef();
  const reportContainerRef = useRef(null);

  const { t } = useTranslation();

  const {
    setStatusFilter,
    statusFilter,
    tagsFilterOptions,
    statusFilterOptions,
    tagsFilter,
    setTagsFilter,
    attemptsFilter,
    setAttemptsFilter,
    insightsSentences,
    generatingInsights,
    insightsError,
    showFilterOptions,
    setShowFilterOptions,
  } = useRunFilter(runId);

  useEffect(() => {
    if (!isFirstLoadFilters) {
      resetRunFeatures();
      resetPageData();
      addToQueue(1);
    }
    if (isFirstLoadFilters && pageData.firstPage > 1) {
      const reportContainer = document.getElementById("report-container");
      reportContainer.scrollTop = 100;
    }
    setIsFirstLoadFilters(false);
  }, [statusFilter, tagsFilter, attemptsFilter]);

  useEffect(() => {
    if (!isFetching && queue.length) {
      const localQueue = queue;
      localQueue.shift();
      setQueue(localQueue);
    }
  }, [isFetching]);

  useEffect(() => {
    if (
      runFeatures &&
      !isFetching &&
      reportContainerRef.current &&
      featuresRef.current
    ) {
      if (
        featuresRef.current.clientHeight <
        reportContainerRef.current.clientHeight
      ) {
        if (pageData.lastPage < pageData.totalPages) {
          getNextPage();
          if (pageData.firstPage > 1) {
            setPosition({
              directionUp: false,
              reportScroll: LOADER_HEIGHT,
              featureHeight: LOADER_HEIGHT,
            });
          }
        } else {
          handleDebounce(getPreviousPage);
        }
      }
    }
  }, [runFeatures, reportContainerRef, featuresRef]);

  useEffect(() => {
    if (position && position?.featureHeight && position?.directionUp) {
      const reportContainer = document.getElementById("report-container");
      reportContainer.scrollTop =
        featuresRef.current.clientHeight - position.featureHeight;
      setPosition(null);
    }
    if (
      position &&
      position?.featureHeight &&
      !position?.directionUp &&
      position?.reportScroll
    ) {
      const reportContainer = document.getElementById("report-container");
      reportContainer.scrollTop = position.reportScroll;
      setPosition(null);
    }
  }, [reportContainerRef, featuresRef, runFeatures]);

  const handleDebounce = fn => {
    if (timerDebounceRef.current) clearTimeout(timerDebounceRef.current);
    timerDebounceRef.current = setTimeout(() => fn(), 500);
  };

  useEffect(() => {
    if (!queue.length) return;
    getRunWithParams(
      runId,
      {
        statusFilter: statusFilter,
        tags: tagsFilter,
        attempts: attemptsFilter,
      },
      queue[0]
    );
  }, [queue]);

  const addToQueue = pageNumber =>
    setQueue(e => [...new Set([...e, pageNumber])]);

  const getNextPage = () => {
    if (pageData.lastPage >= pageData.totalPages) return;

    addToQueue(pageData?.lastPage + 1);
    setPosition({
      featureHeight: featuresRef.current.clientHeight,
      reportHeight: reportContainerRef.current.clientHeight,
      reportScroll: reportContainerRef.current.scrollTop,
      directionUp: false,
    });
  };

  const getPreviousPage = () => {
    if (pageData.firstPage <= 1) return;

    addToQueue(pageData?.firstPage - 1);
    setPosition({
      featureHeight: featuresRef.current.clientHeight,
      reportHeight: reportContainerRef.current.clientHeight,
      directionUp: true,
    });
  };

  const scrollFunction = e => {
    if (e.target.scrollTop < 10 && !isFetching && pageData.firstPage > 1) {
      handleDebounce(getPreviousPage);
    }

    if (
      featuresRef.current?.clientHeight -
        e.target.clientHeight -
        e.target.scrollTop <
        20 &&
      !isFetching &&
      !queue.length &&
      pageData.lastPage < pageData.totalPages
    ) {
      handleDebounce(getNextPage);
    }
  };

  const JiraButton = ({ data, feature, index, url, scenario }) =>
    isJiraConfigured ? (
      <JiraBugButton
        category={runType}
        data={data}
        url={url}
        scenario={scenario}
        feature={feature}
        data-test={`create-jira-${feature.id}-${index}`}
      />
    ) : null;

  const FeaturesContainer = () =>
    run && runFeatures
      ? runFeatures?.map((feature, featureIndex) => (
          <Feature
            key={`feature-${featureIndex}`}
            feature={feature}
            isDark={isDark}
            uuid={uuid}
            JiraBugButton={JiraButton}
            category={runType}
            isMobile={isMobile}
            featureIndex={featureIndex}
            axe={axe}
            lighthouse={lighthouse}
            statusFilter={statusFilter}
            tagsFilter={tagsFilter}
            attemptsFilter={attemptsFilter}
            insightsSentences={insightsSentences?.filter(
              s => s.featureName === feature.name
            )}
            generatingInsights={generatingInsights}
            insightsError={insightsError}
          />
        ))
      : null;

  return error && error?.code ? (
    <ErrorPage code={error.code} />
  ) : (
    <Container>
      <FilterSection
        run={run}
        isInProgress={isInProgress}
        scenariosLength={scenariosLength}
        showDownloadButton={() => insightsSentences && insightsSentences.length}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        totalScenarioCount={pageData.totalScenarios}
        tagsFilterOptions={tagsFilterOptions}
        statusFilterOptions={statusFilterOptions}
        tagsFilter={tagsFilter}
        setTagsFilter={setTagsFilter}
        attemptsFilter={attemptsFilter}
        setAttemptsFilter={setAttemptsFilter}
        showFilterOptions={showFilterOptions}
        setShowFilterOptions={setShowFilterOptions}
      />
      <ReportContainer
        ref={reportContainerRef}
        onScroll={isInProgress ? null : scrollFunction}
        id="report-container"
      >
        <div ref={featuresRef} id="feature-container">
          {axe ? (
            <ReportSummary>
              <StandardsContainer>
                <StandardsWarning>
                  <StyledCautionIcon />
                  <p>{t("runView.testevolveaxe.followingStandards")}</p>
                </StandardsWarning>
                <Tags>
                  {run?.axe_summary?.standards &&
                    Object.keys(run?.axe_summary?.standards).map(entry => (
                      <StickerContainer key={`tag-${entry}`}>
                        {entry}
                      </StickerContainer>
                    ))}
                </Tags>
              </StandardsContainer>
            </ReportSummary>
          ) : null}
          {isInProgress || pageData?.firstPage <= 1 ? null : (
            <LoaderContainer data-test="previous-page-loader">
              <Loading />
            </LoaderContainer>
          )}
          <FeaturesContainer />
          {isInProgress || pageData?.lastPage >= pageData?.totalPages ? null : (
            <LoaderContainer data-test="next-page-loader">
              <Loading />
            </LoaderContainer>
          )}
        </div>
      </ReportContainer>
    </Container>
  );
};
