import styled from "styled-components";
import { SvgIcon } from "../../../../../components/icon/icon";

export const StickerContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing.paddingXSM};

  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  background-color: ${({ theme }) => theme.colours.runPropertyBackground};

  border-radius: ${({ theme }) => theme.borders.radius};

  padding: ${({ theme }) => theme.spacing.paddingSM};

  max-width: ${({ theme }) => theme.sizing.maxViolationStickerWidth};

  h1 {
    font-size: 16px;
    text-align: center;
    vertical-align: center;
    padding: ${({ theme }) => theme.spacing.paddingXSM};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  p {
    width: 60px;
    height: 100%;
    font-size: 12px;
    text-align: right;
    vertical-align: center;
    padding: ${({ theme }) => theme.spacing.paddingXSM};
  }
`;

export const Chevron = styled(SvgIcon)`
  height: ${({ theme }) => theme.sizing.svgHeight};
  transition: ${({ theme }) => theme.transitionTime.fast};
  color: ${({ color }) => color};
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
`;

export const ViolationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
