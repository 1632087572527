import { useEffect, useState } from "react";
import { useFetch } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useNotifications } from "../../../context/hooks/useNotifications/useNotifications";
import { useModal } from "../../../context/modal/modal.provider";

export const useCreateProject = () => {
  const { post, error, status } = useFetch();
  const { createNotification, types } = useNotifications();

  const [newProjectSuccess, setNewProjectSuccess] = useState(true);
  const { openModal, MODALS, closeModal } = useModal();

  const createProject = projectName =>
    post(ENDPOINTS.CREATE_PROJECT, { name: projectName });

  useEffect(() => {
    if (status.isResolved) {
      createNotification(types.SUCCESS, "success.createProject");
      setNewProjectSuccess(true);
      closeModal();
    }
    if (status.isRejected && error) {
      createNotification(types.ERROR, `errors.createProject.${error.code}`);
    }
  }, [status.isResolved, status.isRejected, error]);

  const openCreateProjectModal = () =>
    openModal(MODALS.CREATE_PROJECT, { createProject });

  return {
    createProject,
    openCreateProjectModal,
    setNewProjectSuccess,
    newProjectSuccess,
  };
};
