import { CustomLink } from "../../../../components/Link/StyledLink";
import {
  LinkIcon,
  LinkIconContainer,
  TitleContainer,
} from "../testEvolveAccessiblityRunView.styles";
import {
  Container,
  Reference,
  ReferencesContainer,
} from "./rightWidget.styles";

export const Guideline = ({ guideline }) => {
  return (
    <Container id={guideline.title} guideline>
      <TitleContainer>
        <h2>
          {guideline.ref_id}. {guideline.title}
        </h2>
        <CustomLink to={guideline.url} external>
          <LinkIconContainer>
            <LinkIcon />
          </LinkIconContainer>
        </CustomLink>
      </TitleContainer>
      <ReferencesContainer>
        {guideline.references?.map(ref => (
          <CustomLink external to={ref.url} key={`${ref.title}-reference`}>
            <Reference>
              {ref.title}
              <LinkIconContainer noHover>
                <LinkIcon />
              </LinkIconContainer>
            </Reference>
          </CustomLink>
        ))}
      </ReferencesContainer>
      <p>{guideline.description}</p>
    </Container>
  );
};
