import React, { useState, useEffect, memo } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { Stripe, StripeFormContent } from "../../../stripe/Stripe";
import { Message, Modal } from "../../..";
import { ContainerMsg } from "../../../../views/organisationSettings/tabs/billing/paymentDetails/modals/modals.styles";
import { usePaymentDetails } from "../../../../views/organisationSettings/tabs/billing/paymentDetails/hooks/usePaymentDetails";
import { useConfig } from "../../../../context/config/config.provider";
import { usePaymentIntent } from "../../../../context/admin/paymentIntent/paymentIntent.provider";
import { useFormValidation } from "../../../../context/hooks";

export const ModifyPaymentForm = ({ closeModal, country }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { patchPaymentMethod } = usePaymentDetails();
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const { paymentIntent, getPaymentIntent, paymentIntentError } =
    usePaymentIntent();
  const { isDark } = useTheme();
  const [isValid, setIsValid] = useState({
    cvc: false,
    email: false,
    cardNumber: false,
    expiry: false,
  });
  const { validateEmail } = useFormValidation();

  useEffect(() => {
    if (paymentIntentError?.code) {
      setIsLoading(false);
      setErrorMessage(
        t(`errors.editPaymentDetails.${paymentIntentError?.code}`)
      );
    }
  }, [paymentIntentError]);

  useEffect(() => {
    if (paymentIntent?.setup_intent) callStripe();
    else {
      setIsLoading(false);
    }
  }, [paymentIntent]);

  const callStripe = async () => {
    let result = await stripe.confirmCardSetup(paymentIntent.setup_intent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          email: email,
        },
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
      setIsLoading(false);
    } else {
      patchPaymentMethod(result.setupIntent.payment_method);
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    getPaymentIntent();
  };

  const handleEmail = e => {
    setEmail(e.target.value);

    const res = validateEmail(e.target.value);
    setIsValid(v => ({ ...v, email: res.isValid ? true : false }));
  };

  const handleCardNumber = e => {
    setIsValid(v => ({ ...v, cardNumber: e.complete }));
  };

  const handleCvc = e => {
    setIsValid(v => ({ ...v, cvc: e.complete }));
  };

  const handleExpiry = e => {
    setIsValid(v => ({ ...v, expiry: e.complete }));
  };

  const validatePaymentDetails = () => Object.values(isValid).every(v => v);

  return (
    <Modal
      data-test="modal"
      title={t("subscriptionUpdate.updatePaymentDetails")}
      secondary={{
        text: t("paymentUpdate.cancel"),
        onClick: () => closeModal(),
      }}
      primary={{
        text: isLoading
          ? t("paymentUpdate.applying")
          : t("paymentUpdate.apply"),
        onClick: () => handleSubmit(),
        disabled: !validatePaymentDetails(),
      }}
      size="small"
      id="modal"
    >
      <StripeFormContent
        country={country}
        isDarkMode={isDark}
        handleCvc={handleCvc}
        handleEmail={handleEmail}
        handleExpiry={handleExpiry}
        handleCardNumber={handleCardNumber}
        data-test="addDetailsForm"
      />
      {errorMessage ? (
        <Message error data-test="error-message">
          {errorMessage}
        </Message>
      ) : null}
      <ContainerMsg>
        <p data-testid={"mandate"}>{t("paymentUpdate.mandate")}</p>
      </ContainerMsg>
    </Modal>
  );
};

export const ModifyPaymentModalUnMemo = props => {
  const { config } = useConfig();

  if (config.stripeApiKey) {
    return (
      <Stripe apiKey={config.stripeApiKey}>
        <ModifyPaymentForm {...props} />
      </Stripe>
    );
  } else {
    return null;
  }
};

export const ModifyPaymentModal = memo(ModifyPaymentModalUnMemo);
