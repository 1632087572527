import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useCreateSession = (runId, getRun, getRunDetails) => {
  const { post, status, body } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && body) {
      getRun();
      getRunDetails();
    }
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.createSessionError"
      );
  }, [status.isRejected, status.isResolved, body]);

  const createSession = e =>
    post(
      {
        ...ENDPOINTS.CREATE_SESSION,
        path: ENDPOINTS.CREATE_SESSION.path.replace(":runId", runId),
      },
      e
    );

  return {
    createSession,
  };
};
