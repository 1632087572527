import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PROVIDERS } from "../../../../../static/constants/constants";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useEditRun = (id, getRunDetails) => {
  const { runType } = useParams();
  const { status, put, error, body } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && body) getRunDetails();
    if (status.isRejected && error)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.editRunError"
      );
  }, [status.isResolved, status.isRejected, body]);

  const editRun = payload =>
    put(
      {
        ...ENDPOINTS.EDIT_RUN,
        path: ENDPOINTS.EDIT_RUN.path
          .replace(":provider", PROVIDERS.MANUAL)
          .replace(":runId", id),
      },
      { ...payload, category: runType }
    );

  return {
    editRun,
  };
};
