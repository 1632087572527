import { useLatestRun } from "./hooks/useLatestRun";
import { useTranslation } from "react-i18next";
import {
  Footer,
  Container,
  HorizontalContainer,
  VerticalContainer,
  LeftDiv,
  SectionHeader,
} from "./latestRunCard.styles";
import { Dot } from "../../../../components/dot/dot";
import { ScenariosSection } from "./ScenariosSection/scenariosSection";
import { ViolationsSection } from "./violationsSection/violationsSection";
import { useApp } from "../../../../context/app/app.provider";

export const LatestRunCard = ({ run, isLatest, nodeDetails }) => {
  const { t } = useTranslation();
  const { isMobile } = useApp();

  const { timeAgo, status, runStatus } = useLatestRun(run);

  return (
    <Container>
      <HorizontalContainer isMobile={isMobile}>
        <VerticalContainer isMobile={isMobile}>
          <SectionHeader data-test="scenarios-title">
            {isLatest
              ? t("runView.testevolveaxe.scenariosSectionTitle")
              : t("runView.testevolveaxe.selectedScenariosSectionTitle")}
          </SectionHeader>
          <ScenariosSection run={run} nodeDetails={nodeDetails} />
          <Footer>
            <LeftDiv data-test="test-status">
              <Dot colour={status} title="run-dot" />
              <p data-test="runStatus">{runStatus}</p>
            </LeftDiv>
            <p data-test="time-ago">{timeAgo}</p>
          </Footer>
        </VerticalContainer>
        <VerticalContainer isMobile={isMobile}>
          <SectionHeader data-test="violations-title">
            {isLatest
              ? t("runView.testevolveaxe.violationsSectionTitle")
              : t("runView.testevolveaxe.selectedViolationsSectionTitle")}
          </SectionHeader>
          <ViolationsSection run={run} />
        </VerticalContainer>
      </HorizontalContainer>
    </Container>
  );
};
