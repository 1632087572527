import Cookies from "js-cookie";
import { useFetch } from "../../../context/hooks";
import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../../context/auth/auth.provider";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

const USERNAME = "username";
const PASSWORD = "password";

export const useSignInActions = () => {
  const formRef = useRef(null);
  const [formError, setFormError] = useState(false);
  const [organisation, setOrganisation] = useState("");

  const { post, status, origin, error, reset } = useFetch();
  const {
    setAuthenticated,
    setUnAuthenticated,
    isAuthenticated,
    setSessionNotExpired,
  } = useAuth();

  useEffect(() => {
    setSessionNotExpired();
  }, []);

  useEffect(() => {
    if (status.isResolved && origin === ENDPOINTS.AUTH_SIGNIN.path)
      setAuthenticated();
    if (status.isRejected && origin === ENDPOINTS.AUTH_SIGNIN.path)
      Cookies.remove("SCC");
    if (status.isRejected) formRef.current?.reset();
  }, [status, origin, setAuthenticated, setUnAuthenticated]);

  const resetFields = () => {
    if (formError || error) {
      formRef.current.reset();
      setOrganisation("");
      setFormError(false);
      reset();
    }
  };

  const signInAction = e => {
    e?.preventDefault();
    setSessionNotExpired();

    const form = new FormData(e.target);
    const username = form.get(USERNAME);
    const password = form.get(PASSWORD);

    if (!formError) {
      let headers = {};
      if (organisation) headers.tenantId = organisation;
      post(ENDPOINTS.AUTH_SIGNIN, { username, password }, headers);
    }
  };

  return {
    error,
    formRef,
    resetFields,
    setOrganisation,
    organisation,
    signInAction,
    isAuthenticated,
    isLoading: status.isLoading,
  };
};
