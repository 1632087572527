import { TEST_CATEGORIES } from "../../../static/constants/constants";
import { CypressMinimalRunCard } from "./cypressMinimalCard/cypressMinimalCard";
import { PlaywrightMinimalRunCard } from "./playwrightMinimalCard/playwrightMinimalCard";
import { TestEvolveMinimalRunCard } from "./testEvolveFunctional/testEvolveMinimalCard";

export const MinimalRunCard = props => {
  switch (props?.category) {
    case TEST_CATEGORIES.PLAYWRIGHT_FUNCTIONAL:
      return <PlaywrightMinimalRunCard {...props} />;
    case TEST_CATEGORIES.CYPRESS_FUNCTIONAL:
      return <CypressMinimalRunCard {...props} />;
    default:
      return <TestEvolveMinimalRunCard {...props} />;
  }
};
