import { Modal } from "../../modal";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../../context/modal/modal.provider";
import { useProjectLabels } from "../../../../context/projectLabels/projectLabels.provider";
import { useEffect, useState } from "react";
import { Loading } from "../../../loading/loading";
import { TextArea } from "../../../textArea/textArea";
import { CreatableSelect } from "../../../select/creatableSelect";
import { useCreateManualRun } from "../../../../views/runView/testevolveExploratory/hooks/run/useCreateRun";
import { useNotifications } from "../../../../context/hooks";
import { TEST_CATEGORIES } from "../../../../static/constants/constants";
import { StyledSelect } from "../../../../views/settings/tabs/reports/reports.styles";
import { useApp } from "../../../../context/app/app.provider";
import { SelectContainer, SelectLabel } from "../../../select/select.styles";

const defaultLevel = { label: "AA", value: "AA" };
const levelOptions = [
  { label: "AAA", value: "AAA" },
  { label: "AA", value: "AA" },
  { label: "A", value: "A" },
];

export const CreateAccessibilityRunModal = ({ getLabels }) => {
  const { t } = useTranslation();

  const { isNotDesktop } = useApp();
  const { closeModal } = useModal();
  const { createNotification, types } = useNotifications();
  const { projectLabels, getProjectLabels } = useProjectLabels();
  const { createManualRun, createManualRunSucess } = useCreateManualRun(
    TEST_CATEGORIES.TEST_EVOLVE_ACCESSIBILITY
  );

  const [label, setLabel] = useState(null);
  const [error, setError] = useState(false);
  const [level, setLevel] = useState(defaultLevel);
  const [description, setDescription] = useState(null);

  useEffect(() => {
    if (!projectLabels) getProjectLabels();
  }, []);

  useEffect(() => {
    if (createManualRunSucess) {
      if (getLabels) getLabels();
      closeModal();
    }
  }, [createManualRunSucess]);

  const noLabel = () => {
    setError(true);
    createNotification(
      types.ERROR,
      t("runView.testevolveexploratory.createRun.noLabel")
    );
  };

  return (
    <Modal
      title={t("runView.testevolveaccessibility.createRun.title")}
      primary={{
        text: t("runView.testevolveexploratory.createRun.createRun"),
        onClick: () =>
          !label || !label?.length
            ? noLabel()
            : createManualRun({
                label,
                description,
                level: level.value,
                category: TEST_CATEGORIES.TEST_EVOLVE_ACCESSIBILITY,
              }),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      {" "}
      {!projectLabels ? (
        <Loading />
      ) : (
        <>
          <CreatableSelect
            error={error}
            isClearable
            options={projectLabels}
            setter={setLabel}
            label={t("runView.testevolveexploratory.createRun.label")}
          />
          <SelectContainer>
            <SelectLabel>
              {t("runView.testevolveaccessibility.createRun.level")}
            </SelectLabel>
            <StyledSelect
              value={level}
              options={levelOptions}
              onChange={setLevel}
              placeholder={t(
                "runView.testevolveaccessibility.createRun.selectLevel"
              )}
              classNamePrefix="Select"
              isNotDesktop={isNotDesktop}
              aria-label={t(
                "runView.testevolveaccessibility.createRun.selectLevel"
              )}
              innerProps={{
                "aria-label": t(
                  "runView.testevolveaccessibility.createRun.selectLevel"
                ),
              }}
            />
          </SelectContainer>
          <TextArea
            label="Description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder={t(
              "runView.testevolveexploratory.createRun.descriptionPlaceholder"
            )}
          />
        </>
      )}
    </Modal>
  );
};
