import { useParams } from "react-router";
import { useNodeView } from "../../nodeView/hooks/useNodeView";
import { useState, useEffect } from "react";

export const useRun = () => {
  const { node } = useNodeView();
  const { runId, runType } = useParams();
  const [run, setRun] = useState(null);

  useEffect(() => {
    const r = node[runType]?.history?.filter(
      r => r.id?.toString() === runId
    )[0];
    if (r) setRun(r);
  }, [runId]);

  return {
    run,
    selectedRun: run,
  };
};
