import styled from "styled-components";
import { readableColor } from "polished";

export const ViolationsContainer = styled.td`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  overflow-x: ${({ isNotDesktop }) => (isNotDesktop ? "auto" : "visible")};
  padding: 0;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
`;

export const StyledRow = styled.tr`
  cursor: pointer;
  outline: ${({ isSelected, theme }) =>
    isSelected ? `2px solid ${theme.colours.haloAccent}` : "none"};

  &:hover {
    transition: transform 0.2s;
    outline: ${({ theme, isSelected }) =>
      `${isSelected ? "2px" : "1px"} solid ${theme.colours.haloAccent}`};
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  color: ${({ theme }) => readableColor(theme.colours.background)};
  border-spacing: 0;

  th {
    letter-spacing: 0.5px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 20px;
  }

  td,
  th {
    text-align: left;
    padding: ${({ theme }) => theme.spacing.padding};
  }

  tr {
    border-radius: ${({ theme }) => theme.borders.cardRadius};
  }

  tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => readableColor(theme.colours.background)};
    border-radius: ${({ theme }) => theme.borders.cardRadius};

    th:first-child,
    td:first-child {
      border-bottom-left-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:last-child,
    td:last-child {
      border-bottom-right-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:first-child,
    td:first-child {
      border-top-left-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:last-child,
    td:last-child {
      border-top-right-radius: ${({ theme }) => theme.borders.cardRadius};
    }
  }
`;
