import React from "react";

export const combineProviders = (...components) =>
  components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      function func({ children }) {
        return (
          <AccumulatedComponents child={CurrentComponent.name}>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      },
    ({ children }) => <>{children}</>
  );
