import { useTranslation } from "react-i18next";
import { Input } from "../../../../components";
import {
  RecipientContainer,
  StyledAddButton,
  StyledDeleteButton,
} from "./reports.styles";

const EMAILS_NAME = "distribution_emails";

export const ReportRecipients = ({
  setFieldValue,
  values,
  errorKey,
  resetValidation,
  error,
}) => {
  const { t } = useTranslation();
  const addFieldValue = () => setFieldValue(EMAILS_NAME, [...values, ""]);

  const setEmail = (e, key) => {
    resetValidation();
    let newArray = values.map(val => val);
    newArray[key] = e.target.value;
    setFieldValue(EMAILS_NAME, newArray);
  };

  const removeFieldValue = key => {
    let newArray = values.map(val => val);
    newArray.splice(key, 1);
    setFieldValue(EMAILS_NAME, newArray);
  };

  return (
    <>
      {values.map((input, key) => (
        <RecipientContainer key={`container-${key}`}>
          <Input
            id="distribution_emails"
            placeholder={t("placeholder.reportRecipients")}
            value={values[key]}
            key={`input-${key}`}
            onChange={e => setEmail(e, key)}
            error={errorKey?.name === key || error}
          />
          {key > 0 && (
            <StyledDeleteButton danger onClick={() => removeFieldValue(key)}>
              {t("btnRemove")}
            </StyledDeleteButton>
          )}
        </RecipientContainer>
      ))}
      <StyledAddButton onClick={addFieldValue}>{t("btnAdd")}</StyledAddButton>
    </>
  );
};
