import {
  FrozenBar,
  FrozenGraphComponent,
  FrozenGraphContainer,
  NonFrozenBar,
} from "../releasesView.styles";
import { useTranslation } from "react-i18next";

export const FrozenGraph = ({ frozen, total }) => {
  const { t } = useTranslation();

  if (!total)
    return (
      <FrozenGraphContainer>
        <h3>{t("releases.releasesView.noRuns")}</h3>
      </FrozenGraphContainer>
    );

  return (
    <FrozenGraphContainer data-test="release-frozen-graph">
      <p>{`${frozen}/${total} ${t("releases.releasesView.runsFrozen")}`}</p>
      <FrozenGraphComponent>
        <FrozenBar width={frozen} />
        <NonFrozenBar width={total - frozen} />
      </FrozenGraphComponent>
    </FrozenGraphContainer>
  );
};
