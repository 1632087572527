import { jiraConfigReducer } from "./jiraConfig.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { ENDPOINTS } from "../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const initialState = {
  jiraConfig: {},
};

const [useHook, JiraConfigProvider] = createState(
  "jiraConfig",
  initialState,
  jiraConfigReducer
);

const useJiraConfig = () => {
  const { t } = useTranslation();
  const { jiraConfig, dispatch } = useHook();
  const { post, get, deleteApi, status, body, error, method } = useFetch();
  const { createNotification, types } = useNotifications();
  const createConfig = payload => post(ENDPOINTS.JIRA_CONFIG, payload);

  const getConfig = payload => get(ENDPOINTS.JIRA_CONFIG, payload);

  const deleteConfig = () => deleteApi(ENDPOINTS.JIRA_CONFIG);

  useEffect(() => {
    if (status.isResolved && (method === "POST" || method === "GET") && body)
      dispatch({ type: ACTIONS.SET_JIRA_CONFIG, payload: body });
    if (status.isResolved && method === "DELETE")
      dispatch({ type: ACTIONS.DELETE_JIRA_CONFIG });
    if (status.isRejected && method === "POST" && error)
      createNotification(
        types.ERROR,
        t(
          `errors.jira.${
            Array.isArray(error.text) ? error.text[0] : error.text
          }`
        )
      );
    if (status.isRejected && method === "DELETE")
      createNotification(types.ERROR, "error");
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    jiraConfig,
    isJiraConfigured: jiraConfig?.url !== undefined,
    getConfig,
    createConfig,
    deleteConfig,
    jiraLoading: !(status.isResolved || status.isRejected),
  };
};

export { useJiraConfig, JiraConfigProvider };
