import React from "react";
import {
  HighlightingMainContainer,
  ReportColumnContainer,
  ReportColumnHeader,
  StyledTitlesContainer,
  StyledContainer,
} from "./reports.styles";
import { Button } from "../../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import { ReportConfig } from "./ReportConfig";
import { useApp } from "../../../../context/app/app.provider";

export const ReportConfigList = ({ deleteReport, reportConfigs }) => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();

  return (
    <StyledContainer isNotDesktop={isNotDesktop}>
      <StyledTitlesContainer displayFlex>
        <h1>{t("reports.columnTitles.jobs")}</h1>
      </StyledTitlesContainer>
      <ReportColumnContainer>
        <ReportColumnHeader>
          {t("reports.columnHeadings.name")}
        </ReportColumnHeader>
        <ReportColumnHeader>
          {t("reports.columnHeadings.labels")}
        </ReportColumnHeader>
        <ReportColumnHeader>
          {t("reports.columnHeadings.schedule")}
        </ReportColumnHeader>
        <ReportColumnHeader>
          {t("reports.columnHeadings.startDate")}
        </ReportColumnHeader>
        <ReportColumnHeader></ReportColumnHeader>
      </ReportColumnContainer>
      {reportConfigs &&
        reportConfigs.map((reportConfig, key) => (
          <HighlightingMainContainer key={key}>
            <ReportConfig reportConfig={reportConfig} />
            <Button
              onClick={() => deleteReport(reportConfig.id)}
              data-test={"delete-report-" + key}
              danger
            >
              {t("reports.button.delete")}
            </Button>
          </HighlightingMainContainer>
        ))}
    </StyledContainer>
  );
};
