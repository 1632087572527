import { Section } from "../../tabs.styles";
import { useTranslation } from "react-i18next";
import { Button, Loading } from "../../../../../components";
import { SectionContainer } from "../billingTab.styles";
import {
  ButtonContainer,
  DetailsContainer,
  EntriesContainer,
  Entry,
  StyledKey,
  SubType,
  TitleContainer,
  InfoMessage,
  MessageContainer,
} from "./subscriptionDetails.styles";
import { ModifySubscriptionSection } from "./modifySubscription";
import {
  MONTH,
  FREE,
  BUSINESS,
  PERIOD_CHANGE,
  PARTNER,
  TEAM,
} from "../../../../../static/constants/constants";
import { DateTime } from "luxon";
import { useOrganisation } from "../../../../../context/organisation/organisation.provider";
import { useApp } from "../../../../../context/app/app.provider";
import { useModal } from "../../../../../context/modal/modal.provider";
import { useEffect, useState } from "react";
import { useCountry } from "../paymentDetails/hooks/useCountry";

export const SubscriptionDetails = ({
  country,
  paymentDetails,
  useSubscription,
  isProfile,
}) => {
  const { isNotDesktop, isMobile } = useApp();
  const { t } = useTranslation();
  const { isPartner } = useOrganisation();
  const { openModal, MODALS } = useModal();
  const { subscriptionDetails } = useSubscription;
  const { openCountryModal } = useCountry();
  const { closeModal, modal } = useModal();
  const [editSubscriptionSectionOpen, setEditSubscriptionSectionOpen] =
    useState(false);
  const toggleEditSubscriptionSection = () =>
    setEditSubscriptionSectionOpen(e => !e);

  const {
    period,
    max_seats,
    max_runs,
    used_runs,
    used_seats,
    subscription_id,
    subscription_validity,
    cancel_at_end_of_period,
    scheduled_update_period,
    scheduled_subscription_id,
  } = subscriptionDetails;

  const setPartnerModalOpen = () =>
    openModal(MODALS.ENABLE_PARTNER_SUBSCRIPTION, { country });

  useEffect(() => {
    if (country?.code && modal === MODALS.SELECT_COUNTRY) {
      if (isPartner) setPartnerModalOpen(true);
      else {
        closeModal();
        toggleEditSubscriptionSection();
      }
    }
  }, [country, modal]);

  const openPartnerSubscriptionModal = () =>
    openModal(MODALS.ENABLE_PARTNER_SUBSCRIPTION, { country });

  const editButtonHandler = () => {
    if (isProfile) return;
    if (!country?.code) return openCountryModal(toggleEditSubscriptionSection);
    if (isPartner) openPartnerSubscriptionModal();
    else toggleEditSubscriptionSection(true);
  };

  const getPeriod = () => {
    if (period) {
      return period === MONTH
        ? t("subscriptionInfo.Monthly")
        : t("subscriptionInfo.Annual");
    }
    return t("subscriptionInfo.n/a");
  };

  const getExpirationDate = () => {
    if (subscription_id === FREE) {
      return t("subscriptionInfo.n/a");
    }

    return subscription_validity > 0
      ? DateTime.fromMillis(subscription_validity).toFormat("LLL d, yyyy")
      : null;
  };

  if (!subscription_id || !subscriptionDetails) return <Loading />;

  return (
    <>
      <MessageContainer>
        {cancel_at_end_of_period && (
          <InfoMessage error data-test="cancel-subscription-pending-message">
            {t("billing.subscriptionCancellationPending", {
              expiryDate: getExpirationDate(),
            })}
          </InfoMessage>
        )}
        {scheduled_update_period && (
          <InfoMessage data-test="scheduled-period-pending-message">
            {t("subscriptionUpdate.scheduledPeriodChange", {
              expiryDate: getExpirationDate(),
              newPeriod: PERIOD_CHANGE[scheduled_update_period],
            })}
          </InfoMessage>
        )}
        {scheduled_subscription_id && (
          <InfoMessage data-test="scheduled-seats-pending-message">
            {t("subscriptionUpdate.scheduledSeatChange", {
              expiryDate: getExpirationDate(),
              newSeats: scheduled_subscription_id,
            })}
          </InfoMessage>
        )}
      </MessageContainer>
      <Section>
        <SectionContainer isNotDesktop={isNotDesktop}>
          <DetailsContainer grow={4} isNotDesktop={isNotDesktop}>
            <TitleContainer>
              <p>{t("subscriptionInfo.SubscriptionDetails")}</p>
            </TitleContainer>
            <EntriesContainer isNotDesktop={isNotDesktop} isMobile={isMobile}>
              {isProfile ? null : (
                <Entry>
                  <ButtonContainer>
                    {cancel_at_end_of_period ? (
                      <Button
                        onClick={() =>
                          openModal(MODALS.REACTIVATE_SUBSCRIPTION, {
                            subscriptionDetails,
                          })
                        }
                        accent
                        className="btn-reactivate-subscription"
                        data-test="reactivate-subscription-button"
                        disabled={editSubscriptionSectionOpen}
                      >
                        {t("billing.reactivate")}
                      </Button>
                    ) : (
                      <>
                        <Button
                          onClick={editButtonHandler}
                          disabled={
                            editSubscriptionSectionOpen ||
                            subscription_id === PARTNER
                          }
                        >
                          {t("subscriptionInfo.Edit")}
                        </Button>
                        {(subscription_id === BUSINESS ||
                          subscription_id === TEAM) && (
                          <Button
                            secondary
                            onClick={() =>
                              openModal(MODALS.CANCEL_SUBSCRIPTION, {
                                subscriptionDetails,
                              })
                            }
                            disabled={editSubscriptionSectionOpen}
                          >
                            {t("btnCancel")}
                          </Button>
                        )}
                      </>
                    )}
                  </ButtonContainer>
                </Entry>
              )}
              <Entry>
                <SubType>
                  {subscription_id === t("subscriptionInfo.business")
                    ? t("subscriptionInfo.BUSINESS")
                    : subscription_id}
                </SubType>
              </Entry>
              <Entry>
                <StyledKey>{t("subscriptionInfo.Period")}</StyledKey>
                <span>{getPeriod()}</span>
              </Entry>
            </EntriesContainer>
          </DetailsContainer>
          <DetailsContainer grow={2} isNotDesktop={isNotDesktop}>
            <TitleContainer>{t("subscriptionInfo.scenarios")}</TitleContainer>
            <EntriesContainer isNotDesktop={isNotDesktop} isMobile={isMobile}>
              <Entry>
                <StyledKey>{t("subscriptionInfo.used")}</StyledKey>
                <span>{used_runs}</span>
              </Entry>
              <Entry>
                <StyledKey>{t("subscriptionInfo.Remaining")}</StyledKey>
                <span>
                  {max_runs === -1
                    ? t("subscriptionInfo.Unlimited")
                    : (max_runs - used_runs).toString()}
                </span>
              </Entry>
            </EntriesContainer>
          </DetailsContainer>
          <DetailsContainer grow={2} isNotDesktop={isNotDesktop}>
            <TitleContainer>{t("subscriptionInfo.Seats")}</TitleContainer>
            <EntriesContainer isNotDesktop={isNotDesktop} isMobile={isMobile}>
              <Entry>
                <StyledKey>{t("subscriptionInfo.InUse")}</StyledKey>
                <span>{used_seats}</span>
              </Entry>
              <Entry>
                <StyledKey>{t("subscriptionInfo.Remaining")}</StyledKey>
                <span>
                  {max_seats === -1
                    ? t("subscriptionInfo.Unlimited")
                    : (max_seats - used_seats).toString()}
                </span>
              </Entry>
            </EntriesContainer>
          </DetailsContainer>
          <DetailsContainer grow={2}>
            <TitleContainer>{t("subscriptionInfo.Expiration")}</TitleContainer>
            <Entry>
              <span>
                {subscription_validity === null
                  ? t("subscriptionInfo.Unlimited")
                  : getExpirationDate()}
              </span>
            </Entry>
          </DetailsContainer>
        </SectionContainer>
      </Section>
      {editSubscriptionSectionOpen ? (
        <ModifySubscriptionSection
          toggleUpdateSection={toggleEditSubscriptionSection}
          subscriptionDetails={subscriptionDetails}
          country={country}
          paymentDetails={paymentDetails}
        />
      ) : null}
    </>
  );
};
