import styled from "styled-components";

export const NodeCard = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.padding};
  width: ${({ theme }) => theme.sizing.nodeCardWidth};
  height: ${({ theme }) => theme.sizing.nodeCardHeight};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.nodeCardBackground};

  &:hover {
    transition: transform 0.2s;
    transform: scale(1.02);
    box-shadow: ${({ theme }) => theme.decoration.cardShadowHover};
  }
`;

export const NodeType = styled.h2`
  font-size: ${({ theme }) => theme.spacing.padding};
  font-weight: 400;
`;

export const TestTypeContainer = styled.div`
  gap: ${({ theme }) => theme.spacing.paddingSM};
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: -${({ theme }) => theme.spacing.paddingMD};
`;

export const CenteredContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  h1 {
    padding: ${({ theme }) => theme.spacing.padding} 0;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-size: 14px;
  }
`;

export const GraphContainer = styled.div``;

export const LeftDiv = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;
