import {
  ArrowIcon,
  FilterAndSortOption,
  FilterButton,
  FilterButtonsContainer,
  SortAndFilterContainer,
  SortDirectionButton,
  SortSelect,
} from "./releasesView.styles";
import { useTranslation } from "react-i18next";

export const ReleaseSortAndFilter = useSortingAndFiltering => {
  const {
    toggleSortDirection,
    toggleClosed,
    toggleOpen,
    showOpen,
    showClosed,
    isSortedAsc,
    isSortedDesc,
    sortMethod,
    setSortMethod,
  } = useSortingAndFiltering;

  const { t } = useTranslation();

  return (
    <SortAndFilterContainer>
      <FilterAndSortOption>
        <p>{t("releases.releasesView.showing")}</p>
        <FilterButtonsContainer>
          <FilterButton
            active={showOpen}
            onClick={toggleOpen}
            aria-label="Show open releases"
          >
            {t("releases.OPEN")}
          </FilterButton>
          <FilterButton
            active={showClosed}
            onClick={toggleClosed}
            aria-label="Show closed releases"
          >
            {t("releases.CLOSED")}
          </FilterButton>
        </FilterButtonsContainer>
      </FilterAndSortOption>
      <FilterAndSortOption>
        <label htmlFor="sort-release">
          {t("releases.releasesView.sortBy")}
        </label>
        <SortSelect
          value={sortMethod}
          onChange={e => setSortMethod(e.target.value)}
          id="sort-release"
          aria-label="Sort release by"
        >
          <option value={"closeDate"}>{t("Close Date")}</option>
          <option value={"openDate"}>{t("Open Date")}</option>
          <option value={"name"}>{t("Alphabetical")}</option>
        </SortSelect>
        <SortDirectionButton
          active={isSortedDesc}
          onClick={toggleSortDirection}
          aria-label="Toggle filter direction"
        >
          <ArrowIcon active={isSortedAsc} />
          <ArrowIcon active={isSortedDesc} up={true} />
        </SortDirectionButton>
      </FilterAndSortOption>
    </SortAndFilterContainer>
  );
};
