import styled from "styled-components";

export const StyledOrgContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const StyledHeader = styled.h1`
  font-size: 24px;
  color: ${({ theme }) => theme.colours.mainText};
  text-align: center;
  word-break: break-word;
  text-align: center;
`;
