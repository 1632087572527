import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { useFetch } from "../../../context/hooks/fetch/useFetch";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { getLabelFromURL } from "../../../utils/labelFromURL/labelfromURL";
import { CATEGORY_PROVIDER_MAP } from "../../../static/constants/constants";

export const useRunDetails = () => {
  const { runType } = useParams();
  const [runDetails, setRunDetails] = useState(null);
  const { get, status, body, error, origin } = useFetch();
  const label = getLabelFromURL();

  const getRunDetails = (page = 1) => {
    const encodedLabel = encodeURI(label);

    let url = ENDPOINTS.RUN_DETAILS.path
      .replace(":category", runType)
      .replace(":label", encodedLabel)
      .replace(":page", page)
      .replace(":provider", CATEGORY_PROVIDER_MAP[runType]);

    get({
      ...ENDPOINTS.RUN_DETAILS,
      path: url,
    });
  };

  useEffect(() => {
    if (status.isResolved && body) setRunDetails(body);
  }, [status.isResolved, status.isRejected, body, error, origin]);

  return {
    runDetails,
    getRunDetails,
    runDetailsError: error,
  };
};
