import { UsersList } from "./usersList";
import { TabContainer, Section } from "../tabs.styles";
import { OrganisationUsersProvider } from "../../../../context/organisationUsers/organisationUsers.provider";
import { InviteUserSection } from "./inviteUserSection";

export const OrganisationTab = () => (
  <OrganisationUsersProvider>
    <TabContainer>
      <Section>
        <InviteUserSection />
      </Section>
      <Section>
        <UsersList />
      </Section>
    </TabContainer>
  </OrganisationUsersProvider>
);
