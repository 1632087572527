import { useLocation } from "react-router-dom";

export const useQueryParams = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const getAllParams = () => {
    const queries = search
      .split("?")
      .map(s => s.split("&"))
      .flat();
    const filtered = queries.filter(q => q.includes("="));
    const keyValues = filtered.map(f => f.split("="));

    return Object.fromEntries(keyValues);
  };

  const getParam = val => {
    const params = getAllParams(val);
    const param = params[val];
    return param;
  };

  const getParams = (...vals) => {
    return vals.reduce(
      (obj, item) => ({ ...obj, [item]: query.get(item) }),
      {}
    );
  };

  return {
    getParam,
    getParams,
    getAllParams,
  };
};
