import {
  Chevron,
  LeftContainer,
  ReleaseDescription,
  ReleaseDetailsContainer,
  RightContainer,
  StatusSticker,
  Title,
  TitleContainer,
} from "../release.styles";
import {
  MENU_STATE,
  RELEASE_STATUSES,
} from "../../../static/constants/constants";
import {
  ButtonIcon,
  LabelEditButton,
} from "../../runView/runDetails/runDetails.styles";
import { useReleases } from "../../../context/releases/releases.provider";
import { useTranslation } from "react-i18next";
import {
  convertDateToLuxonDate,
  daysAgo,
  daysUntil,
} from "../../../utils/dates/dateUtils";
import { usePermissions } from "../../../context/hooks";
import { useApp } from "../../../context/app/app.provider";
import { ReactComponent as ShareIcon } from "../../../static/assets/icon-share.svg";
import { Spacer } from "../../runReportView/components/bug.styles";
import { SharedReleaseDetails } from "./sharedReleaseDetails";
import { useOpenRelease } from "../../../components/releases/openRelease/useOpenRelease";
import { DateTime } from "luxon";
import { useModal } from "../../../context/modal/modal.provider";
import { ReleaseDetailsContextMenu } from "../../../components/menus/releaseDetailsContextMenu/releaseDetailsContextMenu";
import { assets } from "../../../static/assets/assets";
import { useClick, useMenuState } from "@szhsin/react-menu";
import { useRef } from "react";

export const ReleaseDetailsContent = () => {
  const { t } = useTranslation();

  const { isMobile } = useApp();
  const { release } = useReleases();
  const { isAdmin } = usePermissions();
  const { openModal, MODALS } = useModal();

  const isOpen = release.status === RELEASE_STATUSES.OPEN;
  const isClosed = release.status === RELEASE_STATUSES.CLOSED;

  const daysSince = release.close_date ? daysAgo(release.close_date) : null;
  const daysRemaining = release.close_date
    ? daysUntil(release.close_date)
    : null;

  return (
    <ReleaseDetailsContainer>
      <LeftContainer>
        <TitleContainer>
          <Title>
            <h1>{release.name}</h1>
            {isAdmin() ? (
              <LabelEditButton
                onClick={() =>
                  openModal(MODALS.EDIT_RELEASE, { release: release })
                }
              />
            ) : null}
          </Title>
          {isMobile ? (
            <ReleaseDescription>{release.description}</ReleaseDescription>
          ) : null}
          {isAdmin() ? (
            <ReleaseContextMenu
              release={release}
              admin={isAdmin()}
              isClosed={isClosed}
              openModal={() =>
                openModal(MODALS.CLOSE_RELEASE, {
                  release: release,
                })
              }
            />
          ) : (
            <StatusSticker isAdmin={isAdmin()} isClosed={isClosed}>
              {t(`releases.${RELEASE_STATUSES[release.status]}`)}
            </StatusSticker>
          )}
        </TitleContainer>
        {!isMobile ? (
          <ReleaseDescription>{release.description}</ReleaseDescription>
        ) : null}
      </LeftContainer>
      <Spacer />
      {isOpen && release.close_date && daysSince !== null ? (
        <RightContainer>
          <h3>
            {t(
              daysSince < 0 ? "releases.daysOverdue" : "releases.daysRemaining",
              {
                days: Math.abs(daysSince),
                multiple: daysSince === 1 ? null : "s",
              }
            )}
          </h3>
          <p>
            {convertDateToLuxonDate(release.close_date).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
          </p>
        </RightContainer>
      ) : null}
      {isClosed && release.close_date && daysRemaining !== null ? (
        <RightContainer>
          <h3>
            {t("releases.daysAgo", {
              days: daysRemaining,
              multiple: daysRemaining === 1 ? null : "s",
            })}
          </h3>
          <p>
            {convertDateToLuxonDate(release.close_date).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
          </p>
        </RightContainer>
      ) : null}
      <ButtonIcon
        title="Share"
        data-test="share-btn"
        onClick={() =>
          openModal(MODALS.SHARED_RELEASE_VIEW, { releaseId: release.id })
        }
      >
        <ShareIcon />
      </ButtonIcon>
    </ReleaseDetailsContainer>
  );
};

export const ReleaseDetails = props => {
  if (props.isShared) return <SharedReleaseDetails />;

  return <ReleaseDetailsContent {...props} />;
};

const ReleaseContextMenu = ({ release, admin, isClosed, openModal }) => {
  const { t } = useTranslation();
  const { openRelease } = useOpenRelease();
  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);
  const menuOpen = menuState.state === MENU_STATE.OPEN;

  return (
    <>
      <StatusSticker
        status={release.status}
        admin={admin}
        isClosed={isClosed}
        onMouseDown={anchorProps.onMouseDown}
        isOpen={menuOpen}
        ref={ref}
        onClick={e => {
          anchorProps.onClick();
          e.stopPropagation();
        }}
      >
        {t(`releases.${RELEASE_STATUSES[release.status]}`)}
        <Chevron {...assets.icons.navChevron} isOpen={true} />
      </StatusSticker>
      <ReleaseDetailsContextMenu
        release={release}
        t={t}
        openCloseReleaseModal={openModal}
        r={ref}
        menuState={menuState}
        toggleMenu={toggleMenu}
        isClosed={isClosed}
        openRelease={openRelease}
      />
    </>
  );
};
