import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export const useTime = () => {
  const { t } = useTranslation();

  const getReadableTimeDifference = (startDate, endDate) => {
    let end = DateTime.local();
    const start = DateTime.fromISO(startDate);
    if (endDate) {
      end = DateTime.fromISO(endDate);
    }
    const toFixedNoRounding = num => {
      const reg = new RegExp("^-?\\d+(?:\\.\\d{0,0})?", "g");
      const a = num.toString().match(reg);
      return a ? a[0].replace(".", "") : num.toString();
    };

    const seconds = toFixedNoRounding(end.diff(start, "seconds").seconds);
    const minutes = toFixedNoRounding(end.diff(start, "minutes").minutes);
    const hours = toFixedNoRounding(end.diff(start, "hours").hours);
    const days = toFixedNoRounding(end.diff(start, "days").days);

    if (seconds < 0) return `${t("time.secWithCount", { time: 0 })}`;
    if (days > 0) {
      if (days > 1) return `${t("time.dayWithCount_plural", { time: days })}`;
      return `${t("time.dayWithCount", { time: days })}`;
    }

    if (hours > 0) {
      if (hours > 1)
        return `${t("time.hourWithCount_plural", { time: hours })}`;
      return `${t("time.hourWithCount", { time: hours })}`;
    }

    if (minutes > 0) {
      if (minutes > 1)
        return `${t("time.minWithCount_plural", { time: minutes })}`;
      return `${t("time.minWithCount", { time: minutes })}`;
    }

    if (seconds > 0) {
      if (seconds > 1)
        return `${t("time.secWithCount_plural", { time: seconds })}`;
      return `${t("time.secWithCount", { time: seconds })}`;
    }
  };

  const getFormattedFinishTime = time => {
    const dateTime = DateTime.fromISO(time);
    return `${dateTime.toLocaleString(
      DateTime.TIME_WITH_SHORT_OFFSET
    )} ${dateTime.toLocaleString(DateTime.DATE_SHORT)}`;
  };

  const getTimeBetween = (time1, time2) => {
    const end = new Date(time2).getTime();
    const start = new Date(time1).getTime();
    let diff = (end - start) / 1000;
    const hours = Math.floor(diff / 3600);
    diff = diff - hours * 3600;
    const mins = Math.floor(diff / 60);
    diff = diff - mins * 60;
    const secs = Math.floor(diff);

    return `${hours < 10 ? "0" : ""}${hours}:${mins < 10 ? "0" : ""}${mins}:${
      secs < 10 ? "0" : ""
    }${secs}`;
  };

  return {
    getTimeBetween,
    getFormattedFinishTime,
    getReadableTimeDifference,
  };
};
