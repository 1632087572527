import { BillingUpdateReducer } from "./billingUpdate.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";

const initialState = {
  update: false,
};

const [useHook, BillingUpdateProvider] = createState(
  "billing",
  initialState,
  BillingUpdateReducer
);

const useUpdate = () => {
  const { update, dispatch } = useHook();

  const setBillingUpdate = () => dispatch({ type: ACTIONS.BILLING_UPDATE });

  const setNoBillingUpdate = () =>
    dispatch({ type: ACTIONS.NO_BILLING_UPDATE });

  return {
    update,
    setBillingUpdate,
    setNoBillingUpdate,
  };
};

export { useUpdate, BillingUpdateProvider };
