import { configReducer } from "./config.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";

const initialState = {
  hCaptchaSiteKey: "",
  stripeApiKey: "",
  taxRates: {},
};

const [useHook, ConfigProvider] = createState(
  "config",
  initialState,
  configReducer
);

const useConfig = () => {
  const { hCaptchaSiteKey, stripeApiKey, taxRates, dispatch } = useHook();

  const setConfig = body =>
    dispatch({ type: ACTIONS.GET_CONFIG_SUCCESS, payload: body });

  return {
    config: { hCaptchaSiteKey, stripeApiKey, taxRates },
    setConfig,
  };
};

export { useConfig, ConfigProvider };
