import styled from "styled-components";
import { ReactComponent as Icon } from "../../../static/assets/icon-open-menu.svg";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.projectCardWidth};
  height: ${({ theme }) => theme.sizing.projectCardHeight};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  background-color: ${({ theme }) => theme.colours.foreground};
  border: 1px solid ${({ theme }) => theme.borders.headerBorder};
  padding: ${({ theme }) => theme.spacing.padding}
    ${({ theme }) => theme.spacing.paddingLG};
  gap: ${({ theme }) => theme.spacing.paddingLG};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  &:hover {
    transition: transform 0.2s;
    transform: scale(1.02);
    box-shadow: ${({ theme }) => theme.decoration.cardShadowHover};
  }

  h1 {
    word-wrap: break-word;
  }
`;

export const ProjectId = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${({ theme }) => theme.colours.mainText};
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const OpenMenuIcon = styled(Icon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  z-index: 96;
  opacity: ${({ active }) => (active ? "100%" : "60%")};
  color: ${({ theme }) => theme.colours.mainText};

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: end;

  .szh-menu {
    background-color: ${({ theme }) => theme.colours.dropdownBG};
    width: ${({ theme }) => theme.sizing.dropdownMenuWidth};
    border: 1px ${({ theme }) => theme.borders.dropdownBorder} solid;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    padding: ${({ theme }) => theme.spacing.paddingSM};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  }

  .szh-menu__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${({ theme }) => theme.sizing.dropdownRowHeight};
    z-index: 99;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    gap: ${({ theme }) => theme.spacing.paddingSM};

    &:hover {
      background-color: ${({ theme }) => theme.colours.dropdownHighlight};
      p {
        opacity: 100%;
      }
    }
  }

  svg {
    opacity: ${({ active }) => (active ? "100%" : "60%")};
    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.colours.mainText};
`;

export const StyledMenu = styled(ControlledMenu)`
  color: ${({ theme }) => theme.colours.mainText};

  svg {
    opacity: ${({ active }) => (active ? "100%" : "60%")};
    color: ${({ theme }) => theme.colours.mainText};
  }
`;
export const DeleteText = styled.p`
  color: ${({ theme }) => theme.colours.error};
`;
