import { useLatestRun } from "../hooks/useLatestRun";
import { PieChart, Pie, Cell } from "recharts";
import { useTranslation } from "react-i18next";
import {
  Container,
  GraphContainer,
  Section,
  StyledRow,
} from "./scenariosSection.styles";
import { routes } from "../../../../../router/routes";
import { useHistoryWithParams } from "../../../../../context/hooks/historyWithParams/useHistoryWithParams";
import {
  RUN_STATUSES,
  STATUSES,
} from "../../../../../static/constants/constants";
import { StatusTable } from "../../../testEvolveVisual/latestRunCard/ScenariosSection/scenariosSection.styles";
import { Dot } from "../../../testEvolveLighthouse/latestRunCard/ScenariosSection/scenariosSection.styles";

export const ScenariosSection = ({ run, nodeDetails }) => {
  const { t } = useTranslation();
  const history = useHistoryWithParams();
  const { scenarioStatuses, scenariosChartData } = useLatestRun(run);
  const isInProgress = run.status === RUN_STATUSES.IN_PROGRESS;

  const reportUrl = `${routes.RUN_VIEW_REPORT.path
    .replace(":nodeId", nodeDetails?.id)
    .replace(":runType", run?.category)
    .replace(":label", encodeURI(encodeURIComponent(run?.label)))
    .replace(":runId", run?.id)}`;

  const stickerHandler = (key, value) => {
    if (isInProgress) return;

    history.pushWithHash(
      key && value ? `${reportUrl}?${key}=${value}` : reportUrl
    );
  };

  return (
    <Container>
      <Section data-test="scenarios-pie-chart">
        <GraphContainer data-test="scenarios-pie-chart">
          <PieChart data-test="scenarios-pie-chart">
            <Pie
              data={scenariosChartData}
              cornerRadius={40}
              dataKey="value"
              data-test="scenarios-pie"
              radius={[10, 10, 0, 0]}
              innerRadius={68}
              outerRadius={80}
              paddingAngle={5}
            >
              <Cell
                key={"cell-pass"}
                fill={"url(#gradientPass"}
                stroke="none"
                radius={[10, 10, 10, 10]}
                aria-label="pass-cell"
              />
              <Cell
                key={"cell-fail"}
                fill={"url(#gradientFail"}
                stroke="none"
                radius={[10, 10, 10, 10]}
                aria-label="fail-cell"
              />
              <Cell
                key={"cell-error"}
                fill={"url(#gradientError"}
                stroke="none"
                radius={[10, 10, 10, 10]}
                aria-label="error-cell"
              />
              <Cell
                key={"cell-skipped"}
                fill={"url(#gradientSkipped"}
                stroke="none"
                radius={[10, 10, 10, 10]}
                aria-label="skipped-cell"
              />
            </Pie>
          </PieChart>
        </GraphContainer>
      </Section>
      <Section>
        <StatusTable data-test="scenarios-table">
          <thead>
            <tr>
              <th>{t("runView.testevolveaxe.colour")}</th>
              <th>{t("runView.testevolveaxe.status")}</th>
              <th>{t("runView.testevolveaxe.description")}</th>
              <th>{t("runView.testevolveaxe.count")}</th>
            </tr>
          </thead>
          <tbody>
            <StyledRow
              clickable={!isInProgress}
              onClick={() => stickerHandler("status", STATUSES.PASSED)}
            >
              <td>
                <Dot colourCode={scenariosChartData[0].color} rotate="45" />
              </td>
              <td>{t("runView.testevolveaxe.passed.name")}</td>
              <td>{t("runView.testevolveaxe.passed.quickDescription")}</td>
              <td>{scenarioStatuses.passed}</td>
            </StyledRow>
            <StyledRow
              clickable={!isInProgress}
              onClick={() => stickerHandler("status", STATUSES.FAILED)}
            >
              <td>
                <Dot colourCode={scenariosChartData[1].color} rotate="45" />
              </td>
              <td>{t("runView.testevolveaxe.failed.name")}</td>
              <td>{t("runView.testevolveaxe.failed.quickDescription")}</td>
              <td>{scenarioStatuses.failed}</td>
            </StyledRow>
            <StyledRow
              clickable={!isInProgress}
              onClick={() => stickerHandler("status", STATUSES.ERROR)}
            >
              <td>
                <Dot colourCode={scenariosChartData[2].color} rotate="45" />
              </td>
              <td>{t("runView.testevolveaxe.error.name")}</td>
              <td>{t("runView.testevolveaxe.error.quickDescription")}</td>
              <td>{scenarioStatuses.error}</td>
            </StyledRow>
            <StyledRow
              clickable={!isInProgress}
              onClick={() => stickerHandler("status", STATUSES.SKIPPED)}
            >
              <td>
                <Dot colourCode={scenariosChartData[3].color} rotate="45" />
              </td>
              <td>{t("runView.testevolveaxe.skipped.name")}</td>
              <td>{t("runView.testevolveaxe.skipped.quickDescription")}</td>
              <td>{scenarioStatuses.skipped}</td>
            </StyledRow>
          </tbody>
        </StatusTable>
      </Section>
    </Container>
  );
};
