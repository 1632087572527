import styled from "styled-components";

export const SummariesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.padding};
  padding-bottom: ${({ theme }) => theme.spacing.padding};
  align-items: center;
  width: ${({ theme }) => theme.sizing.summaryWidth};

  h1 {
    font-size: ${({ theme }) => theme.fontSize.largester};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  h2 {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-align: center;
  }
`;

export const LeftDiv = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;
