import { appReducer } from "./app.reducer";
import { createState } from "../../utils/context";
import { BREAKPOINTS } from "../../theme/constants";
import { ACTIONS } from "../../static/constants/actions";

const initialState = {
  viewport: "XS",
  fullscreen: false,
};

const [useHook, AppProvider] = createState("app", initialState, appReducer);

const useApp = () => {
  const { viewport, fullscreen, dispatch } = useHook();

  const setFullScreen = val =>
    dispatch({ type: ACTIONS.SET_FULLSCREEN, payload: val });

  const setViewport = val =>
    dispatch({ type: ACTIONS.SET_VIEWPORT, payload: val });

  const watchViewport = () => {
    const checkWidth = () => {
      const matchSM = window.matchMedia(`(min-width: ${BREAKPOINTS.SM})`);
      const matchMD = window.matchMedia(`(min-width: ${BREAKPOINTS.MD})`);
      const matchLG = window.matchMedia(`(min-width: ${BREAKPOINTS.LG})`);
      const matchXL = window.matchMedia(`(min-width: ${BREAKPOINTS.XL})`);

      let vp = "XS";

      if (matchXL.matches) {
        vp = "XL";
      } else if (matchLG.matches) {
        vp = "LG";
      } else if (matchMD.matches) {
        vp = "MD";
      } else if (matchSM.matches) {
        vp = "SM";
      }
      if (vp !== viewport) setViewport(vp);
    };

    checkWidth();

    window.addEventListener("resize", checkWidth);
  };

  return {
    viewport,
    fullscreen,
    setViewport,
    watchViewport,
    setFullScreen,
    isMobile: viewport === "SM" || viewport === "XS",
    isTablet: viewport === "MD",
    isDesktop: viewport === "LG" || viewport === "XL",
    isNotDesktop: !(viewport === "LG" || viewport === "XL"),
    isXL: viewport === "XL",
  };
};

export { useApp, AppProvider };
