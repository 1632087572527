import React from "react";
import { routes } from "../../../../../../router/routes";
import {
  ScenarioCard,
  Dot,
  ScenarioNameData,
  SeveritySticker,
  ChecksSticker,
} from "./scenario.styles";
import { useTranslation } from "react-i18next";
import { useHistoryWithParams } from "../../../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { useModal } from "../../../../../../context/modal/modal.provider";

export const Scenario = ({
  name,
  checksPerformed,
  performance,
  accessibility,
  bestPractices,
  seo,
  performanceColour,
  accessibilityColour,
  bestPracticesColour,
  seoColour,
  run,
  nodeId,
  isShared,
}) => {
  const history = useHistoryWithParams();
  const { t } = useTranslation();
  const { openModal, MODALS } = useModal();

  return (
    <>
      <ScenarioCard
        onClick={() =>
          isShared
            ? openModal(MODALS.SHARE_RUN, { run })
            : history.push(
                `${routes.RUN_VIEW_REPORT.path
                  .replace(":nodeId", nodeId)
                  .replace(":runType", run.category)
                  .replace(":label", run.label)
                  .replace(":runId", run.id)}`
              )
        }
      >
        <ScenarioNameData>{name}</ScenarioNameData>
        <td>
          {(checksPerformed || checksPerformed === 0) && (
            <ChecksSticker>
              <h1>{checksPerformed}</h1>
              <p>
                {checksPerformed === 1
                  ? t("runView.check")
                  : t("runView.checks")}
              </p>
            </ChecksSticker>
          )}
        </td>
        <td>
          {(performance || performance === 0) && (
            <SeveritySticker>
              <Dot colour={performanceColour} rotate="45" />
              <p>{performance}</p>
            </SeveritySticker>
          )}
        </td>
        <td>
          {(accessibility || accessibility === 0) && (
            <SeveritySticker>
              <Dot colour={accessibilityColour} rotate="45" />
              <p>{accessibility}</p>
            </SeveritySticker>
          )}
        </td>
        <td>
          {(bestPractices || bestPractices === 0) && (
            <SeveritySticker>
              <Dot colour={bestPracticesColour} rotate="45" />
              <p>{bestPractices}</p>
            </SeveritySticker>
          )}
        </td>
        <td>
          {(seo || seo === 0) && (
            <SeveritySticker>
              <Dot colour={seoColour} rotate="45" />
              <p>{seo}</p>
            </SeveritySticker>
          )}
        </td>
      </ScenarioCard>
    </>
  );
};
