import styled, { css } from "styled-components";

export const StickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => theme.sizing.stickerHeight};
  width: fit-content;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  font-size: 14px;
  grid-column: ${({ double }) => double && "span 2"};

  svg {
    height: ${({ theme }) => theme.sizing.svgHeight};
    width: auto;
  }

  ${({ runProperties }) =>
    !runProperties &&
    css`
      padding: ${({ theme }) => theme.spacing.paddingSM}
        ${({ theme }) => theme.spacing.paddingMD};
      border-radius: ${({ theme }) => theme.borders.radius};
      box-shadow: ${({ theme }) => theme.decoration.cardShadow};
      background-color: ${({ theme }) => theme.colours.runPropertyBackground};
    `};
`;

export const Text = styled.p`
  fill: ${({ theme }) => theme.colours.mainText};
`;

export const ValContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${({ theme }) => theme.colours.mainText};

  p {
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }
`;

export const Heading = styled.div`
  opacity: 60%;
  font-size: 12px;
  color: ${({ theme }) => theme.colours.mainText};
`;
