import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { useApp } from "../../../../context/app/app.provider";
import { ApiKeysInput, Container } from "./apiKeysTab.styles";

export const ApiKeyForm = ({ alias, setAlias, createApiKey }) => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();

  return (
    <Container isNotDesktop={isNotDesktop}>
      <h2>{t("settings.apiKeys.inputTitle")}</h2>
      <ApiKeysInput
        placeholder="Name"
        value={alias}
        onChange={e => setAlias(e.target.value)}
      />
      <Button
        data-test="create-api-key-btn"
        disabled={!alias.length}
        onClick={() => createApiKey()}
      >
        {t("settings.apiKeys.createApiKey.addKey")}
      </Button>
    </Container>
  );
};
