import React, { useState } from "react";
import {
  StyledTitle,
  StyledContainer,
  VerticalContainer,
  StyledProductCard,
  StyledSecondaryText,
  HorizontalContainer,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useDownloads } from "./hooks/useDownloads";
import { ReactComponent as FlareIcon } from "../../static/assets/icon-flare.svg";
import { ReactComponent as HaloLogo } from "../../static/assets/halo-logo-no-words.svg";
import { FlareSection } from "./flare/flareSection";
import { HaloCliSection } from "./cli/cli";

const DOWNLOADS = {
  FLARE: "flare",
  HALO_CLI: "halo_cli",
};

export const Downloads = () => {
  const i18n = useTranslation();
  const [selected, setSelected] = useState(null);
  const { flareVersion, haloCliVersion } = useDownloads();

  return (
    <StyledContainer>
      <StyledTitle>{i18n.t("downloads.downloadsTitle")}</StyledTitle>
      <HorizontalContainer>
        <StyledProductCard
          selected={selected === DOWNLOADS.FLARE}
          onClick={() =>
            setSelected(selected === DOWNLOADS.FLARE ? null : DOWNLOADS.FLARE)
          }
        >
          <FlareIcon />
          <VerticalContainer>
            <StyledTitle>{i18n.t("downloads.flare")}</StyledTitle>
            <StyledSecondaryText>
              {i18n.t("downloads.latestVersion", { version: flareVersion })}
            </StyledSecondaryText>
          </VerticalContainer>
        </StyledProductCard>
        <StyledProductCard
          selected={selected === DOWNLOADS.HALO_CLI}
          onClick={() =>
            setSelected(
              selected === DOWNLOADS.HALO_CLI ? null : DOWNLOADS.HALO_CLI
            )
          }
        >
          <HaloLogo />
          <VerticalContainer>
            <StyledTitle>{i18n.t("downloads.halo_cli")}</StyledTitle>
            <StyledSecondaryText>
              {i18n.t("downloads.latestVersion", { version: haloCliVersion })}
            </StyledSecondaryText>
          </VerticalContainer>
        </StyledProductCard>
      </HorizontalContainer>
      {selected === DOWNLOADS.FLARE ? (
        <FlareSection version={flareVersion} />
      ) : null}
      {selected === DOWNLOADS.HALO_CLI ? (
        <HaloCliSection version={haloCliVersion} />
      ) : null}
      <br />
    </StyledContainer>
  );
};
