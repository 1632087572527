import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.mainText};
`;
