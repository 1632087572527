import { ACTIONS } from "../../static/constants/actions";

export const configReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.GET_CONFIG_SUCCESS:
      return {
        ...state,
        hCaptchaSiteKey: payload.hcaptcha_site_key,
        stripeApiKey: payload.stripe_api_key,
        taxRates: payload.tax_rates,
      };
    default:
      return state;
  }
};
