import React, { useRef } from "react";
import { Container, ReportContainer } from "./playwrightRunReport.styles";
import { TestSuite } from "./testSuite/testSuite";
import { useUuid } from "../../../context/uuid/useUuid";
import { useApp } from "../../../context/app/app.provider";
import { useTheme } from "../../../context/theme/theme.provider";
import { ErrorPage } from "../../../components/errorPage/errorPage";
import { Loading } from "../../../components";

export const PlaywrightRunReport = ({ run, runType, error, isFetching }) => {
  const { uuid } = useUuid();
  const { isDark } = useTheme();
  const { isMobile } = useApp();

  const featuresRef = useRef(null);
  const reportContainerRef = useRef(null);

  const TestSuitesContainer = () =>
    run && run.test_suites
      ? run.test_suites?.map((suite, suiteIndex) => (
          <TestSuite
            key={`feature-${suiteIndex}`}
            suite={suite}
            isDark={isDark}
            uuid={uuid}
            category={runType}
            isMobile={isMobile}
            suiteIndex={suiteIndex}
          />
        ))
      : null;

  if (isFetching) return <Loading />;

  return error && error?.code ? (
    <ErrorPage code={error.code} />
  ) : (
    <Container>
      <ReportContainer ref={reportContainerRef} id="report-container">
        <div ref={featuresRef} id="feature-container">
          <TestSuitesContainer />
        </div>
      </ReportContainer>
    </Container>
  );
};
