import { useEffect, useState } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";

export const useLatestRuns = setLatestRuns => {
  const [isLoading, setIsLoading] = useState(true);
  const { get, status, body } = useFetch();

  useEffect(() => {
    if (status.isResolved && body) {
      setIsLoading(false);
      setLatestRuns(body.run_summaries);
    }
  }, [status.isResolved, status.isRejected, body]);

  const getLatestRuns = () => get(ENDPOINTS.LATEST_RUNS);

  return {
    getLatestRuns,
    loadingLatestRuns: isLoading,
  };
};
