import { useEffect } from "react";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../hooks";
import { useOrganisationUsers } from "../../organisationUsers.provider";

export const useProjectRoles = () => {
  const { status, patch } = useFetch();
  const { createNotification, types } = useNotifications();
  const { getOrganisationUsers } = useOrganisationUsers();

  const patchProjectRole = (userId, projectId, payload) =>
    patch(
      {
        ...ENDPOINTS.USER_PROJECT_ROLES,
        path: ENDPOINTS.USER_PROJECT_ROLES.path.replace(":id", userId),
      },
      payload,
      { "X-PROJECT-ID": projectId }
    );

  useEffect(() => {
    if (status.isResolved) {
      createNotification(types.SUCCESS, "settings.organisation.successfulEdit");
      getOrganisationUsers();
    }
    if (status.isRejected) {
      createNotification(types.ERROR, "Something went wrong");
    }
  }, [status.isResolved, status.isRejected]);

  return {
    patchProjectRole,
  };
};
