import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../components";
import { useScreenshot } from "../../hooks/useScreenshot";
import {
  JiraImage,
  SectionContainer,
  StyledLabel,
  ThumbnailContainer,
} from "../styles";

const sp = /screenshot\/(.*)/;
const SCREENSHOT = "screenshot";

export const JiraScreenshotSection = ({
  screenshots,
  setScreenshotIds,
  screenshotIds,
}) => {
  const { t } = useTranslation();
  const [screenshotImages, setScreenshotImages] = useState([]);
  const { response, getScreenshot, status } = useScreenshot();

  useEffect(() => {
    setScreenshotImages([]);
    screenshots?.forEach(sc => {
      getScreenshot(sc.id);
    });
  }, []);

  const extractImage = async () => {
    if (!response?.url?.includes(SCREENSHOT)) return;

    const id = response.url?.match(sp)?.[1];
    if (!id) return;
    if (screenshotImages.filter(e => e.id === id).length > 0) return;

    const scObject = { id, body: response.body };
    setScreenshotIds(prev => [...new Set([...prev, id])]);

    setScreenshotImages(prev => [...prev, scObject]);
  };

  useEffect(() => {
    extractImage();
  }, [response]);

  const toggleSelected = id => {
    if (isSelected(id)) setScreenshotIds(e => e.filter(ss => ss !== id));
    else setScreenshotIds(e => [...e, id]);
  };

  const isSelected = id => screenshotIds.includes(id);

  return status.isLoading ? (
    <SectionContainer>
      <Loading />
    </SectionContainer>
  ) : screenshotImages && screenshotImages.length ? (
    <SectionContainer>
      <StyledLabel id={"screenshots"} hideLabels={false}>
        {t("jira.label.screenshots", {
          selected: screenshotIds.length,
          total: screenshotImages.length,
        })}
      </StyledLabel>
      <ThumbnailContainer>
        {screenshotImages.map(sc => (
          <JiraImage
            src={sc.body}
            key={sc.id}
            selected={isSelected(sc.id)}
            onClick={() => toggleSelected(sc.id)}
          />
        ))}
      </ThumbnailContainer>
    </SectionContainer>
  ) : null;
};
