import { ACTIONS } from "../../static/constants/actions";

export const nodeReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_NODE_SUMMARY:
      return {
        ...state,
        nodeSummary: payload,
      };
    case ACTIONS.SET_NODE:
      return {
        ...state,
        node: payload,
        runs: [payload.latest_run, ...payload.run_history],
      };
    default:
      return state;
  }
};
