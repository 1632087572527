export const ACTIONS = {
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",

  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SIGNOUT_FAILURE: "SIGNOUT_FAILURE",

  ROLES_SUCCESS: "ROLES_SUCCESS",
  USER: "USER",
  REMOVE_USER: "REMOVE_USER",
  USER_SUCCESS: "USER_SUCCESS",

  SET_PROJECT: "SET_PROJECT",
  SET_PROJECTS: "SET_PROJECTS",
  MODIFY_PROJECT: "MODIFY_PROJECT",
  CLEAR_PROJECTS: "CLEAR_PROJECTS",
  REMOVE_PROJECT: "REMOVE_PROJECT",
  SET_COUNTRY: "SET_COUNTRY",
  GET_ORGANISATION_SUCCESS: "GET_ORGANISATION_SUCCESS",
  GET_ORGANISATION_FAILURE: "GET_ORGANISATION_FAILURE",

  GET_CONFIG_SUCCESS: "GET_CONFIG_SUCCESS",
  SET_SAVE_BUTTON_ACTIVE: "SET_SAVE_BUTTON_ACTIVE",
  SET_INITIAL_PROJECTS: "SET_INITIAL_PROJECTS",

  OPEN_NAV: "NAV_OPEN",
  CLOSE_NAV: "NAV_CLOSE",
  SET_NAV_ITEM_OPEN: "SET_NAV_ITEM_OPEN",

  SET_FULLSCREEN: "SET_FULLSCREEN",
  SET_VIEWPORT: "SET_VIEWPORT",
  SET_LABELS: "SET_LABELS",

  FIRST_TIME: "FIRST_TIME",
  LIGHT_MODE: "LIGHT_MODE",
  DARK_MODE: "DARK_MODE",

  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",

  GET_USERS_LIST: "GET_USERS_LIST",
  GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
  GET_PROJECTS_USERS_LIST_SUCCESS: "GET_PROJECTS_USERS_LIST_SUCCESS",

  GET_USER_OPTIONS_SUCCESS: "GET_USER_OPTIONS_SUCCESS",
  TOGGLE_USER_ACTIVE: "TOGGLE_USER_ACTIVE",
  GET_USERS_UPDATED: "GET_USERS_UPDATED",
  SELECT_GLOBAL_ROLE_CHECKBOX: "SELECT_GLOBAL_ROLE_CHECKBOX",
  SELECT_ADMIN_OPTIONS: "SELECT_ADMIN_OPTIONS",
  SELECT_ADMIN_OPTIONS_SUCCESS: "SELECT_ADMIN_OPTIONS_SUCCESS",
  CLEAR_USER_UPDATED: "CLEAR_USER_UPDATED",
  UPDATE_USERS_LIST: "UPDATE_USERS_LIST",
  GET_ADMIN_LOGS_SUCCESS: "GET_ADMIN_LOGS_SUCCESS",
  TOGGLE_GUIDE_OPEN: "TOGGLE_GUIDE_OPEN",

  SET_TREE: "SET_TREE",
  SET_NODE: "SET_NODE",
  SET_OPEN: "SET_OPEN",
  SET_CLOSED: "SET_CLOSED",
  SET_NODE_SUMMARY: "SET_NODE_SUMMARY",
  SET_BUTTON_INACTIVE: "SET_BUTTON_INACTIVE",

  SET_SESSION_EXPIRED: "SET_SESSION_EXPIRED",
  SET_SESSION_NOT_EXPIRED: "SET_SESSION_NOT_EXPIRED",
  HANDLE_TIMER: "HANDLE_TIMER",

  SET_SUBSCRIPTION_PLANS: "SET_SUBSCRIPTION_PLANS",
  SET_API_KEYS: "SET_API_KEYS",
  SET_PAYMENT_INTENT: "SET_PAYMENT_INTENT",
  SET_PAYMENT_INTENT_NULL: "SET_PAYMENT_INTENT_NULL",

  BILLING_UPDATE: "BILLING_UPDATE",
  NO_BILLING_UPDATE: "NO_BILLING_UPDATE",

  SET_JIRA_CONFIG: "SET_JIRA_CONFIG",
  DELETE_JIRA_CONFIG: "DELETE_JIRA_CONFIG",
  PAGE_INCREMENT: "PAGE_INCREMENT",
  PAGE_DECREMENET: "PAGE_DECREMENET",
  PAGE_RESET: "PAGE_RESET",
  SET_RUN_SHARING_TOKEN: "SET_RUN_SHARING_TOKEN",
  SET_RELEASE_SHARING_TOKEN: "SET_RELEASE_SHARING_TOKEN",

  SET_RUN_STATUS_LATEST: "SET_RUN_STATUS_LATEST",
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION",

  SET_RELEASES: "SET_RELEASES",
  SET_ALL_OPEN_RELEASES: "SET_ALL_OPEN_RELEASES",
  ADD_RELEASE: "ADD_RELEASE",
  SET_RELEASE: "SET_RELEASE",
  MODIFY_RELEASE: "MODIFY_RELEASE",
  REMOVE_RELEASE: "REMOVE_RELEASE",
  CLEAR_RELEASES: "CLEAR_RELEASES",
  APPEND_RELEASES: "APPEND_RELEASES",

  MODIFY_RELEASE_LABELS: "MODIFY_RELEASE_LABELS",
  CLEAR_PROJECT: "CLEAR_PROJECT",

  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",

  SET_IN_PROGRESS_RUNS: "SET_IN_PROGRESS_RUNS",
  SET_LATEST_RUNS: "SET_LATEST_RUNS",
  SET_STEP: "SET_STEP",
  HIDE_GUIDE: "HIDE_GUIDE",
  SET_IN_PROGRESS_LOADING: "SET_IN_PROGRESS_LOADING",
  SET_LATEST_LOADING: "SET_LATEST_LOADING",
  CLEAR_HOME: "CLEAR_HOME",
};
