import { projectLabelsReducer } from "./projectLabels.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { ENDPOINTS } from "../../static/constants/routeConfig";
import { useEffect } from "react";
import { useFetch } from "../hooks";

const initialState = {
  projectLabels: null,
};

const [useHook, ProjectLabelsProvider] = createState(
  "projectLabels",
  initialState,
  projectLabelsReducer
);

const useProjectLabels = () => {
  const { projectLabels, dispatch } = useHook();
  const { get, status, body, error, method } = useFetch();

  const setLabels = prjs =>
    dispatch({ type: ACTIONS.SET_LABELS, payload: prjs });

  const getProjectLabels = () => get(ENDPOINTS.LABELS);

  useEffect(() => {
    if (status.isResolved && body && method === "GET") setLabels(body);
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    projectLabels,
    getProjectLabels,
  };
};

export { useProjectLabels, ProjectLabelsProvider };
