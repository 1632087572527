import { useTranslation } from "react-i18next";
import { Button } from "../../components";
import { useReleases } from "../../context/releases/releases.provider";
import { ReleaseCard } from "./releaseCard/releaseCard";
import { LoadMoreContainer, ReleasesContainer } from "./releasesView.styles";

export const ReleaseCardContainer = ({ getNextPage }) => {
  const { t } = useTranslation();
  const { releases, total_releases } = useReleases();

  return (
    <>
      <ReleasesContainer>
        {releases?.map(rel => (
          <ReleaseCard key={`release-${rel.id}-${rel.name}`} release={rel} />
        ))}
      </ReleasesContainer>
      {releases.length < total_releases ? (
        <LoadMoreContainer>
          <Button onClick={getNextPage}>
            {t("releases.releasesView.loadMore")}
          </Button>
        </LoadMoreContainer>
      ) : null}
    </>
  );
};
