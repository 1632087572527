import styled, { css } from "styled-components";

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: 0 ${({ theme }) => theme.spacing.padding};

  div {
    width: fit-content;
  }
`;

export const CreateFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  width: 100%;
`;

export const TitleContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  color: ${({ theme }) => theme.colours.mainText};
  padding: 0 ${({ theme }) => theme.spacing.padding};
`;

export const CustomerColumnHeader = styled.div`
  color: ${({ theme }) => theme.colours.mainText};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CustomerColumnContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colours.foreground};
  }
`;

export const StyledField = styled.input`
  width: 100%;
  appearance: none;
  @media all and (-ms-high-contrast: none) {
    & {
      box-sizing: content-box;
    }
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.paddingSM};
    background: ${theme.colours.background};
    height: ${theme.sizing.btnHeight};
    line-height: inherit;
    border: 1px solid ${({ theme }) => theme.colours.foreground};
    border-radius: ${theme.borders.radius};
    color: ${theme.colours.mainText};
    border-color: ${({ theme, invalid }) => invalid && theme.colours.error};

    &::-moz-placeholder {
      line-height: 0;
      color: ${({ theme }) => theme.colours.secondaryText};
    }
    &::placeholder {
      color: ${({ theme }) => theme.colours.secondaryText};
    }
  `};
`;

export const StyledFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: auto;
  border: 0;
  padding: ${({ theme }) => theme.spacing.padding};
  justify-content: center;
  width: ${({ isNotDesktop }) => (isNotDesktop ? "100%" : "13%")};

  ${({ theme, large }) =>
    large &&
    css`
      ${StyledField} {
        height: ${theme.sizing.btnHeight};
      }
    `}
  
  ${({ theme, spacing }) =>
    spacing &&
    css`
      ${StyledField} {
        margin-bottom: ${theme.spacing.paddingSM};
      }
    `}
	};

`;

export const CustomerColumnCell = styled.div`
  padding: ${({ theme }) => theme.spacing.padding} 0;
  display: flex;
  align-items: center;
`;

export const StyledCreateFieldContainer = styled.div`
  display: flex;
  align-self: auto;
  border: 0;
  padding: ${({ theme }) => theme.spacing.padding};
  flex-grow: 1;

  width: ${({ isNotDesktop }) => isNotDesktop && "100%"};

  ${({ theme, large }) =>
    large &&
    css`
      ${StyledField} {
        height: ${theme.sizing.btnHeight};
      }
    `}
  
  ${({ theme, spacing }) =>
    spacing &&
    css`
      ${StyledField} {
        margin-bottom: ${theme.spacing.paddingSM};
      }
    `}
	};
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledMainContainer = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.padding};
`;

export const ManageFormContainer = styled.div`
  display: flex;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: space-between;
  align-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing.padding};
  border-bottom: 1px solid ${({ theme }) => theme.colours.background};
`;

export const ButtonAndSpinnerContainer = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
`;
