import { useTranslation } from "react-i18next";
import { Modal } from "../../modal";
import { useDeleteUser } from "../../../../views/organisationSettings/tabs/organisation/hooks/useDeleteUser";

export const DeleteUserModal = ({ closeModal, user }) => {
  const { t } = useTranslation();
  const { deleteUser } = useDeleteUser();

  return (
    <Modal
      title={t("organisationSettings.deleteUser.modalTitle")}
      text={t("organisationSettings.deleteUser.modalSupportText", {
        user: user.username,
      })}
      primary={{
        text: t("btnConfirm"),
        onClick: () => deleteUser(user.id),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    />
  );
};
