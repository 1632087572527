import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";
import { useTranslation } from "react-i18next";

export const useDeleteAttachment = afterSuccess => {
  const { t } = useTranslation();
  const { deleteApi, status, error, response } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && response) {
      const responseUrl = response.url.split("/");
      const id = responseUrl[responseUrl.length - 1];
      createNotification(
        types.SUCCESS,
        t("runView.testevolveexploratory.deleteAttachmentSuccess", { id: id })
      );
      if (afterSuccess) afterSuccess(id);
    }
    if (status.isRejected && error)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.deleteAttachmentError"
      );
  }, [status.isResolved, status.isRejected, error]);

  const deleteAttachment = attachmentId =>
    deleteApi({
      ...ENDPOINTS.GET_ATTACHMENT,
      path: ENDPOINTS.GET_ATTACHMENT.path.replace(
        ":attachmentId",
        attachmentId
      ),
    });

  return {
    deleteAttachment,
    deleteAttachmentLoading: status.isLoading,
  };
};
