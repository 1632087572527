import { css } from "styled-components";

export const base = css`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;

  html {
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    background-color: ${({ theme }) => theme.colours.background};
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  body {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  h1 {
    margin: 0;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.colours.mainText};
    font-weight: 500;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.colours.mainText};
  }

  p,
  label {
    line-height: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: ${({ theme }) => theme.colours.mainText};
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
  }

  #root {
    height: 100%;
  }
`;
