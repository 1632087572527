import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useCloseRun = (runId, getRun) => {
  const { patch, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(
        types.SUCCESS,
        "runView.testevolveexploratory.closeRunSuccess"
      );
      getRun();
    }

    if (status.isRejected)
      createNotification(
        types.ERROR,
        error.code === 422
          ? "runView.testevolveexploratory.closeRunError422"
          : "runView.testevolveexploratory.closeRunError"
      );
  }, [status.isRejected, status.isResolved, body, error]);

  const closeRun = () =>
    patch({
      ...ENDPOINTS.CLOSE_MANUAL_RUN,
      path: ENDPOINTS.CLOSE_MANUAL_RUN.path.replace(":runId", runId),
    });

  return {
    closeRun,
  };
};
