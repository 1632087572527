import { useEffect, useState } from "react";
import { Modal } from "../../modal/modal";
import { Input } from "../../Input/Input";
import { TextArea } from "../../textArea/textArea";
import { DatePicker } from "../../datePicker/datePicker";
import { useTranslation } from "react-i18next";
import { useFormValidation } from "../../../context/hooks";
import {
  ErrorContainer,
  Container,
} from "../../../views/organisationSettings/tabs/partnerCustomers/forms/forms.styles";
import { Message } from "../../message/message";
import {
  RELEASE_OBJ,
  RELEASE_NAME_MIN_LENGTH,
  RELEASE_NAME_MAX_LENGTH,
  RELEASE_DESC_MAX_LENGTH,
} from "../../../static/constants/constants";
import { convertDateToTs } from "../../../utils/dates/dateUtils";

export const ReleaseModal = ({
  toggleModal,
  hook,
  edit,
  initial,
  modalTitle,
  release,
}) => {
  const { submit, isLoading, isError } = hook;

  const [payload, setPayload] = useState(initial);
  const { t } = useTranslation();
  const {
    validateForm,
    isInvalid,
    isValid,
    validation,
    reason,
    resetValidation,
    type,
  } = useFormValidation();

  useEffect(() => {
    resetValidation();
  }, [payload]);

  const updateRelease = (key, value) =>
    setPayload(e => ({ ...e, [key]: value }));

  useEffect(() => {
    if (isValid) submit(payload, release);
  }, [isValid, isInvalid, validation, reason]);

  useEffect(() => {
    if (isError) setPayload(initial);
  }, []);

  const buttonHandler = () => {
    validateForm([
      {
        name: RELEASE_OBJ.NAME,
        value: payload.name,
        validate: [type.LENGTH],
        params: { min: RELEASE_NAME_MIN_LENGTH, max: RELEASE_NAME_MAX_LENGTH },
      },
    ]);
  };

  return (
    <Modal
      title={
        modalTitle
          ? modalTitle
          : t(`releases.${edit ? "editModal" : "createModal"}.title`)
      }
      primary={{
        onClick: buttonHandler,
        text: t(edit ? "btnSave" : "btnCreate"),
        disabled: isInvalid || (edit && !hook.compareRelease(payload)),
        loader: true,
        loading: isLoading,
      }}
      secondary={{
        onClick: toggleModal,
        text: t("btnCancel"),
      }}
    >
      <ErrorContainer>
        {isInvalid && validation.length
          ? validation.map(err =>
              err.name && err.text ? (
                <Message key={err.name} error>
                  {err.text}
                </Message>
              ) : null
            )
          : null}
      </ErrorContainer>
      <Input
        label={t("releases.createModal.nameLabel")}
        value={payload.name}
        onChange={e => updateRelease(RELEASE_OBJ.NAME, e.target.value)}
        placeholder={t("releases.createModal.namePlaceholder")}
      />
      <Container>
        <TextArea
          label={t("releases.createModal.descriptionLabel")}
          value={payload.description}
          onChange={e => updateRelease(RELEASE_OBJ.DESCRIPTION, e.target.value)}
          placeholder={t("releases.createModal.descriptionPlaceholder")}
          maxLength={RELEASE_DESC_MAX_LENGTH}
        />
        <p>
          {payload?.description?.length || 0}/{RELEASE_DESC_MAX_LENGTH}
        </p>
      </Container>
      <DatePicker
        onChange={e => updateRelease(RELEASE_OBJ.OPEN_DATE, e)}
        name={t("releases.createModal.openDateName")}
        label={t("releases.createModal.openDateLabel")}
        placeholder={t("releases.createModal.datePlaceholder")}
        value={convertDateToTs(payload.open_date)}
        maxDate={convertDateToTs(payload.close_date)}
        present
        future
        past
      />
      <DatePicker
        onChange={e => updateRelease(RELEASE_OBJ.CLOSE_DATE, e)}
        name={t("releases.createModal.closeDateName")}
        label={t("releases.createModal.closeDateLabel")}
        placeholder={t("releases.createModal.datePlaceholder")}
        value={convertDateToTs(payload.close_date)}
        minDate={convertDateToTs(payload.open_date)}
        present
        future
      />
    </Modal>
  );
};
