import React from "react";
import { TableComponent } from "./AdminLogsTable";
import { useAdminLogs } from "../../../../context/admin/logs/adminLogs.provider";

export const AdminLogsContainer = () => {
  const { adminLogs, getAdminLogs } = useAdminLogs();

  React.useEffect(() => {
    getAdminLogs();
  }, []);

  return (
    <div>
      {adminLogs && adminLogs.length > 0 && (
        <TableComponent adminLogs={adminLogs} data-test="table-component" />
      )}
    </div>
  );
};
