import { useEffect } from "react";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { routes } from "../../../router/routes";
import { useHistoryWithParams } from "../../../context/hooks/historyWithParams/useHistoryWithParams";
import { useFetch } from "../../../context/hooks";
import { useAuth } from "../../../context/auth/auth.provider";

export const useAuthCheck = () => {
  const history = useHistoryWithParams();
  const { get, status, origin, error, body } = useFetch();
  const { setAuthenticated, setUnAuthenticated } = useAuth();

  const checkAuth = () => get(ENDPOINTS.ORGANISATION);

  useEffect(() => {
    if (status.isResolved && body && origin === ENDPOINTS.ORGANISATION.path) {
      setAuthenticated();
    }
    if (status.isRejected || error) {
      setUnAuthenticated();
      history.push(routes.SIGNIN.path);
    }
  }, [status.isResolved, status.isRejected, error]);

  return {
    checkAuth,
  };
};
