import { useTranslation } from "react-i18next";
import { useProject } from "../../../../context/project/project.provider";
import { routes } from "../../../../router/routes";
import { Modal } from "../../modal";
import styled from "styled-components";
import { useDisableReleaseSharing } from "../../../../views/releaseView/sharedReleaseView/useDisableReleaseSharing";
import { useEnableReleaseSharing } from "../../../../views/releaseView/sharedReleaseView/useEnableReleaseSharing";
import { useEffect, useState } from "react";
import { useQueryReleaseToken } from "../../../../views/releaseView/sharedReleaseView/useQueryReleaseToken";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CustomLink } from "../../../Link/StyledLink";
import { Button } from "../../../Button/Button";
import { useReleaseSharingToken } from "../../../../views/releaseView/sharedReleaseView/useReleaseSharingToken";

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.paddingMD};
  padding: ${({ theme }) => theme.spacing.paddingMD};
  background-color: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};
  color: ${({ theme }) => theme.colours.mainText};
  max-width: ${({ theme }) => theme.sizing.modalWidth};

  a {
    overflow-wrap: break-word;
    word-break: break-all;
    color: ${({ theme }) => theme.colours.mainText};
    text-align: center;

    &: hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colours.haloAccent};
    }
  }
`;

export const ShareReleaseViewModal = ({ closeModal, releaseId }) => {
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();
  const { project } = useProject();

  const { queryReleaseToken } = useQueryReleaseToken();
  const { enableReleaseSharing } = useEnableReleaseSharing();
  const { disableReleaseSharing } = useDisableReleaseSharing();
  const { releaseSharingToken } = useReleaseSharingToken();

  const sharingLink = () =>
    `${window.location.origin}${routes.SHARED_RELEASE_VIEW.path
      .replace(":releaseId", releaseId)
      .replace(":token", releaseSharingToken)}?project=${project.id}`;

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 3000);
  }, [copied]);

  useEffect(() => {
    if (releaseId) queryReleaseToken(releaseId);
  }, [releaseId]);

  return releaseSharingToken ? (
    <Modal
      primary={{
        text: t("btnUnshare"),
        onClick: () => disableReleaseSharing(releaseSharingToken),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
      title={t("releases.sharedReleaseView.sharedModalTitle")}
    >
      <LinkContainer>
        <CustomLink to={sharingLink()}>{sharingLink()}</CustomLink>
        <CopyToClipboard text={sharingLink()}>
          <Button accent onClick={() => setCopied(true)}>
            {copied ? t("btnCopied") : t("btnClickToCopy")}
          </Button>
        </CopyToClipboard>
      </LinkContainer>
    </Modal>
  ) : (
    <Modal
      primary={{
        text: t("btnShare"),
        onClick: () => enableReleaseSharing(releaseId),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
      title={t("releases.sharedReleaseView.sharedModalTitle")}
      text={t("releases.sharedReleaseView.sharedModalText")}
    />
  );
};
