import { useEffect, useState } from "react";
import { Loading, Modal } from "../../..";
import { useTranslation } from "react-i18next";
import { useReleases } from "../../../../context/releases/releases.provider";
import {
  ReleaseOption,
  ReleaseOptionsContainer,
} from "./addLabelToRelease.styles";
import { useAddLabelToRelease } from "../../../releases/addLabelToRelease/useAddLabelToRelease";
import { useGetReleases } from "../../../../context/releases/getReleases";

export const AddLabelToReleaseModal = ({ closeModal, label }) => {
  const { t } = useTranslation();
  const { allOpenReleases } = useReleases();
  const [selectedRelease, setSelectedRelease] = useState(null);
  const { addNewLabel } = useAddLabelToRelease();
  const { getReleases, getReleasesLoading } = useGetReleases();
  const [availableReleases, setAvailableReleases] = useState([]);

  useEffect(() => {
    getReleases({});
  }, []);

  useEffect(() => {
    const ar = allOpenReleases?.filter(r => !r.labels?.includes(label));
    setAvailableReleases(ar);
  }, [allOpenReleases]);

  const buttonHandler = () => addNewLabel([{ value: label }], selectedRelease);

  return (
    <Modal
      title={t("releases.addLabelToRelease.title")}
      text={t("releases.addLabelToRelease.text")}
      primary={{
        text: t("btnConfirm"),
        onClick: buttonHandler,
        props: {
          disabled: !selectedRelease || !availableReleases?.length,
        },
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      {getReleasesLoading ? (
        <Loading />
      ) : (
        <ReleaseOptionsContainer>
          {availableReleases?.length ? (
            availableReleases?.map(release => (
              <ReleaseOption
                selected={selectedRelease?.id === release.id}
                key={`${release.name}-option`}
                onClick={() => setSelectedRelease(release)}
              >
                {release.name}
              </ReleaseOption>
            ))
          ) : (
            <p>{t("releases.addLabelToRelease.noReleases")}</p>
          )}
        </ReleaseOptionsContainer>
      )}
    </Modal>
  );
};
