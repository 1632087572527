import { RightWidgetContiner } from "../testEvolveAccessiblityRunView.styles";
import { Guideline } from "./guideline";
import { Principle } from "./principle";
import { SuccessCriteria } from "./successCriteria";
import { useTranslateData } from "./useTranslateData";

export const RightWidget = ({ run, getRun }) => {
  const { principle, guideline, successCriteria } = useTranslateData(run);

  return (
    <RightWidgetContiner>
      {principle ? <Principle principle={principle} /> : null}
      {guideline ? <Guideline guideline={guideline} /> : null}
      {successCriteria ? (
        <SuccessCriteria
          runId={run.id}
          successCriteria={successCriteria}
          getRun={getRun}
        />
      ) : null}
    </RightWidgetContiner>
  );
};
