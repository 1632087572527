import { StyledSelect } from "../../../settings/tabs/reports/reports.styles";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../../../static/assets/icon-cross.svg";

export const FreezeDropdown = styled(StyledSelect)`
  margin: 0;
  color: ${({ theme }) => theme.colours.mainText};

  & .Select__single-value {
    color: ${({ theme }) => theme.colours.white};
  }

  & .Select__input-container {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .Select__control {
    background-color: ${({ theme }) => theme.colours.freezeButtonBackground};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    box-shadow: none;
    color: ${({ theme }) => theme.colours.mainText};
    border: ${({ theme, error }) =>
      error && `1px solid ${theme.colours.error}`};

    &:hover {
      border-color: ${({ theme }) => theme.colours.haloAccent};
    }

    height: 100%;
    opacity: 0;
  }

  & .Select__control--is-focused {
    border-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.haloAccent};
  }

  & .Select__indicator-separator {
    width: 0;
    height: 0;
    content: none;
    padding: 0;
    margin: 0;
  }

  & .Select__menu {
    background-color: ${({ theme }) => theme.colours.freezeButtonBackground};
    color: ${({ theme }) => theme.colours.mainText};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    border: 1px solid ${({ theme }) => theme.colours.freezeButtonText};
    z-index: 20;
  }

  & .Select__value-container {
    width: 0;
    height: 0;
    content: none;
    padding: 0;
    margin: 0;
  }

  & .Select__multi-value {
    width: 0;
    height: 0;
    content: none;
    padding: 0;
    margin: 0;
  }

  & .Select__multi-value__label {
    width: 0;
    height: 0;
    content: none;
    padding: 0;
    margin: 0;
  }

  & .Select__multi-value__remove {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }

  & .Select__option {
    background-color: ${({ theme }) => theme.colours.freezeButtonBackground};
    cursor: pointer;
  }

  & .Select__option--is-focused {
    background-color: ${({ theme }) => theme.colours.freezeButtonText};
    color: ${({ theme }) => theme.colours.freezeButtonBackground};
  }

  & .Select__option--is-selected {
    background-color: ${({ theme }) => theme.colours.freezeButtonText};
    color: ${({ theme }) => theme.colours.freezeButtonBackground};
  }

  & .Select__indicators {
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__placeholder {
    color: ${({ theme }) => theme.colours.freezeButtonText};
  }

  & .Select__menu-notice {
    color: ${({ theme }) => theme.colours.freezeButtonText};
  }

  position: absolute !important;
  width: ${({ theme }) => theme.sizing.freezeBtnWidth};
`;

export const FreezeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${({ theme }) => theme.sizing.btnHeight};

  flex-wrap: wrap;
`;

export const CrossIcon = styled(Icon)`
  height: ${({ theme }) => theme.fontSize.medium};
  min-height: ${({ theme }) => theme.fontSize.medium};
  z-index: 24;

  width: ${({ theme }) => theme.fontSize.medium};
  min-width: ${({ theme }) => theme.fontSize.medium};

  margin-right: -${({ theme }) => theme.spacing.paddingSM};

  &:hover {
    color: ${({ theme }) => theme.colours.error};
  }
`;
