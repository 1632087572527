import {
  GraphContainer,
  ProgressElement,
  TotalProgress,
} from "../minimalRunCard.styles";

export const PassPercentGraph = ({ passPercentage, progressPercentage }) => {
  return (
    <GraphContainer>
      <TotalProgress percent={progressPercentage}>
        {passPercentage === 0 ? null : (
          <ProgressElement type="passed" value={passPercentage} />
        )}
        {passPercentage === 100 ? null : (
          <ProgressElement type="failed" value={100 - passPercentage} />
        )}
      </TotalProgress>
    </GraphContainer>
  );
};
