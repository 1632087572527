import styled from "styled-components";
import { theme } from "../../theme/theme";

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing.gutter};
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Image = styled.img`
  display: block;
  max-height: 40px;
  width: auto;
  height: auto;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border: 1px solid ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};
  border-color: ${({ theme }) => theme.colours.background};
  background-color: ${({ theme }) => theme.colours.background};
  color: ${({ theme }) => theme.colours.mainText};

  svg {
    fill: ${({ theme }) => theme.colours.mainText};
    stroke: ${({ theme }) => theme.colours.mainText};
  }

  line {
    stroke: ${({ theme }) => theme.colours.mainText};
  }

  path {
    stroke: ${({ theme }) => theme.colours.mainText};
  }
`;

export const MultiRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  padding: 0px ${({ theme }) => theme.spacing.padding};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.background};

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 ${({ theme }) => theme.spacing.paddingLG}
      ${({ theme }) => theme.colours.background} inset !important;
    -webkit-text-fill-color: ${({ theme }) =>
      theme.colours.mainText} !important;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.error};
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const EmailElement = styled.input`
  border: none;
  color: ${({ theme }) => theme.colours.mainText};
  opacity: 1;
  background-color: ${({ theme }) => theme.colours.background};
  &:focus {
    border: none;
    outline: none;
  }
  input:invalid {
    color: ${({ theme }) => theme.colours.error},
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colours.altText};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colours.altText};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    opacity: 1;
  }
},
`;

export const TextElement = styled.input`
  border: none;
  color: ${({ theme }) => theme.colours.mainText};
  opacity: 1;
  background-color: ${({ theme }) => theme.colours.background};

  &:focus {
    border: none;
    outline: none;
  }
  input:invalid {
    color: ${({ theme }) => theme.colours.error},
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colours.placeHolderText};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colours.placeHolderText};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
},
`;

export const ReadOnlyElement = styled.span`
  border: none;
  text-align: left;
  color: ${({ theme }) => theme.colours.mainText};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  opacity: 1;
  background-color: ${({ theme }) => theme.colours.background};
`;

export const iconStyle = {
  width: theme.sizing.iconSize,
  height: theme.sizing.iconSize,
  opacity: 0.6,
};
