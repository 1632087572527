export const formatCurrency = (
  lang,
  currency = "USD",
  number,
  country = "US"
) => {
  if (number === undefined) return "loading";

  return new Intl.NumberFormat(`${lang}-${country}`, {
    style: "currency",
    currency: currency.toUpperCase(),
  }).format(number);
};
