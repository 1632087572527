import { PieChart, Pie, Cell } from "recharts";
import { useTranslation } from "react-i18next";
import {
  Container,
  GraphContainer,
  StatusTable,
  Section,
  GraphOuter,
} from "./scenariosSection.styles";
import { useApp } from "../../../../../context/app/app.provider";
import { Dot } from "../../../testEvolveLighthouse/latestRunCard/ScenariosSection/scenariosSection.styles";

export const ScenariosSection = ({ scenarioStatuses, scenariosChartData }) => {
  const { t } = useTranslation();
  const { isMobile } = useApp();
  return (
    <Container>
      <Section>
        <GraphOuter isMobile={isMobile}>
          <GraphContainer>
            <PieChart>
              <Pie
                data={scenariosChartData}
                cornerRadius={40}
                dataKey="value"
                data-test="scenarios-pie"
                radius={[10, 10, 0, 0]}
                innerRadius={68}
                outerRadius={80}
                paddingAngle={5}
              >
                <Cell
                  key={"cell-pass"}
                  fill={"url(#gradientPass"}
                  stroke="none"
                  radius={[10, 10, 10, 10]}
                  aria-label="pass-cell"
                />
                <Cell
                  key={"cell-fail"}
                  fill={"url(#gradientFail"}
                  stroke="none"
                  radius={[10, 10, 10, 10]}
                  aria-label="fail-cell"
                />
                <Cell
                  key={"cell-error"}
                  fill={"url(#gradientError"}
                  stroke="none"
                  radius={[10, 10, 10, 10]}
                  aria-label="error-cell"
                />
                <Cell
                  key={"cell-skipped"}
                  fill={"url(#gradientSkipped"}
                  stroke="none"
                  radius={[10, 10, 10, 10]}
                  aria-label="skipped-cell"
                />
                <Cell
                  key={"cell-new"}
                  fill={"url(#newPass"}
                  stroke="none"
                  radius={[10, 10, 10, 10]}
                  aria-label="new-pass-cell"
                />
              </Pie>
            </PieChart>
          </GraphContainer>
        </GraphOuter>
      </Section>
      <Section>
        <StatusTable data-test="scenarios-table">
          <thead>
            <tr>
              <th>{t("runView.testevolvevisual.colour")}</th>
              <th>{t("runView.testevolvevisual.status")}</th>
              <th>{t("runView.testevolvevisual.description")}</th>
              <th>{t("runView.testevolvevisual.count")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Dot colourCode={scenariosChartData[0].color} rotate="45" />
              </td>
              <td>{t("runView.testevolvevisual.passed.name")}</td>
              <td>{t("runView.testevolvevisual.passed.quickDescription")}</td>
              <td>{scenarioStatuses.passed}</td>
            </tr>
            <tr>
              <td>
                <Dot colourCode={scenariosChartData[1].color} rotate="45" />
              </td>
              <td>{t("runView.testevolvevisual.failed.name")}</td>
              <td>{t("runView.testevolvevisual.failed.quickDescription")}</td>
              <td>{scenarioStatuses.failed}</td>
            </tr>
            <tr>
              <td>
                <Dot colourCode={scenariosChartData[2].color} rotate="45" />
              </td>
              <td>{t("runView.testevolvevisual.error.name")}</td>
              <td>{t("runView.testevolvevisual.error.quickDescription")}</td>
              <td>{scenarioStatuses.error}</td>
            </tr>
            <tr>
              <td>
                <Dot colourCode={scenariosChartData[3].color} rotate="45" />
              </td>
              <td>{t("runView.testevolvevisual.skipped.name")}</td>
              <td>{t("runView.testevolvevisual.skipped.quickDescription")}</td>
              <td>{scenarioStatuses.skipped}</td>
            </tr>
            <tr>
              <td>
                <Dot colourCode={scenariosChartData[4].color} rotate="45" />
              </td>
              <td>{t("runView.testevolvevisual.new.name")}</td>
              <td>{t("runView.testevolvevisual.new.quickDescription")}</td>
              <td>{scenarioStatuses.new}</td>
            </tr>
          </tbody>
        </StatusTable>
      </Section>
    </Container>
  );
};
