import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useResendUserInvite = () => {
  const { post, status } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved) {
      createNotification(
        types.SUCCESS,
        "settings.organisation.successfulResend"
      );
    }
    if (status.isRejected) {
      createNotification(
        types.ERROR,
        "settings.organisation.unSuccessfulResend"
      );
    }
  }, [status.isRejected, status.isResolved]);

  const resendInvite = user =>
    post(ENDPOINTS.USER_RESEND_INVITE, { username: user });

  return {
    resendInvite,
  };
};
