import { useEffect, useState, useRef } from "react";
import { useFetch, useFormValidation, useTenant } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

export const useForgotPassword = () => {
  const formRef = useRef(null);
  const { tenant } = useTenant();

  const [token, setToken] = useState(null);
  const [username, setUsername] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const { post, status, origin, error, reset } = useFetch();
  const { reason, isValid, isInvalid, validateForm, resetValidation } =
    useFormValidation();

  useEffect(() => {
    if (status.isResolved && origin === ENDPOINTS.RESET_PASSWORD.path)
      setResetPasswordSuccess(true);
    if (status.isRejected) formRef.current?.reset();
  }, [status, origin]);

  useEffect(() => {
    if (isValid && token) {
      reset();

      const headers = {};
      if (!tenant) headers.tenantId = orgId;

      post(
        ENDPOINTS.RESET_PASSWORD,
        {
          username: username,
          captcha_token: token,
        },
        headers
      );
    }
  }, [isValid, token]);

  const resetPassword = token => {
    setToken(token);
    validateForm([{ name: "email", value: username, validate: ["email"] }]);
  };

  const resetFields = () => {
    if (error || isInvalid) {
      formRef.current?.reset();
      resetValidation();
      setUsername(null);
      reset();
    }
  };

  return {
    error,
    formRef,
    formError: {
      invalid: isInvalid,
      reason: reason,
    },
    setOrgId,
    resetFields,
    setUsername,
    resetPassword,
    resetPasswordSuccess,
    isLoading: status.isLoading,
  };
};
