import { useEffect } from "react";
import { useReports } from "./hooks/useReports";
import { ReportConfigList } from "./reportConfigList";
import { ReportForm } from "./ReportForm";
import { StyledCard, StyledCardWrapper } from "./reports.styles";

export const ReportsTab = () => {
  const { getReportConfigs, reportConfigs, deleteReportConfig, createReport } =
    useReports();

  useEffect(() => {
    getReportConfigs();
  }, []);

  return (
    <StyledCard>
      <StyledCardWrapper>
        <ReportForm reportConfigs={reportConfigs} createReport={createReport} />
        {reportConfigs?.length ? (
          <ReportConfigList
            reportConfigs={reportConfigs}
            deleteReport={deleteReportConfig}
          />
        ) : null}
      </StyledCardWrapper>
    </StyledCard>
  );
};
