import React from "react";
import { useTheme } from "../../../../../../context/theme/theme.provider";
import { ReactComponent as LogoDark } from "../../../../../../static/assets/logo-gradient-blue-jira-software.svg";
import { ReactComponent as LogoLight } from "../../../../../../static/assets/logo-gradient-white-jira-software.svg";

export const JiraLogo = ({ light, dark, className, ...rest }) => {
  const { theme } = useTheme();

  if (dark) return <LogoLight data-test="light-logo" {...rest} />;
  if (light) return <LogoDark data-test="dark-logo" {...rest} />;

  return theme === "dark" ? (
    <LogoLight className={className} {...rest} />
  ) : (
    <LogoDark className={className} {...rest} />
  );
};
