import { ACTIONS } from "../../../static/constants/actions";

export const AdminLogsReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.GET_ADMIN_LOGS_SUCCESS:
      return {
        ...state,
        adminLogs: payload,
      };
    default:
      return state;
  }
};
