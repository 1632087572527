import { useEffect } from "react";
import { ENDPOINTS } from "../../static/constants/routeConfig";
import { useFetch } from "../hooks";
import { useReleases } from "./releases.provider";

const DEFAULT = {
  STATUS: "open",
  SORT: "closeDate",
  PAGE: 1,
  PAGE_SIZE: 20,
  DIRECTION: "ASC",
};

export const useGetReleases = () => {
  const { status, get, body } = useFetch();
  const { appendReleases, setReleases } = useReleases();

  const getReleases = ({
    status = DEFAULT.STATUS,
    sort = DEFAULT.SORT,
    page = DEFAULT.PAGE,
    pageSize = DEFAULT.PAGE_SIZE,
    direction = DEFAULT.DIRECTION,
  }) => {
    get({
      ...ENDPOINTS.RELEASES,
      path: `${ENDPOINTS.RELEASES.path}?status=${status}&sort=${sort}:${direction}&page=${page}&pageSize=${pageSize}`,
    });
  };

  useEffect(() => {
    if (status.isResolved && body) {
      if (body.page === 1) setReleases(body);
      else appendReleases(body);
    }
  }, [status.isResolved, body]);

  return {
    getReleases,
    getReleasesLoading: status.isLoading,
    getReleasesError: status.isRejected,
  };
};
