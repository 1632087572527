import { memo, useEffect, useState } from "react";
import { useSubscriptionPlans } from "../../../../../context/subscriptionPlans/subscriptionPlans.provider";
import {
  StyledWrapper,
  PlansContainer,
  ButtonsContainer,
  SwitchButton,
  Column,
  Price,
  SectionTitle,
  StyledButton,
  StyledButtonIconContainer,
  TaxMessageContainer,
  PlanInformationContainer,
  SummaryContainer,
  ProductContainer,
} from "./modifySubscriptions.styles";
import {
  YEAR,
  BUSINESS,
  MONTH,
  ANNUALLY,
  MONTHLY,
  TEAM,
} from "../../../../../static/constants/constants";
import { useTranslation } from "react-i18next";
import { extractPlanData } from "./extractPlanData";
import { Button } from "../../../../../components";
import { ReactComponent as Cross } from "../../../../../static/assets/icon-cross.svg";
import { useConfig } from "../../../../../context/config/config.provider";
import { formatCurrency } from "./formatCurrency";
import { useModal } from "../../../../../context/modal/modal.provider";

export const ModifySubscriptionSectionUnMemo = ({
  country,
  subscriptionDetails,
  toggleUpdateSection,
}) => {
  let { period, subscription_id, max_seats } = subscriptionDetails;

  const [activePlan, setActivePlan] = useState(
    period === MONTH ? "month" : "year"
  );

  const [plansData, setPlansData] = useState(null);
  const [productSelected, setProductSelected] = useState(null);
  const { openModal, MODALS } = useModal();

  const { config } = useConfig();
  const { getSubscriptionPlans, subscriptionPlans } = useSubscriptionPlans();
  const { t } = useTranslation();

  period = period === MONTH ? "month" : "year";
  const hasSubscription =
    subscription_id === BUSINESS || subscription_id === TEAM;

  const openCreateSubscriptionModal = () =>
    openModal(MODALS.CREATE_SUBSCRIPTION, {
      currencyIsoCode: country.currencyIsoCode,
      productSelected: productSelected,
      activePlan: activePlan === YEAR ? ANNUALLY : MONTHLY,
      price: getUnformattedPrice(),
      country: country,
    });

  const openUpdateSubscriptionModal = () =>
    openModal(MODALS.UPDATE_SUBSCRIPTION, {
      productSelected: productSelected,
      currentPrice: formatPrice(
        plansData[subscription_id.toLowerCase()][period]?.price
      ),
      currentTax: getTax(
        plansData[subscription_id.toLowerCase()][period]?.price
      ),
      currentTotal: getTotal(
        plansData[subscription_id.toLowerCase()][period]?.price
      ),
      newPrice: formatPrice(productSelected.price),
      newTax: getTax(productSelected.price),
      newTotal: getTotal(productSelected.price),
      showTax: config.taxRates[country.code.toLowerCase()] > 0,
      currentMaxSeats: max_seats,
      newSeats: Number(productSelected.users),
      currentPeriod: period === YEAR ? ANNUALLY : MONTHLY,
      newPeriod: productSelected.interval === YEAR ? ANNUALLY : MONTHLY,
      country: country,
    });

  useEffect(() => {
    getSubscriptionPlans(country.currencyIsoCode);
  }, [country]);

  useEffect(() => {
    setPlansData(extractPlanData(subscriptionPlans));
  }, [subscriptionPlans]);

  useEffect(() => {
    if (productSelected && hasSubscription) openUpdateSubscriptionModal();
    if (productSelected && !hasSubscription) openCreateSubscriptionModal();
  }, [productSelected]);

  const formatPrice = price =>
    subscriptionPlans
      ? formatCurrency("en", country.currencyIsoCode, price)
      : t("loading");

  const getTax = price => {
    return subscriptionPlans && config.taxRates
      ? formatCurrency(
          "en",
          country.currencyIsoCode,
          (price * config.taxRates[country.code.toLowerCase()]) / 100
        )
      : t("loading");
  };

  const getTotal = price => {
    return subscriptionPlans && config.taxRates
      ? formatCurrency(
          "en",
          country.currencyIsoCode,
          (price * (config.taxRates[country.code.toLowerCase()] + 100)) / 100
        )
      : t("loading");
  };

  const getUnformattedPrice = () => {
    return productSelected ? productSelected.price : t("loading");
  };

  return (
    <StyledWrapper>
      <StyledButton data-test="crossBtn" onClick={toggleUpdateSection}>
        <StyledButtonIconContainer>
          <Cross />
        </StyledButtonIconContainer>
      </StyledButton>
      <ButtonsContainer data-test="btns-container">
        <SwitchButton
          data-test="switch-button-year"
          secondary={activePlan !== YEAR}
          onClick={() => setActivePlan(YEAR)}
        >
          {t("subscriptionUpdate.ANNUALLY")} -{" "}
          <i>{t("subscriptionUpdate.2freeMonths")}</i>
        </SwitchButton>
        <SwitchButton
          data-test="switch-button-month"
          secondary={activePlan !== "month"}
          onClick={() => setActivePlan("month")}
        >
          {t("subscriptionUpdate.MONTHLY")}
        </SwitchButton>
      </ButtonsContainer>
      <PlansContainer data-test="plans-container">
        <ProductContainer>
          <Column>
            <SectionTitle data-test="header-team">
              {t("subscriptionInfo.team")}
            </SectionTitle>
          </Column>
          <SummaryContainer>
            <h4>{t("subscriptionInfo.teamSummary")}</h4>
          </SummaryContainer>
          {plansData?.team ? (
            <PlanInformationContainer>
              <p>
                {t(plansData.team[activePlan]?.projects)}{" "}
                {t("subscriptionInfo.projects")}
              </p>
              <p>
                {t(plansData.team[activePlan]?.users)}{" "}
                {t("subscriptionInfo.users")}
              </p>
              <p>
                {t(plansData.team[activePlan]?.scenarios)}{" "}
                {t("subscriptionInfo.monthlyTests")}
              </p>
            </PlanInformationContainer>
          ) : null}
          <Column>
            <Price data-test="team-price">
              {plansData?.team &&
                formatPrice(plansData?.team[activePlan]?.price)}
            </Price>
            <Button
              data-test="btn-subscription-update-to-team"
              accent
              disabled={subscription_id === TEAM && period === activePlan}
              onClick={() =>
                setProductSelected({
                  ...plansData?.team[activePlan],
                  product: TEAM,
                })
              }
            >
              {t("subscriptionUpdate.Update")}
            </Button>
          </Column>
        </ProductContainer>
        <ProductContainer>
          <div>
            <SectionTitle data-test="header-subscription-business">
              {t("subscriptionUpdate.business")}
            </SectionTitle>
          </div>
          <SummaryContainer>
            <h4>{t("subscriptionInfo.businessSummary")}</h4>
          </SummaryContainer>
          {plansData?.business ? (
            <PlanInformationContainer>
              <ul>
                <li>
                  {t(plansData.business[activePlan]?.projects)}{" "}
                  {t("subscriptionInfo.projects")}
                </li>
                <li>
                  {t(plansData.business[activePlan]?.users)}{" "}
                  {t("subscriptionInfo.users")}
                </li>
                <li>
                  {t(plansData.business[activePlan]?.scenarios)}{" "}
                  {t("subscriptionInfo.monthlyTests")}
                </li>
              </ul>
            </PlanInformationContainer>
          ) : null}
          <Column>
            <Price data-test="business-price">
              {plansData?.business &&
                formatPrice(plansData?.business[activePlan]?.price)}
            </Price>
            <Button
              data-test="btn-subscription-update-to-business"
              accent
              onClick={() =>
                setProductSelected({
                  ...plansData?.business[activePlan],
                  product: BUSINESS,
                })
              }
              disabled={subscription_id === BUSINESS && period === activePlan}
            >
              {t("subscriptionUpdate.Update")}
            </Button>
          </Column>
        </ProductContainer>
      </PlansContainer>
      <TaxMessageContainer>
        {t("subscriptionUpdate.vatWillApply")}
      </TaxMessageContainer>
      <TaxMessageContainer>
        {t("subscriptionInfo.autoUpgradeMessage")}
      </TaxMessageContainer>
    </StyledWrapper>
  );
};

export const ModifySubscriptionSection = memo(
  ModifySubscriptionSectionUnMemo,
  (prev, curr) => {
    return (
      prev.country.code === curr.country.code &&
      prev.subscriptionDetails.period === curr.subscriptionDetails.period &&
      prev.subscriptionDetails.subscription_id ===
        curr.subscriptionDetails.subscription_id &&
      prev.subscriptionDetails.max_seats === curr.subscriptionDetails.max_seats
    );
  }
);
