import { createContext, useContext, useEffect, useReducer } from "react";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { ACTIONS } from "../../../../static/constants/actions";

const initialState = { runSharingToken: null };

const Context = createContext(initialState);
const useHook = () => useContext(Context);

export const sharingTokenReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_RUN_SHARING_TOKEN:
      return {
        ...state,
        runSharingToken: payload,
      };
    default:
      return state;
  }
};

const RunSharingTokenProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sharingTokenReducer, initialState);

  return (
    <Context.Provider value={{ ...state, dispatch }}>
      {children}
    </Context.Provider>
  );
};

const useRunSharingToken = () => {
  const history = useHistoryWithParams();

  const { runSharingToken, dispatch } = useHook();

  const setRunSharingToken = token =>
    dispatch({ type: ACTIONS.SET_RUN_SHARING_TOKEN, payload: token.token });

  const clearRunSharingToken = () =>
    dispatch({ type: ACTIONS.SET_RUN_SHARING_TOKEN, payload: null });

  useEffect(() => {
    history.listen(() => clearRunSharingToken());
  }, [window.location.pathname]);

  return {
    runSharingToken,
    setRunSharingToken,
    clearRunSharingToken,
  };
};

export { useRunSharingToken, RunSharingTokenProvider };
