import { Principle } from "./principle";
import {
  AccessibilityNavigation,
  LeftWidgetContainer,
} from "../testEvolveAccessiblityRunView.styles";
import { RunStatusSection } from "./runStatus";

export const LeftWidget = ({ run }) => (
  <LeftWidgetContainer>
    <RunStatusSection run={run} />
    <AccessibilityNavigation>
      {run?.accessibility.map(principle => (
        <Principle
          key={`acc-section-${principle.description}`}
          principle={principle}
        />
      ))}
    </AccessibilityNavigation>
  </LeftWidgetContainer>
);
