import { useEffect } from "react";
import { usePermissions } from "../../hooks";
import { findObjectDifference } from "../utils";
import { useOrganisationUsers } from "../organisationUsers.provider";

export const useAdminUsers = () => {
  const { isUserAdmin, isPartnerAdmin } = usePermissions();
  const { adminUsers, initialAdminUsers, users, dispatch } =
    useOrganisationUsers();

  const setAdminUsers = payload =>
    dispatch({ type: "SET_ADMIN_USERS", payload: payload });

  const setInitialAdminUsers = payload =>
    dispatch({ type: "SET_INITIAL_ADMIN_USERS", payload: payload });

  const extractIsUserAdmin = (users, user) => ({
    ...users,
    [user.id]: isUserAdmin(user) || isPartnerAdmin(user),
  });

  const setUpdatedAdminUsers = payload =>
    dispatch({ type: "SET_UPDATED_ADMIN_USERS", payload: payload });

  const extractAdminUsers = () => users?.reduce(extractIsUserAdmin, {});

  const toggleUserAdmin = usr => {
    const updatedUsers = { ...adminUsers, [usr.id]: !adminUsers[usr.id] };
    setAdminUsers(updatedUsers);
  };

  const checkForUpdatedUsers = () => {
    const updatedUsers = findObjectDifference(initialAdminUsers, adminUsers);
    setUpdatedAdminUsers(updatedUsers);
  };

  const extractAllUsers = () => {
    if (users && users.length) {
      const extractedUsers = extractAdminUsers();
      setAdminUsers(extractedUsers);
      setInitialAdminUsers(extractedUsers);
    }
  };

  useEffect(extractAllUsers, [users]);

  useEffect(checkForUpdatedUsers, [adminUsers, initialAdminUsers]);

  return {
    adminUsers,
    toggleUserAdmin,
    extractAdminUsers,
    isUserAdmin: user => adminUsers[user.id],
  };
};
