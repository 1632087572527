import styled from "styled-components/macro";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.background};
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  gap: ${({ theme }) => theme.spacing.paddingLG};

  border: ${({ theme }) =>
    theme.isDark ? `4px solid ${theme.colours.foreground}` : null};

  width: 100%;
`;

export const StyledTitle = styled.h1``;

export const StyledSecondaryText = styled.p`
  height: ${({ theme }) => theme.spacing.paddingMD};
`;

export const StyledProductCard = styled.div`
  cursor: pointer;
  display: flex;
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  width: ${({ theme }) => theme.sizing.downloadProductCard};
  gap: ${({ theme }) => theme.spacing.paddingMD};

  background-color: ${({ theme }) => theme.colours.foreground};
  outline: ${({ theme, selected }) =>
    selected ? `1px solid ${theme.colours.haloAccent}` : null};
  border: ${({ theme }) =>
    theme.isDark ? `4px solid ${theme.colours.foreground}` : null};

  align-items: center;

  svg {
    height: 96px;
  }
`;

export const OSCardContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingLG};
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
`;

export const OSCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme }) => theme.colours.altForeground};
  padding: ${({ theme }) => theme.spacing.paddingLG};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  width: ${({ theme }) => theme.sizing.downloadOsCode};
  min-width: ${({ theme }) => theme.sizing.downloadOsCode};

  background-color: ${({ theme }) => theme.colours.foreground};
  outline: ${({ theme, selected }) =>
    selected ? `1px solid ${theme.colours.haloAccent}` : null};
  border: 4px solid ${({ theme }) => theme.colours.foreground};
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const DownloadButton = styled.button`
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.paddingLG};
  background-color: ${({ theme }) => theme.colours.haloAccent};
  border-radius: ${({ theme }) => theme.borders.radius};
  cursor: pointer;
  box-shadow: none;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colours.white};
`;

export const IconContainer = styled.div`
  height: 96px;
  color: ${({ theme }) => theme.colours.mainText};
  svg {
    height: 100%;
  }
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${({ theme }) => theme.spacing.padding};
`;
