import { memo, useState } from "react";
import { useApp } from "../../../../context/app/app.provider";
import { Modal } from "../../../index";
import {
  HeadingTextContainer,
  HorizontalContainer,
  InputContainer,
  LinkContainer,
  MobileSelectionContainer,
  PlayerContainer,
  Spacer,
  VideoLinkContainer,
  VideoScreenContainer,
  VideoTopBar,
} from "./firstLoginModal.styles";
import ReactPlayer from "react-player/youtube";
import { StyledCheckbox } from "../../../checkbox/checkbox.styles";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { useTheme } from "../../../../context/theme/theme.provider";
import { useUser } from "../../../../context/user/user.provider";

const VideoLink = ({ title, name, url, setState }) => (
  <VideoLinkContainer onClick={() => setState({ name, url, title })}>
    <img src={`images/${name}.png`} alt={title} />
  </VideoLinkContainer>
);

const FirstLoginModalUnMemo = ({ closeModal }) => {
  const { isMobile } = useApp();
  const { isDark } = useTheme();
  const [selectedVideo, setSelectedVideo] = useState(false);
  const [contentToShow, setContentToShow] = useState(false);
  const { t } = useTranslation();
  const { toggleGuideOpen } = useUser();

  const toggleModal = () => {
    toggleGuideOpen();
    closeModal();
  };

  const adminVideos = [
    {
      name: "managing_projects",
      url: "https://www.youtube.com/watch?v=l40HZOVXzGY",
    },
    {
      name: "managing_org_users",
      url: "https://www.youtube.com/watch?v=7xovM5Hm6wk",
    },
    {
      name: "managing_project_permissions",
      url: "https://www.youtube.com/watch?v=M9OD7CeBkIc",
    },
    {
      name: "organising_nav",
      url: "https://www.youtube.com/watch?v=ZqppSPZu2Ao",
    },
    {
      name: "creating_api_keys",
      url: "https://www.youtube.com/watch?v=qKMA5FtyATg",
    },
  ];

  const pmVideos = [
    {
      name: "finding_test_results",
      url: "https://www.youtube.com/watch?v=9EarNY49TV0",
    },
  ];

  const devVideos = [
    {
      name: "installing_flare",
      url: "https://www.youtube.com/watch?v=Qr4I_eYRQjA",
    },
    { name: "first_test", url: "https://www.youtube.com/watch?v=jz0ee2DUcy8" },
    {
      name: "results_to_halo",
      url: "https://www.youtube.com/watch?v=IJ_DK7J6Xno",
    },
    {
      name: "understanding_run_groups",
      url: "https://www.youtube.com/watch?v=c8LkTF0yqiI",
    },
    {
      name: "raising_bugs",
      url: "https://www.youtube.com/watch?v=ZARuiadFuQg",
    },
  ];

  return (
    <Modal
      title={selectedVideo ? null : t("guide.title")}
      text={selectedVideo ? null : t("guide.supportText")}
      secondary={{
        onClick: selectedVideo ? () => setSelectedVideo(false) : toggleModal,
        text: selectedVideo ? t("btnBack") : t("btnExit"),
      }}
    >
      {selectedVideo ? (
        <VideoScreen {...selectedVideo} />
      ) : (
        <>
          <HorizontalContainer>
            {isMobile ? (
              <MobileSelectionContainer>
                <p>{t("guide.ima")}</p>
                <form onChange={e => setContentToShow(e.target.value)}>
                  <InputContainer checked={contentToShow === "0"}>
                    <StyledCheckbox
                      type="radio"
                      id="admin"
                      name="contentPicker"
                      value={0}
                      checked={contentToShow === "0"}
                      dark={isDark}
                    />
                    <label htmlFor="admin">{t("guide.administrator")}</label>
                  </InputContainer>
                  <InputContainer checked={contentToShow === "1"}>
                    <StyledCheckbox
                      type="radio"
                      id="smthg"
                      name="contentPicker"
                      value={1}
                      checked={contentToShow === "1"}
                      dark={isDark}
                    />
                    <label htmlFor="smthg">{t("guide.pm")}</label>
                  </InputContainer>
                  <InputContainer checked={contentToShow === "2"}>
                    <StyledCheckbox
                      type="radio"
                      id="dev"
                      name="contentPicker"
                      value={2}
                      checked={contentToShow === "2"}
                      dark={isDark}
                    />
                    <label htmlFor="dev">{t("guide.dev")}</label>
                  </InputContainer>
                </form>
              </MobileSelectionContainer>
            ) : (
              <>
                <HeadingTextContainer>
                  <p>
                    <Trans i18nKey="guide.adminDescription" />
                  </p>
                </HeadingTextContainer>
                <HeadingTextContainer>
                  <p>
                    <Trans i18nKey="guide.pmDescription" />
                  </p>
                </HeadingTextContainer>
                <HeadingTextContainer>
                  <p>
                    <Trans i18nKey="guide.devDescription" />
                  </p>
                </HeadingTextContainer>
              </>
            )}
          </HorizontalContainer>
          <HorizontalContainer>
            {contentToShow === "0" || !isMobile ? (
              <LinkContainer isMobile={isMobile}>
                {adminVideos.map(({ name, url }) => (
                  <VideoLink
                    key={name}
                    title={t(`guide.${name}`)}
                    name={name}
                    setState={setSelectedVideo}
                    url={url}
                  />
                ))}
              </LinkContainer>
            ) : null}
            {contentToShow === "1" || !isMobile ? (
              <LinkContainer isMobile={isMobile}>
                {pmVideos.map(({ name, url }) => (
                  <VideoLink
                    key={name}
                    title={t(`guide.${name}`)}
                    name={name}
                    setState={setSelectedVideo}
                    url={url}
                  />
                ))}
              </LinkContainer>
            ) : null}
            {contentToShow === "2" || !isMobile ? (
              <LinkContainer isMobile={isMobile}>
                {devVideos.map(({ name, url }) => (
                  <VideoLink
                    key={name}
                    title={t(`guide.${name}`)}
                    name={name}
                    setState={setSelectedVideo}
                    url={url}
                  />
                ))}
              </LinkContainer>
            ) : null}
          </HorizontalContainer>
          <Spacer />
        </>
      )}
    </Modal>
  );
};

const VideoScreen = ({ title, url }) => (
  <VideoScreenContainer>
    <VideoTopBar>
      <h1>{title}</h1>
      <p></p>
    </VideoTopBar>
    <PlayerContainer>
      <ReactPlayer
        className="react-player"
        url={url}
        height="100%"
        width="100%"
        controls={true}
        playing
      />
    </PlayerContainer>
  </VideoScreenContainer>
);

export const FirstLoginModal = memo(FirstLoginModalUnMemo);
