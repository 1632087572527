import { useTranslation } from "react-i18next";
import { theme } from "../../../../../theme/theme";
import { useTime } from "../../../../../context/hooks/time/useTime";
import {
  RUN_PROPERTIES,
  STATUSES,
} from "../../../../../static/constants/constants";

export const useLatestRun = run => {
  const { t } = useTranslation();
  const { getReadableTimeDifference } = useTime();

  const status = run.status;
  const runStatus = t("runCard.testevolvevisual.status.complete") || "N/A";

  const timeAgo =
    `${getReadableTimeDifference(run?.finish_time ?? run?.updated_time)} ${t(
      "time.ago"
    )}` || "N/A";
  const checksPerformed = run?.visual_summary?.checksPerformed;
  const totalFailedChecks = run?.visual_summary?.totalFailedChecks;
  const checkLabels = run?.visual_summary?.checkLabels;

  const scenarioStatuses = {
    passed: run?.passed,
    failed: run?.failed,
    error: run?.error,
    skipped: run?.skipped,
    new: run?.new_baseline,
  };

  const totalScenarios =
    (run.failed + run.pending + run.passed + run.error) / 100;

  const scenariosChartData = [
    {
      name: t("runCard.testevolvevisual.passed"),
      value: run?.passed / totalScenarios,
      color: theme.colours.success,
    },
    {
      name: t("runCard.testevolvevisual.failed"),
      value: run?.failed / totalScenarios,
      color: theme.colours.severity.high,
    },
    {
      name: t("runCard.testevolvevisual.error"),
      value: run?.error / totalScenarios,
      color: theme.colours.severity.highest,
    },
    {
      name: t("runCard.testevolvevisual.skipped"),
      value: run?.skipped / totalScenarios,
      color: theme.colours.severity.info,
    },
    {
      name: t("runCard.testevolvevisual.new"),
      value: run?.new_baseline / totalScenarios,
      color: theme.colours.severity.new,
    },
  ];

  const totalChecks = run?.visual_summary?.checksPerformed;
  const failedChecks = run?.visual_summary?.totalFailedChecks;
  const passedChecks = totalChecks - failedChecks;

  const passedPercentage = (passedChecks / totalChecks) * 100;

  const data = [
    {
      name: "Passed",
      value: passedChecks,
      color: "url(#gradientPass)",
    },
    {
      name: "Failed",
      value: failedChecks,
      color: "url(#gradientFail)",
    },
  ];

  const scenarios = run?.features?.map(feature => feature.scenarios).flat();

  const failedScenarios = scenarios?.filter(scenario => {
    return scenario.status === STATUSES.FAILED;
  });
  const errorScenarios = scenarios?.filter(scenario => {
    return scenario.status === STATUSES.ERROR;
  });
  const passedScenarios = scenarios?.filter(scenario => {
    return scenario.status === STATUSES.PASSED;
  });
  const skippedScenarios = scenarios?.filter(scenario => {
    return scenario.status === STATUSES.SKIPPED;
  });
  const newScenarios = scenarios?.filter(scenario => {
    return scenario.status === STATUSES.NEW;
  });

  const runProps = RUN_PROPERTIES[run.test_type]?.map(prop => [
    run.run_properties[prop],
    prop,
  ]);

  return {
    run,
    data,
    status,
    timeAgo,
    runProps,
    runStatus,
    checkLabels,
    newScenarios,
    errorScenarios,
    failedScenarios,
    passedScenarios,
    checksPerformed,
    skippedScenarios,
    scenarioStatuses,
    passedPercentage,
    totalFailedChecks,
    scenariosChartData,
    startTime: run?.start_time,
  };
};
