import styled from "styled-components";
import CreatableSelect from "react-select/creatable";

export const StyledCreatable = styled(CreatableSelect)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colours.background};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  outline: ${({ theme, error }) =>
    error ? `1px solid ${theme.colours.error}` : ""};

  & .Select__control {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    box-shadow: none;

    &:hover {
      border-color: ${({ theme, error }) =>
        error ? theme.colours.error : theme.colours.haloAccentLight};
    }
  }

  & .Select__control--is-focused {
    border-color: ${({ theme, error }) =>
      error ? theme.colours.error : theme.colours.haloAccentLight};
  }

  & .Select__menu {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__single-value {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .Select__multi-value {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value__label {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value__remove {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    border-top-right-radius: ${({ theme }) => theme.borders.radius};
    border-bottom-right-radius: ${({ theme }) => theme.borders.radius};
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__option {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
  }

  & .Select__option--is-focused {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__option--is-selected {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__indicators {
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }
  z-index: 90;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  text-align: ${props => (props.align ? props.align : "left")};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
`;
