import { useEffect, useState } from "react";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useFetch } from "../../../context/hooks";

export const useGetLabelReleases = () => {
  const { status, get, body } = useFetch();
  const [labelReleases, setLabelReleases] = useState([]);

  const getLabelReleases = label => {
    get({
      ...ENDPOINTS.RELEASES,
      path: `${ENDPOINTS.RELEASES.path}?label=${encodeURI(
        encodeURIComponent(label)
      )}`,
    });
  };

  useEffect(() => {
    if (status.isResolved && body) setLabelReleases(body.releases);
  }, [status.isResolved, body]);

  return {
    getLabelReleases,
    labelReleases,
    labelReleasesSuccess: status.isResolved,
  };
};
