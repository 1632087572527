import styled from "styled-components";

export const BigNumbersContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const BigNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.padding};

  h1 {
    font-size: ${({ theme }) => theme.fontSize.largester};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    text-align: center;
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-align: center;
  }
`;

export const SmallNumbersContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: 0 ${({ theme }) => theme.spacing.padding};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SameRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
`;

export const SmallNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.bulletColour};
  border: 1px solid ${({ theme }) => theme.colours.bulletColour};

  h1 {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    text-align: center;
    color: inherit;
  }

  svg {
    height: ${({ theme }) => theme.sizing.iconSmall};
    width: auto;
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-align: center;
  }
`;

export const SmallerNumbersContainer = styled.div`
  display: flex;

  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.paddingSM};

  p {
    text-align: center;
  }
`;

export const SmallerNumberContainer = styled.div`
  height: ${({ theme }) => theme.sizing.runDetailMinHeight};
  width: ${({ theme }) => theme.sizing.runDetailMinHeight};
  display: flex;

  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${({ theme }) => theme.colours.bulletColour};

  p {
    text-align: center;
    vertical-align: middle;
    font-size: ${({ theme }) => theme.fontSize.larger};
    font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  }

  border-radius: 100%;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};

  background: ${({ theme, probact, risk }) => `linear-gradient(${
    theme.colours.foreground
  }, ${theme.colours.foreground}) padding-box,
    linear-gradient(to right, ${
      (probact == 1 &&
        `${theme.gradients.passing_start}, ${theme.gradients.passing_end}`) ||
      (probact == 2 &&
        `${theme.gradients.moderate_start}, ${theme.gradients.moderate_end}`) ||
      (probact == 3 &&
        `${theme.gradients.error_start}, ${theme.gradients.error_end}`) ||
      (risk > 6 &&
        `${theme.gradients.error_start}, ${theme.gradients.error_end}`) ||
      (risk < 4 &&
        `${theme.gradients.passing_start}, ${theme.gradients.passing_end}`) ||
      `${theme.gradients.moderate_start}, ${theme.gradients.moderate_end}`
    }) border-box`};
  border: 2px solid transparent;
`;

export const WordContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  h2 {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.medium};
  }
`;

export const StatusStickerContainer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.sizing.runDetailMinHeight};
`;
