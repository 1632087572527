import { useCreateRelease } from "../../../releases/createRelease/useCreateRelease";
import { ReleaseModal } from "../../../releases/common/releaseModal";
import { RELEASE_OBJ } from "../../../../static/constants/constants";

const initial = {
  [RELEASE_OBJ.NAME]: "",
  [RELEASE_OBJ.DESCRIPTION]: "",
  [RELEASE_OBJ.OPEN_DATE]: null,
  [RELEASE_OBJ.CLOSE_DATE]: null,
};

export const CreateReleaseModal = ({ closeModal, ...props }) => {
  const createReleaseHook = useCreateRelease(closeModal);

  return (
    <ReleaseModal
      {...props}
      initial={initial}
      toggleModal={closeModal}
      hook={createReleaseHook}
    />
  );
};
