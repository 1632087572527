import { useState } from "react";
import { useTree } from "../../../../context/tree/tree.provider";
import { assets } from "../../../../static/assets/assets";
import {
  EntryContainer,
  HierarchyContainer,
  NodeEntry,
  RightChevron,
} from "../../../../views/nodeView/nodeView.styles";
import { Modal } from "../../modal";
import { useMoveRun } from "../../../../views/nodeView/hooks/useMoveRun";
import { useTranslation } from "react-i18next";

export const MoveRunModal = ({ closeModal, run, nodeSummary }) => {
  const { tree } = useTree();
  const { moveRun } = useMoveRun();
  const { t } = useTranslation();

  const [open, setOpen] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  const openAndSelectNode = node => {
    if (node.children && node.children.length) {
      if (isOpen(node))
        setOpen(prevOpen => prevOpen.filter(n => n !== node.id));
      else setOpen(prevOpen => [...prevOpen, node.id]);
    }
    setSelectedNode(node);
  };

  const isOpen = node => open.includes(node.id);

  const isSelected = node => selectedNode?.id === node.id;

  const NodeComponent = ({ node }) => (
    <EntryContainer>
      <NodeEntry
        onClick={() => openAndSelectNode(node)}
        selected={isSelected(node)}
        key={`node-entry-${node.name}`}
        level={node.level}
      >
        {node.children?.length ? (
          <RightChevron
            {...assets.icons.navChevron}
            isOpen={isOpen(node)}
            active={isSelected(node)}
          />
        ) : null}
        {node.name}
      </NodeEntry>
      {node.children && isOpen(node)
        ? node.children.map(child => (
            <NodeComponent
              key={`child-node-component-${node.name}`}
              node={child}
            />
          ))
        : null}
    </EntryContainer>
  );

  return (
    <Modal
      title={t("nodeView.moveRun")}
      text={t("nodeView.moveRunText")}
      primary={{
        text: t("btnConfirm"),
        onClick: () => moveRun(nodeSummary, run, selectedNode.id),
        disabled: selectedNode === null,
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <HierarchyContainer>
        {tree.map(node => (
          <NodeComponent key={`node-component-${node.name}`} node={node} />
        ))}
      </HierarchyContainer>
    </Modal>
  );
};
