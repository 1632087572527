import { useEffect, useState } from "react";
import { useFetch } from "../../../../../context/hooks/fetch/useFetch";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { DateTime } from "luxon";
import { useNotifications } from "../../../../../context/hooks";
import { DATE_FORMAT_YYYMMDD } from "../../../../../static/constants/constants";

export const useReportPreview = () => {
  const [reportPreview, setReportPreview] = useState({});

  const { post, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();

  const getPreview = payload => {
    let startDate;
    if (payload.start_date) {
      startDate = DateTime.fromJSDate(payload.start_date);
    } else {
      startDate = new DateTime({});
    }

    const body = {
      name: payload.name,
      labels: payload.labels,
      frequency: payload.frequency,
      start_date: startDate.toFormat(DATE_FORMAT_YYYMMDD),
      time_zone: startDate.zoneName,
      distribution_emails: payload.distribution_emails,
    };
    post(ENDPOINTS.REPORT_PREVIEW, body);
  };

  const clearReportPreview = () => setReportPreview({});

  useEffect(() => {
    if (status.isResolved && body) setReportPreview(body);
    if (status.isRejected)
      createNotification(types.ERROR, "errors.reports.previewError");
  }, [status.isRejected, status.isResolved, body, error]);

  return {
    reportPreview,
    getPreview,
    clearReportPreview,
  };
};
