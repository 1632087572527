import { ACTIONS } from "../../static/constants/actions";

export const projectReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.MODIFY_PROJECT: {
      let currentProject = state.project;
      if (currentProject === payload.id) currentProject = payload;

      const index = state.projects.findIndex(
        p => p.id.toString() === payload.id.toString()
      );
      const currentProjects = state.projects.filter(
        p => p.id.toString() !== payload.id.toString()
      );
      currentProjects.splice(index, 0, payload);

      return {
        ...state,
        project: state.project ? currentProject : null,
        projects: currentProjects,
      };
    }
    case ACTIONS.SET_PROJECT:
      return {
        ...state,
        project: state.projects.find(
          p => p.id.toString() === payload.toString()
        ),
      };
    case ACTIONS.SET_PROJECTS:
      return {
        ...state,
        projects: payload,
      };
    case ACTIONS.REMOVE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter(prj => prj.id !== payload),
      };
    case ACTIONS.CLEAR_PROJECTS:
      return {
        ...state,
        projects: [],
      };
    case ACTIONS.CLEAR_PROJECT:
      return {
        ...state,
        project: null,
      };
    default:
      return state;
  }
};
