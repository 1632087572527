import { navReducer } from "./nav.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { useApp } from "../app/app.provider";
import { useEffect } from "react";

const initialState = { navIsOpen: false, open: [] };

const [useHook, NavProvider] = createState("nav", initialState, navReducer);

const useNavOpen = () => {
  const { navIsOpen, open, dispatch } = useHook();
  const { isNotDesktop } = useApp();

  const openNav = () => dispatch({ type: ACTIONS.OPEN_NAV });

  const closeNav = () => dispatch({ type: ACTIONS.CLOSE_NAV });

  const togglePane = () => (navIsOpen ? closeNav() : openNav());

  const setOpen = id => dispatch({ type: ACTIONS.SET_OPEN, payload: id });

  const setClosed = id => dispatch({ type: ACTIONS.SET_CLOSED, payload: id });

  useEffect(() => {
    if (!isNotDesktop) openNav();
  }, []);

  return {
    open,
    setOpen,
    openNav,
    closeNav,
    navIsOpen,
    setClosed,
    togglePane,
  };
};

export { useNavOpen, NavProvider };
