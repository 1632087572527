import styled from "styled-components";

export const ScenarioCard = styled.tr`
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  background-color: ${({ theme }) => theme.colours.foreground};
  color: ${({ theme }) => theme.colours.mainText};

  p {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ScenarioNameData = styled.td`
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing.paddingSM};
  height: ${({ theme }) => theme.sizing.scenarioNameHeight};
`;

export const ChecksSticker = styled.div`
  display: flex;
  flex-direction: row;
  min-width: ${({ theme }) => theme.sizing.checksStickerWidth};
  height: ${({ theme }) => theme.sizing.checksStickerHeight};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  margin: ${({ theme }) => theme.spacing.paddingXSM};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  align-items: center;

  h1 {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  p {
    text-align: right;
  }
`;

export const FailedChecks = styled.p`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
`;
