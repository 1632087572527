import { useEffect, useState } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useProject } from "../../../../context/project/project.provider";
import { RUN_STATUSES } from "../../../../static/constants/constants";

export const useRunInsights = (run, isShared, isMostRecent) => {
  const { project } = useProject();
  const { get, body, status } = useFetch();
  const [runInsights, setRunInsights] = useState({});
  const encodedLabel = encodeURI(run.label);

  const getRunInsights = () =>
    get({
      path: ENDPOINTS.GET_RUN_INSIGHTS.path
        .replace(":projectId", project.id)
        .replace(":label", encodedLabel)
        .replace(":category", run.category),
    });

  useEffect(() => {
    if (status.isResolved && body) setRunInsights(body);
  }, [status.isResolved, status.isRejected, status.isLoading, body]);

  useEffect(() => {
    if (!isShared && isMostRecent && run.status === RUN_STATUSES.COMPLETE)
      getRunInsights();
  }, [run.status, isShared, isMostRecent]);

  return {
    runInsights,
    getRunInsights,
  };
};
