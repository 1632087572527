export const extractPlanData = subscriptionPlans => {
  if (subscriptionPlans.products && subscriptionPlans.products.length) {
    const products = Object.fromEntries(
      subscriptionPlans.products.map(product => [
        [product.kyuri_id],
        Object.fromEntries(
          product.plans.map(plan => [
            [plan.interval],
            { ...product, ...plan, plans: null },
          ])
        ),
      ])
    );
    return products;
  }
  return {};
};
