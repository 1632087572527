import { useTranslation } from "react-i18next";
import {
  ArrowContainer,
  PropertiesContainer,
  RunPropertiesContainer,
  RunPropertyCell,
  RunPropertyHeading,
} from "../runInsights.styles";
import { filterRunProperties } from "../utils";
import {
  Heading,
  ValContainer,
} from "../../../../../nodeView/nodeCardContainer/nodeCardTestEvolve/testTypes/testTypes.styles";
import { useTestTypeIcon } from "../../../../../nodeView/nodeCardContainer/nodeCardTestEvolve/hooks/useTestTypeIcon";

export const RunPropertiesSection = ({ runInsights }) => {
  const { t } = useTranslation();
  const { returnIcon } = useTestTypeIcon();
  const uniqueProperties = filterRunProperties(
    runInsights.previousRunProperties,
    runInsights.latestRunProperties
  );

  return (
    <>
      <RunPropertyHeading>
        <h2>{t("runInsights.previousRunProperties")}</h2>
        <ArrowContainer>&#10230;</ArrowContainer>
        <h2>{t("runInsights.latestRunProperties")}</h2>
      </RunPropertyHeading>
      <RunPropertiesContainer>
        {uniqueProperties.length ? (
          uniqueProperties?.map(rp => {
            return (
              <RunPropertyCell key={`${rp}-insights`}>
                <Heading>{t(`runProperties.${rp}`)}</Heading>
                <PropertiesContainer>
                  <ValContainer>
                    {returnIcon(runInsights.previousRunProperties[rp], rp)}
                    {runInsights.previousRunProperties[rp]}
                  </ValContainer>
                  <ArrowContainer>&#10230;</ArrowContainer>
                  <ValContainer>
                    {returnIcon(runInsights.latestRunProperties[rp], rp)}
                    {runInsights.latestRunProperties[rp]}
                  </ValContainer>
                </PropertiesContainer>
              </RunPropertyCell>
            );
          })
        ) : (
          <p>{t("runInsights.noChange")}</p>
        )}
      </RunPropertiesContainer>
    </>
  );
};
