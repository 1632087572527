import { useTranslation } from "react-i18next";
import { ComparisonSelect, SelectContainer } from "../runInsights.styles";

const AVERAGE = "average";
const PREVIOUS = "previous";

export const Select = ({ setStatisticToggle, statisticToggle }) => {
  const { t } = useTranslation();

  return (
    <SelectContainer>
      <h2>{t("runInsights.compareTo")}</h2>
      <ComparisonSelect
        value={statisticToggle}
        onChange={e => setStatisticToggle(e.target.value)}
      >
        <option value={AVERAGE}>{t("runInsights.average")}</option>
        <option value={PREVIOUS}>{t("runInsights.previous")}</option>
      </ComparisonSelect>
    </SelectContainer>
  );
};
