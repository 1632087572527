import styled from "styled-components";

export const StyledCheckbox = styled.input`
  position: relative;
  width: 1.5em;
  height: 1.5em;
  color: ${({ theme }) => theme.colours.haloAccent};
  border: 1px solid
    ${({ theme, dark }) => (dark ? theme.colours.white : theme.colours.black)};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  &::before {
    position: absolute;
    content: "";
    display: block;
    top: ${({ theme }) => theme.sizing.checkbox.top};
    left: ${({ theme }) => theme.sizing.checkbox.left};
    width: ${({ theme }) => theme.sizing.checkbox.beforeWidth};
    height: ${({ theme }) => theme.sizing.checkbox.beforeHeight};
    border-style: solid;
    border-color: ${({ theme }) => theme.colours.white};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: ${({ theme }) => theme.colours.white};
    border-color: ${({ theme }) => theme.colours.haloAccent};
    background: ${({ theme }) => theme.colours.haloAccent};
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  &:disabled {
    opacity: 0.6;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`;
