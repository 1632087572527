import { em } from "polished";
import { css } from "styled-components";
import { BREAKPOINTS } from "../../theme/constants";

const DEFAULT_BREAKPOINTS = {
  XS: em(BREAKPOINTS.XS),
  SM: em(BREAKPOINTS.SM),
  MD: em(BREAKPOINTS.MD),
  LG: em(BREAKPOINTS.LG),
  XL: em(BREAKPOINTS.XL),
  MIN_HEIGHT: em(BREAKPOINTS.MIN_HEIGHT),
};

const getSizeFromBreakpoint = (breakpointValue, breakpoints) => {
  if (breakpoints[breakpointValue]) {
    return breakpoints[breakpointValue];
  } else if (parseInt(breakpointValue)) {
    return breakpointValue;
  } else {
    throw new Error(
      "styled-media-query: No valid breakpoint or size specified for media."
    );
  }
};

const generateMedia = (breakpoints = DEFAULT_BREAKPOINTS) => {
  const lessThan =
    breakpoint =>
    (...args) =>
      css`
        @media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
          ${css(...args)};
        }
      `;

  const greaterThan =
    breakpoint =>
    (...args) =>
      css`
        @media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
          ${css(...args)};
        }
      `;

  const heightLessThan =
    breakpoint =>
    (...args) =>
      css`
        @media (max-height: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
          ${css(...args)};
        }
      `;

  const heightGreaterThan =
    breakpoint =>
    (...args) =>
      css`
        @media (min-height: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
          ${css(...args)};
        }
      `;

  return {
    lessThan,
    greaterThan,
    heightLessThan,
    heightGreaterThan,
  };
};

export const mediaQuery = generateMedia();
