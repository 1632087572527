import { useEffect, useState } from "react";
import { useFetch } from "../../../context/hooks";
import { useNotifications } from "../../../context/hooks/useNotifications/useNotifications";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useModal } from "../../../context/modal/modal.provider";
import { useProject } from "../../../context/project/project.provider";

export const useRenameProject = project => {
  const { put, error, status, body } = useFetch();
  const { openModal, MODALS, closeModal } = useModal();
  const { createNotification, types } = useNotifications();
  const { editProject } = useProject();

  const [nameInput, setNameInput] = useState("");
  const [renameProjectSuccess, setRenameProjectSuccess] = useState(false);

  const openRenameModal = e => {
    e.syntheticEvent.stopPropagation();
    openModal(MODALS.RENAME_PROJECT, { project });
  };

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(types.SUCCESS, "success.renameProject");
      setRenameProjectSuccess(true);
      editProject(body);
      closeModal();
    }
    if (status.isRejected) {
      createNotification(types.ERROR, `errors.renameProject.${error.code}`);
      setNameInput("");
    }
  }, [status.isResolved, status.isRejected, error, body]);

  const renameProject = input => {
    put(
      {
        ...ENDPOINTS.RENAME_PROJECT,
        path: ENDPOINTS.RENAME_PROJECT.path.replace(":id", project.id),
      },
      { name: input }
    );
  };

  return {
    renameProject,
    setNameInput,
    closeModal,
    nameInput,
    openRenameModal,
    renameProjectSuccess,
    renameProjectFailure: status.isRejected,
    setRenameProjectSuccess,
  };
};
