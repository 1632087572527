import { releasesReducer } from "./releases.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";

const initialState = {
  releases: [],
  release: {},
  allOpenReleases: [],
};

const [useHook, ReleasesProvider] = createState(
  "releases",
  initialState,
  releasesReducer
);

const useReleases = () => {
  const { releases, allOpenReleases, release, dispatch, ...state } = useHook();

  const setReleases = releases =>
    dispatch({ type: ACTIONS.SET_RELEASES, payload: releases });

  const setAllOpenReleases = releases =>
    dispatch({ type: ACTIONS.SET_ALL_OPEN_RELEASES, payload: releases });

  const appendReleases = releases =>
    dispatch({ type: ACTIONS.APPEND_RELEASES, payload: releases });

  const addRelease = release =>
    dispatch({ type: ACTIONS.ADD_RELEASE, payload: release });

  const removeRelease = release =>
    dispatch({ type: ACTIONS.REMOVE_RELEASE, payload: release });

  const modifyRelease = (id, release) =>
    dispatch({ type: ACTIONS.MODIFY_RELEASE, payload: { id, release } });

  const clearReleases = () => dispatch({ type: ACTIONS.CLEAR_RELEASES });

  const setRelease = release =>
    dispatch({ type: ACTIONS.SET_RELEASE, payload: release });

  const modifyReleaseLabels = (release, labels) =>
    dispatch({
      type: ACTIONS.MODIFY_RELEASE_LABELS,
      payload: { release, labels },
    });

  return {
    releases,
    addRelease,
    modifyRelease,
    setReleases,
    clearReleases,
    removeRelease,
    appendReleases,
    modifyReleaseLabels,
    setAllOpenReleases,
    allOpenReleases,
    setRelease,
    release,
    ...state,
  };
};

export { useReleases, ReleasesProvider };
