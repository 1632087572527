import { useTranslation } from "react-i18next";
import { ReleaseModal } from "../../../releases/common/releaseModal";
import { useDuplicateRelease } from "../../../releases/duplicateRelease/useDuplicateRelease";

export const DuplicateReleaseModal = ({ closeModal, release, ...props }) => {
  const { t } = useTranslation();
  const hook = useDuplicateRelease(closeModal);

  return (
    <ReleaseModal
      {...props}
      initial={release}
      toggleModal={closeModal}
      hook={hook}
      release={release}
      modalTitle={t("releases.duplicate.title")}
    />
  );
};
