import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: ${({ isNotDesktop }) => (isNotDesktop ? "center" : "")};
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: ${({ isNotDesktop }) => (isNotDesktop ? "center" : "")};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const NodeCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
