import { DateTime } from "luxon";

export const useRunDurations = () => {
  const durationsMap = runs =>
    runs.map(entry => {
      const dateTimeStart = DateTime.fromISO(entry.start_time);
      const dateTimeEnd = DateTime.fromISO(entry.updated_time);
      const durationTime = dateTimeEnd.diff(dateTimeStart).milliseconds / 60000;

      return { id: entry.id, duration: Math.round(durationTime) };
    });

  return {
    durationsMap,
  };
};
