import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";
import { useFetch } from "../../../../../../context/hooks";
import React, { useEffect, useRef } from "react";
import { useTree } from "../../../../../../context/tree/tree.provider";
import { useNotifications } from "../../../../../../context/hooks/useNotifications/useNotifications";
import { NewNodeItem } from "../../navigationParent.styles";
import {
  CLICK,
  ESCAPE,
  KEYDOWN,
  KEYS,
} from "../../../../../../static/constants/constants";

export const useCreateNode = closeCreateNode => {
  const createRef = useRef(null);
  const { post, status, body, error } = useFetch();
  const { getTree, getTreeSuccess } = useTree();
  const { createNotification, types } = useNotifications();

  const handleKeyboard = ({ key }) => {
    if (key === ESCAPE) closeCreateNode();
  };

  const handleClick = e => {
    if (createRef?.current && createRef.current !== e.target) closeCreateNode();
  };

  useEffect(() => {
    document.addEventListener(KEYDOWN, handleKeyboard, true);
    document.addEventListener(CLICK, handleClick, true);

    return () => {
      document.removeEventListener(KEYDOWN, handleKeyboard);
      document.removeEventListener(CLICK, handleClick);
    };
  }, [createRef]);

  useEffect(() => {
    if (getTreeSuccess) closeCreateNode();
  }, [getTreeSuccess]);

  useEffect(() => {
    if (status.isRejected)
      createNotification(
        types.ERROR,
        `errors.addNode.${
          Array.isArray(error.text) ? error.text[0] : error.text
        }`
      );
    if (status.isResolved && body) {
      createNotification(types.SUCCESS, "success.addNode");
      getTree();
    }
  }, [status.isRejected, status.isResolved, body]);

  const newNodeHandler = (e, parentId) => {
    if (e.key === KEYS.ENTER || e.keyCode === 13)
      post(ENDPOINTS.ADD_NODE, { name: e.target.value, parent_id: parentId });
    if (e.key === KEYS.ESCAPE) closeCreateNode();
  };

  const onBlurHandler = (e, parentId) => {
    if (e.target.value?.length)
      post(ENDPOINTS.ADD_NODE, { name: e.target.value, parent_id: parentId });
    else closeCreateNode();
  };

  const NewNodeComponent = ({ parentId }) => (
    <NewNodeItem>
      <input
        onBlur={e => onBlurHandler(e, parentId)}
        autoFocus
        onKeyDown={e => newNodeHandler(e, parentId)}
        onChange={e => newNodeHandler(e, parentId)}
      />
    </NewNodeItem>
  );

  return {
    NewNodeComponent,
    newNodeHandler,
    onBlurHandler,
    createRef,
  };
};
