import { useEffect } from "react";
import { useReleaseLabels } from "../../../views/releaseView/hooks/useReleaseLabels";
import { useNotifications } from "../../../context/hooks";
import { useModal } from "../../../context/modal/modal.provider";

export const useAddLabelToRelease = () => {
  const { addNewLabel, addSuccess, addFailure } = useReleaseLabels();
  const { createNotification, types } = useNotifications();
  const { openModal, closeModal, MODALS } = useModal();

  useEffect(() => {
    if (addSuccess) {
      createNotification(types.SUCCESS, "releases.addLabelToRelease.success");
      closeModal();
    }

    if (addFailure)
      createNotification(types.ERROR, "releases.addLabelToRelease.error");
  }, [addSuccess, addFailure]);

  const toggleModal = (e, label) => {
    e?.syntheticEvent?.stopPropagation();
    e?.syntheticEvent?.preventDefault();
    if (e?.stopPropagation) e?.stopPropagation();
    if (e?.preventDefault) e?.preventDefault();
    openModal(MODALS.ADD_LABEL_TO_RELEASE, { label });
  };

  return {
    addNewLabel,
    openAddLabelModal: toggleModal,
  };
};
