import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.padding};

  overflow-y: auto;

  ${({ isShared }) =>
    isShared &&
    css`
      height: 100%;
      min-height: 100%;
    `};
`;

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.padding};
  height: 100%;
  min-height: 95%;
`;

export const HorizontalWidgetContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isXL ? "row" : "column")};

  width: 100%;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isNotDesktop }) => (isNotDesktop ? "100%" : "50%")};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isNotDesktop }) => (isNotDesktop ? "100%" : "50%")};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const CardTitle = styled.h1`
  ${({ active }) =>
    !active &&
    css`
      opacity: 0.5;
    `}
`;

export const CardTab = styled.div`
  cursor: pointer;

  ${({ active, theme }) =>
    active &&
    css`
      border-bottom: 4px solid ${theme.colours.haloAccent};
    `}

  ${({ active, theme }) =>
    !active &&
    css`
      border-bottom: 4px solid ${theme.colours.foreground};
    `}
`;

export const CardTabContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.padding};

  padding-bottom: ${({ theme }) => theme.spacing.paddingSM};

  overflow-x: auto;
`;
