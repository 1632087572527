import React from "react";
import { Card } from "./card/Card";
import {
  Container,
  NoScenariosP,
  SectionHeader,
  StyledScenariosTable,
} from "./scenarios.styles";
import { useTranslation } from "react-i18next";
import { useLatestRun } from "../../latestRunCard/hooks/useLatestRun";
import { Scenario } from "./scenario/scenario";
import { STATUSES } from "../../../../../static/constants/constants";

export const Scenarios = ({ run }) => {
  const { t } = useTranslation();
  const {
    failedScenarios,
    passedScenarios,
    errorScenarios,
    skippedScenarios,
    newScenarios,
  } = useLatestRun(run);

  const StatusSection = ({ status, first }) => {
    return (
      <SectionHeader color={status} first={first}>
        <h2>{t(`runView.testevolvevisual.${status}.name`)}</h2>
        <p>{t(`runView.testevolvevisual.${status}.description`)}</p>
      </SectionHeader>
    );
  };

  const TableHeaderRow = ({ hideFailures }) => {
    return (
      <tr>
        <th>{t("runView.testevolvevisual.scenarioNameColumn")}</th>
        <th>{t("runView.testevolvevisual.totalChecks")}</th>
        <th>
          {!hideFailures
            ? t("runView.testevolvevisual.totalFailedChecks")
            : null}
        </th>
      </tr>
    );
  };

  const scenariosList = () => {
    return (
      <Container>
        <>
          <StatusSection status={STATUSES.FAILED} first={true} />
          {failedScenarios && failedScenarios.length ? (
            <StyledScenariosTable>
              <TableHeaderRow />
              {failedScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.visual.checksPerformed}
                    totalFailedChecks={item.visual.totalFailedChecks}
                  />
                );
              })}
            </StyledScenariosTable>
          ) : (
            <NoScenariosP>{t("noScenarios")}</NoScenariosP>
          )}
        </>
        <>
          <StatusSection status={STATUSES.ERROR} />
          {errorScenarios && errorScenarios.length ? (
            <StyledScenariosTable>
              <TableHeaderRow />
              {errorScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.visual.checksPerformed}
                    totalFailedChecks={item.visual.totalFailedChecks}
                  />
                );
              })}
            </StyledScenariosTable>
          ) : (
            <NoScenariosP>{t("noScenarios")}</NoScenariosP>
          )}
        </>
        <>
          <StatusSection status={STATUSES.PASSED} />
          {passedScenarios && passedScenarios.length ? (
            <StyledScenariosTable>
              <TableHeaderRow hideFailures />
              {passedScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.visual.checksPerformed}
                  />
                );
              })}
            </StyledScenariosTable>
          ) : (
            <NoScenariosP>{t("noScenarios")}</NoScenariosP>
          )}
        </>
        <>
          <StatusSection status={STATUSES.SKIPPED} />
          {skippedScenarios && skippedScenarios.length ? (
            <StyledScenariosTable>
              <TableHeaderRow hideFailures />
              {skippedScenarios.map((item, index) => (
                <Scenario key={index} name={item.name} />
              ))}
            </StyledScenariosTable>
          ) : (
            <NoScenariosP>{t("noScenarios")}</NoScenariosP>
          )}
        </>
        <>
          <StatusSection status={STATUSES.NEW} />
          {newScenarios && newScenarios.length ? (
            <StyledScenariosTable>
              <TableHeaderRow hideFailures />
              {newScenarios.map((item, index) => (
                <Scenario
                  key={index}
                  name={item.name}
                  checksPerformed={item.visual.checksPerformed}
                />
              ))}
            </StyledScenariosTable>
          ) : (
            <NoScenariosP>{t("noScenarios")}</NoScenariosP>
          )}
        </>
      </Container>
    );
  };

  return (
    <Card fillWidth fillHeight>
      <h1>{t("runView.testevolvevisual.scenariosTitle")}</h1>
      {run ? scenariosList() : null}
    </Card>
  );
};
