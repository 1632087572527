import { useEffect } from "react";
import { useLatestRun } from "./hooks/useLatestRun";
import { Dot } from "../../../../components/dot/dot";
import { Gauge } from "../../../../components/graph/graph";
import {
  Footer,
  LeftDiv,
  Container,
  GraphContainer,
  RunStatusTitle,
  RunStatusValue,
  RunStatusSticker,
  RunStatusContainer,
  HorizontalContainer,
  RunStatusStickerBox,
} from "./latestRunCard.styles";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../../context/app/app.provider";
import { routes } from "../../../../router/routes";
import { STATUSES } from "../../../../static/constants/constants";
import { useModal } from "../../../../context/modal/modal.provider";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";

const TEST_RUN_STATUS_IN_PROGRESS = "in_progress";
const ONE_SECOND = 1000;

const STATUS_HEADINGS = {
  PASSED: "Passed",
  FAILED: "Failed",
  TOTAL_SCENARIOS: "Total Scenarios",
};

export const LatestRunCard = ({ run, isLatest, nodeDetails, isShared }) => {
  const {
    data,
    status,
    timeAgo,
    runStatus,
    runStatuses,
    passPercent,
    setTime,
  } = useLatestRun(run);
  const { isMobile } = useApp();
  const { t } = useTranslation();
  const history = useHistoryWithParams();
  const { openModal, MODALS } = useModal();

  const isInProgress = run.status === TEST_RUN_STATUS_IN_PROGRESS;

  const openSharedModal = () => openModal(MODALS.SHARE_RUN, { run });

  const reportUrl = `${routes.RUN_VIEW_REPORT.path
    .replace(":nodeId", nodeDetails?.id)
    .replace(":runType", run?.category)
    .replace(":label", encodeURI(encodeURIComponent(run?.label)))
    .replace(":runId", run?.id)}`;

  useEffect(() => {
    if (isInProgress) {
      const tick = () => setTime();

      let id = setInterval(tick, ONE_SECOND);
      return () => clearInterval(id);
    }
  }, [run.status]);

  const stickerHandler = entry => {
    if (isInProgress) return;

    switch (entry.heading) {
      case STATUS_HEADINGS.PASSED:
        return isShared
          ? openSharedModal()
          : history.push(`${reportUrl}?status=${STATUSES.PASSED}`);
      case STATUS_HEADINGS.FAILED:
        return isShared
          ? openSharedModal()
          : history.push(`${reportUrl}?status=${STATUSES.FAILED}`);
      case STATUS_HEADINGS.TOTAL_SCENARIOS:
        return isShared ? openSharedModal() : history.push(reportUrl);
      default:
        return null;
    }
  };

  return (
    <>
      <Container>
        <h1>
          {t(isLatest ? "runView.latestRunTitle" : "runView.selectedRunTitle")}
        </h1>
        <HorizontalContainer isMobile={isMobile}>
          <GraphContainer isMobile={isMobile}>
            <Gauge data-test="passing-graph" data={data} passed={passPercent} />
          </GraphContainer>
          <RunStatusContainer isMobile={isMobile}>
            {runStatuses?.map(entry => (
              <RunStatusStickerBox
                isMobile={isMobile}
                key={`sticker-${entry.heading}`}
              >
                <RunStatusSticker
                  onClick={() => stickerHandler(entry)}
                  clickable={
                    Object.values(STATUS_HEADINGS).includes(entry.heading) &&
                    !isInProgress
                  }
                >
                  <RunStatusTitle data-test={`runDetails-${entry.heading}`}>
                    {entry.heading}
                  </RunStatusTitle>
                  <RunStatusValue>{entry.value}</RunStatusValue>
                </RunStatusSticker>
              </RunStatusStickerBox>
            ))}
          </RunStatusContainer>
        </HorizontalContainer>
        <Footer>
          <LeftDiv>
            <Dot colour={status} />
            <p data-test="runStatus">{runStatus}</p>
          </LeftDiv>
          <p data-test="timeago">{timeAgo}</p>
        </Footer>
      </Container>
    </>
  );
};
