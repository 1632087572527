import styled, { css } from "styled-components";

export const RunInsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingLG};
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StatisticsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingLG};
  flex-wrap: wrap;
`;

export const StatisticsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: fit-content;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.padding}
    ${({ theme }) => theme.spacing.paddingLG};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  min-width: ${({ theme }) => theme.sizing.dropdownMenuWidth};

  ${({ clickable }) =>
    clickable &&
    css`
      &:hover {
        cursor: pointer;
        outline: ${({ theme, clickable }) =>
          clickable ? `1px solid ${theme.colours.haloAccent}` : null};
      }
    `}
`;

export const StatisticHeading = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.mediumLarge};
  opacity: 0.6;
  font-weight: medium;
`;

export const StatisticValue = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.largest};
  font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
`;

export const ComparisonSelect = styled.select`
  outline: none;
  border: none;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background-color: ${({ theme }) => theme.colours.foreground};
  color: ${({ theme }) => theme.colours.mainText};
  border: 1px solid ${({ theme }) => theme.colours.secondaryButton};
  cursor: pointer;
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;

  h2 {
    font-size: ${({ theme }) => theme.fontSize.larger};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

export const RunPropertyHeading = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  h2 {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

export const RunPropertyCell = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${({ theme }) => theme.sizing.stickerHeight};
  width: fit-content;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  min-width: fit-content;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  font-size: ${({ theme }) => theme.fontSize.medium};
  grid-column: ${({ double }) => double && "span 2"};

  svg {
    height: ${({ theme }) => theme.sizing.svgHeight};
    width: auto;
  }
`;

export const PropertiesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.larger};
  text-align: center;
  margin-top: ${({ theme }) => theme.sizing.arrowOffset};
`;

export const RunPropertiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const PercentageDifference = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const StatisticValues = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;
