import styled from "styled-components";
import { CenteredContainer } from "../../components";

export const FormContainer = styled(CenteredContainer)`
  justify-content: center;
  text-align: center;
  gap: 64px;
`;

export const SuccessMessageContainer = styled(CenteredContainer)`
  justify-content: center;
  text-align: center;
  width: 70%;
  gap: 32px;
`;
