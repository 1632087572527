import styled from "styled-components";

export const DropdownContainer = styled.div`
  z-index: 100;
  display: block;
  position: fixed;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border-radius: ${({ theme }) => theme.spacing.paddingSM};
  min-height: ${({ theme }) => theme.spacing.btnHeight};
  background-color: ${({ theme }) => theme.colours.dropdownBG};
  padding: ${({ theme }) => theme.spacing.paddingSM};

  outline: none;
  border: none;
`;
