import { useEffect, useState } from "react";
import { useFetch } from "../../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";

export const useSubscriptionDetails = () => {
  const [details, setDetails] = useState({});
  const { get, status, body } = useFetch();

  const getSubscriptionDetails = () => get(ENDPOINTS.SUBSCRIPTION_CURRENT);

  useEffect(() => {
    if (status.isResolved && body) setDetails(body);
  }, [status.isResolved, body]);

  return {
    subscriptionDetails: details,
    getSubscriptionDetails,
    subscriptionDetailsPending: status.isLoading,
  };
};
