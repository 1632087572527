import { useTranslation } from "react-i18next";
import { DeleteRunText } from "../../../../views/nodeView/nodeView.styles";
import { Modal } from "../../modal";
import { useDeleteRunHistory } from "../../../../views/nodeView/hooks/useDeleteRunHistory";

export const DeleteRunHistoryModal = ({
  closeModal,
  run,
  nodeId,
  getLabels,
}) => {
  const { t } = useTranslation();
  const { deleteRuns } = useDeleteRunHistory(nodeId, getLabels);

  return (
    <Modal
      size="small"
      title={"Delete Runs"}
      primary={{
        text: t("btnConfirm"),
        onClick: () => deleteRuns(run),
        props: { danger: true },
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <DeleteRunText>{t("nodeView.deleteRunConfirm")}</DeleteRunText>
      <DeleteRunText>{t("nodeView.deleteRunConfirm2")}</DeleteRunText>
    </Modal>
  );
};
