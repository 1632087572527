import { useEffect, useRef, useState } from "react";
import {
  CopyButton,
  FakeButton,
  MetaDataContainer,
  MetaDataEntry,
  MetaDataP,
  MetaDataTitle,
  MetaDataValue,
  NoMetaData,
  StyledCopyIcon,
  StyledCopyTrueIcon,
} from "./metadataTab.styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import {
  COPIED_TIMEOUT,
  METADATA_DOCUMENTATION_LINK,
} from "../../../../static/constants/constants";
import { CustomLink } from "../../../../components/Link/StyledLink";
import { StyledLinkIcon } from "../../runDetails/runDetails.styles";

const isLink = item => item.includes(".");

export const MetaDataTab = ({ run }) => {
  const metadata = run?.meta_data;

  const timeoutRef = useRef(null);
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (timeoutRef?.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setCopied(false), COPIED_TIMEOUT);
  }, [copied]);

  return (
    <MetaDataContainer>
      {!metadata || !Object.entries(metadata).length ? (
        <>
          <NoMetaData>{t("runView.noMetadata")}</NoMetaData>
          <NoMetaData>{t("runView.noMetadata2")}</NoMetaData>
          <CustomLink external to={METADATA_DOCUMENTATION_LINK}>
            <FakeButton>
              <StyledLinkIcon />
              <p>{t("navigation.docs")}</p>
            </FakeButton>
          </CustomLink>
        </>
      ) : (
        Object.entries(metadata).map((entry, i) => (
          <MetaDataEntry key={entry[0]} link={isLink(entry[1])}>
            <MetaDataTitle>{entry[0]}</MetaDataTitle>
            <MetaDataValue link={isLink(entry[1])}>
              <MetaDataP link={isLink(entry[1])}>{entry[1]}</MetaDataP>
              <CopyToClipboard text={entry[1]} onCopy={() => setCopied(i)}>
                <CopyButton>
                  {copied === i ? <StyledCopyTrueIcon /> : <StyledCopyIcon />}
                </CopyButton>
              </CopyToClipboard>
            </MetaDataValue>
          </MetaDataEntry>
        ))
      )}
    </MetaDataContainer>
  );
};
