import { css } from "styled-components";
import styled from "styled-components/macro";
import { Button } from "../../../../components";
import { mediaQuery } from "../../../../utils/theme/mediaQuery";

export const StyledButton = styled(Button)`
  background: ${({ disabled }) => (disabled ? "white" : "green")};
  color: ${({ disabled }) => (disabled ? "transparent" : "white")};
`;

export const Wrapper = styled.div`
  overflow-y: visible;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;

export const StyledTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  margin-bottom: 20px;
  padding-bottom: ${({ theme }) => theme.spacing.padding};
  border-bottom: 1px solid ${({ theme }) => theme.colours.mainText};
  color: ${({ theme }) => theme.colours.mainText};

  div {
    width: 30%;

    &:nth-child(2) {
      width: ${({ showPartner }) => (showPartner ? "10%" : "15%")};
      ${mediaQuery.lessThan("MD")`display: none;`}
    }

    &:nth-child(3) {
      width: ${({ showPartner }) => (showPartner ? "10%" : "15%")};
      ${mediaQuery.lessThan("MD")`display: none;`}
    }

    &:nth-child(4) {
      width: ${({ showPartner }) => (showPartner ? "10%" : "40%")};
      ${mediaQuery.lessThan("MD")`display: none;`}
    }

    &:nth-child(5) {
      width: 40%;
      ${mediaQuery.lessThan("MD")`display: none;`}
    }
  }
`;
export const StyledMainContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
  color: ${({ theme }) => theme.colours.mainText};
  align-items: center;
  gap: ${({ isNotDesktop, theme }) => isNotDesktop && theme.spacing.padding};
  padding-bottom: ${({ isNotDesktop, theme }) =>
    isNotDesktop && theme.spacing.padding};
`;

export const UserNameContainer = styled.div`
  width: 30%;

  padding-right: 5px;
  [class*="col-"] {
    width: 100%;
  }
  ${mediaQuery.lessThan("MD")`width: 100%`}

  > div {
    display: flex;
    width: 100%;
    margin: ${({ theme }) => theme.spacing.paddingSM};
    align-items: center;

    p {
      padding: ${({ theme }) => theme.spacing.paddingSM};
      background-color: ${({ theme }) => theme.colours.background};
      border-radius: ${({ theme }) => theme.borders.radius};
    }

    span {
      margin-left: ${({ theme }) => theme.spacing.padding};
      color: ${({ theme }) => theme.colours.haloAccentLight};
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        color: ${({ theme }) => theme.colours.mainText};
      }
    }
  }
`;

export const StyledBottomLine = styled.hr`
  display: block;
  border-style: inset;
  border-width: 1px;
  width: 100%;
`;

export const StyledBottomLineContainer = styled.div`
  width: 100%;
  padding: 0.7rem 4rem;
`;

export const CheckboxContainer = styled.div`
  width: ${({ showPartner }) => (showPartner ? "17%" : "25%")};
  padding-left: ${({ theme }) => theme.spacing.padding};

  ${mediaQuery.lessThan(
    "MD"
  )` padding-left: 0px; padding-right: 15px; padding-top: ${({ theme }) =>
    theme.spacing.padding}`}
  ${mediaQuery.lessThan(
    "SM"
  )`padding-left: 0px; padding-right: 15px; padding-top: ${({ theme }) =>
    theme.spacing.padding}`}
`;

export const CollapseButtonContainer = styled.div`
  width: 15%;
  margin-left: 80%;
  display: none;
  position: absolute;
  padding-top: ${({ theme }) => theme.spacing.padding};

  ${mediaQuery.lessThan("MD")`display: flex;`}
`;

export const ProjectRolesContainer = styled.div`
  disply: flex;
  padding-right: 0;
  margin-right: ${({ theme, isNotDesktop }) =>
    isNotDesktop ? "0px" : theme.spacing.padding};

  width: ${({ isNotDesktop }) => (isNotDesktop ? "100%" : "60%")};
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  ${({ isNotDesktop }) =>
    isNotDesktop &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export const Content = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.padding};
  display: flex;
  flex-direction: row;
`;

export const StyledCollapseContent = styled.div`
  ${mediaQuery.greaterThan("MD")` display: flex;
		width: 70%;
		padding-top: ${({ theme }) => theme.spacing.padding};
		flex-direction: row;
  `}
  ${mediaQuery.lessThan("MD")`
		opacity: ${props => (props.open ? "1" : "0")};
		height: 0;
		transition: height 0.3s ease-in-out, overflow 0.2 ease-in-out;
		${props => props.open && "height: 200px;"}
		${props => !props.open && "overflow: hidden;"}
		display: block;
		width: 100%;
  `}
`;

export const StyledLabel = styled.div`
  padding-left: 20px;
  padding-top: ${({ theme }) => theme.spacing.padding};
`;
