import React, { useEffect, useState } from "react";
import {
  ContainerMsg,
  OrganisationDetailsContainer,
  OrganisationDetailsRow,
  PurpleSpan,
} from "../../../../views/organisationSettings/tabs/partnerCustomers/modals/modal.styles";
import { useTranslation } from "react-i18next";
import { usePreviewCustomer } from "../../../../views/organisationSettings/tabs/partnerCustomers/hooks/previewCreateCustomer";
import { formatCurrency } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/formatCurrency";
import { Modal } from "../../..";
import { useCreateCustomer } from "../../../../views/organisationSettings/tabs/partnerCustomers/hooks/createCustomer";
import { Stripe } from "../../../stripe/Stripe";

const UnWrappedPreviewCreateCustomerModal = ({
  closeModal,
  product,
  orgId,
  orgName,
  productLabel,
  username,
  setUpdated,
}) => {
  const payload = { product, orgId, orgName, username };
  const { t } = useTranslation();

  const { createCustomer } = useCreateCustomer(closeModal, setUpdated);
  const { getPreview, preview } = usePreviewCustomer(closeModal);

  const [adding, setAdding] = useState(false);

  useEffect(() => {
    getPreview(payload);
  }, []);

  const update = () => {
    setAdding(true);
    const p = {
      org_id: payload.orgId,
      name: payload.orgName,
      product: payload.product.value,
      admin_username: username,
    };
    createCustomer(p);
  };

  let proratedCost = t("loading");
  if (preview?.prorated_invoice) {
    proratedCost = formatCurrency(
      "en",
      preview?.prorated_invoice.currency,
      preview?.prorated_invoice.total
    );
    if (preview?.prorated_invoice.total > preview?.prorated_invoice.sub_total) {
      proratedCost = `${proratedCost} ${t("includingVat")}`;
    }
  }
  let ongoingCost = t("loading");
  if (preview?.next_invoice) {
    ongoingCost = formatCurrency(
      "en",
      preview.next_invoice.currency,
      preview.next_invoice.total
    );
    if (preview?.next_invoice.total > preview?.next_invoice.sub_total) {
      ongoingCost = `${ongoingCost} ${t("includingVat")}`;
    }
  }

  return (
    <Modal
      title={t("customer.title.createOrganisation")}
      size="small"
      secondary={{
        text: t("customer.button.cancel"),
        onClick: () => closeModal(),
      }}
      primary={{
        text: adding ? t("customer.button.adding") : t("customer.button.add"),
        onClick: () => update(),
        props: {
          disabled: adding || !preview?.next_invoice,
        },
      }}
    >
      <OrganisationDetailsContainer>
        <OrganisationDetailsRow>
          <div>{t("customer.label.organisationId")}</div>
          <div>{payload.orgId}</div>
        </OrganisationDetailsRow>
        <OrganisationDetailsRow>
          <div>{t("customer.label.organisationName")}</div>
          <div>{payload.orgName}</div>
        </OrganisationDetailsRow>
        <OrganisationDetailsRow>
          <div>{t("customer.label.product")}</div>
          <div>{productLabel}</div>
        </OrganisationDetailsRow>
      </OrganisationDetailsContainer>
      <ContainerMsg>
        <p data-testid={"prorated-cost"}>
          {t("customer.create.text.proratedCost")}{" "}
          <PurpleSpan data-test="preview"> {proratedCost}</PurpleSpan>
        </p>
        <p data-testid={"immediate-cost"}>
          {t("customer.create.text.ongoingCost")}{" "}
          <PurpleSpan data-test="preview"> {ongoingCost}</PurpleSpan>
        </p>
        <p data-testid={"mandate"}>{t("customer.create.text.mandate")}</p>
      </ContainerMsg>
    </Modal>
  );
};

export const PreviewCreateCustomerModal = props => (
  <Stripe>
    <UnWrappedPreviewCreateCustomerModal {...props} />
  </Stripe>
);
