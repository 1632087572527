import { ACTIONS } from "../../static/constants/actions";

export const authReducer = (state, { type }) => {
  switch (type) {
    case ACTIONS.SIGNIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        newUserCreated: false,
        resetPasswordSuccess: false,
      };
    case ACTIONS.SIGNIN_FAILURE:
    case ACTIONS.SIGNOUT_SUCCESS:
      return { ...state, isAuthenticated: false };
    case ACTIONS.SET_SESSION_EXPIRED:
      return { ...state, sessionExpired: true };
    case ACTIONS.SET_SESSION_NOT_EXPIRED:
      return { ...state, sessionExpired: false };
    default:
      return state;
  }
};
