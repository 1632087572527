import { Loading } from "../../../../components";
import {
  InsightsContainer,
  InsightsHeader,
  InsightsHeaderContainer,
  InsightsSentencesList,
  InsightsSubHeader,
  LoadingContainer,
} from "../testevolveRunReport.styles";
import { useTranslation } from "react-i18next";

export const Insights = ({ scenarioSentences, generatingInsights }) => {
  const { t } = useTranslation();

  return (
    <InsightsContainer data-test="insights-container">
      {generatingInsights ? (
        <LoadingContainer data-test="loading-spinner">
          <Loading />
          <h1>{t("runReport.insights.loadingText")}</h1>
        </LoadingContainer>
      ) : (
        <>
          <InsightsHeaderContainer>
            <InsightsHeader>{t("runReport.insights.title")}</InsightsHeader>
            <InsightsSubHeader>
              {t("runReport.insights.supportText")}
            </InsightsSubHeader>
          </InsightsHeaderContainer>
          {scenarioSentences.map((sentences, index) => (
            <InsightsSentencesList key={`insight-${index}`}>
              {sentences.map(sentence => (
                <li
                  data-test="insights-sentence"
                  key={`insight-sentence-${index}`}
                >
                  {sentence}
                </li>
              ))}
            </InsightsSentencesList>
          ))}
        </>
      )}
    </InsightsContainer>
  );
};
