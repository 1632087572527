import { t } from "i18next";
import { useState } from "react";
import { LighthouseScoreGauge } from "../../../../components/graph/graph";
import { useUuid } from "../../../../context/uuid/useUuid";
import {
  Arrow,
  AuditDescription,
  AuditDropDown,
  AuditsRow,
  AuditTitle,
  GaugeContainer,
  SectionContainer,
  ScoreSection,
  SmallText,
  Violation,
  ViolationsSection,
  RightContainer,
  Triangle,
  Square,
  Circle,
  DescriptionSection,
  KeyContainer,
  Anchor,
  Ring,
  DescriptionContainer,
  SubItemContainer,
} from "../lighthouseSection.styles";
import { getContents } from "../utils";
import { CustomLink } from "../../../../components/Link/StyledLink";
import { useTheme } from "styled-components";

const sp = /[[\]{}<>]+/;
const ACCESSIBILITY_LINK =
  "https://developer.chrome.com/docs/lighthouse/accessibility/?utm_source=lighthouse&utm_medium=cli";

const removeEmpties = str => str.filter(s => s.length);

const extractLink = text => {
  const test = /\[(.*?)\]\((.*?)\)/;

  let done = false;
  let string = text;
  let desc = [];

  while (!done) {
    if (!test.test(string)) return;
    let result = test.exec(string);

    desc = [...desc, string.split(result[0])[0]];

    const link = (
      <CustomLink key={result[1]} accent={true} external={true} to={result[2]}>
        {result[1]}
      </CustomLink>
    );

    desc = [...desc, link];

    string = string.split(result[0])[1];

    if (!test.test(string)) {
      desc = [...desc, <p key={string}>{string}</p>];
      done = true;
    }
  }

  return <DescriptionContainer>{desc.map(i => i)}</DescriptionContainer>;
};

export const AccessbilitySection = ({ data, graphData, scrollRef }) => {
  return (
    <SectionContainer>
      <Anchor ref={scrollRef} />
      <ScoreSection>
        <GaugeContainer>
          <LighthouseScoreGauge
            large={true}
            data={graphData}
            passed={graphData.find(d => d.name === "Accessibility")?.value}
          />
        </GaugeContainer>
        <DescriptionSection>
          <span>
            {t("runReport.lighthouse_descriptions.accessibility_1")}
            <CustomLink accent external to={ACCESSIBILITY_LINK}>
              {t("runReport.lighthouse_descriptions.accessibility_2")}
            </CustomLink>
            {t("runReport.lighthouse_descriptions.accessibility_3")}
          </span>
        </DescriptionSection>
        <ScoreKey />
      </ScoreSection>
      <ViolationsSection>
        {data.groups.map(vio => (
          <Violation key={vio}>
            <h3>{vio.title}</h3>
            {vio.audits.map(audit => (
              <Audit key={audit.id} audit={audit} />
            ))}
            <SmallText>{vio.description}</SmallText>
          </Violation>
        ))}
      </ViolationsSection>
    </SectionContainer>
  );
};

export const Audit = ({ audit, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { uuid } = useUuid();
  const { isDark } = useTheme();

  return (
    <>
      <AuditsRow onClick={() => setIsOpen(o => !o)}>
        <RightContainer>
          {audit.score === null ? <Ring /> : null}
          {audit.score !== null && audit.score < 0.5 ? <Triangle /> : null}
          {audit.score && audit.score < 0.9 && audit.score > 0.49 ? (
            <Square />
          ) : null}
          {audit.score && audit.score > 0.89 ? <Circle /> : null}
          <AuditTitle>
            {removeEmpties(audit.title.split("`")).map(str =>
              sp.test(str[0]) ? <code key={str}>{str}</code> : str
            )}
          </AuditTitle>
        </RightContainer>
        <RightContainer>
          {value}
          <Arrow isOpen={isOpen} />
        </RightContainer>
      </AuditsRow>
      {isOpen ? (
        <AuditDropDown isDark={isDark}>
          <AuditDescription>{extractLink(audit.description)}</AuditDescription>
          {audit?.details &&
          audit.details?.headings &&
          audit?.details?.items ? (
            <table>
              <thead>
                <tr>
                  {audit.details.headings.map(heading => (
                    <th key={heading.text || heading.label}>
                      <p>{heading.text || heading.label}</p>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {audit.details.items.map(item => (
                  <tr key={uuid()}>
                    {audit.details.headings.map(heading => (
                      <td key={heading.text}>
                        {getContents(item, heading.key)}
                        {heading.subItemsHeading && item.subItems
                          ? item.subItems.items.map(subItem => (
                              <SubItemContainer
                                key={`subitem-${uuid()}-${subItem.key}`}
                              >
                                {getContents(
                                  subItem,
                                  heading.subItemsHeading.key
                                )}
                              </SubItemContainer>
                            ))
                          : null}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </AuditDropDown>
      ) : null}
    </>
  );
};

export const ScoreKey = () => (
  <KeyContainer>
    <Triangle />
    <p>0-49</p>
    <Square />
    <p>50-89</p>
    <Circle />
    <p>90-100</p>
  </KeyContainer>
);
