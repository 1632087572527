import styled, { css } from "styled-components";

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ gap, theme }) => (gap ? theme.spacing.padding : "none")}
  padding: ${({ theme }) => theme.spacing.padding};
  width: 100%;
  flex-grow: 1;
`;

export const HeadingTextContainer = styled.div`
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) =>
    `${theme.spacing.padding} ${theme.spacing.padding}`};
`;

export const LinkContainer = styled.div`
  width: ${({ isMobile }) => (isMobile ? "100%" : "calc(100% / 3)")};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  text-align: left;
  padding: ${({ isMobile, theme }) =>
    isMobile ? "0" : `0 ${theme.spacing.padding}`};

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:last-of-type {
        border-left: 1px solid ${({ theme }) => theme.colours.mainText};
      }
      &:first-of-type {
        border-right: 1px solid ${({ theme }) => theme.colours.mainText};
      }
    `}
  overflow-y: auto;

  img {
    border-radius: ${({ theme }) => theme.borders.radius};
  }
`;

export const Spacer = styled.div`
  height: ${({ theme }) => theme.spacing.paddingSM};
`;

export const VideoLinkContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};
  width: ${({ theme }) => (theme.isNotDesktop ? "100%" : "50%")};
  margin: 0;
  min-width: 100px;

  img {
    width: 100%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
    img {
      opacity: 60%;
    }
  }
`;

export const VideoScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingLG};
  height: 100%;
  width: 100%;
  flex-grow: 1;
`;

export const PlayerContainer = styled.div`
  width: 100%;
  height: 100%;

  iframe {
    min-height: 70vh;
    min-width: 65vw;
  }
`;

export const VideoTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    width: 0;
  }
`;

export const MobileSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  label {
    padding-left: ${({ theme }) => theme.spacing.paddingSM};
  }
`;

export const InputContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  display: flex;
  align-items: center;

  background-color: ${({ theme, checked }) =>
    checked ? theme.colours.background : "none"};
  border-radius: ${({ theme }) => theme.borders.radius};
`;
