import { useTranslation } from "react-i18next";
import { Modal } from "../../modal";
import {
  StyledErrorText,
  StyledName,
  StyledSupportText,
} from "../../../releases/releases.styles";
import { useCloseRelease } from "../../../releases/closeRelease/useCloseRelease";
import { DatePicker } from "../../../datePicker/datePicker";
import { DateTime } from "luxon";
import { useState } from "react";
import {
  convertDateToTs,
  isFutureDate,
} from "../../../../utils/dates/dateUtils";

export const CloseReleaseModal = ({ release, closeModal }) => {
  const { t } = useTranslation();
  const { closeRelease, isLoading } = useCloseRelease(closeModal);
  if (isFutureDate(release.close_date)) release.close_date = DateTime.now();

  const [closeDate, setCloseDate] = useState(
    release.close_date || DateTime.now()
  );

  const isLabelsAttached = release?.labels?.length;

  return (
    <Modal
      title={t("releases.closeRelease.title")}
      primary={{
        onClick: () => closeRelease(release.id, closeDate),
        text: t("releases.closeRelease.title"),
        loader: true,
        loading: isLoading,
        props: {
          disabled: !isLabelsAttached,
        },
      }}
      secondary={{
        onClick: closeModal,
        text: t("btnCancel"),
      }}
    >
      <p>{t("releases.closeRelease.supportText")}</p>
      <StyledName>{release.name}</StyledName>
      <DatePicker
        label={t("releases.closeRelease.pickerLabel")}
        past
        present
        placeholder={t("releases.createModal.datePlaceholder")}
        value={convertDateToTs(closeDate)}
        onChange={e => setCloseDate(e)}
      />
      {!isLabelsAttached ? (
        <StyledErrorText>
          {t("releases.closeRelease.noLabelsWarning")}
        </StyledErrorText>
      ) : (
        <StyledSupportText>
          {t("releases.closeRelease.frozenWarning")}
        </StyledSupportText>
      )}
    </Modal>
  );
};
