import { useTranslation } from "react-i18next";
import {
  formatTime,
  getPercentageDifference,
  getTimeBetweenInSeconds,
} from "../utils";
import {
  PercentageDifference,
  StatisticHeading,
  StatisticValue,
  StatisticValues,
  StatisticsCard,
  StatisticsContainer,
} from "../runInsights.styles";

export const Statistics = ({ run, runInsights, statisticToggle }) => {
  const { t } = useTranslation();

  const timeTaken = getTimeBetweenInSeconds(
    run.start_time,
    run.finish_time ?? run.updated_time
  );
  const passPercentageDiff = getPercentageDifference(
    run.passed_percentage,
    runInsights[statisticToggle].passPercentage
  );
  const scenarioTimeDiff = getPercentageDifference(
    run.average_scenario_time,
    runInsights[statisticToggle].averageScenarioTime
  );
  const timeTakenDiff = getPercentageDifference(
    timeTaken,
    runInsights[statisticToggle].timeTaken
  );

  return (
    <StatisticsContainer>
      <StatisticsCard>
        <StatisticHeading>{t("runInsights.passPercentage")}</StatisticHeading>
        <StatisticValues>
          <StatisticValue>{run.passed_percentage}%</StatisticValue>
          <PercentageDifference>
            {passPercentageDiff >= 0 ? "+" : null}
            {passPercentageDiff}%
          </PercentageDifference>
          <StatisticValue>
            {runInsights[statisticToggle].passPercentage}%
          </StatisticValue>
        </StatisticValues>
      </StatisticsCard>
      <StatisticsCard>
        <StatisticHeading>{t("runInsights.ast")}</StatisticHeading>
        <StatisticValues>
          <StatisticValue>
            {formatTime(run.average_scenario_time)}
          </StatisticValue>
          <PercentageDifference>
            {scenarioTimeDiff > 0 ? "+" : null}
            {scenarioTimeDiff}%
          </PercentageDifference>
          <StatisticValue>
            {formatTime(runInsights[statisticToggle].averageScenarioTime)}
          </StatisticValue>
        </StatisticValues>
      </StatisticsCard>
      <StatisticsCard>
        <StatisticHeading>{t("runInsights.timeTaken")}</StatisticHeading>
        <StatisticValues>
          <StatisticValue>{formatTime(timeTaken)}</StatisticValue>
          <PercentageDifference>
            {timeTakenDiff > 0 ? "+" : null}
            {timeTakenDiff}%
          </PercentageDifference>
          <StatisticValue>
            {formatTime(runInsights[statisticToggle].timeTaken)}
          </StatisticValue>
        </StatisticValues>
      </StatisticsCard>
    </StatisticsContainer>
  );
};
