import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { CATEGORY_PROVIDER_MAP } from "../../../static/constants/constants";

export const useUnFreezeRun = () => {
  const { post, status, reset } = useFetch();
  const { createNotification, types } = useNotifications();

  const unFreezeRun = (run, release) =>
    post({
      ...ENDPOINTS.UNFREEZE_RUN,
      path: ENDPOINTS.UNFREEZE_RUN.path
        .replace(":runId", run.id)
        .replace(":releaseId", release.id)
        .replace(":provider", CATEGORY_PROVIDER_MAP[run.category]),
    });

  useEffect(() => {
    if (status.isRejected)
      createNotification(types.ERROR, "releases.unFreezeRun.error");
    if (status.isResolved)
      createNotification(types.SUCCESS, "releases.unFreezeRun.success");
  }, [status.isRejected, status.isResolved]);

  return {
    unFreezeRun,
    unFreezeRunSuccess: status.isResolved,
    unFreezeRunReset: reset,
  };
};
