import { useEffect, useState } from "react";
import { useDeleteAttachment } from "./deleteAttachment";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

const FORM_DATA_TYPE = "type";
const FORM_DATA_CONTENT = "content";
const FORM_DATA_FILES = "files";

export const useEditEntry = (getRun, close) => {
  const [attachmentsToRemove, setAttachmentsToRemove] = useState([]);

  const removeFromQueue = id =>
    setAttachmentsToRemove(arr =>
      arr.filter(e => e.id.toString() !== id.toString())
    );

  const { patch, status } = useFetch(true);
  const { createNotification, types } = useNotifications();
  const { deleteAttachment, deleteAttachmentLoading } =
    useDeleteAttachment(removeFromQueue);

  useEffect(() => {
    if (status.isResolved) {
      close();
      getRun();
    }
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.editEntryError"
      );
  }, [status.isRejected, status.isResolved]);

  useEffect(() => {
    if (attachmentsToRemove.length == 0 && status.isResolved) getRun();
  }, [attachmentsToRemove, status.isResolved]);

  useEffect(() => {
    if (attachmentsToRemove && attachmentsToRemove?.length)
      deleteAttachment(attachmentsToRemove[0].id);
  }, [attachmentsToRemove]);

  const editEntry = (newEntry, entryId, originalEntry) => {
    const formData = new FormData();

    if (newEntry.type !== originalEntry.type)
      formData.append(FORM_DATA_TYPE, newEntry.type);
    if (newEntry.content !== originalEntry.content)
      formData.append(FORM_DATA_CONTENT, newEntry.content);

    const toAdd = newEntry.attachments?.filter(
      att1 => !originalEntry.attachments?.find(att2 => att2.id === att1.id)
    );
    if (toAdd && toAdd?.length)
      toAdd.forEach(arrayEntry =>
        formData.append(FORM_DATA_FILES, arrayEntry.blob, arrayEntry.file_name)
      );

    const toRemove = originalEntry.attachments?.filter(
      att1 => !newEntry.attachments?.find(att2 => att2.id === att1.id)
    );
    if (toRemove && toRemove?.length) setAttachmentsToRemove(toRemove);

    patch(
      {
        ...ENDPOINTS.EDIT_ENTRY,
        path: ENDPOINTS.EDIT_ENTRY.path.replace(":entryId", entryId),
      },
      formData
    );
  };

  return {
    editEntry,
    loadingEdit: status.isLoading || deleteAttachmentLoading,
  };
};
