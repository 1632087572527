import { ReactComponent as SparkLogo } from "../../../static/assets/icon-spark.svg";
import { ReactComponent as ExploratoryIcon } from "../../../static/assets/halo-logo-no-words.svg";
import { ReactComponent as PlaywrightIcon } from "../../../static/assets/icon-playwright.svg";
import { ReactComponent as CypressIconLm } from "../../../static/assets/icon-cypress-lm.svg";
import { ReactComponent as CypressIconDm } from "../../../static/assets/icon-cypress-dm.svg";
import { TEST_CATEGORIES } from "../../../static/constants/constants";
import { useTheme } from "../../../context/theme/theme.provider";

export const CategoryIcon = ({ category }) => {
  const { isDark } = useTheme();

  switch (category) {
    case TEST_CATEGORIES.CYPRESS_FUNCTIONAL:
      return isDark ? <CypressIconDm /> : <CypressIconLm />;
    case TEST_CATEGORIES.PLAYWRIGHT_FUNCTIONAL:
      return <PlaywrightIcon />;
    case TEST_CATEGORIES.TEST_EVOLVE_EXPLORATORY:
    case TEST_CATEGORIES.TEST_EVOLVE_ACCESSIBILITY:
      return <ExploratoryIcon />;
    default:
      return <SparkLogo />;
  }
};
