import { useEffect } from "react";
import { useFetch } from "../../hooks";
import { createState } from "../../../utils/context";
import { PaymentIntentReducer } from "./paymentIntent.reducer";
import { ACTIONS } from "../../../static/constants/actions";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

const initialState = {
  paymentIntent: {},
};

const [useHook, PaymentIntentProvider] = createState(
  "paymentIntent",
  initialState,
  PaymentIntentReducer
);

const usePaymentIntent = () => {
  const { paymentIntent, dispatch } = useHook();
  const { get, body, status, error } = useFetch();

  useEffect(() => {
    if (status.isResolved) setAdminLogs(body);
  }, [status.isResolved, status.isRejected, error]);

  const setAdminLogs = payload =>
    dispatch({ type: ACTIONS.SET_PAYMENT_INTENT, payload: payload });

  const getPaymentIntent = () => get(ENDPOINTS.PAYMENT_INTENT);

  const setPaymentIntentSetupNull = () =>
    dispatch({ type: ACTIONS.SET_PAYMENT_INTENT_NULL });

  return {
    paymentIntent,
    getPaymentIntent,
    setPaymentIntentSetupNull,
    paymentIntentError: error,
  };
};

export { usePaymentIntent, PaymentIntentProvider };
