import {
  RadioButton,
  RadioGroupContainer,
  RadioGrouping,
  RadioLabel,
} from "./input.styles";

const isChecked = (val, val2) => val?.toString() === val2?.toString();

export const RadioGroup = ({
  prefix,
  options,
  setter,
  onChange,
  value,
  showFirst,
  showLast,
  label,
}) => {
  return (
    <RadioGroupContainer>
      {label ? <RadioLabel>{label}</RadioLabel> : null}
      <RadioGrouping>
        {showFirst ? options[0] : null}
        {options.map(val => (
          <RadioButton
            checked={isChecked(value, val)}
            onChange={setter ? e => setter(e.target.value) : onChange}
            type="radio"
            value={val}
            key={`${prefix}radio-${val}`}
          />
        ))}
        {showLast ? options[options.length - 1] : null}
      </RadioGrouping>
    </RadioGroupContainer>
  );
};
