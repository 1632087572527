import { Select } from "./select";
import { Statistics } from "./statistics";
import { useState } from "react";

const AVERAGE = "average";

export const ComparisonSection = ({ run, runInsights }) => {
  const [statisticToggle, setStatisticToggle] = useState(AVERAGE);

  return (
    <>
      <Select
        statisticToggle={statisticToggle}
        setStatisticToggle={setStatisticToggle}
      />
      <Statistics
        statisticToggle={statisticToggle}
        run={run}
        runInsights={runInsights}
      />
    </>
  );
};
