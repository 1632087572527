import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from "@lexical/list";
import { $setBlocksType } from "@lexical/selection";
import {
  $createParagraphNode,
  $isRangeSelection,
  $getSelection,
} from "lexical";
import * as React from "react";
import DropDown, { DropDownItem } from "../../../ui/DropDown";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconBullet } from "../icons/list-ul.svg";
import { ReactComponent as IconNumbered } from "../icons/list-ol.svg";
import { ReactComponent as IconCheck } from "../icons/square-check.svg";
import { ReactComponent as IconParagraph } from "../icons/text-paragraph.svg";
import { blockTypeToBlockName } from "../utils/blockTypeToBlockName";

export const BlockFormatDropDown = ({
  editor,
  blockType,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatCheckList = () => {
    if (blockType !== "check") {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      formatParagraph();
    }
  };

  return (
    <DropDown
      disabled={disabled}
      buttonClassName="toolbar-item block-controls"
      buttonIconClassName={"icon block-type " + blockType}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel={t("richTextEditor.toolbar.blockFormatAriaLabel")}
    >
      <DropDownItem
        $active={blockType === "paragraph"}
        onClick={formatParagraph}
      >
        <IconParagraph />
        <span className="text">
          {t("richTextEditor.toolbar.blockFormatNormal")}
        </span>
      </DropDownItem>
      <DropDownItem $active={blockType === "bullet"} onClick={formatBulletList}>
        <IconBullet />
        <span className="text">
          {t("richTextEditor.toolbar.blockFormatBullet")}
        </span>
      </DropDownItem>
      <DropDownItem
        $active={blockType === "number"}
        onClick={formatNumberedList}
      >
        <IconNumbered />
        <span className="text">
          {t("richTextEditor.toolbar.blockFormatNumber")}
        </span>
      </DropDownItem>
      <DropDownItem $active={blockType === "check"} onClick={formatCheckList}>
        <IconCheck />
        <span className="text">
          {t("richTextEditor.toolbar.blockFormatCheck")}
        </span>
      </DropDownItem>
    </DropDown>
  );
};
