import styled from "styled-components";

export const StyledFeaturesList = styled.ul`
  flex-grow: 1;
  height: ${({ theme }) => theme.sizing.featuresListHeight};
  list-style-type: none;
`;

export const StyledFeature = styled.li`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;
