import { useJira } from "../../../../views/runReportView/testevolve/hooks/useJira";
import { useTranslation } from "react-i18next";
import { Input } from "../../..";
import React, { useEffect, useState } from "react";
import { Loading, Modal } from "../../..";
import { useJiraConfig } from "../../../../context/jiraConfig/jiraConfig.provider";
import {
  StyledFormContainer,
  StyledFieldContainer,
  StyledContainer,
  StyledMainContainer,
  StyledJiraLogo,
  StyledArrow,
  LogoContainer,
  LogosContainer,
  StyledLogo,
  StyledBugLink,
  SpinnerContainer,
  StyledLabel,
  StyledTextArea,
  ProjectSelect,
  SuccessContainer,
  // PrioritySelect,
} from "../../../../views/runReportView/testevolve/styles";
import { MAX_TEXT_FIELD_LENGTH } from "../../../../views/runReportView/testevolve/jira/constants";
import { JiraScreenshotSection } from "../../../../views/runReportView/testevolve/jira/JiraScreenshotSection";

export const JiraBugModal = ({
  summary,
  description,
  closeModal,
  scenario,
  feature,
  runId,
}) => {
  const { t } = useTranslation();

  const screenshots = scenario.steps
    .filter(step => !!step.screenshots)
    .map(step => step.screenshots)
    .flat();

  const { jiraConfig, getConfig } = useJiraConfig();
  const {
    jiraProjects,
    getJiraProjects,
    jiraCreated,
    createIssue,
    createIssueLoading,
  } = useJira();

  const [jiraSummary, setJiraSummary] = useState("");
  // const [priorityOptions, setPriorityOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  // const [selectedPriority, setSelectedPriority] = useState(null);
  const [jiraDescription, setJiraDescription] = useState(description);
  const [screenshotIds, setScreenshotIds] = useState([]);

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getJiraProjects();
  }, []);

  useEffect(() => {
    setJiraSummary(summary);
  }, [summary]);

  useEffect(() => {
    setJiraDescription(description);
  }, [description]);

  const raiseIssue = () => {
    createIssue({
      project: selectedProject.value,
      // priority: selectedPriority.value,
      summary: jiraSummary,
      description: jiraDescription,
      screenshot_ids: screenshotIds,
      run_id: runId,
      scenario_name: scenario.name,
      feature_name: feature.name,
    });
  };

  const projectOptions =
    jiraProjects &&
    jiraProjects
      ?.map(project => {
        return {
          label: project.key,
          value: project.key,
        };
      })
      ?.sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));

  // const projectPriorities = key =>
  //   jiraProjects
  //     .find(project => project.key === key)
  //     .priorities.map(priority => {
  //       return {
  //         label: priority.name,
  //         value: priority.id,
  //       };
  //     });

  return (
    <Modal
      size="medium"
      toggleModal={() => closeModal()}
      title={t("jira.title.raiseBug")}
      primary={
        !jiraCreated.key
          ? {
              text: t("Confirm"),
              onClick: () => raiseIssue(),
              props: {
                disabled: !(selectedProject && jiraSummary && description),
                loader: true,
                loading: createIssueLoading,
              },
            }
          : null
      }
      secondary={{
        text: jiraCreated.key ? t("Close") : t("Cancel"),
        onClick: () => closeModal(),
      }}
    >
      {jiraProjects?.length ? (
        <StyledContainer>
          <LogosContainer>
            <LogoContainer>
              <StyledLogo />
            </LogoContainer>
            <LogoContainer>
              <StyledArrow />
            </LogoContainer>
            <LogoContainer>
              <StyledJiraLogo />
            </LogoContainer>
          </LogosContainer>
          {jiraCreated && jiraCreated?.key && jiraConfig && (
            <SuccessContainer>
              <p>{t("jira.message.success")}</p>
              <StyledBugLink
                external={true}
                to={`${jiraConfig.url}/browse/${jiraCreated.key}`}
              >
                {jiraCreated.key}
              </StyledBugLink>
            </SuccessContainer>
          )}
          {!jiraCreated.key && (
            <StyledMainContainer>
              {jiraProjects?.loading ? (
                <SpinnerContainer data-test="loadingSpinner">
                  <Loading />
                </SpinnerContainer>
              ) : (
                jiraProjects?.length !== 0 && (
                  <StyledFormContainer
                    className={"Form"}
                    columns={true}
                    data-testid="form-container"
                  >
                    <StyledFieldContainer large={"large"} className={"Field"}>
                      <StyledLabel
                        id={"projectLabel"}
                        htmlFor={"project"}
                        hideLabels={false}
                      >
                        {t("jira.label.project")}
                      </StyledLabel>
                      <ProjectSelect
                        name={"project"}
                        placeholder={t("jira.placeholder.project")}
                        data-test={"jira-project"}
                        isMulti={false}
                        options={projectOptions}
                        isClearable={false}
                        classNamePrefix={"Select"}
                        value={selectedProject}
                        onChange={value => {
                          setSelectedProject(value);
                          // setPriorityOptions(projectPriorities(value.value));
                        }}
                      />
                    </StyledFieldContainer>

                    {/* {priorityOptions.length > 0 && (
                      <StyledFieldContainer large={"large"} className={"Field"}>
                        <StyledLabel
                          id={"priorityLabel"}
                          htmlFor={"priority"}
                          hideLabels={false}
                        >
                          {t("jira.label.priority")}
                        </StyledLabel>
                        <PrioritySelect
                          name={"priority"}
                          placeholder={t("jira.placeholder.priority")}
                          data-test={"jira-priority"}
                          isMulti={false}
                          options={priorityOptions}
                          isClearable={false}
                          classNamePrefix={"Select"}
                          value={selectedPriority}
                          onChange={value => setSelectedPriority(value)}
                        />
                      </StyledFieldContainer>
                    )} */}

                    <StyledFieldContainer large={"large"} className={"Field"}>
                      <StyledLabel
                        id={"summaryLabel"}
                        htmlFor={"summary"}
                        hideLabels={false}
                      >
                        {t("jira.label.summary")}
                      </StyledLabel>
                      <Input
                        name={"summary"}
                        placeholder={t("jira.placeholder.summary")}
                        type={"text"}
                        aria-labelledby={"summary"}
                        value={jiraSummary}
                        onChange={e => setJiraSummary(e.target.value)}
                      />
                    </StyledFieldContainer>

                    <StyledFieldContainer large={"large"} className={"Field"}>
                      <StyledLabel
                        id={"descriptionLabel"}
                        htmlFor={"description"}
                        hideLabels={false}
                      >
                        {t("jira.label.description")}
                      </StyledLabel>
                      <StyledTextArea
                        name={"description"}
                        component={"textarea"}
                        aria-labelledby={"description"}
                        value={jiraDescription}
                        onChange={e => setJiraDescription(e.target.value)}
                        maxLength={MAX_TEXT_FIELD_LENGTH}
                      />
                    </StyledFieldContainer>
                    <StyledFieldContainer>
                      {screenshots && screenshots.length ? (
                        <JiraScreenshotSection
                          screenshots={screenshots}
                          screenshotIds={screenshotIds}
                          setScreenshotIds={setScreenshotIds}
                        />
                      ) : null}
                    </StyledFieldContainer>
                  </StyledFormContainer>
                )
              )}
            </StyledMainContainer>
          )}
        </StyledContainer>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
