import { useEffect } from "react";
import { useFetch } from "../../../context/hooks";
import { useReleaseSharingToken } from "./useReleaseSharingToken";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

export const useQueryReleaseToken = () => {
  const { post, body, status } = useFetch();
  const { setReleaseSharingToken } = useReleaseSharingToken();

  const queryReleaseToken = id =>
    post({
      ...ENDPOINTS.QUERY_RELEASE_SHARING_TOKEN,
      path: ENDPOINTS.QUERY_RELEASE_SHARING_TOKEN.path.replace("releaseId", id),
    });

  useEffect(() => {
    if (status.isResolved && body) setReleaseSharingToken(body);
  }, [status.isResolved, body]);

  return {
    queryReleaseToken,
  };
};
