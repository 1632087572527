import styled, { css } from "styled-components";
import { ResponsiveContainer } from "recharts";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const HorizontalContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const OutsideGraphContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;

  ${({ theme, report }) =>
    report &&
    css`
      &:hover {
        outline: 1px solid ${theme.colours.bulletColour};
        border-radius: ${({ theme }) => theme.borders.radius};
      }
    `}

  ${({ theme, active }) =>
    active &&
    css`
      outline: 1px solid ${theme.colours.bulletColour};
      border-radius: ${({ theme }) => theme.borders.radius};
    `}
`;

export const GraphContainer = styled(ResponsiveContainer)`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .recharts-cartesian-grid-horizontal {
    opacity: ${({ theme }) => theme.decoration.graphGridOpacity};
  }

  .recharts-label {
    tspan {
      fill: ${({ theme }) => theme.colours.white};
    }
  }

  ${({ theme, report }) =>
    report &&
    css`
      &:hover {
        outline: 1px solid ${theme.colours.bulletColour};
        border-radius: ${({ theme }) => theme.borders.radius};
      }
    `}

  ${({ theme, active }) =>
    active &&
    css`
      outline: 1px solid ${theme.colours.bulletColour};
      border-radius: ${({ theme }) => theme.borders.radius};
    `}
`;

export const StickersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  height: 100%;
  flex: auto;
`;

export const LighthouseScoreBox = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colours.foreground};
`;

export const LighthouseSticker = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
`;

export const LighthouseContainerTitle = styled.p`
  font-size: 12px;
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  color: ${({ theme }) =>
    theme.isDark ? theme.colours.mainText : theme.colours.fadedMainText};
`;

export const LighthouseContainer = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const LighthouseValue = styled.p`
  font-size: ${({ $label }) => ($label ? "16px" : "24px")};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: right;
`;

export const Dot = styled.div`
  height: ${({ size, theme }) => size || theme.spacing.padding};
  width: ${({ size, theme }) => size || theme.spacing.padding};
  background-color: ${({ colour, theme }) =>
    (colour === "passed" && theme.gradients.passing_end) ||
    (colour === "failed" && theme.gradients.critical_start) ||
    (colour === "moderate" && theme.gradients.moderate_end)};
  border-radius: 25%;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  margin: ${({ theme }) => theme.spacing.paddingSM};
`;
