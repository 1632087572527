import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};

  overflow-y: auto;
`;

Card.Label = styled.h3`
  width: 100%;
  text-align: center;
  padding: 8px;
  font-weight: 500;
`;
