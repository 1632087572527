import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";
import { TEST_CATEGORIES } from "../../../../../static/constants/constants";

export const useCreateManualRun = runType => {
  const { post, status, body } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && body)
      createNotification(
        types.SUCCESS,
        runType === TEST_CATEGORIES.TEST_EVOLVE_ACCESSIBILITY
          ? "runView.testevolveaccessibility.createRunSuccess"
          : "runView.testevolveexploratory.createRunSuccess"
      );
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.createRunError"
      );
  }, [status.isRejected, status.isResolved, body]);

  const createManualRun = e => post(ENDPOINTS.EXPLORATORY_RUN, e);

  return {
    createManualRun,
    createManualRunSucess: status.isResolved,
  };
};
