import styled, { css } from "styled-components";
import { ReactComponent as Icon } from "../../../static/assets/icon-open-menu.svg";
import { ReactComponent as IconFreeze } from "../../../static/assets/icon-freeze.svg";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";

export const NodeCardContainer = styled.div`
  position: relative;
  cursor: ${({ displayBanner }) => (displayBanner ? "not-allowed" : "pointer")};
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.sizing.nodeCardWidth};
  min-width: ${({ theme }) => theme.sizing.nodeCardWidth};
  height: ${({ theme }) => theme.sizing.nodeCardHeight};
  max-height: ${({ theme }) => theme.sizing.nodeCardHeight};
  background-color: ${({ theme }) => theme.colours.nodeCardBackground};
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  gap: ${({ theme }) => theme.spacing.paddingSM};

  &:hover {
    transition: transform 0.2s;
    transform: scale(1.02);
    z-index: 98;
  }
  scroll-behavior: smooth;
  scroll-snap-align: center;
  box-shadow: ${({ theme, frozen }) =>
    frozen ? theme.decoration.cardShadowHover : theme.decoration.cardShadow};

  outline: ${({ theme, frozen }) =>
    frozen ? `4px solid ${theme.colours.freezeButtonText}` : "none"};
`;

export const CenteredContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StyledLabel = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.larger};
  padding: ${({ theme }) => theme.spacing.paddingSM} 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: ${({ theme }) => theme.sizing.nodeCardLabelWidth};
`;

export const NodeType = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const OpenMenuIcon = styled(Icon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  z-index: 90;
  color: ${({ theme }) => theme.colours.mainText};

  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colours.background : theme.colours.foreground};

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 90;
  .szh-menu {
    background-color: ${({ theme }) => theme.colours.dropdownBG};
    border: 1px ${({ theme }) => theme.borders.dropdownBorder} solid;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    padding: ${({ theme }) => theme.spacing.paddingSM};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
    z-index: 99;
  }

  .szh-menu__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${({ theme }) => theme.sizing.dropdownRowHeight};
    z-index: 99;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    gap: ${({ theme }) => theme.spacing.paddingSM};

    &:hover {
      background-color: ${({ theme }) => theme.colours.dropdownHighlight};
      p {
        opacity: 100%;
      }
    }
  }

  svg {
    opacity: ${({ active }) => (active ? "100%" : "60%")};
    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  justify-content: flex-start;
  color: ${({ theme, $red }) =>
    $red ? theme.colours.error : theme.colours.mainText};
  p {
    text-transform: capitalize;
    color: ${({ theme, $red }) =>
      $red ? theme.colours.error : theme.colours.mainText};
  }

  svg {
    height: ${({ theme }) => theme.sizing.svgHeightMD};
    width: auto;
  }
`;

export const StyledPositiveMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.colours.mainText};
  p {
    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const StyledMenu = styled(ControlledMenu)`
  .szh-menu {
    background-color: ${({ theme }) => theme.colours.dropdownBG};
    border: 1px ${({ theme }) => theme.borders.dropdownBorder} solid;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    padding: ${({ theme }) => theme.spacing.paddingSM};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
    z-index: 99;
  }

  .szh-menu__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${({ theme }) => theme.sizing.dropdownRowHeight};
    z-index: 99;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    gap: ${({ theme }) => theme.spacing.paddingSM};

    &:hover {
      background-color: ${({ theme }) => theme.colours.dropdownHighlight};
      p {
        opacity: 100%;
      }
    }
  }

  color: ${({ theme }) => theme.colours.mainText};
  z-index: 999;
  svg {
    opacity: ${({ active }) => (active ? "100%" : "60%")};
    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  vertical-align: center;
  align-items: center;

  svg {
    height: ${({ theme }) => theme.sizing.svgHeight};
    width: ${({ theme }) => theme.sizing.svgHeight};
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  color: ${({ theme }) => theme.colours.mainText};
  gap: ${({ theme }) => theme.spacing.paddingXSM};

  font-size: ${({ theme }) => theme.fontSize.smaller};
  grid-column: ${({ double }) => double && "span 2"};
  max-width: ${({ theme }) => theme.sizing.nodeCardLabelWidth};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ runProperties }) =>
    !runProperties &&
    css`
      padding: ${({ theme }) => theme.spacing.paddingXSM}
        ${({ theme }) => theme.spacing.padding};
      border-radius: ${({ theme }) => theme.borders.radiusSM};
      box-shadow: ${({ theme }) => theme.decoration.cardShadow};
      background-color: ${({ theme }) => theme.colours.runPropertyBackground};
    `};
`;

export const Spacer = styled.div`
  width: ${({ theme }) => theme.sizing.svgHeightMD};
`;

export const ChildContainer = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const Banner = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colours.mainText};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  position: absolute;
  z-index: 99;
  width: auto;
  font-size: ${({ theme }) => theme.fontSize.mediumLG};
  background-color: ${({ theme }) => theme.colours.background};
  padding: ${({ theme }) => theme.spacing.padding};
  border: 1px ${({ theme }) => theme.colours.haloAccentLight} solid;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  gap: ${({ theme }) => theme.spacing.paddingSM};

  position: absolute;
  top: 40%;
  left: ${({ theme }) => theme.spacing.padding};
  right: ${({ theme }) => theme.spacing.padding};
`;

export const ApprovalContainer = styled.div`
  height: ${({ theme }) => theme.sizing.btnHeight};
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const FreezeIcon = styled(IconFreeze)`
  width: auto;
  height: ${({ theme }) => theme.spacing.paddingLG};
  min-width: auto;
  min-height: ${({ theme }) => theme.spacing.paddingLG};
  color: ${({ theme }) => theme.colours.freezeButtonText};
  padding: ${({ theme }) => theme.spacing.paddingSmallest};

  ${({ isShared }) =>
    !isShared &&
    css`
      &: hover {
        background: linear-gradient(
          to left top,
          transparent 46.75%,
          currentColor 49.5%,
          currentColor 0.5%,
          transparent 52.25%
        );
        padding: ${({ theme }) => theme.spacing.paddingSmallest};
        width: auto;
        height: ${({ theme }) => theme.spacing.paddingLG};
        min-width: auto;
        min-height: ${({ theme }) => theme.spacing.paddingLG};
        color: ${({ theme }) => theme.colours.fadedMainText};
      }
    `}
`;
