import { useTranslation } from "react-i18next";
import { Modal } from "../../..";
import { useUpdateSubscription } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/hooks/updateSubscription";

export const CancelSubscriptionModal = ({
  subscriptionDetails,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { cancelSubscriptionAction } = useUpdateSubscription(closeModal);

  let newPeriodDate = new Date(subscriptionDetails.subscription_validity);
  newPeriodDate.setDate(newPeriodDate.getDate() + 1);

  return (
    <Modal
      title={t("cancelSubscription.modalTitle")}
      primary={{
        text: t("btnConfirm"),
        onClick: cancelSubscriptionAction,
        props: { danger: true },
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <p>
        {t("cancelSubscription.confirmText1", {
          newPeriodDate: newPeriodDate,
        })}
      </p>
      <p>{t("cancelSubscription.confirmText2")}</p>
      <p>{t("cancelSubscription.confirmText3")}</p>
    </Modal>
  );
};
