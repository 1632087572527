import React, { useEffect, useState } from "react";
import {
  StyledFormContainer,
  ButtonAndSpinnerContainer,
  StyledFieldContainer,
  HiddenRadio,
  StyledRadio,
  RadioContainer,
  RadioLabel,
  RadioLabelText,
  StyledContainer,
  TitleContainer,
  StyledMainContainer,
  StyledLabel,
  ErrorMessage,
  RadioGroupContainer,
} from "./reports.styles";
import { ReportRecipients } from "./reportRecipients";
import { MultiSelect } from "./MultiSelect";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Button/Button";
import { useReportPreview } from "./hooks/useReportPreview";
import { useFormValidation } from "../../../../context/hooks";
import { Input } from "../../../../components";
import { useProjectLabels } from "../../../../context/projectLabels/projectLabels.provider";
import { useApp } from "../../../../context/app/app.provider";
import { EMAIL_REPORT_LIMIT } from "../../../../static/constants/constants";
import { DatePicker } from "../../../../components/datePicker/datePicker";
import { useModal } from "../../../../context/modal/modal.provider";

const fieldNames = [
  "name",
  "labels",
  "frequency",
  "start_date",
  "distribution_emails",
];

const WEEKLY = "WEEKLY";

export const ReportForm = ({ createReport, reportConfigs }) => {
  const { isNotDesktop } = useApp();
  const initialValues = {
    frequency: WEEKLY,
    distribution_emails: [""],
    labels: [],
    name: "",
  };

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialValues);
  const [previewButtonClicked, setPreviewButtonClicked] = useState(false);
  const [createButtonClicked, setCreateButtonClicked] = useState(false);
  const { openModal, MODALS } = useModal();

  const resetFields = () => {
    setValues(initialValues);
    resetValidation();
    setErrors({});
  };

  const setFieldValue = (field, val) => {
    setValues(prev => ({ ...prev, [field]: val }));
  };

  const { t } = useTranslation();
  const { getProjectLabels, projectLabels } = useProjectLabels();
  const { reportPreview, getPreview, clearReportPreview } = useReportPreview();
  const {
    validateForm,
    isValid,
    isInvalid,
    reason,
    validation,
    resetValidation,
  } = useFormValidation();

  const isEmpty = obj => Object.keys(obj).length === 0;

  const validateFields = () => {
    const emptyFields = fieldNames.filter(field => !values[field]);

    if (
      !values.distribution_emails.length ||
      !values.distribution_emails[0].length
    )
      emptyFields.push("distribution_emails");

    let errs = {};
    emptyFields.forEach(err => {
      errs[err] = { name: err, reason: "empty" };
    });

    setErrors(errs);

    if (emptyFields.length) return;

    const emailsToValidate = values.distribution_emails.map((val, key) => ({
      name: key,
      value: val,
      validate: ["email"],
    }));
    fieldNames.forEach(field => {
      const val = values[field];
      if (!val || val.length === 0) {
        setErrors(prev => ({ ...prev, [field]: true }));
      }
    });
    validateForm(emailsToValidate);
  };

  useEffect(() => {
    resetFields();
  }, [reportConfigs]);

  useEffect(() => {
    getProjectLabels();
  }, []);

  useEffect(() => {
    if (reportPreview.content) {
      openModal(MODALS.REPORT_PREVIEW, { preview: reportPreview.content });
      clearReportPreview();
    }
  }, [reportPreview]);

  useEffect(() => {
    if (isValid && isEmpty(errors)) {
      if (previewButtonClicked) {
        setPreviewButtonClicked(false);
        getPreview(values);
      }
      if (createButtonClicked) {
        setCreateButtonClicked(false);
        createReport(values);
        resetFields();
      }
    }
  }, [isValid, isInvalid, reason, validation]);

  return (
    <StyledContainer isNotDesktop={isNotDesktop}>
      <TitleContainer isNotDesktop={isNotDesktop}>
        <h1>{t("reports.title")}</h1>
      </TitleContainer>
      {reportConfigs?.length >= EMAIL_REPORT_LIMIT ? (
        <p>{t("errors.reports.400.too_many_reports")}</p>
      ) : (
        <StyledMainContainer isNotDesktop={isNotDesktop}>
          <StyledFormContainer
            isNotDesktop={isNotDesktop}
            className={"Form"}
            columns={true}
            data-testid="form-container"
          >
            <StyledFieldContainer large={"large"} className={"Field"}>
              <StyledLabel id={"nameLabel"} htmlFor={"name"} hideLabels={false}>
                {t("reports.labels.reportName")}
              </StyledLabel>
              <Input
                name={"name"}
                placeholder={t("placeholder.reportName")}
                type={"text"}
                aria-labelledby={"name"}
                onChange={e => setFieldValue("name", e.target.value)}
                error={errors.name}
                value={values["name"]}
              />
              {errors.name && errors.name.reason && (
                <ErrorMessage>
                  {t(`reports.errors.${errors.name.reason}`, {
                    field: errors.name.name,
                  })}
                </ErrorMessage>
              )}
            </StyledFieldContainer>
            <StyledFieldContainer large={"large"} className={"Field"}>
              <StyledLabel
                id={"labelsLabel"}
                htmlFor={"react-select-2-input"}
                hideLabels={false}
              >
                {t("reports.labels.reportLabels")}
                <MultiSelect
                  id="labels"
                  name={"labels"}
                  placeholder={t("placeholder.reportLabels")}
                  options={projectLabels}
                  onChange={setFieldValue}
                  error={errors.labels}
                  values={values["labels"]}
                />
              </StyledLabel>
              {errors.labels && errors.labels.reason && (
                <ErrorMessage>
                  {t(`reports.errors.${errors.labels.reason}`, {
                    field: errors.labels.name,
                  })}
                </ErrorMessage>
              )}
            </StyledFieldContainer>
            <StyledFieldContainer large={"large"} className={"Field"}>
              <StyledLabel
                id={"reportScheduleLabel"}
                htmlFor={"frequency"}
                hideLabels={false}
              >
                {t("reports.labels.reportSchedule")}
              </StyledLabel>
              <RadioGroupContainer
                role="group"
                aria-labelledby="my-radio-group"
              >
                <RadioContainer>
                  <RadioLabel>
                    <RadioLabelText>
                      {t("reports.schedule.DAILY")}
                    </RadioLabelText>
                    <HiddenRadio
                      type="radio"
                      name="frequency"
                      value="DAILY"
                      onChange={() => setFieldValue("frequency", "DAILY")}
                    />
                    <StyledRadio checked={values["frequency"] === "DAILY"} />
                  </RadioLabel>
                </RadioContainer>
                <RadioContainer>
                  <RadioLabel>
                    <RadioLabelText>
                      {t("reports.schedule.WEEKLY")}
                    </RadioLabelText>
                    <HiddenRadio
                      type="radio"
                      name="frequency"
                      value={WEEKLY}
                      onChange={() => setFieldValue("frequency", WEEKLY)}
                    />
                    <StyledRadio checked={values["frequency"] === WEEKLY} />
                  </RadioLabel>
                </RadioContainer>
                <RadioContainer>
                  <RadioLabel>
                    <RadioLabelText>
                      {t("reports.schedule.MONTHLY")}
                    </RadioLabelText>
                    <HiddenRadio
                      type="radio"
                      name="frequency"
                      value="MONTHLY"
                      onChange={() => setFieldValue("frequency", "MONTHLY")}
                    />
                    <StyledRadio checked={values["frequency"] === "MONTHLY"} />
                  </RadioLabel>
                </RadioContainer>
              </RadioGroupContainer>
            </StyledFieldContainer>

            <StyledFieldContainer large={"large"} className={"Field"}>
              <StyledLabel
                id={"reportStartDateLabel"}
                htmlFor={"start_date"}
                hideLabels={false}
              >
                {t("reports.labels.reportStartDate")}
              </StyledLabel>
              <DatePicker
                placeholder={t("placeholder.reportStartDate")}
                onChange={date => setFieldValue("start_date", date)}
                value={values["start_date"]}
                error={errors.start_date}
                name="start_date"
                future={true}
              />
              {errors.start_date && errors.start_date.reason && (
                <ErrorMessage>
                  {t(`reports.errors.${errors.start_date.reason}`, {
                    field: "start date",
                  })}
                </ErrorMessage>
              )}
            </StyledFieldContainer>

            <StyledFieldContainer large={"large"} className={"Field"}>
              <StyledLabel
                id={"reportRecipientsLabel"}
                htmlFor={"distribution_emails"}
                hideLabels={false}
              >
                {t("reports.labels.reportRecipients")}
              </StyledLabel>
              <ReportRecipients
                name={"distribution_emails"}
                setFieldValue={setFieldValue}
                values={values["distribution_emails"]}
                error={isInvalid || !!errors.distribution_emails}
                errorKey={validation.find(val => val.isInvalid === true)}
                resetValidation={resetValidation}
              />
              {isInvalid && reason && <ErrorMessage>{reason}</ErrorMessage>}
              {errors.distribution_emails &&
                errors.distribution_emails.reason && (
                  <ErrorMessage>
                    {t(`reports.errors.${errors.distribution_emails.reason}`, {
                      field: "emails",
                    })}
                  </ErrorMessage>
                )}
            </StyledFieldContainer>
          </StyledFormContainer>
          <ButtonAndSpinnerContainer>
            <Button
              accent
              type="submit"
              className="submitButton"
              data-test="btn-previewReport"
              onClick={() => {
                setPreviewButtonClicked(true);
                validateFields();
              }}
            >
              {t("reports.button.preview")}
            </Button>
            <Button
              accent
              type="submit"
              className="submitButton"
              data-test="btn-createReport"
              onClick={() => {
                setCreateButtonClicked(true);
                validateFields();
              }}
            >
              {t("reports.button.save")}
            </Button>
          </ButtonAndSpinnerContainer>
        </StyledMainContainer>
      )}
    </StyledContainer>
  );
};
