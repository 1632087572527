import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useQueryParams } from "../../../context/hooks";
import { Button } from "../../../components/Button/Button";
import { ButtonContainer } from "../../../components/NavigationPane/navigation/navigationParent/navigationParent.styles";
import { useHistoryWithParams } from "../../../context/hooks/historyWithParams/useHistoryWithParams";

const PAGE = "page";

export const useRunHistoryPagination = (runDetails, getRunDetails) => {
  const { t } = useTranslation();
  const totalPages = Math.ceil(runDetails?.run_total / 10) || 1;
  const location = useLocation();
  const { getParam } = useQueryParams();

  const getPage = () => Number(getParam(PAGE)) || 1;
  const changePage = p => {
    history.push(`${location.pathname}?${PAGE}=${p}`);
  };

  const [page, setPage] = useState(getPage());
  const history = useHistoryWithParams();

  useEffect(() => {
    if (page < 1) changePage(1);
    setPage(getPage());
  }, [page, location.pathname, location.search]);

  useEffect(() => {
    getRunDetails(getParam(PAGE));
  }, [location.pathname, location.search]);

  const RunHistoryPaginationComponent = () => (
    <>
      <p>{`${t("runHistory.showingPage")}${page}/${totalPages}`}</p>
      <ButtonContainer>
        <Button onClick={() => changePage(page - 1)} disabled={page === 1}>
          {t("btnPrevious")}
        </Button>
        <Button
          onClick={() => changePage(page + 1)}
          disabled={page === totalPages}
        >
          {t("btnNext")}
        </Button>
      </ButtonContainer>
    </>
  );

  return {
    RunHistoryPaginationComponent,
  };
};
