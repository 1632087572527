import styled from "styled-components";

export const Method = styled.div`
  display: flex;
  flex-direction: row;

  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;

  justify-content: space-between;

  width: 100%;
  min-width: 100%;
  max-width: 100%;
  gap: ${({ theme }) => theme.spacing.padding};

  outline: 2px solid ${({ theme }) => theme.colours.background};
`;

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingMD};
  max-width: 100%;
  width: 100%:
  overflow-y: auto;
  height: ${({ theme }) => theme.sizing.featuresListHeight};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  flex-shrink: ${({ shrink }) => (shrink ? "1" : "0")};
`;

export const Label = styled.p`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colours.fadedMainText};
  margin-bottom: ${({ space, theme }) => (space ? theme.spacing.paddingSM : 0)};
`;

export const InsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  h2 {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.largeMiddler};
    margin-bottom: -${({ theme }) => theme.spacing.paddingXSM};
    color: ${({ theme }) => theme.colours.bulletColour};
  }
`;

export const Value = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  direction: rtl;

  font-size: ${({ theme }) => theme.fontSize.medium};

  bdi {
    max-width: 100%;
    width: 100%;
  }
`;
