import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";

export const useDeleteCustomer = (closeModal, setUpdated) => {
  const { t } = useTranslation();
  const { deleteApi, status, error } = useFetch();
  const { createNotification, types } = useNotifications();

  const deleteCustomerOrganisation = orgId => {
    const path = ENDPOINTS.CUSTOMER_ORGANISATION.path.replace(":org_id", orgId);

    deleteApi({ ...ENDPOINTS.CUSTOMER_ORGANISATION, path: path });
  };

  useEffect(() => {
    if (status.isResolved) {
      createNotification(
        types.SUCCESS,
        t("success.deleteCustomerOrganisation")
      );
      setUpdated();
      closeModal();
    }
    if (status.isRejected && error) {
      createNotification(
        types.ERROR,
        t(`errors.deleteCustomerOrganisation.${error.code}`)
      );
      closeModal();
    }
  }, [status.isResolved, status.isRejected, error]);

  return {
    deleteCustomerOrganisation,
  };
};
