import styled from "styled-components";
import { mediaQuery } from "../../../../utils/theme/mediaQuery";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  ${mediaQuery.lessThan("XL")`
    width: 100%;
  `}
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? "100%" : "40%")};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding: ${({ theme }) => theme.spacing.padding};
  height: ${({ theme }) => theme.sizing.latestRunWidgetHeight};
`;

export const SectionHeader = styled.h1`
  color: ${({ theme }) => theme.colours.mainText};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LeftDiv = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;
