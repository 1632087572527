import React from "react";
import {
  Bar,
  Cell,
  XAxis,
  YAxis,
  Label,
  BarChart,
  CartesianGrid,
} from "recharts";
import {
  Footer,
  Container,
  StickerLabel,
  StickerValue,
  GraphContainer,
  StickerContainer,
  StickerLargeValue,
  StickersContainer,
  HorizontalContainer,
  StickerContainerLarge,
} from "./latestRunCard.styles";
import { useTranslation } from "react-i18next";
import { useLatestRun } from "./hooks/useLatestRun";

export const LatestRunCard = (run, runDetails) => {
  const {
    timeAgo,
    chartData,
    totalVulnerabilities,
    dependencies,
    devDependencies,
    optionalDependencies,
    totalDependencies,
  } = useLatestRun();
  const { t } = useTranslation();

  return (
    <Container>
      <h1>
        {t(
          run.id === runDetails?.latest_run?.id
            ? "runView.latestRunTitle"
            : "runView.selectedRunTitle"
        )}
      </h1>
      <HorizontalContainer>
        <GraphContainer data-test="npm-audit-latest-run-graph">
          <BarChart
            width={500}
            height={400}
            barSize={12}
            data={chartData}
            margin={{ bottom: 25, left: 10 }}
          >
            <XAxis dataKey="name">
              <Label
                offset={10}
                position="bottom"
                style={{ textAnchor: "middle" }}
                value={t("graphs.violationType")}
              />
            </XAxis>
            <YAxis>
              <Label
                angle={270}
                offset={0}
                position="left"
                style={{ textAnchor: "middle" }}
                value={t("graphs.vulnerabilitiesCount")}
              />
            </YAxis>
            <CartesianGrid vertical={false} />
            <Bar
              dataKey="count"
              isAnimationActive={false}
              radius={[10, 10, 0, 0]}
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={`url(#gradient${entry.name}`}
                />
              ))}
            </Bar>
          </BarChart>
        </GraphContainer>
        <StickersContainer>
          <StickerContainerLarge data-test="npmaudit-vulnerabilites">
            <StickerLabel>
              {t("runCard.npmaudit.labels.vulnerabilities")}
            </StickerLabel>
            <StickerLargeValue>{totalVulnerabilities}</StickerLargeValue>
          </StickerContainerLarge>
          <StickerContainer data-test="npmaudit-dependencies">
            <StickerLabel>
              {t("runCard.npmaudit.labels.dependencies")}
            </StickerLabel>
            <StickerValue>{dependencies}</StickerValue>
          </StickerContainer>
          <StickerContainer data-test="npmaudit-dev-dependencies">
            <StickerLabel>
              {t("runCard.npmaudit.labels.devDependencies")}
            </StickerLabel>
            <StickerValue>
              {devDependencies} / {optionalDependencies}
            </StickerValue>
          </StickerContainer>
          <StickerContainer>
            <StickerLabel data-test="npmaudit-total-dependencies">
              {t("runCard.npmaudit.labels.totalDependencies")}
            </StickerLabel>
            <StickerValue>{totalDependencies}</StickerValue>
          </StickerContainer>
          <Footer>
            <p data-test="time-ago">{timeAgo}</p>
          </Footer>
        </StickersContainer>
      </HorizontalContainer>
    </Container>
  );
};
