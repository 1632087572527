import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { routes } from "../../../../router/routes";
import { roles } from "../../../../static/constants/constants";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import {
  Container,
  AddUserSelect,
  OrgSettingsLink,
} from "./addUserToProject.styles";
import { useFetch, usePermissions } from "../../../../context/hooks";
import { useNotifications } from "../../../../context/hooks/useNotifications/useNotifications";
import { useOrganisationUsers } from "../../../../context/organisationUsers/organisationUsers.provider";

export const AddUserToProject = ({ useProjectRoles }) => {
  const { getProjectUsers, projectUsers } = useProjectRoles;
  const filterUsers = users =>
    isPartnerAdmin()
      ? filterNonPartnerUsers(users)
      : filteredPartnerUsers(users);

  const filterNonPartnerUsers = users =>
    users
      .filter(usr => !isUserAdmin(usr))
      .filter(usr => usr.active)
      .map(user => ({ label: user.username, value: user }));

  const filteredPartnerUsers = users =>
    users
      .filter(usr => !isUserAdmin(usr))
      .filter(usr => !isPartnerAdmin(usr))
      .filter(usr => !isPartnerUser(usr))
      .filter(usr => usr.active)
      .map(user => ({ label: user.username, value: user }));

  const { t } = useTranslation();
  const { status, patch, error } = useFetch();
  const { getOrganisationUsers, organisationUsers } = useOrganisationUsers();
  const { createNotification, types } = useNotifications();
  const { isUserAdmin, isPartnerAdmin, isPartnerUser } = usePermissions();

  const [selectedUserOption, setSelectedUserOption] = useState([]);
  const [userOptions, setUserOptions] = useState(
    filterUsers(organisationUsers)
  );

  const [buttonActive, setButtonActive] = useState(false);

  useEffect(() => {
    setUserOptions(filterUsers(organisationUsers));
  }, [organisationUsers]);

  useEffect(() => {
    getOrganisationUsers();
  }, []);

  const buttonHandler = () => {
    const projectRolesApiConfig = {
      ...ENDPOINTS.USER_PROJECT_ROLES,
      path: ENDPOINTS.USER_PROJECT_ROLES.path.replace(
        ":id",
        selectedUserOption.value.id
      ),
    };

    patch(projectRolesApiConfig, [
      { key: roles.PROJECT_USER, type: "PROJECT" },
    ]);
  };

  useEffect(() => {
    if (status.isResolved) {
      createNotification(types.SUCCESS, "settings.organisation.successfulEdit");
      getProjectUsers();
    }
    setSelectedUserOption([]);
    setButtonActive(false);
  }, [status.isResolved, status.isRejected, error]);

  return (
    <Container>
      <h2>{t("settings.organisation.addUser")}</h2>
      <p>
        {t("settings.organisation.addUserSupportText")}
        <OrgSettingsLink
          to={routes.ORGANISATION_SETTINGS.path.replace(
            ":settingsTab",
            "organisation"
          )}
        >
          {t("settings.organisation.orgSettingsLinkText")}
        </OrgSettingsLink>
        {t("settings.organisation.addUserSupportText2")}
      </p>
      <AddUserSelect
        options={userOptions
          .sort((a, b) => a.label.localeCompare(b.label))
          .filter(
            usr => !projectUsers.find(prjUsr => prjUsr.username === usr.label)
          )}
        value={selectedUserOption}
        classNamePrefix={"Select"}
        onChange={e => {
          setButtonActive(true);
          setSelectedUserOption(e);
        }}
        aria-label="add-user-select"
      />
      <Button disabled={!buttonActive} onClick={buttonHandler}>
        {t("settings.organisation.addUser")}
      </Button>
    </Container>
  );
};
