import styled from "styled-components";
import { ResponsiveContainer } from "recharts";

export const HorizontalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
`;

export const GraphContainer = styled(ResponsiveContainer)`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  .recharts-cartesian-grid-horizontal {
    opacity: ${({ theme }) => theme.decoration.graphGridOpacity};
  }

  .recharts-label {
    tspan {
      fill: ${({ theme }) => theme.colours.white};
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
