import React from "react";
import { useLatestRun } from "../hooks/useLatestRun";
import { LighthouseScoreGauge } from "../../../../../components/graph/graph";
import { useTranslation } from "react-i18next";
import {
  Container,
  GraphContainer,
  Section,
  StickersContainer,
  HorizontalContainer,
  LighthouseScoreBox,
  LighthouseSticker,
  Dot,
  LighthouseContainer,
  LighthouseContainerTitle,
  LighthouseValue,
  OutsideGraphContainer,
} from "./violationsSection.styles";
import { theme } from "../../../../../theme/theme";

export const LighthouseGraphs = ({ data, report, func }) => (
  <>
    <HorizontalContainer>
      <OutsideGraphContainer
        onClick={e => func(e, "performance")}
        id="performance"
      >
        <GraphContainer report={report}>
          <LighthouseScoreGauge
            data={data.performance}
            passed={data.performance[0].value}
            containerHeight={theme.sizing.graphContainerSM}
          />
        </GraphContainer>
      </OutsideGraphContainer>
      <OutsideGraphContainer
        onClick={e => func(e, "accessibility")}
        id="accessibility"
      >
        <GraphContainer report={report}>
          <LighthouseScoreGauge
            data={data.accessibility}
            passed={data.accessibility[0].value}
            containerHeight={theme.sizing.graphContainerSM}
          />
        </GraphContainer>
      </OutsideGraphContainer>
    </HorizontalContainer>
    <HorizontalContainer>
      <OutsideGraphContainer
        onClick={e => func(e, "bestPractices")}
        id="bestPractices"
      >
        <GraphContainer report={report}>
          <LighthouseScoreGauge
            data={data.bestPractices}
            passed={data.bestPractices[0].value}
            containerHeight={theme.sizing.graphContainerSM}
          />
        </GraphContainer>
      </OutsideGraphContainer>
      <OutsideGraphContainer onClick={e => func(e, "seo")} id="seo">
        <GraphContainer report={report}>
          <LighthouseScoreGauge
            data={data.seo}
            passed={data.seo[0].value}
            containerHeight={theme.sizing.graphContainerSM}
          />
        </GraphContainer>
      </OutsideGraphContainer>
    </HorizontalContainer>
  </>
);

export const ViolationsSection = ({ run }) => {
  const { data } = useLatestRun(run);
  const { t } = useTranslation();

  return (
    <Container>
      <Section data-test="violation-gauges">
        <LighthouseGraphs data={data} />
        <HorizontalContainer>
          <StickersContainer>
            <LighthouseScoreBox>
              <LighthouseSticker>
                <LighthouseContainer data-test="lighthouse-poor-sticker">
                  <LighthouseContainerTitle>
                    {t("runView.testevolvelighthouse.rating.fail")}
                  </LighthouseContainerTitle>
                  <Dot colour={"failed"} rotate="45" />
                  <LighthouseValue $label={true}>{"0-49"}</LighthouseValue>
                </LighthouseContainer>
              </LighthouseSticker>
            </LighthouseScoreBox>
            <LighthouseScoreBox>
              <LighthouseSticker>
                <LighthouseContainer data-test="lighthouse-needs-improvement-sticker">
                  <LighthouseContainerTitle>
                    {t("runView.testevolvelighthouse.rating.moderate")}
                  </LighthouseContainerTitle>
                  <Dot colour={"moderate"} rotate="45" />
                  <LighthouseValue $label={true}>{"50-89"}</LighthouseValue>
                </LighthouseContainer>
              </LighthouseSticker>
            </LighthouseScoreBox>
            <LighthouseScoreBox>
              <LighthouseSticker>
                <LighthouseContainer data-test="lighthouse-good-sticker">
                  <LighthouseContainerTitle>
                    {t("runView.testevolvelighthouse.rating.pass")}
                  </LighthouseContainerTitle>
                  <Dot colour={"passed"} rotate="45" />
                  <LighthouseValue $label={true}>{"90-100"}</LighthouseValue>
                </LighthouseContainer>
              </LighthouseSticker>
            </LighthouseScoreBox>
          </StickersContainer>
        </HorizontalContainer>
      </Section>
    </Container>
  );
};
