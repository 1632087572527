import { Menu, MenuRow, ScreenCover, MenuRowText } from "./dropdownMenu.styles";
import { DarkModeToggle } from "react-dark-mode-toggle-2";
import { useTheme } from "../../../context/theme/theme.provider";
import { useTranslation } from "react-i18next";
import { routes } from "../../../router/routes";
import { usePermissions } from "../../../context/hooks";
import { useSignout } from "../../../context/signout/useSignout";
import { useHistoryWithParams } from "../../../context/hooks/historyWithParams/useHistoryWithParams";

export const DropdownMenu = ({ toggleOpen }) => {
  const history = useHistoryWithParams();
  const { t } = useTranslation();
  const { signout } = useSignout();
  const { isDark, toggleDark } = useTheme();
  const { isUserAdmin, isPartnerAdmin } = usePermissions();

  return (
    <>
      <Menu data-test="user-dropdown">
        {isUserAdmin() || isPartnerAdmin() ? (
          <MenuRow
            data-test="settings-row"
            onClick={() => {
              toggleOpen();
              history.push(
                routes.ORGANISATION_SETTINGS.path.replace(
                  ":settingsTab",
                  "organisation"
                )
              );
            }}
          >
            <MenuRowText
              onClick={() => {
                toggleOpen();
                history.push(
                  routes.ORGANISATION_SETTINGS.path.replace(
                    ":settingsTab",
                    "organisation"
                  )
                );
              }}
            >
              {t("header.orgSettings")}
            </MenuRowText>
          </MenuRow>
        ) : null}
        <MenuRow
          data-test="signout-row"
          onClick={() => {
            toggleOpen();
            history.push("/profile");
          }}
        >
          <MenuRowText
            onClick={() => {
              toggleOpen();
              history.push("/profile");
            }}
          >
            {t("header.profileSettings")}
          </MenuRowText>
        </MenuRow>
        <MenuRow
          data-test="downloads-row"
          onClick={() => {
            toggleOpen();
            history.push(routes.DOWNLOADS.path);
          }}
        >
          <MenuRowText
            onClick={() => {
              toggleOpen();
              history.push(routes.DOWNLOADS.path);
            }}
          >
            {t("header.downloads")}
          </MenuRowText>
        </MenuRow>
        <MenuRow data-test="toggle-row">
          <MenuRowText>{t("header.light")}</MenuRowText>
          <DarkModeToggle onChange={toggleDark} isDarkMode={isDark} size={64} />
          <MenuRowText>{t("header.dark")}</MenuRowText>
        </MenuRow>
        <MenuRow data-test="signout-row" onClick={() => signout()}>
          <MenuRowText onClick={() => signout()}>
            {t("header.signOut")}
          </MenuRowText>
        </MenuRow>
      </Menu>
      <ScreenCover onClick={toggleOpen} onWheel={toggleOpen} />
    </>
  );
};
