import React from "react";
import { useParams } from "react-router";
import { Progress } from "./progress/Progress";
import { routes } from "../../../../../router/routes";
import { StyledTestsList, StyledTestSuite } from "./styles";
import { STATUSES } from "../../../../../static/constants/constants";
import { useModal } from "../../../../../context/modal/modal.provider";
import { useHistoryWithParams } from "../../../../../context/hooks/historyWithParams/useHistoryWithParams";

export const processTests = run => {
  if (run.test_suites && run.test_suites.length)
    return sortFeaturesByFailures(run.test_suites);
  return [];
};

const sortFeaturesByFailures = testSuites =>
  testSuites.sort((a, b) => {
    const aPassPercentage = (a.tests - (a.failures + a.errors)) / a.tests;
    const bPassPercentage = (b.tests - (b.failures + b.errors)) / b.tests;

    if (aPassPercentage === bPassPercentage)
      return a.name.localeCompare(b.name);
    return aPassPercentage - bPassPercentage;
  });

export const Tests = ({ run, isShared }) => {
  const { nodeId } = useParams();
  const history = useHistoryWithParams();
  const { openModal, MODALS } = useModal();

  const reportUrl = `${routes.RUN_VIEW_REPORT.path
    .replace(":nodeId", nodeId)
    .replace(":runType", run.category)
    .replace(":label", encodeURI(encodeURIComponent(run.label)))
    .replace(":runId", run.id)}`;

  const onTestClick = suite =>
    isShared
      ? openModal(MODALS.SHARE_RUN, { run })
      : history.pushWithHash(reportUrl, suite.name.replace(/ /g, ""));

  return (
    <StyledTestsList>
      {processTests(run).map((suite, index) => (
        <Test
          key={index}
          onTestClick={onTestClick}
          suite={suite}
          index={index}
        />
      ))}
    </StyledTestsList>
  );
};

const Test = ({ suite, index, onTestClick }) => {
  const passed = suite.tests - suite.failures;
  const passPercentage =
    suite.tests === 0 ? 0 : Math.round((passed / suite.tests) * 100);

  return (
    <StyledTestSuite key={index} onClick={() => onTestClick(suite)}>
      <Progress
        fullWidth
        label={suite.file || suite.name}
        labelAlignLeft={true}
        percentageLabelValue={passPercentage}
        showPercentageLabel={true}
        showPercentageLabelLeft
        percent={100}
        largerLine={true}
        animate={true}
        animated={true}
        data={[
          { type: STATUSES.PASSED, value: passPercentage },
          {
            type: STATUSES.FAILED,
            value: 100 - passPercentage,
          },
        ]}
        data-test="progress-feature"
        rounded
      />
    </StyledTestSuite>
  );
};
