import styled from "styled-components";

export const BugsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  height: 100%;
  width: 100%;
`;
