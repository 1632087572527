import { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth/auth.provider";
import { useOrganisation } from "../../../context/organisation/organisation.provider";
import { useUserOptions } from "../../../context/admin/userOptions/userOptions.provider";
import { useAuthCheck } from "./useAuthCheck";
import { useSubscription } from "../../../context/subscription/subscription.provider";
import { useUser } from "../../../context/user/user.provider";
import { useGetProjects } from "../../../context/project/useGetProjects";
import { usePermissions } from "../../../context/hooks";
import { LOADING } from "../../../static/constants/constants";

export const useInit = () => {
  const [ready, setReady] = useState(false);

  const { organisationActive } = useOrganisation();
  const { setUnAuthenticated, isAuthenticated } = useAuth();
  const { getUserOptions, userOptionsSuccess } = useUserOptions();
  const { getSubscription, subscription } = useSubscription();
  const { getOrganisation, organisationName } = useOrganisation();
  const { getUser, username } = useUser();
  const { getProjects, projects } = useGetProjects();
  const { isAdmin } = usePermissions();
  const { checkAuth } = useAuthCheck();

  const checkAuthOptions = () => {
    getUserOptions();
    checkAuth();
  };

  useEffect(() => {
    if (
      username &&
      subscription?.subscription_id &&
      organisationName &&
      projects
    )
      setReady(true);
  }, [username, subscription, organisationName, projects]);

  const getAllSetup = () => {
    getUser();
    getProjects();
    getOrganisation();
    getSubscription();
  };

  return {
    getAllSetup,
    isAuthenticated,
    setUnAuthenticated,
    checkAuthOptions,
    organisationActive,
    isOrgLoaded: organisationActive !== LOADING,
    ready: isAdmin() ? userOptionsSuccess && ready : ready,
  };
};
