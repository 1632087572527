import { useApp } from "../../../context/app/app.provider";
import { useGetRelease } from "../../../context/releases/useGetRelease";
import { NodeCard } from "../../nodeView/nodeCardContainer/nodeCard";
import {
  CardsContainer,
  NodeCardWrapper,
} from "../../nodeView/nodeCardContainer/nodeCardContainer.styles";

export const ReleaseRuns = ({ isShared }) => {
  const { isNotDesktop } = useApp();
  const { release } = useGetRelease();

  return (
    <CardsContainer isNotDesktop={isNotDesktop}>
      {release?.runs?.map(run => (
        <NodeCardWrapper key={run.id}>
          <NodeCard
            key={run.id}
            category={run.category}
            run={run}
            onRelease={release}
            frozen={run.frozen}
            isShared={isShared}
          />
        </NodeCardWrapper>
      ))}
    </CardsContainer>
  );
};
