import { useEffect, useState, useRef } from "react";
import { useFetch, useFormValidation } from "../../../../../context/hooks";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";
import { useOrganisationUsers } from "../../../../../context/organisationUsers/organisationUsers.provider";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useSubscription } from "../../../../../context/subscription/subscription.provider";

export const useInviteUser = () => {
  const formRef = useRef(null);

  const [inputText, setInputText] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);

  const { subscription, getSubscription } = useSubscription();
  const { createNotification, types } = useNotifications();
  const { post, status, reset, error, body } = useFetch();
  const { reason, isValid, isInvalid, validateForm, resetValidation } =
    useFormValidation();
  const { getOrganisationUsers } = useOrganisationUsers();

  const resetField = () => {
    if (error)
      createNotification(types.ERROR, `errors.inviteUser.${error.code}`);
    if (isInvalid)
      createNotification(types.ERROR, "errors.validation.invalidEmail");

    setSelectedProjects([]);
    setInputText("");
    resetValidation();
    reset();
  };

  useEffect(() => {
    if (status.isResolved) {
      createNotification(
        types.SUCCESS,
        "settings.organisation.successfulInvite"
      );
      getOrganisationUsers();
      getSubscription();
      resetField();
    }
    if (status.isRejected) resetField();
  }, [status.isRejected, status.isResolved, body]);

  useEffect(() => {
    if (isValid)
      post(ENDPOINTS.USER_INVITE, {
        username: inputText,
        projects: selectedProjects.map(prj => prj.value.id),
      });
    if (isInvalid) resetField();
  }, [isValid, isInvalid]);

  const buttonHandler = () => {
    validateForm([
      { name: "Email address", value: inputText, validate: ["email"] },
    ]);
  };

  return {
    formRef,
    inputText,
    resetField,
    buttonHandler,
    setInputText,
    selectedProjects,
    setSelectedProjects,
    error: status.isRejected,
    formError: {
      valid: isValid,
      invalid: isInvalid,
      reason: reason,
    },
    users: subscription.used_seats,
    maxUsers: subscription.max_seats,
  };
};
