import { useTranslation } from "react-i18next";
import { DownloadContainer, SectionTitle } from "../home.styles";
import {
  Cell,
  GettingStartedContainer,
  ProgressBar,
  ProgressBarContainer,
  Row,
  SectionContent,
  StyledCircle,
} from "./gettingStarted.styles";
import { getOS } from "../../../utils/os/getOS";
import { useDownloads } from "../../downloads/hooks/useDownloads";
import { CustomLink } from "../../../components/Link/StyledLink";
import {
  DOCUMENTATION_LINK_CREATE_PROJECT,
  DOCUMENTATION_LINK_RUNNING_TESTS,
  PLATFORM_DOWNLOAD_LINK,
} from "../../../static/constants/constants";
import { Button } from "../../../components";
import { useHome } from "../context/home.context";
import { useEffect, useRef } from "react";
import { TickIcon } from "./tickIcon";

export const GettingStarted = () => {
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const { downloadVersion } = useDownloads();
  const { step, setStep, hasData, hideGuide } = useHome();

  const incrementStep = () => setTimeout(() => changeStep(step + 1), 400);

  const scroll = e =>
    document
      ?.getElementById(`cell-${e}`)
      ?.scrollIntoView({ behavior: "smooth", block: "nearest" });

  const changeStep = e => {
    setTimeout(() => scroll(e), 500);
    setStep(e);
  };

  useEffect(() => {
    if (hasData && step >= 3) setTimeout(() => hideGuide(), 2000);
    if (hasData && step < 3) incrementStep();
  }, [hasData, step]);

  return (
    <GettingStartedContainer>
      <SectionTitle>{t("projectHome.guide.title")}</SectionTitle>
      <p>{t("projectHome.guide.supportText")}</p>
      <SectionContent ref={containerRef}>
        <Row>
          <Cell id="cell-0">
            <StyledCircle isCompleted={step > 0}>
              {step > 0 ? <TickIcon /> : 1}
            </StyledCircle>
          </Cell>
          <Cell id="cell-1">
            <StyledCircle isCompleted={step > 1}>
              {step > 1 ? <TickIcon /> : 2}
            </StyledCircle>
          </Cell>
          <Cell id="cell-2">
            <StyledCircle isCompleted={step > 2}>
              {step > 2 ? <TickIcon /> : 3}
            </StyledCircle>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <ProgressBarContainer which={0}>
              <ProgressBar isCompleted={step > 0} />
            </ProgressBarContainer>
          </Cell>
          <Cell>
            <ProgressBarContainer>
              <ProgressBar isCompleted={step > 1} />
            </ProgressBarContainer>
          </Cell>
          <Cell>
            <ProgressBarContainer>
              <ProgressBar isCompleted={step > 2} />
            </ProgressBarContainer>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <h3>{t("projectHome.guide.downloadHeading")}</h3>
          </Cell>
          <Cell>
            <h3>{t("projectHome.guide.createProjectHeading")}</h3>
          </Cell>
          <Cell>
            <h3>{t("projectHome.guide.runTestHeading")}</h3>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <p>{t("projectHome.guide.downloadText")}</p>
          </Cell>
          <Cell>
            <p>{t("projectHome.guide.createProjectText")}</p>
          </Cell>
          <Cell>
            <p>{t("projectHome.guide.runTestText")}</p>
          </Cell>
        </Row>
        <Row>
          <Cell></Cell>
          <Cell>
            <CustomLink external accent to={DOCUMENTATION_LINK_CREATE_PROJECT}>
              {t("seeMore")}
            </CustomLink>
          </Cell>
          <Cell>
            <CustomLink accent external to={DOCUMENTATION_LINK_RUNNING_TESTS}>
              {t("seeMore")}
            </CustomLink>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <DownloadContainer>
              <CustomLink
                button
                accent
                external
                small
                to={getDownloadLink(downloadVersion)}
              >
                <Button onClick={() => changeStep(1)}>
                  {t("btnDownload")}
                </Button>
              </CustomLink>
              <p onClick={() => changeStep(1)}>
                {t("projectHome.guide.alreadyHaveIt")}
              </p>
            </DownloadContainer>
          </Cell>
          <Cell>
            <DownloadContainer>
              <Button onClick={() => changeStep(2)} disabled={step !== 1}>
                {t("Done")}
              </Button>
            </DownloadContainer>
          </Cell>
        </Row>
      </SectionContent>
    </GettingStartedContainer>
  );
};

const getDownloadLink = downloadVersion => {
  const os = getOS();
  if (!os || !PLATFORM_DOWNLOAD_LINK[os]) return;

  return PLATFORM_DOWNLOAD_LINK[os](downloadVersion);
};
