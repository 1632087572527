import { useEffect, useState } from "react";
import { useFetch } from "../../hooks";
import { createState } from "../../../utils/context";
import { ApiKeysReducer } from "./apiKeys.reducer";
import { ACTIONS } from "../../../static/constants/actions";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useNotifications } from "../../hooks/useNotifications/useNotifications";
import { useTranslation } from "react-i18next";

const initialState = {
  apiKeys: [],
};

const [useHook, ApiKeysProvider] = createState(
  "apiKeys",
  initialState,
  ApiKeysReducer
);

const useApiKeys = () => {
  const { apiKeys, dispatch } = useHook();

  const { t } = useTranslation();
  const { createNotification, types } = useNotifications();
  const { get, deleteApi, post, body, status, error, method } = useFetch();

  const [modalOpen, setModalOpen] = useState(false);
  const [alias, setAlias] = useState("");

  useEffect(() => {
    if (status.isResolved && method === "GET") setApiKeys(body);
    if (status.isResolved && method === "DELETE") {
      createNotification(types.SUCCESS, t("settings.apiKeys.successfulDelete"));
      getApiKeys();
    }
    if (status.isResolved && method === "POST" && body) setModalOpen(true);
    if (status.isRejected && method === "POST") {
      setAlias("");
      createNotification(types.ERROR, t(`errors.createKey.${error.code}`));
    }
  }, [status.isResolved, status.isRejected, error, body]);

  const setApiKeys = payload =>
    dispatch({ type: ACTIONS.SET_API_KEYS, payload: payload });

  const getApiKeys = () => get(ENDPOINTS.API_KEYS);

  const deleteApiKey = payload =>
    deleteApi(ENDPOINTS.API_KEYS, { api_key_identifier: payload });

  const createApiKey = () => post(ENDPOINTS.API_KEYS, { api_key_alias: alias });

  return {
    alias,
    body,
    apiKeys,
    setAlias,
    modalOpen,
    getApiKeys,
    setModalOpen,
    deleteApiKey,
    createApiKey,
  };
};

export { useApiKeys, ApiKeysProvider };
