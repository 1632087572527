import styled, { css } from "styled-components";
import { SvgIcon } from "../../../../components/icon/icon";
import { ReactComponent as RobotIcon } from "../../../../static/assets/icon-robot.svg";

import { ACCESSIBILITY_STATUSES } from "../../../../static/constants/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  color: ${({ theme }) => theme.colours.mainText};

  padding: ${({ theme }) => theme.spacing.padding};

  margin-left: ${({ theme, guideline, successCriteria }) =>
    successCriteria
      ? theme.spacing.paddingLGR
      : guideline
      ? theme.spacing.paddingMD
      : 0};

  gap: ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.mainText};
  border: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.altForeground}` : "none"};

  p {
    line-height: 1.5;
  }

  h2 {
    font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  }
`;

export const ReferencesContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? "column" : "row")};

  gap: ${({ theme }) => theme.spacing.paddingSM};

  a {
    color: ${({ theme }) => theme.colours.mainText};
    text-decoration: none !important;
  }
`;

export const Reference = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  outline: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.altForeground}` : "none"};
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  color: ${({ theme }) => theme.colours.mainText};

  svg {
    color: ${({ theme }) => theme.colours.mainText};
  }

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  svg {
    height: ${({ theme }) => theme.sizing.iconSmall} !important;
    width: ${({ theme }) => theme.sizing.iconSmall} !important;
  }

  ${({ level }) =>
    level &&
    css`
      outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
    `}
`;

export const SpecialCase = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const DeprecatedLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSize.larger};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  color: ${({ theme }) => theme.colours.error};

  border: 2px solid ${({ theme }) => theme.colours.error};

  width: fit-content;
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radiusLG};
  margin-top: -${({ theme }) => theme.spacing.padding};
`;

export const SetStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};

  outline: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.altForeground}` : "none"};
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  color: ${({ theme }) => theme.colours.mainText};

  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ChooseStatusContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${({ theme }) => theme.spacing.padding};

  button {
    cursor: pointer;
    width: ${({ theme }) => theme.sizing.statusWidth};
    height: ${({ theme }) => theme.sizing.btnHeight};
    border-radius: ${({ theme }) => theme.borders.radius};
    border: none;
  }
`;

export const StatusSticker = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme, status }) =>
    status === ACCESSIBILITY_STATUSES.PASSED.toUpperCase()
      ? theme.colours.closedButtonBackground
      : status === ACCESSIBILITY_STATUSES.FAILED.toUpperCase()
      ? theme.colours.pale_red
      : theme.colours.light_grey};

  color: ${({ theme, status }) =>
    status === ACCESSIBILITY_STATUSES.PASSED.toUpperCase()
      ? theme.colours.closedButtonForeground
      : status === ACCESSIBILITY_STATUSES.FAILED.toUpperCase()
      ? theme.colours.severity.highest
      : theme.colours.status.skipped};

  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
  justify-content: center;
  width: ${({ w }) => w || "fit-content"};
  min-width: ${({ w }) => w || "fit-content"};
  height: ${({ theme }) => theme.sizing.statusHeight};
  min-height: ${({ theme }) => theme.sizing.statusHeight};

  border-radius: ${({ theme }) => theme.borders.radius};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: pointer;
  max-width: fit-content;

  svg {
    color: ${({ theme, status }) =>
      status === ACCESSIBILITY_STATUSES.PASSED.toUpperCase()
        ? theme.colours.closedButtonForeground
        : status === ACCESSIBILITY_STATUSES.FAILED.toUpperCase()
        ? theme.colours.severity.highest
        : theme.colours.status.skipped};
    opacity: 1;
  }
`;

export const Chevron = styled(SvgIcon)`
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: auto;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(90deg)")};
  transition: ${({ theme }) => theme.transitionTime.fast};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? "column" : "row")};
  justify-content: space-between;
  align-items: center;
`;

export const NoteButton = styled.button`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  border: none;

  width: ${({ theme }) => theme.sizing.statusHeight};
  height: ${({ theme }) => theme.sizing.statusHeight};

  color: ${({ theme }) => theme.colours.mainText};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }
`;

export const NoteButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: fit-content;

  p {
    font-size: ${({ theme }) => theme.fontSize.smaller};
  }

  ${({ theme }) =>
    theme.isNotDesktop &&
    css`
      align-items: center;
      gap: ${({ theme }) => theme.spacing.paddingSM};
      margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
    `}

  ${({ theme, status }) =>
    theme.isDesktop &&
    css`
      height: ${({ theme }) => theme.sizing.statusSticker};
      max-height: ${({ theme }) => theme.sizing.statusSticker};
      align-items: ${status ? "flex-end" : "center"};

      #run-status-sticker {
        margin-bottom: ${status ? theme.spacing.paddingSM : 0};
      }
    `}
`;

export const StepsToAssessContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};

  h3 {
    font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
    font-size: ${({ theme }) => theme.fontSize.mediumLarge};
  }

  ul {
    padding-left: ${({ theme }) => theme.spacing.paddingSM};
  }
`;

export const SuccessCriteriaStepContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const AssessmentNotesContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid ${({ theme }) => theme.colours.haloAccent};
  padding: ${({ theme }) => theme.spacing.padding};
  height: 100%;
`;

export const AssessmentNotes = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${({ theme }) => theme.sizing.assessmentMinWidth};
  max-width: ${({ theme }) => theme.sizing.assessmentMaxWidth};
  flex-grow: 1;
  width: 80%;

  gap: ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};

  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 100%;
      max-width: 100%;
    `}

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      position: relative;

      h2 {
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        text-wrap: nowrap;
      }

      #editor-container {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;

        div:first-child {
          height: 100%;
        }
      }

      #rich-text-editor {
        height: 100%;
        max-height: 100%;
        overflow: hidden;
      }

      #editor-shell {
        height: 100%;
        max-height: 100%;
        overflow: hidden;
      }

      #rte-editor {
        height: 100%;
        max-height: 100%;
        overflow: hidden;
      }

      #rte-container {
        max-height: 100%;
        height: 95%;
      }

      .ContentEditable__root {
        height: 100%;
      }
    `}
`;

export const RichTextEditorContainer = styled.div`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 100%;
      max-width: 100%;
    `}

  ${({ theme }) =>
    !theme.isMobile &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding: ${({ theme }) => theme.spacing.padding};
  width: fit-content;
  z-index: 2;
  bottom: 0;
  border: 1px solid ${({ theme }) => theme.colours.haloAccent};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const NoteTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;
  color: ${({ theme }) => theme.colours.mainText};

  svg {
    height: ${({ theme }) => theme.sizing.iconSize};
    width: ${({ theme }) => theme.sizing.iconSize};
    min-height: ${({ theme }) => theme.sizing.iconSize};
    min-width: ${({ theme }) => theme.sizing.iconSize};
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colours.mainText};

  gap: ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.mainText};

  overflow-y: hidden;
  height: ${({ showFullDescription }) =>
    showFullDescription ? "fit-content" : "40px"};

  p {
    line-height: 1.5;
  }

  h2 {
    font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  }
`;

export const SeeMoreDescription = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -${({ theme }) => theme.spacing.paddingSM};

  ${({ showFullDescription }) =>
    !showFullDescription &&
    css`
      box-shadow: 0 0 16px 16px ${({ theme }) => theme.colours.foreground};
    `}

  p {
    color: ${({ theme }) => theme.colours.bulletColour};
  }
`;

export const StyledRobotIcon = styled(RobotIcon)`
  height: ${({ theme }) => theme.sizing.iconSize};
  width: ${({ theme }) => theme.sizing.iconSize};
  color: ${({ theme }) => theme.colours.bulletColour};
`;

export const AutomationContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  color: ${({ theme }) => theme.colours.bulletColour};
  margin-top: -${({ theme }) => theme.spacing.padding};

  border: 2px solid ${({ theme }) => theme.colours.bulletColour};
  width: fit-content;
  padding: ${({ theme }) => theme.spacing.paddingXSM}
    ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radiusLG};

  p {
    color: ${({ theme }) => theme.colours.bulletColour};
    font-size: ${({ theme }) => theme.fontSize.mediumLarge};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: ${({ theme }) => theme.sizing.iconSize};
  }
`;

export const StepsToAssessTitle = styled.h2`
  color: ${({ theme }) => theme.colours.bulletColour};
`;

export const StepsToAssessSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  color: ${({ theme }) => theme.colours.mainText};
  border: 1px solid ${({ theme }) => theme.colours.haloAccent};

  padding: ${({ theme }) => theme.spacing.padding};

  margin-left: ${({ theme, guideline, successCriteria }) =>
    successCriteria
      ? theme.spacing.paddingLGR
      : guideline
      ? theme.spacing.paddingMD
      : 0};

  gap: ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.mainText};

  p {
    line-height: 1.5;
  }

  h2 {
    font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  }
`;
