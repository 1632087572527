import { useEffect, useState } from "react";
import { useFetch } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

export const useUnassignedLabels = () => {
  const [labels, setLabels] = useState([]);
  const [labelCount, setLabelCount] = useState(0);
  const [labelsWithRuns, setLabelsWithRuns] = useState([]);

  useEffect(() => {
    if (labels && labels.total_labels) setLabelCount(labels.total_labels);
    if (labels && labels.labels_with_runs)
      setLabelsWithRuns(
        Object.keys(labels?.labels_with_runs).map(key => ({
          label: key,
          runs: labels?.labels_with_runs[key],
        }))
      );
  }, [labels, labels.labels_with_runs]);

  const { get, status, body, error } = useFetch();

  const getLabels = (page = 1) =>
    get({
      ...ENDPOINTS.UNASSIGNED_LABELS_WITH_RUNS,
      path: ENDPOINTS.UNASSIGNED_LABELS_WITH_RUNS.path.replace(":page", page),
    });

  useEffect(() => {
    if (status.isResolved && body) setLabels(body);
    if (status.isResolved && !body) {
      setLabels([]);
      setLabelCount(0);
      setLabelsWithRuns([]);
    }
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    labelCount,
    labels: labelsWithRuns,
    getLabels,
    loading: status.isLoading,
  };
};
