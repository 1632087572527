import { useTranslation } from "react-i18next";
import {
  EmptyContentContainer,
  SectionContainer,
  SectionContent,
  SectionTitle,
} from "../home.styles";
import { TestEvolveMinimalRunCard } from "../../../components/runCard/minimalRunCards/testEvolveFunctional/testEvolveMinimalCard";
import { FakeMinimalRunCard } from "../../../components/runCard/minimalRunCards/minimalRunCard.styles";
import image from "../../../static/assets/no_data_image.png";
import { useHome } from "../context/home.context";

export const InProgressRuns = () => {
  const { t } = useTranslation();
  const { inProgressRuns, loadingInProgressRuns } = useHome();

  return (
    <SectionContainer>
      <SectionTitle>{t("projectHome.inProgressRunsTitle")}</SectionTitle>
      <p>{t("projectHome.inProgressRunsText")}</p>
      <SectionContent tabIndex={0}>
        {loadingInProgressRuns && !inProgressRuns?.length ? (
          [...Array(5)].map((_, i) => (
            <FakeMinimalRunCard key={`fake-in-progress-${i}`} />
          ))
        ) : inProgressRuns && inProgressRuns?.length ? (
          inProgressRuns.map(run => (
            <TestEvolveMinimalRunCard
              key={`in-progress-run-${run.id}`}
              run={run}
              inProgress={true}
            />
          ))
        ) : (
          <EmptyContentContainer>
            <img src={image} alt="No Runs to show" />
            <p>{t("projectHome.inProgressRunsEmpty")}</p>
          </EmptyContentContainer>
        )}
      </SectionContent>
    </SectionContainer>
  );
};
