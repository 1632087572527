import { useState, useEffect } from "react";
import { useApp } from "../../../context/app/app.provider";
import { useHistoryWithParams } from "../../../context/hooks/historyWithParams/useHistoryWithParams";
import { useNavOpen } from "../../../context/navigation/nav.provider";
import { RELEASE } from "../../../static/constants/constants";

export const useNav = baseUrl => {
  const history = useHistoryWithParams();
  const { isDesktop } = useApp();
  const { openNav, navIsOpen, togglePane, open, setOpen, setClosed } =
    useNavOpen();
  const [projectDropdownOpen, setProjectDropdownOpen] = useState(false);

  const getInitialActive = () => {
    if (window.location.pathname.includes(`${baseUrl}`))
      return window.location.pathname.split(`/${baseUrl}/`)[1];
    else return window.location.pathname;
  };

  const [active, setActive] = useState(getInitialActive());

  useEffect(() => {
    setActive(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    if (isDesktop) openNav();
  }, [isDesktop]);

  const isOpen = id => open?.includes(id);

  const toggleProjectDropdown = () => setProjectDropdownOpen(state => !state);

  const toggleOpen = id => (isOpen(id) ? setClosed(id) : setOpen(id));

  const linkHandler = (id, url, func) => {
    if (typeof url === "function") url();
    else {
      if (url) history.push(url);
      func(id.toString());
      if (!isDesktop) togglePane();
    }
  };

  const parentLinkHandler = (id, url, func) => {
    if (url) history.push(url);
    if (id !== RELEASE) func(id.toString());
    if (!isDesktop) togglePane();
    if (isDesktop) toggleOpen(id);
  };

  return {
    isOpen,
    active,
    setOpen,
    setActive,
    toggleOpen,
    togglePane,
    linkHandler,
    parentLinkHandler,
    projectDropdownOpen,
    paneOpen: navIsOpen,
    toggleProjectDropdown,
  };
};
