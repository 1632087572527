import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context/auth/auth.provider";
import { Modal } from "../../..";

export const SessionExpiredModal = () => {
  const { t } = useTranslation();
  const { setUnAuthenticated, sessionExpired } = useAuth();

  return sessionExpired ? (
    <Modal
      title={t("sessionExpired.title")}
      text={t("sessionExpired.supportingText")}
      primary={{
        text: t("btnOk"),
        onClick: setUnAuthenticated,
      }}
    />
  ) : null;
};
