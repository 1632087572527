import { useTranslation } from "react-i18next";
import { StyledPlusIcon, TitleContainer } from "./releasesView.styles";
import { Button } from "../../components";
import { usePermissions } from "../../context/hooks";
import { useModal } from "../../context/modal/modal.provider";

export const ReleaseDetails = () => {
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();
  const { openModal, MODALS } = useModal();

  return (
    <>
      <TitleContainer>
        <h1>{t("releases.releasesView.title")}</h1>
        {isAdmin() ? (
          <Button
            onClick={() => openModal(MODALS.CREATE_RELEASE)}
            data-test="create-release-btn"
          >
            <StyledPlusIcon />
            {t("releases.releasesView.newRelease")}
          </Button>
        ) : null}
      </TitleContainer>
    </>
  );
};
