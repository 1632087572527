import { useTranslation } from "react-i18next";
import { LighthouseScoreGauge } from "../../../../components/graph/graph";
import { CustomLink } from "../../../../components/Link/StyledLink";
import { Audit, ScoreKey } from "../accessibilitySection/acessibilitySection";
import {
  Anchor,
  DescriptionSection,
  GaugeContainer,
  ScoreSection,
  SectionContainer,
  SmallText,
  Violation,
  ViolationsSection,
} from "../lighthouseSection.styles";

const WEB_VITALS_LINK =
  "https://web.dev/learn-core-web-vitals/?utm_source=lighthouse&utm_medium=cli";
const GOOGLE_SEARCH_LINK =
  "https://developers.google.com/search/docs/essentials?visit_id=638116371340089306-1869951593&rd=1";

export const SeoSection = ({ data, graphData, scrollRef }) => {
  const { t } = useTranslation();

  return (
    <SectionContainer>
      <ScoreSection>
        <Anchor ref={scrollRef} />
        <GaugeContainer>
          <LighthouseScoreGauge
            large={true}
            data={graphData}
            passed={graphData.find(d => d.name === "SEO")?.value}
          />
        </GaugeContainer>
        <DescriptionSection>
          <span>
            {t("runReport.lighthouse_descriptions.seo_1")}
            <CustomLink accent external to={WEB_VITALS_LINK}>
              {t("runReport.lighthouse_descriptions.seo_2")}
            </CustomLink>
          </span>
          <CustomLink accent external to={GOOGLE_SEARCH_LINK}>
            {t("runReport.lighthouse_descriptions.seo_3")}
          </CustomLink>
        </DescriptionSection>
        <ScoreKey />
      </ScoreSection>
      <ViolationsSection>
        {data.groups.map(vio => (
          <Violation key={vio}>
            <h3>{vio.title}</h3>
            {vio.audits.map(audit => (
              <Audit key={audit.id} audit={audit} />
            ))}
            <SmallText>{vio.description}</SmallText>
          </Violation>
        ))}
      </ViolationsSection>
    </SectionContainer>
  );
};
