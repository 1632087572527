import React from "react";
import {
  Breadcrumbs,
  InfoContainer,
  BreadcrumbLink,
  NodeDetailsContainer,
} from "./nodeDetails.styles";
import { useNodeView } from "../hooks/useNodeView";
import { useProject } from "../../../context/project/project.provider";
import { useApp } from "../../../context/app/app.provider";
import { NewRunButton } from "../../unassignedLabels/newRunButton";

export const NodeDetails = () => {
  const { project } = useProject();
  const { nodeSummary } = useNodeView();
  const { isNotDesktop } = useApp();

  return (
    <NodeDetailsContainer>
      <InfoContainer isNotDesktop={isNotDesktop}>
        <Breadcrumbs>
          {project ? <p data-test="project_id">{project.name}</p> : null}
          {nodeSummary?.breadcrumbs.split("/").map((name, index) => (
            <React.Fragment
              key={`${nodeSummary.path?.split("/")[index]}-fragment`}
            >
              /
              <BreadcrumbLink
                key={nodeSummary.path?.split("/")[index]}
                to={`/node/${nodeSummary.path?.split("/")[index]}`}
                data-test={`breadcrumbs-link-${name}`}
              >
                {name}
              </BreadcrumbLink>
            </React.Fragment>
          ))}
        </Breadcrumbs>
        <h1 data-test="title">{nodeSummary.name}</h1>
      </InfoContainer>
      <NewRunButton />
    </NodeDetailsContainer>
  );
};
