import React from "react";
import { Redirect } from "react-router-dom";
import { routes } from "../../router/routes";
import { useTranslation } from "react-i18next";
import { useTenant } from "../../context/hooks";
import { useSignInActions } from "./hooks/useSignIn";
import { Message } from "../../components";
import {
  CompanyLogo,
  ProductLogo,
  OrgContainer,
  SignInContainer,
  StyledContainer,
  BackLinkContainer,
} from "./signIn.styles";
import { TenantedForm } from "./forms/tenantedForm";
import { UnTenantedForm } from "./forms/untenantedForm";
import { useLocation } from "react-router-dom";

export const SignIn = () => {
  const { tenant } = useTenant();
  const { t } = useTranslation();
  const {
    error,
    isLoading,
    formRef,
    resetFields,
    setUsername,
    setPassword,
    signInAction,
    setOrganisation,
    organisation,
    isAuthenticated,
  } = useSignInActions();
  const location = useLocation();

  const from = location?.state?.from;

  const redirectTo =
    from && from?.pathname !== "/projects"
      ? `${routes.INIT.path}?redirect=${from?.pathname}${from?.search}${from?.hash}`
      : routes.INIT.path;

  return isAuthenticated ? (
    <Redirect to={redirectTo} />
  ) : (
    <SignInContainer>
      <StyledContainer>
        <ProductLogo />
        {tenant ? (
          <OrgContainer>
            <p>{t("signin.signinginto")}</p>
            <h1>{tenant}</h1>
          </OrgContainer>
        ) : null}
        {tenant ? (
          <BackLinkContainer>
            <a
              data-test="signin-link"
              disabled={true}
              rel="noreferrer"
              href={window.location.href.replace(`${tenant}.`, "")}
            >
              {t("signin.switchOrg")}
            </a>
          </BackLinkContainer>
        ) : null}
        {error ? (
          <Message error>{t(`errors.signin.${error.code}`)}</Message>
        ) : null}
        {tenant ? (
          <TenantedForm
            tenant={tenant}
            setUsername={setUsername}
            setPassword={setPassword}
            signInAction={signInAction}
            isLoading={isLoading}
            formRef={formRef}
            resetFields={resetFields}
          />
        ) : (
          <UnTenantedForm
            tenant={tenant}
            setOrganisation={setOrganisation}
            organisation={organisation}
            signInAction={signInAction}
            isLoading={isLoading}
          />
        )}
        <CompanyLogo />
      </StyledContainer>
    </SignInContainer>
  );
};
