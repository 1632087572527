import { useTranslation } from "react-i18next";
import { Button, Input, CenteredContainer } from "../../../components";
import { routes } from "../../../router/routes";
import {
  PasswordContainer,
  ForgotPasswordLink,
  StyledForm,
  FormContainer,
} from "../signIn.styles";

export const TenantedForm = ({
  signInAction,
  resetFields,
  formRef,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <FormContainer>
      <StyledForm
        data-test="tenanted-form"
        ref={formRef}
        onFocus={resetFields}
        onSubmit={signInAction}
      >
        <Input
          type="text"
          name="username"
          id="username"
          aria-label="username"
          data-test="username-input"
          label={t("signin.username")}
        />
        <PasswordContainer>
          <Input
            type="password"
            name="password"
            id="password"
            aria-label="password"
            data-test="password-input"
            label={t("signin.password")}
          />
          <ForgotPasswordLink to={routes.FORGOT_PASSWORD.path}>
            {t("signin.forgotPassword")}
          </ForgotPasswordLink>
        </PasswordContainer>
        <CenteredContainer fullWidth>
          <Button
            primary
            loading={isLoading}
            loader={true}
            data-test="signIn-button"
            type="submit"
            value="Submit"
          >
            {t("signin.signIn")}
          </Button>
        </CenteredContainer>
      </StyledForm>
    </FormContainer>
  );
};
