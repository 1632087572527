import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useFetch } from "../../../context/hooks/fetch/useFetch";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { CATEGORY_PROVIDER_MAP } from "../../../static/constants/constants";

export const useSharedRunview = () => {
  const [run, setRun] = useState(null);
  const [unshared, setUnshared] = useState(false);
  const { token, runType } = useParams();
  const { get, status, body, error, origin } = useFetch();

  const getRun = () => {
    let url = ENDPOINTS.GET_SHARED_RUN.path
      .replace(":runSharingToken", token)
      .replace(":provider", CATEGORY_PROVIDER_MAP[runType]);

    get({
      ...ENDPOINTS.GET_SHARED_RUN,
      path: url,
    });
  };

  useEffect(() => {
    if (status.isResolved && body) setRun(body);
    if (status.isRejected && error.code === 404) setUnshared(true);
  }, [status.isResolved, status.isRejected, body, error, origin]);

  return {
    getRun,
    run,
    unshared,
  };
};
