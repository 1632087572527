import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../context/hooks";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";
import { useProject } from "../../../../../context/project/project.provider";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";

export const useRemoveUser = getProjectUsers => {
  const { project } = useProject();
  const { patch, status } = useFetch();
  const { createNotification, types } = useNotifications();
  const { t } = useTranslation();

  const patchProjectRole = userId =>
    patch(
      {
        ...ENDPOINTS.USER_PROJECT_ROLES,
        path: ENDPOINTS.USER_PROJECT_ROLES.path.replace(":id", userId),
      },
      [],
      { "X-PROJECT-ID": project.id }
    );

  useEffect(() => {
    if (status.isResolved) {
      createNotification(types.SUCCESS, t("removeUser.success"));
      getProjectUsers();
    }
  }, [status.isRejected, status.isResolved]);

  return {
    patchProjectRole,
  };
};
