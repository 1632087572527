import styled from "styled-components";

export const StyledTestsList = styled.ul`
  flex-grow: 1;
  height: ${({ theme }) => theme.sizing.featuresListHeight};
  list-style-type: none;
`;

export const StyledTestSuite = styled.li`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;

export const TestCaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.paddingSM};
`;

export const TestCase = styled.div`
  display: flex;
  min-height: ${({ theme }) => theme.spacing.paddingMD};
  width: 100%;
  cursor: pointer;
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;
