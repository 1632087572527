import { useEffect, useState } from "react";
import { useFetch } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";

export const useCustomers = () => {
  const { get, error, status, body } = useFetch();
  const [customerOrganisations, setCustomerOrganisations] = useState([]);

  const getCustomerOrganisations = () => get(ENDPOINTS.CUSTOMER_ORGANISATIONS);

  useEffect(() => {
    if (status.isResolved && body) {
      const newBody = body.map(usr => ({
        ...usr,
        seats: usr.seats === -1 ? 8 : usr.seats,
      }));
      setCustomerOrganisations(newBody);
    }
  }, [status.isResolved, body, status.isRejected, error]);

  return {
    getCustomerOrganisations,
    customerOrganisations,
  };
};
