import { useEffect, useState } from "react";
import { useFetch } from "../../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";
import { useNotifications } from "../../../../../../context/hooks/useNotifications/useNotifications";
import { useUpdate } from "../../../../../../context/billingUpdate/billingUpdate.provider";

const PAYMENT_SUCCEEDED = "succeeded";
const PAYMENT_REQUIRES_CUSTOMER_ACTION = "requires_action";

export const useCreateSubscription = (stripe, toggleModal) => {
  const { post, body, error, status, origin } = useFetch();
  const { createNotification, types } = useNotifications();
  const { setBillingUpdate } = useUpdate();

  const [subscribing, setSubscribing] = useState(false);
  const [payerAuthenticationData, setPayerAuthenticationData] = useState({});

  const setAuthenticationData = payload => {
    const data = {
      clientSecret: payload.latest_invoice.payment_intent.client_secret,
      paymentMethodId: payload.latest_invoice.payment_intent.payment_method,
      priceId: payload.plan.id,
      quantity: payload.quantity,
      subscriptionId: payload.id,
    };
    setPayerAuthenticationData(data);
  };

  const createSubscriptionAction = payload =>
    post(ENDPOINTS.SUBSCRIPTION, payload);

  const handleSubscriptionCreation = body => {
    const paymentIntent = body.latest_invoice.payment_intent;

    if (paymentIntent?.status === PAYMENT_REQUIRES_CUSTOMER_ACTION) {
      setAuthenticationData(body);
    } else if (
      body.latest_invoice.paid ||
      paymentIntent?.status === PAYMENT_SUCCEEDED
    ) {
      toggleModal();
      setBillingUpdate();
    } else {
      toggleModal();
      setBillingUpdate();
    }
  };

  const payerAuthenticationAction = payload => {
    createSubscriptionAction({
      payment_method_id: payload.data.paymentMethodId,
      price_id: payload.data.priceId,
      quantity: payload.data.quantity,
      subscription_id: payload.data.subscriptionId,
    });
  };

  useEffect(() => {
    if (subscribing && payerAuthenticationData) {
      stripe
        .confirmCardPayment(payerAuthenticationData.clientSecret, {
          payment_method: payerAuthenticationData.paymentMethodId,
          setup_future_usage: "off_session",
        })
        .then(result => {
          payerAuthenticationAction({
            result: result,
            data: payerAuthenticationData,
          });
        });
    }
  }, [payerAuthenticationData]);

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(
        types.SUCCESS,
        "subscriptionUpdate.planCreationSuccess"
      );
      handleSubscriptionCreation(body);
    }
    if (status.isRejected && error) {
      createNotification(
        types.ERROR,
        error.text && Array.isArray(error.text)
          ? error.text.map(err => `errors.createSubscription.${err}`)
          : "subscriptionCreate.failureMessage"
      );
      setSubscribing(false);
    }
  }, [status.isResolved, status.isRejected, body, error, origin]);

  return {
    createSubscriptionAction,
    setSubscribing,
    subscribing,
  };
};
