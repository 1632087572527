import styled from "styled-components";

export const TextEditorContainer = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const EditorShell = styled.div``;

export const EditorContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme, readOnly }) =>
    readOnly ? "none" : theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  outline: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.highlight}` : null};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
`;

export const FontSizeInput = styled.input`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 14px;
  color: ${({ theme }) => theme.colours.mainText};
  border-radius: ${({ theme }) => theme.borders.radius};
  height: ${({ theme }) => theme.sizing.inputHeight};
  width: ${({ theme }) => theme.sizing.inputHeight};
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  text-align: center;

  background: ${({ theme }) => theme.colours.background};

  outline: none;
  border: none;
`;

export const ToolbarItem = styled.button`
  border: 0;
  display: flex;
  background: none;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  color: ${({ theme }) => theme.colours.mainText};

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }

  svg {
    height: ${({ theme }) => theme.sizing.svgHeightSM};
    width: ${({ theme }) => theme.sizing.svgHeightSM};
  }
`;

export const StyledEditor = styled.div`
  flex: auto;
  position: relative;
  resize: vertical;
  z-index: -1;
`;

export const StyledEditorScroller = styled.div`
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  overflow: auto;
  resize: vertical;
`;

export const TableCellActionButton = styled.button`
  display: flex;

  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colours.foreground};
  border: none;
  outline: none;

  height: ${({ theme }) => theme.sizing.svgHeightMD};
  width: ${({ theme }) => theme.sizing.svgHeightMD};

  border-radius: 50%;

  cursor: pointer;
`;

export const TableCellActionButtonContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
  width: fit-content;
  z-index: 99999;
  background: none;
`;

export const DropDownButton = styled.button`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  color: ${({ theme }) => theme.colours.mainText};
  cursor: pointer;
  line-height: ${({ theme }) => theme.spacing.padding};
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colours.dropdownBG};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 0;
  align-items: center;

  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colours.highlight};
  }

  outline: ${({ theme, $active }) =>
    $active ? `1px solid ${theme.colours.highlight}` : "none"};

  svg {
    width: ${({ theme }) => theme.sizing.svgHeightMD};
    height: ${({ theme }) => theme.sizing.svgHeightMD};
    margin-right: ${({ theme }) => theme.spacing.padding};
    line-height: ${({ theme }) => theme.spacing.padding};
    display: flex;
    background-size: contain;
    background-position: center;
  }
`;
