import styled from "styled-components";

export const Menu = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.dropdownOffsetTop};
  right: ${({ theme }) => theme.spacing.dropdownOffsetRight};
  width: ${({ theme }) => theme.sizing.dropdownMenuWidth};
  background-color: ${({ theme }) => theme.colours.dropdownBG};
  z-index: 98;
  border: 1px ${({ theme }) => theme.borders.dropdownBorder} solid;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
`;

export const ScreenCover = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 97;
`;

export const MenuRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: ${({ theme }) => theme.sizing.dropdownRowHeight};
  z-index: 99;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  gap: ${({ theme }) => theme.spacing.paddingSM};

  &:hover {
    background-color: ${({ theme }) => theme.colours.dropdownHighlight};
    p {
      opacity: 100%;
    }
  }
`;

export const MenuRowText = styled.p`
  opacity: 60%;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  user-select: none;
`;
