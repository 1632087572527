import {
  ChildElement,
  NewNodeItem,
  ParentElement,
  StyledPlusIcon,
} from "./navigationParent.styles";
import "@szhsin/react-menu/dist/index.css";
import { useNav } from "../../hooks/useNav";
import React, { useState, useRef, useEffect } from "react";
import { useCreateNode } from "./hooks/node/createNode";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { usePermissions } from "../../../../context/hooks";
import { useApp } from "../../../../context/app/app.provider";
import { NavigationItem } from "../navigationItem/navigationItem";
import { NodeProvider } from "../../../../context/nodes/nodes.provider";
import { NodeMenuComponent } from "../nodeMenu/nodeMenu";
import { useModal } from "../../../../context/modal/modal.provider";
import { ReleaseMenu } from "../../../../views/releasesView/releaseCard/releaseMenu";
import { useClick, useMenuState } from "@szhsin/react-menu";
import { MENU_STATE } from "../../../../static/constants/constants";
import { OpenMenuButton } from "../../../menus/openMenuButton";
import { useTheme } from "styled-components";

export const NavigationParent = ({
  id,
  to,
  Icon,
  name,
  active,
  chevron,
  children,
  setActive,
  root,
  baseUrl,
  type,
  parentId,
  paneRef,
}) => {
  const isNode = type === "node";
  const isRelease = type === "release";
  const [renameNodeMode, setRenameNodeMode] = useState(null);
  const [newNodeMode, setNewNodeMode] = useState(false);

  const { isOpen, setOpen } = useNav();
  const [hovered, setHovered] = useState(false);
  const [selectedChild, setSelectedChild] = useState(false);
  const { isNotDesktop } = useApp();
  const { isAdmin } = usePermissions();
  const { openModal, MODALS } = useModal();
  const theme = useTheme();

  const renameNodeHandler = (e, id) => {
    e.stopPropagation = true;
    setRenameNodeMode(id);
    setOpen(id);
  };

  const createNodeHandler = (e, id) => {
    e.stopPropagation = true;
    setNewNodeMode(id);
    setOpen(id);
  };

  const closeRenameNode = () => setRenameNodeMode(false);
  const closeCreateNode = () => setNewNodeMode(false);

  useEffect(() => {
    if (selectedChild) {
      if (paneRef?.current) paneRef.current.onwheel = e => e.preventDefault();
      if (paneRef?.current)
        paneRef.current.children[0].style.overflow = "hidden";
      if (paneRef?.current)
        paneRef.current.children[0].style.paddingRight =
          theme.spacing.paddingMD;
    } else {
      if (paneRef?.current) paneRef.current.onwheel = null;
      if (paneRef?.current) paneRef.current.children[0].style.overflow = "auto";
      if (paneRef?.current) paneRef.current.children[0].style.paddingRight = "";
    }
  }, [selectedChild]);

  return (
    <NodeProvider>
      <ParentElement>
        <NavigationItem
          paneRef={paneRef}
          name={name}
          id={id}
          setActive={setActive}
          active={active}
          to={to}
          Icon={Icon}
          parent={true}
          chevron={chevron}
          isOpen={isOpen}
          setHovered={setHovered}
          rename={renameNodeMode === id}
          baseUrl={baseUrl}
          type={type}
          parentId={parentId}
          setOpen={setOpen}
          closeRenameNode={closeRenameNode}
        >
          {isAdmin() && isRelease && root ? (
            <StyledPlusIcon
              data-test="create-release-btn"
              onClick={() => openModal(MODALS.CREATE_RELEASE, {})}
            />
          ) : null}
          {isAdmin() &&
          isNode &&
          (isNotDesktop || hovered === id || selectedChild === id) ? (
            <ContextMenu
              id={id}
              name={name}
              create={true}
              rename={!root}
              renameNodeHandler={renameNodeHandler}
              createNodeHandler={createNodeHandler}
              setSelectedChild={setSelectedChild}
            />
          ) : null}
        </NavigationItem>
      </ParentElement>
      <ChildElement>
        {newNodeMode === id ? (
          <NewNodeComponent
            parentId={id}
            key={`new-node-${name}`}
            closeCreateNode={closeCreateNode}
          />
        ) : null}
        {isOpen(id) &&
          children
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(child =>
              child.children && child.children.length ? (
                <NavigationParent
                  key={`nav-parent-${child.name}`}
                  setActive={setActive}
                  active={active}
                  id={child.id}
                  chevron={chevron}
                  baseUrl={baseUrl}
                  to={`/${baseUrl}/${child.id}`}
                  type={type}
                  parentId={id}
                  paneRef={paneRef}
                  {...child}
                />
              ) : null
            )}
        {isOpen(id) &&
          children
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(child =>
              !child?.children?.length ? (
                <>
                  <NavigationItem
                    key={`nav-item-${child.name}`}
                    setActive={setActive}
                    active={active}
                    id={child.id}
                    setHovered={setHovered}
                    rename={renameNodeMode === child.id}
                    baseUrl={baseUrl}
                    to={`/${baseUrl}/${child.id}`}
                    type={type}
                    parentId={id}
                    setOpen={setOpen}
                    closeRenameNode={closeRenameNode}
                    selected={selectedChild === child.id}
                    {...child}
                  >
                    {isAdmin() &&
                    isNode &&
                    (isNotDesktop ||
                      hovered === child.id ||
                      selectedChild === child.id) ? (
                      <ContextMenu
                        renameNodeHandler={renameNodeHandler}
                        createNodeHandler={createNodeHandler}
                        key={`menu-${child.name}`}
                        id={child.id}
                        name={child.name}
                        create={true}
                        del={child.children?.length === 0}
                        rename={true}
                        parentId={id}
                        setSelectedChild={setSelectedChild}
                      />
                    ) : null}
                    {isAdmin() &&
                    isRelease &&
                    (isNotDesktop ||
                      hovered === child.id ||
                      selectedChild === child.id) ? (
                      <ContextMenu
                        isRelease={true}
                        id={child.id}
                        name={child.name}
                        child={child}
                        setSelectedChild={setSelectedChild}
                      />
                    ) : null}
                  </NavigationItem>
                  <ChildElement key={`child-${child.name}`}>
                    {newNodeMode === child.id ? (
                      <NewNodeComponent
                        parentId={child.id}
                        key={`new-node-${child.name}`}
                        closeCreateNode={closeCreateNode}
                      />
                    ) : null}
                  </ChildElement>
                </>
              ) : null
            )}
      </ChildElement>
    </NodeProvider>
  );
};

const NewNodeComponent = ({ parentId, closeCreateNode }) => {
  const { newNodeHandler, onBlurHandler, createRef } =
    useCreateNode(closeCreateNode);

  return (
    <NewNodeItem>
      <input
        ref={createRef}
        onBlur={e => onBlurHandler(e, parentId)}
        autoFocus
        onKeyDown={e => newNodeHandler(e, parentId)}
        onChange={e => newNodeHandler(e, parentId)}
      />
    </NewNodeItem>
  );
};

const ContextMenu = ({
  child,
  setSelectedChild,
  id,
  name,
  isRelease,
  parentId,
  ...props
}) => {
  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);
  const menuOpen = menuState.state === MENU_STATE.OPEN;

  return (
    <>
      <OpenMenuButton
        r={ref}
        anchorProps={anchorProps}
        menuOpen={menuOpen}
        onClick={() => setSelectedChild(id)}
      />
      {isRelease ? (
        <ReleaseMenu
          release={child}
          r={ref}
          menuState={menuState}
          toggleMenu={toggleMenu}
          onClose={() => setSelectedChild(false)}
          {...props}
        />
      ) : (
        <NodeMenuComponent
          r={ref}
          menuState={menuState}
          toggleMenu={toggleMenu}
          onClose={() => setSelectedChild(false)}
          renameNodeHandler={props.renameNodeHandler}
          createNodeHandler={props.createNodeHandler}
          key={`menu-${name}`}
          id={id}
          name={name}
          create={true}
          del={props.del}
          rename={true}
          parentId={parentId || null}
        />
      )}
    </>
  );
};
