import { Link } from "react-router-dom";
import {
  BugComponent,
  DisplayName,
  IssueKey,
  Spacer,
  StatusSticker,
} from "./bug.styles";
import { ReactComponent as BugIcon } from "../../../static/assets/icon-bug.svg";

const statuses = ["To Do", "Done"];

const mapStatus = status =>
  statuses.includes(status) ? status.replace(" ", "_").toLowerCase() : "other";

export const Bug = ({ issue_key, base_url, display_name, large, status }) => (
  <Link
    to={{ pathname: `${base_url}/${issue_key}` }}
    target="_blank"
    style={{ textDecoration: "none" }}
  >
    <BugComponent data-test={`bug-${issue_key}`} large={large}>
      <BugIcon />
      <IssueKey>{issue_key}</IssueKey>
      {large ? (
        <>
          <DisplayName>{display_name}</DisplayName>
          <Spacer />
          <StatusSticker status={mapStatus(status)}>
            {status.toUpperCase()}
          </StatusSticker>
        </>
      ) : null}
    </BugComponent>
  </Link>
);
