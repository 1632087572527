import { useState, useRef, useEffect } from "react";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import {
  useFetch,
  useFormValidation,
  useQueryParams,
} from "../../../context/hooks";

export const useSetPassword = () => {
  const formRef = useRef(null);

  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [passwordConfirm, setConfirmPassword] = useState("");
  const [setPasswordSuccess, setSetPasswordSuccess] = useState(false);
  const [params, setParams] = useState({ userId: null, token: null });
  const [emptyField, setEmptyField] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const { getParams } = useQueryParams();
  const { put, status, origin, error, reset } = useFetch();
  const { isValid, isInvalid, validation, validateForm, resetValidation } =
    useFormValidation();

  useEffect(() => {
    if (status.isResolved) setSetPasswordSuccess(true);
    if (status.isRejected) formRef.current?.reset();
  }, [status, origin]);

  useEffect(() => {
    setParams(getParams("userId", "token"));
  }, []);

  useEffect(() => {
    if (submitted && isValid && captchaToken && params.userId && params.token) {
      put(ENDPOINTS.RESET_PASSWORD, {
        id: params.userId,
        token: params.token,
        password,
        captcha_token: captchaToken,
      });
    }
  }, [isValid, validation, submitted]);

  useEffect(() => {
    validateForm([
      {
        name: "your password",
        value: password,
        validate: ["length"],
        params: { min: 12 },
      },
      {
        name: "your password",
        value: passwordConfirm,
        validate: ["length"],
        params: { min: 12 },
      },
      {
        name: "email",
        value: [password, passwordConfirm],
        validate: ["equal"],
      },
    ]);
  }, [password, passwordConfirm]);

  const buttonHandler = t => {
    setSubmitted(true);
    if (!password && !passwordConfirm) return setEmptyField([1, 2]);
    if (!password) return setEmptyField([...emptyField, 1]);
    if (!passwordConfirm) return setEmptyField([...emptyField, 2]);

    setCaptchaToken(t);
    validateForm([
      {
        name: "your password",
        value: password,
        validate: ["length"],
        params: { min: 12 },
      },
      {
        name: "your password",
        value: passwordConfirm,
        validate: ["length"],
        params: { min: 12 },
      },
      {
        name: "email",
        value: [password, passwordConfirm],
        validate: ["equal"],
      },
    ]);
  };

  const resetFields = () => {
    if (submitted && (error || isInvalid)) {
      resetValidation();
      setPassword("");
      setConfirmPassword("");
      reset();
      setSubmitted(false);
      setCaptchaToken(null);
    }
  };

  return {
    error,
    formRef,
    emptyField,
    setEmptyField,
    formError: {
      length: !!validation?.find(entry => entry.reason === "invalidMinLength"),
      inequality: !!validation?.find(entry => entry.reason === "inequality"),
      error:
        !!validation?.find(entry => entry.reason === "inequality") ||
        !!validation?.find(entry => entry.reason === "invalidMinLength"),
    },
    resetFields,
    setPassword,
    buttonHandler,
    setConfirmPassword,
    setPasswordSuccess,
    isLoading: status.isLoading,
  };
};
