import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "../../theme/theme.provider";

export const useNotifications = () => {
  const { t } = useTranslation();
  const { isDark } = useTheme();

  const types = {
    ERROR: "error",
    SUCCESS: "success",
  };

  const createNotification = (type, text) => {
    toast[type](t(text), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: isDark ? "dark" : "light",
    });
  };

  return {
    NotificationContainer,
    createNotification,
    types,
  };
};

export const NotificationContainer = () => {
  const { isDark } = useTheme();

  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      role="status"
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={isDark ? "dark" : "light"}
    />
  );
};
