import { stripUnit, rem } from "polished";

const DEFAULT_SPACING = "8px";

export const sp = (steps, base = DEFAULT_SPACING) => {
  if (typeof steps !== "number") {
    throw new Error(
      "Please provide a number of steps to the modularScale helper."
    );
  }

  const realBase = typeof base === "string" ? stripUnit(base) : base;

  if (typeof realBase === "string") {
    throw new Error(
      `Invalid value passed as base to modularScale, expected number or px string but got "${base}"`
    );
  }

  return rem(realBase * steps);
};
