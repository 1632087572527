import styled from "styled-components";

export const RunStatusGraph = ({ criteria }) => {
  return (
    <ProgressBarContainer>
      {criteria.passed ? (
        <Passed data-test="passed-percentage" value={criteria.passed} />
      ) : null}
      {criteria.failed ? (
        <Failed data-test="failed-percentage" value={criteria.failed} />
      ) : null}
      {criteria.not_started ? (
        <NotStarted
          data-test="not-started-percentage"
          value={criteria.not_started}
        />
      ) : null}
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  height: ${({ theme }) => theme.sizing.minimalGraphHeight};
  width: 100%;

  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  margin-top: ${({ theme }) => theme.spacing.paddingSM};
`;

const Bar = styled.div`
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  height: ${({ theme }) => theme.sizing.minimalGraphHeight};
  width: ${({ value }) => (value ? `${value * 100}%` : 0)};
  -webkit-transition: width 0.5s ease-out;
  -moz-transition: width 0.5s ease-out;
  -o-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
`;

const Passed = styled(Bar)`
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.gradients.passing_end}, ${theme.gradients.passing_start})`};
  z-index: 1;
`;

const Failed = styled(Bar)`
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.gradients.failed_check_start}, ${theme.gradients.failed_check_end})`};
  z-index: 2;
`;

const NotStarted = styled(Bar)`
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.gradients.skipped_end}, ${theme.gradients.skipped_start})`};
  z-index: 2;
`;
