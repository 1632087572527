import { Modal } from "../../modal";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Button/Button";
import { routes } from "../../../../router/routes";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CustomLink } from "../../../../components/Link/StyledLink";
import { useProject } from "../../../../context/project/project.provider";
import { useQueryToken } from "../../../../views/runView/hooks/sharedRunView/useQueryToken";
import { useRunSharingToken } from "../../../../views/runView/hooks/sharedRunView/useRunSharingToken";
import { useEnableRunSharing } from "../../../../views/runView/hooks/sharedRunView/useEnableRunSharing";
import { useDisableRunSharing } from "../../../../views/runView/hooks/sharedRunView/useDisableRunSharing";

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.paddingMD};
  padding: ${({ theme }) => theme.spacing.paddingMD};
  background-color: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};
  color: ${({ theme }) => theme.colours.mainText};
  max-width: ${({ theme }) => theme.sizing.modalWidth};

  a {
    overflow-wrap: break-word;
    word-break: break-all;
    color: ${({ theme }) => theme.colours.mainText};
    text-align: center;

    &: hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colours.haloAccent};
    }
  }
`;

export const ShareRunViewModal = ({ closeModal, runDetails }) => {
  const { project } = useProject();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const label = runDetails?.latest_run?.label;
  const category = runDetails?.latest_run?.category;
  const { enableRunSharing } = useEnableRunSharing();
  const { disableRunSharing } = useDisableRunSharing();
  const { queryToken } = useQueryToken();
  const { runSharingToken } = useRunSharingToken();

  const sharingLink = () =>
    `${window.location.origin}${routes.SHARED_RUN_VIEW.path
      .replace(":runType", category)
      .replace(":label", encodeURI(encodeURIComponent(label)))
      .replace(":token", runSharingToken)}?project=${project.id}`;

  useEffect(() => {
    if (label && category) queryToken(label, category);
  }, [label, category]);

  return runSharingToken ? (
    <Modal
      primary={{
        text: t("btnUnshare"),
        onClick: () => disableRunSharing(category, runSharingToken),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
      title={t("sharedRunView.shareDashboardModalTitle")}
    >
      <LinkContainer>
        <CustomLink to={sharingLink()}>{sharingLink()}</CustomLink>
        <CopyToClipboard text={sharingLink()}>
          <Button accent onClick={() => setCopied(true)}>
            {copied ? t("btnCopied") : t("btnClickToCopy")}
          </Button>
        </CopyToClipboard>
      </LinkContainer>
    </Modal>
  ) : (
    <Modal
      primary={{
        text: t("btnShare"),
        onClick: () => enableRunSharing(label, category),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
      title={t("sharedRunView.shareDashboardModalTitle")}
      text={t("sharedRunView.shareDashboardModalText")}
    />
  );
};
