import { useState } from "react";
import { Modal } from "../../..";
import {
  RadioContainer,
  RadioGroupContainer,
  StyledMessage,
  StyledMessageNoBorder,
  RadioLabel,
  HiddenRadio,
  StyledRadio,
  Link,
  TextArea,
  TextAreaContainer,
  MessageContainer,
  NotesContainer,
  Spacer,
} from "./approval.styles";
import {
  APPROVAL_NOTES_LENGTH,
  TEST_EVOLVE_DOCUMENTATION_RUN_APPROVAL,
} from "../../../../static/constants/constants";
import { RadioLabelText } from "../../../../views/settings/tabs/reports/reports.styles";
import { approvalUtils } from "../../../../views/runView/hooks/approval/approvalUtils";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../context/theme/theme.provider";
import { ReactComponent as EditIcon } from "../../../../static/assets/icon-edit.svg";

export const RunApproveModal = ({ run, closeModal, approveRun }) => {
  const runId = run.id;
  const runApproved = run.approved;

  const [checked, setChecked] = useState(true);
  const [description, setDescription] = useState("");
  const [editMode, setEditMode] = useState(false);

  const { approvalIcon, formatApproved } = approvalUtils();
  const { isDark } = useTheme();
  const { t } = useTranslation();

  return (
    <Modal
      size="small"
      title={t("runHistory.approveRunModal.modalTitle")}
      text={t("runHistory.approveRunModal.modalMessage", {
        runId: runId,
      })}
      primary={{
        text: t("btnConfirm"),
        onClick: () => approveRun(run, checked, description),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: () => closeModal(),
      }}
    >
      <RadioGroupContainer role="group" aria-labelledby="my-radio-group">
        <RadioContainer>
          <RadioLabel>
            <RadioLabelText>
              {t("runHistory.approveRunModal.radio1")}
            </RadioLabelText>
            <HiddenRadio
              type="radio"
              name="false-check"
              value={false}
              onChange={() => setChecked(false)}
            />
            <StyledRadio checked={!checked} />
          </RadioLabel>
        </RadioContainer>
        <RadioContainer>
          <RadioLabel>
            <RadioLabelText>
              {t("runHistory.approveRunModal.radio2")}
            </RadioLabelText>
            <HiddenRadio
              type="radio"
              name="true-check"
              value={true}
              onChange={() => setChecked(true)}
            />
            <StyledRadio checked={checked} />
          </RadioLabel>
        </RadioContainer>
      </RadioGroupContainer>
      {!runApproved?.message || editMode ? (
        <TextAreaContainer>
          <TextArea
            key="description"
            placeholder="Notes..."
            value={description}
            onChange={e => setDescription(e.target.value)}
            maxLength={APPROVAL_NOTES_LENGTH}
          />
          <p>
            {description.length}/{APPROVAL_NOTES_LENGTH}
          </p>
        </TextAreaContainer>
      ) : (
        <NotesContainer>
          <p>{t("runHistory.approveRunModal.notes")}</p>
          <MessageContainer onClick={() => setEditMode(true)}>
            <p>{runApproved.message}</p>
            <Spacer />
            <EditIcon />
          </MessageContainer>
        </NotesContainer>
      )}
      {runApproved && runApproved.status ? (
        <StyledMessage dark={isDark}>
          {approvalIcon(runApproved, true)}
          {formatApproved(runApproved)}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={TEST_EVOLVE_DOCUMENTATION_RUN_APPROVAL}
          >
            {t("runHistory.approveRunModal.learnMore")}
          </Link>
        </StyledMessage>
      ) : (
        <StyledMessageNoBorder dark={isDark}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={TEST_EVOLVE_DOCUMENTATION_RUN_APPROVAL}
          >
            {t("runHistory.approveRunModal.learnMore")}
          </Link>
        </StyledMessageNoBorder>
      )}
    </Modal>
  );
};
