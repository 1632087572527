import React from "react";
import styled from "styled-components";

const Svg = styled.svg.attrs({
  version: "1.1",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
})``;

export const SvgIcon = ({ className, d, fill, viewBox }) => (
  <Svg viewBox={viewBox} className={className}>
    <path fill={fill} d={d} />
  </Svg>
);
