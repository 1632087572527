import React from "react";
import { CheckboxContainer, StyledCheckbox } from "./checkbox.styles";

export const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <StyledCheckbox
      data-test="checkbox-component"
      type="checkbox"
      checked={checked}
      onChange={props.onChange}
      {...props}
    />
  </CheckboxContainer>
);
