import { STATUSES } from "../../../../static/constants/constants";
import {
  ArgumentTable,
  ArgumentTableCell,
  ArgumentTableContainer,
  ArgumentTableHeader,
  ArgumentTableRow,
  DocStringContainer,
  StepDetailsContainer,
  StyledFailIcon,
  StyledFailure,
  StyledPassIcon,
  StyledSkippedIcon,
  StyledStep,
  StyledStepContainer,
  TextContainer,
} from "../testevolveRunReport.styles";
import { Insights } from "../insights/insights";
import SyntaxHighlighter from "react-syntax-highlighter";
import { AxeSections } from "../../testevolveAccessibility/axeSection";
import { LightHouseSections } from "../../lighthouse/lighthouseSection";
import { useRunStatus } from "../../../../context/runStatus/runStatus.provider";
import {
  a11yDark,
  a11yLight,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useScreenshot } from "../../hooks/useScreenshot";

export const Step = ({
  step,
  isMobile,
  axe,
  isDark,
  uuid,
  lighthouse,
  stepIndex,
  JiraBugButton,
  scenarioIndex,
  scenarioSentences,
  generatingInsights,
  insightsError,
  scenario,
  feature,
}) => {
  const { runStatus } = useRunStatus();
  const { ScreenshotSection } = useScreenshot();

  if (step.docString) {
    step.docString = {
      ...step.docString,
      content: step.docString.content.replaceAll(" ", "\xa0"),
    };
  }

  return (
    <>
      <StyledStepContainer
        data-test="scenario-step"
        key={uuid()}
        skipped={step.status === STATUSES.SKIPPED}
      >
        <StyledStep
          success={step.status === STATUSES.PASSED}
          isMobile={isMobile}
        >
          {step.status === STATUSES.PASSED ? (
            <StyledPassIcon />
          ) : step.status === STATUSES.SKIPPED ? (
            <StyledSkippedIcon />
          ) : (
            <StyledFailIcon />
          )}
          <TextContainer>
            <b>{step.keyword}</b>
            {step.name}
          </TextContainer>
        </StyledStep>
        <StepDetailsContainer>
          {step?.dataTable ? (
            <ArgumentTableContainer>
              <ArgumentTable>
                <thead>
                  {step.dataTable.headers.map((header, i) => (
                    <ArgumentTableHeader
                      key={`${step.name}-${header}-table-header-${i}`}
                    >
                      {header}
                    </ArgumentTableHeader>
                  ))}
                </thead>
                <tbody>
                  {step.dataTable.rows.map((row, i) => (
                    <ArgumentTableRow
                      key={`${step.name}-${row}-table-row-${i}`}
                    >
                      {row.map(cell => (
                        <ArgumentTableCell
                          key={`${step.name}-${cell}-table-cell-${i}`}
                        >
                          {cell}
                        </ArgumentTableCell>
                      ))}
                    </ArgumentTableRow>
                  ))}
                </tbody>
              </ArgumentTable>
            </ArgumentTableContainer>
          ) : null}
          {step?.docString ? (
            <DocStringContainer dark={isDark}>
              <SyntaxHighlighter
                wrapLongLines={true}
                wrapLines={true}
                language={"yml"}
                style={isDark ? a11yDark : a11yLight}
                className="docString"
              >
                {step.docString.content}
              </SyntaxHighlighter>
            </DocStringContainer>
          ) : null}
          {axe && step?.axe_checks && step?.axe_checks?.length ? (
            <AxeSections
              step={step}
              stepRef={`${scenarioIndex}-${stepIndex}`}
              axe_checks={step.axe_checks}
              JiraBugButton={JiraBugButton}
              scenario={scenario}
              feature={feature}
            />
          ) : null}
          {lighthouse &&
          step?.lighthouse_checks &&
          step?.lighthouse_checks?.length ? (
            <LightHouseSections
              step={step}
              scenario={scenario}
              stepRef={`${scenarioIndex}-${stepIndex}`}
              lighthouse_checks={step.lighthouse_checks}
              JiraBugButton={JiraBugButton}
              feature={feature}
            />
          ) : null}
          {step.failure && (
            <StyledFailure>
              <pre>
                <b>{step.failure.failure_method}</b>
                <br />
                <br />
                {step.failure.failure_stacktrace}
              </pre>
              <ScreenshotSection step={step} />
              {(generatingInsights || scenarioSentences.length) &&
              runStatus.isLatest &&
              !insightsError ? (
                <Insights
                  scenarioSentences={scenarioSentences}
                  generatingInsights={generatingInsights}
                />
              ) : null}
            </StyledFailure>
          )}
        </StepDetailsContainer>
      </StyledStepContainer>
    </>
  );
};
