import styled from "styled-components";

export const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingXSM};

  padding: ${({ theme }) => theme.spacing.paddingSM};

  width: 100%;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};

  overflow-x: auto;

  outline: 1px solid ${({ theme }) => theme.colours.highlight};

  min-height: fit-content;
`;

export const ToolbarButton = styled.button`
  color: ${({ theme }) => theme.colours.mainText};

  border: 0;
  display: flex;
  background: none;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  &:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.colours.background};
  }

  &:disabled {
    cursor: not-allowed;
  }

  background-color: ${({ theme, active }) =>
    active ? theme.colours.highlight : theme.colours.foreground};
`;
