import { useCreateEntry } from "./useCreateEntry";
import { useDeleteEntry } from "./useDeleteEntry";
import { useEditEntry } from "./useEditEntry";

export const useEntries = (sessionId, getRun, close) => {
  const { editEntry, loadingEdit } = useEditEntry(getRun, close);
  const { deleteEntry, loadingDelete } = useDeleteEntry(getRun);
  const { createEntry, loadingCreate } = useCreateEntry(
    sessionId,
    getRun,
    close
  );

  return {
    editEntry,
    deleteEntry,
    createEntry,
    loading: loadingEdit || loadingDelete || loadingCreate,
  };
};
