import {
  LeftContainer,
  ReleaseDescription,
  ReleaseDetailsContainer,
  RightContainer,
  StatusSticker,
  Title,
  TitleContainer,
} from "../release.styles";
import { RELEASE_STATUSES } from "../../../static/constants/constants";
import { useReleases } from "../../../context/releases/releases.provider";
import { useTranslation } from "react-i18next";
import { daysAgo, daysUntil } from "../../../utils/dates/dateUtils";
import { useApp } from "../../../context/app/app.provider";
import { Spacer } from "../../runReportView/components/bug.styles";

export const SharedReleaseDetails = () => {
  const { t } = useTranslation();

  const { isMobile } = useApp();
  const { release } = useReleases();

  const isOpen = release.status === RELEASE_STATUSES.OPEN;
  const isClosed = release.status === RELEASE_STATUSES.CLOSED;

  const daysSince = release.close_date ? daysAgo(release.close_date) : null;
  const daysRemaining = release.close_date
    ? daysUntil(release.close_date)
    : null;

  if (!release?.id) return null;

  return (
    <ReleaseDetailsContainer>
      <LeftContainer>
        <TitleContainer>
          <Title>
            <h1>{release.name}</h1>
          </Title>
          {isMobile ? (
            <ReleaseDescription>{release.description}</ReleaseDescription>
          ) : null}
          <StatusSticker isClosed={isClosed}>
            {t(`releases.${RELEASE_STATUSES[release.status]}`)}
          </StatusSticker>
        </TitleContainer>
        {!isMobile ? (
          <ReleaseDescription>{release.description}</ReleaseDescription>
        ) : null}
      </LeftContainer>
      <Spacer />
      {isOpen && daysSince !== null ? (
        <RightContainer>
          <h3>
            {t("releases.daysRemaining", {
              days: daysSince,
              multiple: daysSince === 1 ? null : "s",
            })}
          </h3>
        </RightContainer>
      ) : null}
      {isClosed && daysRemaining !== null ? (
        <RightContainer>
          <h3>
            {t("releases.daysAgo", {
              days: daysRemaining,
              multiple: daysRemaining === 1 ? null : "s",
            })}
          </h3>
        </RightContainer>
      ) : null}
    </ReleaseDetailsContainer>
  );
};
