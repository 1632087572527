import styled, { css } from "styled-components";
import { Button } from "../";
import { StyledSelect } from "../../views/settings/tabs/reports/reports.styles";

export const Cont = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  p {
    font-size: ${({ theme }) => theme.fontSize.large};
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colours.haloAccent};
    }
  }
`;

export const EditButton = styled(Button)`
  background-color: ${({ theme }) => theme.colours.haloAccent};
  font-size: ${({ theme }) => theme.fontSize.medium};
  width: ${({ theme }) => theme.sizing.nodeBtnWidth};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  letter-spacing: ${({ theme }) => theme.letterSpacing};
  color: ${({ theme }) => theme.colours.white};
`;

export const LabelsTitle = styled.p``;

export const LabelsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacing.padding};
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
`;

export const Label = styled.div`
  cursor: pointer;
  height: ${({ theme }) => theme.sizing.btnHeight};
  min-width: ${({ theme }) => theme.sizing.tabLegth};
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) => theme.colours.foreground};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.mainText};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme }) => theme.fontSize.medium};

  p {
    padding: ${({ theme }) => theme.spacing.paddingSM};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colours.mainText};
    letter-spacing: ${({ theme }) => theme.letterSpacing};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    opacity: 0.8;
  }
`;

export const NewLabelContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
  background-color: ${({ theme }) => theme.colours.foreground};
  width: ${({ isNotDesktop }) => (isNotDesktop ? "100%" : "fit-content")};
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  flex-direction: ${({ isNotDesktop }) => isNotDesktop && "column"};
`;

export const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;

  width: ${({ theme }) => theme.sizing.deleteBtnWdith};
  background-color: ${({ theme }) => theme.colours.error};
  color: ${({ theme }) => theme.colours.white};
  padding: -${({ theme }) => theme.spacing.paddingSM};
  height: 100%;
`;

export const InsideLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const AddLabelSelect = styled(StyledSelect)`
  width: fit-content;
  height: fit-content;
  min-width: ${({ theme }) => theme.sizing.selectWidth};
  min-height: ${({ theme }) => theme.sizing.btnHeight};

  ${({ theme }) =>
    theme.isNotDesktop &&
    css`
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    `}
`;
