import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useCreateEntry = (sessionId, getRun) => {
  const { post, status, body, error } = useFetch(true);
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && body && body.id) {
      close();
      getRun();
    }
    if (status.isRejected && error)
      createNotification(
        types.ERROR,
        error?.code === 413
          ? "runView.testevolveexploratory.createEntryError413"
          : "runView.testevolveexploratory.createEntryError"
      );
  }, [status.isRejected, status.isResolved, error]);

  const createEntry = e => {
    const formData = new FormData();
    if (e.attachments) {
      e.files = e.attachments;
      delete e.attachments;
    }

    Object.entries(e).forEach(entry => {
      if (Array.isArray(entry[1]))
        entry[1].forEach(arrayEntry => {
          if (arrayEntry.blob && arrayEntry.file_name)
            formData.append(entry[0], arrayEntry.blob, arrayEntry.file_name);
        });
      else formData.append(entry[0], entry[1]);
    });

    post(
      {
        ...ENDPOINTS.CREATE_ENTRY,
        path: ENDPOINTS.CREATE_ENTRY.path.replace(":sessionId", sessionId),
      },
      formData
    );
  };

  return {
    createEntry,
    loadingCreate: status.isLoading,
  };
};
