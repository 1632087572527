import {
  Footer,
  LeftDiv,
  Container,
  SectionHeader,
  VerticalContainer,
  HorizontalContainer,
} from "./latestRunCard.styles";
import { useTranslation } from "react-i18next";
import { useLatestRun } from "./hooks/useLatestRun";
import { Dot } from "../../../../components/dot/dot";
import { ChecksSection } from "./checksSection/checksSection";
import { ScenariosSection } from "./ScenariosSection/scenariosSection";
import { useApp } from "../../../../context/app/app.provider";

export const LatestRunCard = ({ run, isLatest }) => {
  const { t } = useTranslation();
  const {
    status,
    timeAgo,
    runStatus,
    checkLabels,
    checksPerformed,
    scenarioStatuses,
    totalFailedChecks,
    scenariosChartData,
  } = useLatestRun(run);
  const { isMobile } = useApp();

  return (
    <Container>
      <HorizontalContainer isMobile={isMobile}>
        <VerticalContainer isMobile={isMobile}>
          <SectionHeader data-test="scenarios-title">
            {isLatest
              ? t("runView.testevolvevisual.latestScenariosSectionTitle")
              : t("runView.testevolvevisual.scenariosSectionTitle")}
          </SectionHeader>
          <ScenariosSection
            scenarioStatuses={scenarioStatuses}
            scenariosChartData={scenariosChartData}
          />
          <Footer>
            <LeftDiv data-test="test-status">
              <Dot colour={status} />
              <p data-test="runStatus">{runStatus}</p>
            </LeftDiv>
            <p data-test="time-ago">{timeAgo}</p>
          </Footer>
        </VerticalContainer>
        <VerticalContainer isMobile={isMobile}>
          <SectionHeader data-test="visual-checks-title">
            {isLatest
              ? t("runView.testevolvevisual.latestChecksSectionTitle")
              : t("runView.testevolvevisual.checksSectionTitle")}
          </SectionHeader>
          <ChecksSection
            checksPerformed={checksPerformed}
            totalFailedChecks={totalFailedChecks}
            checkLabels={checkLabels}
          />
        </VerticalContainer>
      </HorizontalContainer>
    </Container>
  );
};
