export const isUserAdmin = usr =>
  usr?.global_roles?.some(global => global.key === "USER_ADMIN");

export const extractProjects = users => {
  if (!Array.isArray(users)) users = [users];
  let userProjectsObj = {};
  users?.forEach(user => {
    let usr = users.find(usr => usr.id === user.id);
    let usrProjects = isUserAdmin(user)
      ? usr?.project_roles
      : usr?.project_roles?.filter(usrPrj =>
          usrPrj.roles.some(prjRole => prjRole.key === "PROJECT_USER")
        );
    userProjectsObj[user.id] = usrProjects?.map(usrPrj => ({
      label: usrPrj.project.name,
      value: usrPrj.project,
    }));
  });
  return userProjectsObj;
};

export const findObjectDifference = (obj1, obj2) => {
  let updated = {};
  Object.entries(obj1).forEach(([key, value]) => {
    const newValue = obj2[key];
    if (value !== newValue) updated = { ...updated, [key]: newValue };
  });
  return updated;
};
