import { useTranslation } from "react-i18next";
import { NodeCard } from "../../common/NodeCard";
import { useNodeView } from "../../hooks/useNodeView";
import {
  PROVIDERS,
  RUN_STATUSES,
} from "../../../../static/constants/constants";
import { Container } from "./nodeCardTestEvolveAccessibility.styles";
import { useLatestRun } from "../../../runView/testevolveExploratory/hooks/run/useLatestRun";
import { AccessibilityNodeCardGraph } from "./accessibilityNodeCardGraph/accessibilityNodeCardGraph";

export const NodeCardTestEvolveAccessibility = ({
  run,
  getLabels,
  onRelease,
  frozen,
  isShared,
}) => {
  const { t } = useTranslation();
  const { nodeId } = useNodeView();
  const { startTime, runProps } = useLatestRun(run);
  const runStatus =
    t(`runCard.testevolve.status.${run.status.toLowerCase()}`) ||
    t("runCard.testevolveexploratory.n/a");

  return (
    <NodeCard
      data-test="test-evolve-accessibility-card"
      type={run.category.replace(".", "")}
      nodeId={nodeId}
      run={run}
      startTime={startTime}
      label={run.label}
      getLabels={getLabels}
      runProps={runProps}
      onRelease={onRelease}
      frozen={frozen}
      isShared={isShared}
      provider={PROVIDERS.MANUAL}
      runStatus={runStatus}
      status={run.status}
      hideApproval={run.status.toLowerCase() !== RUN_STATUSES.CLOSED}
      hideFreeze={run.status.toLowerCase() !== RUN_STATUSES.CLOSED}
      noRunProperties
    >
      <Container>
        <AccessibilityNodeCardGraph run={run} />
      </Container>
    </NodeCard>
  );
};
