import { useFetch } from "../hooks";
import { useEffect, useState } from "react";
import { nodeReducer } from "./nodes.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { ENDPOINTS } from "../../static/constants/routeConfig";

const initialState = { nodeSummary: {}, node: {}, runs: [] };

const [useHook, NodeProvider] = createState("node", initialState, nodeReducer);

const useNode = () => {
  const { node, nodeSummary, runs, runView, dispatch } = useHook();
  const { get, error, status, body, origin } = useFetch();
  const [getSuccess, setGetSuccess] = useState(false);

  const getNodeSummary = id =>
    get({
      ...ENDPOINTS.NODE_SUMMARY,
      path: ENDPOINTS.NODE_SUMMARY.path.replace(":nodeId", id),
    });

  const setNodeSummary = node =>
    dispatch({ type: ACTIONS.SET_NODE_SUMMARY, payload: node });

  useEffect(() => {
    if (status.isResolved && body && !origin.includes("labelled")) {
      setNodeSummary(body);
      setGetSuccess(true);
    }
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    node,
    nodeSummary,
    runView,
    runs,
    setNodeSummary,
    getNodeSummary,
    getSuccess,
    resetGetSuccess: () => setGetSuccess(false),
    error,
    nodeSummaryErr: status.isRejected,
  };
};

export { useNode, NodeProvider };
