import styled, { css, keyframes } from "styled-components";
import { RUN_STATUSES } from "../../static/constants/constants";

const pulsing = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

export const Dot = styled.div`
  height: ${({ size, theme }) => size || theme.spacing.padding};
  width: ${({ size, theme }) => size || theme.spacing.padding};
  background-color: ${({ colour, theme }) =>
    (colour === RUN_STATUSES.ABANDONED && theme.colours.error) ||
    (colour === RUN_STATUSES.IN_PROGRESS && theme.colours.success) ||
    (colour === RUN_STATUSES.COMPLETE && theme.colours.success) ||
    (colour === RUN_STATUSES.OPEN && theme.colours.success) ||
    theme.colours.pending};
  border-radius: 50%;
  display: inline-block;

  ${({ colour }) =>
    colour === "in_progress" &&
    css`
      animation: ${pulsing} 2s linear infinite;
      -webkit-animation: ${pulsing} 2s linear infinite;
      -moz-animation: ${pulsing} 2s linear infinite;
    `};
`;
