import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import React from "react";
import { useQueryParams } from "../../context/hooks";
import { PROJECT } from "../../static/constants/constants";
// import { ButtonStyles, IconButtonStyles } from "../../elements/button/Button";

export const CustomLink = styled(
  ({
    // wrapper,
    // isActive,
    children,
    to,
    // primary,
    // success,
    // accent,
    className,
    // nav,
    params,
    // button,
    // buttonIcon,
    // nounderline,
    // fontWeightMedium,
    small,
    accent,
    // disabled,
    external,
    ...other
  }) => {
    const externalLink = external || /^http[s]?:\/\//.test(to);
    const { getParam } = useQueryParams();

    if (!externalLink) {
      return (
        <StyledLink
          to={`${to}?project=${getParam(PROJECT)}`}
          className={className}
          params={params}
          accent={accent}
          small={small}
          {...other}
        >
          {children}
        </StyledLink>
      );
    }
    return (
      <a
        href={to}
        {...other}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
)`
  cursor: pointer;
  text-decoration: none;

  ${({ wrapper, nav, accent, theme, button, nounderline, disabled }) =>
    !button &&
    css`
      color: ${(wrapper && "inherit") ||
      (nav && theme.colours.primary) ||
      (accent && theme.colours.bulletColour) ||
      theme.colours.primary};
      &:hover,
      &:focus {
        ${!wrapper && !nav && "text-decoration: underline;"};
        ${nounderline && "text-decoration: none"};
      }
      ${disabled && "pointer-events: none;"};
    `}
`;

export const StyledLink = styled(Link)`
  cursor: ${({ theme }) => theme.cursors.link};
  text-decoration: none;
  ${({ accent, theme, bold, disabled }) =>
    css`
      color: ${accent ? theme.colours.bulletColour : theme.colours.mainText};
      font-weight: ${bold && theme.fontWeight.bold};
      &:hover,
      &:focus {
        outline: ${({ theme }) => theme.borders.noBorder};
        color: ${accent ? theme.colours.mainText : theme.colours.haloAccent};
        text-decoration: ${({ theme }) => theme.decoration.underline};
      }
      ${disabled && "pointer-events: none;"};
    `}
`;
