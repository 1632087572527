import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { useApp } from "../../../../context/app/app.provider";
import { ApiKeyRow, ListTitle, NoKeysText } from "./apiKeysTab.styles";

export const ApiKeysList = ({ keys, deleteKey }) => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();

  return (
    <>
      <ListTitle>
        <h3>{t("settings.apiKeys.createApiKey.columnTitles.alias")}</h3>
        <h3>{t("settings.apiKeys.createApiKey.columnTitles.actions")}</h3>
      </ListTitle>
      {keys && keys.length ? (
        keys.map(k => (
          <ApiKeyRow
            isNotDesktop={isNotDesktop}
            key={`row-${k.api_key_alias}`}
            data-test={`row-${k.api_key_alias}`}
          >
            <div key={`key-${k.api_key_alias}`}>
              <p>{k.api_key_alias}</p>
            </div>
            <Button
              data-test="delete-key-btn"
              danger
              onClick={() => deleteKey(k.api_key_identifier)}
            >
              {t("btnDelete")}
            </Button>
          </ApiKeyRow>
        ))
      ) : (
        <NoKeysText>{t("settings.apiKeys.noKeys")}</NoKeysText>
      )}
    </>
  );
};
