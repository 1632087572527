import { useEffect } from "react";
import { useFetch } from "../../../../../context/hooks";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";

export const useUnlockUser = () => {
  const { put, status, error } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isRejected)
      createNotification(types.ERROR, `errors.unlockAccount.${error.code}`);
    if (status.isResolved)
      createNotification(types.SUCCESS, "success.unlockAccount");
  }, [status, error]);

  const unlockUser = userId =>
    put({ path: `${ENDPOINTS.UNLOCK_USER.path}?userId=${userId}` });

  return {
    unlockUser,
    success: status.isResolved,
  };
};
