import { ACTIONS } from "../../static/constants/actions";

export const subscriptionPlansReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_SUBSCRIPTION_PLANS:
      return { ...state, subscriptionPlans: payload };
    default:
      return state;
  }
};
