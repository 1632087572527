import React from "react";
import { useTranslation } from "react-i18next";
import {
  ReadOnlySelect,
  ReportConfigContainer,
  ReportEmailsField,
  ReportEmailsLabel,
  ReportFieldLabel,
  ReportLabelsField,
  ReportNameField,
  ReportScheduleField,
  ReportStartDateField,
} from "./reports.styles";

export const ReportConfig = ({ reportConfig }) => {
  const { t } = useTranslation();

  const mapOptions = values => {
    return values.map(label => {
      return {
        id: label,
        label: label,
        value: label,
      };
    });
  };

  return (
    <ReportConfigContainer>
      <ReportNameField className={"Field"}>
        <ReportFieldLabel>{t("reports.columnHeadings.name")}</ReportFieldLabel>
        {reportConfig.name}
      </ReportNameField>
      <ReportLabelsField className={"Field"}>
        <ReportFieldLabel>
          {t("reports.columnHeadings.labels")}
        </ReportFieldLabel>
        <ReadOnlySelect
          data-test="report-labels"
          isMulti={true}
          isDisabled={true}
          value={mapOptions(reportConfig.labels)}
          isClearable={false}
          classNamePrefix={"Select"}
        />
      </ReportLabelsField>
      <ReportScheduleField className={"Field"}>
        <ReportFieldLabel>
          {t("reports.columnHeadings.schedule")}
        </ReportFieldLabel>
        <p>{t(`reports.schedule.${reportConfig.frequency}`)}</p>
      </ReportScheduleField>
      <ReportStartDateField className={"Field"}>
        <ReportFieldLabel>
          {t("reports.columnHeadings.startDate")}
        </ReportFieldLabel>
        <p>{reportConfig.start_date}</p>
      </ReportStartDateField>
      <ReportEmailsLabel className={"Field"}>
        <ReportFieldLabel>
          {t("reports.columnHeadings.recipients")}
        </ReportFieldLabel>
        {t("reports.columnHeadings.recipients")}
      </ReportEmailsLabel>
      <ReportEmailsField className={"Field"}>
        <ReadOnlySelect
          data-test="report-emails"
          isMulti={true}
          isDisabled={true}
          value={mapOptions(reportConfig.distribution_emails)}
          isClearable={false}
          classNamePrefix={"Select"}
        />
      </ReportEmailsField>
    </ReportConfigContainer>
  );
};
