import { useOrganisationUsers } from "../organisationUsers.provider";
import { useGlobalRoles } from "./patch/globalRoles";
import { useProjectRoles } from "./patch/projectRoles";
import { useUserStatus } from "./patch/userStatus";

const USER_ADMIN_OBJ = { key: "USER_ADMIN", type: "GLOBAL" };
const PARTNER_ADMIN_OBJ = { key: "PARTNER_ADMIN", type: "GLOBAL" };
const PARTNER_USER_OBJ = { key: "PARTNER_USER", type: "GLOBAL" };
const PROJECT_USER_OBJ = { key: "PROJECT_USER", type: "PROJECT" };

export const useHandleSaveButton = () => {
  const { patchGlobalRole, executeQueue, addFunctionToQueue } =
    useGlobalRoles();
  const { patchProjectRole } = useProjectRoles();
  const { patchUserStatus } = useUserStatus();
  const {
    users,
    updatedAdminUsers,
    updatedActiveUsers,
    updatedUserProjects,
    updatedPartnerUsers,
    initialUserProjects,
    initialPartnerAdmins,
    initialAdminUsers,
    initialPartnerUsers,
  } = useOrganisationUsers();

  const filterRole = (roles, roleToFilter) =>
    roles.filter(p => p.key !== roleToFilter);

  const transformRoles = roles =>
    roles.map(role => ({ key: role.key, type: "PROJECT" }));

  const getProjectRoles = (user, project) =>
    user.project_roles.find(entry => entry.project.id === project.id)?.roles;

  const removeUpdatedProjects = (initialProjects, updatedProjects, user) => {
    initialProjects.forEach(project => {
      const projectRemoved = !updatedProjects.find(
        newProject => newProject.id === project.id
      );

      if (projectRemoved) {
        const projectRoles = getProjectRoles(user, project);
        const transformedRoles = transformRoles(projectRoles);
        const filteredRoles = filterRole(
          transformedRoles,
          PROJECT_USER_OBJ.key
        );
        patchProjectRole(user.id, project.id, filteredRoles);
      }
    });
  };

  const addUpdatedProjects = (initialProjects, updatedProjects, user) => {
    updatedProjects.forEach(project => {
      const projectAdded = !initialProjects.find(
        newProject => newProject.id === project.id
      );

      if (projectAdded) {
        const projectRoles = getProjectRoles(user, project);
        const transformedRoles = transformRoles(projectRoles);
        patchProjectRole(user.id, project.id, [
          ...transformedRoles,
          PROJECT_USER_OBJ,
        ]);
      }
    });
  };

  const patchProjectsAccess = (updatedProjects, initialProjects) => {
    Object.entries(updatedProjects).forEach(([userId, value]) => {
      const user = users.find(user => `${user.id}` === `${userId}`);

      removeUpdatedProjects(initialProjects[userId], value, user);

      addUpdatedProjects(initialProjects[userId], value, user);
    });
  };

  const patchWithUpdatedGlobalRoles = (key, value, role, singleRole) => {
    const currentRoles = singleRole
      ? []
      : users.find(user => `${user.id}` === key)?.global_roles;

    let payload = value ? [role] : filterRole(currentRoles, role.key);

    patchGlobalRole(key, payload);
  };

  const addPatchToQueue = (key, value, role) => {
    addFunctionToQueue(() => patchWithUpdatedGlobalRoles(key, value, role));
  };

  const handleGlobalRoles = () => {
    Object.entries(updatedAdminUsers).forEach(([key, value]) => {
      if (value && updatedPartnerUsers[key]) return;
      else if (value && initialPartnerUsers[key])
        addPatchToQueue(key, true, PARTNER_ADMIN_OBJ, true);
      else if (
        value === false &&
        updatedPartnerUsers[key] === false &&
        initialPartnerAdmins[key]
      )
        return;
      else if (!value && initialPartnerAdmins[key])
        addPatchToQueue(key, true, PARTNER_USER_OBJ, true);
      else addPatchToQueue(key, value, USER_ADMIN_OBJ, true);
    });

    Object.entries(updatedPartnerUsers).forEach(([key, value]) => {
      if (value && updatedAdminUsers[key])
        addPatchToQueue(key, true, PARTNER_ADMIN_OBJ, true);
      else if (value && initialAdminUsers[key])
        addPatchToQueue(key, true, PARTNER_ADMIN_OBJ, true);
      else if (
        value === false &&
        updatedAdminUsers[key] === false &&
        initialPartnerAdmins[key]
      )
        addPatchToQueue(key, false, PARTNER_ADMIN_OBJ, true);
      else if (!value && initialPartnerAdmins[key])
        addPatchToQueue(key, true, USER_ADMIN_OBJ, true);
      else addPatchToQueue(key, value, PARTNER_USER_OBJ, true);
    });
  };

  const handleProjectRoles = () => {
    addFunctionToQueue(() =>
      patchProjectsAccess(updatedUserProjects, initialUserProjects)
    );
  };

  const handleSaveButton = () => {
    Object.entries(updatedActiveUsers).forEach(([key, value]) =>
      patchUserStatus(key, value)
    );

    handleGlobalRoles();
    handleProjectRoles();

    executeQueue();
  };

  return {
    handleSaveButton,
  };
};
