import styled from "styled-components";

export const TextArea = ({
  label,
  margin,
  width,
  onChange,
  type,
  placeholder,
  defaultValue,
  inputRef,
  setter,
  lineHeight,
  minHeight,
  minWidth,
  ...rest
}) => (
  <StyledInputContainer margin={margin} width={width}>
    {label ? <StyledLabel>{label}</StyledLabel> : null}
    <StyledTextArea
      placeholder={placeholder || label}
      onChange={setter ? e => setter(e.target.value) : onChange}
      type={type}
      defaultValue={defaultValue}
      ref={inputRef}
      lineHeight={lineHeight}
      minHeight={minHeight}
      minWidth={minWidth}
      {...rest}
    />
  </StyledInputContainer>
);

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ margin }) => margin};
  width: ${({ width }) => (width ? width : "100%")};
`;

export const StyledTextArea = styled.textarea`
  height: ${({ theme }) => theme.sizing.inputHeight};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colours.error : theme.colours.background};
  padding: ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme }) => theme.colours.background};
  color: ${({ theme }) => theme.colours.mainText};

  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colours.error : theme.colours.borderColour};

  &:focus {
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.colours.error : theme.colours.haloAccent};
    outline: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }
  line-height: ${({ lineHeight }) => lineHeight};

  max-width: 100%;
  width: 100%;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "100%")};

  min-height: ${({ theme, minHeight }) =>
    minHeight ? minHeight : theme.sizing.textAreaMin};
  max-height: ${({ theme }) => theme.sizing.textAreaMax};
`;

export const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  text-align: ${props => (props.align ? props.align : "left")};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
`;
