import { memo, useEffect, useState } from "react";
import { Button } from "../../../../components";
import { CriteriaStatusMenu } from "./criteriaStatusMenu";
import { CustomLink } from "../../../../components/Link/StyledLink";
import { ACCESSIBILITY_STATUSES } from "../../../../static/constants/constants";
import { RichTextEditor } from "../../../../components/richTextEditor/richTextEditor";
import {
  LinkIcon,
  LinkIconContainer,
} from "../testEvolveAccessiblityRunView.styles";
import {
  AssessmentNotes,
  AssessmentNotesContainer,
  AutomationContainer,
  Container,
  DeprecatedLabel,
  DescriptionContainer,
  NoteButtonContainer,
  NoteTitleContainer,
  Reference,
  ReferencesContainer,
  RichTextEditorContainer,
  SeeMoreDescription,
  StepsToAssessContainer,
  StepsToAssessSection,
  StepsToAssessTitle,
  StyledRobotIcon,
  SuccessCriteriaStepContainer,
  TitleContainer,
} from "./rightWidget.styles";
import { usePatchSuccessCriteria } from "./patchSuccessCriteria";
import { useTranslation } from "react-i18next";
import { ButtonContainer } from "../../testevolveExploratory/testevolveExploratoryRunView.styles";
import { ReactComponent as PencilIcon } from "../../../../static/assets/icon-pencil.svg";
import { mediumDateTime } from "../../../../utils/dates/dateUtils";

export const SuccessCriteriaUnMemo = ({ successCriteria, runId, getRun }) => {
  const { t } = useTranslation();
  const { editStatus, editNote } = usePatchSuccessCriteria(getRun);
  const [editNoteMode, setEditNoteMode] = useState(false);
  const [noteContent, setNoteContent] = useState(
    successCriteria.assessment_notes || ""
  );
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    setEditNoteMode(false);
    setNoteContent(successCriteria.assessment_notes || "");
  }, [successCriteria.ref_id, successCriteria.assessment_notes]);

  useEffect(() => {
    setShowFullDescription(false);
  }, [successCriteria.ref_id]);

  return (
    <>
      <Container id={successCriteria.title} successCriteria>
        <TitleContainer>
          <h2>
            {successCriteria.ref_id}. {successCriteria.title}
          </h2>
          <NoteButtonContainer
            status={
              successCriteria.status?.user && successCriteria.status?.time
            }
          >
            <CriteriaStatusMenu
              runId={runId}
              editStatus={editStatus}
              status={
                successCriteria.status || ACCESSIBILITY_STATUSES.NOT_STARTED
              }
              refId={successCriteria.ref_id}
            />
            {successCriteria.status?.user ? (
              <p>{successCriteria.status.user}</p>
            ) : null}
            {successCriteria.status?.time ? (
              <p>{mediumDateTime(successCriteria.status.time)}</p>
            ) : null}
          </NoteButtonContainer>
        </TitleContainer>
        {successCriteria.deprecated ? (
          <DeprecatedLabel>
            {t("runView.testevolveaccessibility.deprecated")}
          </DeprecatedLabel>
        ) : null}
        {successCriteria.automation_coverage ? (
          <AutomationContainer>
            <StyledRobotIcon />
            <p>{t("runView.testevolveaccessibility.assistedByAutomation")}</p>
          </AutomationContainer>
        ) : null}
        <ReferencesContainer>
          <Reference level>
            {t("runView.testevolveaccessibility.createRun.level")}:{" "}
            {successCriteria.level}
          </Reference>
          {successCriteria.references?.map(ref => (
            <CustomLink external to={ref.url} key={`${ref.title}-reference`}>
              <Reference>
                {ref.title}
                <LinkIconContainer noHover>
                  <LinkIcon />
                </LinkIconContainer>
              </Reference>
            </CustomLink>
          ))}
        </ReferencesContainer>
        <DescriptionContainer showFullDescription={showFullDescription}>
          <p>{successCriteria.description}</p>
          {successCriteria.special_cases?.map(sc =>
            sc.title && !sc.description ? (
              <p key={`${sc.title}-p`}>- {sc.title}</p>
            ) : (
              <>
                {sc.title ? <h3>{sc.title}:</h3> : null}
                <p key={`${sc.title}-${sc.description}-p`}>{sc.description}</p>
              </>
            )
          )}
          {successCriteria.notes?.map(step => (
            <>
              <h3>{t("runView.testevolveaccessibility.note")}</h3>
              <p key={`${step.content}-p`}>{step.content}</p>
            </>
          ))}
        </DescriptionContainer>
        <SeeMoreDescription
          onClick={() => setShowFullDescription(e => !e)}
          showFullDescription={showFullDescription}
        >
          <p>
            {t(
              showFullDescription
                ? "runView.testevolveaccessibility.hideDescription"
                : "runView.testevolveaccessibility.showFullDescription"
            )}
          </p>
        </SeeMoreDescription>
      </Container>
      <SuccessCriteriaStepContainer>
        {successCriteria.steps_to_assess_compliance &&
        successCriteria.steps_to_assess_compliance?.length ? (
          <StepsToAssessSection successCriteria>
            <StepsToAssessTitle>
              {t("runView.testevolveaccessibility.stepsToAssist")}
            </StepsToAssessTitle>
            {successCriteria.steps_to_assess_compliance.map(step => (
              <StepsToAssessContainer key={`${step.title}-container`}>
                <h3 key={`${step.title}-p`}>{step.title}</h3>
                <ul
                  key={`${step.title}-${step.description}-p`}
                >{`  ${step.description}`}</ul>
              </StepsToAssessContainer>
            ))}
          </StepsToAssessSection>
        ) : null}
        {successCriteria.deprecated ? null : (
          <AssessmentNotes onClick={() => setEditNoteMode(true)}>
            {editNoteMode ? (
              <RichTextEditorContainer data-test="rich-text-edit-note">
                <NoteTitleContainer>
                  <h2>
                    {t("runView.testevolveaccessibility.assessmentNotes")}
                  </h2>
                  <PencilIcon />
                </NoteTitleContainer>
                <RichTextEditor
                  initial={noteContent}
                  identifier={`entry-${successCriteria.ref_id}`}
                  setter={setNoteContent}
                />
                <ButtonContainer>
                  <Button
                    secondary
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setEditNoteMode(false);
                    }}
                  >
                    {t("btnCancel")}
                  </Button>
                  <Button
                    onClick={() =>
                      editNote(runId, successCriteria.ref_id, noteContent)
                    }
                  >
                    {t("btnSave")}
                  </Button>
                </ButtonContainer>
              </RichTextEditorContainer>
            ) : (
              <AssessmentNotesContainer>
                <NoteTitleContainer>
                  <h2>
                    {t("runView.testevolveaccessibility.assessmentNotes")}
                  </h2>
                  <PencilIcon />
                </NoteTitleContainer>
                <RichTextEditor
                  initial={successCriteria.assessment_notes}
                  identifier={`entry-${successCriteria.ref_id}`}
                  readOnly={true}
                />
              </AssessmentNotesContainer>
            )}
          </AssessmentNotes>
        )}
      </SuccessCriteriaStepContainer>
    </>
  );
};

export const SuccessCriteria = memo(SuccessCriteriaUnMemo);
