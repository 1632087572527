import styled from "styled-components";
import { Button } from "../../../../../components";

export const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colours.foreground};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colours.foreground};
  color: ${({ theme }) => theme.colours.mainText};

  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.padding};
  padding-top: ${({ theme }) => theme.spacing.paddingMD};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const StyledButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -30px;
`;

export const StyledButtonIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius};
  height: ${({ theme }) => theme.sizing.btnHeight};
  width: ${({ theme }) => theme.sizing.btnHeight};

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
  svg {
    height: ${({ theme }) => theme.sizing.iconSize};
    width: ${({ theme }) => theme.sizing.iconSize};
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  background-color: ${({ theme }) => theme.colours.primary};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid ${({ theme }) => theme.colours.background};
`;

export const TaxMessageContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
  font-style: italic;
`;

export const SwitchButton = styled(Button)`
  background-color: ${({ secondary, theme }) =>
    secondary && theme.colours.foreground};
  &:focus {
    outline: 0;
  }
  width: 100%;
  @media (min-width: 900px) {
    width: 300px;
  }
  margin: 0 1px;
`;

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: 72px;
  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

export const VerticalContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &:first-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colours.foreground};
    padding-bottom: ${({ theme }) => theme.spacing.paddingMD};
    margin-bottom: ${({ theme }) => theme.spacing.paddingMD};
    @media (min-width: 900px) {
      border-bottom: 0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
  @media (min-width: 900px) {
    &:first-of-type {
      border-right: 1px solid ${({ theme }) => theme.colours.background};
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingMD};

  button {
    width: 100%;
  }
`;

export const EnterpriseMsg = styled.p`
  color: ${({ theme }) => theme.colours.error};
`;

export const SeatInput = styled.input`
  width: ${({ theme }) => theme.spacing.paddingXL};
  height: ${({ theme }) => theme.sizing.btnHeight};
  text-align: center;
  border-radius: ${({ theme }) => theme.borders.radiusM};
  color: ${({ theme }) => theme.colours.mainText};
  background-color: ${({ theme }) => theme.colours.background};
  outline: none;
  border: none;
`;

export const Price = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const SectionTitle = styled.h1`
  font-weight: 600;
`;

export const PlanInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};

  ul {
    line-height: ${({ theme }) => theme.spacing.paddingLG};
  }
`;

export const SummaryContainer = styled.div`
  width: 100%;
  display: flex;

  margin-bottom: ${({ theme }) => theme.spacing.padding};
  height: ${({ theme }) => theme.spacing.paddingLG};

  h4 {
    color: ${({ theme }) =>
      theme.isDark ? theme.colours.mainText : theme.colours.haloAccent};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colours.background};

  gap: ${({ theme }) => theme.spacing.padding};
  width: 320px;
  height: 480px;

  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: 48px 48px;
`;
