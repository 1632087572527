import React from "react";
import { NodeCard } from "./nodeCard";
import { useTranslation } from "react-i18next";
import { useNodeView } from "../hooks/useNodeView";
import { useApp } from "../../../context/app/app.provider";
import {
  Container,
  CardsContainer,
  NodeCardWrapper,
} from "./nodeCardContainer.styles";

export const NodeCardContainer = () => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();
  const { nodeSummary } = useNodeView();

  return (
    <Container isNotDesktop={isNotDesktop}>
      <h1>{t("nodeView.latestRunTitle")}</h1>
      <CardsContainer isNotDesktop={isNotDesktop}>
        {nodeSummary.runs.map(run => (
          <NodeCardWrapper key={run.id}>
            <NodeCard key={run.id} category={run.category} run={run} />
          </NodeCardWrapper>
        ))}
      </CardsContainer>
    </Container>
  );
};
