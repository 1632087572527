import {
  MenuContainer,
  StyledMenu,
  StyledMenuItem,
} from "../../NavigationPane/navigation/navigationParent/navigationParent.styles";

export const ReleaseDetailsContextMenu = ({
  release,
  t,
  openCloseReleaseModal,
  menuState,
  r,
  toggleMenu,
  isClosed,
  openRelease,
}) => {
  return (
    <MenuContainer>
      <StyledMenu
        transition
        position="auto"
        align="start"
        {...menuState}
        anchorRef={r}
        onClose={() => {
          toggleMenu(false);
        }}
      >
        {isClosed ? (
          <StyledMenuItem
            onClick={() => openRelease(release.id)}
            data-test="open-release"
          >
            {t("releases.openRelease.title")}
          </StyledMenuItem>
        ) : (
          <StyledMenuItem
            onClick={openCloseReleaseModal}
            data-test="close-release"
          >
            {t("releases.closeRelease.title")}
          </StyledMenuItem>
        )}
      </StyledMenu>
    </MenuContainer>
  );
};
