import styled from "styled-components";

export const VulnerabilitiesContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  text-align: center;

  h2 {
    padding-bottom: ${({ theme }) => theme.spacing.padding};
    font-size: 18px;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

export const TotalVulnerabilities = styled.h1`
  padding: ${({ theme }) => theme.spacing.padding};
  font-size: 48px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
