import { useState, useEffect } from "react";
import {
  GET_FLARE_VERSION_URL,
  GET_HALO_CLI_VERSION_URL,
} from "../../../static/constants/constants";

export const useDownloads = () => {
  const [flareVersion, setFlareVersion] = useState("");
  const [haloCliVersion, setHaloCliVersion] = useState("");

  const fetchFlareVersion = async () => {
    const res = await fetch(GET_FLARE_VERSION_URL.path);
    if (res.status !== 200) {
      setFlareVersion("");
      return;
    }
    const text = await res.text();
    setFlareVersion(text);
  };

  const fetchHaloCliVersion = async () => {
    const res = await fetch(GET_HALO_CLI_VERSION_URL.path);
    if (res.status !== 200) {
      setHaloCliVersion("");
      return;
    }
    const text = await res.text();
    setHaloCliVersion(text);
  };

  useEffect(() => {
    fetchFlareVersion();
    fetchHaloCliVersion();
  }, []);

  return {
    flareVersion,
    haloCliVersion,
  };
};
