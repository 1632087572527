import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useReleases } from "../../../context/releases/releases.provider";
import _ from "lodash";
import { convertDateToSQL } from "../../../utils/dates/dateUtils";

export const useEditRelease = (toggleCreateReleaseOpen, release) => {
  const { patch, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const { modifyRelease } = useReleases();

  const compareRelease = e => {
    let payload = {};

    const formattedOpenDate = convertDateToSQL(e.open_date);
    const formattedCloseDate = convertDateToSQL(e.close_date);

    if (e.name !== release.name) payload.name = e.name;
    if (e.description !== release.description)
      payload.description = e.description;
    if (formattedOpenDate !== release.open_date)
      payload.open_date = formattedOpenDate;
    if (formattedCloseDate !== release.close_date)
      payload.close_date = formattedCloseDate;
    if (_.isEmpty(payload)) return false;

    return payload;
  };

  const editRelease = e => {
    const payload = compareRelease(e);
    if (!payload) return;

    patch(
      {
        ...ENDPOINTS.RELEASE,
        path: ENDPOINTS.RELEASE.path.replace(":id", release.id),
      },
      payload
    );
  };

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(types.SUCCESS, "releases.editModal.success");
      toggleCreateReleaseOpen();
      modifyRelease(release.id, body);
    }
    if (status.isRejected && error)
      createNotification(types.ERROR, "releases.editModal.error");
  }, [status.isResolved, body]);

  return {
    submit: editRelease,
    createNodeLoading: status.isLoading,
    isError: status.isRejected,
    isLoading: status.isLoading,
    compareRelease,
  };
};
