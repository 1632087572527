import React, { useEffect, useState } from "react";
import { Modal } from "../../..";
import { useTranslation } from "react-i18next";
import {
  Table,
  GreenText,
  ContainerMsg,
  PurpleSpan,
} from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/modals/modals.styles";
import {
  MONTHLY,
  ANNUAL,
  ANNUALLY,
} from "../../../../static/constants/constants";
import { formatCurrency } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/formatCurrency";
import { useUpdateSubscription } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/hooks/updateSubscription";
import { useSubscriptionPreview } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/hooks/subscriptionPreview";

export const UpdateSubscriptionModal = ({
  closeModal,
  currentMaxSeats,
  productSelected,
  newSeats,
  currentPeriod,
  newPeriod,
  currentPrice,
  currentTax,
  currentTotal,
  newPrice,
  newTax,
  newTotal,
  showTax,
  country,
}) => {
  const { t } = useTranslation();
  const [amending, setAmending] = useState(false);
  const { updateSubscriptionAction } = useUpdateSubscription(closeModal);
  const { preview, getSubscriptionPreview } = useSubscriptionPreview();

  useEffect(() => {
    if (newSeats > currentMaxSeats) {
      getSubscriptionPreview(
        productSelected.name,
        newPeriod === ANNUALLY ? ANNUAL : newPeriod
      );
    }
  }, [currentMaxSeats, newSeats, newPeriod]);

  const update = () => {
    setAmending(true);
    updateSubscriptionAction(productSelected.product, newPeriod);
  };

  return (
    <Modal
      title={t("subscriptionUpdate.amendSubscription")}
      secondary={{
        text: t("paymentUpdate.cancel"),
        onClick: closeModal,
      }}
      primary={{
        text: amending
          ? t("subscriptionUpdate.amending")
          : t("subscriptionUpdate.amend"),
        onClick: update,
        disabled: amending,
      }}
      size={"medium"}
    >
      <Table>
        <thead>
          <tr>
            <th />
            <th>{t("subscriptionUpdate.Period")}</th>
            <th>{t("subscriptionUpdate.Price")}</th>
            {showTax ? (
              <>
                <th>{t("subscriptionUpdate.Tax")}</th>
                <th>{t("subscriptionUpdate.Total")}</th>
              </>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="from">
              <GreenText>{t("subscriptionUpdate.From")}</GreenText>
            </td>
            <td>{currentPeriod}</td>
            <td>{currentPrice}</td>
            {showTax ? (
              <>
                <td>{currentTax}</td>
                <td>
                  <GreenText>{currentTotal}</GreenText>
                </td>
              </>
            ) : (
              <></>
            )}
          </tr>
          <tr>
            <td>
              <GreenText>{t("subscriptionUpdate.To")}</GreenText>
            </td>
            <td>{newPeriod}</td>
            <td>{newPrice}</td>
            {showTax ? (
              <>
                <td>{newTax}</td>
                <td>
                  <GreenText>{newTotal}</GreenText>
                </td>
              </>
            ) : (
              <></>
            )}
          </tr>
        </tbody>
      </Table>
      <ContainerMsg>
        <p>
          {t("subscriptionUpdate.para1", {
            period:
              newPeriod === MONTHLY
                ? t("subscriptionUpdate.monthly")
                : t("subscriptionUpdate.annual"),
          })}
        </p>
        {newSeats > currentMaxSeats && (
          <p>
            {t("subscriptionUpdate.para2")}
            <PurpleSpan data-test="preview">
              {" "}
              {preview
                ? formatCurrency(
                    "en",
                    country.currencyIsoCode,
                    preview.prorated_invoice?.total
                  )
                : t("loading")}
              {showTax ? t("includingVat") : ""}
            </PurpleSpan>
            .
          </p>
        )}
        <p>{t("subscriptionUpdate.para3")}</p>
      </ContainerMsg>
    </Modal>
  );
};
