import React, { useEffect, useRef } from "react";
import {
  StyledLink,
  FakeButton,
  ScreenCover,
  StyledContainer,
  DetailsContainer,
  CollapseButton,
  OverallContainer,
  FakeContainer,
  NavContainer,
  DocumentationContainer,
  Spacer,
} from "./navigationPane.styles";
import { BiHomeAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
// import { RiDashboard3Line } from "react-icons/ri";
import { Navigation } from "./navigation/navigation";
import { ProjectContainer } from "./projectContainer/projectContainer";
import {
  IconContainer,
  StyledNavItem,
} from "./navigation/navigationItem/navigationItem.styles";
import { ProjectDropdown } from "./projectContainer/projectDropdown/projectDropdown";
import { OrganisationContainer } from "./organisationContainer/organisationContainer";
import { routes } from "../../router/routes";
import {
  NODE,
  RELEASE,
  TEST_EVOLVE_DOCUMENTATION,
} from "../../static/constants/constants";
import { useNav } from "./hooks/useNav";
import { useApp } from "../../context/app/app.provider";
import { useTree } from "../../context/tree/tree.provider";
import { useUser } from "../../context/user/user.provider";
import { ReactComponent as DocsIcon } from "../../static/assets/icon-docs.svg";
import { ReactComponent as LabelIcon } from "../../static/assets/icon-label.svg";
import { ReactComponent as GreyArrow } from "../../static/assets/chevron-right.svg";
import { ReactComponent as ReleaseIcon } from "../../static/assets/icon-release.svg";
import { ReactComponent as SettingsIcon } from "../../static/assets/settings-icon.svg";
import { ReactComponent as HierarchyIcon } from "../../static/assets/hierarchy-icon.svg";
import { ReactComponent as QuickStartIcon } from "../../static/assets/icon-quickstart.svg";
import { useReleases } from "../../context/releases/releases.provider";
import { useGetAllOpenReleases } from "../../context/releases/getAllOpenReleases";

export const NavigationPane = () => {
  const { paneOpen, projectDropdownOpen, toggleProjectDropdown, togglePane } =
    useNav();
  const { tree, getTree } = useTree();
  const { getAllOpenReleases } = useGetAllOpenReleases();
  const { allOpenReleases } = useReleases();
  const { isNotDesktop } = useApp();
  const { toggleGuideOpen } = useUser();
  const { t } = useTranslation();
  const paneRef = useRef(null);

  useEffect(() => {
    getTree();
    getAllOpenReleases({});
  }, []);

  return (
    <OverallContainer
      paneOpen={paneOpen}
      isNotDesktop={isNotDesktop}
      role="navigation"
      id="nav-pane"
      ref={paneRef}
    >
      <StyledContainer paneOpen={paneOpen} isNotDesktop={isNotDesktop}>
        <DetailsContainer
          isNotDesktop={isNotDesktop}
          onClick={isNotDesktop ? null : toggleProjectDropdown}
          open={projectDropdownOpen}
        >
          <OrganisationContainer />
          <ProjectContainer />
          {isNotDesktop ? (
            <StyledLink
              data-test="signin-link"
              disabled={true}
              rel="noreferrer"
              href={routes.PROJECTS.path}
            >
              <FakeButton data-test="fake-button">
                <p>{t("Change Project")}</p>
              </FakeButton>
            </StyledLink>
          ) : null}
        </DetailsContainer>
        <ProjectDropdown open={projectDropdownOpen} />
        {projectDropdownOpen ? (
          <ScreenCover onClick={toggleProjectDropdown} />
        ) : null}
        <NavContainer>
          <Navigation
            paneRef={paneRef}
            tree={[
              {
                name: t("navigation.home"),
                to: "/home",
                id: "home",
                Icon: BiHomeAlt,
              },
              // {
              //   name: t("navigation.dashboards"),
              //   to: "/dashboard",
              //   id: "dashboards",
              //   Icon: RiDashboard3Line,
              // },
              {
                name: t("navigation.unassignedLabels"),
                to: "/unassigned-labels",
                id: "unassigned-labels",
                Icon: LabelIcon,
              },
              {
                name: t("navigation.hierarchy"),
                Icon: HierarchyIcon,
                id: tree[0]?.id,
                children: tree[0]?.children.map(child => ({
                  ...child,
                  type: NODE,
                })),
                baseUrl: NODE,
                type: NODE,
                to: `/node/${tree[0]?.id}`,
                node: tree[0] || {},
                root: tree[0]?.root,
                chevron: true,
              },
              {
                name: t("navigation.allOpenReleases"),
                Icon: ReleaseIcon,
                id: RELEASE,
                type: RELEASE,
                baseUrl: RELEASE,
                chevron: true,
                root: true,
                to: "/release",
                children: allOpenReleases?.map(child => ({
                  ...child,
                  type: RELEASE,
                })),
              },
              {
                name: t("navigation.settings"),
                to: "/settings",
                Icon: SettingsIcon,
                id: "settings",
                admin: true,
              },
            ]}
          />
        </NavContainer>
        <Spacer />
        <DocumentationContainer>
          <StyledNavItem onClick={toggleGuideOpen} center>
            <IconContainer active={false}>
              <QuickStartIcon size={24} />
              <p>{t("navigation.gettingStarted")}</p>
            </IconContainer>
          </StyledNavItem>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={TEST_EVOLVE_DOCUMENTATION}
          >
            <StyledNavItem center>
              <IconContainer active={false}>
                <DocsIcon size={24} />
                <p>{t("navigation.docs")}</p>
              </IconContainer>
            </StyledNavItem>
          </a>
        </DocumentationContainer>
      </StyledContainer>
      {!paneOpen && !isNotDesktop ? <FakeContainer /> : null}
      {!isNotDesktop ? (
        <CollapseButton
          aria-label="toggle-nav-pane"
          onClick={() => togglePane()}
          right={paneOpen}
        >
          <GreyArrow onClick={() => togglePane()} />
        </CollapseButton>
      ) : null}
    </OverallContainer>
  );
};
