import styled from "styled-components";
import { Checkbox } from "../../../checkbox/checkbox";
import { mediaQuery } from "../../../../utils/theme/mediaQuery";

export const CheckboxTitle = styled.p`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  color: ${({ theme }) => theme.colours.primaryText};
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const MyCheckbox = styled(Checkbox)`
  display: flex;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;

  ${mediaQuery.lessThan("XL")`
    width: 100%;
  `}
`;
