export const routes = {
  SIGNIN: {
    path: "/signin",
  },
  SIGNUP: {
    path: "/signup",
  },
  DOWNLOADS: {
    path: "/downloads",
  },
  FORGOT_PASSWORD: {
    path: "/forgot-password",
  },
  SET_PASSWORD: {
    path: "/set-password",
  },
  RESET_PASSWORD: {
    path: "/reset-password",
  },
  HOME: {
    path: "/home",
  },
  PROFILE: {
    path: "/profile",
  },
  INIT: {
    path: "/",
  },
  PROJECTS: {
    path: "/projects",
  },
  SETTINGS: {
    path: "/settings",
  },
  SETTINGS_ORGANISATION: {
    path: "/settings/users",
  },
  SETTINGS_API_KEYS: {
    path: "/settings/api-keys",
  },
  SETTINGS_WITH_TAB: {
    path: "/settings/:settingsTab",
  },
  NODE_VIEW: {
    path: "/node/:nodeId",
  },
  RUN_VIEW_REPORT: {
    path: "/node/:nodeId/run/:runType/:label/:runId/report",
  },
  RUN_VIEW: {
    path: "/node/:nodeId/run/:runType/:label/:runId",
  },
  RUN_VIEW_RELEASE: {
    path: "/release/:releaseId/run/:runType/:label/:runId",
  },
  SHARED_RUN_VIEW: {
    path: "/kiosk/run/:runType/:label/:token",
  },
  ORGANISATION_SETTINGS: {
    path: "/organisation-settings/:settingsTab",
  },
  ORGANISATION_BILLING: {
    path: "/organisation-settings/billing",
  },
  BILLING: {
    path: "/organisation-settings/billing",
  },
  UNASSIGNED_LABELS: {
    path: "/unassigned-labels",
  },
  RELEASES: {
    path: "/release",
  },
  RELEASE: {
    path: "/release/:releaseId",
  },
  SHARED_RELEASE_VIEW: {
    path: "/kiosk/release/:releaseId/:token",
  },
};
