import styled from "styled-components";

export const PurpleSpan = styled.span`
  color: ${({ theme }) => theme.colours.haloAccent};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ContainerMsg = styled.div`
  margin-top: ${({ theme }) => theme.spacing.paddingMD};
  p {
    text-align: center;
  }
`;

export const OrganisationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const OrganisationDetailsRow = styled.div`
  display: flex;
  flex-direction: row;

  div {
    &:nth-child(1) {
      flex-grow: 0;
      flex-basis: 50%;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      color: ${({ theme }) => theme.colours.haloAccent};
      text-align: right;
      margin-right: ${({ theme }) => theme.spacing.padding};
    }

    &:nth-child(2) {
      flex-grow: 0;
      flex-basis: 50%;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      text-align: left;
      margin-left: ${({ theme }) => theme.spacing.padding};
    }
  }
`;
