import styled, { css } from "styled-components";
import { readableColor } from "polished";
import { ResponsiveContainer } from "recharts";
import { Cell } from "recharts";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50%;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const GraphContainer = styled(ResponsiveContainer)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  g {
    fill: ${({ theme }) => theme.colours.haloAccent};
  }

  tspan {
    fill: ${({ theme }) => theme.colours.mainText};
  }

  .recharts-label {
    tspan {
      fill: ${({ theme }) => theme.colours.white};
    }
  }
`;

export const StatusTable = styled.table`
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  color: ${({ theme }) => readableColor(theme.colours.background)};
  border-spacing: 0;
  font-size: 12px;

  th {
    letter-spacing: 0.5px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 14px;
  }

  td,
  th {
    text-align: left;
    padding: ${({ theme }) => theme.spacing.paddingSM};
  }

  tr {
    background-color: ${({ theme }) => theme.colours.foreground};
  }

  tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => readableColor(theme.colours.background)};
  }
`;

export const StyledRow = styled.tr`
  ${({ clickable }) =>
    clickable &&
    css`
      &:hover {
        cursor: pointer;
        outline: ${({ theme, clickable }) =>
          clickable ? `1px solid ${theme.colours.haloAccent}` : null};
      }
    `}
`;

export const ColourKey = styled.td`
  background-color: ${({ color }) => color};
`;

export const StyledCell = styled(Cell)`
  g {
    fill: ${({ fill }) => fill};
    color: ${({ fill }) => fill};
  }
`;
