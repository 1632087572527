import styled, { css } from "styled-components";
import { ReactComponent as EditIcon } from "../../../static/assets/icon-edit.svg";
import { Btn } from "../../../components/Button/Button";
import { ReactComponent as Icon } from "../../../static/assets/icon-freeze.svg";
import { Chevron } from "../../releaseView/release.styles";
import { ReactComponent as LinkIcon } from "../../../static/assets/icon-link.svg";

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};

  p {
    font-size: 12px;
    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const LabelContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colours.mainText};
`;

export const ButtonIcon = styled.div`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  margin: 0;
  color: ${({ theme }) => theme.colours.mainText};

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }

  svg {
    width: ${({ theme }) => theme.sizing.iconSize};
    height: ${({ theme }) => theme.sizing.iconSize};
    padding: 0;
    margin: 0;
  }
`;

export const LabelEditButton = styled(EditIcon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  z-index: 96;
  color: ${({ theme }) => theme.colours.mainText};
  min-width: ${({ theme }) => theme.sizing.svgHeightMD};

  &:hover {
    transition: transform 0.1s;
    transform: scale(1.25);
    background-color: ${({ theme }) => theme.colours.background};
  }
`;

export const RunDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border: 1px solid ${({ theme }) => theme.colours.haloAccent};

  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

export const FreezeButton = styled(Btn)`
  background-color: ${({ theme }) => theme.colours.freezeButtonBackground};
  color: ${({ theme }) => theme.colours.freezeButtonText};
  border: 1px solid ${({ theme }) => theme.colours.freezeButtonText};
  height: ${({ theme }) => theme.sizing.btnHeight};
  width: fit-content;
  padding 0 ${({ theme }) => theme.spacing.padding};
  max-width: ${({ theme }) => theme.sizing.btnWidth};
  z-index: 12;

  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  p {
    color: ${({ theme }) => theme.colours.freezeButtonText};
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  svg {
    color: ${({ theme }) => theme.colours.freezeButtonText}
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  ${({ hideFreeze, theme }) =>
    hideFreeze &&
    css`
      cursor: not-allowed;
      background-color: ${theme.colours.background};
      color: ${theme.colours.white};

      svg {
        color: ${theme.colours.white};
      }
    `}
`;

export const FreezeIcon = styled(Icon)`
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: auto;
  color: ${({ theme }) => theme.colours.freezeButtonText};
`;

export const StyledChevron = styled(Chevron)`
  width: ${({ theme }) => theme.spacing.padding};
`;

export const ReleasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-grow: 1;

  overflow: hidden;
  flex-wrap: wrap;
`;

export const StyledLinkIcon = styled(LinkIcon)`
  height: ${({ theme }) => theme.sizing.iconSmall};
  width: auto;
`;
