import React from "react";
import { StyledSelect } from "./reports.styles";

export const MultiSelect = ({
  options,
  values,
  placeholder,
  onChange,
  error,
  ...props
}) => {
  const mapOptions = values => {
    return (
      values &&
      values.map(label => {
        return {
          id: label,
          label: label,
          value: label,
        };
      })
    );
  };

  return (
    <StyledSelect
      data-test="select-component"
      value={mapOptions(values)}
      isMulti={true}
      onChange={values => {
        const mapped = values ? values.map(value => value.id) : values;
        onChange("labels", mapped);
      }}
      options={mapOptions(options)}
      placeholder={placeholder}
      isClearable={false}
      classNamePrefix={"Select"}
      error={error}
      {...props}
    />
  );
};
