import styled from "styled-components";
import { StyledLink } from "../../../components";

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
  flex-grow: 1;

  p {
    font-size: 12px;
    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colours.mainText};

  font-size: 12px;
  align-items: center;
  align-text: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const BreadcrumbLink = styled(StyledLink)`
  color: ${({ theme }) => theme.colours.mainText};
  text-decoration: none;
  font-size: 12px;
  align-items: center;
  align-text: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};

  background-color: white;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border: 1px solid ${({ theme }) => theme.colours.haloAccent};
`;

export const NodeDetailsContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.padding};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
