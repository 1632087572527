import { useEffect } from "react";
import { ENDPOINTS } from "../../static/constants/routeConfig";
import { useFetch } from "../hooks";
import { useReleases } from "./releases.provider";
import { RELEASE_STATUSES } from "../../static/constants/constants";

const DEFAULT = {
  STATUS: RELEASE_STATUSES.OPEN,
  SORT: "closeDate",
  PAGE: 1,
  PAGE_SIZE: 1000,
  DIRECTION: "ASC",
};

export const useGetAllOpenReleases = () => {
  const { status, get, body } = useFetch();
  const { setAllOpenReleases } = useReleases();

  const getAllOpenReleases = () => {
    get({
      ...ENDPOINTS.RELEASES,
      path: `${ENDPOINTS.RELEASES.path}?status=${DEFAULT.STATUS}&sort=${DEFAULT.SORT}:${DEFAULT.DIRECTION}&page=${DEFAULT.PAGE}&pageSize=${DEFAULT.PAGE_SIZE}`,
    });
  };

  useEffect(() => {
    if (status.isResolved && body) setAllOpenReleases(body);
  }, [status.isResolved, body]);

  return {
    getAllOpenReleases,
    loading: status.isLoading,
    getReleasesLoading: status.isLoading,
    getReleasesError: status.isRejected,
  };
};
