import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useModal } from "../../../../../context/modal/modal.provider";

export const useCloseSession = (sessionId, getRun) => {
  const { patch, status } = useFetch();
  const { closeModal } = useModal();
  const { createNotification, types } = useNotifications();

  const closeSession = () =>
    patch({
      ...ENDPOINTS.CLOSE_SESSION,
      path: ENDPOINTS.CLOSE_SESSION.path.replace(":sessionId", sessionId),
    });

  useEffect(() => {
    if (status.isResolved) {
      getRun();
      closeModal();
    }
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.closeSessionError"
      );
  }, [status.isResolved, status.isRejected]);

  return {
    closeSession,
  };
};
