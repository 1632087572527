import { useTranslation } from "react-i18next";
import {
  Data,
  DataContainer,
  GraphContainer,
  LevelContainer,
  OuterGraphContainer,
  StyledPieChart,
  ValueContainer,
  ValueLabel,
  ValueText,
} from "./accessibilityNodeCardGraph.styles";
import { WCAG_VERSION } from "../../../../../static/constants/constants";

export const AccessibilityNodeCardGraph = ({ run }) => {
  const { t } = useTranslation();

  const data = [];

  if (run.criteria.passed)
    data.push({
      name: "passed",
      value: run.criteria.passed,
      color: "url(#gradientPass)",
    });
  if (run.criteria.failed)
    data.push({
      name: "failed",
      value: run.criteria.failed,
      color: "url(#gradientFail)",
    });
  if (run.criteria.not_started)
    data.push({
      name: "not_started",
      value: run.criteria.not_started,
      color: "url(#gradientTotal)",
    });

  return (
    <OuterGraphContainer>
      <LevelContainer>
        <h2>
          {t("runView.testevolveaccessibility.wcagLevel", {
            level: WCAG_VERSION,
          })}
        </h2>
        <h1>{run.level}</h1>
      </LevelContainer>
      <GraphContainer large>
        <StyledPieChart
          rounded
          data={data}
          lineWidth={14}
          paddingAngle={12}
          animate
          large
        />
        <ValueContainer>
          <ValueText>
            {Math.round((run.criteria.passed / run.criteria.total) * 100)}%
          </ValueText>
          <ValueLabel>{t("runView.testevolveaccessibility.passed")}</ValueLabel>
        </ValueContainer>
      </GraphContainer>
      <DataContainer>
        <Data>
          <h1 data-test="te-accessibility-na-val">
            {run.criteria.not_applicable}
          </h1>
          <h2 data-test="te-accessibility-na-title">
            {t("runView.testevolveaccessibility.not_applicable")}
          </h2>
        </Data>
        <Data>
          <h1 data-test="te-accessibility-na-val">{run.criteria.wont_do}</h1>
          <h2 data-test="te-accessibility-na-title">
            {t("runView.testevolveaccessibility.wont_do")}
          </h2>
        </Data>
      </DataContainer>
    </OuterGraphContainer>
  );
};
