import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useReleases } from "../../../context/releases/releases.provider";
import { convertDateToSQL } from "../../../utils/dates/dateUtils";

export const useCloseRelease = closeModal => {
  const { patch, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const { modifyRelease } = useReleases();

  const closeRelease = (id, closeDate) =>
    patch(
      {
        ...ENDPOINTS.CLOSE_RELEASE,
        path: ENDPOINTS.CLOSE_RELEASE.path.replace(":releaseId", id),
      },
      { close_date: convertDateToSQL(closeDate) }
    );

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(types.SUCCESS, "releases.closeRelease.success");
      modifyRelease(body.id, body);
      closeModal();
    }
    if (status.isRejected && error && error.text)
      createNotification(types.ERROR, `releases.closeRelease.${error.text}`);
  }, [status.isResolved, error, status.isRejected, body]);

  return {
    closeRelease,
    isError: status.isRejected,
    isLoading: status.isLoading,
  };
};
