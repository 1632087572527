import { createContext, useContext, useEffect, useReducer } from "react";
import { ACTIONS } from "../../../static/constants/actions";
import { useHistoryWithParams } from "../../../context/hooks/historyWithParams/useHistoryWithParams";

const initialState = { releaseSharingToken: null };

const Context = createContext(initialState);
const useHook = () => useContext(Context);

export const sharingTokenReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_RELEASE_SHARING_TOKEN:
      return {
        ...state,
        releaseSharingToken: payload,
      };
    default:
      return state;
  }
};

const ReleaseSharingTokenProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sharingTokenReducer, initialState);

  return (
    <Context.Provider value={{ ...state, dispatch }}>
      {children}
    </Context.Provider>
  );
};

const useReleaseSharingToken = () => {
  const history = useHistoryWithParams();

  const { releaseSharingToken, dispatch } = useHook();

  const setReleaseSharingToken = token =>
    dispatch({ type: ACTIONS.SET_RELEASE_SHARING_TOKEN, payload: token.token });

  const clearReleaseSharingToken = () =>
    dispatch({ type: ACTIONS.SET_RELEASE_SHARING_TOKEN, payload: null });

  useEffect(() => {
    history.listen(() => clearReleaseSharingToken());
  }, [window.location.pathname]);

  return {
    releaseSharingToken,
    setReleaseSharingToken,
    clearReleaseSharingToken,
  };
};

export { useReleaseSharingToken, ReleaseSharingTokenProvider };
