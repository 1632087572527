import {
  GaugeContainer,
  Metric,
  MetricsSection,
  SectionContainer,
  ScoreSection,
  ViolationsSection,
  Violation,
  MetricsContainer,
  DescriptionSection,
  Anchor,
} from "../lighthouseSection.styles";
import { LighthouseScoreGauge } from "../../../../components/graph/graph";
import { Audit, ScoreKey } from "../accessibilitySection/acessibilitySection";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../context/theme/theme.provider";
import { CustomLink } from "../../../../components/Link/StyledLink";

const PERFORMANCE_SCORE_LINK =
  "https://developer.chrome.com/docs/lighthouse/performance/performance-scoring/?utm_source=lighthouse&utm_medium=cli";
const PERFORMANCE_CALC_LINK =
  "https://googlechrome.github.io/lighthouse/scorecalc/#FCP=2972&TTI=5779&SI=4801&TBT=750&LCP=3272&CLS=0.24&FMP=3501&device=desktop&version=9.6.7";

export const PerformanceSection = ({ data, graphData, scrollRef }) => {
  const { t } = useTranslation();
  const { isDark } = useTheme();

  return (
    <SectionContainer>
      <Anchor ref={scrollRef} />
      <ScoreSection>
        <GaugeContainer>
          <LighthouseScoreGauge
            large={true}
            data={graphData}
            passed={graphData.find(d => d.name === "Performance")?.value}
          />
        </GaugeContainer>
        <DescriptionSection>
          <span>
            {t("runReport.lighthouse_descriptions.performance_1")}
            <CustomLink accent external to={PERFORMANCE_SCORE_LINK}>
              {t("runReport.lighthouse_descriptions.performance_2")}
            </CustomLink>
            {t("runReport.lighthouse_descriptions.performance_3")}
          </span>
          <CustomLink accent external to={PERFORMANCE_CALC_LINK}>
            {t("runReport.lighthouse_descriptions.performance_4")}
          </CustomLink>
        </DescriptionSection>
        <ScoreKey />
      </ScoreSection>
      <MetricsSection>
        <h3>{t("runReport.metrics")}</h3>
        <MetricsContainer>
          <Metric isDark={isDark} score={data.first_contentful_paint.score}>
            <h3>{t("runReport.first_contentful_paint")}</h3>
            <h2>{data.first_contentful_paint.displayValue}</h2>
          </Metric>
          <Metric isDark={isDark} score={data.interactive.score}>
            <h3>{t("runReport.interactive")}</h3>
            <h2>{data.interactive.displayValue}</h2>
          </Metric>
          <Metric isDark={isDark} score={data.speed_index.score}>
            <h3>{t("runReport.speed_index")}</h3>
            <h2>{data.speed_index.displayValue}</h2>
          </Metric>
          <Metric isDark={isDark} score={data.total_blocking_time.score}>
            <h3>{t("runReport.total_blocking_time")}</h3>
            <h2>{data.total_blocking_time.displayValue}</h2>
          </Metric>
          <Metric isDark={isDark} score={data.largest_contentful_paint.score}>
            <h3>{t("runReport.largest_contentful_paint")}</h3>
            <h2>{data.largest_contentful_paint.displayValue}</h2>
          </Metric>
          <Metric isDark={isDark} score={data.cumulative_layout_shift.score}>
            <h3>{t("runReport.cumulative_layout_shift")}</h3>
            <h2>{data.cumulative_layout_shift.displayValue}</h2>
          </Metric>
        </MetricsContainer>
      </MetricsSection>
      <ViolationsSection>
        <Violation>
          {data?.opportunities && data?.opportunities?.length ? (
            <h3>{t("runReport.opportunities")}</h3>
          ) : null}
          {data?.opportunities?.map(op => (
            <Audit
              key={op.id}
              audit={op}
              value={
                op.details.overallSavingsMs
                  ? `${(op.details.overallSavingsMs / 1000).toFixed(2)} s`
                  : null
              }
            />
          ))}
        </Violation>
      </ViolationsSection>
      <ViolationsSection>
        <Violation>
          {data?.diagnostics && data?.diagnostics?.length ? (
            <h3>{t("runReport.diagnostics")}</h3>
          ) : null}
          {data?.diagnostics?.map(op => (
            <Audit key={op.id} audit={op} />
          ))}
        </Violation>
      </ViolationsSection>
    </SectionContainer>
  );
};
