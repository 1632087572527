import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useReleases } from "../../../context/releases/releases.provider";
import { convertDateToSQL } from "../../../utils/dates/dateUtils";

export const useDuplicateRelease = toggleModal => {
  const { post, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const { addRelease } = useReleases();

  const duplicateRelease = (payload, release) => {
    if (payload.open_date)
      payload.open_date = convertDateToSQL(payload.open_date);
    if (payload.close_date)
      payload.close_date = convertDateToSQL(payload.close_date);
    if (!payload.description?.length) payload.description = null;
    post(
      {
        ...ENDPOINTS.DUPLICATE_RELEASE,
        path: ENDPOINTS.DUPLICATE_RELEASE.path.replace(
          ":releaseId",
          release.id
        ),
      },
      payload
    );
  };

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(types.SUCCESS, "releases.duplicate.success");
      toggleModal();
      addRelease(body);
    }
    if (status.isRejected && error)
      createNotification(types.ERROR, `releases.duplicate.${error.code}`);
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    duplicateRelease,
    submit: duplicateRelease,
    createNodeLoading: status.isLoading,
    isError: status.isRejected,
    isLoading: status.isLoading,
  };
};
