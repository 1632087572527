import { ACTIONS } from "../../static/constants/actions";

export const runStatusReducer = (state, { type, payload }) => {
  switch (type) {
    case `${ACTIONS.SET_RUN_STATUS_LATEST}`: {
      return {
        isLatest: payload,
      };
    }
    default:
      return state;
  }
};
