import styled from "styled-components";
import { ReactComponent as Icon } from "../../../static/assets/icon-link.svg";

export const LeftWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  width: ${({ theme }) => (theme.isNotDesktop ? "100%" : "30%")};
  min-width: ${({ theme }) => (theme.isNotDesktop ? "100%" : "30%")};
  max-width: ${({ theme }) => (theme.isNotDesktop ? "100%" : "30%")};

  max-height: ${({ theme }) => theme.sizing.accessibilityViewSectionheight};
  min-height: ${({ theme }) => theme.sizing.accessibilityViewSectionheight};
`;

export const RunStatusContainer = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.paddingSM};

  display: flex;
  flex-direction: column;
`;

export const AccessibilityNavigation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  height: 100%;

  padding: ${({ theme }) => theme.spacing.padding};

  gap: ${({ theme }) => theme.spacing.padding};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colours.highlight};
    border: 4px solid transparent;
    border-radius: ${({ theme }) => theme.borders.radiusLG};
    background-clip: padding-box;
  }
`;

export const RightWidgetContiner = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  height: 100%;

  padding: ${({ theme }) => theme.spacing.padding};

  gap: ${({ theme }) => theme.spacing.padding};

  min-height: ${({ theme }) => theme.sizing.accessibilityViewSectionheight};
  max-height: ${({ theme }) => theme.sizing.accessibilityViewSectionheight};
  overflow-y: auto;

  flex-grow: 1;

  overflow-y: scroll;

  ::-webkit-scrollbar {
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colours.highlight};
    border: 4px solid transparent;
    border-radius: ${({ theme }) => theme.borders.radiusLG};
    background-clip: padding-box;
  }
`;

export const PrincipleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  color: ${({ theme }) => theme.colours.mainText};

  outline: ${({ theme, isSelected }) =>
    isSelected
      ? `1px solid ${theme.colours.haloAccent}`
      : theme.isDark
      ? `1px solid ${theme.colours.altForeground}`
      : "none"};

  line-height: ${({ theme }) => theme.spacing.paddingMD};

  padding: ${({ theme }) => theme.spacing.padding};

  h2 {
    font-size: ${({ theme }) => theme.fontSize.largeMiddler};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    align-content: center;
  }

  p {
    color: ${({ theme }) => theme.colours.fadedMainText};
    font-size: ${({ theme }) => theme.fontSize.smaller};
  }

  &:hover {
    cursor: pointer;
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const GuidelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  color: ${({ theme }) => theme.colours.mainText};

  outline: ${({ theme, isSelected }) =>
    isSelected
      ? `1px solid ${theme.colours.haloAccent}`
      : theme.isDark
      ? `1px solid ${theme.colours.altForeground}`
      : "none"};
  margin-left: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  padding: ${({ theme }) => theme.spacing.padding};

  h3 {
    line-height: ${({ theme }) => theme.fontSize.large};
    font-size: ${({ theme }) => theme.spacing.padding};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    align-content: center;
  }

  p {
    color: ${({ theme }) => theme.colours.fadedMainText};
    font-size: ${({ theme }) => theme.fontSize.smaller};
  }

  &:hover {
    cursor: pointer;
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }
`;

export const SuccessCriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  color: ${({ theme }) => theme.colours.mainText};

  outline: ${({ theme, isSelected }) =>
    isSelected
      ? `1px solid ${theme.colours.haloAccent}`
      : theme.isDark
      ? `1px solid ${theme.colours.altForeground}`
      : "none"};
  line-height: ${({ theme }) => theme.spacing.padding};

  margin-left: ${({ theme }) => theme.spacing.paddingLG};

  padding: ${({ theme }) => theme.spacing.padding};

  h3 {
    line-height: ${({ theme }) => theme.spacing.padding};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    align-content: center;
  }

  &:hover {
    cursor: pointer;
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  p {
    color: ${({ theme }) => theme.colours.fadedMainText};
    font-size: ${({ theme }) => theme.fontSize.smaller};
  }

  svg {
    width: ${({ theme }) => theme.sizing.iconSmall};
    min-width: ${({ theme }) => theme.sizing.iconSmall};

    height: ${({ theme }) => theme.sizing.iconSmall};
    min-height: ${({ theme }) => theme.sizing.iconSmall};
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;

  line-height: ${({ theme }) => theme.spacing.padding};
  align-items: center;

  gap: ${({ theme }) => theme.spacing.paddingSM};

  h3 {
    line-height: ${({ theme }) => theme.spacing.padding};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    align-content: center;
  }

  svg {
    width: ${({ theme }) => theme.sizing.iconSmall};
    min-width: ${({ theme }) => theme.sizing.iconSmall};

    height: ${({ theme }) => theme.sizing.iconSmall};
    min-height: ${({ theme }) => theme.sizing.iconSmall};
  }
`;

export const LinkIcon = styled(Icon)`
  cursor: pointer;
  height: ${({ theme }) => theme.sizing.iconSize};
  width: ${({ theme }) => theme.sizing.iconSize};
  color: ${({ theme }) => theme.colours.mainText};
`;

export const LinkIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.paddingXXSM};

  &:hover {
    outline: ${({ theme, noHover }) =>
      noHover ? "none" : `1px solid ${theme.colours.haloAccent}`};
    border-radius: ${({ theme }) => theme.borders.radius};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.colours.mainText};

  svg {
    color: ${({ theme }) => theme.colours.mainText};
  }
`;
