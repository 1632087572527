import { ACTIONS } from "../../static/constants/actions";
import { RELEASE_STATUSES } from "../../static/constants/constants";

export const releasesReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.ADD_RELEASE: {
      return {
        ...state,
        releases: [payload, ...state.releases],
        allOpenReleases: [payload, ...state.allOpenReleases],
      };
    }
    case ACTIONS.SET_RELEASES:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.SET_ALL_OPEN_RELEASES:
      return {
        ...state,
        allOpenReleases: payload.releases,
      };
    case ACTIONS.SET_RELEASE:
      return {
        ...state,
        release: payload,
      };
    case ACTIONS.MODIFY_RELEASE_LABELS: {
      const { release, labels } = payload;
      const currentRelease = state.release;

      const releases = state.releases;
      const index = releases.findIndex(r => r.id === release);

      if (!releases[index]) return state;

      releases[index].labels = labels;
      if (releases[index].id === currentRelease.id)
        currentRelease.labels = labels;

      const allOpenReleases = state.allOpenReleases;
      const i = allOpenReleases?.findIndex(r => r.id === release);
      allOpenReleases[i].labels = labels;
      if (allOpenReleases[i].id === currentRelease.id)
        allOpenReleases.labels = labels;

      return {
        ...state,
        releases: releases,
        release: currentRelease,
        allOpenReleases: allOpenReleases,
      };
    }
    case ACTIONS.APPEND_RELEASES:
      if (payload.page <= state.page) return { ...state, ...payload };
      return {
        ...state,
        releases: [...state.releases, ...payload.releases],
        page: payload.page,
        totalPages: payload.total_releases,
      };
    case ACTIONS.MODIFY_RELEASE: {
      const releases = state.releases;
      const i = releases.findIndex(obj => obj.id == payload.id);
      releases[i] = { ...releases[i], ...payload.release };

      const isSelectedRelease = state.release?.id === payload.id;

      let allOpenReleases = state.allOpenReleases;
      const index = allOpenReleases.findIndex(r => r.id === payload.id);

      if (index >= 0 && payload.release.status === RELEASE_STATUSES.CLOSED)
        allOpenReleases = allOpenReleases.filter(
          r => r.id.toString() !== payload.id.toString()
        );
      if (index >= 0 && payload.release.status === RELEASE_STATUSES.OPEN)
        allOpenReleases[index] = payload.release;
      if (index < 0 && payload.release.status === RELEASE_STATUSES.OPEN)
        allOpenReleases = [payload.release, ...allOpenReleases];

      return {
        ...state,
        releases: releases,
        allOpenReleases: allOpenReleases,
        release: isSelectedRelease
          ? { ...state.release, ...payload.release }
          : state.release,
      };
    }
    case ACTIONS.REMOVE_RELEASE:
      return {
        ...state,
        releases: state.releases.filter(
          r => r.id.toString() !== payload.toString()
        ),
        allOpenReleases: state.allOpenReleases.filter(
          r => r.id.toString() !== payload.toString()
        ),
      };
    case ACTIONS.CLEAR_RELEASES:
      return {
        ...state,
        releases: [],
      };
    default:
      return state;
  }
};
