import React from "react";
import {
  StyledOrgContainer,
  StyledHeader,
} from "./organisationContainer.styles";
import { useOrganisation } from "../../../context/organisation/organisation.provider";

export const OrganisationContainer = () => {
  const { organisationName } = useOrganisation();

  return (
    <StyledOrgContainer>
      <StyledHeader>{organisationName}</StyledHeader>
    </StyledOrgContainer>
  );
};
