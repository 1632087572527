import { useEffect } from "react";
import { useFetch } from "../../hooks";
import { createState } from "../../../utils/context";
import { AdminLogsReducer } from "./adminLogs.reducer";
import { ACTIONS } from "../../../static/constants/actions";
import { useNotifications } from "../../hooks/useNotifications/useNotifications";

const initialState = {
  adminLogs: [],
};

const [useHook, AdminLogsProvider] = createState(
  "AdminLogs",
  initialState,
  AdminLogsReducer
);

const useAdminLogs = () => {
  const { adminLogs, dispatch } = useHook();
  const { get, body, status, error } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved) setAdminLogs(body);
    if (status.isRejected)
      createNotification(types.ERROR, `errors.adminLogs.${error.code}`);
  }, [status.isResolved, status.isRejected, error]);

  const setAdminLogs = payload =>
    dispatch({ type: ACTIONS.GET_ADMIN_LOGS_SUCCESS, payload: payload });

  const getAdminLogs = () => get({ path: "/api/core-audit/list" });

  return {
    adminLogs,
    getAdminLogs,
  };
};

export { useAdminLogs, AdminLogsProvider };
