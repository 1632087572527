import React from "react";
import {
  Container,
  WidgetContainer,
  HorizontalWidgetContainer,
} from "./npmAuditRunView.styles";
import { RunTrends } from "./runTrends/runTrends";
import { RunHistory } from "./runHistory/runHistory";
import { RunDetails } from "../runDetails/runDetails";
import { LatestRunCard } from "./latestRunCard/latestRunCard";
import { Loading } from "../../../components";
import { useTranslation } from "react-i18next";

export const NPMAuditRunView = ({
  scrollRef,
  run,
  nodeDetails,
  runDetails,
  getRunDetails,
  isShared,
  setIsPolling,
  release,
}) => {
  const { t } = useTranslation();

  return run ? (
    <Container ref={scrollRef} isShared={isShared}>
      <WidgetContainer>
        {isShared ? (
          <h1>{t("runView.npmAudit")}</h1>
        ) : (
          <RunDetails
            runApproved={run.approved}
            runId={run.id}
            nodeDetails={nodeDetails}
            runDetails={runDetails}
            setIsPolling={setIsPolling}
            run={run}
            release={release}
          />
        )}
        <HorizontalWidgetContainer>
          <LatestRunCard run={run} runDetails={runDetails} />
          <RunTrends runDetails={runDetails} />
        </HorizontalWidgetContainer>
        {isShared ? null : (
          <RunHistory runDetails={runDetails} getRunDetails={getRunDetails} />
        )}
      </WidgetContainer>
    </Container>
  ) : (
    <Loading />
  );
};
