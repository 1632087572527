import { ACTIONS } from "../../../static/constants/actions";

export const PaymentIntentReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: payload,
      };
    case ACTIONS.SET_PAYMENT_INTENT_NULL:
      return {
        ...state,
        paymentIntent: {},
      };
    default:
      return state;
  }
};
