import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../context/hooks";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";

export const useUpdateCustomer = (closeModal, setUpdated) => {
  const { t } = useTranslation();
  const [payload, setPayload] = useState({});

  const { patch, status, error, body } = useFetch();
  const { createNotification, types } = useNotifications();

  const stripe = useStripe();

  const updateCustomer = payload => {
    setPayload(payload);
    patch(ENDPOINTS.CUSTOMER_ORGANISATIONS, payload);
  };

  const stripeConfirm = createdCustomer => {
    stripe
      .confirmCardPayment(createdCustomer.payment_confirmation_secret, {})
      .then(() => {
        updateCustomer({ ...payload, invoice_id: createdCustomer.invoice_id });
      });
  };

  useEffect(() => {
    if (status.isResolved && body?.requires_confirmation) stripeConfirm(body);
    if (status.isResolved && !body?.requires_confirmation) {
      createNotification(
        types.SUCCESS,
        t("success.updateCustomerOrganisation")
      );
      setUpdated();
      closeModal();
    }
    if (status.isRejected || error) {
      createNotification(types.ERROR, t("error"));
      closeModal();
    }
  }, [status.isResolved, status.isRejected, error, body]);

  return {
    updateCustomer,
  };
};
