import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  CATEGORY_PROVIDER_MAP,
  LATEST,
} from "../../../static/constants/constants";
import { routes } from "../../../router/routes";
import { useHistoryWithParams } from "../../../context/hooks/historyWithParams/useHistoryWithParams";
import { useModal } from "../../../context/modal/modal.provider";

export const useDeleteRun = run => {
  const { t } = useTranslation();
  const { nodeId } = useParams();
  const { deleteApi, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const history = useHistoryWithParams();
  const { openModal, MODALS, closeModal } = useModal();

  useEffect(() => {
    if (status.isResolved) {
      createNotification(types.SUCCESS, "success.deleteRun");
      closeModal();
      history.push(
        `${routes.RUN_VIEW.path}`
          .replace(":nodeId", nodeId)
          .replace(":runType", run.category)
          .replace(":label", encodeURI(encodeURIComponent(run.label)))
          .replace(":runId", LATEST)
      );
    }
    if (status.isRejected)
      createNotification(types.ERROR, t(`errors.deleteRun.${error.code}`));
  }, [status.isRejected, status.isResolved, body, error]);

  const deleteRun = () => {
    deleteApi({
      ...ENDPOINTS.RUN,
      path: ENDPOINTS.RUN.path
        .replace(":id", run.id)
        .replace(":provider", CATEGORY_PROVIDER_MAP[run.category]),
    });
  };

  const openDeleteRunModal = run =>
    openModal(MODALS.DELETE_INDIVIDUAL_RUN, { run });

  return {
    deleteRun,
    openDeleteRunModal,
  };
};
