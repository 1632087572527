import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useFetch } from "../../../context/hooks/fetch/useFetch";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

export const useNodeDetails = () => {
  const { nodeId } = useParams();
  const { get, status, body, error } = useFetch();
  const [nodeDetails, setNodeDetails] = useState(null);

  const getNodeDetails = () => {
    let url = ENDPOINTS.NODE_DETAILS.path.replace(":nodeId", nodeId);

    get({
      ...ENDPOINTS.NODE_DETAILS,
      path: url,
    });
  };

  useEffect(() => {
    if (status.isResolved && body) {
      setNodeDetails(body);
    }
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    getNodeDetails,
    nodeDetails,
  };
};
