import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";

export const useOpenSession = (sessionId, getRun) => {
  const { patch, status } = useFetch();
  const { createNotification, types } = useNotifications();

  const openSession = () =>
    patch({
      ...ENDPOINTS.OPEN_SESSION,
      path: ENDPOINTS.OPEN_SESSION.path.replace(":sessionId", sessionId),
    });

  useEffect(() => {
    if (status.isResolved) getRun();
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.openSessionError"
      );
  }, [status.isResolved, status.isRejected]);

  return {
    openSession,
  };
};
