import { ReleaseModal } from "../../../releases/common/releaseModal";
import { useEditRelease } from "../../../releases/editRelease/useEditRelease";

export const EditReleaseModal = ({ closeModal, release, ...props }) => {
  const releaseHook = useEditRelease(closeModal, release);

  return (
    <ReleaseModal
      {...props}
      initial={release}
      toggleModal={closeModal}
      edit={true}
      hook={releaseHook}
    />
  );
};
