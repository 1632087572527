import styled from "styled-components";
import { SvgIcon } from "../../../../../components/icon/icon";

export const StickerContainer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.sizing.stickerHeight};
  width: ${({ theme }) => theme.sizing.stickerWidth};
  margin: ${({ theme }) => theme.spacing.paddingXSM};

  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  background-color: ${({ theme }) => theme.colours.runPropertyBackground};

  border-radius: ${({ theme }) => theme.borders.radius};

  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StickerValue = styled.h1`
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const StickerLabel = styled.p`
  width: 60px;
  font-size: 12px;
  text-align: right;
  vertical-align: middle;
  padding: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const Chevron = styled(SvgIcon)`
  height: ${({ theme }) => theme.sizing.svgHeight};
  transition: ${({ theme }) => theme.transitionTime.fast};
  color: ${({ color }) => color};
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
`;

export const VulnerabilitiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
