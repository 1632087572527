import { memo } from "react";
import * as Modals from "../../components/modal/modals";
import { ScreenCover } from "../../components/modal/styles";
import { useModal } from "./modal.provider";

export const ModalComponentUnMemo = () => {
  const { data, modal, MODALS } = useModal();

  if (!modal) return null;

  const Modal = () => {
    switch (modal) {
      case MODALS.DELETE_NODE:
        return <Modals.DeleteNodeModal {...data} />;
      case MODALS.SESSION_EXPIRED:
        return <Modals.SessionExpiredModal {...data} />;
      case MODALS.CREATE_RELEASE:
        return <Modals.CreateReleaseModal {...data} />;
      case MODALS.DUPLICATE_RELEASE:
        return <Modals.DuplicateReleaseModal {...data} />;
      case MODALS.CLOSE_RELEASE:
        return <Modals.CloseReleaseModal {...data} />;
      case MODALS.EDIT_RELEASE:
        return <Modals.EditReleaseModal {...data} />;
      case MODALS.DELETE_RELEASE:
        return <Modals.DeleteReleaseModal {...data} />;
      case MODALS.ASSIGN_LABEL:
        return <Modals.AssignLabelModal {...data} />;
      case MODALS.ADD_LABEL_TO_RELEASE:
        return <Modals.AddLabelToReleaseModal {...data} />;
      case MODALS.RUN_APPROVE:
        return <Modals.RunApproveModal {...data} />;
      case MODALS.DELETE_INDIVIDUAL_RUN:
        return <Modals.DeleteIndividualRunModal {...data} />;
      case MODALS.DELETE_RUN_HISTORY:
        return <Modals.DeleteRunHistoryModal {...data} />;
      case MODALS.SHARE_RUN:
        return <Modals.SharedRunModal {...data} />;
      case MODALS.EXPORT_RUN:
        return <Modals.RunExportModal {...data} />;
      case MODALS.SHARE_RUN_VIEW:
        return <Modals.ShareRunViewModal {...data} />;
      case MODALS.JIRA_BUG:
        return <Modals.JiraBugModal {...data} />;
      case MODALS.FIRST_LOGIN:
        return <Modals.FirstLoginModal {...data} />;
      case MODALS.REPORT_PREVIEW:
        return <Modals.ReportModal {...data} />;
      case MODALS.API_KEY:
        return <Modals.ApiKeyModal {...data} />;
      case MODALS.UPDATE_LABEL:
        return <Modals.UpdateLabelModal {...data} />;
      case MODALS.SHARED_RELEASE_VIEW:
        return <Modals.ShareReleaseViewModal {...data} />;
      case MODALS.SHARED_RELEASE:
        return <Modals.SharedReleaseModal {...data} />;
      case MODALS.MOVE_RUN:
        return <Modals.MoveRunModal {...data} />;
      case MODALS.RENAME_PROJECT:
        return <Modals.RenameProjectModal {...data} />;
      case MODALS.DELETE_PROJECT:
        return <Modals.DeleteProjectModal {...data} />;
      case MODALS.CREATE_PROJECT:
        return <Modals.CreateProjectModal {...data} />;
      case MODALS.SELECT_COUNTRY:
        return <Modals.SelectCountryModal {...data} />;
      case MODALS.DELETE_PAYMENT:
        return <Modals.DeletePaymentModal {...data} />;
      case MODALS.EDIT_PAYMENT:
        return <Modals.ModifyPaymentModal {...data} />;
      case MODALS.CREATE_SUBSCRIPTION:
        return <Modals.CreateSubscriptionModal {...data} />;
      case MODALS.UPDATE_SUBSCRIPTION:
        return <Modals.UpdateSubscriptionModal {...data} />;
      case MODALS.CANCEL_SUBSCRIPTION:
        return <Modals.CancelSubscriptionModal {...data} />;
      case MODALS.REACTIVATE_SUBSCRIPTION:
        return <Modals.ReactivateSubscriptionModal {...data} />;
      case MODALS.DELETE_USER:
        return <Modals.DeleteUserModal {...data} />;
      case MODALS.PREVIEW_CREATE_CUSTOMER:
        return <Modals.PreviewCreateCustomerModal {...data} />;
      case MODALS.DELETE_CUSTOMER:
        return <Modals.DeleteCustomerModal {...data} />;
      case MODALS.DISASSOCIATE_CUSTOMER:
        return <Modals.DisassociateCustomerModal {...data} />;
      case MODALS.PREVIEW_UPDATE_CUSTOMER:
        return <Modals.PreviewUpdateCustomerModal {...data} />;
      case MODALS.ENABLE_PARTNER_SUBSCRIPTION:
        return <Modals.EnablePartnerSubscriptionModal {...data} />;
      case MODALS.CREATE_EXPLORATORY_RUN:
        return <Modals.CreateManualRunModal {...data} />;
      case MODALS.CREATE_ACCESSIBILITY_RUN:
        return <Modals.CreateAccessibilityRunModal {...data} />;
      case MODALS.EDIT_SESSION_SUMMARY:
        return <Modals.EditSessionSummaryModal {...data} />;
      case MODALS.DELETE_SESSION:
        return <Modals.DeleteSessionModal {...data} />;
      case MODALS.ENTRY_ATTACHMENTS:
        return <Modals.EntryAttachmentsModal {...data} />;
      case MODALS.INSERT_TABLE:
        return <Modals.InsertTableModal {...data} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Modal />
      <ScreenCover />
    </>
  );
};

export const ModalComponent = memo(ModalComponentUnMemo);
