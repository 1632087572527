import { useTranslation } from "react-i18next";
import {
  STATUSES,
  TEST_CATEGORIES,
} from "../../../../static/constants/constants";
import {
  LeftContainer,
  Spacer,
  StyledCautionIcon,
  StyledFailure,
  StyledScenarioContainer,
  StyledScenarioHeader,
  StyledStatus,
  StyledStepsContainer,
  StyledTagsContainer,
} from "../cypressRunReport.styles";
import { Bug } from "../../components/bug";
import { BugContainer } from "../../components/bug.styles";

export const TestCase = ({
  testCase,
  uuid,
  isDark,
  JiraBugButton,
  feature,
  isMobile,
  scenarioIndex,
  category,
}) => {
  const { t } = useTranslation();

  const bugs = testCase?.jira;

  const StatusLabel = () => {
    const status = testCase.failure ? STATUSES.FAILED : STATUSES.PASSED;
    return (
      <StyledStatus>
        {testCase.attempts && <StyledCautionIcon />}
        {status}
        {testCase.attempts && (
          <>
            {t("runReport.status.after")}
            {testCase.attempts}
            {t("runReport.status.attempts")}
          </>
        )}
      </StyledStatus>
    );
  };

  return (
    <StyledScenarioContainer
      key={uuid()}
      data-test={`${scenarioIndex}-${testCase.name}`}
      isMobile={isMobile}
      className="unique-feature-scenario"
    >
      <StyledTagsContainer isDark={isDark}>
        {testCase.tags &&
          testCase.tags.map(tag => (
            <p data-test="scenario-tag" key={uuid()}>
              {tag}
            </p>
          ))}
        <Spacer />
      </StyledTagsContainer>
      <StyledScenarioHeader
        status={testCase.failure ? STATUSES.FAILED : STATUSES.PASSED}
      >
        <LeftContainer>
          <StatusLabel />
          <h3 data-test={testCase.name}>{testCase.name}</h3>
        </LeftContainer>
        {!isMobile &&
          testCase.status !== STATUSES.PASSED &&
          category === TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL && (
            <JiraBugButton
              data={testCase}
              feature={feature}
              testCase={testCase}
              category={TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL}
            />
          )}
      </StyledScenarioHeader>
      {isMobile &&
        category === TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL &&
        testCase.status !== STATUSES.PASSED && (
          <JiraBugButton
            data={testCase}
            feature={feature}
            testCase={testCase}
            category={TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL}
          />
        )}
      {bugs?.bug_list?.length ? (
        <BugContainer data-test="bug-container">
          <h3>{t("runReport.bugTitle")}</h3>
          {bugs?.bug_list?.map(bug => (
            <Bug key={bug.issue_key} base_url={bugs.base_url} {...bug} />
          ))}
        </BugContainer>
      ) : null}
      <StyledStepsContainer>
        {testCase.failure && (
          <StyledFailure>
            <pre>
              <b>{testCase.failure.message}</b>
              <br />
              <br />
              {testCase.failure.content}
            </pre>
          </StyledFailure>
        )}
      </StyledStepsContainer>
    </StyledScenarioContainer>
  );
};
