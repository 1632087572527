import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: ${({ theme, isNotDesktop }) =>
    isNotDesktop ? theme.spacing.paddingLG : theme.spacing.padding};
  padding: ${({ theme, isNotDesktop }) =>
    isNotDesktop ? "0" : theme.spacing.paddingLG};
  max-width: 100%;
`;

export const ProjectCardContainer = styled.div`
  display: flex;
  padding: ${({ theme, isNotDesktop }) =>
    isNotDesktop ? "0" : theme.spacing.paddingLG};
  gap: ${({ theme }) => theme.spacing.padding};
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`;

export const FloatingContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing.paddingLG};
`;

export const NoProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.paddingLG};
  max-width: 100%;
`;

export const InsideContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.paddingLG};
  max-width: 100%;

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  width: fit-content;
  height: fit-content;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  p {
    white-space: pre-line;
    line-height: ${({ theme }) => theme.lineHeight.large};
    text-align: center;
  }
`;

export const LogoContainer = styled.div`
  min-width: ${({ theme }) => theme.sizing.btnWidthLG};
  min-height: ${({ theme }) => theme.sizing.iconSize};
`;

export const AdminUsersContainer = styled.div`
  p {
    white-space: pre-line;
    line-height: ${({ theme }) => theme.lineHeight.medium};
    text-align: center;
  }
`;
