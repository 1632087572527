import { useEffect } from "react";
import { PROVIDERS } from "../../../../static/constants/constants";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../context/hooks";

export const usePatchSuccessCriteria = getRunDetails => {
  const { status, patch, error, body } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && body) getRunDetails();
    if (status.isRejected && error)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.editRunError"
      );
  }, [status.isResolved, status.isRejected, body]);

  const patchSuccessCriteria = (id, refId, payload) =>
    patch(
      {
        ...ENDPOINTS.PATCH_SUCCESS_CRITERIA,
        path: ENDPOINTS.PATCH_SUCCESS_CRITERIA.path
          .replace(":provider", PROVIDERS.MANUAL)
          .replace(":runId", id)
          .replace(":refId", refId),
      },
      payload
    );

  const editNote = (id, refId, note) =>
    patchSuccessCriteria(id, refId, { assessment_notes: note });

  const editStatus = (id, refId, status) =>
    patchSuccessCriteria(id, refId, { status });

  return {
    editNote,
    editStatus,
  };
};
