import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCreateProject } from "./hooks/useCreateProject";
import { useProjectView } from "./hooks/useProjectView";
import { ProjectCard } from "./projectCard/projectCard";
import {
  Container,
  ProjectCardContainer,
  FloatingContainer,
  NoProjectsContainer,
  LogoContainer,
  InsideContainer,
  AdminUsersContainer,
} from "./projectView.styles";
import { Button } from "../../components/Button/Button";
import { usePermissions } from "../../context/hooks";
import { useApp } from "../../context/app/app.provider";
import { Loading, TestEvolveLogo } from "../../components";
import { useOrganisation } from "../../context/organisation/organisation.provider";
import { CustomLink } from "../../components/Link/StyledLink";
import { OrgSettingsLink } from "../settings/tabs/organisation/addUserToProject.styles";
import { routes } from "../../router/routes";
import { NO_UPGRADE_SUBS } from "../../static/constants/constants";
import { useProject } from "../../context/project/project.provider";
import { useHome } from "../home/context/home.context";

export const ProjectView = () => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();
  const {
    projects,
    getProjects,
    projectsPending,
    maxProjects,
    subscriptionId,
  } = useProjectView();
  const { hasPermission, permissions, isAdmin } = usePermissions();
  const { organisation } = useOrganisation();
  const { openCreateProjectModal, setNewProjectSuccess, newProjectSuccess } =
    useCreateProject();
  const { clearProject } = useProject();
  const { clearHomePage } = useHome();

  useEffect(() => {
    clearProject();
    clearHomePage();
  }, []);

  const usedMaxProjects = () => maxProjects <= projects.length;

  useEffect(() => {
    if (newProjectSuccess) {
      getProjects();
      setNewProjectSuccess(false);
    }
  }, [newProjectSuccess]);

  return projectsPending ? (
    <Loading />
  ) : isAdmin() || projects?.length ? (
    <Container isNotDesktop={isNotDesktop}>
      {projects?.length ? (
        <h1>{t("projectView.chooseProject")}</h1>
      ) : (
        <h1>{t("projectView.adminNoProjectsMessage")}</h1>
      )}
      <p>
        {t("projectView.availableProjects", {
          projects: projects.length,
          maxProjects: maxProjects,
        })}
      </p>
      {usedMaxProjects() && !NO_UPGRADE_SUBS.includes(subscriptionId) ? (
        <p>
          <OrgSettingsLink to={routes.ORGANISATION_BILLING.path}>
            {t("subscriptionInfo.Upgrade")}
          </OrgSettingsLink>{" "}
          {t("projectView.createMore")}
        </p>
      ) : null}
      <ProjectCardContainer isNotDesktop={isNotDesktop}>
        {projects ? (
          projects?.map(project => (
            <ProjectCard
              project={project}
              getProjects={getProjects}
              key={"projectCard-" + project.id}
            />
          ))
        ) : (
          <p>{t("projectView.errorMessage")}</p>
        )}
      </ProjectCardContainer>
      {hasPermission(permissions.CREATE_PROJECT) && !usedMaxProjects() ? (
        <FloatingContainer>
          <Button onClick={openCreateProjectModal}>
            {t("projectView.createProject.btnText")}
          </Button>
        </FloatingContainer>
      ) : null}
    </Container>
  ) : (
    <NoProjectsContainer>
      <InsideContainer>
        <p>{t("projectView.noProjects")}</p>
        <AdminUsersContainer>
          {organisation?.admin_users?.map(usr => (
            <p key={`p-${usr}`}>
              <CustomLink
                key={usr}
                accent
                external
                nounderline
                to={`mailto:${usr}`}
              >
                {usr}
              </CustomLink>
            </p>
          ))}
        </AdminUsersContainer>
        <LogoContainer>
          <TestEvolveLogo />
        </LogoContainer>
      </InsideContainer>
    </NoProjectsContainer>
  );
};
