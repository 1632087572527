import styled, { css } from "styled-components";
import { ReactComponent as CopyIcon } from "../../../../static/assets/icon-copy.svg";
import { ReactComponent as CopyTrueIcon } from "../../../../static/assets/icon-copy-true.svg";

export const MetaDataEntry = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.padding};
  width: 100%;

  ${({ link }) =>
    link &&
    css`
      overflow: hidden;
    `}
  ${({ link }) =>
    link &&
    css`
      max-width: 100%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  outline: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.fadedMainText}` : null};
`;

export const NoMetaData = styled.p`
  text-align: center;
`;

export const MetaDataTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colours.bulletColour};
`;

export const MetaDataValue = styled.div`
  display: flex;
`;

export const MetaDataP = styled.p`
  font-size: ${({ theme }) => theme.fontSize.mediumLarge};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  flex-grow: 1;

  ${({ link }) =>
    link &&
    css`
      max-width: 100%;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const MetaDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StyledCopyIcon = styled(CopyIcon)`
  width: ${({ theme }) => theme.sizing.svgHeightXSM};
  height: ${({ theme }) => theme.sizing.svgHeightXSM};
`;

export const CopyButton = styled.button`
  cursor: pointer;
  height: ${({ theme }) => theme.sizing.svgHeight};
  width: ${({ theme }) => theme.sizing.svgHeight};
  min-height: ${({ theme }) => theme.sizing.svgHeight};
  min-width: ${({ theme }) => theme.sizing.svgHeight};
  outline: none;
  border-radius: ${({ theme }) => theme.spacing.paddingXSM};

  border: none;
  box-shadow: ${({ theme }) => theme.decoration.cardShadowSM};

  background-color: ${({ theme }) => theme.colours.foreground};

  border: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.fadedMainText}` : null};

  &: hover {
    border: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colours.mainText};
`;

export const StyledCopyTrueIcon = styled(CopyTrueIcon)`
  height: ${({ theme }) => theme.sizing.svgHeightXSM};
  width: ${({ theme }) => theme.sizing.svgHeightXSM};
  min-height: ${({ theme }) => theme.sizing.svgHeightXSM};
  min-width: ${({ theme }) => theme.sizing.svgHeightXSM};

  path {
    fill: none;
    stroke: ${({ theme, accent }) =>
      accent ? theme.colours.haloAccent : theme.colours.mainText};
    stroke-width: 3;
    stroke-dasharray: 23;
    stroke-dashoffset: 23;
    animation: draw 0.3s linear forwards;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const FakeButton = styled.div`
  height: ${({ theme }) => theme.sizing.btnHeight};
  width: ${({ theme }) => theme.sizing.btnWidth};
  border-radius: ${({ theme }) => theme.borders.radius};

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.haloAccent};
  color: ${({ theme }) => theme.colours.white};
  text-decoration: none;
  gap: ${({ theme }) => theme.spacing.paddingXSM};

  p {
    color: ${({ theme }) => theme.colours.white};
    text-decoration: none;
  }

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
