import {
  StyledLabel,
  UserNameContainer,
  CheckboxContainer,
  StyledMainContainer,
  CheckboxesContainer,
  DeleteButtonContainer,
  ResendInviteButton,
} from "../usersList.styles";
import { useTranslation } from "react-i18next";
import { ProjectsPicker } from "../projectsPicker";
import { useUnlockUser } from "../hooks/useUnlockUser";
import { usePermissions } from "../../../../../context/hooks";
import { useApp } from "../../../../../context/app/app.provider";
import { Checkbox } from "../../../../../components/checkbox/checkbox";
import { useProject } from "../../../../../context/project/project.provider";
import { useAdminUsers } from "../../../../../context/organisationUsers/hooks/adminUsers";
import { useActiveUsers } from "../../../../../context/organisationUsers/hooks/activeUsers";
import { useUserProjects } from "../../../../../context/organisationUsers/hooks/userProjects";
import { usePartnerAdmins } from "../../../../../context/organisationUsers/hooks/partnerAdmins";
import { useTheme } from "../../../../../context/theme/theme.provider";
import { Button } from "../../../../../components";
import { useUser } from "../../../../../context/user/user.provider";
import { useResendUserInvite } from "../hooks/resendUserInvite";
import { useModal } from "../../../../../context/modal/modal.provider";

export const UserRow = ({ key, userRowUser, editable }) => {
  const { isDark } = useTheme();
  const { t } = useTranslation();
  const { projects } = useProject();
  const { isNotDesktop } = useApp();
  const { unlockUser } = useUnlockUser();
  const { resendInvite } = useResendUserInvite();
  const { isPartnerAdmin, isPartner } = usePermissions();
  const { isUserAdmin, toggleUserAdmin } = useAdminUsers();
  const { isUserActive, toggleUserActive } = useActiveUsers();
  const { findUsersProjects, toggleUserProject } = useUserProjects();
  const { isUserPartnerAdmin, togglePartnerUser } = usePartnerAdmins();
  const { user } = useUser();
  const { openModal, MODALS } = useModal();

  const openDeleteUserModal = () =>
    openModal(MODALS.DELETE_USER, { user: userRowUser });

  const isCurrentUser = () =>
    userRowUser.id === user.id && userRowUser.username === user.username;

  const deleteButtonActive = () => {
    if (isCurrentUser()) return false;
    if (isPartner()) return true;
    else {
      if (isPartner(userRowUser)) return false;
    }
    return true;
  };

  return (
    <StyledMainContainer key={key} isNotDesktop={isNotDesktop}>
      <UserNameContainer isNotDesktop={isNotDesktop} isDark={isDark}>
        <div>{userRowUser.username}</div>
        {userRowUser.invitation_pending && (
          <div>
            <p>{t("messaging.activation_pending")}</p>
            <ResendInviteButton
              data-test="resend-invite"
              onClick={() => resendInvite(userRowUser.username)}
            >
              {t("messaging.resend_invite")}
            </ResendInviteButton>
          </div>
        )}
        {userRowUser.locked && (
          <div>
            <p>{t("messaging.account_locked")}</p>
            <ResendInviteButton
              data-test="unlock-account"
              onClick={() => unlockUser(userRowUser.id)}
            >
              {t("messaging.unlock_account")}
            </ResendInviteButton>
          </div>
        )}
      </UserNameContainer>
      <CheckboxesContainer
        showPartner={isUserPartnerAdmin(userRowUser)}
        isNotDesktop={isNotDesktop}
      >
        <CheckboxContainer
          showPartner={isUserPartnerAdmin(userRowUser)}
          isNotDesktop={isNotDesktop}
        >
          <Checkbox
            checked={isUserActive(userRowUser)}
            data-test={`active-checkbox-${key}`}
            onChange={() => (editable ? toggleUserActive(userRowUser) : null)}
            dark={isDark}
            key={`active-checkbox-${key}`}
            aria-labelledby="active-label"
          />
          {isNotDesktop ? (
            <StyledLabel>
              {t("settings.organisation.columnTitles.active")}
            </StyledLabel>
          ) : null}
        </CheckboxContainer>
        <CheckboxContainer
          showPartner={isUserPartnerAdmin(userRowUser)}
          isNotDesktop={isNotDesktop}
        >
          <Checkbox
            checked={isUserAdmin(userRowUser)}
            onChange={() => (editable ? toggleUserAdmin(userRowUser) : null)}
            key={`admin-checkbox-${key}`}
            data-test={`admin-checkbox-${key}`}
            disabled={!isUserActive(userRowUser)}
            dark={isDark}
            aria-labelledby="admin-label"
          />
          {isNotDesktop ? (
            <StyledLabel>
              {t("settings.organisation.columnTitles.admin")}
            </StyledLabel>
          ) : null}
        </CheckboxContainer>
        {isPartnerAdmin() && (
          <CheckboxContainer
            showPartner={isUserPartnerAdmin(userRowUser)}
            isNotDesktop={isNotDesktop}
          >
            <Checkbox
              checked={isUserPartnerAdmin(userRowUser)}
              onChange={() =>
                editable ? togglePartnerUser(userRowUser) : null
              }
              key={`partner-checkbox-${key}`}
              data-test={`partner-checkbox-${key}`}
              dark={isDark}
              aria-labelledby="partner-label"
            />
            {isNotDesktop ? (
              <StyledLabel>
                {t("settings.organisation.columnTitles.partner")}
              </StyledLabel>
            ) : null}
          </CheckboxContainer>
        )}
      </CheckboxesContainer>

      <ProjectsPicker
        options={projects.map(prj => ({
          label: prj.name,
          value: prj,
        }))}
        handleSelect={toggleUserProject}
        selected={findUsersProjects(userRowUser)?.map(prj => ({
          label: prj.name,
          value: prj,
        }))}
        userId={userRowUser.id}
        disabled={!isUserActive(userRowUser) && !isUserAdmin(userRowUser)}
      />
      <DeleteButtonContainer>
        {deleteButtonActive() ? (
          <Button onClick={openDeleteUserModal}>{t("btnDelete")}</Button>
        ) : null}
      </DeleteButtonContainer>
    </StyledMainContainer>
  );
};
