import { useEffect } from "react";
import { useCountry } from "./paymentDetails/hooks/useCountry";
import { PaymentDetails } from "./paymentDetails/paymentDetails";
import { Container, PartnerMessageContainer } from "./billingTab.styles";
import { usePaymentDetails } from "./paymentDetails/hooks/usePaymentDetails";
import { useBillingDetails } from "./billingAddress/hooks/useBillingDetails";
import { BillingDetailsSection } from "./billingAddress/billingAddressDetails";
import { useBillingInvoices } from "./billingInvoices/hooks/useBillingInvoices";
import { SubscriptionDetails } from "./subscriptionDetails/subscriptionDetails";
import { BillingInvoiceSection } from "./billingInvoices/billingInvoicesSection";
import { useUpdate } from "../../../../context/billingUpdate/billingUpdate.provider";
import { useOrganisation } from "../../../../context/organisation/organisation.provider";
import { useSubscriptionDetails } from "./subscriptionDetails/hooks/useSubscriptionDetails";
import { useTranslation } from "react-i18next";
import { roles } from "../../../../static/constants/constants";
import { useOrganisationUsers } from "../../../../context/organisationUsers/organisationUsers.provider";
import { Loading } from "../../../../components";

export const BillingTab = () => {
  const { t } = useTranslation();
  const { update, setNoBillingUpdate, setBillingUpdate } = useUpdate();
  const { organisationUsers, getOrganisationUsers } = useOrganisationUsers();
  const { organisation } = useOrganisation();
  const { getPaymentDetails, paymentDetails } = usePaymentDetails();
  const billing = useBillingDetails(setBillingUpdate);
  const useSubscription = useSubscriptionDetails(setBillingUpdate);
  const {
    getSubscriptionDetails,
    subscriptionDetailsPending,
    subscriptionDetails,
  } = useSubscription;

  const { invoices, getBillingInvoices, billingInvoicesPending } =
    useBillingInvoices(setBillingUpdate);

  const { country, saveCountry } = useCountry();

  const getDetails = () => {
    getPaymentDetails();
    getBillingInvoices();
    billing.getBillingDetails();
    getSubscriptionDetails();
  };

  useEffect(() => {
    getOrganisationUsers();
    if (!organisation.partner_name) getDetails();
  }, []);

  useEffect(() => {
    if (billing?.billingDetails?.address?.country)
      saveCountry({ value: billing.billingDetails.address.country });
  }, [billing?.billingDetails]);

  useEffect(() => {
    if (update) {
      getPaymentDetails();
      getBillingInvoices();
      billing.getBillingDetails();
      getSubscriptionDetails();
      setNoBillingUpdate();
    }
  }, [update]);

  const partnerAdminEmail = organisationUsers?.find(user =>
    user.global_roles.some(role => role.key === roles.PARTNER_ADMIN)
  )?.username;

  return (
    <Container>
      {organisation.partner_name ? (
        <PartnerMessageContainer>
          <p>
            {t("partner.message.managedBilling", {
              partnerName: organisation.partner_name,
              partnerAdminEmail: partnerAdminEmail,
            })}
          </p>
        </PartnerMessageContainer>
      ) : billingInvoicesPending ||
        billing.billingDetailsPending ||
        subscriptionDetailsPending ? (
        <Loading />
      ) : (
        <>
          <SubscriptionDetails
            isProfile={false}
            country={country}
            paymentDetails={paymentDetails}
            useSubscription={useSubscription}
          />
          <PaymentDetails
            paymentDetails={paymentDetails}
            getPaymentDetails={getPaymentDetails}
            subscriptionDetails={subscriptionDetails}
          />
          <BillingDetailsSection billing={billing} />
          <BillingInvoiceSection
            invoices={invoices}
            getBillingInvoices={getBillingInvoices}
          />
        </>
      )}
    </Container>
  );
};
