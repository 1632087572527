import { useParams } from "react-router";
import { useRunDetails } from "./useRunDetails";
import { useNodeDetails } from "./useNodeDetails";
import { useEffect, useRef, useState } from "react";
import { useFetch } from "../../../context/hooks/fetch/useFetch";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import {
  CATEGORY_PROVIDER_MAP,
  LATEST,
} from "../../../static/constants/constants";

export const useRunView = () => {
  const ref = useRef(null);
  const [run, setRun] = useState(null);
  const { runType, runId } = useParams();
  const { runDetails, getRunDetails, runDetailsError } = useRunDetails();
  const { get, status, body, origin, error } = useFetch();
  const { nodeDetails, getNodeDetails } = useNodeDetails();

  const getRun = () =>
    get({
      ...ENDPOINTS.RUN,
      path: ENDPOINTS.RUN.path
        .replace(":id", runId)
        .replace(":provider", CATEGORY_PROVIDER_MAP[runType]),
    });

  useEffect(() => {
    if (status.isResolved && body) setRun(body);
  }, [status.isResolved, status.isRejected, body, error, origin]);

  return {
    run,
    ref,
    runId,
    error,
    getRun: runId === LATEST ? getRunDetails : getRun,
    runType,
    runDetails,
    nodeDetails,
    getRunDetails,
    getNodeDetails,
    isMostRecent:
      runId === LATEST ||
      runDetails?.latest_run?.id?.toString() === runId?.toString(),
    isLatest: runId === LATEST,
    runHistory: runDetails?.run_history,
    errorCode: error?.code || runDetailsError?.code,
  };
};
