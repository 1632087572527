import styled from "styled-components";
import { readableColor } from "polished";
import { Link } from "react-router-dom";

export const GhostContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.sizing.navItemHeight};
  margin-top: -${({ theme }) => theme.spacing.padding};
`;

export const Container = styled.div`
  display: flex;
  z-index: 98;
  background-color: ${({ theme }) => theme.colours.dropdownBG};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  width: 100%;
  margin-top: -${({ theme }) => theme.spacing.padding};
  height: ${({ theme }) => theme.sizing.navItemHeight};
`;

export const Row = styled(Link)`
  padding: ${({ theme }) => theme.spacing.padding};
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  color: ${({ theme }) => readableColor(theme.colours.dropdownBG)};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  text-align: center;
  &:hover {
    background-color: ${({ theme }) => theme.colours.highlight};
  }
`;
