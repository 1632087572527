import { useEffect, useRef, useState } from "react";
import { useTheme } from "../../../context/theme/theme.provider";
import { LighthouseGraphs } from "../../runView/testEvolveLighthouse/latestRunCard/violationsSection/violationsSection";
import {
  CheckContainer,
  CheckUrl,
  Label,
  LabelWrapper,
  Section,
  TableContainer,
} from "../testevolveAccessibility/axeSection.styles";
import { AccessbilitySection } from "./accessibilitySection/acessibilitySection";
import { BestPracticesSection } from "./bestPracticesSection/bestPracticesSection";
import { GraphContainer } from "./lighthouseSection.styles";
import { PerformanceSection } from "./perfomanceSection/performanceSection";
import { SeoSection } from "./seoSection/seoSection";
import { useLighthouseData } from "./useLighthouseData";
import { useJiraConfig } from "../../../context/jiraConfig/jiraConfig.provider";
import { useLocation } from "react-router-dom";
import { TEST_CATEGORIES } from "../../../static/constants/constants";

export const LighthouseSection = ({
  check,
  JiraBugButton,
  stepRef,
  scenario,
  feature,
}) => {
  const { graphData } = useLighthouseData(check);
  const [sectionIsOpen, setSectionIsOpen] = useState(false);
  const { isDark } = useTheme();
  const { isJiraConfigured } = useJiraConfig();

  const performance = useRef(null);
  const accessibility = useRef(null);
  const bestPractices = useRef(null);
  const seo = useRef(null);

  const refs = {
    performance,
    accessibility,
    bestPractices,
    seo,
  };

  const [selectedSection, setSelectedSection] = useState("performance");
  const { hash } = useLocation();
  const url = `#${stepRef}`;

  useEffect(() => {
    if (url === hash) setSectionIsOpen(true);
  }, []);

  useEffect(() => {
    refs[selectedSection]?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [sectionIsOpen]);

  const ScrollToSection = (e, section) => {
    e.preventDefault();
    setSectionIsOpen(false);
    setSelectedSection(section);

    if (sectionIsOpen) {
      refs[section]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <Section dark={isDark}>
        <CheckContainer
          key={`${check.label}-${check.url}`}
          onClick={() => setSectionIsOpen(open => !open)}
        >
          <LabelWrapper>
            <Label>{check.label}</Label>
          </LabelWrapper>
          <CheckUrl>{check.url}</CheckUrl>
          <GraphContainer>
            <LighthouseGraphs
              data={graphData}
              report={true}
              func={ScrollToSection}
            />
          </GraphContainer>
          {isJiraConfigured && (
            <JiraBugButton
              category={TEST_CATEGORIES.TEST_EVOLVE_AUDIT_LIGHTHOUSE}
              data={check}
              url={url}
              scenario={scenario}
              feature={feature}
            />
          )}
        </CheckContainer>
      </Section>
      {sectionIsOpen ? (
        <TableContainer>
          <PerformanceSection
            data={check.performance}
            graphData={graphData.performance}
            scrollRef={refs.performance}
          />
          <AccessbilitySection
            data={check.accessibility}
            graphData={graphData.accessibility}
            scrollRef={refs.accessibility}
          />
          <BestPracticesSection
            data={check.best_practices}
            graphData={graphData.bestPractices}
            scrollRef={refs.bestPractices}
          />
          <SeoSection
            data={check.seo}
            graphData={graphData.seo}
            scrollRef={refs.seo}
          />
        </TableContainer>
      ) : null}
    </>
  );
};

export const LightHouseSections = ({
  step,
  stepRef,
  lighthouse_checks,
  JiraBugButton,
  scenario,
  feature,
}) =>
  lighthouse_checks.map((check, index) => (
    <LighthouseSection
      key={check.label}
      step={step}
      index={index}
      check={check}
      stepRef={`${stepRef}-${index}`}
      JiraBugButton={JiraBugButton}
      feature={feature}
      scenario={scenario}
    />
  ));
