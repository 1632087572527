import styled from "styled-components";
import { ReactComponent as Icon } from "../../static/assets/icon-open-menu.svg";

export const OpenMenuIcon = styled(Icon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  z-index: 90;
  color: ${({ theme }) => theme.colours.mainText};

  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.colours.background : theme.colours.foreground};

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;
