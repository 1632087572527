import styled, { css } from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;

  height: 100%;
  width: ${({ paneOpen }) => (paneOpen ? "320px" : "0")};
  min-width: ${({ paneOpen }) => (paneOpen ? "320px" : "0")};

  transition: width 200ms ease;

  overflow-y: auto;

  z-index: 99;

  padding: ${({ paneOpen }) => (paneOpen ? "0 16px" : "0")};

  box-shadow: ${({ theme }) => theme.decoration.navShadow};

  background-color: ${({ theme }) => theme.colours.navBackground};

  ${({ isNotDesktop }) =>
    isNotDesktop &&
    css`
      height: ${({ paneOpen }) => (paneOpen ? "100%" : "0")};

      transition: height 200ms ease;

      width: ${({ paneOpen }) => (paneOpen ? "100%" : "0")};
    `}

  &:hover {
    ::-webkit-scrollbar {
      opacity: 0.8;
      transition: 0.3s;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colours.foreground};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colours.highlight};
    }
  }

  ::-webkit-scrollbar {
    background: transparent;
    width: ${({ theme }) => theme.sizing.scrollBarWidth};
    height: ${({ theme }) => theme.sizing.scrollBarWidth};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: ${({ theme }) => theme.borders.radius};
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  margin: ${({ theme }) => theme.spacing.padding} 0;
  box-sizing: content-box;
  min-height: ${({ theme }) => theme.sizing.projectDetailsHeight};
  background-color: ${({ theme, open }) =>
    open ? theme.colours.background : theme.colours.foreground};

  &:hover {
    background-color: ${({ theme, isNotDesktop }) =>
      !isNotDesktop && theme.colours.highlight};
  }
`;

export const ScreenCover = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 97;
`;

export const FakeButton = styled.div`
  height: ${({ theme }) => theme.sizing.btnHeight};
  width: ${({ theme }) => theme.sizing.btnWidth};
  border-radius: ${({ theme }) => theme.borders.radius};

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.haloAccent};

  p {
    color: ${({ theme }) => theme.colours.white};
    text-decoration: none;
  }

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colours.mainText};
  text-decoration: none;

  &:active {
    div {
      background-color: ${({ theme }) => theme.colours.secondaryButton};
    }
  }
`;

export const CollapseButton = styled.button`
  margin-right: ${({ theme }) => theme.collapseButton.marginRight};
  margin-left: -${({ theme }) => theme.spacing.padding};

  height: ${({ theme }) => theme.collapseButton.height};
  width: ${({ theme }) => theme.collapseButton.width};

  color: ${({ theme }) => theme.colours.mainText};
  background-color: ${({ theme }) => theme.colours.foreground};
  z-index: 99;
  border: 1px solid ${({ theme }) => theme.colours.highlight};

  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.radius};

  box-shadow: ${({ theme }) => theme.decoration.collapseBtnShadow};
  margin-top: ${({ theme }) => theme.collapseButton.spacingTop};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  svg {
    transform: ${({ right }) => (right ? "rotate(90deg)" : "rotate(-90deg)")};
    opacity: 0.6;
    margin-top: ${({ theme }) => theme.spacing.paddingXSM};
    height: ${({ theme }) => theme.sizing.iconSize};
    margin-left: ${({ right, theme }) =>
      right
        ? theme.collapseButton.marginOpen
        : theme.collapseButton.marginClosed};
  }
`;

export const OverallContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  ${({ isNotDesktop }) =>
    isNotDesktop &&
    css`
      height: ${({ paneOpen }) => (paneOpen ? "100%" : "0")};

      transition: height 200ms ease;

      width: ${({ paneOpen }) => (paneOpen ? "100%" : "0")};
    `}
`;

export const FakeContainer = styled.div`
  height: 100%;
  width: ${({ theme }) => theme.sizing.iconSize};
  box-shadow: ${({ theme }) => theme.decoration.navShadow};
  background-color: ${({ theme }) => theme.colours.navBackground};
`;

export const NavContainer = styled.div`
  margin-top: -${({ theme }) => theme.sizing.projectDropdownMargin};
`;

export const DocumentationContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border-top: 1px solid ${({ theme }) => theme.colours.black};
  cursor: pointer;
  a {
    text-decoration: none;
  }
`;

export const InnerDocumentationContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
  color: ${({ active, theme }) =>
    active ? theme.colours.mainText : theme.colours.fadedMainText};

  &:hover {
    background-color: ${({ theme, active }) =>
      !active && theme.colours.highlight};
    border-radius: ${({ theme }) => theme.borders.radiusMD};
    border-top: none;

    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;
