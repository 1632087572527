import { Button } from "../../../../components";
import { StyledPlusIcon } from "../../../releasesView/releasesView.styles";
import {
  convertDateToTs,
  mediumDateTime,
  timeAgo,
} from "../../../../utils/dates/dateUtils";
import {
  DetailKey,
  DetailValue,
  EntriesContainer,
  HorizontalRunDetail,
  RunDetail,
  SectionContainer,
  SessionDetailRow,
  SessionRow,
  SessionsContainer,
  SessionsHeader,
  StopwatchContainer,
  VerticalRunDetail,
} from "../testevolveExploratoryRunView.styles";
import { ProfileIcon } from "../../../../components/header/header.styles.js";
import { ReactComponent as BugIcon } from "../../../../static/assets/icon-bug.svg";
import { ReactComponent as MapIcon } from "../../../../static/assets/icon-map.svg";
import { ReactComponent as PencilIcon } from "../../../../static/assets/icon-pencil.svg";
import { ReactComponent as TickIcon } from "../../../../static/assets/icon-pass.svg";
import { useTranslation } from "react-i18next";
import { RUN_STATUSES } from "../../../../static/constants/constants.js";
import { useCreateSession } from "../hooks/session/useCreateSession.js";
import { useHistory, useLocation } from "react-router-dom";
import { StopwatchIcon } from "./stopwatchIcon.js";

const SESSION = "session=";

export const Sessions = ({
  sessions,
  isSelected,
  status,
  runId,
  getRun,
  getRunDetails,
}) => {
  const { t } = useTranslation();
  const { createSession } = useCreateSession(runId, getRun, getRunDetails);
  const history = useHistory();
  const location = useLocation();
  const isIDInURL = location.search?.includes(SESSION);

  const setSession = sessionToSet => {
    const search = isIDInURL
      ? location.search?.replace(
          `${SESSION}${isSelected}`,
          `${SESSION}${sessionToSet}`
        )
      : `${location.search}?${SESSION}${sessionToSet}`;

    history.push({
      pathname: location.pathname,
      search: search,
    });
  };

  return (
    <SectionContainer>
      <SessionsHeader>
        <h1>{t("runView.testevolveexploratory.sessions.sessions")}</h1>
        {status.toLowerCase() === RUN_STATUSES.OPEN ? (
          <Button onClick={() => createSession()}>
            <StyledPlusIcon />{" "}
            {t("runView.testevolveexploratory.sessions.newSession")}
          </Button>
        ) : null}
      </SessionsHeader>
      <SessionsContainer>
        {sessions?.length ? (
          <>
            {sessions
              .sort(
                (a, b) =>
                  convertDateToTs(b.start_time) - convertDateToTs(a.start_time)
              )
              .map(session => {
                const isClosed =
                  session.status?.toLowerCase() === RUN_STATUSES.CLOSED;

                return (
                  <SessionRow
                    key={`session-${session.id}`}
                    isSelected={isSelected === session.id}
                    onClick={() => setSession(session.id)}
                    closed={
                      session.status?.toLowerCase() === RUN_STATUSES.CLOSED
                    }
                  >
                    <SessionDetailRow profile>
                      <HorizontalRunDetail profile>
                        <ProfileIcon />
                        <VerticalRunDetail>
                          <DetailKey>
                            {t("runView.testevolveexploratory.sessions.owner")}
                          </DetailKey>
                          <DetailValue>{session.owner}</DetailValue>
                        </VerticalRunDetail>
                      </HorizontalRunDetail>
                      <EntriesContainer>
                        <StopwatchContainer>
                          {isClosed ? <TickIcon /> : <StopwatchIcon />}
                        </StopwatchContainer>
                      </EntriesContainer>
                    </SessionDetailRow>
                    <SessionDetailRow>
                      <HorizontalRunDetail>
                        <BugIcon />
                        <VerticalRunDetail>
                          <DetailKey>
                            {t("runView.testevolveexploratory.sessions.bugs")}
                          </DetailKey>
                          <DetailValue>{session.total_bugs || 0}</DetailValue>
                        </VerticalRunDetail>
                      </HorizontalRunDetail>
                      <HorizontalRunDetail>
                        <MapIcon />
                        <VerticalRunDetail>
                          <DetailKey>
                            {t(
                              "runView.testevolveexploratory.sessions.observations"
                            )}
                          </DetailKey>
                          <DetailValue>
                            {session.total_observations || 0}
                          </DetailValue>
                        </VerticalRunDetail>
                      </HorizontalRunDetail>
                      <HorizontalRunDetail>
                        <PencilIcon />
                        <VerticalRunDetail>
                          <DetailKey>
                            {t("runView.testevolveexploratory.sessions.notes")}
                          </DetailKey>
                          <DetailValue>{session.total_notes || 0}</DetailValue>
                        </VerticalRunDetail>
                      </HorizontalRunDetail>
                    </SessionDetailRow>
                    <SessionDetailRow>
                      <RunDetail>
                        <DetailKey>
                          {t("runView.testevolveexploratory.sessions.started")}
                        </DetailKey>
                        <DetailValue>
                          {mediumDateTime(session.start_time)}
                        </DetailValue>
                      </RunDetail>
                      <RunDetail>
                        <DetailKey>
                          {t(
                            "runView.testevolveexploratory.sessions.lastUpdated"
                          )}
                        </DetailKey>
                        <DetailValue>
                          {timeAgo(session.updated_time)}
                        </DetailValue>
                      </RunDetail>
                    </SessionDetailRow>
                  </SessionRow>
                );
              })}
          </>
        ) : (
          <p>{t("runView.testevolveexploratory.sessions.noSessionsYet")}</p>
        )}
      </SessionsContainer>
    </SectionContainer>
  );
};
