import styled, { css } from "styled-components";

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${({ grow }) => grow};
  border-radius: ${({ theme }) => theme.borders.radius};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const EntriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.paddingMD};
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.paddingMD};
  height: 100%;
  flex-direction: ${({ isNotDesktop, isMobile }) =>
    isMobile ? "column" : isNotDesktop ? "row" : "row"};
`;

export const Entry = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  gap: ${({ theme }) => theme.spacing.padding};
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledKey = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const SubType = styled.span`
  color: ${({ theme }) => theme.colours.bulletColour};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const InfoMessage = styled.div`
  ${({ theme, error }) => css`
    color: ${(error && theme.colours.error) || theme.colours.black};
    background-color: ${(error && theme.colours.pale_red) ||
    theme.colours.pale_green};
    border: 1px solid ${(error && theme.colours.error) || theme.colours.success};
    border-radius: ${theme.borders.radius};
    font-weight: ${theme.fontWeight.regular};
    padding: ${theme.spacing.padding};
    @media (min-width: 900px) {
      padding: ${theme.spacing.padding};
    }
  `}
`;

export const MessageContainer = styled.div``;
