import {
  StyledContainer,
  StyledSubSectionLarge,
  StyledHeader,
  StyledInfoDetails,
  TableData,
  TableHeading,
  TableRow,
  StyledTable,
  TableHead,
  TableBody,
  Image,
  iconStyle,
} from "./billingInvoicesSection.styles";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useApp } from "../../../../../context/app/app.provider";
import { useTheme } from "../../../../../context/theme/theme.provider";
import {
  BILLING_HISTORY_HEADERS_DESKTOP,
  BILLING_HISTORY_HEADERS_DESKTOP_PARTNER,
  BILLING_HISTORY_HEADERS_MOBILE,
} from "../../../../../static/constants/constants";
import { usePermissions } from "../../../../../context/hooks";
import { formatCurrency } from "../subscriptionDetails/formatCurrency";
import pdfIconLight from "../../../../../static/assets/download-pdf-light.png";
import pdfIconDark from "../../../../../static/assets/download-pdf-dark.png";

export const BillingInvoiceSection = ({ invoices }) => {
  const { t } = useTranslation();
  const { isMobile } = useApp();
  const { isDark } = useTheme();
  const { isPartnerAdmin } = usePermissions();

  const getPeriod = sub => {
    let start = DateTime.fromMillis(sub.period.start * 1000);
    let end = DateTime.fromMillis(sub.period.end * 1000);
    let period;

    if (start && end) {
      if (start.year === end.year - 1) {
        period = t("subscriptionInfo.Annual");
      }
      if (start.month === end.month - 1) {
        period = t("subscriptionInfo.Monthly");
      }
    }
    return period;
  };

  const getBasis = (sub, data) =>
    sub?.plan?.product
      ? t(`subscriptionInfo.${sub.plan.product}`)
      : data.plan
      ? t(`subscriptionInfo.${data.plan}`)
      : t("subscriptionInfo.n/a");

  const getPartnerInvoiceType = invoice => {
    if (invoice.billing_reason === "subscription_cycle") {
      return t("customer.invoiceType.RENEWAL");
    } else {
      return t(
        `customer.invoiceType.${invoice.metadata?.PARTNER_INVOICE_TYPE}`
      );
    }
  };

  const getDate = value =>
    DateTime.fromMillis(value.status_transitions.finalized_at * 1000).toFormat(
      "dd LLL yyyy"
    );

  const invoicesToDisplay = invoices.filter(
    invoice => invoice.amount_due > 0 || invoice.metadata?.PARTNER_INVOICE_TYPE
  );
  const totalInvoices = invoicesToDisplay.length;

  return invoices && invoices.length ? (
    <StyledContainer data-test="invoice-section">
      <StyledSubSectionLarge>
        <StyledHeader key="invoice-section">
          <p>{t("billing.invoiceTitle")}</p>
        </StyledHeader>
        <StyledInfoDetails>
          <StyledTable>
            <TableHead>
              <TableRow data-test="table-headers">
                {isMobile
                  ? BILLING_HISTORY_HEADERS_MOBILE.map(heading => (
                      <TableHeading
                        key={`${t(heading)}Heading`}
                        data-test="mobile-heading"
                      >
                        {t(heading)}:
                      </TableHeading>
                    ))
                  : isPartnerAdmin()
                  ? BILLING_HISTORY_HEADERS_DESKTOP_PARTNER.map(heading => (
                      <TableHeading
                        key={`${t(heading)}Heading`}
                        data-test="desktop-heading"
                      >
                        {t(heading)}:
                      </TableHeading>
                    ))
                  : BILLING_HISTORY_HEADERS_DESKTOP.map(heading => (
                      <TableHeading
                        key={`${t(heading)}Heading`}
                        data-test="desktop-heading"
                      >
                        {t(heading)}:
                      </TableHeading>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicesToDisplay.map((invoice, index) => {
                let subscription;
                invoice.lines.data.forEach(product => {
                  if (product.amount > 0 || subscription === undefined) {
                    subscription = product;
                  }
                });
                if (subscription) {
                  return (
                    <TableRow key={`row${index}`} data-test="tableRow">
                      <TableData key={`index${index}`} data-test="index">
                        {totalInvoices - index}
                      </TableData>
                      <TableData key={`date${index}`} data-test="date">
                        {getDate(invoice)}
                      </TableData>
                      {!isMobile && (
                        <>
                          <TableData key={`period${index}`} data-test="period">
                            {getPeriod(subscription)}
                          </TableData>
                          <TableData key={`basis${index}`} data-test="basis">
                            {getBasis(subscription, invoice.metadata)}
                          </TableData>
                          {isPartnerAdmin() && (
                            <TableData key={`type${index}`} data-test="type">
                              {getPartnerInvoiceType(invoice)}
                            </TableData>
                          )}
                        </>
                      )}
                      <TableData key={`price${index}`} data-test="price">
                        {formatCurrency(
                          "en",
                          invoice.currency,
                          invoice.amount_due / 100,
                          invoice.account_country
                        )}
                      </TableData>
                      <TableData key={`pdfIcon${index}`} data-test="pdfIcon">
                        <a href={invoice.invoice_pdf}>
                          <Image
                            key="pdf-icon"
                            src={isDark ? pdfIconDark : pdfIconLight}
                            style={iconStyle}
                          />
                        </a>
                      </TableData>
                    </TableRow>
                  );
                } else {
                  return "";
                }
              })}
            </TableBody>
          </StyledTable>
        </StyledInfoDetails>
      </StyledSubSectionLarge>
    </StyledContainer>
  ) : null;
};
