import { Features } from "./features/Features";
import { RunInsights } from "./runInsights/runInsights";
import { RUN_STATUSES, TABS } from "../../../../static/constants/constants";
import { JiraBugs } from "../../components/bugs/jiraBugs";
import { CardTabs } from "../../components/cardTabs/cardTabs";
import { useCardTabs } from "../../hooks/useCardTabs";
import { Card } from "../../card/Card";
import { MetaDataTab } from "../../components/metadataTab/metadataTab";
import { Summary } from "./summary/summary";

export const RightWidget = props => {
  const { isMostRecent, run, runInsights } = props;
  const { activeTabState, getTabs } = useCardTabs(run);

  const showInsights = () =>
    isMostRecent && runInsights && run.status === RUN_STATUSES.COMPLETE;
  const tabs = getTabs(showInsights());
  const activeTab = activeTabState[0];

  return (
    <Card fillWidth fillHeight tabIndex={0}>
      <CardTabs tabs={tabs} activeTabState={activeTabState} />
      {activeTab === TABS.FEATURES ? <Features {...props} /> : null}
      {activeTab === TABS.INSIGHTS ? <RunInsights {...props} /> : null}
      {activeTab === TABS.BUGS ? <JiraBugs {...props} /> : null}
      {activeTab === TABS.METADATA ? <MetaDataTab {...props} /> : null}
      {activeTab === TABS.SUMMARY ? <Summary {...props} /> : null}
    </Card>
  );
};
