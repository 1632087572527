import Cookies from "js-cookie";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth/auth.provider";
import { useProject } from "../../project/project.provider";
import {
  API_STATUS,
  API_CONSTANTS,
  COMMON_HEADERS,
} from "../../../static/constants/apiConstants";
import { saveAs } from "file-saver";

const SCREENSHOT = "api/screenshot/";
const ATTACHMENT = "api/run/manual/attachment/";

const setAuthHeaders = routeConfig => {
  let headers = {};
  const SCC = Cookies.get(API_CONSTANTS.SCC);
  if (SCC) headers[API_CONSTANTS.X_VAL_TOKEN] = SCC;
  if (routeConfig.version)
    headers[API_CONSTANTS.X_API_VERS] = routeConfig.version;
  return headers;
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case API_STATUS.REJECTED: {
      return {
        ...state,
        status: action.type,
        response: action.response,
        error: {
          code: action.error.status,
          text: action.error.reason,
        },
      };
    }
    case API_STATUS.RESOLVED: {
      return {
        ...state,
        response: action.response,
        status: action.type,
        body: action.body,
        error: null,
      };
    }
    case API_STATUS.PENDING: {
      return {
        ...state,
        status: action.type,
        origin: action.origin,
        method: action.method,
      };
    }
    case API_STATUS.IDLE: {
      return {
        body: null,
        error: null,
        origin: null,
        status: action.type,
      };
    }
    default: {
      return state;
    }
  }
};

export const useFetch = isBlocking => {
  const { t } = useTranslation();
  const { project } = useProject();
  const { setSessionExpired } = useAuth();

  const [stopNavigation, setStopNavigation] = useState(false);

  const preventClose = function (e) {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (stopNavigation) window.addEventListener("beforeunload", preventClose);
    else window.removeEventListener("beforeunload", preventClose);

    return () => {
      window.removeEventListener("beforeunload", preventClose);
    };
  }, [stopNavigation]);

  const [state, dispatch] = useReducer(fetchReducer, {
    status: API_STATUS.IDLE,
    body: null,
    error: null,
    origin: null,
    method: null,
    response: null,
  });

  useEffect(() => {
    if (state.error?.code === 401) setSessionExpired();
  }, [state?.error]);

  const api = async (method, routeConfig, body, headers = {}, fileName) => {
    if (isBlocking) setStopNavigation(true);

    dispatch({
      type: API_STATUS.PENDING,
      origin: routeConfig.path,
      method: method,
    });

    let options = {
      method,
      headers: {
        ...COMMON_HEADERS,
        ...setAuthHeaders(routeConfig),
        ...headers,
      },
      credentials: "include",
    };

    if (body) options.body = JSON.stringify(body);

    if (project && project.id && !headers["X-PROJECT-ID"])
      options.headers["X-PROJECT-ID"] = project.id;

    if (body instanceof FormData) {
      options.body = body;
      delete options.headers["Content-Type"];
    }

    try {
      const response = await fetch(routeConfig.path, options);
      setStopNavigation(false);
      window.removeEventListener("beforeunload", preventClose);

      if (response && !response.ok) {
        let error;
        try {
          const json = (await response.text()) || response || null;
          error = JSON.parse(json);
        } catch {
          error = { reason: t("errors.default") };
        }
        dispatch({
          type: API_STATUS.REJECTED,
          response: response,
          error:
            error?.errors && Array.isArray(error?.errors)
              ? {
                  status: response.status,
                  reason: error.errors.map(err => err.message),
                }
              : {
                  status: response.status,
                  reason: error.reason || error.message,
                },
        });
      } else {
        if (fileName) {
          const blob = await response.blob();
          saveAs(blob, fileName);
          dispatch({ type: API_STATUS.RESOLVED, response: response });
        } else {
          if (
            routeConfig.path.includes(SCREENSHOT) ||
            routeConfig.path.includes(ATTACHMENT)
          ) {
            try {
              const imageBlob = await response.blob();
              const imageObjectURL = URL.createObjectURL(imageBlob);
              dispatch({
                type: API_STATUS.RESOLVED,
                body: imageObjectURL,
                response: { url: routeConfig.path, body: imageObjectURL },
              });
            } catch (e) {
              dispatch({
                type: API_STATUS.REJECTED,
                error: e,
                response: response,
              });
            }
          } else {
            let json = (await response.text()) || null;
            dispatch({
              type: API_STATUS.RESOLVED,
              body: JSON.parse(json),
              response: response,
            });
          }
        }
      }
    } catch (e) {
      dispatch({ type: API_STATUS.REJECTED, error: e });
    }
  };

  const get = async (...params) => api(API_CONSTANTS.GET, ...params);

  const getFile = async (...params) => api(API_CONSTANTS.GET, ...params);

  const post = async (...params) => api(API_CONSTANTS.POST, ...params);

  const put = async (...params) => api(API_CONSTANTS.PUT, ...params);

  const patch = async (...params) => api(API_CONSTANTS.PATCH, ...params);

  const deleteApi = async (...params) => api(API_CONSTANTS.DELETE, ...params);

  const reset = () => dispatch({ type: API_STATUS.IDLE });

  return {
    post,
    patch,
    get,
    put,
    getFile,
    deleteApi,
    reset,
    body: state.body,
    error: state.error,
    origin: state.origin,
    method: state.method,
    response: state.response,
    status: {
      isLoading: state.status === API_STATUS.PENDING,
      isResolved: state.status === API_STATUS.RESOLVED,
      isRejected: state.status === API_STATUS.REJECTED,
    },
  };
};
