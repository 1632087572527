import { useTranslation } from "react-i18next";
import { Modal } from "../../..";
import { useUpdateSubscription } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/hooks/updateSubscription";

export const ReactivateSubscriptionModal = ({
  subscriptionDetails,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { reactivateSubscriptionAction } = useUpdateSubscription(closeModal);

  let newPeriodDate = new Date(subscriptionDetails.subscription_validity);
  newPeriodDate.setDate(newPeriodDate.getDate() + 1);

  return (
    <Modal
      title={t("reactivateSubscription.modalTitle")}
      primary={{
        text: t("btnConfirm"),
        onClick: () => reactivateSubscriptionAction(),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: () => closeModal(),
      }}
    >
      <p>
        {t("reactivateSubscription.confirmText1", {
          newPeriodDate: newPeriodDate,
        })}
      </p>
      <p>
        {t("reactivateSubscription.confirmText2", {
          subscriptionId: subscriptionDetails.subscription_id,
        })}
      </p>
      <p>{t("reactivateSubscription.confirmText3")}</p>
    </Modal>
  );
};
