import styled from "styled-components";
import { ACCESSIBILITY_STATUSES } from "../../../../static/constants/constants";
import { ReactComponent as RobotIcon } from "../../../../static/assets/icon-robot.svg";

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  text-wrap: nowrap;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme, status }) =>
    status === ACCESSIBILITY_STATUSES.PASSED
      ? theme.colours.closedButtonBackground
      : status === ACCESSIBILITY_STATUSES.FAILED
      ? theme.colours.pale_red
      : theme.colours.light_grey};

  color: ${({ theme, status }) =>
    status === ACCESSIBILITY_STATUSES.PASSED
      ? theme.colours.closedButtonForeground
      : status === ACCESSIBILITY_STATUSES.FAILED
      ? theme.colours.severity.highest
      : theme.colours.status.skipped};
  text-transform: capitalize;
`;

export const GraphKey = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  width: 100%;
  flex-wrap: wrap;
`;

export const GraphKeyComponent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
`;

export const KeyColour = styled.div`
  width: ${({ theme }) => theme.fontSize.smaller};
  height: ${({ theme }) => theme.fontSize.smaller};
  border-radius: 100%;

  background: ${({ colour }) => colour};
`;

export const AnotherContainer = styled.div`
  p {
    color: ${({ theme }) => theme.colours.fadedMainText};
    font-size: ${({ theme }) => theme.fontSize.smaller};
    margin-bottom: -${({ theme }) => theme.spacing.paddingSM};
  }
`;

export const StyledRobotIcon = styled(RobotIcon)`
  height: ${({ theme }) => theme.sizing.iconSize};
  width: ${({ theme }) => theme.sizing.iconSize};
  color: ${({ theme }) => theme.colours.bulletColour};
`;
