export const productOptions = t => [
  {
    label: t("subscriptionInfo.team"),
    value: "TEAM",
  },
  {
    label: t("subscriptionInfo.business"),
    value: "BUSINESS",
  },
];
