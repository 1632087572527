import React from "react";
import { useTranslation } from "react-i18next";
import { useForgotPassword } from "./hooks/useForgotPassword";
import { Button, Input, CenteredContainer, Message } from "../../components";
import {
  StyledForm,
  CompanyLogo,
  ProductLogo,
  SignInContainer,
  StyledContainer,
} from "../signIn/signIn.styles";
import {
  FormContainer,
  SuccessMessageContainer,
} from "./forgotPassword.styles";
import { useTenant } from "../../context/hooks";

export const ForgotPassword = () => {
  const {
    error,
    formRef,
    setOrgId,
    formError,
    isLoading,
    resetFields,
    setUsername,
    resetPassword,
    resetPasswordSuccess,
  } = useForgotPassword();
  const { t } = useTranslation();

  const { tenant } = useTenant();

  return (
    <SignInContainer>
      <StyledContainer>
        <ProductLogo />
        <h1>{t("forgotPassword.title")}</h1>
        {error ? (
          <Message error>
            {t(`errors.forgotPassword.${error.code || error.reason}`)}
          </Message>
        ) : null}
        {formError.invalid ? (
          <Message error>{t(`errors.validation.${formError.reason}`)}</Message>
        ) : null}
        {resetPasswordSuccess ? (
          <SuccessMessageContainer>
            <Message success>{t("forgotPassword.successMessage")}</Message>
          </SuccessMessageContainer>
        ) : (
          <StyledForm ref={formRef} onFocus={resetFields}>
            <FormContainer>
              <p>{t("signin.forgotPasswordSupport")}</p>
              {tenant ? null : (
                <Input
                  type="text"
                  name="orgId"
                  data-test="orgId-input"
                  label={t("signin.placeholders.orgID")}
                  onChange={e => setOrgId(e.target.value)}
                />
              )}
              <Input
                type="email"
                name="email"
                data-test="email-input"
                label={t("signin.email")}
                onChange={e => setUsername(e.target.value)}
              />
              <CenteredContainer fullWidth>
                <Button
                  primary
                  captcha
                  loading={isLoading}
                  loader={true}
                  data-test="signUp-button"
                  onClick={resetPassword}
                >
                  {t("btnConfirm")}
                </Button>
              </CenteredContainer>
            </FormContainer>
          </StyledForm>
        )}
        <CompanyLogo />
      </StyledContainer>
    </SignInContainer>
  );
};
