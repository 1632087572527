export const useUuid = () => {
  const uuid = () =>
    "_" +
    (
      Number(String(Math.random()).slice(2)) +
      Date.now() +
      Math.round(performance.now())
    )
      .toString(36)
      .substr(2, 9);

  return {
    uuid,
  };
};
