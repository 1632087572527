import React from "react";
import { useNav } from "../hooks/useNav";
import { usePermissions } from "../../../context/hooks";
import { NavigationItem } from "./navigationItem/navigationItem";
import { NavigationParent } from "./navigationParent/navigationParent";

export const Navigation = ({ tree, paneRef }) => {
  const { active, setActive } = useNav();
  const { isUserAdmin, isPartnerAdmin, isApiAdmin } = usePermissions();
  const items = [
    "home",
    "dashboards",
    "unassigned-labels",
    "settings",
    "getting-started",
  ];

  return (
    <>
      {tree
        .filter(
          item =>
            !(
              item.admin &&
              !(isPartnerAdmin() || isUserAdmin()) &&
              !isApiAdmin()
            )
        )
        .map(item =>
          (item.children && item.children?.length) ||
          !items.includes(item.id) ? (
            <NavigationParent
              paneRef={paneRef}
              key={item.id}
              active={active}
              setActive={setActive}
              chevron={item.chevron}
              {...item}
            />
          ) : (
            <NavigationItem
              paneRef={paneRef}
              key={item.id}
              active={active}
              setActive={setActive}
              {...item}
            />
          )
        )}
    </>
  );
};
