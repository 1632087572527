import screenfull from "screenfull";

const toggleFullscreenLister = toggleFullScreenAction => {
  if (
    document.fullscreenEnabled ||
    document.mozFullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled
  ) {
    screenfull.on("change", () => {
      toggleFullScreenAction();
    });
  }
};

const toggleFullscreen = () => {
  const el = document.getElementById("full-screen-container");
  screenfull.toggle(el);
};

export { toggleFullscreen, toggleFullscreenLister };
