import React from "react";
import { useTranslation } from "react-i18next";
import { NodeCard } from "../../common/NodeCard";
import { useNodeView } from "../../hooks/useNodeView";
import { VisualGauge } from "../../../../components/graph/graph";
import {
  GraphContainer,
  SummariesContainer,
  Summary,
} from "./nodeCardTestEvolveVisual.styles";
import { useLatestRun } from "../../../runView/testEvolveVisual/latestRunCard/hooks/useLatestRun";
import { PROVIDERS } from "../../../../static/constants/constants";

export const NodeCardTestEvolveVisual = ({
  run,
  getLabels,
  onRelease,
  frozen,
  isShared,
}) => {
  const { t } = useTranslation();
  const { nodeId } = useNodeView();
  const {
    data,
    status,
    runProps,
    runStatus,
    startTime,
    checksPerformed,
    passedPercentage,
    totalFailedChecks,
  } = useLatestRun(run);

  return (
    <NodeCard
      run={run}
      type={run.category.replace(".", "")}
      status={status}
      runStatus={runStatus}
      startTime={startTime}
      nodeId={nodeId}
      label={run.label}
      getLabels={getLabels}
      runProps={runProps}
      onRelease={onRelease}
      frozen={frozen}
      isShared={isShared}
      provider={PROVIDERS.SPARK}
    >
      <GraphContainer>
        <VisualGauge
          data={data.filter(d => d.value !== 0)}
          passed={Math.round(passedPercentage)}
        />
        <SummariesContainer>
          <Summary>
            <h1 data-test="te-visual-checksperformed-title">
              {checksPerformed}
            </h1>
            <h2 data-test="te-visual-checksperformed-val">
              {t("runCard.testevolvevisual.numberOfChecksTitle")}
            </h2>
          </Summary>
          <Summary>
            <h1 data-test="te-visual-totalfailures-title">
              {totalFailedChecks}
            </h1>
            <h2 data-test="te-visual-totalfailures-val">
              {t("runCard.testevolvevisual.totalFailedChecks")}
            </h2>
          </Summary>
        </SummariesContainer>
      </GraphContainer>
    </NodeCard>
  );
};
