import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useFormValidation = () => {
  const [validation, setValidation] = useState([]);
  const [state, setState] = useState({
    reason: null,
    isValid: null,
    isInvalid: null,
  });

  const { t } = useTranslation();

  const VALIDATION_TYPES = {
    LENGTH: "length",
    EQUAL: "equal",
    EMAIL: "email",
    CAPITALS: "capitals",
    SPECIAL: "special",
  };

  useEffect(() => {
    if (validation.length) {
      setState({
        ...state,
        isValid: validation.every(val => val.isValid === true),
      });
    }
  }, [validation]);

  const validateEquality = dataArray => {
    const reducer = (previousValue, currentValue) =>
      previousValue === currentValue ? currentValue : false;
    return dataArray.reduce(reducer)
      ? { isValid: true }
      : { isInvalid: true, reason: "inequality" };
  };

  const validateLength = (value, { min, max }) => {
    if (min && max) {
      if ((min && value.length < min) || (max && value.length > max))
        return { isInvalid: true, reason: "invalidLength" };
      else {
        return { isValid: true };
      }
    }

    if (min && value.length < min)
      return { isInvalid: true, reason: "invalidMinLength" };
    else {
      return { isValid: true };
    }
  };

  const validateEmail = value => {
    const val = value.includes("@") ? value.split("@")[1].includes(".") : false;
    if (!val) return { isInvalid: true, reason: "invalidEmail" };
    else {
      return { isValid: true };
    }
  };

  const validateNoSpCharacters = value => {
    const sp = /[!@#$£%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    if (sp.test(value)) return { isInvalid: true, reason: "specialCharacters" };
    if (value.includes(" "))
      return { isInvalid: true, reason: "specialCharacters" };
    return { isValid: true };
  };

  const isLowerCase = str =>
    str == str.toLowerCase() && str != str.toUpperCase();

  const validateNoCapitals = value => {
    if (!isLowerCase(value)) return { isInvalid: true, reason: "capitals" };
    return { isValid: true };
  };

  const validateForm = form => {
    let result = [];
    try {
      form.reverse().forEach(field => {
        let valid = { isValid: true };
        if (field.validate.includes(VALIDATION_TYPES.EMAIL)) {
          const v = validateEmail(field.value);
          if (v.isInvalid) valid = v;
        }
        if (field.validate.includes(VALIDATION_TYPES.LENGTH)) {
          const v = validateLength(field.value, field.params);
          if (v.isInvalid) valid = v;
        }
        if (field.validate.includes(VALIDATION_TYPES.EQUAL)) {
          const v = validateEquality(field.value);
          if (v.isInvalid) valid = v;
        }
        if (field.validate.includes(VALIDATION_TYPES.CAPITALS)) {
          const v = validateNoCapitals(field.value);
          if (v.isInvalid) valid = v;
        }
        if (field.validate.includes(VALIDATION_TYPES.SPECIAL)) {
          const v = validateNoSpCharacters(field.value);
          if (v.isInvalid) valid = v;
        }
        if (valid.isInvalid) {
          setState({
            isInvalid: true,
            reason: t(`errors.validation.${valid.reason}`, {
              name: field.name,
              ...field.params,
            }),
          });
        }
        const stateObj = {
          name: field.name,
          ...valid,
        };

        if (valid.isInvalid) {
          stateObj["text"] = t(`errors.validation.${valid.reason}`, {
            name: field.name,
            ...field.params,
          });
          result.unshift(stateObj);
        }
      });
      if (!result.some(r => r.isInvalid))
        setState({ isValid: true, isInvalid: null, reason: null });
    } catch (e) {
      result.unshift({ isInvalid: true, reason: t("errors.default") });
    }
    setValidation(result);
    return result;
  };

  return {
    validateForm,
    validateEmail,
    validation,
    isValid: state.isValid,
    isInvalid: state.isInvalid,
    reason: state.reason,
    type: VALIDATION_TYPES,
    resetValidation: () => {
      setState({ isValid: null, isInvalid: null, reason: null });
      setValidation([]);
    },
  };
};
