import { readableColor } from "polished";
import styled from "styled-components";
import { css } from "styled-components";
import { STATUSES } from "../../../static/constants/constants";
import { ReactComponent as PassIcon } from "../../../static/assets/icon-pass.svg";
import { ReactComponent as FailIcon } from "../../../static/assets/icon-fail.svg";
import { ReactComponent as SkippedIcon } from "../../../static/assets/icon-skipped.svg";
import { ReactComponent as CautionIcon } from "../../../static/assets/icon-caution.svg";

export const StyledFeatureContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > h2 {
      font-size: 1.15rem;
      margin-bottom: ${theme.spacing.padding};
    }
  `}

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
`;

export const StyledScenarioContainer = styled.div`
  button {
    margin-bottom: ${({ theme, isMobile }) =>
      isMobile && theme.spacing.paddingSM};
  }

  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0
      ${({ theme }) => (theme.isNotDesktop ? "0" : theme.spacing.padding)};
    margin-bottom: ${({ theme }) => theme.spacing.paddingLG};

    p {
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
  `}
  border-radius: ${({ theme }) => theme.borders.cardRadius};
`;

export const StyledScenarioHeader = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: ${({ theme }) => theme.sizing.reportHeaderHeight};

  background: ${({ status, theme }) =>
    (status === STATUSES.ERROR &&
      `linear-gradient(90deg, ${theme.gradients.critical_end}, ${theme.gradients.critical_start})`) ||
    (status === STATUSES.NEW &&
      `linear-gradient(90deg, ${theme.gradients.new_end}, ${theme.gradients.new_start})`) ||
    (status === STATUSES.FAILED &&
      `linear-gradient(-90deg, ${theme.gradients.failed_check_start}, ${theme.gradients.failed_check_end})`) ||
    (status === STATUSES.PASSED &&
      `linear-gradient(90deg, ${theme.gradients.passing_end}, ${theme.gradients.passing_start})`) ||
    (status === STATUSES.SKIPPED &&
      `linear-gradient(90deg, ${theme.gradients.skipped_end}, ${theme.gradients.skipped_start})`) ||
    `linear-gradient(90deg, ${theme.gradients.skipped_end}, ${theme.gradients.skipped_start})`};

  width: 100%;
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  color: ${({ theme, status }) =>
    (status === STATUSES.ERROR &&
      readableColor(theme.gradients.critical_end)) ||
    (status === STATUSES.NEW && readableColor(theme.gradients.new_end)) ||
    (status === STATUSES.FAILED &&
      readableColor(theme.gradients.failed_check_end)) ||
    (status === STATUSES.PASSED &&
      readableColor(theme.gradients.passing_end)) ||
    (status === STATUSES.SKIPPED &&
      readableColor(theme.gradients.skipped_end)) ||
    readableColor(theme.gradients.skipped_end)};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  h3 {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  p {
    color: ${({ theme, status }) =>
      (status === STATUSES.ERROR &&
        readableColor(theme.gradients.critical_end)) ||
      (status === STATUSES.NEW && readableColor(theme.gradients.new_end)) ||
      (status === STATUSES.FAILED &&
        readableColor(theme.gradients.failed_check_end)) ||
      (status === STATUSES.PASSED &&
        readableColor(theme.gradients.passing_end)) ||
      (status === STATUSES.SKIPPED &&
        readableColor(theme.gradients.skipped_end)) ||
      readableColor(theme.gradients.skipped_end)};
  }

  h3 {
    color: ${({ theme, status }) =>
      (status === STATUSES.ERROR &&
        readableColor(theme.gradients.critical_end)) ||
      (status === STATUSES.NEW && readableColor(theme.gradients.new_end)) ||
      (status === STATUSES.FAILED &&
        readableColor(theme.gradients.failed_check_end)) ||
      (status === STATUSES.PASSED &&
        readableColor(theme.gradients.passing_end)) ||
      (status === STATUSES.SKIPPED &&
        readableColor(theme.gradients.skipped_end)) ||
      readableColor(theme.gradients.skipped_end)};
  }
`;

export const StyledStepsContainer = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    gap: ${({ theme }) => theme.spacing.padding};
    flex-direction: column;
    margin: 0;
    padding: 0
      ${({ theme }) => (theme.isNotDesktop ? "0" : theme.spacing.paddingMD)};
    color: ${({ theme }) => theme.colours.mainText};
  `}
`;

export const StyledStepContainer = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: ${({ skipped }) => (skipped ? 0.3 : 1)};
  `}
`;

export const StyledStep = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.spacing.paddingSM};
    margin-bottom: ${({ theme, isMobile }) =>
      isMobile && theme.spacing.paddingSM};
    width: ${({ isMobile }) => isMobile && "10%"};
  }

  div {
    width: ${({ isMobile }) => isMobile && "90%"};
  }

  ${({ theme }) => css`
    width: 100%;
    display: flex;
    font-size: 1rem;
    background: ${theme.colours.background};
    padding: ${({ theme }) => theme.spacing.padding};
    padding-left: ${theme.spacing.SP02};
    font-weight: ${theme.fontWeight.regular};
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    > span {
      font-weight: ${theme.fontWeight.bold};
    }
  `}
`;

export const StyledFailure = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    flex-direction: column;
    padding: ${({ theme }) =>
      theme.isNotDesktop
        ? `${theme.spacing.paddingSM} 0`
        : `${theme.spacing.paddingSM}
       ${theme.spacing.padding}
       ${theme.spacing.paddingSM}`};

    pre {
      font-size: ${({ theme }) => theme.fontSize.smaller};
      background: ${({ theme }) => theme.colours.background};
      border-radius: ${({ theme }) => theme.borders.cardRadius};
      padding: ${({ theme }) => theme.spacing.paddingSM};
      color: ${({ theme }) => theme.colours.mainText};
      white-space: pre-wrap;
      word-break: break-all;
      max-height: 420px;
      overflow: auto;
      line-height: 1.5;
    }
  `}
`;

export const InsightsContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  background: ${({ theme }) => theme.colours.foreground};
  border: 2px ${({ theme }) => theme.colours.haloAccentLight} solid;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.navShadow};
  padding: ${({ theme }) => theme.spacing.padding};
  margin-top: 0.5em;
  white-space: pre-wrap;
  word-break: break-all;
  max-height: 420px;
  overflow: auto;
`;

export const InsightsHeader = styled.h3`
  color: ${({ theme }) => theme.colours.haloAccent};
  font-size: ${({ theme }) => theme.fontSize.larger};
  font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
`;

export const InsightsSentencesList = styled.ul`
  list-style-type: disc;

  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.paddingMD};
  li {
    padding-left: ${({ theme }) => theme.spacing.padding};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.fontSize.medium};
    line-height: ${({ theme }) => theme.fontSize.larger};
  }
`;

export const StyledTagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.paddingSM};

  p {
    cursor: auto;
    padding: ${({ theme }) => theme.spacing.padding};
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    background-color: ${({ theme }) => theme.colours.foreground};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
    border: ${({ theme, isDark }) =>
      isDark && `1px solid ${theme.colours.highlight}`};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.padding};
  overflow: hidden;
`;

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
`;

export const LoaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colours.foreground};
  height: ${({ theme }) => theme.sizing.loadingContainerHeight};
  min-height: ${({ theme }) => theme.sizing.loadingContainerHeight};
`;

export const ReportSummary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding: ${({ theme }) => theme.spacing.padding};
  margin-bottom: ${({ theme }) => theme.spacing.padding};

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.padding};
  }

  p {
    padding-top: ${({ theme }) => theme.spacing.paddingSM};
  }
`;

export const StandardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ theme }) => (theme.isMobile ? "center" : "left")};
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: ${({ theme }) => theme.fontSize.small};
  gap: ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.mainText};
`;

export const Tag = styled.p`
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  border: 1px solid ${({ theme }) => theme.colours.background};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  margin: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const StickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => theme.sizing.stickerHeight};
  width: fit-content;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  font-size: 14px;
  grid-column: ${({ double }) => double && "span 2"};

  svg {
    height: ${({ theme }) => theme.sizing.svgHeight};
    width: auto;
  }

  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.paddingMD};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
`;

export const StandardsWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const FilterOptionsWrapper = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const FilterOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;

  .multipleAttemptsCheck {
    width: fit-content;
  }

  & .Select__control {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    box-shadow: none;
    width: 100%;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const ArgumentTableContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.padding};
  width: fit-content;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  white-space: pre-wrap;
  line-height: ${({ theme }) => theme.fontSize.larger};
  max-width: 100%;
  font-size: ${({ theme }) => theme.fontSize.medium};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border: ${({ theme, dark }) =>
    dark && `1px solid ${theme.colours.background}`};
  max-width: 100%;
  overflow-x: auto;
`;

export const ArgumentTable = styled.table`
  width: fit-content;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
`;

export const ArgumentTableRow = styled.tr`
  width: fit-content;
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ArgumentTableCell = styled.td`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  text-align: center;
`;

export const ArgumentTableHeader = styled.td`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const DocStringContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.padding};
  width: fit-content;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  white-space: pre-wrap;
  line-height: ${({ theme }) => theme.fontSize.larger};
  max-width: 100%;
  font-size: ${({ theme }) => theme.fontSize.medium};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border: ${({ theme, dark }) =>
    dark && `1px solid ${theme.colours.background}`};

  .docString {
    border-radius: ${({ theme }) => theme.borders.radius};
    background-color: ${({ theme }) => theme.colours.foreground} !important;
    max-width: 100%;
    width: 100%;
  }
`;

export const StepDetailsContainer = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.padding};
`;

export const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid
    ${({ theme, status }) =>
      (status === STATUSES.ERROR &&
        readableColor(theme.gradients.critical_start)) ||
      (status === STATUSES.NEW && readableColor(theme.gradients.new_start)) ||
      (status === STATUSES.FAILED &&
        readableColor(theme.gradients.failed_check_start)) ||
      (status === STATUSES.PASSED &&
        readableColor(theme.gradients.passing_end)) ||
      (status === STATUSES.SKIPPED &&
        readableColor(theme.gradients.skipped_end)) ||
      readableColor(theme.gradients.skipped_end)};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.paddingXSM}
    ${({ theme }) => theme.spacing.paddingSM};
  width: fit-content;
  color: ${({ theme, status }) =>
    (status === STATUSES.ERROR &&
      readableColor(theme.gradients.critical_start)) ||
    (status === STATUSES.NEW && readableColor(theme.gradients.new_start)) ||
    (status === STATUSES.FAILED &&
      readableColor(theme.gradients.failed_check_start)) ||
    (status === STATUSES.PASSED &&
      readableColor(theme.gradients.passing_start)) ||
    (status === STATUSES.SKIPPED &&
      readableColor(theme.gradients.skipped_end)) ||
    readableColor(theme.gradients.skipped_end)};
`;

export const StyledStatusContainer = styled.div``;

export const LeftContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledPassIcon = styled(PassIcon)`
  height: ${({ theme }) => theme.sizing.iconSize};
  width: ${({ theme }) => theme.sizing.iconSize};
`;

export const StyledFailIcon = styled(FailIcon)`
  height: ${({ theme }) => theme.sizing.iconSize};
  width: ${({ theme }) => theme.sizing.iconSize};
`;

export const StyledSkippedIcon = styled(SkippedIcon)`
  height: ${({ theme }) => theme.sizing.iconSize};
  width: ${({ theme }) => theme.sizing.iconSize};
`;

export const StyledCautionIcon = styled(CautionIcon)`
  height: ${({ theme }) => theme.sizing.iconSize};
  width: ${({ theme }) => theme.sizing.iconSize};
  margin-right: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const FeatureHeader = styled.div`
  top: -1px;
  z-index: 50;
  position: sticky;
  padding: ${({ theme }) => theme.spacing.paddingLG}
    ${({ theme }) => theme.spacing.paddingLGR};
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.underShadow};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
  border-bottom: ${({ theme, isDark }) =>
    isDark && `1px solid ${theme.colours.highlight}`};
`;

export const FeatureBody = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.paddingLG};
`;

export const TextContainer = styled.div`
  text-align: center;
`;

export const InsightsHeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding-left: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const InsightsSubHeader = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colours.mainText};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.paddingLG};
`;

export const FilterSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding: ${({ theme }) => theme.spacing.padding};

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.padding};
  }

  p {
    padding-top: ${({ theme }) => theme.spacing.paddingSM};
  }
`;

export const SmallContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ theme }) => (theme.isMobile ? "flex-start" : "center")};
  width: 100%;
`;

export const InProgressWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
  padding: ${({ theme }) => theme.spacing.paddingSM} 0;
`;
