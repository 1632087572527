import styled from "styled-components";

export const ScenarioCard = styled.tr`
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  background-color: ${({ theme }) => theme.colours.foreground};
  color: ${({ theme }) => theme.colours.mainText};
  td {
    padding: auto;
  }
  p {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ScenarioNameData = styled.td`
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing.paddingSM};
  height: ${({ theme }) => theme.sizing.scenarioNameHeight};
`;

export const ChecksSticker = styled.div`
  display: flex;
  flex-direction: row;
  min-width: ${({ theme }) => theme.sizing.checksStickerWidth};
  height: ${({ theme }) => theme.sizing.checksStickerHeight};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  margin: ${({ theme }) => theme.spacing.paddingXSM};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  align-items: center;

  h1 {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  p {
    text-align: right;
  }
`;

export const SeveritySticker = styled.div`
  display: flex;
  flex-direction: row;
  min-width: ${({ theme }) => theme.sizing.checksStickerWidthSM};
  height: ${({ theme }) => theme.sizing.checksStickerHeight};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  margin: ${({ theme }) => theme.spacing.paddingXSM};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  align-items: center;

  h1 {
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  p {
    text-align: right;
  }
`;

export const Dot = styled.div`
  height: ${({ size, theme }) => size || theme.spacing.padding};
  width: ${({ size, theme }) => size || theme.spacing.padding};
  background-color: ${({ colour, theme }) =>
    (colour === "passed" && theme.gradients.passing_end) ||
    (colour === "failed" && theme.gradients.critical_start) ||
    (colour === "moderate" && theme.gradients.moderate_end)};
  border-radius: 25%;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  margin: ${({ theme }) => theme.spacing.paddingSM};
`;
