import styled, { css } from "styled-components";

export const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.paddingLG};
  gap: ${({ theme }) => theme.spacing.paddingLG};
  align-items: center;
  color: ${({ theme }) => theme.colours.mainText};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  background-color: ${({ theme }) => theme.colours.foreground};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  max-width: ${({ isMobile }) => (isMobile ? "100%" : "80%")};
  max-height: 100%;
  overflow: visible;
  overflow-y: auto;

  ${({ theme, size }) => css`
    max-width: ${size === "small" && theme.sizing.modalWidthSmall};
    max-width: ${size === "medium" && theme.sizing.modalWidth};
    max-width: ${size === "large" && theme.sizing.modalWidthLarge};
    max-width: ${size === "email" && theme.sizing.modalWidthEmail};
  `};

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
    `};

  h1 {
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

export const ScreenCover = styled.div`
  background-color: black;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  z-index: 99998;
`;

export const ModalContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: ${({ theme }) => theme.spacing.padding};
    `};
`;
