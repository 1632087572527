import { useEffect } from "react";
import { useFetch } from "../hooks";
import { createState } from "../../utils/context";
import { subscriptionPlansReducer } from "./subscriptionPlans.reducer";
import { ACTIONS } from "../../static/constants/actions";
import { ENDPOINTS } from "../../static/constants/routeConfig";

const initialState = {
  subscriptionPlans: [],
};

const [useHook, SubscriptionPlansProvider] = createState(
  "subscriptionPlans",
  initialState,
  subscriptionPlansReducer
);

const useSubscriptionPlans = () => {
  const { body, status, get, error } = useFetch();
  const { subscriptionPlans, dispatch } = useHook();

  const setSubscriptionPlans = payload =>
    dispatch({ type: ACTIONS.SET_SUBSCRIPTION_PLANS, payload });

  const getSubscriptionPlans = currencyIsoCode => {
    const url = {
      ...ENDPOINTS.SUBSCRIPTION_PLANS,
      path: ENDPOINTS.SUBSCRIPTION_PLANS.path.replace(
        ":currency",
        currencyIsoCode
      ),
    };
    get(url);
  };

  useEffect(() => {
    if (status.isResolved && body) setSubscriptionPlans(body);
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    subscriptionPlans,
    getSubscriptionPlans,
  };
};

export { useSubscriptionPlans, SubscriptionPlansProvider };
