import styled from "styled-components";

export const StyledColumn = styled.div`
  width: ${({ isMobile }) => (isMobile ? "100%" : "50%")};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingMD};
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.paddingMD};
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingMD};
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.padding};

  gap: ${({ theme }) => theme.spacing.paddingMD};
`;

export const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: 0 ${({ theme }) => theme.spacing.padding};
`;

export const ErrorContainer = styled.div`
  height: ${({ theme }) => theme.sizing.formErrorHeight};

  p {
    color: ${({ theme }) => theme.colours.error};
  }
`;

export const ChangePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
