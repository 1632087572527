import { useParams } from "react-router";
import { useNode } from "../../../context/nodes/nodes.provider";

export const useNodeView = () => {
  const { nodeId, runId, runType } = useParams();
  const { nodeSummary, getNode, node, runs, getNodeSummary } = useNode();

  return {
    node,
    runs,
    runId,
    nodeId,
    getNode,
    runType,
    nodeSummary,
    getNodeSummary,
    latestRun: node.latest_run || {},
    runTrends: node.run_history || [],
  };
};
