import Tippy from "@tippyjs/react";
import styled from "styled-components";

const TooltipComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing.paddingXSM};

  font-size: 14px;

  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
  color: ${({ theme }) => theme.colours.mainText};
`;

export const Tooltip = ({ content, placement, children, disabled }) => {
  if (disabled) return children;

  return (
    <Tippy
      id={`${content}-tooltip`}
      content={
        <TooltipComponent arai-label={`${content}-tooltip`}>
          {content}
        </TooltipComponent>
      }
      delay={[500, 0]}
      animateFill={true}
      offset={[0, 4]}
      arrow={true}
      placement={placement || "top"}
    >
      {children}
    </Tippy>
  );
};
