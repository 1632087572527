import {
  CrossIcon,
  FreezeContainer,
  FreezeDropdown,
} from "./freezeRunMenu.styles";
import "@szhsin/react-menu/dist/index.css";
import {
  FreezeButton,
  FreezeIcon,
  StyledChevron,
} from "../../runDetails/runDetails.styles";
import React, { useEffect, useState } from "react";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { assets } from "../../../../static/assets/assets";
import { RELEASE_STATUSES } from "../../../../static/constants/constants";
import { useReleases } from "../../../../context/releases/releases.provider";
import { useFreezeRun } from "../../../../components/releases/freezeRun/useFreezeRun";
import { useUnFreezeRun } from "../../../../components/releases/unFreezeRun/useUnFreezeRun";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { useGetLabelReleases } from "../../../../components/releases/getLabelReleases/useGetLabelReleases";

export const FreezeRunMenu = ({ run, runReleases, runLabel, hideFreeze }) => {
  const { allOpenReleases } = useReleases();
  const { freezeRun, freezeRunSuccess } = useFreezeRun();
  const { unFreezeRun, unFreezeRunSuccess } = useUnFreezeRun();
  const [menuOpen, setMenuOpen] = useState(false);
  const [newReleases, setNewReleases] = useState(runReleases);
  const { labelReleases, getLabelReleases, labelReleasesSuccess } =
    useGetLabelReleases();
  const history = useHistoryWithParams();

  const getOptions = () =>
    allOpenReleases
      ?.filter(e => e?.labels?.includes(runLabel))
      .filter(rel => !newReleases.includes(rel.name))
      .map(r => ({ value: r, label: r.name }));

  const [options, setOptions] = useState(getOptions());
  const [newRelease, setNewRelease] = useState(null);
  const [runToUnfreeze, setRunToUnfreeze] = useState(null);

  const toggleMenu = () => setMenuOpen(e => !e);

  useEffect(() => {
    getLabelReleases(runLabel);
  }, []);

  useEffect(() => {
    setNewReleases(runReleases);
  }, [runReleases]);

  useEffect(() => {
    if (freezeRunSuccess && newRelease) {
      setOptions(o => o.filter(r => newRelease.value.id !== r.value.id));
      setNewReleases(r => [...r, newRelease.label]);
      setNewRelease(null);
    }
  }, [freezeRunSuccess, newRelease]);

  useEffect(() => {
    if (unFreezeRunSuccess && runToUnfreeze) {
      setNewReleases(o => o.filter(r => runToUnfreeze.name !== r));
      setOptions(o => [
        ...o,
        { label: runToUnfreeze.name, value: runToUnfreeze },
      ]);
      setRunToUnfreeze(null);
    }
  }, [unFreezeRunSuccess, runToUnfreeze]);

  const changeHandler = e => {
    const r = e.filter(rel => !runReleases.includes(rel))[0];
    setNewRelease(r);
    freezeRun(run, r.value);
    toggleMenu();
  };

  const unFreezeHandler = (e, rel) => {
    e.preventDefault();
    e.stopPropagation();

    const release = allOpenReleases?.find(r => r.name === rel);
    unFreezeRun(run, release);
    setRunToUnfreeze(release);
  };

  return labelReleasesSuccess ? (
    <>
      <FreezeContainer>
        <FreezeButton
          hideFreeze={hideFreeze}
          onClick={hideFreeze ? null : toggleMenu}
          data-test="freeze-run-btn"
          aria-label="Select release to freeze run"
          title="Freeze Run"
          disabled={hideFreeze}
        >
          <FreezeIcon />
          <StyledChevron {...assets.icons.navChevron} isOpen={true} />
        </FreezeButton>
        <FreezeDropdown
          key={"releases-input"}
          id={"releases-input"}
          value={newReleases}
          options={options}
          data-test="releases-input"
          onChange={changeHandler}
          classNamePrefix={"Select"}
          isMulti={true}
          isClearable={false}
          menuIsOpen={menuOpen}
          aria-label="Freeze for release"
          innerProps={{
            "aria-label": "Freeze for release",
          }}
        />
      </FreezeContainer>
      {newReleases?.map(rel => {
        const release = labelReleases?.find(e => e.name === rel);

        return (
          <FreezeButton
            key={`release-${rel}`}
            data-test="run-releases"
            onClick={() => history.push(`/release/${release.id}`)}
          >
            <p>{rel}</p>
            {release?.status !== RELEASE_STATUSES.CLOSED ? (
              <CrossIcon onClick={e => unFreezeHandler(e, rel)} />
            ) : null}
          </FreezeButton>
        );
      })}
    </>
  ) : null;
};
