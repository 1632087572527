export const organisationUsersReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_USERS": {
      return {
        ...state,
        users: payload,
      };
    }
    case "SET_INITIAL_USERS": {
      return {
        ...state,
        initialUsers: payload,
      };
    }
    case "SET_BUTTON_ACTIVE": {
      return {
        ...state,
        saveButtonActive: true,
      };
    }
    case "SET_BUTTON_INACTIVE": {
      return {
        ...state,
        saveButtonActive: false,
      };
    }
    case "SET_ACTIVE_USERS": {
      return {
        ...state,
        activeUsers: payload,
      };
    }
    case "SET_INITIAL_ACTIVE_USERS": {
      return {
        ...state,
        initialActiveUsers: payload,
      };
    }
    case "SET_ADMIN_USERS": {
      return {
        ...state,
        adminUsers: payload,
      };
    }
    case "SET_INITIAL_ADMIN_USERS": {
      return {
        ...state,
        initialAdminUsers: payload,
      };
    }
    case "SET_UPDATED_ADMIN_USERS": {
      return {
        ...state,
        updatedAdminUsers: payload,
      };
    }
    case "SET_UPDATED_ACTIVE_USERS": {
      return {
        ...state,
        updatedActiveUsers: payload,
      };
    }
    case "SET_INITIAL_PARTNER_USERS": {
      return {
        ...state,
        initialPartnerUsers: payload,
      };
    }
    case "SET_INITIAL_PARTNER_ADMINS": {
      return {
        ...state,
        initialPartnerAdmins: payload,
      };
    }
    case "SET_UPDATED_PARTNER_USERS": {
      return {
        ...state,
        updatedPartnerUsers: payload,
      };
    }
    case "SET_PARTNER_USERS": {
      return {
        ...state,
        partnerUsers: payload,
      };
    }
    case "SET_USER_PROJECTS": {
      return {
        ...state,
        userProjects: payload,
      };
    }
    case "SET_INITIAL_USER_PROJECTS": {
      return {
        ...state,
        initialUserProjects: payload,
      };
    }
    case "SET_UPDATED_USER_PROJECTS": {
      return {
        ...state,
        updatedUserProjects: payload,
      };
    }
    default:
      return state;
  }
};
