import { useFetch } from "..";
import { useEffect } from "react";
import { useConfig } from "../../config/config.provider";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

export const useConfigActions = () => {
  const { get, body, status, origin } = useFetch();
  const { config, setConfig } = useConfig();

  useEffect(() => {
    if (status.isResolved && origin === ENDPOINTS.CONFIG.path) setConfig(body);
  }, [status.isResolved]);

  const getConfig = () => get(ENDPOINTS.CONFIG);

  return {
    getConfig,
    config,
    isLoading: status.isLoading,
  };
};
