import styled from "styled-components";
import { SvgIcon } from "../../../icon/icon";
import { ReactComponent as Icon } from "../../../../static/assets/icon-open-menu.svg";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import { ReactComponent as PlusIcon } from "../../../../static/assets/icon-plus.svg";

export const ChildElement = styled.div`
  padding-left: ${({ theme }) => theme.spacing.paddingMD};
  transition: 0.3s;
`;

export const ParentElement = styled.div`
  overflow: visible;
  &:hover {
    svg {
      opacity: 100%;
    }
  }
`;

export const RightChevron = styled(SvgIcon)`
  height: ${({ theme }) => theme.sizing.svgHeightSM};
  width: auto;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(90deg)")};
  transition: ${({ theme }) => theme.transitionTime.fast};
  color: ${({ active, theme }) =>
    active ? theme.colours.mainText : theme.colours.fadedMainText};
`;

export const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  z-index: 97;
  color: ${({ active, theme }) =>
    active ? theme.colours.mainText : theme.colours.fadedMainText};

  &:hover {
    background-color: ${({ theme }) => theme.colours.foreground};
  }
`;

export const OpenMenuIcon = styled(Icon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  color: ${({ active, theme }) =>
    active ? theme.colours.mainText : theme.colours.fadedMainText};

  &:hover {
    background-color: ${({ theme }) => theme.colours.foreground};
  }
`;

export const OpenMenuIconContainer = styled.div`
  display: flex;
`;

export const NewNodeItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.medium};

  height: ${({ theme }) => theme.sizing.navItemHeight};
  min-height: ${({ theme }) => theme.sizing.navItemHeight};

  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radiusMD};
  background-color: ${({ theme }) => theme.colours.background};

  input {
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.borders.radiusMD};
    padding-left: ${({ theme }) => theme.spacing.padding};
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};
    outline: none;
    border: none;

    &: focus {
      outline: none;
    }
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  justify-content: end;

  .szh-menu {
    background-color: ${({ theme }) => theme.colours.dropdownBG};
    width: ${({ theme }) => theme.sizing.dropdownMenuWidth};
    border: 1px ${({ theme }) => theme.borders.dropdownBorder} solid;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    padding: ${({ theme }) => theme.spacing.paddingSM};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  }

  .szh-menu__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${({ theme }) => theme.sizing.dropdownRowHeight};
    z-index: 99;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    gap: ${({ theme }) => theme.spacing.paddingSM};

    &:hover {
      background-color: ${({ theme }) => theme.colours.dropdownHighlight};
      p {
        opacity: 100%;
      }
    }
  }

  svg {
    opacity: ${({ active }) => (active ? "100%" : "60%")};
    color: ${({ theme }) => theme.colours.mainText};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme, error }) =>
    error ? theme.colours.error : theme.colours.mainText};
`;

export const StyledMenu = styled(ControlledMenu)`
  .szh-menu {
    background-color: ${({ theme }) => theme.colours.dropdownBG};
    border: 1px ${({ theme }) => theme.borders.dropdownBorder} solid;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    padding: ${({ theme }) => theme.spacing.paddingSM};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
    z-index: 99;
  }

  .szh-menu__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${({ theme }) => theme.sizing.dropdownRowHeight};
    z-index: 99;
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    gap: ${({ theme }) => theme.spacing.paddingSM};

    &:hover {
      background-color: ${({ theme }) => theme.colours.dropdownHighlight};
      p {
        opacity: 100%;
      }
    }
  }

  color: ${({ theme }) => theme.colours.mainText};
  z-index: 999;
  svg {
    opacity: ${({ active }) => (active ? "100%" : "60%")};
    color: ${({ theme }) => theme.colours.mainText};
  }
`;
export const ModalContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

export const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.paddingLG};
  gap: ${({ theme }) => theme.spacing.paddingLG};
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colours.mainText};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  background-color: ${({ theme }) => theme.colours.foreground};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  z-index: 99999;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const NodeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme }) => theme.colours.background};
  color: ${({ theme }) => theme.colours.mainText};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
`;

export const ScreenCover = styled.div`
  background-color: black;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  z-index; 98;
`;

export const RenameNodeItem = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  background: none;

  input {
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.borders.radiusMD};
    background: none;
    color: ${({ theme }) => theme.colours.mainText};
    outline: none;
    border: none;

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colours.background};
    }
    :-ms-input-placeholder {
      color: ${({ theme }) => theme.colours.background};
    }

    &: focus {
      outline: none;
    }
  }
`;
