import styled, { css } from "styled-components";

export const StyledContainer = styled.div`
  min-width: 7rem;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: 0 ${({ theme }) => theme.spacing.padding};

  div {
    width: fit-content;
  }
`;

export const CreateFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  width: 100%;
`;

export const TitleContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  min-width: 20rem;
  color: ${({ theme }) => theme.colours.mainText};
  padding: 0 ${({ theme }) => theme.spacing.padding};
`;

export const StyledField = styled.input`
  width: 100%;
  appearance: none;
  @media all and (-ms-high-contrast: none) {
    & {
      box-sizing: content-box;
    }
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.paddingSM};
    background: ${theme.colours.background};
    height: ${theme.sizing.btnHeight};
    line-height: inherit;
    border: 1px solid ${({ theme }) => theme.colours.foreground};
    border-radius: ${theme.borders.radius};
    color: ${theme.colours.mainText};
    border-color: ${({ theme, invalid }) => invalid && theme.colours.error};

    &::-moz-placeholder {
      line-height: 0;
      color: ${({ theme }) => theme.colours.secondaryText};
    }
    &::placeholder {
      color: ${({ theme }) => theme.colours.secondaryText};
    }
  `};
`;

export const StyledFieldContainer = styled.div`
  display: flex;
  width: 14.28%;
  align-self: auto;
  border: 0;
  padding: ${({ theme }) => theme.spacing.padding};
  ${({ theme, large }) =>
    large &&
    css`
      ${StyledField} {
        height: ${theme.sizing.btnHeight};
      }
    `}
  
  ${({ theme, spacing }) =>
    spacing &&
    css`
      ${StyledField} {
        margin-bottom: ${theme.spacing.paddingSM};
      }
    `}
	};
`;

export const StyledCreateFieldContainer = styled.div`
  display: flex;
  align-self: auto;
  border: 0;
  padding: ${({ theme }) => theme.spacing.padding};
  flex-grow: 1;

  width: ${({ isNotDesktop }) => isNotDesktop && "100%"};

  ${({ theme, large }) =>
    large &&
    css`
      ${StyledField} {
        height: ${theme.sizing.btnHeight};
      }
    `}
  
  ${({ theme, spacing }) =>
    spacing &&
    css`
      ${StyledField} {
        margin-bottom: ${theme.spacing.paddingSM};
      }
    `}
	};
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledMainContainer = styled.div`
  width: 100%;
  padding-top: 20px;
`;

export const ManageFormContainer = styled.div`
  display: flex;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`;

export const FormAlertContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.padding} 0;
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const FormAlert = styled.div`
  height: 40px;
  background-color: red;
`;

export const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.colours.error};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing.padding};
`;

export const ButtonAndSpinnerContainer = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
`;
