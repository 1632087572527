import React from "react";
import { useTranslation } from "react-i18next";
import { useTestTypeIcon } from "../hooks/useTestTypeIcon.js";
import { StickerContainer, ValContainer, Heading } from "./testTypes.styles.js";

export const TestTypes = ({
  data,
  heading,
  double,
  runProperties,
  runTable,
}) => {
  const { t } = useTranslation();
  const { returnIcon } = useTestTypeIcon();
  const key = Object.keys(data)[0];
  const val = data[key];

  const Icon = returnIcon(val, key);
  return Icon || !runTable ? (
    <StickerContainer
      data-test={runProperties ? `${key}-run-property` : `${key}-test-types`}
      runProperties={runProperties}
      double={double}
    >
      {heading ? <Heading>{t(`runProperties.${key}`)}</Heading> : null}
      <ValContainer>
        {Icon}
        <p>{t(`${key === "test_type" ? "testTypes." : ""}${val}`)}</p>
      </ValContainer>
    </StickerContainer>
  ) : null;
};
