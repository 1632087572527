import {
  StyledMenuItem,
  StyledMenu,
} from "../navigationParent/navigationParent.styles";
import "@szhsin/react-menu/dist/index.css";
import React from "react";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../../context/modal/modal.provider";

export const NodeMenuComponent = ({
  id,
  name,
  create,
  del,
  rename,
  renameNodeHandler,
  createNodeHandler,
  r,
  toggleMenu,
  menuState,
  onClose,
}) => {
  const { t } = useTranslation();
  const { openModal, MODALS } = useModal();

  return (
    <StyledMenu
      transition
      {...menuState}
      anchorRef={r}
      onClose={() => {
        if (onClose) onClose();
        toggleMenu(false);
      }}
    >
      {create ? (
        <StyledMenuItem
          onClick={e => createNodeHandler(e, id)}
          data-test="create-node"
        >
          {t("createNode.title")}
        </StyledMenuItem>
      ) : null}
      {rename ? (
        <StyledMenuItem
          onClick={e => renameNodeHandler(e, id)}
          data-test="rename-node"
        >
          {t("renameNode.title")}
        </StyledMenuItem>
      ) : null}
      {del ? (
        <StyledMenuItem
          onClick={() =>
            openModal(MODALS.DELETE_NODE, {
              nodeDetails: { id: id, name: name },
            })
          }
          data-test="delete-node"
          error
        >
          {t("deleteNode.title")}
        </StyledMenuItem>
      ) : null}
    </StyledMenu>
  );
};
