import React from "react";
import {
  ScenarioCard,
  ScenarioNameData,
  ChecksSticker,
  FailedChecks,
} from "./scenario.styles";

export const Scenario = ({ name, checksPerformed, totalFailedChecks }) => (
  <ScenarioCard>
    <ScenarioNameData>{name}</ScenarioNameData>
    <td>
      {checksPerformed || checksPerformed === 0 ? (
        <ChecksSticker>
          <h1>{checksPerformed}</h1>
          <p>{checksPerformed === 1 ? "check" : "checks"}</p>
        </ChecksSticker>
      ) : null}
    </td>
    <td>
      <FailedChecks>{totalFailedChecks}</FailedChecks>
    </td>
  </ScenarioCard>
);
