import { ReactComponent as IconLeft } from "../icons/text-left.svg";
import { ReactComponent as IconCenter } from "../icons/text-center.svg";
import { ReactComponent as IconRight } from "../icons/text-right.svg";
import { ReactComponent as IconOutdent } from "../icons/outdent.svg";
import { ReactComponent as IconIndent } from "../icons/indent.svg";
import {
  INDENT_CONTENT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from "lexical";
import DropDown, { DropDownItem } from "../../../ui/DropDown";
import { Divider } from "./divider";
import { useTranslation } from "react-i18next";

export const ElementFormatDropdown = ({
  editor,
  value,
  isRTL,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const ELEMENT_FORMAT_OPTIONS = {
    center: {
      icon: "center-align",
      iconRTL: "center-align",
      name: t("richTextEditor.toolbar.centerAlign"),
    },
    start: {
      icon: "left-align",
      iconRTL: "left-align",
      name: t("richTextEditor.toolbar.leftAlign"),
    },
    left: {
      icon: "left-align",
      iconRTL: "left-align",
      name: t("richTextEditor.toolbar.leftAlign"),
    },
    right: {
      icon: "right-align",
      iconRTL: "right-align",
      name: t("richTextEditor.toolbar.rightAlign"),
    },
    end: {
      icon: "right-align",
      iconRTL: "right-align",
      name: t("richTextEditor.toolbar.rightAlign"),
    },
  };

  const formatOption = ELEMENT_FORMAT_OPTIONS[value || "left"];

  return (
    <DropDown
      disabled={disabled}
      buttonLabel={formatOption.name}
      buttonIconClassName={`icon ${
        isRTL ? formatOption.iconRTL : formatOption.icon
      }`}
      buttonClassName="toolbar-item spaced alignment"
      buttonAriaLabel="Formatting options for text alignment"
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        }}
      >
        <IconLeft />
        <span className="text">{ELEMENT_FORMAT_OPTIONS.left.name}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        }}
      >
        <IconCenter />
        <span className="text">{ELEMENT_FORMAT_OPTIONS.center.name}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        }}
      >
        <IconRight />
        <span className="text">{ELEMENT_FORMAT_OPTIONS.right.name}</span>
      </DropDownItem>
      <Divider />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        }}
      >
        <IconOutdent />
        <span className="text">{t("richTextEditor.toolbar.outdent")}</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
        }}
      >
        <IconIndent />
        <span className="text">{t("richTextEditor.toolbar.indent")}</span>
      </DropDownItem>
    </DropDown>
  );
};
