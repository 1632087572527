import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";
import { useNotifications } from "../../../../../../context/hooks/useNotifications/useNotifications";
import {
  MONTHLY,
  MONTHLY_ALL_CAPS,
  ANNUAL_ALL_CAPS,
} from "../../../../../../static/constants/constants";
import { useUpdate } from "../../../../../../context/billingUpdate/billingUpdate.provider";
import { useModal } from "../../../../../../context/modal/modal.provider";

export const useUpdateSubscription = () => {
  const { t } = useTranslation();
  const [cancel, setCancel] = useState(false);
  const { setBillingUpdate } = useUpdate();
  const { closeModal } = useModal();

  const { patch, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();

  const cancelSubscriptionAction = () => {
    setCancel(true);
    patch(ENDPOINTS.SUBSCRIPTION, { cancel_at_end_of_period: true });
  };

  const reactivateSubscriptionAction = () => {
    setCancel(false);
    patch(ENDPOINTS.SUBSCRIPTION, { cancel_at_end_of_period: false });
  };

  const updateSubscriptionAction = (product, changePeriod) => {
    patch(ENDPOINTS.SUBSCRIPTION, {
      product: product,
      change_period:
        changePeriod === MONTHLY ? MONTHLY_ALL_CAPS : ANNUAL_ALL_CAPS,
    });
  };

  useEffect(() => {
    if (status.isResolved) {
      const message = cancel
        ? "subscriptionUpdate.cancelledSubscription"
        : "subscriptionUpdate.updatedSubscription";
      createNotification(types.SUCCESS, message);
      setBillingUpdate();
      closeModal();
    }
    if (status.isRejected && error) {
      const errMessage = cancel
        ? `errors.cancelSubscription.${error.code}`
        : `errors.updateSubscription.${error.code}`;
      createNotification(types.ERROR, t(errMessage));
      closeModal();
    }
  }, [status.isResolved, status.isRejected, error, body]);

  return {
    updateSubscriptionAction,
    cancelSubscriptionAction,
    reactivateSubscriptionAction,
  };
};
