import { useLatestRun } from "../hooks/useLatestRun";
import { PieChart, Pie, Cell } from "recharts";
import { useTranslation } from "react-i18next";
import {
  Container,
  GraphContainer,
  Section,
  Dot,
  StickersContainer,
  ScenarioSticker,
  ScenarioBox,
  ScenarioContainer,
  ScenarioValue,
  RunStatusSticker,
  RunStatusStickerBox,
  RunStatusContainer,
  RunStatusTitle,
  RunStatusValue,
} from "./scenariosSection.styles";
import { routes } from "../../../../../router/routes";
import { useHistoryWithParams } from "../../../../../context/hooks/historyWithParams/useHistoryWithParams";
import {
  RUN_STATUSES,
  STATUSES,
} from "../../../../../static/constants/constants";

export const ScenariosSection = ({ run, nodeDetails }) => {
  const {
    checksPerformed,
    scenariosChartData,
    failedScenarios,
    passedScenarios,
    errorScenarios,
    skippedScenarios,
  } = useLatestRun(run);
  const { t } = useTranslation();
  const history = useHistoryWithParams();
  const isInProgress = run.status === RUN_STATUSES.IN_PROGRESS;

  const reportUrl = `${routes.RUN_VIEW_REPORT.path
    .replace(":nodeId", nodeDetails?.id)
    .replace(":runType", run?.category)
    .replace(":label", encodeURI(encodeURIComponent(run?.label)))
    .replace(":runId", run?.id)}`;

  const stickerHandler = (key, value) => {
    if (isInProgress) return;

    history.pushWithHash(
      key && value ? `${reportUrl}?${key}=${value}` : reportUrl
    );
  };

  return (
    <Container>
      <Section data-test="scenarios-pie-chart">
        <GraphContainer data-test="scenarios-pie-chart">
          <PieChart data-test="scenarios-pie-chart">
            <Pie
              data={scenariosChartData}
              cornerRadius={40}
              dataKey="value"
              data-test="scenarios-pie"
              radius={[10, 10, 0, 0]}
              innerRadius={68}
              outerRadius={80}
              paddingAngle={5}
            >
              <Cell
                key={"cell-pass"}
                fill={"url(#gradientPass"}
                stroke="none"
                radius={[10, 10, 10, 10]}
                aria-label="pass-cell"
              />
              <Cell
                key={"cell-fail"}
                fill={"url(#gradientFail"}
                stroke="none"
                radius={[10, 10, 10, 10]}
                aria-label="fail-cell"
              />
              <Cell
                key={"cell-error"}
                fill={"url(#gradientError"}
                stroke="none"
                radius={[10, 10, 10, 10]}
                aria-label="error-cell"
              />
              <Cell
                key={"cell-skipped"}
                fill={"url(#gradientSkipped"}
                stroke="none"
                radius={[10, 10, 10, 10]}
                aria-label="skipped-cell"
              />
            </Pie>
          </PieChart>
        </GraphContainer>
      </Section>
      <Section>
        <StickersContainer>
          <ScenarioBox>
            <ScenarioSticker
              clickable={!isInProgress}
              onClick={() => stickerHandler("status", STATUSES.PASSED)}
            >
              <ScenarioContainer data-test="te-lighthouse-passing-sticker">
                <RunStatusTitle>
                  {t("runView.testevolvelighthouse.passed.name")}
                </RunStatusTitle>
                <Dot colour={"passed"} rotate="45" />
                <ScenarioValue>{passedScenarios.length}</ScenarioValue>
              </ScenarioContainer>
            </ScenarioSticker>
          </ScenarioBox>
          <ScenarioBox>
            <ScenarioSticker
              clickable={!isInProgress}
              onClick={() => stickerHandler("status", STATUSES.FAILED)}
            >
              <ScenarioContainer data-test="te-lighthouse-failing-sticker">
                <RunStatusTitle>
                  {t("runView.testevolvelighthouse.failed.name")}
                </RunStatusTitle>
                <Dot colour={"failed"} rotate="45" />
                <ScenarioValue>{failedScenarios.length}</ScenarioValue>
              </ScenarioContainer>
            </ScenarioSticker>
          </ScenarioBox>
          <ScenarioBox>
            <ScenarioSticker
              clickable={!isInProgress}
              onClick={() => stickerHandler("status", STATUSES.ERROR)}
            >
              <ScenarioContainer data-test="te-lighthouse-error-sticker">
                <RunStatusTitle>
                  {t("runView.testevolvelighthouse.error.name")}
                </RunStatusTitle>
                <Dot colour={"error"} rotate="45" />
                <ScenarioValue>{errorScenarios.length}</ScenarioValue>
              </ScenarioContainer>
            </ScenarioSticker>
          </ScenarioBox>
          <ScenarioBox>
            <ScenarioSticker
              clickable={!isInProgress}
              onClick={() => stickerHandler("status", STATUSES.SKIPPED)}
            >
              <ScenarioContainer data-test="te-lighthouse-skipped-sticker">
                <RunStatusTitle>
                  {t("runView.testevolvelighthouse.skipped.name")}
                </RunStatusTitle>
                <Dot colour={"skipped"} rotate="45" />
                <ScenarioValue>{skippedScenarios.length}</ScenarioValue>
              </ScenarioContainer>
            </ScenarioSticker>
          </ScenarioBox>
        </StickersContainer>
        <StickersContainer>
          <RunStatusStickerBox>
            <RunStatusSticker
              clickable={!isInProgress}
              onClick={() => stickerHandler()}
            >
              <RunStatusContainer data-test="te-lighthouse-checks-sticker">
                <RunStatusTitle>
                  {t("runView.testevolvelighthouse.numberOfChecksTitle")}
                </RunStatusTitle>
                <RunStatusValue>{checksPerformed}</RunStatusValue>
              </RunStatusContainer>
            </RunStatusSticker>
          </RunStatusStickerBox>
        </StickersContainer>
      </Section>
    </Container>
  );
};
