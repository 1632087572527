import { ACTIONS } from "../../static/constants/actions";

export const organisationReducer = (state, { type, payload }) => {
  switch (type) {
    case `${ACTIONS.GET_ORGANISATION_SUCCESS}`: {
      return {
        ...state,
        ...payload,
      };
    }
    case `${ACTIONS.GET_ORGANISATION_FAILURE}`: {
      return {
        ...state,
        active: true,
      };
    }
    default:
      return state;
  }
};
