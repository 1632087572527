import {
  Wrapper,
  ButtonContainer,
  UserNameContainer,
  StyledMainContainer,
  ProjectRolesContainer,
  StyledTitlesContainer,
} from "./usersList.styles";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { RemoveUserButton } from "./removeUserButton";
import { ProjectRolesPicker } from "./projectRolesPicker";
import { usePermissions } from "../../../../context/hooks";
import { useApp } from "../../../../context/app/app.provider";
import { useUser } from "../../../../context/user/user.provider";
import { roles as r } from "../../../../static/constants/constants";
import { useUserOptions } from "../../../../context/admin/userOptions/userOptions.provider";

export const UsersList = ({ useProjectRoles }) => {
  const { roles } = useUser();
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();
  const { isPartner, isPartnerAdmin } = usePermissions();
  const { availableRoles } = useUserOptions();

  const {
    btnDisabled,
    projectRoles,
    handleSubmit,
    projectUsers,
    handleSelect,
    getProjectUsers,
  } = useProjectRoles;

  const loggedInUserHasPartnerAdminRole = () =>
    roles?.some(role => role.key === r.PARTNER_ADMIN);

  const editableUsers = () =>
    projectUsers?.filter(user => !isPartner(user) && user.active);

  const nonEditableUsers = () =>
    projectUsers?.filter(user => isPartner(user) && user.active);

  useEffect(() => {
    getProjectUsers();
  }, []);

  return (
    <>
      <h2>{t("settings.organisation.users")}</h2>
      <Wrapper>
        <StyledTitlesContainer>
          <div>{t("settings.organisation.columnTitles.user")}</div>
          {loggedInUserHasPartnerAdminRole() && (
            <div>{t("settings.organisation.columnTitles.partner")}</div>
          )}
          <div>{t("settings.organisation.columnTitles.roles")}</div>
        </StyledTitlesContainer>
        {projectUsers &&
          editableUsers().map((user, key) => {
            return (
              <StyledMainContainer key={key} isNotDesktop={isNotDesktop}>
                <UserNameContainer>
                  <div>{user.username}</div>
                </UserNameContainer>
                <ProjectRolesContainer isNotDesktop={isNotDesktop}>
                  <ProjectRolesPicker
                    user={user}
                    roles={availableRoles}
                    disabled={false}
                    projectRoles={projectRoles}
                    handleSelect={handleSelect}
                    aria-label="add-project-select"
                    aria-labelledBy="react-select-3-placeholder"
                  />
                </ProjectRolesContainer>
                <RemoveUserButton
                  user={user}
                  getProjectUsers={getProjectUsers}
                />
              </StyledMainContainer>
            );
          })}
        <ButtonContainer isNotDesktop={isNotDesktop}>
          <Button
            accent
            onClick={() => handleSubmit()}
            disabled={btnDisabled}
            data-test="save-user-btn"
          >
            {t("btnSave")}
          </Button>
        </ButtonContainer>
      </Wrapper>
      {projectUsers && nonEditableUsers()?.length > 0 && (
        <Wrapper>
          <StyledTitlesContainer
            displayFlex
            showPartner={loggedInUserHasPartnerAdminRole}
          >
            <div>{t("settings.organisation.columnTitles.partnerUsers")}</div>
            <div>{t("settings.organisation.columnTitles.roles")}</div>
          </StyledTitlesContainer>
          {projectUsers &&
            projectUsers
              .filter(user => isPartner(user))
              .map((user, key) => {
                return (
                  <StyledMainContainer key={key}>
                    <UserNameContainer>
                      <div>{user.username}</div>
                    </UserNameContainer>
                    <ProjectRolesContainer>
                      <ProjectRolesPicker
                        user={user}
                        disabled={!isPartnerAdmin()}
                        roles={availableRoles}
                        handleSelect={handleSelect}
                      />
                    </ProjectRolesContainer>
                    <RemoveUserButton user={user} />
                  </StyledMainContainer>
                );
              })}
        </Wrapper>
      )}
    </>
  );
};
