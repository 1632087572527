import React from "react";
import {
  Section,
  GraphContainer,
  HorizontalContainer,
} from "./violations.styles.js";
import { theme } from "../../../../../theme/theme";
import { LighthouseScoreGauge } from "../../../../../components/graph/graph";
import { useLatestRun } from "../../../../runView/testEvolveLighthouse/latestRunCard/hooks/useLatestRun.js";

export const Violations = ({ run }) => {
  const { data } = useLatestRun(run);

  return (
    <Section>
      <HorizontalContainer>
        <GraphContainer>
          <LighthouseScoreGauge
            data={data.performance}
            passed={data.performance[0].value}
            containerHeight={theme.sizing.graphContainerSM}
          />
        </GraphContainer>
        <GraphContainer>
          <LighthouseScoreGauge
            data={data.accessibility}
            passed={data.accessibility[0].value}
            containerHeight={theme.sizing.graphContainerSM}
          />
        </GraphContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <GraphContainer>
          <LighthouseScoreGauge
            data={data.bestPractices}
            passed={data.bestPractices[0].value}
            containerHeight={theme.sizing.graphContainerSM}
          />
        </GraphContainer>
        <GraphContainer>
          <LighthouseScoreGauge
            data={data.seo}
            passed={data.seo[0].value}
            containerHeight={theme.sizing.graphContainerSM}
          />
        </GraphContainer>
      </HorizontalContainer>
    </Section>
  );
};
