import styled from "styled-components";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../../icon/icon";

export const RightChevron = styled(SvgIcon)`
  height: ${({ theme }) => theme.sizing.svgHeightSM};
  width: auto;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(90deg)")};
  transition: ${({ theme }) => theme.transitionTime.fast};
  color: inherit;
`;

export const StyledNavItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: ${({ center }) => (center ? "center" : "flex-start")};

  gap: ${({ theme }) => theme.spacing.paddingSM};
  height: ${({ theme }) => theme.sizing.navItemHeight};
  min-height: ${({ theme }) => theme.sizing.navItemHeight};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radiusMD};
  padding-left: ${({ theme }) => theme.spacing.padding};
  padding-right: ${({ theme }) => theme.spacing.padding};
  border: ${({ rename, theme }) =>
    rename ? `2px solid ${theme.colours.haloAccentLight}` : "none"};

  color: ${({ active, theme }) =>
    active ? theme.colours.mainText : theme.colours.fadedMainText};

  background-color: ${({ theme, active, selected }) =>
    active
      ? theme.colours.haloAccentLight
      : selected
      ? theme.colours.highlight
      : theme.colours.navBackground};

  &:hover {
    background-color: ${({ theme, active }) =>
      !active && theme.colours.highlight};

    color: ${({ theme }) => theme.colours.mainText};
  }

  svg {
    color: inherit;
  }

  ${RightChevron} {
    height: ${({ theme }) => theme.sizing.svgHeightSM};
    z-index: 999;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colours.mainText};
  font-weight: 500;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;

  p {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding-left: ${({ theme }) => theme.spacing.padding};
    text-decoration: none;
    color: inherit
  }

  svg {
    color: inherit
    height: ${({ theme }) => theme.sizing.svgHeight};
    width: ${({ theme }) => theme.sizing.svgHeight};
  }
`;

export const ChevronContainer = styled.div`
  &:active {
    background-color: ${({ theme }) => theme.colours.status.skipped};
  }
  padding: ${({ theme }) => theme.spacing.paddingXXSM};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;

  p {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: inherit;

    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;

    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-grow: 1;
  }
`;
