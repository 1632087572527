import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export const KeyContainer = styled.div`
  width: 100%;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.padding};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const StyledKey = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingMD};
  border-radius: 6px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background: ${({ theme }) => theme.colours.background};
`;

export const CopyMessage = styled.div`
  display: ${props => (props.selected ? "none" : "block")};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const SuccessMessage = styled.div`
  align-self: center;
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;
