import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { countryData } from "../../static/constants/countryCodes";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  FormContainer,
  RowContainer,
  MultiRowContainer,
  ElementContainer,
  EmailElement,
  TextElement,
  iconStyle,
  ReadOnlyElement,
  IconContainer,
  Image,
} from "./stripe.styles";
import mastercardIcon from "../../static/assets/icon-mastercard.png";
import mastercardIconDark from "../../static/assets/icon-mastercard-dark.png";
import visaIcon from "../../static/assets/icon-visa.png";
import amexIcon from "../../static/assets/icon-amex.png";
import { ReactComponent as CalendarIcon } from "../../static/assets/icon-calendar.svg";
import { ReactComponent as CalendarIconDark } from "../../static/assets/icon-calendar-dark.svg";
import { ReactComponent as EmailIcon } from "../../static/assets/icon-new-post.svg";
import { ReactComponent as EmailIconDark } from "../../static/assets/icon-new-post-dark.svg";
import { ReactComponent as CardIcon } from "../../static/assets/icon-credit-card.svg";
import { ReactComponent as CardIconDark } from "../../static/assets/icon-credit-card-dark.svg";
import { ReactComponent as CvcIcon } from "../../static/assets/icon-card-security-code.svg";
import { ReactComponent as CvcIconDark } from "../../static/assets/icon-card-security-code-dark.svg";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../context/config/config.provider";
import { useTheme as useDarkMode } from "../../context/theme/theme.provider";

export const Stripe = ({ children }) => {
  const { config } = useConfig();

  if (!config.stripeApiKey) return null;

  const stripePromise = loadStripe(config.stripeApiKey);

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export const StripeFormContent = ({
  handleEmail,
  handlePostalCode,
  handleVatNumber,
  country,
  isDarkMode,
  isInitialPayment,
  handleCardNumber,
  handleExpiry,
  handleCvc,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isDark } = useDarkMode();
  const cardExpiryOptions = {
    placeholder: t("paymentUpdate.expiryDate"),
    style: {
      base: {
        color: theme.colours.mainText,
        "::placeholder": {
          color: theme.colours.altText,
          fontWeight: theme.fontWeight.regular,
        },
      },
      invalid: {
        color: theme.colours.error,
      },
    },
  };

  const dmCardExpiryOptions = {
    ...cardExpiryOptions,
    style: {
      ...cardExpiryOptions.style,
      base: {
        ...cardExpiryOptions.style.base,
        color: theme.colours.white,
      },
    },
  };

  const cardNumberOptions = {
    placeholder: t("subscriptionUpdate.cardNumber"),
    style: {
      base: {
        color: theme.colours.mainText,
        border: theme.borders.deafult,
        fontFamily: theme.typography.fontFamily,

        "::placeholder": {
          color: theme.colours.altText,
          fontWeight: theme.fontWeight.regular,
          fontFamily: theme.typography.fontFamily,
        },
      },
      invalid: {
        color: theme.colours.error,
      },
    },
  };

  const dmCardNumberOptions = {
    ...cardNumberOptions,
    style: {
      ...cardNumberOptions.style,
      base: {
        ...cardNumberOptions.style.base,
        color: theme.colours.white,

        "::placeholder": {
          color: theme.colours.altText,
          fontWeight: theme.fontWeight.regular,
          fontFamily: theme.typography.fontFamily,
        },
      },
    },
  };

  const cardCvcOptions = {
    placeholder: t("paymentUpdate.CVC"),
    style: {
      base: {
        color: theme.colours.mainText,
        fontFamily: theme.typography.fontFamily,

        "::placeholder": {
          color: theme.colours.altText,
          fontWeight: theme.fontWeight.regular,
          fontFamily: theme.typography.fontFamily,
        },
      },
      invalid: {
        color: theme.colours.error,
      },
    },
  };

  const dmCardCvcOptions = {
    ...cardCvcOptions,
    style: {
      ...cardCvcOptions.style,
      base: {
        ...cardCvcOptions.style.base,
        color: theme.colours.white,

        "::placeholder": {
          color: theme.colours.altText,
          fontWeight: theme.fontWeight.regular,
          fontFamily: theme.typography.fontFamily,
        },
      },
    },
  };

  return (
    <FormContainer>
      <MultiRowContainer>
        <IconContainer>
          <Image data-test="visa-icon" src={visaIcon} />
          <Image
            data-test="mastercard-icon"
            src={isDark ? mastercardIconDark : mastercardIcon}
          />
          <Image data-test="amex-icon" src={amexIcon} />
        </IconContainer>
      </MultiRowContainer>
      <MultiRowContainer>
        <RowContainer>
          <ElementContainer>
            <EmailElement
              data-test="email-field"
              id="email"
              type="email"
              onChange={handleEmail}
              placeholder={t("stripe.placeholders.email")}
            />
          </ElementContainer>
          {isDarkMode ? (
            <EmailIconDark data-test="email-icon-dark" style={iconStyle} />
          ) : (
            <EmailIcon data-test="email-icon" style={iconStyle} />
          )}
        </RowContainer>
      </MultiRowContainer>
      <MultiRowContainer>
        <RowContainer>
          <ElementContainer>
            <CardNumberElement
              data-test="card"
              id="cardNumber"
              options={isDarkMode ? dmCardNumberOptions : cardNumberOptions}
              onChange={handleCardNumber}
            />
          </ElementContainer>
          {isDarkMode ? (
            <CardIconDark data-test="card-icon-dark" style={iconStyle} />
          ) : (
            <CardIcon data-test="card-icon" style={iconStyle} />
          )}
        </RowContainer>
      </MultiRowContainer>
      <MultiRowContainer>
        <RowContainer>
          <ElementContainer>
            <CardExpiryElement
              data-test="expiry"
              options={isDarkMode ? dmCardExpiryOptions : cardExpiryOptions}
              onChange={handleExpiry}
            />
          </ElementContainer>
          {isDarkMode ? (
            <CalendarIconDark data-test="expiry-icon-dark" style={iconStyle} />
          ) : (
            <CalendarIcon data-test="expiry-icon" style={iconStyle} />
          )}
        </RowContainer>
        <RowContainer>
          <ElementContainer>
            <CardCvcElement
              data-test="cvc"
              options={isDarkMode ? dmCardCvcOptions : cardCvcOptions}
              onChange={handleCvc}
            />
          </ElementContainer>
          {isDarkMode ? (
            <CvcIconDark data-test="cvc-icon-dark" style={iconStyle} />
          ) : (
            <CvcIcon data-test="cvc-icon" style={iconStyle} />
          )}
        </RowContainer>
      </MultiRowContainer>
      {isInitialPayment && (
        <MultiRowContainer>
          <RowContainer>
            <ElementContainer>
              <ReadOnlyElement>
                {
                  countryData.find(
                    someCountry => someCountry.value === country.code
                  ).label
                }
              </ReadOnlyElement>
            </ElementContainer>
          </RowContainer>
          <RowContainer>
            <ElementContainer>
              <TextElement
                data-test="postal-code-field"
                id="postalCode"
                onChange={handlePostalCode}
                placeholder={t("stripe.placeholders.zip")}
              />
            </ElementContainer>
          </RowContainer>
        </MultiRowContainer>
      )}
      {isInitialPayment && country.code === "GB" && (
        <MultiRowContainer>
          <RowContainer>
            <ElementContainer>
              <TextElement
                data-test="vat-number-field"
                id="handleVatNumber"
                onChange={handleVatNumber}
                placeholder={t("stripe.placeholders.vat")}
              />
            </ElementContainer>
          </RowContainer>
        </MultiRowContainer>
      )}
    </FormContainer>
  );
};
