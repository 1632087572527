import styled, { css } from "styled-components";
import { ReactComponent as PlusIcon } from "../../static/assets/icon-plus.svg";
import { ReactComponent as MinusIcon } from "../../static/assets/icon-minus.svg";
import { ReactComponent as Arrow } from "../../static/assets/icon-arrow-right.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.paddingSM};

  ${({ isShared }) =>
    isShared &&
    css`
      height: 100%;
      min-height: 100%;
    `};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.padding};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  justify-content: space-between;
  align-items: center;
`;

export const StyledPlusIcon = styled(PlusIcon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  z-index: 97;
  color: ${({ theme }) => theme.colours.white};
`;

export const StyledMinusIcon = styled(MinusIcon)`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  z-index: 97;
  color: ${({ theme }) => theme.colours.white};
`;

export const StyledReleaseCard = styled.div`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.nodeCardWidth};
  min-width: ${({ theme }) => theme.sizing.nodeCardWidth};
  height: ${({ theme }) => theme.sizing.releaseCardHeight};

  gap: ${({ theme }) => theme.spacing.padding};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};

  padding: ${({ theme }) => theme.spacing.padding};

  display: flex;
  flex-direction: column;

  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  &:hover {
    transition: transform 0.2s;
    transform: scale(1.02);
    z-index: 98;
  }

  transform: ${({ isActive }) => (isActive ? "scale(1.02)" : "none")};

  text-decoration: none;
`;

export const ReleasesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.padding};

  justify-content: ${({ theme }) =>
    theme.isNotDesktop ? "center" : "flex-start"};
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-bottom: -${({ theme }) => theme.spacing.paddingMD};
  height: ${({ theme }) => theme.spacing.padding};
`;

export const SortSelect = styled.select`
  outline: none;
  border: none;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background-color: ${({ theme }) => theme.colours.foreground};
  color: ${({ theme }) => theme.colours.mainText};
  border: 1px solid ${({ theme }) => theme.colours.secondaryButton};
  cursor: pointer;
  height: ${({ theme }) => theme.sizing.btnHeight};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }
`;

export const FrozenGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  border-radius: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.padding};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  border: 1px solid ${({ theme }) => theme.colours.altForeground};

  p {
    color: ${({ theme }) => theme.colours.freezeButtonText};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 18px;
  }

  height: ${({ theme }) => theme.sizing.releaseCardGraphHeight};
`;

export const FrozenGraphComponent = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 12px;

  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const FrozenBar = styled.div`
  background-color: ${({ theme }) => theme.colours.freezeButtonText};

  height: 12px;
  border-radius: 12px;
  flex-grow: ${({ width }) => width};
`;

export const NonFrozenBar = styled(FrozenBar)`
  background-color: ${({ theme }) => theme.colours.freezeButtonBackground};
  flex-grow: ${({ width }) => width};
`;

export const ReleaseCardName = styled.div`
  h1 {
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${({ theme }) => theme.fontSize.largeMiddle};
  }
`;

export const ArrowIcon = styled(Arrow)`
  transform: rotate(${({ up }) => (up ? "90deg" : "270deg")});
  height: ${({ active }) => (active ? "24px" : "16px")};
  width: auto;
`;

export const SortDirectionButton = styled.button`
  display: flex;
  padding: ${({ theme }) => theme.spacing.paddingSM};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid ${({ theme }) => theme.colours.altForeground};
  width: fit-content;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  height: ${({ theme }) => theme.sizing.btnHeight};

  justify-content: center;
  align-items: center;

  cursor: pointer;

  color: ${({ theme }) => theme.colours.mainText};

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  &: active {
    background-color: ${({ theme }) => theme.colours.background};
  }

  ${({ active }) =>
    active &&
    css`
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    `}

  svg {
    color: ${({ theme }) => theme.colours.mainText};

    &:last-of-type {
      margin-left: -${({ theme }) => theme.spacing.paddingSM};
    }
  }
`;

export const SortAndFilterContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const FilterButton = styled.div`
  cursor: pointer;

  display: flex;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  height: ${({ theme }) => theme.sizing.btnHeight};

  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colours.mainText};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  width: fit-content;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  justify-content: center;
  align-items: center;

  outline: ${({ theme, active }) =>
    active ? `1px solid ${theme.colours.haloAccent}` : "none"};

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  &: active {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;

export const FilterAndSortOption = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-direction: row;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.paddingSM};
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BottomBar = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-end;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingXSM};

  p {
    font-size: ${({ theme }) => theme.fontSize.medium};
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize.smaller};
    font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  }
`;
