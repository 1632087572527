import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";
import { useFetch } from "../../../../../context/hooks";
import { routes } from "../../../../../router/routes";
import { useHistoryWithParams } from "../../../../../context/hooks/historyWithParams/useHistoryWithParams";
import {
  CATEGORY_PROVIDER_MAP,
  LATEST,
} from "../../../../../static/constants/constants";

export const useUpdateLabel = category => {
  const { t } = useTranslation();
  const history = useHistoryWithParams();
  const { patch, status, error } = useFetch();
  const { createNotification, types } = useNotifications();

  const [successLabelValue, setSuccessLabelValue] = useState(null);

  const UNDEFINED_NODE_ID = "undefined";

  useEffect(() => {
    if (status.isRejected) {
      createNotification(types.ERROR, t(`errors.updateLabel.${error.code}`));
    }
    if (status.isResolved) {
      createNotification(types.SUCCESS, "success.updateLabel");
      history.push(
        routes.RUN_VIEW.path
          .replace(":nodeId", UNDEFINED_NODE_ID)
          .replace(":runType", category)
          .replace(":label", encodeURI(encodeURIComponent(successLabelValue)))
          .replace(":runId", LATEST)
      );
    }
  }, [status.isLoading, status.isRejected, status.isResolved]);

  const patchLabel = (run, labelValue, checked) => {
    patch(
      {
        ...ENDPOINTS.EDIT_LABEL,
        path: ENDPOINTS.EDIT_LABEL.path
          .replace(":id", run.id)
          .replace(":provider", CATEGORY_PROVIDER_MAP[run.category]),
      },
      { new_label: labelValue, all_runs: checked }
    );
    setSuccessLabelValue(labelValue);
  };

  return {
    patchLabel,
    updateLabelFail: status.isRejected,
  };
};
