import styled from "styled-components";
import { useApp } from "../../context/app/app.provider";
import { toggleFullscreen } from "./hooks/useFullScreen";
import { DarkModeToggle } from "react-dark-mode-toggle-2";
import { useTheme } from "../../context/theme/theme.provider";
import { ReactComponent as Icon } from "../../static/assets/icon-full-screen.svg";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../components/tooltip/tooltip";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.padding};

  color: ${({ theme }) => theme.colours.mainText};
`;

const FullScreen = styled(Icon)`
  opacity: 0.6;
  cursor: pointer;
`;

const IconContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DropdownMenu = ({ toggleOpen }) => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();
  const { isDark, toggleDark } = useTheme();

  return (
    <Container>
      <Tooltip content={t("header.theme")}>
        <IconContainer data-tip data-for={"theme"}>
          <DarkModeToggle
            onChange={toggleDark}
            isDarkMode={isDark}
            size={64}
            data-tip
            data-for={"theme"}
          />
        </IconContainer>
      </Tooltip>
      {!isNotDesktop ? (
        <Tooltip content={t("header.fullScreen")}>
          <IconContainer>
            <FullScreen
              data-tip
              data-for={"fullscreen"}
              onClick={() => {
                toggleOpen();
                toggleFullscreen();
              }}
            />
          </IconContainer>
        </Tooltip>
      ) : null}
    </Container>
  );
};
