import React, { useEffect, useState } from "react";
import {
  ManageFormContainer,
  StyledMainContainer,
  StyledFieldContainer,
  CustomerColumnHeader,
  CustomerColumnCell,
} from "./manageCustomerForm.styles ";
import { StyledSelect } from "../../organisation/projectsPicker.styles";
import { productOptions } from "./productOptions";
import { formatCurrency } from "../../billing/subscriptionDetails/formatCurrency";
import { Stripe } from "../../../../../components/stripe/Stripe";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components";
import { useApp } from "../../../../../context/app/app.provider";
import { useModal } from "../../../../../context/modal/modal.provider";

export const UnwrappedManageCustomerForm = ({
  customer,
  index,
  currencyIsoCode,
  setUpdated,
  first,
}) => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();
  const { openModal, MODALS } = useModal();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [productSelectValue, setproductSelectValue] = useState({
    value: customer.subscription_id,
    label: customer.subscription_id,
  });

  const openDeleteCustomerModal = () =>
    openModal(MODALS.DELETE_CUSTOMER, {
      orgName: customer.name,
      orgId: customer.org_id,
      setUpdated: setUpdated,
    });

  const openDisassociateModal = () =>
    openModal(MODALS.DISASSOCIATE_CUSTOMER, {
      orgName: customer.name,
      orgId: customer.org_id,
      setUpdated: setUpdated,
    });

  const openPreviewUpdateCustomerModal = () =>
    openModal(MODALS.PREVIEW_UPDATE_CUSTOMER, {
      existingProductLabel: existingProductLabel,
      updatedProductLabel: productSelectValue ? productSelectValue.label : "",
      existingProduct: customer.subscription_id,
      orgName: customer.name,
      setUpdated: setUpdated,
      payload: {
        org_id: customer.org_id,
        product: productSelectValue.value,
      },
    });

  useEffect(() => {
    setproductSelectValue({
      value: customer.subscription_id,
      label: customer.subscription_id,
    });
  }, [customer, customer.product]);

  useEffect(() => {
    setIsSubmitting(false);
  }, [customer]);

  const findproductOption = value =>
    productOptions(t).find(option => option.value === value);

  const updateCustomer = () => {
    setIsSubmitting(true);
    openPreviewUpdateCustomerModal();
  };

  const deleteCustomer = () => {
    setIsSubmitting(true);
    openDeleteCustomerModal();
  };

  const disassociateCustomer = () => {
    setIsSubmitting(true);
    openDisassociateModal();
  };

  let existingProductLabel = "";
  if (customer.subscription_id) {
    existingProductLabel = findproductOption(customer.subscription_id)?.label;
  }

  return (
    <StyledMainContainer data-test="customer-row" isNotDesktop={isNotDesktop}>
      <ManageFormContainer
        className={"Form"}
        columns={true}
        data-testid="form-container"
        isNotDesktop={isNotDesktop}
      >
        <StyledFieldContainer
          large
          className={"Field"}
          isNotDesktop={isNotDesktop}
        >
          {first || isNotDesktop ? (
            <CustomerColumnHeader>
              {t("customer.columnHeadings.organisationName")}
            </CustomerColumnHeader>
          ) : null}
          <CustomerColumnCell key={`${customer.name}-name-div`}>
            <p data-test="name" key={`${customer.name}-name`}>
              {customer.name}
            </p>
          </CustomerColumnCell>
        </StyledFieldContainer>
        <StyledFieldContainer
          large
          className={"Field"}
          isNotDesktop={isNotDesktop}
        >
          {first || isNotDesktop ? (
            <CustomerColumnHeader>
              {t("customer.columnHeadings.editProduct")}
            </CustomerColumnHeader>
          ) : null}
          <CustomerColumnCell key={`${customer.name}-select-div`}>
            <StyledSelect
              name={"product"}
              key={`${customer.name}-select`}
              placeholder={t("customer.placeholder.product")}
              component={StyledSelect}
              data-testid={`updateProduct${index}`}
              isMulti={false}
              options={productOptions(t)}
              isClearable={false}
              classNamePrefix={"Select"}
              value={findproductOption(productSelectValue.value)}
              onChange={value => {
                setproductSelectValue(findproductOption(value.value));
              }}
            />
          </CustomerColumnCell>
        </StyledFieldContainer>
        <StyledFieldContainer
          large
          className={"Field"}
          isNotDesktop={isNotDesktop}
        >
          {first || isNotDesktop ? (
            <CustomerColumnHeader>
              {t("customer.columnHeadings.usedRemaining")}
            </CustomerColumnHeader>
          ) : null}
          <CustomerColumnCell key={`${customer.name}-product-div`}>
            <p data-test="used_product">{`${customer.used_seats} / ${
              customer.seats - customer.used_seats
            }`}</p>
          </CustomerColumnCell>
        </StyledFieldContainer>
        <StyledFieldContainer
          large
          className={"Field"}
          isNotDesktop={isNotDesktop}
        >
          {first || isNotDesktop ? (
            <CustomerColumnHeader>
              {t("customer.columnHeadings.cost")}
            </CustomerColumnHeader>
          ) : null}
          <CustomerColumnCell key={`${customer.name}-cost-div`}>
            <p data-test="cost">
              {formatCurrency(
                "en",
                currencyIsoCode,
                customer.next_payment_amount
              )}
            </p>
          </CustomerColumnCell>
        </StyledFieldContainer>
        <StyledFieldContainer
          large
          className={"Field"}
          isNotDesktop={isNotDesktop}
        >
          <Button
            className="submitButton"
            data-test={`btn-disassociateCustomer${index}`}
            danger
            onClick={disassociateCustomer}
          >
            {t("customer.button.disassociate")}
          </Button>
        </StyledFieldContainer>
        <StyledFieldContainer large className={"Field"}>
          <Button
            className="submitButton"
            data-test={`btn-deleteCustomer${index}`}
            danger
            onClick={deleteCustomer}
          >
            {t("customer.button.delete")}
          </Button>
        </StyledFieldContainer>
        <StyledFieldContainer
          large
          className={"Field"}
          isNotDesktop={isNotDesktop}
        >
          <Button
            className="submitButton"
            type="button"
            disabled={
              isSubmitting ||
              productSelectValue.value === customer.subscription_id
            }
            data-testid={`btn-updateCustomer${index}`}
            accent={true}
            onClick={updateCustomer}
          >
            {t("customer.button.save")}
          </Button>
        </StyledFieldContainer>
      </ManageFormContainer>
    </StyledMainContainer>
  );
};

export const ManageCustomerForm = props => (
  <Stripe>
    <UnwrappedManageCustomerForm {...props} />
  </Stripe>
);
