import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;
  justify-content: center;
`;

export const ErrorTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.huge};
`;

export const ErrorSupport = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const LogoContainer = styled.div`
  width: ${({ theme }) => theme.sizing.btnWidthLG};
`;
