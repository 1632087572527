import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";

export const usePreviewUpdateCustomer = () => {
  const { t } = useTranslation();
  const { get, status, error, body } = useFetch();
  const { createNotification, types } = useNotifications();

  const [preview, setPreview] = useState({});

  const getPreview = payload => {
    const path = ENDPOINTS.PREVIEW_UPDATE_CUSTOMER_ORGANISATION.path
      .replace(":product", payload.product)
      .replace(":org_id", payload.org_id);

    get({ ...ENDPOINTS.PREVIEW_UPDATE_CUSTOMER_ORGANISATION, path: path });
  };

  useEffect(() => {
    if (status.isResolved && body) setPreview(body);
    if (status.isRejected || error) createNotification(types.ERROR, t("error"));
  }, [status.isResolved, status.isRejected, error, body]);

  return {
    getPreview,
    preview,
  };
};
