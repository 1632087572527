import {
  Approved,
  ApprovedLarger,
  AutoApproved,
  AutoApprovedLarger,
  Reviewed,
  ReviewedLarger,
} from "../../../../components/modal/modals/runApproveModal/approval.styles";
import { useTranslation } from "react-i18next";
import {
  APPROVED_STATUS,
  AUTO_APPROVED_STATUS,
  REVIEWED_STATUS,
} from "../../../../static/constants/constants";

export const approvalUtils = () => {
  const { t } = useTranslation();

  const approvalIcon = (approved, large) => {
    if (approved == null) return;

    switch (approved.status) {
      case APPROVED_STATUS:
        return !large ? <Approved /> : <ApprovedLarger />;
      case AUTO_APPROVED_STATUS:
        return !large ? <AutoApproved /> : <AutoApprovedLarger />;
      case REVIEWED_STATUS:
        return !large ? <Reviewed /> : <ReviewedLarger />;
      default:
        break;
    }
  };

  const getApprovedStatus = approved => {
    if (approved == null) return;

    switch (approved.status) {
      case APPROVED_STATUS:
        return t("runApproval.approved");
      case AUTO_APPROVED_STATUS:
        return t("runApproval.autoApproved");
      case REVIEWED_STATUS:
        return t("runApproval.reviewed");
      default:
        break;
    }
  };

  const formatApproved = approved => {
    if (approved === null || !approved?.by || !approved?.date) return;

    const formattedBy = approved.by.split("@")[0];
    const date = new Date(approved.date);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const formattedDate = date.toLocaleDateString("en-UK", options);

    return t("runView.approvedBy", {
      status: getApprovedStatus(approved),
      by: formattedBy,
      date: formattedDate,
    });
  };

  return {
    approvalIcon,
    getApprovedStatus,
    formatApproved,
  };
};
