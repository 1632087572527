export const useRunDurations = () => {
  const durationsMap = runs =>
    runs.map(entry => ({
      id: entry.id,
      duration: Math.round(entry.time / 60),
    }));

  return {
    durationsMap,
  };
};
