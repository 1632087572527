import { useEffect, useState } from "react";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useFetch } from "../../../context/hooks";
import { useReleases } from "../../../context/releases/releases.provider";
import { useGetRelease } from "../../../context/releases/useGetRelease";

export const useReleaseLabels = () => {
  const { release, getRelease } = useGetRelease();
  const [releaseState, setReleaseState] = useState(release);
  const releaseLabels = releaseState ? releaseState?.labels : [];
  const [addSuccess, setAddSuccess] = useState(false);
  const [addFailure, setAddFailure] = useState(false);
  const [labels, setLabels] = useState(releaseLabels);
  const { modifyReleaseLabels } = useReleases();

  useEffect(() => {
    setReleaseState(release);
  }, [release]);

  useEffect(() => {
    if (releaseState && releaseState?.labels) setLabels(releaseState?.labels);
  }, [releaseState, releaseState?.labels, release]);

  const { patch, status, body, error } = useFetch();

  const modifyLabels = (payload, parsedRelease) => {
    const releaseId = parsedRelease ? parsedRelease.id : releaseState.id;
    const url = ENDPOINTS.RELEASE_LABELS.path.replace(":releaseId", releaseId);
    patch({ ...ENDPOINTS.RELEASE_LABELS, path: url }, { labels: payload });
  };

  const addNewLabel = (label, parsedRelease) => {
    if (parsedRelease) setReleaseState(parsedRelease);
    const newLabels = [
      ...(parsedRelease ? parsedRelease.labels : labels),
      ...label.map(e => e.value),
    ];
    modifyLabels(newLabels, parsedRelease);
  };

  const removeLabel = (label, parsedRelease) =>
    modifyLabels(
      labels.filter(l => l !== label),
      parsedRelease
    );

  useEffect(() => {
    if (status.isResolved && body) {
      setAddSuccess(true);
      setLabels(body.labels);
      if (releaseState) modifyReleaseLabels(releaseState.id, body.labels);
      if (releaseState && getRelease) getRelease(releaseState.id);
    }
    if (status.isRejected) setAddFailure(true);
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    addNewLabel,
    removeLabel,
    modifyLabels,
    setAddSuccess,
    addSuccess,
    addFailure,
    labels,
  };
};
