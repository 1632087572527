import {
  SmallText,
  StyledCode,
  SubItems,
  UrlContainer,
} from "./lighthouseSection.styles";

export const getNodeContents = node => (
  <>
    {node.nodeLabel ? <p>{node.nodeLabel}</p> : null}
    {node.snippet ? <StyledCode>{node.snippet}</StyledCode> : null}
  </>
);

export const getUrlContents = url => {
  const urlTest = /:\/\/(.*?)/;

  if (!urlTest.test(url))
    return (
      <UrlContainer>
        <p>{url}</p>
      </UrlContainer>
    );

  const display = `../${url.match(/.*\/(.*?\/.*)/)[1]}`;
  let urlDisplay = url.match(/:\/\/(.*?)\//);

  if (!urlDisplay) urlDisplay = url.match(/:\/\/(.*?)/);
  return (
    <UrlContainer>
      <p>{display}</p>
      {urlDisplay ? <SmallText>{`(${urlDisplay[1]})`}</SmallText> : null}
    </UrlContainer>
  );
};

const getIssueTypeSubItems = items => (
  <SubItems>{items.items.map(item => getUrlContents(item.url))}</SubItems>
);

const getIssueType = (item, key) => (
  <>
    {item[key]}
    {getIssueTypeSubItems(item.subItems)}
  </>
);

export const getContents = (item, key) => {
  if (!item[key]) return null;

  switch (key) {
    case "url":
      return getUrlContents(item[key]);
    case "source":
      return getUrlContents(item[key].url);
    case "sourceLocation":
      return getUrlContents(item[key].url);
    case "node":
      return getNodeContents(item[key]);
    case "relatedNode":
      return getNodeContents(item[key]);
    case "description":
      return <StyledCode noColour>{item[key]}</StyledCode>;
    case "issueType":
      return getIssueType(item, key);
    default:
      return typeof item[key] === "object" ? null : item[key];
  }
};
