import styled, { css } from "styled-components";
import { CustomLink } from "../../../components/Link/StyledLink";

export const CheckContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: ${({ theme }) => theme.isMobile && "column"};
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.isMobile && theme.spacing.paddingSM};
  z-index: 1;
  position: relative;

  align-items: center;
  padding: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
  }
`;

export const ViolationsBugWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;
`;

export const LabelWrapper = styled.div`
  display: flex;
  width: ${({ theme }) => !theme.isMobile && theme.sizing.btnWidthLG};
`;

export const CheckUrl = styled.p`
  width: ${({ isMobile }) => isMobile && "100%"};
  overflow-wrap: ${({ isMobile }) => isMobile && "break-word"};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const Label = styled.p`
  display: flex;
  width: ${({ theme }) => theme.isMobile && "100%"};
  font-size: ${({ theme }) => theme.fontSize.mediumLarge};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: left;
  align-items: center;
  color: ${({ theme }) => theme.colours.mainText};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid ${({ theme }) => theme.colours.background};
  white-space: pre-wrap;
`;

export const Section = styled.div`
  margin-top: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border: ${({ theme, dark }) =>
    dark && `2px solid ${theme.colours.background}`};
`;

export const ViolationsContainer = styled.div`
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  display: flex;
`;

export const Status = styled.div`
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  display: flex;
  flex-direction: column;
  min-width: ${({ theme }) => theme.spacing.checksStickerWidthSM};
  text-align: center;

  p {
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }
`;

export const TableContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};
  border: 1px solid ${({ theme }) => theme.colours.background};
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }) => !isMobile && "6% 15% 5% 74%"};
`;

export const TableCell = styled.div`
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  display: flex;
  flex-direction: column;

  p {
    font-size: ${({ theme }) => theme.fontSize.smallerMedium};
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }

  table {
    width: 100%;
  }

  th {
    width: 33%;
    font-size: ${({ theme }) => theme.fontSize.smallerMedium};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    text-align: left;
  }

  h {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: 1.2;
    margin: 0px 0px 0.25rem;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      table {
        display: block;

        td {
          display: inline-block;
        }
      }
      th {
        width: 100%;
      }
    `};

  table {
    margin-top: ${({ theme }) => theme.spacing.paddingSM};
  }
`;

export const BoldText = styled.b`
  font-size: ${({ theme }) => theme.fontSize.smallerMedium};
  font-weight: bold;
`;

export const Link = styled(CustomLink)`
  font-size: ${({ theme }) => theme.fontSize.smallerMedium};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  text-decoration: underline;
  color: ${({ theme }) => theme.colours.mainText};
`;

export const InsideTableCell = styled.td`
  width: ${({ isMobile }) => (isMobile ? "100%" : "33%")};

  pre {
    max-width: 100%;
    width: fit-content;
    border-radius: ${({ theme }) => theme.borders.radius};
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-all;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem 0.2rem;
  }
`;

export const InsideTable = styled.div``;

export const InsideTableHeaderCell = styled.div`
  width: ${({ isMobile }) => (isMobile ? "100%" : "33%")};
  font-size: ${({ theme }) => theme.fontSize.smallerMedium};
  font-weight: bold;
`;

export const InsideTableHeader = styled.div`
  display: flex;
`;

export const InsideTableBody = styled.div`
  display: flex;

  flex-direction: ${({ isMobile }) => isMobile && "column"};

  padding-bottom: ${({ theme }) => theme.spacing.paddingSM};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.error};
`;

export const SuccessMessage = styled.p`
  color: ${({ theme }) => theme.colours.pass};
`;

export const NoChecksMessage = styled.p`
  color: ${({ theme }) => theme.colours.mainText};
`;
