import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button/Button";
import { ButtonContainer } from "../../../components/NavigationPane/navigation/navigationParent/navigationParent.styles";
import styled from "styled-components";

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

const InsideContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const usePagination = (labelCount, getLabels, scrollRef) => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState(1);
  const totalPages = Math.ceil(labelCount / 10);

  useEffect(() => {
    getLabels(activePage);
    scrollRef?.current?.scrollIntoView(true);
  }, [activePage]);

  const PaginationComponent = () => (
    <PaginationContainer>
      <InsideContainer>
        <p>{`${t("runHistory.showingPage")}${activePage}/${totalPages}`}</p>
        <ButtonContainer>
          <Button
            onClick={() => setActivePage(activePage - 1)}
            disabled={activePage === 1}
          >
            {t("btnPrevious")}
          </Button>
          <Button
            onClick={() => setActivePage(activePage + 1)}
            disabled={activePage === totalPages}
          >
            {t("btnNext")}
          </Button>
        </ButtonContainer>
      </InsideContainer>
    </PaginationContainer>
  );

  return {
    PaginationComponent,
  };
};
