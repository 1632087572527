import { useEffect, useState } from "react";
import { useFetch } from "../../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";

export const useSubscriptionPreview = () => {
  const { get, status, error, body } = useFetch();
  const [preview, setPreview] = useState();

  const getSubscriptionPreview = (product, period) => {
    const url = `${ENDPOINTS.SUBSCRIPTION_PREVIEW.path}?product=${product}&period=${period}`;
    get({ path: url });
  };

  useEffect(() => {
    if (status.isResolved && body) setPreview(body);
  }, [status.isResolved, status.isRejected, error, body]);

  return {
    preview,
    getSubscriptionPreview,
  };
};
