import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.padding};

  overflow-y: auto;

  ${({ isShared }) =>
    isShared &&
    css`
      height: 100%;
      min-height: 100%;
    `};
`;

export const WidgetContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 95%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const HorizontalWidgetContainer = styled.div`
  display: flex;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};

  width: 100%;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isNotDesktop }) => (isNotDesktop ? "100%" : "50%")};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isNotDesktop }) => (isNotDesktop ? "100%" : "50%")};
  gap: ${({ theme }) => theme.spacing.padding};
`;
