import { useEffect, useState } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useProject } from "../../../../context/project/project.provider";

export const useInsights = () => {
  const { get, body, status, error } = useFetch();
  const [insightsError, setInsightsError] = useState(null);
  const [insightsSentences, setInsightsSentences] = useState([]);
  const [generatingInsights, setGeneratingInsights] = useState(false);
  const { project } = useProject();

  useEffect(() => {
    if (status.isResolved && body) {
      setGeneratingInsights(false);
      setInsightsSentences(body);
    }
    if (status.isRejected && error) setInsightsError(error);
  }, [status.isResolved, status.isRejected, error, body]);

  const getScenarioInsights = run => {
    setGeneratingInsights(true);
    get({
      path: ENDPOINTS.GET_INSIGHTS.path
        .replace(":projectId", project.id)
        .replace(":label", run.label)
        .replace(":category", run.category),
    });
  };

  return {
    insightsSentences,
    insightsError,
    generatingInsights,
    getScenarioInsights,
  };
};
