import { TABS } from "../../../../static/constants/constants";
import { Card } from "../../card/Card";
import { JiraBugs } from "../../components/bugs/jiraBugs";
import { CardTabs } from "../../components/cardTabs/cardTabs";
import { MetaDataTab } from "../../components/metadataTab/metadataTab";
import { useCardTabs } from "../../hooks/useCardTabs";
import { Scenarios } from "./scenarios/scenarios";

export const RightWidget = props => {
  const { run } = props;
  const { activeTabState, getTabs } = useCardTabs(run);

  const tabs = getTabs();
  const activeTab = activeTabState[0];

  return (
    <Card fillWidth fillHeight tabIndex={0}>
      <CardTabs tabs={tabs} activeTabState={activeTabState} />
      {activeTab === TABS.SCENARIOS ? <Scenarios {...props} /> : null}
      {activeTab === TABS.BUGS ? <JiraBugs {...props} /> : null}
      {activeTab === TABS.METADATA ? <MetaDataTab {...props} /> : null}
    </Card>
  );
};
