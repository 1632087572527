import {
  InfoContainer,
  StyledColumn,
  PageContainer,
  SectionContainer,
  StyledRow,
  SmallContainer,
  ErrorContainer,
  ChangePasswordContainer,
} from "./profileSettings.styles";
import { useEffect } from "react";
import { routes } from "../../router/routes";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button/Button";
import { useChangePassword } from "./hooks/changePassword";
import { useUser } from "../../context/user/user.provider";
import { Input } from "../../components/Input/Input";
import { useOrganisation } from "../../context/organisation/organisation.provider";
import { usePaymentDetails } from "../organisationSettings/tabs/billing/paymentDetails/hooks/usePaymentDetails";
import { SubscriptionDetails } from "../organisationSettings/tabs/billing/subscriptionDetails/subscriptionDetails";
import { useSubscriptionDetails } from "../organisationSettings/tabs/billing/subscriptionDetails/hooks/useSubscriptionDetails";
import { useApp } from "../../context/app/app.provider";
import { useHistoryWithParams } from "../../context/hooks/historyWithParams/useHistoryWithParams";

export const ProfileSettings = () => {
  const {
    newPassword,
    buttonHandler,
    setNewPassword,
    currentPassword,
    confirmPassword,
    setConfirmPassword,
    setCurrentPassword,
    validationError,
    validation,
    resetValidation,
  } = useChangePassword();
  const { username } = useUser();
  const { isMobile } = useApp();
  const { organisationName } = useOrganisation();
  const subscription = useSubscriptionDetails();
  const { getPaymentDetails, paymentDetails } = usePaymentDetails();

  const history = useHistoryWithParams();
  const { t } = useTranslation();

  useEffect(() => {
    getPaymentDetails();
    subscription.getSubscriptionDetails();
  }, []);

  const formError = validation.find(
    v => v.isInvalid && v.name === "passwordLength"
  )
    ? t("errors.validation.invalidMinLength", {
        name: "your new password",
        min: "12",
      })
    : validation.find(v => v.isInvalid && v.name === "inequality")
    ? t("errors.validation.inequality")
    : null;

  return (
    <PageContainer>
      <SectionContainer isMobile={isMobile}>
        <StyledColumn isMobile={isMobile}>
          <h1>{t("profile.profileDetails")}</h1>
          <InfoContainer>
            <h3>{t("profile.username")}</h3>
            <p>{username}</p>
          </InfoContainer>
          <InfoContainer>
            <h3>{t("profile.organisation")}</h3>
            <p>{organisationName}</p>
          </InfoContainer>
        </StyledColumn>
        <StyledColumn isMobile={isMobile}>
          <h1>{t("profile.changePassword")}</h1>
          <ChangePasswordContainer>
            <Input
              placeholder={t("placeholder.currentPassword")}
              onChange={e => {
                resetValidation();
                setCurrentPassword(e.target.value);
              }}
              type="password"
              value={currentPassword}
              error={validationError}
            />
            <ErrorContainer>
              {validationError &&
              validation.find(
                v => v.isInvalid && v.name === "passwordLength"
              ) ? (
                <p>{formError}</p>
              ) : null}
            </ErrorContainer>
            <Input
              placeholder={t("placeholder.newPassword")}
              onChange={e => {
                resetValidation();
                setNewPassword(e.target.value);
              }}
              type="password"
              value={newPassword}
              error={validationError}
            />
            <ErrorContainer>
              {" "}
              {validationError ? <p>{formError}</p> : null}
            </ErrorContainer>
            <Input
              placeholder={t("placeholder.confirmPassword")}
              onChange={e => {
                resetValidation();
                setConfirmPassword(e.target.value);
              }}
              type="password"
              value={confirmPassword}
              error={validationError}
            />
            <ErrorContainer>
              {" "}
              {validationError ? <p>{formError}</p> : null}
            </ErrorContainer>
          </ChangePasswordContainer>
          <Button onClick={buttonHandler}>{t("btnSubmit")}</Button>
        </StyledColumn>
      </SectionContainer>
      <SectionContainer>
        <StyledRow>
          <SmallContainer>
            <h1>{t("profile.subscriptionDetails")}</h1>
            <Button onClick={() => history.push(routes.BILLING.path)} large>
              {t("billing.updateSubscription")}
            </Button>
          </SmallContainer>
          <SubscriptionDetails
            isProfile={true}
            paymentDetails={paymentDetails}
            useSubscription={subscription}
          />
        </StyledRow>
      </SectionContainer>
    </PageContainer>
  );
};
