import { Editor } from "./Editor";
import { useEffect, useState } from "react";
import { LexicalNodes } from "./nodes/lexicalNodes";
import { TableContext } from "./plugins/TablePlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { BLANK_RICH_TEXT } from "../../static/constants/constants";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import { EditorShell, TextEditorContainer } from "./richTextEditor.styles";
import "./index.css";
import { isValidJSON } from "../../utils/json/isValidJSON";

const getInitialState = initial => {
  if (!initial || initial === "") return JSON.stringify(BLANK_RICH_TEXT);

  const json = isValidJSON(initial);
  if (json && json?.root) return initial;

  const initialJSON = BLANK_RICH_TEXT;
  initialJSON.root.children[0].children[0].text = initial;
  return JSON.stringify(initialJSON);
};

export const RichTextEditor = ({ setter, readOnly, initial }) => {
  const [editorState, setEditorState] = useState(getInitialState(initial));

  useEffect(() => {
    if (editorState !== getInitialState(initial))
      setEditorState(getInitialState(initial));
  }, [initial, editorState]);

  const initialConfig = {
    editorState: editorState,
    namespace: "Playground",
    nodes: [...LexicalNodes],
    editable: !readOnly,
    onError: error => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <TextEditorContainer id="rich-text-editor">
      {editorState !== getInitialState(initial) ? null : (
        <LexicalComposer initialConfig={initialConfig}>
          <TableContext>
            <EditorShell id="editor-shell">
              <Editor
                setter={setter}
                editorState={editorState}
                setEditorState={setEditorState}
                readOnly={readOnly}
              />
            </EditorShell>
          </TableContext>
        </LexicalComposer>
      )}
    </TextEditorContainer>
  );
};
