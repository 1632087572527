import { AppProvider } from "./app/app.provider";
import { TreeProvider } from "./tree/tree.provider";
import { AuthProvider } from "./auth/auth.provider";
import { UserProvider } from "./user/user.provider";
import { combineProviders } from "../utils/context";
import { ThemeProvider } from "./theme/theme.provider";
import { NavProvider } from "./navigation/nav.provider";
import { ConfigProvider } from "./config/config.provider";
import { ProjectProvider } from "./project/project.provider";
import { OrganisationProvider } from "./organisation/organisation.provider";
import { UserOptionsProvider } from "./admin/userOptions/userOptions.provider";
import { AdminLogsProvider } from "./admin/logs/adminLogs.provider";
import { SubscriptionPlansProvider } from "./subscriptionPlans/subscriptionPlans.provider";
import { ApiKeysProvider } from "./admin/apiKeys/apiKeys.provider";
import { PaymentIntentProvider } from "./admin/paymentIntent/paymentIntent.provider";
import { BillingUpdateProvider } from "./billingUpdate/billingUpdate.provider";
import { JiraConfigProvider } from "./jiraConfig/jiraConfig.provider";
import { ProjectLabelsProvider } from "./projectLabels/projectLabels.provider";
import { RunStatusProvider } from "./runStatus/runStatus.provider";
import { SubscriptionProvider } from "./subscription/subscription.provider";
import { ReleasesProvider } from "./releases/releases.provider";
import { ModalProvider } from "./modal/modal.provider";
import { NodeProvider } from "./nodes/nodes.provider";
import { RunSharingTokenProvider } from "../views/runView/hooks/sharedRunView/useRunSharingToken";
import { ReleaseSharingTokenProvider } from "../views/releaseView/sharedReleaseView/useReleaseSharingToken";
import { CountryProvider } from "../views/organisationSettings/tabs/billing/paymentDetails/hooks/useCountry";
import { HomeProvider } from "../views/home/context/home.context";

export const StoreProvider = combineProviders(
  AppProvider,
  NavProvider,
  UserProvider,
  AuthProvider,
  NodeProvider,
  ThemeProvider,
  ConfigProvider,
  ProjectProvider,
  UserOptionsProvider,
  OrganisationProvider,
  AdminLogsProvider,
  TreeProvider,
  SubscriptionPlansProvider,
  ApiKeysProvider,
  PaymentIntentProvider,
  BillingUpdateProvider,
  JiraConfigProvider,
  ProjectLabelsProvider,
  RunStatusProvider,
  SubscriptionProvider,
  ReleasesProvider,
  ModalProvider,
  RunSharingTokenProvider,
  ReleaseSharingTokenProvider,
  CountryProvider,
  HomeProvider
);
