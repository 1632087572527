export const MODALS = {
  DELETE_NODE: "DELETE_NODE",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  CREATE_RELEASE: "CREATE_RELEASE",
  DUPLICATE_RELEASE: "DUPLICATE_RELEASE",
  CLOSE_RELEASE: "CLOSE_RELEASE",
  EDIT_RELEASE: "EDIT_RELEASE",
  DELETE_RELEASE: "DELETE_RELEASE",
  ASSIGN_LABEL: "ASSIGN_LABEL",
  ADD_LABEL_TO_RELEASE: "ADD_LABEL_TO_RELEASE",
  RUN_APPROVE: "RUN_APPROVE",
  DELETE_INDIVIDUAL_RUN: "DELETE_INDIVIDUAL_RUN",
  DELETE_RUN_HISTORY: "DELETE_RUN_HISTORY",
  SHARE_RUN: "SHARE_RUN",
  EXPORT_RUN: "EXPORT_RUN",
  SHARE_RUN_VIEW: "SHARE_RUN_VIEW",
  JIRA_BUG: "JIRA_BUG",
  FIRST_LOGIN: "FIRST_LOGIN",
  REPORT_PREVIEW: "REPORT_PREVIEW",
  API_KEY: "API_KEY",
  UPDATE_LABEL: "UPDATE_LABEL",
  SHARED_RELEASE_VIEW: "SHARED_RELEASE_VIEW",
  SHARED_RELEASE: "SHARED_RELEASE",
  MOVE_RUN: "MOVE_RUN",
  RENAME_PROJECT: "RENAME_PROJECT",
  DELETE_PROJECT: "DELETE_PROJECT",
  CREATE_PROJECT: "CREATE_PROJECT",
  SELECT_COUNTRY: "SELECT_COUNTRY",
  DELETE_PAYMENT: "DELETE_PAYMENT",
  EDIT_PAYMENT: "EDIT_PAYMENT",
  CREATE_SUBSCRIPTION: "CREATE_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  REACTIVATE_SUBSCRIPTION: "REACTIVATE_SUBSCRIPTION",
  ENABLE_PARTNER_SUBSCRIPTION: "ENABLE_PARTNER_SUBSCRIPTION",
  DELETE_USER: "DELETE_USER",
  PREVIEW_CREATE_CUSTOMER: "PREVIEW_CREATE_CUSTOMER",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
  DISASSOCIATE_CUSTOMER: "DISASSOCIATE_CUSTOMER",
  PREVIEW_UPDATE_CUSTOMER: "PREVIEW_UPDATE_CUSTOMER",
  CREATE_EXPLORATORY_RUN: "CREATE_EXPLORATORY_RUN",
  CREATE_ACCESSIBILITY_RUN: "CREATE_ACCESSIBILITY_RUN",
  EDIT_SESSION_SUMMARY: "EDIT_SESSION_SUMMARY",
  DELETE_SESSION: "DELETE_SESSION",
  ENTRY_ATTACHMENTS: "ENTRY_ATTACHMENTS",
  INSERT_TABLE: "INSERT_TABLE",
  DELETE_ATTACHMENT: "DELETE_ATTACHMENT",
};
