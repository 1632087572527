import { runPropertiesRank } from "../../../../../static/constants/constants";

export const getTimeBetweenInSeconds = (time1, time2) => {
  const end = new Date(time2).getTime();
  const start = new Date(time1).getTime();
  return (end - start) / 1000;
};

export const getPercentageDifference = (val2, val1) => {
  const diff = Math.round(((val2 - val1) / val1) * 100);
  if (isNaN(diff)) return val1;
  if (!isFinite(diff)) return val2;
  return diff;
};

export const formatTime = time => {
  if (time < 60) {
    return `${time}s`;
  } else if (time < 3600) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}m ${seconds}s`;
  } else {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours}h ${minutes}m ${seconds}s`;
  }
};

export const filterRunProperties = (previous, latest) =>
  runPropertiesRank.filter(
    rp => JSON.stringify(previous[rp]) !== JSON.stringify(latest[rp])
  );
