import { useTheme } from "styled-components";
import { Checkmark } from "./gettingStarted.styles";

export const TickIcon = () => {
  const theme = useTheme();

  return (
    <Checkmark>
      <svg id="m-check-mark" width="30px" height="30px" viewBox="0 0 245 173">
        <g
          id="step-1"
          stroke="none"
          strokeWidth="10"
          fill="none"
          fillRule="evenodd"
          strokeOpacity="1"
        >
          <polyline
            id="checkmark"
            stroke={theme.colours.success}
            strokeWidth="38"
            points="5.640625 83.7607422 83.2539062 161.663086 238.97168 6.11328125"
          ></polyline>
        </g>
      </svg>
    </Checkmark>
  );
};
