import { useEffect, useState } from "react";
import { Modal } from "../../..";
import { useTree } from "../../../../context/tree/tree.provider";
import {
  HierarchyContainer,
  RightChevron,
  EntryContainer,
  NodeEntry,
} from "../../../../views/unassignedLabels/unassignedLabels.styles";
import { assets } from "../../../../static/assets/assets";
import { useNodeLabels } from "../../../../views/nodeView/nodeLabels/hooks/useNodeLabels";
import { useNode } from "../../../../context/nodes/nodes.provider";
import { useNotifications } from "../../../../context/hooks";
import { useTranslation } from "react-i18next";

export const AssignLabelModal = ({ closeModal, label, getLabels }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);

  const { tree } = useTree();
  const { getNodeSummary, getSuccess, resetGetSuccess, nodeSummary } =
    useNode();
  const { projectLabels, modifyLabels, addSuccess, setAddSuccess } =
    useNodeLabels();
  const { createNotification, types } = useNotifications();

  const isOpen = node => open.includes(node.id);

  const isSelected = node => selectedNode?.id === node.id;

  const assignLabel = () => {
    setSubmit(true);
    getNodeSummary(selectedNode.id);
  };

  useEffect(() => {
    if (addSuccess) {
      setAddSuccess(false);
      setSubmit(false);
      createNotification(types.SUCCESS, "Assigned Successfully");
      getLabels();
      closeModal();
    }
  }, [addSuccess]);

  useEffect(() => {
    if (projectLabels && submit && getSuccess && nodeSummary) {
      resetGetSuccess();
      setSubmit(false);
      modifyLabels([...nodeSummary.labels, label]);
    }
  }, [projectLabels, submit, getSuccess, nodeSummary]);

  const openAndSelectNode = node => {
    if (node.children && node.children.length) {
      if (isOpen(node))
        setOpen(prevOpen => prevOpen.filter(n => n !== node.id));
      else setOpen(prevOpen => [...prevOpen, node.id]);
    }
    setSelectedNode(node);
  };

  const NodeComponent = ({ node }) => (
    <EntryContainer>
      <NodeEntry
        onClick={() => openAndSelectNode(node)}
        selected={isSelected(node)}
        key={`node-entry-${node.name}`}
        level={node.level}
      >
        {node.children?.length ? (
          <RightChevron
            {...assets.icons.navChevron}
            isOpen={isOpen(node)}
            active={isSelected(node)}
          />
        ) : null}
        {node.name}
      </NodeEntry>
      {node.children && isOpen(node)
        ? node.children.map(child => (
            <NodeComponent
              key={`child-node-component-${node.name}`}
              node={child}
            />
          ))
        : null}
    </EntryContainer>
  );

  return (
    <Modal
      title={t("unassignedLabels.assignLabel.title")}
      text={t("unassignedLabels.assignLabel.supportText")}
      primary={{
        text: t("btnConfirm"),
        onClick: assignLabel,
        props: {
          disabled: selectedNode === null,
        },
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <HierarchyContainer>
        {tree.map(node => (
          <NodeComponent key={`node-component-${node.name}`} node={node} />
        ))}
      </HierarchyContainer>
    </Modal>
  );
};
