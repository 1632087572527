import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { runStatusReducer } from "./runStatus.reducer";

const initialState = { isLatest: false };

const [useHook, RunStatusProvider] = createState(
  "runStatus",
  initialState,
  runStatusReducer
);

const useRunStatus = () => {
  const { dispatch, ...runStatus } = useHook();

  const setRunStatusLatest = isLatest =>
    dispatch({ type: ACTIONS.SET_RUN_STATUS_LATEST, payload: isLatest });

  return {
    runStatus,
    setRunStatusLatest,
  };
};

export { useRunStatus, RunStatusProvider };
