import { useEffect } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useRunSharingToken } from "./useRunSharingToken";
import { CATEGORY_PROVIDER_MAP } from "../../../../static/constants/constants";

export const useEnableRunSharing = () => {
  const { post, body, status } = useFetch();
  const { setRunSharingToken } = useRunSharingToken();

  const enableRunSharing = (label, category) =>
    post(
      {
        ...ENDPOINTS.ENABLE_SHARING,
        path: ENDPOINTS.ENABLE_SHARING.path.replace(
          ":provider",
          CATEGORY_PROVIDER_MAP[category]
        ),
      },
      { label, category }
    );

  useEffect(() => {
    if ((status.isResolved, body)) setRunSharingToken(body);
  }, [status.isResolved, body]);

  return {
    enableRunSharing,
  };
};
