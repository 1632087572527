import { useEffect, useState } from "react";
import { useFetch } from "../../../../../../context/hooks";
import { useNotifications } from "../../../../../../context/hooks/useNotifications/useNotifications";
import { useTree } from "../../../../../../context/tree/tree.provider";
import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";

export const useDeleteNode = closeModal => {
  const { deleteApi, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const [success, setSuccess] = useState(false);

  const { getTree, getTreeSuccess } = useTree();

  const deleteNode = id =>
    deleteApi({
      ...ENDPOINTS.DELETE_NODE,
      path: ENDPOINTS.DELETE_NODE.path.replace(":id", id),
    });

  useEffect(() => {
    if (status.isRejected && error) {
      createNotification(
        types.ERROR,
        `errors.deleteNode.${
          Array.isArray(error.text) ? error.text[0] : error.text
        }`
      );
    }
    if (status.isResolved) {
      getTree();
      createNotification(types.SUCCESS, "success.deleteNode");
      setSuccess(true);
    }
  }, [status.isRejected, status.isResolved, body, error]);

  useEffect(() => {
    if (success && getTreeSuccess) closeModal();
  }, [success, getTreeSuccess]);

  return {
    deleteNode,
    deleteSuccess: getTreeSuccess,
  };
};
