import { useTranslation } from "react-i18next";
import { theme } from "../../../../../theme/theme";
import { useTime } from "../../../../../context/hooks/time/useTime";
import { RUN_PROPERTIES } from "../../../../../static/constants/constants";

export const useLatestRun = run => {
  const { t } = useTranslation();
  const { getReadableTimeDifference } = useTime();

  const status = run.status;
  const runStatus = t(`runCard.testevolvelighthouse.status.${status}`) || "N/A";

  const timeAgo =
    `${getReadableTimeDifference(run.updated_time)} ${t("time.ago")}` || "N/A";

  const checksPerformed = run.lighthouse_summary.checksPerformed;

  const scenarioStatuses = {
    passed: run.passed,
    failed: run.failed,
    error: run.error,
    skipped: run.skipped,
  };

  const lowestScore = {
    performance: run.lighthouse_summary.lowestPerformance,
    accessibility: run.lighthouse_summary.lowestAccessibility,
    bestPractices: run.lighthouse_summary.lowestBestPractices,
    SEO: run.lighthouse_summary.lowestSEO,
  };

  const gradientFromScore = (score, dull) => {
    if (score <= 49) {
      return dull ? "url(#dullGradientFail" : "url(#gradientFail)";
    }
    if (score <= 89) {
      return dull ? "url(#dullGradientModerate" : "url(#gradientModerate)";
    } else {
      return dull ? "url(#dullGradientPass" : "url(#gradientPass)";
    }
  };

  const performanceData = [
    {
      name: t("runView.testevolvelighthouse.scores.performance"),
      value: lowestScore.performance,
      color: gradientFromScore(lowestScore.performance),
    },
    {
      name: "Failed",
      value: 100 - lowestScore.performance,
      color: gradientFromScore(lowestScore.performance, true),
    },
  ];
  const accessibilityData = [
    {
      name: t("runView.testevolvelighthouse.scores.accessibility"),
      value: lowestScore.accessibility,
      color: gradientFromScore(lowestScore.accessibility),
    },
    {
      name: "Failed",
      value: 100 - lowestScore.accessibility,
      color: gradientFromScore(lowestScore.accessibility, true),
    },
  ];
  const bestPracticesData = [
    {
      name: t("runView.testevolvelighthouse.scores.bestPractices"),
      value: lowestScore.bestPractices,
      color: gradientFromScore(lowestScore.bestPractices),
    },
    {
      name: "Failed",
      value: 100 - lowestScore.bestPractices,
      color: gradientFromScore(lowestScore.bestPractices, true),
    },
  ];
  const seoData = [
    {
      name: t("runView.testevolvelighthouse.scores.seo"),
      value: lowestScore.SEO,
      color: gradientFromScore(lowestScore.SEO),
    },
    {
      name: "Failed",
      value: 100 - lowestScore.SEO,
      color: gradientFromScore(lowestScore.SEO, true),
    },
  ];

  const data = {
    performance: performanceData,
    accessibility: accessibilityData,
    bestPractices: bestPracticesData,
    seo: seoData,
  };

  const totalScenarios =
    (run.failed + run.pending + run.passed + run.error) / 100;
  const scenariosChartData = [
    {
      name: t("runCard.testEvolveAxe.passed"),
      value: run.passed / totalScenarios,
      color: theme.colours.success,
    },
    {
      name: t("runCard.testEvolveAxe.failed"),
      value: run.failed / totalScenarios,
      color: theme.colours.severity.high,
    },
    {
      name: t("runCard.testEvolveAxe.error"),
      value: run.error / totalScenarios,
      color: theme.colours.severity.highest,
    },
    {
      name: t("runCard.testEvolveAxe.skipped"),
      value: run.skipped / totalScenarios,
      color: theme.colours.severity.info,
    },
  ];

  const scenarios = run?.features?.map(feature => feature.scenarios).flat();

  const failedScenarios = scenarios?.filter(
    scenario => scenario.status === "failed"
  );
  const errorScenarios = scenarios?.filter(
    scenario => scenario.status === "error"
  );
  const passedScenarios = scenarios?.filter(
    scenario => scenario.status === "passed"
  );
  const skippedScenarios = scenarios?.filter(
    scenario => scenario.status === "skipped"
  );

  const runProps = RUN_PROPERTIES[run.test_type]?.map(prop => [
    run.run_properties[prop],
    prop,
  ]);

  return {
    data,
    run,
    timeAgo,
    status,
    runProps,
    runStatus,
    checksPerformed,
    scenarioStatuses,
    failedScenarios,
    passedScenarios,
    errorScenarios,
    skippedScenarios,
    scenariosChartData,
    startTime: run.start_time,
  };
};
