import styled, { css } from "styled-components";
import { ReactComponent as ChevronIcon } from "../../../../static/assets/chevron-right.svg";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.padding};
  max-width: 100%;
  min-width: 80vw;

  height: 80vh;

  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
`;

export const InsideModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  overflow: hidden;
`;

export const FocusImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.padding};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.nodeCardBackground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};

  flex-grow: 1;
  flex-shrink: 1;

  overflow: hidden;

  min-width: 60%;
  width: fit-content;

  outline: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.highlight}` : null};

  outline: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.highlight}` : null};

  position: relative;

  .react-player {
    flex-grow: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  video {
    background: ${({ theme }) => theme.colours.black};
  }
`;

export const FocusImage = styled.img`
  max-height: 100%;
  max-width: 100%;

  margin: auto;
`;

export const CarouselContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-grow: 1;
  width: 100%;
  height: ${({ theme }) => theme.sizing.carouselHeight};
  max-height: ${({ theme }) => theme.sizing.carouselHeight};
  align-items: center;
`;

export const IconPreviewContainers = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const MiniImageContainer = styled.div`
  cursor: pointer;
  outline: ${({ theme, $active }) =>
    $active ? `1px solid ${theme.colours.haloAccent}` : "none"};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.nodeCardBackground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};

  div {
    height: ${({ theme }) => theme.sizing.svgHeightLG};
    max-height: ${({ theme }) => theme.sizing.svgHeightLG};
    max-width: ${({ theme }) => theme.sizing.svgWidth169};
  }

  video {
    width: 0;
    height: 0;
    display: none;
  }

  img {
    width: auto;
    height: ${({ theme }) => theme.sizing.svgHeightLG};
  }

  min-width: fit-content;
`;

export const MiniImage = styled.img`
  width: auto;
  height: ${({ theme }) => theme.sizing.svgHeightLG};
`;

export const PreviousNextIconContainer = styled.button`
  display: flex;
  height: ${({ theme }) => theme.sizing.circleSize};

  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.nodeCardBackground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  color: ${({ theme }) => theme.colours.mainText};

  cursor: pointer;

  outline: ${({ theme }) =>
    theme.isDark ? `1px solid ${theme.colours.highlight}` : null};
  border: none;

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colours.background};
    `}
`;

export const PreviousIcon = styled(ChevronIcon)`
  cursor: pointer;
  transform: rotate(90deg)};

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colours.highlight};
    `}
`;

export const NextIcon = styled(ChevronIcon)`
  cursor: pointer;
  transform: rotate(270deg)};

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colours.highlight};
    `}
`;

export const DetailsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  color: ${({ theme }) => theme.colours.mainText};
  width: 100%;
  justify-content: space-between;
  align-items: center;

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding: ${({ theme }) => theme.spacing.paddingXXSM};

  p {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DetailsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.nodeCardBackground};
  border-radius: ${({ theme }) => theme.borders.radiusSM};
  height: ${({ theme }) => theme.sizing.inputHeight};
  width: ${({ theme }) => theme.sizing.inputHeight};
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  color: ${({ theme }) => theme.colours.mainText};
  cursor: pointer;

  outline: none;
  border: none;

  svg {
    height: 100%;
    width: auto;
  }

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  margin: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const FakeButton = styled.div`
  width: ${({ theme }) => theme.sizing.entryDetailsHeight};
`;
