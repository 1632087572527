import {
  ButtonContainer,
  ModalContainer,
  StyledModal,
  ScreenCover,
} from "./styles";
import { Button } from "..";
import { useApp } from "../../context/app/app.provider";
import { useEffect } from "react";
import { useModal } from "../../context/modal/modal.provider";

const ESCAPE = "Escape";
const KEYDOWN = "keydown";

export const Modal = ({ secondary, primary, title, text, children, size }) => {
  const { closeModal } = useModal();
  const { isMobile } = useApp();

  const handleKeyboard = ({ key }) => {
    if (key === ESCAPE) closeModal();
  };

  useEffect(() => {
    document.addEventListener(KEYDOWN, handleKeyboard, true);

    return () => document.removeEventListener(KEYDOWN, handleKeyboard);
  }, []);

  return (
    <>
      <ScreenCover data-test="screen-wrapper" />
      <ModalContainer isMobile={isMobile}>
        <StyledModal
          data-test="modal"
          size={isMobile ? null : size}
          isMobile={isMobile}
        >
          <h1 data-test="modal-title">{title}</h1>
          {text ? <p data-test="modal-text">{text}</p> : null}
          {children ? children : null}
          <ButtonContainer isMobile={isMobile}>
            {secondary && secondary.onClick ? (
              <Button
                data-test="modal-secondary-btn"
                secondary
                onClick={secondary.onClick}
                {...secondary.props}
              >
                {secondary.text}
              </Button>
            ) : null}
            {primary && primary.onClick ? (
              <Button
                data-test="modal-primary-btn"
                primary
                disabled={primary.disabled}
                onClick={primary.onClick}
                {...primary.props}
              >
                {primary.text}
              </Button>
            ) : null}
          </ButtonContainer>
        </StyledModal>
      </ModalContainer>
    </>
  );
};
