import React, { useRef } from "react";
import { useSignUp } from "./hooks/useSignUp";
import { useTranslation } from "react-i18next";
import {
  Button,
  Message,
  Divider,
  Input,
  CenteredContainer,
} from "../../components";
import {
  CompanyLogo,
  ProductLogo,
  TermsMessage,
  FormContainer,
  ReportsMessage,
  ErrorContainer,
  SignInContainer,
  StyledContainer,
  SuccessContainer,
  PasswordContainer,
} from "../signIn/signIn.styles";
import {
  TERMS_URL,
  SUPPORT_EMAIL,
  PRIVACY_POLICY_URL,
} from "../../static/constants/constants";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { CustomLink } from "../../components/Link/StyledLink";
import { useConfig } from "../../context/config/config.provider";

const ORG_ID_MAX_LENGTH = "63";

export const SignUp = () => {
  const formRef = useRef(null);
  const { t } = useTranslation();
  const { config } = useConfig();

  const {
    orgId,
    error,
    onFocus,
    orgIdRef,
    orgNameRef,
    emailRef,
    isLoading,
    formErrors,
    formAction,
    captchaRef,
    onEmailChange,
    signupSuccess,
    onOrgIdChange,
    onOrgNameChange,
  } = useSignUp(formRef);

  const handleSubmit = async e => {
    e.preventDefault();
    formAction(e);
  };

  return (
    <SignInContainer>
      <StyledContainer>
        <ProductLogo />
        {signupSuccess ? (
          <SuccessContainer>
            <h1 data-test="success-message">{t("setPassword.success")}</h1>
            <p>{t("setPassword.successMessage1")}</p>
            <p>{t("setPassword.successMessage2")}</p>
            <p>
              {t("setPassword.successMessage3")}{" "}
              <CustomLink
                accent
                external
                nounderline
                to={`mailto:${SUPPORT_EMAIL}`}
              >
                {t("setPassword.contactUs")}
              </CustomLink>
            </p>
          </SuccessContainer>
        ) : (
          <>
            <br />
            <h1>{t("signin.signupTitle")}</h1>
            {error ? (
              <Message error>{t(`errors.signup.${error.code}`)}</Message>
            ) : null}
            <ErrorContainer>
              {formErrors?.map((formError, id) =>
                formError.isInvalid ? (
                  <Message key={`signup-error-${id}`} error>
                    {formError.text}
                  </Message>
                ) : null
              )}
            </ErrorContainer>
            <FormContainer>
              <form onSubmit={handleSubmit} ref={formRef}>
                {config.hCaptchaSiteKey ? (
                  <HCaptcha
                    size="invisible"
                    ref={captchaRef}
                    sitekey={config.hCaptchaSiteKey}
                    onVerify={() => {}}
                  />
                ) : null}
                <Input
                  type="text"
                  id="orgId"
                  name="orgId"
                  aria-label="orgId"
                  label={t("signin.orgLabel")}
                  data-test="organisation-id-input"
                  key="orgId"
                  onChange={e => onOrgIdChange(e, formRef)}
                  value={orgId?.toLowerCase()}
                  onFocus={onFocus}
                  maxLength={ORG_ID_MAX_LENGTH}
                  placeholder={t("signin.placeholders.orgID")}
                  inputRef={orgIdRef}
                />
                <ReportsMessage>
                  {t("signup.reportsMessage")}
                  <a>
                    {orgId}
                    {t("signup.urlSuffix")}
                  </a>
                </ReportsMessage>
                <Input
                  type="text"
                  id="orgName"
                  name="orgName"
                  aria-label="orgName"
                  data-test="organisation-name-input"
                  label={t("signin.organisationName")}
                  onFocus={onFocus}
                  onChange={e => onOrgNameChange(e, formRef)}
                  placeholder={t("signin.placeholders.name")}
                  inputRef={orgNameRef}
                />
                <PasswordContainer>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    aria-label="email"
                    data-test="email-input"
                    label={t("signin.email")}
                    onFocus={onFocus}
                    onChange={e => onEmailChange(e, formRef)}
                    placeholder={t("signin.placeholders.email")}
                    inputRef={emailRef}
                  />
                </PasswordContainer>
                <TermsMessage>
                  {t("signin.terms1")}
                  <a target="_blank" rel="noreferrer" href={PRIVACY_POLICY_URL}>
                    {t("signin.privacyPolicy")}
                  </a>
                  {t("signin.terms2")}
                  <a target="_blank" rel="noreferrer" href={TERMS_URL}>
                    {t("signin.termsAndConditions")}
                  </a>
                </TermsMessage>
                <CenteredContainer fullWidth>
                  <Button
                    primary
                    // disabled={formErrors.length}
                    loading={isLoading}
                    loader={true}
                    data-test="signUp-button"
                    type="submit"
                    value="Submit"
                  >
                    {t("signin.signUp")}
                  </Button>
                  <Divider>{t("signin.or")}</Divider>
                  <Button secondary to="/signin" data-test="sign-in-button">
                    {t("signin.signIn")}
                  </Button>
                </CenteredContainer>
              </form>
            </FormContainer>
          </>
        )}
        <CompanyLogo />
      </StyledContainer>
    </SignInContainer>
  );
};
