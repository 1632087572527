export const assets = {
  icons: {
    navChevron: {
      fill: "currentColor",
      viewBox: "0 0 30 30",
      d: "M 27 18 L 27 20 C 27 20.386719 26.777344 20.738281 26.429688 20.902344 C 26.078125 21.070312 25.664062 21.019531 25.367188 20.773438 L 15 12.292969 L 4.632812 20.773438 C 4.335938 21.019531 3.921875 21.070312 3.570312 20.902344 C 3.222656 20.738281 3 20.386719 3 20 L 3 18 C 3 17.699219 3.136719 17.414062 3.367188 17.226562 L 14.367188 8.226562 C 14.734375 7.925781 15.265625 7.925781 15.632812 8.226562 L 26.632812 17.226562 C 26.863281 17.414062 27 17.699219 27 18 Z M 27 18 ",
    },
    home: {
      fill: "none",
      viewBox: "0 0 24 24",
    },
  },
};
