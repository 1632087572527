import mastercardIcon from "../../../../../static/assets/icon-mastercard.png";
import mastercardIconDark from "../../../../../static/assets/icon-mastercard-dark.png";
import visaIcon from "../../../../../static/assets/icon-visa.png";
import amexIcon from "../../../../../static/assets/icon-amex.png";
import { useTheme } from "styled-components";
import { Image } from "../billingTab.styles";
import { useTranslation } from "react-i18next";

const CARD_TYPES = {
  VISA: "visa",
  MASTERCARD: "mastercard",
  AMEX: "amex",
};

export const CardIcon = ({ cardType }) => {
  const { isDark } = useTheme();
  const { t } = useTranslation();

  const cardSwitch = cardType => {
    if (cardType) {
      switch (cardType) {
        case CARD_TYPES.VISA:
          return <Image data-test="visa-icon" src={visaIcon} />;
        case CARD_TYPES.MASTERCARD:
          return (
            <Image
              data-test="mastercard-icon"
              src={isDark ? mastercardIconDark : mastercardIcon}
            />
          );
        case CARD_TYPES.AMEX:
          return <Image data-test="amex-icon" src={amexIcon} />;
        default:
          return { cardType };
      }
    } else {
      return t("subscriptionInfo.n/a");
    }
  };

  return cardSwitch(cardType);
};
