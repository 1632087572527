import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { ListNode, $isListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import {
  $isParentElementRTL,
  $getSelectionStyleValueForProperty,
  $patchStyleText,
} from "@lexical/selection";
import {
  $findMatchingParent,
  mergeRegister,
  $getNearestNodeOfType,
  $isEditorIsNestedEditor,
} from "@lexical/utils";
import {
  $isRangeSelection,
  $isElementNode,
  CAN_REDO_COMMAND,
  $getSelection,
  $isRootOrShadowRoot,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  KEY_MODIFIER_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { $isTableNode, $isTableSelection } from "@lexical/table";
import { getSelectedNode } from "../../utils/getSelectedNode";
import useModal from "../../hooks/useModal";
import DropdownColorPicker from "../../ui/DropdownColorPicker";
import { sanitizeUrl } from "../../utils/url";
import FontSize from "./fontSize";

import { ReactComponent as IconBold } from "./icons/type-bold.svg";
import { ReactComponent as IconItalic } from "./icons/type-italic.svg";
import { ReactComponent as IconUnderline } from "./icons/type-underline.svg";
import { ReactComponent as IconFontColour } from "./icons/font-color.svg";
import { ReactComponent as IconBackgroundColour } from "./icons/bg-color.svg";

import { ReactComponent as IconUndo } from "./icons/arrow-counterclockwise.svg";
import { ReactComponent as IconRedo } from "./icons/arrow-clockwise.svg";
import { ReactComponent as IconLink } from "./icons/link.svg";

import { ReactComponent as IconStrikethrough } from "./icons/type-strikethrough.svg";

import { PLATFORM_MAC } from "../../../../static/constants/constants";
import { ToolBar, ToolbarButton } from "./toolbar.styles";
import { BlockFormatDropDown } from "./components/blockFormatDropdown";
import { blockTypeToBlockName } from "./utils/blockTypeToBlockName";
import { ElementFormatDropdown } from "./components/elementFormatDropdown";
import { Divider } from "./components/divider";
import { InsertDropdown } from "./components/insertDropdown";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

const IS_APPLE = (navigator?.userAgentData?.platform || navigator?.platform)
  ?.toUpperCase()
  ?.includes(PLATFORM_MAC);

export default function ToolbarPlugin({ setIsLinkEditMode }) {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] = useState("paragraph");
  const [rootType, setRootType] = useState("root");

  const [fontSize, setFontSize] = useState("15px");
  const [fontColor, setFontColor] = useState("default");
  const [bgColor, setBgColor] = useState("default");
  const [elementFormat, setElementFormat] = useState("left");
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [modal, showModal] = useModal();
  const [isRTL, setIsRTL] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [isImageCaption, setIsImageCaption] = useState(false);
  const theme = useTheme();

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
        const rootElement = activeEditor.getRootElement();
        setIsImageCaption(
          !!rootElement?.parentElement?.classList.contains(
            "image-caption-container"
          )
        );
      } else {
        setIsImageCaption(false);
      }

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsRTL($isParentElementRTL(selection));

      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) setIsLink(true);
      else setIsLink(false);

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) setRootType("table");
      else setRootType("root");

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type);
          }
        }
      }
      setFontColor(
        $getSelectionStyleValueForProperty(
          selection,
          "color",
          theme.colours.mainText
        )
      );
      setBgColor(
        $getSelectionStyleValueForProperty(
          selection,
          "background-color",
          theme.colours.foreground
        )
      );

      let matchingParent;
      if ($isLinkNode(parent)) {
        matchingParent = $findMatchingParent(
          node,
          parentNode => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }

      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent?.getFormatType()
          : $isElementNode(node)
          ? node?.getFormatType()
          : parent?.getFormatType() || "left"
      );
    }
    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      setFontSize(
        $getSelectionStyleValueForProperty(selection, "font-size", "15px")
      );
    }
  }, [activeEditor, editor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor);
        $updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    activeEditor.getEditorState().read(() => {
      $updateToolbar();
    });
  }, [activeEditor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener(editable => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      activeEditor.registerCommand(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [$updateToolbar, activeEditor, editor]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      payload => {
        const event = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === "KeyK" && (ctrlKey || metaKey)) {
          event.preventDefault();
          let url;
          if (!isLink) {
            setIsLinkEditMode(true);
            url = sanitizeUrl("https://");
          } else {
            setIsLinkEditMode(false);
            url = null;
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      activeEditor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: "historic" } : {}
      );
    },
    [activeEditor]
  );

  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ color: value }, skipHistoryStack);
    },
    [applyStyleText]
  );

  const onBgColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ "background-color": value }, skipHistoryStack);
    },
    [applyStyleText]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
      activeEditor.dispatchCommand(
        TOGGLE_LINK_COMMAND,
        sanitizeUrl("https://")
      );
    } else {
      setIsLinkEditMode(false);
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [activeEditor, isLink, setIsLinkEditMode]);

  const canViewerSeeInsertDropdown = !isImageCaption;

  return (
    <ToolBar>
      <ToolbarButton
        disabled={!canUndo || !isEditable}
        aria-label={t("richTextEditor.toolbar.undoAriaLabel")}
        onClick={() => activeEditor.dispatchCommand(UNDO_COMMAND, undefined)}
        title={t(
          IS_APPLE
            ? "richTextEditor.toolbar.undoCommandApple"
            : "richTextEditor.toolbar.undoCommand"
        )}
      >
        <IconUndo />
      </ToolbarButton>
      <ToolbarButton
        type="button"
        disabled={!canRedo || !isEditable}
        aria-label={t("richTextEditor.toolbar.redoAriaLabel")}
        onClick={() => activeEditor.dispatchCommand(REDO_COMMAND, undefined)}
        title={t(
          IS_APPLE
            ? "richTextEditor.toolbar.redoCommandApple"
            : "richTextEditor.toolbar.redoCommand"
        )}
      >
        <IconRedo />
      </ToolbarButton>
      <Divider />
      {blockType in blockTypeToBlockName && activeEditor === editor && (
        <>
          <BlockFormatDropDown
            disabled={!isEditable}
            blockType={blockType}
            rootType={rootType}
            editor={activeEditor}
          />
          <Divider />
        </>
      )}
      <>
        <Divider />
        <FontSize
          selectionFontSize={fontSize.slice(0, -2)}
          editor={activeEditor}
          disabled={!isEditable}
        />
        <Divider />
        <ToolbarButton
          type="button"
          active={isBold}
          disabled={!isEditable}
          onClick={() =>
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
          }
          title={t(
            IS_APPLE
              ? "richTextEditor.toolbar.boldCommandApple"
              : "richTextEditor.toolbar.boldCommand"
          )}
          aria-label={t(
            IS_APPLE
              ? "richTextEditor.toolbar.boldAriaLabelApple"
              : "richTextEditor.toolbar.boldAriaLabel"
          )}
        >
          <IconBold />
        </ToolbarButton>
        <ToolbarButton
          type="button"
          active={isItalic}
          disabled={!isEditable}
          onClick={() =>
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
          }
          title={t(
            IS_APPLE
              ? "richTextEditor.toolbar.italicCommandApple"
              : "richTextEditor.toolbar.italicCommand"
          )}
          aria-label={t(
            IS_APPLE
              ? "richTextEditor.toolbar.italicAriaLabelApple"
              : "richTextEditor.toolbar.italicAriaLabel"
          )}
        >
          <IconItalic />
        </ToolbarButton>
        <ToolbarButton
          type="button"
          active={isUnderline}
          disabled={!isEditable}
          onClick={() =>
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
          }
          title={t(
            IS_APPLE
              ? "richTextEditor.toolbar.underlineCommandApple"
              : "richTextEditor.toolbar.underlineCommand"
          )}
          aria-label={t(
            IS_APPLE
              ? "richTextEditor.toolbar.underlineAriaLabelApple"
              : "richTextEditor.toolbar.underlineAriaLabel"
          )}
        >
          <IconUnderline />
        </ToolbarButton>
        <ToolbarButton
          type="button"
          disabled={!isEditable}
          active={isStrikethrough}
          onClick={() =>
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
          }
          title={t("richTextEditor.toolbar.strikethrough")}
          aria-label={t("richTextEditor.toolbar.strikethroughAriaLabel")}
        >
          <IconStrikethrough />
        </ToolbarButton>
        <ToolbarButton
          type="button"
          active={isLink}
          disabled={!isEditable}
          onClick={insertLink}
          title={t("richTextEditor.toolbar.link")}
          aria-label={t("richTextEditor.toolbar.link")}
        >
          <IconLink />
        </ToolbarButton>
        <DropdownColorPicker
          color={fontColor}
          Icon={IconFontColour}
          disabled={!isEditable}
          onChange={onFontColorSelect}
          buttonClassName="toolbar-item color-picker"
          title={t("richTextEditor.toolbar.textColourTitle")}
          buttonAriaLabel={t("richTextEditor.toolbar.textColourAriaLabel")}
        />
        <DropdownColorPicker
          color={bgColor}
          disabled={!isEditable}
          onChange={onBgColorSelect}
          Icon={IconBackgroundColour}
          title={t("richTextEditor.toolbar.backgroundColourTitle")}
          buttonAriaLabel={t(
            "richTextEditor.toolbar.backgroundColourAriaLabel"
          )}
        />
        {canViewerSeeInsertDropdown && (
          <>
            <Divider />
            <InsertDropdown
              editor={activeEditor}
              isEditable={isEditable}
              showModal={showModal}
            />
          </>
        )}
      </>
      <Divider />
      <ElementFormatDropdown
        disabled={!isEditable}
        value={elementFormat}
        editor={activeEditor}
        isRTL={isRTL}
      />
      {modal}
    </ToolBar>
  );
}
