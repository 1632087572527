import { useEffect } from "react";
import { useFetch } from "../../hooks";
import { createState } from "../../../utils/context";
import { UserOptionsReducer } from "./userOptions.reducer";
import { ACTIONS } from "../../../static/constants/actions";

const initialState = {
  roles: { global: [], project: [] },
  projects: [],
};

const [useHook, UserOptionsProvider] = createState(
  "userOptions",
  initialState,
  UserOptionsReducer
);

const useUserOptions = () => {
  const { roles, projects, dispatch } = useHook();
  const { get, body, status } = useFetch();

  useEffect(() => {
    if (status.isResolved) setUserOptions(body);
  }, [status.isResolved]);

  const setUserOptions = payload =>
    dispatch({ type: ACTIONS.GET_USER_OPTIONS_SUCCESS, payload: payload });

  const getUserOptions = () => get({ path: "/api/user-options" });

  return {
    roles,
    projects,
    getUserOptions,
    availableRoles: roles,
    userOptionsSuccess: status.isResolved,
  };
};

export { useUserOptions, UserOptionsProvider };
