import styled from "styled-components";
import { readableColor } from "polished";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.spacing.paddingXSM}
    ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  overflow-x: auto;
`;

export const StyledRow = styled.tr`
  cursor: pointer;
  outline: ${({ isSelected, theme }) =>
    isSelected ? `2px solid ${theme.colours.haloAccent}` : "none"};

  &:hover {
    transition: transform 0.2s;
    outline: ${({ theme, isSelected }) =>
      `${isSelected ? "2px" : "1px"} solid ${theme.colours.haloAccent}`};
  }
`;

export const BannerCell = styled.td`
  colspan: 100%;
  text-align: center !important;
  vertical-align: middle;
  padding: ${({ theme }) => theme.sizing.paddingMD} 0 !important;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

export const StyledBanner = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colours.mainText};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  width: fit-content;
  font-size: ${({ theme }) => theme.fontSize.mediumLG};
  background-color: ${({ theme }) => theme.colours.background};
  padding: ${({ theme }) => theme.spacing.padding};
  border: 1px ${({ theme }) => theme.colours.haloAccentLight} solid;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  gap: ${({ theme }) => theme.spacing.paddingSM};
  margin: 0 ${({ theme }) => theme.spacing.padding};
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colours.foreground};
  width: 100%;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  color: ${({ theme }) => readableColor(theme.colours.background)};
  border-spacing: 0;

  th {
    letter-spacing: 0.5px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 20px;
  }

  td,
  th {
    text-align: left;
    padding: ${({ theme }) => theme.spacing.padding};
  }

  tr {
    border-radius: ${({ theme }) => theme.borders.cardRadius};
  }

  tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => readableColor(theme.colours.background)};
    border-radius: ${({ theme }) => theme.borders.cardRadius};

    th:first-child,
    td:first-child {
      border-bottom-left-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:last-child,
    td:last-child {
      border-bottom-right-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:first-child,
    td:first-child {
      border-top-left-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:last-child,
    td:last-child {
      border-top-right-radius: ${({ theme }) => theme.borders.cardRadius};
    }
  }
`;

export const FlexTd = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
`;

export const RunPropertyContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-wrap: wrap;
`;

export const DurationContainer = styled.div`
  display: flex;
`;

export const FrozenTd = styled.td`
  margin-right: -${({ theme }) => theme.spacing.paddingSM};
  width: 0;
`;

export const FrozenTh = styled.th`
  margin-right: -${({ theme }) => theme.spacing.paddingSM};
  width: 0;
`;
