import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useOrganisationUsers } from "../../organisationUsers.provider";

export const useUserStatus = () => {
  const { status, patch } = useFetch();
  const { createNotification, types } = useNotifications();
  const { getOrganisationUsers } = useOrganisationUsers();

  const patchUserStatus = (userId, payload) =>
    patch(
      {
        ...ENDPOINTS.USER_STATUS,
        path: ENDPOINTS.USER_STATUS.path.replace(":id", userId),
      },
      { active: payload }
    );

  useEffect(() => {
    if (status.isResolved) {
      createNotification(types.SUCCESS, "settings.organisation.successfulEdit");
      getOrganisationUsers();
    }
  }, [status.isResolved]);

  return {
    patchUserStatus,
  };
};
