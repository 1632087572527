import { useTranslation } from "react-i18next";
import {
  STATUSES,
  TEST_CATEGORIES,
} from "../../../../static/constants/constants";
import {
  LeftContainer,
  Spacer,
  StyledCautionIcon,
  StyledScenarioContainer,
  StyledScenarioHeader,
  StyledStatus,
  StyledStepsContainer,
  StyledTagsContainer,
} from "../testevolveRunReport.styles";
import { Step } from "../step/step";
import { Bug } from "../../components/bug";
import { BugContainer } from "../../components/bug.styles";

export const Scenario = ({
  lighthouse,
  axe,
  scenario,
  uuid,
  isDark,
  JiraBugButton,
  feature,
  isMobile,
  scenarioIndex,
  category,
  featureIndex,
  scenarioSentences,
  generatingInsights,
  insightsError,
}) => {
  const { t } = useTranslation();

  const bugs = scenario?.jira;

  const StatusLabel = () => {
    const status =
      scenario.status.charAt(0).toUpperCase() + scenario.status.slice(1);
    return (
      <StyledStatus>
        {scenario.attempts && <StyledCautionIcon />}
        {status}
        {scenario.attempts && (
          <>
            {t("runReport.status.after")}
            {scenario.attempts}
            {t("runReport.status.attempts")}
          </>
        )}
      </StyledStatus>
    );
  };

  return (
    <StyledScenarioContainer
      key={uuid()}
      data-test={`${scenarioIndex}-${scenario.name}`}
      isMobile={isMobile}
      className="unique-feature-scenario"
    >
      <StyledTagsContainer isDark={isDark}>
        {scenario.tags &&
          scenario.tags.map(tag => (
            <p data-test="scenario-tag" key={uuid()}>
              {tag}
            </p>
          ))}
        <Spacer />
      </StyledTagsContainer>
      <StyledScenarioHeader status={scenario.status}>
        <LeftContainer>
          <StatusLabel />
          <h3 data-test={scenario.name}>{scenario.name}</h3>
        </LeftContainer>
        {!isMobile &&
          scenario.status !== STATUSES.PASSED &&
          category === TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL && (
            <JiraBugButton
              data={scenario}
              feature={feature}
              scenario={scenario}
              category={TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL}
            />
          )}
      </StyledScenarioHeader>
      {isMobile &&
        category === TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL &&
        scenario.status !== STATUSES.PASSED && (
          <JiraBugButton
            data={scenario}
            feature={feature}
            scenario={scenario}
            category={TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL}
          />
        )}
      {bugs?.bug_list?.length ? (
        <BugContainer data-test="bug-container">
          <h3>{t("runReport.bugTitle")}</h3>
          {bugs?.bug_list?.map(bug => (
            <Bug key={bug.issue_key} base_url={bugs.base_url} {...bug} />
          ))}
        </BugContainer>
      ) : null}
      <StyledStepsContainer>
        {scenario && scenario?.steps
          ? scenario?.steps?.map((step, stepIndex) => (
              <Step
                step={step}
                key={`step-${stepIndex}`}
                isMobile={isMobile}
                stepIndex={stepIndex}
                axe={axe}
                isDark={isDark}
                uuid={uuid}
                lighthouse={lighthouse}
                JiraBugButton={JiraBugButton}
                scenario={scenario}
                feature={feature}
                scenarioIndex={`${featureIndex}-${scenarioIndex}`}
                scenarioSentences={scenarioSentences}
                generatingInsights={generatingInsights}
                insightsError={insightsError}
              />
            ))
          : null}
      </StyledStepsContainer>
    </StyledScenarioContainer>
  );
};
