import styled from "styled-components";
import { SvgIcon } from "../../components/icon/icon";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const HorizontalContainer = styled.div`
  display: flex;
  height: calc(100% - 72px);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  padding: ${({ theme }) => theme.spacing.padding};
`;

export const HierarchyContainer = styled.div`
  display: flex;
  max-height: 80%;
  height: 25vh;
  width: 100%;
  overflow: auto;
`;

export const NodeEntry = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.sizing.dropdownRowHeight};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:hover {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};
  }

  background-color: ${({ selected, theme }) =>
    selected ? theme.colours.haloAccent : theme.colours.foreground};
  color: ${({ selected, theme }) =>
    selected ? theme.colours.white : theme.colours.mainText};
`;

export const EntryContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing.padding};
  width: calc(100% - 16px);
`;

export const RightChevron = styled(SvgIcon)`
  height: ${({ theme }) => theme.sizing.svgHeightSM};
  width: auto;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(90deg)")};
  transition: ${({ theme }) => theme.transitionTime.fast};
  color: ${({ theme }) => theme.colours.mainText};
  opacity: ${({ active }) => (active ? "100%" : "50%")};
  margin-right: ${({ theme }) => theme.spacing.paddingSM};
`;

export const DeleteRunText = styled.p`
  text-align: center;
  line-height: 24px;
`;

export const RunPropertiesContainer = styled.div`
  gap: ${({ theme }) => theme.spacing.paddingSM};
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  height: ${({ theme }) => theme.sizing.runPropertiesHeight};
`;

export const RunProperty = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  border-radius: ${({ theme }) => theme.borders.radiusLG};
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colours.mainText};

  svg {
    height: ${({ theme }) => theme.sizing.iconSize};
    width: ${({ theme }) => theme.sizing.iconSize};
  }
`;
