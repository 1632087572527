import { useTranslation } from "react-i18next";
import { Modal } from "../../modal";
import { Input } from "../../../Input/Input";
import { ErrorMessage } from "../../../../views/projectView/hooks/styles";
import { useState } from "react";
import { useRenameProject } from "../../../../views/projectView/hooks/useRenameProject";

export const RenameProjectModal = ({ closeModal, project }) => {
  const { t } = useTranslation();
  const [formError, setFormError] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const { renameProject } = useRenameProject(project);

  const handleClick = () => {
    if (nameInput.length < 1 || nameInput.length > 50) setFormError(true);
    else renameProject(nameInput);
  };

  return (
    <Modal
      title={t("projectView.renameProject.title")}
      text={t("projectView.renameProject.supportingText")}
      primary={{
        text: t("btnRename"),
        onClick: handleClick,
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <Input
        autoFocus={true}
        onChange={e => setNameInput(e.target.value)}
        value={nameInput}
      />
      {formError ? (
        <ErrorMessage>
          {t("projectView.createProject.validationError")}
        </ErrorMessage>
      ) : (
        <p> </p>
      )}
    </Modal>
  );
};
