import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";
import { useProject } from "../../../../../context/project/project.provider";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch } from "../../../../../context/hooks";

export const usePatchProjectRoles = getProjectUsers => {
  const { t } = useTranslation();
  const { project } = useProject();
  const { createNotification, types } = useNotifications();
  const { status, origin, error, patch } = useFetch();

  const patchProjectRole = (userId, payload) =>
    patch(
      {
        ...ENDPOINTS.USER_PROJECT_ROLES,
        path: ENDPOINTS.USER_PROJECT_ROLES.path.replace(":id", userId),
      },
      payload,
      { "X-PROJECT-ID": project.id }
    );

  useEffect(() => {
    if (status.isResolved) {
      createNotification(
        types.SUCCESS,
        t("settings.organisation.successfulEdit")
      );
      getProjectUsers();
    }

    if (status.isRejected && error)
      createNotification(types.ERROR, t("errors.default"));
  }, [status.isResolved, status.isRejected, origin, error]);

  return {
    patchProjectRole,
  };
};
