import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";

export const usePreviewCustomer = closeModal => {
  const { t } = useTranslation();
  const { get, status, error, body } = useFetch();
  const { createNotification, types } = useNotifications();

  const [preview, setPreview] = useState({});

  const getPreview = payload => {
    payload.product = payload.product?.value;
    const path = ENDPOINTS.PREVIEW_CREATE_CUSTOMER_ORGANISATION.path
      .replace(":product", payload.product)
      .replace(":org_id", payload.orgId)
      .replace(":name", payload.orgName);

    get({ ...ENDPOINTS.PREVIEW_CREATE_CUSTOMER_ORGANISATION, path: path });
  };

  useEffect(() => {
    if (status.isResolved && body) setPreview(body);
    if (status.isRejected || error) {
      createNotification(types.ERROR, t("errors.previewCreateCustomer"));
      closeModal();
    }
  }, [status.isResolved, status.isRejected, error, body]);

  return {
    getPreview,
    preview,
  };
};
