import { tabs } from "./tabs";
import { Redirect, useParams } from "react-router-dom";
import { Tab, Content, TabContainer } from "./settings.styles";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../context/hooks";
import { settingsTabNames } from "../../static/constants/constants";
import { useProject } from "../../context/project/project.provider";
import { useLocation } from "react-router-dom";

export const Settings = () => {
  const { settingsTab } = useParams();
  const { t } = useTranslation();
  const { isUserAdmin, isPartnerAdmin, hasPermission, isApiAdmin } =
    usePermissions();
  const { project } = useProject();
  const location = useLocation();

  return project &&
    project?.id &&
    (isUserAdmin() || isPartnerAdmin() || isApiAdmin()) ? (
    <Content>
      <h1>{t("settings.title")}</h1>
      <TabContainer>
        {settingsTabNames.map(tab => {
          let show = true;

          if (tab.requiresAdmin) show = isUserAdmin() || isPartnerAdmin();
          if (tab.requiresRole) show = hasPermission(tab.requiresRole);

          return show ? (
            <Tab
              to={`/settings/${tab.name}${location.search}`}
              key={`${tab.name}-tab`}
              $active={tab.name === settingsTab}
            >
              {t(`settings.tabs.${tab.name}`)}
            </Tab>
          ) : null;
        })}
      </TabContainer>
      {tabs[settingsTab]}
    </Content>
  ) : (
    <Redirect to="/projects" />
  );
};
