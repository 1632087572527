import React from "react";
import {
  Container,
  SectionHeader,
  StyledScenariosTable,
} from "./scenarios.styles";
import { useTranslation } from "react-i18next";
import { useLatestRun } from "../../latestRunCard/hooks/useLatestRun";
import { Scenario } from "./scenario/scenario";
import { useParams } from "react-router-dom";
import { NoScenariosP } from "../../../testEvolveVisual/rightWidget/scenarios/scenarios.styles";

export const Scenarios = ({ run, isShared }) => {
  const { t } = useTranslation();
  const { nodeId } = useParams();
  const { failedScenarios, passedScenarios, errorScenarios, skippedScenarios } =
    useLatestRun(run);

  const StatusSection = ({ status, first }) => {
    return (
      <SectionHeader color={status} first={first}>
        <h2>{t(`runView.testevolvelighthouse.${status}.name`)}</h2>
        <p>{t(`runView.testevolvelighthouse.${status}.description`)}</p>
      </SectionHeader>
    );
  };

  const TableHeaderRow = ({ hideSeverities }) => {
    return (
      <tr>
        <th>{t("runView.testevolvelighthouse.scenarioNameColumn")}</th>
        <th>{t("runView.testevolvelighthouse.numberChecks")}</th>
        <th>
          {!hideSeverities &&
            t("runView.testevolvelighthouse.scores.performance")}
        </th>
        <th>
          {!hideSeverities &&
            t("runView.testevolvelighthouse.scores.accessibility")}
        </th>
        <th>
          {!hideSeverities &&
            t("runView.testevolvelighthouse.scores.bestPractices")}
        </th>
        <th>
          {!hideSeverities && t("runView.testevolvelighthouse.scores.seo")}
        </th>
      </tr>
    );
  };

  const colourFromScore = score => {
    if (score > 89) return "passed";
    if (score > 49) return "moderate";
    else return "failed";
  };

  const scenariosList = () => {
    return (
      <Container>
        <StatusSection status="failed" first={true} />
        {failedScenarios && failedScenarios.length ? (
          <StyledScenariosTable>
            <TableHeaderRow />
            <tbody>
              {failedScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.lighthouse.checksPerformed}
                    performance={item.lighthouse.performance}
                    accessibility={item.lighthouse.accessibility}
                    bestPractices={item.lighthouse.bestPractices}
                    seo={item.lighthouse.SEO}
                    performanceColour={colourFromScore(
                      item.lighthouse.performance
                    )}
                    accessibilityColour={colourFromScore(
                      item.lighthouse.accessibility
                    )}
                    bestPracticesColour={colourFromScore(
                      item.lighthouse.bestPractices
                    )}
                    seoColour={colourFromScore(item.lighthouse.SEO)}
                    run={run}
                    nodeId={nodeId}
                    isShared={isShared}
                  />
                );
              })}
            </tbody>
          </StyledScenariosTable>
        ) : (
          <NoScenariosP>{t("noScenarios")}</NoScenariosP>
        )}

        <StatusSection status="error" />
        {errorScenarios && errorScenarios.length ? (
          <StyledScenariosTable>
            <TableHeaderRow hideSeverities />
            <tbody>
              {errorScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.lighthouse.checksPerformed}
                    performance={item.lighthouse.performance}
                    accessibility={item.lighthouse.accessibility}
                    bestPractices={item.lighthouse.bestPractices}
                    seo={item.lighthouse.SEO}
                    performanceColour={colourFromScore(
                      item.lighthouse.performance
                    )}
                    accessibilityColour={colourFromScore(
                      item.lighthouse.accessibility
                    )}
                    bestPracticesColour={colourFromScore(
                      item.lighthouse.bestPractices
                    )}
                    seoColour={colourFromScore(item.lighthouse.SEO)}
                    run={run}
                    nodeId={nodeId}
                    isShared={isShared}
                  />
                );
              })}
            </tbody>
          </StyledScenariosTable>
        ) : (
          <NoScenariosP>{t("noScenarios")}</NoScenariosP>
        )}

        <StatusSection status="passed" />
        {passedScenarios && passedScenarios.length ? (
          <StyledScenariosTable>
            <TableHeaderRow hideSeverities />
            <tbody>
              {passedScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    checksPerformed={item.lighthouse.checksPerformed}
                    run={run}
                    nodeId={nodeId}
                    isShared={isShared}
                  />
                );
              })}
            </tbody>
          </StyledScenariosTable>
        ) : (
          <NoScenariosP>{t("noScenarios")}</NoScenariosP>
        )}

        <StatusSection status="skipped" />
        {skippedScenarios && skippedScenarios.length ? (
          <StyledScenariosTable>
            <TableHeaderRow hideSeverities />
            <tbody>
              {skippedScenarios.map((item, index) => {
                return (
                  <Scenario
                    key={index}
                    name={item.name}
                    run={run}
                    nodeId={nodeId}
                    isShared={isShared}
                  />
                );
              })}
            </tbody>
          </StyledScenariosTable>
        ) : (
          <NoScenariosP>{t("noScenarios")}</NoScenariosP>
        )}
      </Container>
    );
  };

  return scenariosList();
};
