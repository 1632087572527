import { useEffect } from "react";
import { useFetch } from "../hooks";
import { createState } from "../../utils/context";
import { subscriptionReducer } from "./subscription.reducer";
import { ACTIONS } from "../../static/constants/actions";
import { ENDPOINTS } from "../../static/constants/routeConfig";

const SUBSCRIPTION_FREE = "FREE";

const initialState = {
  subscription: null,
};

const [useHook, SubscriptionProvider] = createState(
  "subscription",
  initialState,
  subscriptionReducer
);

const useSubscription = () => {
  const { body, status, get, error } = useFetch();
  const { subscription, dispatch } = useHook();

  const setSubscription = payload =>
    dispatch({ type: ACTIONS.SET_SUBSCRIPTION, payload });

  const getSubscription = () => get(ENDPOINTS.SUBSCRIPTION_CURRENT);

  useEffect(() => {
    if (status.isResolved && body) setSubscription(body);
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    subscription,
    getSubscription,
    isFree: subscription?.subscription_id === SUBSCRIPTION_FREE,
    isPaid: subscription?.subscription_id !== SUBSCRIPTION_FREE,
  };
};

export { useSubscription, SubscriptionProvider };
