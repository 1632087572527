import React from "react";
import { Theme } from "../theme";
import { routes } from "./routes";
import { Redirect, Switch } from "react-router-dom";
import { useConfigActions, usePermissions } from "../context/hooks";
import { useApp } from "../context/app/app.provider";
import { useAuth } from "../context/auth/auth.provider";
import { PublicRoute } from "./PublicRoute/PublicRoute";
import { Downloads } from "../views/downloads/downloads";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import {
  SignIn,
  Home,
  Init,
  SignUp,
  ForgotPassword,
  ProjectView,
  SetPassword,
  Settings,
  NodeView,
  RunView,
  RunReportView,
} from "../views";
import { OrganisationSettings } from "../views/organisationSettings/organisationSettings";
import { ProfileSettings } from "../views/profileSettings/profileSettings";
import { UnassignedLabels } from "../views/unassignedLabels/UnassignedLabels";
import { useProjectLabels } from "../context/projectLabels/projectLabels.provider";
import { useProject } from "../context/project/project.provider";
import { SharedRunView } from "../views/sharedRunView/sharedRunView";
import { ReleaseView } from "../views/releaseView/releaseView";
import { ReleasesView } from "../views/releasesView/releasesView";
import { SharedReleaseView } from "../views/sharedReleaseView/sharedReleaseView";
import { Gradients } from "../components";
import { ModalComponent } from "../context/modal/modalComponent";
import { useLocation } from "react-router-dom";
import { useModal } from "../context/modal/modal.provider";
import { useUser } from "../context/user/user.provider";

export const Router = () => {
  const { watchViewport } = useApp();
  const { isAuthenticated } = useAuth();
  const { getConfig } = useConfigActions();
  const { getProjectLabels } = useProjectLabels();
  const { project } = useProject();
  const { isUserAdmin, isApiAdmin, isPartnerAdmin } = usePermissions();
  const { pathname } = useLocation();
  const { closeModal } = useModal();
  const { guideOpen, toggleGuideModal } = useUser();

  React.useEffect(() => {
    getConfig();
    watchViewport();
  }, []);

  React.useEffect(() => {
    closeModal();
    if (guideOpen) toggleGuideModal();
  }, [pathname, guideOpen]);

  React.useEffect(() => {
    if (isAuthenticated && project) getProjectLabels();
  }, [isAuthenticated, project]);

  return (
    <Theme>
      <ModalComponent />
      <Gradients />
      <Switch>
        <PublicRoute path={routes.SIGNIN.path}>
          <SignIn />
        </PublicRoute>
        <PublicRoute path={routes.SIGNUP.path}>
          <SignUp />
        </PublicRoute>
        <PublicRoute path={routes.SHARED_RUN_VIEW.path}>
          <SharedRunView />
        </PublicRoute>
        <PublicRoute path={routes.SHARED_RELEASE_VIEW.path}>
          <SharedReleaseView />
        </PublicRoute>
        <PublicRoute path={routes.FORGOT_PASSWORD.path}>
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute path={routes.SET_PASSWORD.path}>
          <SetPassword />
        </PublicRoute>
        <PublicRoute path={routes.RESET_PASSWORD.path}>
          <SetPassword resetPassword={true} />
        </PublicRoute>
        <PrivateRoute path={routes.RUN_VIEW_REPORT.path}>
          <RunReportView />
        </PrivateRoute>
        <PrivateRoute path={routes.RUN_VIEW.path}>
          <RunView />
        </PrivateRoute>
        <PrivateRoute path={routes.RUN_VIEW_RELEASE.path}>
          <RunView showRelease={true} />
        </PrivateRoute>
        <PrivateRoute path={routes.NODE_VIEW.path}>
          <NodeView />
        </PrivateRoute>
        <PrivateRoute path={routes.HOME.path}>
          <Home />
        </PrivateRoute>
        <PrivateRoute path={routes.SETTINGS_WITH_TAB.path}>
          {project && project?.id ? (
            <Settings />
          ) : (
            <Redirect to={`${routes.PROJECTS.path}`} />
          )}
        </PrivateRoute>
        <PrivateRoute path={routes.PROFILE.path}>
          <ProfileSettings />
        </PrivateRoute>
        <PrivateRoute path={routes.DOWNLOADS.path}>
          <Downloads />
        </PrivateRoute>
        <PrivateRoute path={routes.UNASSIGNED_LABELS.path}>
          <UnassignedLabels />
        </PrivateRoute>
        <PrivateRoute path={routes.SETTINGS.path}>
          {project && project?.id ? (
            isUserAdmin() || isPartnerAdmin() ? (
              <Redirect
                to={`${routes.SETTINGS_ORGANISATION.path}${location.search}`}
              />
            ) : isApiAdmin() ? (
              <Redirect
                to={`${routes.SETTINGS_API_KEYS.path}${location.search}`}
              />
            ) : (
              <Redirect to={`${routes.INIT.path}${location.search}`} />
            )
          ) : (
            <Redirect to={routes.PROJECTS.path} />
          )}
        </PrivateRoute>
        <PrivateRoute path={routes.ORGANISATION_SETTINGS.path}>
          <OrganisationSettings />
        </PrivateRoute>
        <PrivateRoute path={routes.RELEASE.path}>
          <ReleaseView />
        </PrivateRoute>
        <PrivateRoute path={routes.RELEASES.path}>
          <ReleasesView />
        </PrivateRoute>
        <PrivateRoute path={routes.PROJECTS.path}>
          <ProjectView />
        </PrivateRoute>
        <PrivateRoute path={routes.INIT.path}>
          <Init />
        </PrivateRoute>
      </Switch>
    </Theme>
  );
};
