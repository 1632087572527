import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export const usePolling = (callback, delay) => {
  const location = useLocation();

  useEffect(() => {
    callback();
  }, [location]);

  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
