import {
  BottomBar,
  DateContainer,
  ReleaseCardName,
  StyledReleaseCard,
  TopBar,
} from "../releasesView.styles";
import { useTranslation } from "react-i18next";
import {
  DATE_OPTIONS,
  MENU_STATE,
  RELEASE_STATUSES,
} from "../../../static/constants/constants";
import { StatusSticker } from "../../releaseView/release.styles";
import { convertDateToLuxonDate } from "../../../utils/dates/dateUtils";
import { FrozenGraph } from "./frozenGraph";
import { Spacer } from "../../runReportView/components/bug.styles";
import { useClick, useMenuState } from "@szhsin/react-menu";
import { ReleaseMenu } from "./releaseMenu";
import { useRef } from "react";
import { OpenMenuButton } from "../../../components/menus/openMenuButton";
import { useHistoryWithParams } from "../../../context/hooks/historyWithParams/useHistoryWithParams";

const RELEASE_DATE_OPTIONS = {
  ...DATE_OPTIONS,
  hour: undefined,
  minute: undefined,
  second: undefined,
};

export const ReleaseCard = ({ release }) => {
  const { t } = useTranslation();
  const history = useHistoryWithParams();
  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);
  const menuOpen = menuState.state === MENU_STATE.OPEN;

  return (
    <>
      <ReleaseMenu
        release={release}
        r={ref}
        menuState={menuState}
        toggleMenu={toggleMenu}
      />
      <StyledReleaseCard
        onClick={() => history.push(`/release/${release.id}`)}
        data-test={`${release.name}-release-card`}
        isActive={menuOpen}
      >
        <TopBar>
          <OpenMenuButton
            r={ref}
            anchorProps={anchorProps}
            menuOpen={menuOpen}
          />
        </TopBar>
        <StatusSticker
          isClosed={release.status === RELEASE_STATUSES.CLOSED}
          data-test="release-status"
        >
          {t(`releases.${RELEASE_STATUSES[release.status]}`)}
        </StatusSticker>
        <ReleaseCardName data-test="release-name">
          <h1>{release.name}</h1>
        </ReleaseCardName>
        <FrozenGraph
          frozen={release.frozen_count}
          total={release.total_count}
        />
        <BottomBar>
          {release.open_date ? (
            <DateContainer data-test="open-date">
              <h2>{t("releases.releasesView.open")}</h2>
              <p>
                {convertDateToLuxonDate(release.open_date)
                  ?.toJSDate()
                  ?.toLocaleString("en-GB", RELEASE_DATE_OPTIONS)}
              </p>
            </DateContainer>
          ) : null}
          <Spacer />
          {release.close_date ? (
            <DateContainer data-test="close-date">
              <h2>{t("releases.releasesView.close")}</h2>
              <p>
                {convertDateToLuxonDate(release.close_date)
                  ?.toJSDate()
                  ?.toLocaleString("en-GB", RELEASE_DATE_OPTIONS)}
              </p>
            </DateContainer>
          ) : null}
        </BottomBar>
      </StyledReleaseCard>
    </>
  );
};
