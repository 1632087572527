import React from "react";
import { Button } from "../../../../components";
import { useTranslation } from "react-i18next";
import { TEST_CATEGORIES } from "../../../../static/constants/constants";
import { MAX_TEXT_FIELD_LENGTH } from "./constants";
import { useModal } from "../../../../context/modal/modal.provider";
import { useParams } from "react-router-dom";

export const JiraBugButton = ({ category, data, url, scenario, feature }) => {
  const { t } = useTranslation();
  const { openModal, MODALS } = useModal();
  const { runId } = useParams();
  const constructDescription = (category, data) => {
    let description = "";
    if (category === TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL) {
      description = functionalDescription(data);
    } else if (category === TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE) {
      description = axeDescription(data);
    } else if (category === TEST_CATEGORIES.TEST_EVOLVE_AUDIT_LIGHTHOUSE) {
      description = lighthouseDescription(data);
    }
    if (description.length > MAX_TEXT_FIELD_LENGTH)
      description = `${description.substring(0, MAX_TEXT_FIELD_LENGTH - 3)}...`;
    return description;
  };

  const constructSummary = (category, data) => {
    let summary = "";
    if (category === TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE) {
      summary = axeSummary(data);
    } else if (category === TEST_CATEGORIES.TEST_EVOLVE_AUDIT_LIGHTHOUSE) {
      summary = lighthouseSummary(data);
    }
    return summary;
  };

  const functionalDescription = scenario => {
    return scenario.steps.reduce((string, step) => {
      if (step.failure) {
        return (
          string +
          `${step.keyword}${step.name}\n\n${step.failure.failure_method}\n\n${step.failure.failure_stacktrace}\n\n`
        );
      } else {
        return string + `${step.keyword}${step.name}\n`;
      }
    }, `Scenario: ${scenario.name}\n\n`);
  };

  const axeSummary = check => `${t("jira.axe.summary")} ${check.label}`;

  const axeDescription = check => {
    return `${t("jira.axe.description.title")}\n
    ${t("jira.axe.description.labelHeading")} ${check.label}
    ${t("jira.axe.description.urlHeading")} ${check.url}\n
    ${check.critical} ${t("runCard.testevolveaxe.violations.critical")} ${t(
      "jira.axe.description.violations"
    )}
    ${check.serious} ${t("runCard.testevolveaxe.violations.serious")} ${t(
      "jira.axe.description.violations"
    )}
    ${check.moderate} ${t("runCard.testevolveaxe.violations.moderate")} ${t(
      "jira.axe.description.violations"
    )}
    ${check.minor} ${t("runCard.testevolveaxe.violations.minor")} ${t(
      "jira.axe.description.violations"
    )}\n
    ${t("jira.visitHalo")} ${window.location.origin}${
      window.location.pathname
    }${url}`;
  };

  const lighthouseSummary = check =>
    `${t("jira.lighthouse.summary")} ${check.label}`;

  const lighthouseDescription = check => {
    return `${t("jira.lighthouse.description.title")}\n
    ${t("jira.lighthouse.description.labelHeading")} ${check.label}
    ${t("jira.lighthouse.description.urlHeading")} ${check.url}\n
    ${check.performance.score} ${t(
      "runView.testevolvelighthouse.scores.performance"
    )} ${t("jira.lighthouse.description.score")}
    ${check.accessibility.score} ${t(
      "runView.testevolvelighthouse.scores.accessibility"
    )} ${t("jira.lighthouse.description.score")}
    ${check.best_practices.score} ${t(
      "runView.testevolvelighthouse.scores.bestPractices"
    )} ${t("jira.lighthouse.description.score")}
    ${check.seo.score} ${t("runView.testevolvelighthouse.scores.seo")} ${t(
      "jira.lighthouse.description.score"
    )}\n
    ${t("jira.visitHalo")} ${window.location.origin}${
      window.location.pathname
    }${url}`;
  };

  const setJiraModalOpen = () =>
    openModal(MODALS.JIRA_BUG, {
      summary: constructSummary(category, data),
      description: constructDescription(category, data),
      scenario: scenario,
      feature: feature,
      runId: runId,
    });

  return (
    <Button onClick={setJiraModalOpen} secondary jira>
      {t("jira.button.create")}
    </Button>
  );
};
