import styled from "styled-components";
import { Link } from "react-router-dom";
import { SvgIcon } from "../../components/icon/icon";

export const ReleaseDetailsContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  padding: ${({ theme }) => theme.spacing.padding};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const ReleaseViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const ReleaseDescription = styled.p`
  opacity: 0.7;
  text-align: ${({ theme }) => (theme.isMobile ? "center" : "left")};
`;

export const LeftContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-direction: column;
  max-width: 100%;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) =>
    theme.isNotDesktop ? theme.spacing.paddingSM : 0};
  flex-direction: column;
  align-items: ${({ theme }) => (theme.isNotDesktop ? "center" : "flex-end")};

  h3 {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  gap: ${({ theme }) => theme.spacing.paddingXSM};

  p {
    font-size: ${({ theme }) => theme.fontSize.medium};
  }
`;

export const StatusSticker = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme, isClosed }) =>
    isClosed
      ? theme.colours.closedButtonBackground
      : theme.colours.openButtonBackground};

  color: ${({ theme, isClosed }) =>
    isClosed ? theme.colours.closedButtonForeground : theme.colours.haloAccent};

  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
  justify-content: center;
  width: ${({ w }) => w || "fit-content"};
  min-width: ${({ w }) => w || "fit-content"};

  border-radius: ${({ theme }) => theme.borders.radius};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: ${({ admin }) => (admin ? "pointer" : "default")};
  max-width: fit-content;

  svg {
    color: ${({ theme, isClosed }) =>
      isClosed
        ? theme.colours.closedButtonForeground
        : theme.colours.haloAccent};
    opacity: 1;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};

  justify-content: flex-start;

  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.padding};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 100%;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  color: ${({ theme }) => theme.colours.mainText};
  width: fit-content;
  opacity: 0.7;

  &:hover {
    color: ${({ theme }) => theme.colours.haloAccent};
  }
`;

export const Chevron = styled(SvgIcon)`
  width: ${({ theme }) => theme.sizing.svgHeightMD};
  height: auto;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(90deg)")};
  transition: ${({ theme }) => theme.transitionTime.fast};
`;
