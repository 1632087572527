import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../";
import { useApp } from "../../context/app/app.provider";
import {
  InsideLabelContainer,
  Label,
  LabelsContainer,
  SectionContainer,
  NewLabelContainer,
  EditButton,
  DeleteButton,
  AddLabelSelect,
} from "./labels.styles";
import { useProjectLabels } from "../../context/projectLabels/projectLabels.provider";

export const LabelsComponent = ({
  labels,
  addNewLabel,
  removeLabel,
  isShared,
  edit,
}) => {
  const { isNotDesktop } = useApp();
  const { t } = useTranslation();

  const { projectLabels, getProjectLabels } = useProjectLabels();

  const [newLabel, setNewLabel] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
    getProjectLabels();
    setEditMode(false);
  }, []);

  useEffect(() => {
    setNewLabel([]);
  }, [editMode]);

  const getOptions = () =>
    projectLabels
      .filter(lbl => !labels.includes(lbl))
      .sort()
      .map(l => ({ label: l, value: l }))
      ?.filter(e => !newLabel?.map(f => f.value)?.includes(e.value));

  return (
    <SectionContainer isNotDesktop={isNotDesktop}>
      <LabelsContainer isNotDesktop={isNotDesktop}>
        {!edit || isShared ? null : (
          <EditButton onClick={() => setEditMode(editMode ? false : true)}>
            {editMode ? t("btnClose") : t("nodeView.manageLabels")}
          </EditButton>
        )}
        {labels?.sort().map((label, key) => (
          <Label
            hovered={editMode && hovered === label}
            onMouseLeave={() => (editMode ? setHovered(null) : null)}
            onMouseEnter={() => (editMode ? setHovered(label) : null)}
            key={`label-${key}`}
            onClick={editMode ? () => removeLabel(label) : null}
            editMode={editMode}
          >
            <InsideLabelContainer>
              <p>{label}</p>
            </InsideLabelContainer>
            {editMode ? <DeleteButton>X</DeleteButton> : null}
          </Label>
        ))}
      </LabelsContainer>
      {editMode && !isShared ? (
        <NewLabelContainer isNotDesktop={isNotDesktop}>
          <AddLabelSelect
            isMulti={true}
            value={newLabel || null}
            options={getOptions()}
            onChange={e => setNewLabel(e)}
            placeholder={t("nodeView.addLabel")}
            classNamePrefix="Select"
            isNotDesktop={isNotDesktop}
            aria-label="Add label"
            innerProps={{
              "aria-label": "Add label",
            }}
          />
          <Button
            disabled={!newLabel}
            onClick={() => {
              addNewLabel(newLabel);
              setNewLabel([]);
            }}
          >
            {t("Add")}
          </Button>
        </NewLabelContainer>
      ) : null}
    </SectionContainer>
  );
};
