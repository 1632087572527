import { ACTIONS } from "../../../static/constants/actions";

export const HomeReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_LATEST_RUNS: {
      let showGuide = state.showGettingStarted || false;
      if (
        !state.inProgressRuns.length &&
        !payload.length &&
        !state.loadingInProgressRuns
      )
        showGuide = true;
      return {
        ...state,
        latestRuns: payload,
        showGettingStarted: showGuide,
        hasData: state.inProgressRuns.length || payload.length,
        loadingLatestRuns: false,
      };
    }
    case ACTIONS.SET_IN_PROGRESS_RUNS: {
      let showGuide = state.showGettingStarted || false;
      if (
        !state.latestRuns.length &&
        !payload.length &&
        !state.loadingLatestRuns
      )
        showGuide = true;
      return {
        ...state,
        inProgressRuns: payload,
        showGettingStarted: showGuide,
        hasData: state.latestRuns.length || payload.length,
        loadingInProgressRuns: false,
      };
    }
    case ACTIONS.SET_STEP:
      return {
        ...state,
        gettingStartedStep: payload,
      };
    case ACTIONS.SET_IN_PROGRESS_LOADING:
      return {
        ...state,
        loadingInProgressRuns: true,
      };
    case ACTIONS.SET_LATEST_LOADING:
      return {
        ...state,
        loadingLatestRuns: true,
      };
    case ACTIONS.HIDE_GUIDE:
      return {
        ...state,
        showGettingStarted: false,
      };
    case ACTIONS.CLEAR_HOME:
      return {
        ...payload,
      };
    default:
      return state;
  }
};
