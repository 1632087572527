import styled, { css } from "styled-components";
import { ResponsiveContainer } from "recharts";

export const Dot = styled.div`
  height: ${({ size, theme }) => size || theme.spacing.padding};
  width: ${({ size, theme }) => size || theme.spacing.padding};
  background-color: ${({ colour, theme, colourCode }) =>
    colourCode
      ? colourCode
      : (colour === "passed" && theme.gradients.passing_end) ||
        (colour === "failed" && theme.gradients.error_start) ||
        (colour === "error" && theme.gradients.critical_end) ||
        (colour === "skipped" && theme.gradients.skipped_start)};
  border-radius: 25%;
  transform: ${({ rotate }) => `rotate(${rotate}deg)`};
  margin: ${({ theme }) => theme.spacing.paddingSM};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const GraphContainer = styled(ResponsiveContainer)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  g {
    fill: ${({ theme }) => theme.colours.haloAccent};
  }

  tspan {
    fill: ${({ theme }) => theme.colours.mainText};
  }

  .recharts-label {
    tspan {
      fill: ${({ theme }) => theme.colours.white};
    }
  }
`;

export const StickersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const RunStatusStickerBox = styled.div`
  background-color: ${({ theme }) => theme.colours.foreground};
`;

export const ScenarioBox = styled.div`
  background-color: ${({ theme }) => theme.colours.foreground};
`;

export const ScenarioSticker = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};

  ${({ clickable }) =>
    clickable &&
    css`
      &:hover {
        cursor: pointer;
        outline: ${({ theme, clickable }) =>
          clickable ? `1px solid ${theme.colours.haloAccent}` : null};
      }
    `}
`;

export const RunStatusSticker = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};

  ${({ clickable }) =>
    clickable &&
    css`
      &:hover {
        cursor: pointer;
        outline: ${({ theme, clickable }) =>
          clickable ? `1px solid ${theme.colours.haloAccent}` : null};
      }
    `}
`;

export const RunStatusTitle = styled.p`
  font-size: 12px;
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  color: ${({ theme }) =>
    theme.isDark ? theme.colours.mainText : theme.colours.fadedMainText};
`;

export const RunStatusValue = styled.p`
  font-size: ${({ label }) => (label ? "16px" : "24px")};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ScenarioValue = styled.p`
  font-size: ${({ label }) => (label ? "16px" : "24px")};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const RunStatusContainer = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const ScenarioContainer = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
