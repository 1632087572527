import { modalReducer } from "./modal.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { MODALS } from "./modals";

const initialState = { modal: false, data: null };

const [useHook, ModalProvider] = createState(
  "modal",
  initialState,
  modalReducer
);

const useModal = () => {
  const { modal, data, dispatch } = useHook();

  const openModal = (modal, data) =>
    dispatch({ type: ACTIONS.OPEN_MODAL, payload: { modal, data } });

  const closeModal = () => dispatch({ type: ACTIONS.CLOSE_MODAL });

  return {
    MODALS,
    openModal,
    closeModal,
    modal,
    data: { ...data, closeModal },
  };
};

export { useModal, ModalProvider };
