import { NodeCard } from "../nodeView/nodeCardContainer/nodeCard";
import { CardsContainer } from "./unassignedLabels.styles";
import { Button } from "../../components/Button/Button";

export const LabelRow = ({
  label,
  runs,
  t,
  isNotDesktop,
  getLabels,
  openAssignLabel,
}) => {
  return (
    <>
      <h2>{label}</h2>
      <Button onClick={openAssignLabel}>{t("unassignedLabels.assign")}</Button>
      <CardsContainer isNotDesktop={isNotDesktop}>
        {runs.map(run => (
          <NodeCard
            key={run.id}
            category={run.category}
            run={run}
            getLabels={getLabels}
          />
        ))}
      </CardsContainer>
    </>
  );
};
