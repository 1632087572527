import { useEffect } from "react";
import { ENDPOINTS } from "../../static/constants/routeConfig";
import { useAuth } from "../auth/auth.provider";
import { useFetch } from "../hooks";
import { useUser } from "../user/user.provider";

export const useSignout = () => {
  const { username } = useUser();
  const { setUnAuthenticated } = useAuth();
  const { post, status, error } = useFetch();
  const { clearUser } = useUser();

  const signout = () => {
    post(ENDPOINTS.AUTH_SIGNOUT, { username: username });
    sessionStorage.clear();
    localStorage.clear();
  };

  useEffect(() => {
    if (status.isResolved || status.isRejected) {
      clearUser();
      sessionStorage.clear();
      localStorage.clear();
      setUnAuthenticated();
    }
  }, [status.isResolved, status.isRejected, error]);

  return {
    signout,
  };
};
