import { RunInsightsContainer } from "./runInsights.styles";
import { RunPropertiesSection } from "./runProperties/runProperties";
import { ComparisonSection } from "./comparison/comparison";
import { LatestStatistics } from "./latestStatistics/latestStatistics";

export const RunInsights = ({ run, runInsights, nodeDetails }) => {
  return Object.keys(runInsights).length ? (
    <RunInsightsContainer>
      <LatestStatistics
        run={run}
        runInsights={runInsights}
        nodeDetails={nodeDetails}
      />
      <ComparisonSection run={run} runInsights={runInsights} />
      <RunPropertiesSection runInsights={runInsights} />
    </RunInsightsContainer>
  ) : null;
};
