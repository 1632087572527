import {
  Wrapper,
  ButtonContainer,
  StyledTitlesContainer,
} from "./usersList.styles";
import { useEffect } from "react";
import { UserRow } from "./userRow/userRow";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { usePermissions } from "../../../../context/hooks";
import { useApp } from "../../../../context/app/app.provider";
import { useProjectView } from "../../../projectView/hooks/useProjectView";
import { useHandleSaveButton } from "../../../../context/organisationUsers/hooks/handleSaveButton";
import { useOrganisationUsers } from "../../../../context/organisationUsers/organisationUsers.provider";

export const UsersList = () => {
  const { t } = useTranslation();
  const { isNotDesktop } = useApp();
  const { getProjects } = useProjectView();
  const { isPartnerAdmin } = usePermissions();
  const { handleSaveButton } = useHandleSaveButton();
  const {
    editableUsers,
    nonEditableUsers,
    saveButtonActive,
    getOrganisationUsers,
  } = useOrganisationUsers();

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getOrganisationUsers();
  }, []);

  return (
    <>
      <h2>{t("organisationSettings.organisation.users")}</h2>
      <Wrapper>
        <StyledTitlesContainer showPartner={isPartnerAdmin()}>
          <div>{t("organisationSettings.organisation.columnTitles.user")}</div>
          <div>
            <label id="active-label">
              {t("organisationSettings.organisation.columnTitles.active")}
            </label>
          </div>
          <div>
            <label id="admin-label">
              {t("organisationSettings.organisation.columnTitles.admin")}
            </label>
          </div>
          {isPartnerAdmin() && (
            <div>
              <label id="partner-label">
                {t("organisationSettings.organisation.columnTitles.partner")}
              </label>
            </div>
          )}
          <div>
            <label id="project-label">
              {t("organisationSettings.organisation.columnTitles.projects")}
            </label>
          </div>
        </StyledTitlesContainer>
        {editableUsers().map((user, key) => (
          <UserRow key={key} userRowUser={user} editable={true} />
        ))}
        <ButtonContainer isNotDesktop={isNotDesktop}>
          <Button
            accent
            onClick={() => handleSaveButton()}
            disabled={!saveButtonActive}
            data-test="save-user-btn"
          >
            {t("btnSave")}
          </Button>
        </ButtonContainer>
      </Wrapper>
      {nonEditableUsers()?.length > 0 && (
        <Wrapper>
          <StyledTitlesContainer displayFlex showPartner={isPartnerAdmin()}>
            <div>
              {t("organisationSettings.organisation.columnTitles.partnerUsers")}
            </div>
            <div>
              {t("organisationSettings.organisation.columnTitles.active")}
            </div>
            <div>
              {t("organisationSettings.organisation.columnTitles.admin")}
            </div>
          </StyledTitlesContainer>
          {nonEditableUsers().map((user, key) => (
            <UserRow key={key} userRowUser={user} />
          ))}
        </Wrapper>
      )}
    </>
  );
};
