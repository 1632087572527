import { useTranslation } from "react-i18next";
import { Tooltip } from "../../../../../components/tooltip/tooltip";
import {
  Container,
  InsideContainer,
  Label,
  Method,
  SummaryContainer,
  Value,
} from "./summary.styles";

export const Summary = ({ run }) => {
  const summary = run?.summary;
  const { t } = useTranslation();

  if (!summary) return <p>{t("runView.summary.noSummary")}</p>;

  return (
    <SummaryContainer>
      <InsideContainer>
        <h2>{t("runView.summary.topFailingSteps")}</h2>
        <Label space>{t("runView.summary.topFailingStepsText")}</Label>
        {summary.top_five_failed_steps?.map(step => (
          <Method key={step.step}>
            <Tooltip content={step.step}>
              <Container grow shrink>
                <Label>{t("runView.summary.stepName")}</Label>
                <Value>
                  <bdi>{step.step}</bdi>
                </Value>
              </Container>
            </Tooltip>
            <Container>
              <Label>{t("runView.summary.count")}</Label>
              <Value>{step.count}</Value>
            </Container>
          </Method>
        ))}
      </InsideContainer>
      <InsideContainer>
        <h2>{t("runView.summary.topFailingMethods")}</h2>
        <Label space>{t("runView.summary.topFailingMethodsText")}</Label>
        {summary.top_five_failed_methods?.map(method => (
          <Method key={method.method}>
            <Tooltip content={method.method}>
              <Container grow shrink>
                <Label>{t("runView.summary.methodName")}</Label>
                <Value>
                  <bdi>{method.method}</bdi>
                </Value>
              </Container>
            </Tooltip>
            <Container>
              <Label>{t("runView.summary.count")}</Label>
              <Value>{method.count}</Value>
            </Container>
          </Method>
        ))}
      </InsideContainer>
    </SummaryContainer>
  );
};
