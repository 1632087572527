import { themeReducer } from "./theme.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";

const initialState = {
  theme: "light",
};

const [useHook, ThemeProvider] = createState(
  "theme",
  initialState,
  themeReducer
);

const useTheme = () => {
  const { theme, dispatch } = useHook();

  const setLight = () => dispatch({ type: ACTIONS.LIGHT_MODE });

  const setDark = () => dispatch({ type: ACTIONS.DARK_MODE });

  return {
    isDark: theme === "dark",
    toggleDark: () => (theme === "dark" ? setLight() : setDark()),
    theme,
  };
};

export { useTheme, ThemeProvider };
