import styled from "styled-components";
import { readableColor } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => theme.sizing.featuresListHeight};
`;

export const SectionHeader = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.sizing.sectionHeaderHeight};
  border-left: ${({ theme }) => theme.spacing.paddingSM} solid
    ${({ theme, color }) =>
      (color === "passed" && theme.gradients.passing_end) ||
      (color === "failed" && theme.gradients.error_start) ||
      (color === "error" && theme.gradients.critical_end) ||
      (color === "skipped" && theme.gradients.skipped_start)};
  margin-top: ${({ first, theme }) =>
    first ? "0px" : theme.spacing.paddingMD};

  h2 {
    margin-left: ${({ theme }) => theme.spacing.paddingXSM};
    font-size: 18px;
    margin-bottom: ${({ theme }) => theme.spacing.paddingXSM};
  }

  p {
    margin-left: ${({ theme }) => theme.spacing.paddingXSM};
    font-size: 12px;
    color: ${({ theme }) => theme.colours.altText};
  }
`;

export const StyledScenariosTable = styled.table`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.padding};

  th {
    color: ${({ theme }) => theme.colours.mainText};
    font-size: 12px;
    text-align: center;
    font-weight: normal;
  }

  th:first-child {
    text-align: left;
  }

  tbody {
    tr:nth-child(even) {
      background-color: ${({ theme }) => theme.colours.background};
      color: ${({ theme }) => readableColor(theme.colours.background)};

      &:hover {
        background-color: ${({ theme }) => theme.colours.haloAccent};
        color: ${({ theme }) => theme.colours.white};
      }
    }

    tr:nth-child(odd) {
      &:hover {
        background-color: ${({ theme }) => theme.colours.haloAccent};
        color: ${({ theme }) => theme.colours.white};
      }
    }
  }

  tr {
    border-radius: ${({ theme }) => theme.borders.radius};

    td:first-child {
      border-radius: ${({ theme }) => theme.borders.radius} 0 0
        ${({ theme }) => theme.borders.radius};
    }

    td:last-child {
      border-radius: 0 ${({ theme }) => theme.borders.radius}
        ${({ theme }) => theme.borders.radius} 0;
    }
  }
`;
