import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTime } from "../../../../../context/hooks/time/useTime";
import {
  RUN_PROPERTIES,
  RUN_STATUSES,
} from "../../../../../static/constants/constants";

const STATUSES = {
  PASSED: "Passed",
  FAILED: "Failed",
  SKIPPED: "Skipped",
  ERROR: "Error",
};

export const useLatestRun = run => {
  const { t } = useTranslation();
  const { getReadableTimeDifference, getTimeBetween } = useTime();
  const [timer, setTimer] = useState(0);

  const setTime = () => {
    const start = DateTime.fromISO(run.start_time);
    let end = DateTime.local();
    let time = getTimeBetween(start, end);
    setTimer(time);
  };

  const totalScenarios = (run.failures + run.passed) / 100;

  const data = [
    {
      name: STATUSES.PASSED,
      value: run.passed / totalScenarios,
      color: "url(#gradientPass)",
    },
    {
      name: STATUSES.FAILED,
      value: run.failures / totalScenarios,
      color: "url(#gradientFail)",
    },
    {
      name: STATUSES.SKIPPED,
      value: run.skipped / totalScenarios || 0,
      color: "url(#gradientSkipped)",
    },
    {
      name: STATUSES.ERROR,
      value: run.errors / totalScenarios || 0,
      color: "url(#gradientError)",
    },
  ];

  const runStatuses = [
    {
      heading: t("runView.testevolve.passed"),
      value: run.passed,
    },
    {
      heading: t("runView.testevolve.failed"),
      value: run.failures,
    },
    {
      heading: t("runView.playwright.totalTestSuites"),
      value: run.test_suite_total,
    },
    {
      heading: t("runView.playwright.totalTestCases"),
      value: run.tests,
    },
    {
      heading: t("runView.playwright.timeTaken"),
      value:
        run.status === RUN_STATUSES.IN_PROGRESS
          ? timer
          : getTimeBetween(run.start_time, run.finish_time ?? run.updated_time),
    },
    {
      heading: t("runView.playwright.averageTestTime"),
      value: run.average_test_time ? `${run.average_test_time} s` : t("n/a"),
    },
  ];

  const status = run.status;

  const runStatus = t(`runCard.testevolve.status.${status}`) || t("n/a");

  const endTime =
    status === RUN_STATUSES.COMPLETE ? run.finish_time : run.updated_time;

  const timeAgo =
    `${getReadableTimeDifference(endTime)} ${t("time.ago")}` || t("n/a");

  const runProps = RUN_PROPERTIES[run.test_type]?.map(prop => [
    run.run_properties[prop],
    prop,
  ]);

  return {
    run,
    data,
    status,
    setTime,
    timeAgo,
    runProps,
    runStatus,
    runStatuses,
    startTime: run.start_time,
    runProperties: run.run_properties,
    passPercent: run.passed_percentage,
  };
};
