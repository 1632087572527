import { useEffect, useRef, useState } from "react";
import {
  OpenMenuIcon,
  MenuContainer,
  StyledMenuItem,
  DeleteText,
  StyledMenu,
} from "../../../views/projectView/projectCard/projectCard.styles";
import { useTheme } from "styled-components";
import { useDeleteProject } from "../../../views/projectView/hooks/useDeleteProject";
import { useRenameProject } from "../../../views/projectView/hooks/useRenameProject";
import { useTranslation } from "react-i18next";

export const ProjectCardContextMenu = ({ project, getProjects }) => {
  const ref = useRef(null);
  const { colours } = useTheme();
  const { handleDeleteProject } = useDeleteProject();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  const { openRenameModal, renameProjectSuccess, closeModal } =
    useRenameProject(project);

  useEffect(() => {
    if (renameProjectSuccess) {
      getProjects();
      closeModal();
    }
  }, [renameProjectSuccess]);

  return (
    <MenuContainer>
      <OpenMenuIcon
        ref={ref}
        onClick={e => {
          setMenuOpen(true);
          e.stopPropagation();
        }}
        fill={colours.mainText}
        data-test="openMenuIcon"
      />
      <StyledMenu
        state={isMenuOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setMenuOpen(false)}
        transition
        position="auto"
        align="start"
      >
        <StyledMenuItem onClick={openRenameModal} data-test="rename-project">
          <p>{t("projectView.renameProject.btnText")}</p>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={e => handleDeleteProject(e, project)}
          data-test="delete-project"
        >
          <DeleteText>{t("projectView.deleteProject")}</DeleteText>
        </StyledMenuItem>
      </StyledMenu>
    </MenuContainer>
  );
};
