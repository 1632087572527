import DropDown, { DropDownItem } from "../../../ui/DropDown";
import { ReactComponent as IconRule } from "../icons/horizontal-rule.svg";
import { ReactComponent as IconTable } from "../icons/table.svg";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { useModal } from "../../../../../context/modal/modal.provider";
import { useTranslation } from "react-i18next";

export const InsertDropdown = ({ isEditable, editor }) => {
  const { t } = useTranslation();
  const { openModal, MODALS } = useModal();

  return (
    <DropDown
      disabled={!isEditable}
      buttonClassName="toolbar-item spaced"
      buttonLabel={t("richTextEditor.toolbar.insertLabel")}
      buttonAriaLabel={t("richTextEditor.toolbar.insertAriaLabel")}
      buttonIconClassName="icon plus"
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
        }}
      >
        <IconRule />
        <span className="text">
          {t("richTextEditor.toolbar.horizontalRule")}
        </span>
      </DropDownItem>
      <DropDownItem
        onClick={() => openModal(MODALS.INSERT_TABLE, { activeEditor: editor })}
      >
        <IconTable />
        <span className="text">{t("richTextEditor.toolbar.table")}</span>
      </DropDownItem>
    </DropDown>
  );
};
