import React from "react";
import { useTranslation } from "react-i18next";
import { NodeCard } from "../../common/NodeCard";
import { useNodeView } from "../../hooks/useNodeView";
import { Vulnerabilities } from "./vulnerabilities/vulnerabilities";
import { sumObject } from "../../../../utils/objectMethods/objectMethods";
import {
  VulnerabilitiesContainer,
  TotalVulnerabilities,
} from "./nodeCardNPMAudit.styles";

export const NodeCardNPMAudit = ({
  run,
  getLabels,
  onRelease,
  frozen,
  isShared,
}) => {
  const { t } = useTranslation();
  const { npmAudit, nodeId } = useNodeView();

  const totalVulnerabilities = sumObject(
    npmAudit.latest_run.data.vulnerabilities
  );

  return (
    <NodeCard
      data-test="npm-audit-card"
      type={run.category.replace(".", "")}
      run={npmAudit}
      nodeId={nodeId}
      label={run.label}
      getLabels={getLabels}
      frozen={frozen}
      onRelease={onRelease}
      isShared={isShared}
    >
      <VulnerabilitiesContainer>
        <TotalVulnerabilities data-test="total-vulnerabilities">
          {totalVulnerabilities}
        </TotalVulnerabilities>
        <h2>{t("runCard.npmaudit.vulnerabilities.title")}</h2>
        <Vulnerabilities data={npmAudit.latest_run.data.vulnerabilities} />
      </VulnerabilitiesContainer>
    </NodeCard>
  );
};
