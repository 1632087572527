import { tabs } from "./tabs/tabs";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  ContentContainer,
  TabContainer,
  Tab,
} from "./organisationSettings.styles";
import { usePermissions } from "../../context/hooks";
import { OrganisationUsersProvider } from "../../context/organisationUsers/organisationUsers.provider";
import { useProject } from "../../context/project/project.provider";

export const OrganisationSettings = () => {
  const tabNames = [
    { name: "organisation" },
    { name: "billing" },
    { name: "logs" },
    { name: "integrations" },
    { name: "customers", requiresCustomerAdmin: true },
  ];

  const { settingsTab } = useParams();
  const { t } = useTranslation();
  const { isUserAdmin, isCustomerAdmin, isPartnerAdmin } = usePermissions();
  const { project } = useProject();

  return isUserAdmin() || isPartnerAdmin() || isCustomerAdmin() ? (
    <OrganisationUsersProvider>
      <Container>
        <h1>{t("settings.organisation.title")}</h1>
        <TabContainer>
          {tabNames
            .filter(tab =>
              tab.requiresCustomerAdmin ? isCustomerAdmin() : true
            )
            .map(tab => (
              <Tab
                to={`/organisation-settings/${tab.name}${
                  project?.id ? `?project=${project.id}` : ""
                }`}
                key={`${tab.name}-tab`}
                $active={tab.name === settingsTab}
              >
                {t(`organisationSettings.tabs.${tab.name}`)}
              </Tab>
            ))}
        </TabContainer>
        <ContentContainer>{tabs[settingsTab]}</ContentContainer>
      </Container>
    </OrganisationUsersProvider>
  ) : (
    <Redirect to="/projects" />
  );
};
