import styled from "styled-components";
import { ResponsiveContainer } from "recharts";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const GraphContainer = styled(ResponsiveContainer)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  g {
    fill: ${({ theme }) => theme.colours.haloAccent};
  }

  tspan {
    fill: ${({ theme }) => theme.colours.mainText};
  }

  .recharts-label {
    tspan {
      fill: ${({ theme }) => theme.colours.white};
    }
  }
`;

export const StickersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  height: 100%;
`;

export const RunStatusStickerBox = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.colours.foreground};
`;

export const RunStatusSticker = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
`;

export const RunStatusTitle = styled.p`
  opacity: 60%;
  font-size: 12px;
  margin-bottom: ${({ theme }) => theme.spacing.SP0_5};
  width: 100%;
`;

export const RunStatusValue = styled.p`
  font-size: ${({ label, theme }) =>
    label ? theme.fontSize.medium : theme.fontSize.larger};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RunStatusContainer = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Heading = styled.div`
  opacity: 60%;
  font-size: 12px;
  color: ${({ theme }) => theme.colours.mainText};
`;
