import { ACTIONS } from "../../static/constants/actions";

export const BillingUpdateReducer = (state, { type }) => {
  switch (type) {
    case ACTIONS.BILLING_UPDATE:
      return {
        ...state,
        update: true,
      };
    case ACTIONS.NO_BILLING_UPDATE:
      return {
        ...state,
        update: false,
      };
    default:
      return state;
  }
};
