import { useTranslation } from "react-i18next";
import { Modal } from "../../modal";
import { useDeleteRun } from "../../../../views/runView/hooks/useDeleteRun";

export const DeleteIndividualRunModal = ({ run, closeModal }) => {
  const { t } = useTranslation();
  const { deleteRun } = useDeleteRun(run);

  return (
    <Modal
      size="small"
      title={t("runHistory.deleteRunModal.modalTitle")}
      text={t("runHistory.deleteRunModal.modalMessage", {
        runId: run.id,
      })}
      primary={{
        text: t("btnConfirm"),
        onClick: deleteRun,
        props: { danger: true },
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    />
  );
};
