import { useQueryParams } from "../../../../context/hooks";

export const useTranslateData = run => {
  const { getParam } = useQueryParams();

  const successCriteriaRef = getParam("criteria");
  const principleRef = successCriteriaRef?.split(".")[0];
  const guidelineRef = `${principleRef}.${successCriteriaRef?.split(".")[1]}`;

  const principle = run?.accessibility?.find(p => p.ref_id === principleRef);
  const guideline = principle?.guidelines?.find(g => g.ref_id === guidelineRef);
  const successCriteria = guideline?.success_criteria?.find(
    sc => sc.ref_id === successCriteriaRef
  );

  return {
    principle,
    guideline,
    successCriteria,
  };
};
