import { useEffect } from "react";
import { useFetch } from "../hooks";
import { userReducer } from "./user.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { ENDPOINTS } from "../../static/constants/routeConfig";
import { useModal } from "../modal/modal.provider";

const INITIAL_STATE = {
  username: null,
  roles: ["LOADING"],
  isAdmin: false,
  newUserCreated: false,
  requestResetPasswordSuccess: false,
  resetPasswordSuccess: false,
  changePasswordSuccess: false,
  showSplashScreen: false,
  global_roles: [],
  project_roles: [],
  guideOpen: false,
};

const [useHook, UserProvider] = createState("user", INITIAL_STATE, userReducer);

const useUser = () => {
  const { dispatch, guideOpen, ...user } = useHook();
  const { get, body, status } = useFetch();
  const { openModal, MODALS } = useModal();

  const setUser = body => dispatch({ type: ACTIONS.USER, payload: body });

  const clearUser = () => dispatch({ type: ACTIONS.REMOVE_USER });

  const toggleGuideOpen = () => dispatch({ type: ACTIONS.TOGGLE_GUIDE_OPEN });

  const toggleGuideModal = () => openModal(MODALS.FIRST_LOGIN);

  useEffect(() => {
    if (status.isResolved) {
      setUser(body);
      if (body.first_login) toggleGuideOpen();
    }
  }, [status.isResolved]);

  const getUser = () => get(ENDPOINTS.USER);

  return {
    user,
    getUser,
    toggleGuideModal,
    toggleGuideOpen,
    guideOpen,
    clearUser,
    roles: user.roles,
    isAdmin: user.isAdmin,
    username: user.username,
  };
};

export { useUser, UserProvider };
