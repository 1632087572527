import { ACTIONS } from "../../static/constants/actions";

export const jiraConfigReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_JIRA_CONFIG:
      return {
        ...state,
        jiraConfig: payload,
      };
    case ACTIONS.DELETE_JIRA_CONFIG:
      return {
        ...state,
        jiraConfig: {},
      };
    default:
      return state;
  }
};
