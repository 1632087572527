import { useClick, useMenuState } from "@szhsin/react-menu";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  MENU_STATE,
  RELEASE_STATUSES,
} from "../../../static/constants/constants";
import { Chevron, StatusSticker } from "../../releaseView/release.styles";
import { assets } from "../../../static/assets/assets";
import {
  MenuContainer,
  StyledMenu,
  StyledMenuItem,
} from "../../../components/NavigationPane/navigation/navigationParent/navigationParent.styles";
import { useOpenRun } from "../testevolveExploratory/hooks/run/useOpenRun";
import { useCloseRun } from "../testevolveExploratory/hooks/run/useCloseRun";
import { useTheme } from "styled-components";

export const RunStatusContextMenu = ({ run, admin, getRun, disabled }) => {
  const ref = useRef(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const { openRun } = useOpenRun(run.id, getRun);
  const { closeRun } = useCloseRun(run.id, getRun);

  const [menuState, toggleMenu] = useMenuState({ transition: true });

  const menuOpen = menuState.state === MENU_STATE.OPEN;
  const anchorProps = useClick(menuState.state, toggleMenu);
  const isClosed = run.status.toLowerCase() === MENU_STATE.CLOSED;

  return (
    <>
      <StatusSticker
        w={theme.sizing.exploratoryStatusStickerWidth}
        data-test="run-status"
        status={run.status}
        admin={admin}
        isClosed={isClosed}
        onMouseDown={anchorProps.onMouseDown}
        isOpen={menuOpen}
        ref={ref}
        onClick={e => {
          if (disabled) return;
          anchorProps.onClick();
          e.stopPropagation();
        }}
      >
        {t(`releases.${RELEASE_STATUSES[run.status]}`)}
        {!disabled ? (
          <Chevron {...assets.icons.navChevron} isOpen={true} />
        ) : null}
      </StatusSticker>
      {disabled ? null : (
        <MenuContainer>
          <StyledMenu
            transition
            position="auto"
            align="start"
            {...menuState}
            anchorRef={ref}
            onClose={() => {
              toggleMenu(false);
            }}
          >
            {isClosed ? (
              <StyledMenuItem onClick={openRun} data-test="open-release">
                {t("Open Run")}
              </StyledMenuItem>
            ) : (
              <StyledMenuItem onClick={closeRun} data-test="close-release">
                {t("Close Run")}
              </StyledMenuItem>
            )}
          </StyledMenu>
        </MenuContainer>
      )}
    </>
  );
};
