import React from "react";
import { ReactComponent as LogoDark } from "../../../../../../static/assets/arrow-grey.svg";
import { ReactComponent as LogoLight } from "../../../../../../static/assets/arrow-white.svg";

export const Arrow = ({ light, className, ...rest }) => {
  return !light ? (
    <LogoDark className={className} {...rest} />
  ) : (
    <LogoLight className={className} {...rest} />
  );
};
