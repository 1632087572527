import { useEffect } from "react";
import { useFetch } from "../hooks";
import { treeReducer } from "./tree.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { ENDPOINTS } from "../../static/constants/routeConfig";

const initialState = { tree: [] };

const [useHook, TreeProvider] = createState("tree", initialState, treeReducer);

const useTree = () => {
  const { tree, dispatch } = useHook();
  const { get, status, body } = useFetch();

  const getTree = () => get(ENDPOINTS.TREE);

  useEffect(() => {
    if (status.isResolved && body) {
      dispatch({ type: ACTIONS.SET_TREE, payload: body });
    }
  }, [status.isResolved, body]);

  return {
    tree,
    getTree,
    getTreeSuccess: status.isResolved,
  };
};

export { useTree, TreeProvider };
