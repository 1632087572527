import React from "react";
import { useNav } from "../../hooks/useNav";
import {
  StyledNavItem,
  RightChevron,
  IconContainer,
  ChevronContainer,
  TextContainer,
} from "./navigationItem.styles";
import { assets } from "../../../../static/assets/assets";
import { Tooltip } from "../../../tooltip/tooltip";
import { RenameNodeItem } from "../navigationParent/navigationParent.styles";
import { useRenameNode } from "../navigationParent/hooks/node/renameNode";

const SETTINGS_TAB = "settings";

export const NavigationItem = ({
  name,
  id,
  to,
  children,
  active,
  setActive,
  Icon,
  parent,
  isOpen,
  chevron,
  setHovered,
  rename,
  baseUrl,
  closeRenameNode,
  selected,
}) => {
  const { parentLinkHandler, linkHandler, toggleOpen } = useNav(baseUrl);

  const getHighlight = () => {
    if (active.includes(SETTINGS_TAB) && id === SETTINGS_TAB) return true;
    let activeSplit = active.split("/");
    let highlight = activeSplit[activeSplit.length - 1];

    return highlight === to || highlight === id?.toString();
  };

  return (
    <Tooltip content={name}>
      <StyledNavItem
        key={`nav-cont-${name}`}
        data-test={`nav-cont-${name}`}
        active={getHighlight()}
        onMouseEnter={setHovered ? () => setHovered(id) : null}
        onMouseLeave={setHovered ? () => setHovered(false) : null}
        rename={rename}
        selected={selected}
      >
        <IconContainer>
          {Icon ? <Icon size={24} key={`icon-${name}`} /> : null}
        </IconContainer>
        <ChevronContainer
          onClick={e => {
            e.stopPropagation();
            toggleOpen(id);
          }}
        >
          {chevron ? (
            <RightChevron
              {...assets.icons.navChevron}
              isOpen={isOpen(id)}
              active={active === id}
            />
          ) : null}
        </ChevronContainer>
        {rename ? (
          <RenameNodeComponent
            id={id}
            closeRenameNode={closeRenameNode}
            name={name}
          />
        ) : (
          <>
            <TextContainer
              data-test="node-link"
              active={getHighlight()}
              onClick={() =>
                parent
                  ? parentLinkHandler(id, to, setActive)
                  : linkHandler(id, to, setActive)
              }
            >
              <p data-tip data-for={`name-${name}`}>
                {name}
              </p>
            </TextContainer>
            {children}
          </>
        )}
      </StyledNavItem>
    </Tooltip>
  );
};

const RenameNodeComponent = ({ id, closeRenameNode, name }) => {
  const { onBlurHandler, submitHandler, renameRef } =
    useRenameNode(closeRenameNode);

  return (
    <RenameNodeItem>
      <input
        ref={renameRef}
        onBlur={e => onBlurHandler(e, id)}
        autoFocus
        onKeyDown={e => submitHandler(e, id)}
        onChange={e => submitHandler(e, id)}
        placeholder={name}
      />
    </RenameNodeItem>
  );
};
