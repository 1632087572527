import styled, { css } from "styled-components";
import { mediaQuery } from "../../../../utils/theme/mediaQuery";
import { Checkbox } from "../../../../components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: 8px;

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};

  ${mediaQuery.lessThan("XL")`
    width: 100%;
  `}
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? "100%" : "50%")};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  flex-grow: 1;
`;

export const StickerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ isMobile }) => (isMobile ? "2" : "4")},
    1fr
  );
  padding: 8px;
  gap: 8px;

  align-content: stretch;
`;

export const RunStatusStickerBox = styled.div`
  width: 50%;
  padding: 8px;
  height: fit-content;
  background-color: ${({ theme }) => theme.colours.foreground};
`;

export const DoubleRunStatusStickerBox = styled.div`
  width: 100%;
  padding: 8px;
  height: fit-content;
  background-color: ${({ theme }) => theme.colours.foreground};
`;

export const RunStatusSticker = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};

  ${({ clickable }) =>
    clickable &&
    css`
      &:hover {
        cursor: pointer;
        outline: ${({ theme, clickable }) =>
          clickable ? `1px solid ${theme.colours.haloAccent}` : null};
      }
    `}
`;

export const RunStatusTitle = styled.p`
  color: ${({ theme }) => theme.colours.secondaryText};
  font-size: 12px;
  margin-bottom: 4px;
`;

export const RunStatusValue = styled.p`
  font-size: ${({ $label }) => ($label ? "16px" : "24px")};
  font-weight: 400;

  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RunStatusContainer = styled.div`
  box-sizing: content-box;
  width: ${({ isMobile }) => (isMobile ? "100%" : "50%")};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
`;

export const LeftDiv = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const LabelSticker = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing.padding};
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.runPropertyBackground};
`;

export const LabelTitle = styled.p`
  width: 100%;
  display: flex;
  color: ${({ theme }) => theme.colours.secondaryText};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
`;

export const LabelStatusValue = styled.p`
  font-size: ${({ $label }) => ($label ? "16px" : "24px")};
  font-weight: 400;

  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CheckboxTitle = styled.p`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  color: ${({ theme }) => theme.colours.primaryText};
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const MyCheckbox = styled(Checkbox)`
  display: flex;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
  align-items: center;

  ${mediaQuery.lessThan("XL")`
    width: 100%;
  `}
`;
