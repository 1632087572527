import Select from "react-select";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const PartnerMessageContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colours.foreground};
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.padding};
    border-radius: ${({ theme }) => theme.spacing.paddingSM};
  `}
`;

export const SectionContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  gap: ${({ theme }) => theme.spacing.paddingSM};
  display: flex;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
`;

export const StyledSelect = styled(Select)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colours.background};
  width: 100%;

  & .Select__control {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    box-shadow: none;

    &:hover {
      border-color: ${({ theme }) => theme.colours.haloAccentLight};
    }
  }

  & .Select__control--is-focused {
    border-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__menu {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__single-value {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .Select__multi-value {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value__label {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value__remove {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    border-top-right-radius: ${({ theme }) => theme.borders.radius};
    border-bottom-right-radius: ${({ theme }) => theme.borders.radius};
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__option {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
  }

  & .Select__option--is-focused {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__option--is-selected {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__indicators {
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }
  z-index: 90;
`;

export const Image = styled.img`
  display: block;
  max-height: ${({ theme }) => theme.sizing.btnHeight};
  max-width: ${({ theme }) => theme.sizing.btnHeight};
  width: auto;
  height: auto;
`;

export const StyledInfoSpan = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ theme }) => theme.sizing.btnHeight};
`;
