import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.paddingSM};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  height: 50%;
  min-height: ${({ theme }) => theme.sizing.latestRunWidgetHeight};
  flex-grow: 1;

  height: ${({ theme }) => theme.sizing.runTrendsHeight};
  min-height: ${({ theme }) => theme.sizing.runTrendsHeight};
`;

export const Sticker = styled.div`
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.highlight};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const TrendsTitle = styled.h1`
  padding-left: ${({ theme }) => theme.spacing.padding};
`;
