import { SelectContainer, SelectLabel, StyledCreatable } from "./select.styles";

const UNDEFINED = "undefined";

export const CreatableSelect = ({
  setter,
  options,
  onChange,
  isClearable,
  label,
  error,
}) => {
  return (
    <SelectContainer>
      {label ? <SelectLabel>{label}</SelectLabel> : null}
      <StyledCreatable
        classNamePrefix="Select"
        isClearable={isClearable}
        error={error}
        options={
          Array.isArray(options)
            ? options.map(i => ({ label: i, value: i }))
            : options
        }
        onChange={
          setter
            ? e => setter(typeof e?.value === UNDEFINED ? null : e.value)
            : onChange
        }
      />
    </SelectContainer>
  );
};
