import { useClick, useMenuState } from "@szhsin/react-menu";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ACCESSIBILITY_STATUSES,
  MENU_STATE,
} from "../../../../static/constants/constants";
import { Chevron, StatusSticker } from "./rightWidget.styles";
import { assets } from "../../../../static/assets/assets";
import {
  MenuContainer,
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/NavigationPane/navigation/navigationParent/navigationParent.styles";

export const CriteriaStatusMenu = ({
  status,
  disabled,
  editStatus,
  refId,
  runId,
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  const [menuState, toggleMenu] = useMenuState({ transition: true });

  const menuOpen = menuState.state === MENU_STATE.OPEN;
  const anchorProps = useClick(menuState.state, toggleMenu);

  return (
    <>
      {disabled ? null : (
        <MenuContainer>
          <StyledMenu
            transition
            position="auto"
            align="start"
            {...menuState}
            anchorRef={ref}
            onClose={() => {
              toggleMenu(false);
            }}
          >
            {Object.values(ACCESSIBILITY_STATUSES)
              .filter(e => e !== status.status?.toLowerCase())
              .map(val => (
                <StyledMenuItem
                  key={`${val}-option`}
                  data-test={`${val}-option`}
                  onClick={() => editStatus(runId, refId, val.toUpperCase())}
                >
                  {t(`runView.testevolveaccessibility.${val}`)}
                </StyledMenuItem>
              ))}
          </StyledMenu>
        </MenuContainer>
      )}
      <StatusSticker
        id="run-status-sticker"
        data-test="run-status"
        status={status?.status}
        isClosed={false}
        onMouseDown={anchorProps.onMouseDown}
        isOpen={menuOpen}
        ref={ref}
        onClick={e => {
          if (disabled) return;
          anchorProps.onClick();
          e.stopPropagation();
        }}
      >
        {t(
          `runView.testevolveaccessibility.${
            ACCESSIBILITY_STATUSES[status?.status] ||
            ACCESSIBILITY_STATUSES.NOT_STARTED
          }`
        )}
        {!disabled ? (
          <Chevron {...assets.icons.navChevron} isOpen={true} />
        ) : null}
      </StatusSticker>
    </>
  );
};
