import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { JiraLogo } from "../elements/logo/JiraLogo";
import { TestEvolveLogo } from "../../../../../components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isNotDesktop }) =>
    isNotDesktop ? "center" : "flex-start"};
  align-items: ${({ isNotDesktop }) =>
    isNotDesktop ? "center" : "flex-start"};
  gap: ${({ theme }) => theme.spacing.padding};
  min-width: 7rem;
  position: relative;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
  justify-content: ${({ isNotDesktop }) =>
    isNotDesktop ? "center" : "flex-start"};
  min-width: 20rem;
  color: ${({ theme }) => theme.colours.mainText};
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
`;

export const StyledField = styled.input`
  width: 25%;
  appearance: none;
  @media all and (-ms-high-contrast: none) {
    & {
      box-sizing: content-box;
    }
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.paddingSM};
    background: ${theme.colours.background};
    height: ${theme.sizing.btnHeight};
    line-height: inherit;
    border: 1px solid ${({ theme }) => theme.colours.foreground};
    border-radius: ${theme.borders.radius};
    color: ${theme.colours.mainText};
    border-color: ${({ theme, invalid }) => invalid && theme.colours.error};

    &::-moz-placeholder {
      line-height: 0;
    }
  `};
`;

export const ConfigFormContainer = styled.div`
  display: flex;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
  flex-wrap: wrap;
  justify-content: ${({ isNotDesktop }) =>
    isNotDesktop ? "center" : "flex-start"};
  align-items: center;
  align-content: ${({ isNotDesktop }) =>
    isNotDesktop ? "center" : "flex-start"};
  gap: ${({ theme }) => theme.spacing.padding};
  width: 100%;
  ${({ isNotDesktop }) =>
    isNotDesktop &&
    css`
      width: 100%;
      input {
        width: 100%;
      }
    `};
`;

export const StyledFieldContainer = styled.div`
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  align-self: auto;
  border: 0;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StyledMainContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  flex-direction: row;
`;

export const HighlightingMainContainer = styled(StyledMainContainer)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colours.foreground};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colours.foreground};
  }
`;

export const StyledBugLink = styled(Link)`
  color: ${({ theme }) => theme.colours.accent};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const StyledLogo = styled(TestEvolveLogo)`
  width: 100%;
  height: auto;
  display: flex;
  max-width: ${({ theme }) => theme.sizing.logoSize};
  padding: ${({ theme }) => `${theme.spacing.gutter} 0`};
`;

export const StyledJiraLogo = styled(JiraLogo)`
  ${({ theme, size, isNotDesktop }) => css`
    width: ${isNotDesktop ? "100%" : "400px"};
    height: auto;
    display: flex;
    max-width: ${size === "small" && theme.sizing.logoSize};
    max-width: ${size === undefined && theme.sizing.logoSize};
    max-width: ${size === "large" && theme.sizing.logoSizeLarge};
    max-width: ${isNotDesktop && theme.sizing.jiraLogoWidth};

    padding: ${({ theme }) => `${theme.spacing.gutter} 0`};
  `}
`;

export const LogosContainer = styled.div`
  display: flex;
  div {
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
`;

export const LogoContainer = styled.div``;

export const StyledMessage = styled.p`
  width: 100%;
  text-align: center;
  max-width: 100%;
  margin: 0 auto 30px;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
  font-weight: 300;
`;
