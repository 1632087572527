import { useState } from "react";
import { SuccessCriteria } from "./successCriteria";
import { assets } from "../../../../static/assets/assets";
import {
  GuidelineContainer,
  TopContainer,
} from "../testEvolveAccessiblityRunView.styles";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import {
  ChevronContainer,
  RightChevron,
} from "../../../../components/NavigationPane/navigation/navigationItem/navigationItem.styles";
import { RunStatusGraph } from "./runStatusGraph";
import { useTranslation } from "react-i18next";

export const Guideline = ({ guideline }) => {
  const { t } = useTranslation();
  const history = useHistoryWithParams();
  const [isOpen, setIsOpen] = useState(false);

  const changeGuideline = s =>
    history.push(`${location.pathname}?${"criteria"}=${s}`);

  const onClick = id => {
    changeGuideline(id);
    setIsOpen(e => !e);
  };

  return (
    <>
      <GuidelineContainer
        data-test="section-guideline"
        isOpen={isOpen}
        onClick={() => onClick(guideline.ref_id)}
      >
        <p>{t("runView.testevolveaccessibility.guideline")}</p>
        <TopContainer>
          <h3>
            {guideline.ref_id}. {guideline.title}
          </h3>
          <ChevronContainer>
            <RightChevron {...assets.icons.navChevron} isOpen={isOpen} />
          </ChevronContainer>
        </TopContainer>
        <RunStatusGraph criteria={guideline.criteria} />
      </GuidelineContainer>
      {isOpen
        ? guideline.success_criteria.map(successCriteria => (
            <SuccessCriteria
              key={`success-criteria-${successCriteria.ref_id}`}
              successCriteria={successCriteria}
            />
          ))
        : null}
    </>
  );
};
