import { useSortingAndFiltering } from "./hooks/useSortingAndFiltering";
import { ReleaseCardContainer } from "./releaseCardContainer";
import { ReleaseDetails } from "./releaseDetails";
import { ReleaseSortAndFilter } from "./releaseSortAndFilter";
import { Container } from "./releasesView.styles";

export const ReleasesView = () => {
  const sortAndFilter = useSortingAndFiltering();

  return (
    <Container>
      <ReleaseDetails />
      <ReleaseSortAndFilter {...sortAndFilter} />
      <ReleaseCardContainer getNextPage={sortAndFilter.getNextPage} />
    </Container>
  );
};
