import { useEffect, useState } from "react";
import { useInsights } from "../testevolve/insights/useInsights";
import { useRunData } from "./runData";
import {
  RUN_STATUSES,
  TEST_CATEGORIES,
} from "../../../static/constants/constants";
import { useSubscription } from "../../../context/subscription/subscription.provider";
import { useRunStatus } from "../../../context/runStatus/runStatus.provider";
import { useParams } from "react-router-dom";
import { useQueryParams } from "../../../context/hooks";

export const useRunFilter = () => {
  const { getAllParams } = useQueryParams();
  const { attempts, status } = getAllParams();

  const [tagsFilterOptions, setTagsFilterOptions] = useState([]);
  const [statusFilterOptions, setStatusFilterOptions] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);
  const { getRunData, runData } = useRunData();
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  const initialStatus = status ? [status] : [];
  const initialAttempts = attempts ? attempts : false;
  const [statusFilter, setStatusFilter] = useState(initialStatus);
  const [attemptsFilter, setAttemptsFilter] = useState(initialAttempts);

  const { isPaid } = useSubscription();
  const { runStatus, setRunStatusLatest } = useRunStatus();
  const { runType, runId } = useParams();

  const canGetInsights = () =>
    runData?.status === RUN_STATUSES.COMPLETE &&
    isPaid &&
    runData?.is_latest &&
    runType === TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL;

  const {
    insightsError,
    insightsSentences,
    generatingInsights,
    getScenarioInsights,
  } = useInsights(runData);

  useEffect(() => {
    if (runData && runStatus) {
      createTagsFilterOptions();
      createStatusFilterOptions();
    }
  }, [runData?.updated_time]);

  useEffect(() => {
    getRunData(runId);
  }, []);

  useEffect(() => {
    if (canGetInsights() && runData && runData.id) getScenarioInsights(runData);
    if (runData?.is_latest) setRunStatusLatest(true);
  }, [runData]);

  const createTagsFilterOptions = () => {
    const featureTags = [
      ...new Set(runData.features.map(feature => feature.tags).flat()),
    ];
    const scenarioTags = [
      ...new Set(
        runData.features
          .map(feature => [
            ...new Set(feature.scenarios.map(scenario => scenario.tags).flat()),
          ])
          .flat()
      ),
    ];

    const tags = [...new Set([...featureTags, ...scenarioTags])].filter(
      item => !!item
    );
    setTagsFilterOptions(tags.map(tag => ({ label: tag, value: tag })));
  };

  const createStatusFilterOptions = () => {
    const statuses = [
      ...new Set(
        runData.features
          .map(feature => [
            ...new Set(feature.scenarios.map(scenario => scenario.status)),
          ])
          .flat()
      ),
    ];
    setStatusFilterOptions(
      statuses.map(status => ({ label: status, value: status }))
    );
  };

  return {
    tagsFilterOptions,
    statusFilterOptions,
    setStatusFilter,
    statusFilter,
    tagsFilter,
    setTagsFilter,
    attemptsFilter,
    setAttemptsFilter,
    getScenarioInsights: () => getScenarioInsights(runData),
    insightsSentences,
    generatingInsights,
    insightsError,
    showFilterOptions,
    setShowFilterOptions,
  };
};
