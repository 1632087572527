import styled from "styled-components";
import { StyledLink } from "../../components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  height: 72px;
  min-height: 72px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
  border: 1px black solid;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  height: calc(100% - 72px);
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const TabContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const Tab = styled(StyledLink)`
  cursor: pointer;
  height: 40px;
  width: 120px;
  border-radius: 12px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colours.haloAccentLight : theme.colours.foreground};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.mainText};

  &:focus {
    color: ${({ theme }) => theme.colours.mainText};
  }
`;
