import { useTranslation } from "react-i18next";
import { Modal } from "../../modal";

export const ReportModal = ({ closeModal, preview }) => {
  const { t } = useTranslation();

  return (
    <Modal
      toggleModal={() => closeModal()}
      primary={{
        text: t("btnOk"),
        onClick: closeModal,
      }}
      size="email"
      height="medium"
    >
      <div dangerouslySetInnerHTML={{ __html: preview }} />
    </Modal>
  );
};
