import { useEffect } from "react";
import { roles } from "../../static/constants/constants";
import { ENDPOINTS } from "../../static/constants/routeConfig";
import { createState } from "../../utils/context";
import { useFetch, usePermissions } from "../hooks";
import { organisationUsersReducer } from "./organisationUsers.reducer";

const initialState = {
  users: [],
  initialUsers: {},

  activeUsers: {},
  initialActiveUsers: {},
  updatedActiveUsers: [],

  adminUsers: {},
  initialAdminUsers: {},
  updatedAdminUsers: [],

  partnerUsers: {},
  initialPartnerUsers: {},
  updatedPartnerUsers: [],

  initialPartnerAdmins: {},

  userProjects: {},
  initialUserProjects: {},
  updatedUserProjects: {},
};

const [useHook, OrganisationUsersProvider] = createState(
  "organisationUsers",
  initialState,
  organisationUsersReducer
);

const useOrganisationUsers = () => {
  const { get, status, body } = useFetch();
  const { isPartnerAdmin } = usePermissions();

  const {
    dispatch,
    updatedUserProjects,
    updatedPartnerUsers,
    updatedAdminUsers,
    updatedActiveUsers,
    ...state
  } = useHook();

  const setUsers = users => dispatch({ type: "SET_USERS", payload: users });

  const setInitialUsers = users =>
    dispatch({ type: "SET_INITIAL_USERS", payload: users });

  const getPartnerRoles = role =>
    role.key === roles.PARTNER_ADMIN || role.key === roles.PARTNER_USER;

  const findEditableUsers = user =>
    isPartnerAdmin() || !user.global_roles.some(getPartnerRoles);

  const findNonEditableUsers = user =>
    !isPartnerAdmin() && user.global_roles.some(getPartnerRoles);

  const objectLength = object => Object.keys(object).length;

  const updatedUsers = {
    ...updatedUserProjects,
    ...updatedPartnerUsers,
    ...updatedAdminUsers,
    ...updatedActiveUsers,
  };

  const getOrganisationUsers = () => get({ path: ENDPOINTS.USERS_LIST.path });

  useEffect(() => {
    if (status.isResolved && body) {
      setUsers(body);
      setInitialUsers(body);
    }
  }, [status.isResolved, body]);

  return {
    ...state,
    dispatch,
    setUsers,
    organisationUsers: state.users,
    setInitialUsers,
    updatedAdminUsers,
    updatedActiveUsers,
    updatedUserProjects,
    updatedPartnerUsers,
    getOrganisationUsers,
    saveButtonActive: !!objectLength(updatedUsers),
    editableUsers: () => state.users?.filter(findEditableUsers),
    nonEditableUsers: () => state.users?.filter(findNonEditableUsers),
  };
};

export { useOrganisationUsers, OrganisationUsersProvider };
