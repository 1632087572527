import { TestCase } from "../testCase/testCase";
import {
  FeatureBody,
  FeatureHeader,
  StyledFeatureContainer,
} from "../cypressRunReport.styles";

export const TestSuite = ({
  suite,
  isDark,
  uuid,
  JiraBugButton,
  isMobile,
  category,
  suiteIndex,
}) => {
  return suite && suite?.test_cases ? (
    <StyledFeatureContainer
      data-test="suite"
      key={uuid()}
      id={`#${suite.file.replace(/ /g, "")}`}
    >
      <FeatureHeader isDark={isDark}>
        <h2 data-test={suite.file} name="suite-title">
          {suite.file}
        </h2>
      </FeatureHeader>
      <FeatureBody>
        {Array.isArray(suite.test_cases) ? (
          suite.test_cases?.map((testcase, caseIndex) => (
            <TestCase
              testCase={testcase}
              uuid={uuid}
              key={`scenario-${caseIndex}`}
              isDark={isDark}
              JiraBugButton={JiraBugButton}
              suite={suite}
              isMobile={isMobile}
              category={category}
              scenarioIndex={caseIndex}
              suiteIndex={suiteIndex}
              suiteId={suite.id}
            />
          ))
        ) : (
          <TestCase
            testCase={suite.testcase}
            uuid={uuid}
            key={"case"}
            isDark={isDark}
            JiraBugButton={JiraBugButton}
            suite={suite}
            isMobile={isMobile}
            category={category}
            scenarioIndex={0}
            suiteIndex={suiteIndex}
            suiteId={suite.id}
          />
        )}
      </FeatureBody>
    </StyledFeatureContainer>
  ) : null;
};
