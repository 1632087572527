import { useEffect } from "react";
import { useFetch } from "../../../context/hooks";
import { useNotifications } from "../../../context/hooks/useNotifications/useNotifications";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useNode } from "../../../context/nodes/nodes.provider";
import { useParams } from "react-router-dom";
import { useModal } from "../../../context/modal/modal.provider";
import { MODALS } from "../../../context/modal/modals";

export const useMoveRun = run => {
  const { nodeId } = useParams();

  const { patch, error, status } = useFetch();
  const { openModal, closeModal } = useModal();
  const { createNotification, types } = useNotifications();

  const { nodeSummary, getNodeSummary } = useNode();

  const openMoveRunModal = e => {
    e.syntheticEvent.stopPropagation();
    openModal(MODALS.MOVE_RUN, { nodeSummary, run });
  };

  const moveRun = (nodeSummary, run, selectedNodeId) => {
    const endpoint = {
      ...ENDPOINTS.MOVE_RUN,
      path: ENDPOINTS.MOVE_RUN.path
        .replace(":nodeId", nodeSummary.id)
        .replace(":category", run.category)
        .replace(":label", encodeURI(run.label)),
    };
    patch(endpoint, { new_node_id: selectedNodeId });
  };

  useEffect(() => {
    if (status.isResolved) {
      createNotification(types.SUCCESS, "nodeView.moveRunSuccess");
      closeModal(false);
      getNodeSummary(nodeId);
    }
    if (status.isRejected && error) {
      createNotification(types.ERROR, `errors.moveRun.${error.status}`);
    }
  }, [error, status.isRejected, status.isResolved]);

  return {
    moveRun,
    openMoveRunModal,
  };
};
