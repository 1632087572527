import styled, { useTheme, keyframes } from "styled-components";

export const StopwatchIcon = () => {
  const theme = useTheme();

  return (
    <InsideIconHolder>
      <StyledSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <TopButton>
          <path
            fill="none"
            stroke={theme.colours.mainText}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="20"
            d="M128 8v29"
          />
          <path
            fill="none"
            stroke={theme.colours.mainText}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="20"
            d="M112 8h32"
          />
        </TopButton>
        <circle
          cx="128"
          cy="143"
          r="105"
          fill={theme.colours.foreground}
          stroke={theme.colours.mainText}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="20"
        />
        <BigHand
          fill="none"
          stroke={theme.colours.mainText}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="20"
          d="M128 72v71"
        />
      </StyledSVG>
    </InsideIconHolder>
  );
};

const InsideIconHolder = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSVG = styled.svg`
  overflow: visible;
`;

const topButtonKeyframe = keyframes`
  0% {transform: translate(0,0);}
  4% {transform: translate(0,-8px);}
  10% {transform: translate(0px,15px);}
  16% {transform: translate(0,-5px);}
  20% {transform: translate(0,0);}
`;

const TopButton = styled.g`
  animation: ${topButtonKeyframe} 3s ease 0s infinite;
  -webkit-animation: ${topButtonKeyframe} 3s ease 0s infinite;
  -moz-animation: ${topButtonKeyframe} 3s ease 0s infinite;
`;

const bigHandKeyframe = keyframes`
  0% {transform: rotate(0deg);}
  25% {transform: rotate(360deg);}
  100% {transform: rotate(360deg);}
`;

const BigHand = styled.path`
  transform-origin: 50% 55.85%;
  animation: ${bigHandKeyframe} 3s cubic-bezier(0.93, 0.3, 0.3, 0.93) 0.25s
    infinite;
  -webkit-animation: ${bigHandKeyframe} 3s cubic-bezier(0.93, 0.3, 0.3, 0.93)
    0.25s infinite;
  -moz-animation: ${bigHandKeyframe} 3s cubic-bezier(0.93, 0.3, 0.3, 0.93) 0.25s
    infinite;
`;
