import React from "react";
import { useTranslation } from "react-i18next";
import { assets } from "../../../../../static/assets/assets";
import {
  Chevron,
  StickerLabel,
  StickerValue,
  StickerContainer,
  VulnerabilitiesContainer,
} from "./vulnerabilities.styles.js";

export const Vulnerabilities = ({ data }) => {
  const { t } = useTranslation();

  return (
    <VulnerabilitiesContainer>
      <StickerContainer data-test="critical-sticker">
        <Chevron
          {...assets.icons.navChevron}
          color={({ theme }) => theme.colours.severity.highest}
        />
        <StickerValue>{data.critical}</StickerValue>
        <StickerLabel>
          {t("runCard.npmaudit.vulnerabilities.critical")}
        </StickerLabel>
      </StickerContainer>
      <StickerContainer data-test="high-sticker">
        <Chevron
          {...assets.icons.navChevron}
          color={({ theme }) => theme.colours.severity.high}
          rotate="45"
        />
        <StickerValue>{data.high}</StickerValue>
        <StickerLabel>
          {t("runCard.npmaudit.vulnerabilities.high")}
        </StickerLabel>
      </StickerContainer>
      <StickerContainer data-test="moderate-sticker">
        <Chevron
          {...assets.icons.navChevron}
          color={({ theme }) => theme.colours.severity.medium}
          rotate="90"
        />
        <StickerValue>{data.moderate}</StickerValue>
        <StickerLabel>
          {t("runCard.npmaudit.vulnerabilities.moderate")}
        </StickerLabel>
      </StickerContainer>
      <StickerContainer data-test="low-sticker">
        <Chevron
          {...assets.icons.navChevron}
          color={({ theme }) => theme.colours.severity.low}
          rotate="180"
        />
        <StickerValue>{data.low}</StickerValue>
        <StickerLabel>{t("runCard.npmaudit.vulnerabilities.low")}</StickerLabel>
      </StickerContainer>
      <StickerContainer data-test="info-sticker">
        <StickerValue>{data.info}</StickerValue>
        <StickerLabel>
          {t("runCard.npmaudit.vulnerabilities.info")}
        </StickerLabel>
      </StickerContainer>
    </VulnerabilitiesContainer>
  );
};
