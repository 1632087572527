import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.error};
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export const Details = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.paddingMD};
  display: flex;
  justify-content: space-between;
`;

export const GreenText = styled.p`
  color: ${({ theme }) => theme.colours.haloAccent};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: right;
  margin: 0;
`;

export const ContainerMsg = styled.div`
  margin-top: ${({ theme }) => theme.spacing.paddingMD};
  p {
    text-align: center;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin: 0;
  @media (min-width: 500px) {
    transform: translateX(-40px);
  }

  td {
    text-align: center;

    p {
      text-align: center;
    }
  }

  th {
    text-align: center;
  }
`;

export const PurpleSpan = styled.span`
  color: ${({ theme }) => theme.colours.accent};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const StyledContainer = styled.div`
  min-width: 7rem;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.paddingMD}
    ${({ theme }) => theme.spacing.paddingMD};
  p {
    text-align: center;
    line-height: 1.5em;
  }
`;
