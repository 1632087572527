import styled from "styled-components";
import { readableColor } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => theme.sizing.featuresListHeight};
`;

export const SectionHeader = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.sizing.sectionHeaderHeight};
  border-left: ${({ theme }) => theme.spacing.paddingSM} solid
    ${({ theme, color }) => theme.colours.status[color]};
  margin-top: ${({ first, theme }) =>
    first ? "0px" : theme.spacing.paddingMD};

  h2 {
    margin-left: ${({ theme }) => theme.spacing.paddingXSM};
    font-size: 18px;
    margin-bottom: ${({ theme }) => theme.spacing.paddingXSM};
  }

  p {
    margin-left: ${({ theme }) => theme.spacing.paddingXSM};
    font-size: 12px;
    color: ${({ theme }) => theme.colours.altText};
  }
`;

export const StyledScenariosTable = styled.table`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.padding};

  th {
    color: ${({ theme }) => theme.colours.mainText};
    font-size: 12px;
    text-align: center;
    font-weight: normal;
  }

  th:first-child {
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => readableColor(theme.colours.background)};
  }
`;

export const NoScenariosP = styled.p`
  padding-top: ${({ theme }) => theme.spacing.paddingSM};
`;
