import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  appearance: none;

  @media all and (-ms-high-contrast: none) {
    & {
      box-sizing: content-box;
    }
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.padding};
    background-color: ${theme.colours.background};
    height: ${theme.sizing.btnHeight};
    line-height: inherit;
    border: ${({ theme, error }) =>
      error
        ? `1px solid ${theme.colours.error}`
        : `1px solid ${theme.colours.background}`};
    border-radius: ${theme.borders.radius};
    color: ${theme.colours.mainText};
    margin-bottom: ${theme.spacing.paddingSM};
    &::-moz-placeholder {
      /* Firefox 19+ */
      line-height: 0;
    }
  `};
`;

export const StyledDatePickerPopper = styled.div`
  & .react-datepicker {
    background-color: ${({ theme }) => theme.colours.foreground};
    box-shadow: ${({ theme }) => theme.decoration.cardShadow};
    color: ${({ theme }) => theme.colours.mainText};
    border: none;
  }

  & .react-datepicker-popper {
    position: fixed !important;
    margin-top: -${({ theme }) => theme.spacing.paddingSM}; !important;
  }

  input {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .react-datepicker__input-container {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .react-datepicker__header {
    background-color: ${({ theme }) => theme.colours.foreground};
    border: none;
  }

  & .react-datepicker__current-month {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .react-datepicker__day-name {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .react-datepicker__day {
    color: ${({ theme }) => theme.colours.mainText};

    &:hover {
      background-color: ${({ theme }) => theme.colours.haloAccentLight};
    }

    &:focus {
      background-color: ${({ theme }) => theme.colours.haloAccent};
    }
  }

  & .react-datepicker__day--disabled {
    background-color: ${({ theme }) => theme.colours.background};
    &:hover {
      background-color: ${({ theme }) => theme.colours.background};
    }
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: 0.3rem;
  }

  & .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.white};
  }

  & .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.white};
  }
`;
