import { useHistory } from "react-router-dom";
import { Container } from "./sharedReleaseModal.styles";
import { Divider, Modal, Button } from "../../..";
import { routes } from "../../../../router/routes";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../../../../context/hooks";

export const SharedReleaseModal = ({ closeModal, releaseId }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { getParam } = useQueryParams();
  const project = getParam("project");

  const redirectUrl = `${routes.RELEASE.path}?project=${project}`.replace(
    ":releaseId",
    releaseId
  );

  return (
    <Modal
      secondary={{
        onClick: closeModal,
        text: t("btnCancel"),
      }}
      text={t("sharedRunView.haveAnAccount")}
      title={t("sharedRunView.notSignedIn")}
    >
      <Container>
        <Button onClick={() => history.push(redirectUrl)}>
          {t("sharedRunView.toSignIn")}
        </Button>
        <Divider>{t("signin.or")}</Divider>
        <p>{t("sharedRunView.requestAccess")}</p>
      </Container>
    </Modal>
  );
};
