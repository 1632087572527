import React from "react";
import { base } from "./base";
import * as themes from "./theme";
import { normalize } from "polished";
import { useTheme } from "../context/theme/theme.provider";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { useApp } from "../context/app/app.provider";

const GlobalStyle = createGlobalStyle`
  ${normalize()}
  ${base}
`;

export const Theme = ({ children }) => {
  const app = useApp();
  const { theme, isDark } = useTheme();
  const providedTheme = themes[theme] || themes["light"];

  return (
    <ThemeProvider theme={{ ...providedTheme, ...app, isDark }}>
      <GlobalStyle data-test="global-style" />
      {children}
    </ThemeProvider>
  );
};
