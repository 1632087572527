import { ACTIONS } from "../../static/constants/actions";

export const navReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.OPEN_NAV:
      return {
        ...state,
        navIsOpen: true,
      };
    case ACTIONS.CLOSE_NAV:
      return {
        ...state,
        navIsOpen: false,
      };
    case ACTIONS.SET_OPEN:
      return {
        ...state,
        open: [...state.open, payload],
      };
    case ACTIONS.SET_CLOSED:
      return {
        ...state,
        open: state.open.filter(id => id != payload),
      };
    default:
      return state;
  }
};
