import styled from "styled-components";

export const ContainerMsg = styled.div`
  margin-top: ${({ theme }) => theme.spacing.paddingMD};
  p {
    text-align: center;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.error};
  margin: 0;
  font-size: ${({ theme }) => theme.typography.minFontSize};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;

  gap: ${({ theme }) => theme.spacing.paddingLG};

  p {
    text-align: center;
  }
`;
