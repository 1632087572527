import React from "react";
import { StyledDatePicker, StyledDatePickerPopper } from "./datePicker.styles";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { StyledInputContainer, StyledLabel } from "../Input/Input";
import { DATE_FORMAT_YYYMMDD } from "../../static/constants/constants";

export const DatePicker = ({
  placeholder,
  onChange,
  name,
  value,
  error,
  future,
  past,
  present,
  minDate,
  maxDate,
  margin,
  width,
  label,
  ...rest
}) => {
  const getMaxDate = () => {
    if (maxDate) return maxDate;
    if (past && present && future) return null;
    if (past && present) return new DateTime({}).startOf("day").toJSDate();
    if (past)
      return new DateTime({}).startOf("day").plus({ days: -1 }).toJSDate();
    return null;
  };

  const getMinDate = () => {
    if (minDate) return minDate;
    if (past && present && future) return null;
    if (present && future) return new DateTime({}).startOf("day").toJSDate();
    if (future)
      return new DateTime({}).startOf("day").plus({ days: 1 }).toJSDate();
    return null;
  };

  return (
    <StyledInputContainer margin={margin} width={width}>
      <StyledLabel>{label}</StyledLabel>
      <StyledDatePicker
        popperContainer={StyledDatePickerPopper}
        selected={value}
        dateFormat={DATE_FORMAT_YYYMMDD}
        name={name}
        onChange={onChange}
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        autoComplete={"off"}
        placeholderText={placeholder}
        error={error}
        showPopperArrow={false}
        {...rest}
      />
    </StyledInputContainer>
  );
};
