import { useEffect } from "react";
import {
  NodeProvider,
  useNode,
} from "../../../../context/nodes/nodes.provider";
import {
  ButtonContainer,
  ModalContainer,
  NodeDetailsContainer,
  StyledModal,
} from "../../../NavigationPane/navigation/navigationParent/navigationParent.styles";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Button/Button";
import { useDeleteNode } from "../../../NavigationPane/navigation/navigationParent/hooks/node/deleteNode";

export const DeleteNodeModalContent = ({ nodeDetails, closeModal }) => {
  const { t } = useTranslation();
  const { deleteNode } = useDeleteNode(closeModal);
  const { nodeSummary, getNodeSummary } = useNode();

  useEffect(() => {
    if (nodeDetails) getNodeSummary(nodeDetails.id);
  }, [nodeDetails]);

  const noRuns = nodeSummary?.labels?.length === 0;

  return (
    <>
      <ModalContainer>
        <StyledModal>
          <h1>{t("deleteNode.title")}</h1>
          {!noRuns ? (
            <>
              <p>{t("deleteNode.nodeHasRunsText")}</p>
              <p>{t("deleteNode.nodeHasRunsText2")}</p>
            </>
          ) : (
            <>
              <p>{t("deleteNode.supportingText1")}</p>
              <NodeDetailsContainer>
                <p>{nodeDetails.name}</p>
                <p>{nodeDetails.id}</p>
              </NodeDetailsContainer>
              <p>{t("deleteNode.supportingText2")}</p>
            </>
          )}
          <ButtonContainer>
            <Button secondary onClick={closeModal}>
              {t("btnCancel")}
            </Button>
            {noRuns ? (
              <Button danger onClick={() => deleteNode(nodeDetails.id)}>
                {t("btnDelete")}
              </Button>
            ) : null}
          </ButtonContainer>
        </StyledModal>
      </ModalContainer>
    </>
  );
};

export const DeleteNodeModal = props => (
  <NodeProvider>
    <DeleteNodeModalContent {...props} />
  </NodeProvider>
);
