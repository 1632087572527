import styled from "styled-components";
import Select from "react-select";
import { StyledLink } from "../../../../components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const AddUserSelect = styled(Select)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};
  color: ${({ theme }) => theme.colours.mainText};

  & .Select__control {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    box-shadow: none;

    &:hover {
      border-color: ${({ theme }) => theme.colours.haloAccent};
    }
  }

  & .Select__single-value {
    color: ${({ theme }) => theme.colours.white};
  }

  & .Select__input-container {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .Select__control--is-focused {
    border-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__menu {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value__label {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value__remove {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    border-top-right-radius: ${({ theme }) => theme.borders.radius};
    border-bottom-right-radius: ${({ theme }) => theme.borders.radius};
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__option {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
  }

  & .Select__option--is-focused {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__option--is-selected {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__indicators {
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }
  z-index: 90;
`;

export const OrgSettingsLink = styled(StyledLink)`
  font-style: italic;
  color: ${({ theme }) => theme.colours.bulletColour};
`;
