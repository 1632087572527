import React from "react";
import {
  Content,
  HorizontalContainer,
  PageContainer,
  TitleContainer,
} from "./home.styles";
import { LatestRuns } from "./latestRuns/latestRuns";
import { UpcomingReleases } from "./upcomingReleases/upcomingReleases";
import { InProgressRuns } from "./inProgressRuns/inProgressRuns";
import { useTranslation } from "react-i18next";
import { useHome } from "./context/home.context";
import { GettingStarted } from "./gettingStarted/gettingStarted";
import { usePolling } from "../../context/hooks/poll/usePolling";
import { POLLING_INTERVAL } from "../../static/constants/constants";

export const Home = () => {
  const { t } = useTranslation();
  const { showGettingStarted, getInProgressRuns, getLatestRuns } = useHome();

  usePolling(() => {
    getInProgressRuns();
    getLatestRuns();
  }, [POLLING_INTERVAL]);

  return (
    <PageContainer>
      <TitleContainer>
        <h1>{t("projectHome.title")}</h1>
      </TitleContainer>
      <HorizontalContainer>
        <Content>
          {showGettingStarted ? (
            <GettingStarted />
          ) : (
            <>
              <InProgressRuns />
              <LatestRuns />
            </>
          )}
          <UpcomingReleases />
        </Content>
      </HorizontalContainer>
    </PageContainer>
  );
};
