import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import {
  StyledContainer,
  StyledSubSection,
  StyledInfoDetails,
  StyledHeader,
  StyledKey,
  InfoItem,
  StyledColumn,
  ButtonContainer,
  RequiredText,
  StyledField,
  StyledErrorMessage,
  StyledSelect,
} from "./billingAddressDetails.styles";
import { Button } from "../../../../../components";
import { countryData } from "../../../../../static/constants/countryCodes";
import {
  ADDRESS_HEADERS as addressHeaders,
  ADDRESS_OPTIONS,
} from "../../../../../static/constants/constants";

export const BillingDetailsSection = ({ billing }) => {
  const {
    billingDetails,
    putBillingDetails,
    isLoading,
    editMode,
    setIsLoading,
    setEditMode,
    error,
    billingDetailsLoaded,
  } = billing;

  const [alerts, setAlerts] = useState([]);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [address, setAddress] = useState(billingDetails);

  const { t } = useTranslation();

  useEffect(() => {
    setAddress(billingDetails);
  }, [billingDetails]);

  const onHandleChange = e => {
    if (e.target.id === "email") {
      e.target.value ? setEmailEmpty(false) : setEmailEmpty(true);
    }
    let entry = address;

    if (ADDRESS_OPTIONS.includes(e.target.id))
      entry.address[e.target.id] = e.target.value;
    else entry[e.target.id] = e.target.value;

    setAddress(entry);
  };

  const handleSelectCountry = e =>
    setAddress(prev => ({
      ...prev,
      address: { ...prev.address, country: e.value },
    }));

  const onClick = () => {
    setIsLoading(true);
    setAlerts({});
    putBillingDetails(address);
  };

  return !error && billingDetailsLoaded && address && billingDetails.address ? (
    <StyledContainer>
      <StyledSubSection>
        <StyledHeader data-test="billing-address">
          <p>{t("Billing Address")}</p>
        </StyledHeader>
        <StyledInfoDetails>
          {Object.keys(addressHeaders).map(key => (
            <InfoItem key={`${key}Item`}>
              <StyledColumn key={`${key}HeadingCol`}>
                <StyledKey key={`${key}Heading`}>
                  {addressHeaders[key].title}:
                </StyledKey>
              </StyledColumn>
              <StyledColumn key={`${key}ItemCol`}>
                {key === "country" ? (
                  <StyledSelect
                    key={`${key}Input`}
                    id={key}
                    value={countryData.find(
                      c => c.value === address?.address?.country
                    )}
                    options={countryData}
                    isDisabled={key === "email" || !editMode}
                    data-test="country-input"
                    onChange={e => handleSelectCountry(e)}
                    classNamePrefix={"Select"}
                  />
                ) : (
                  <>
                    <StyledField
                      key={`${key}Input`}
                      id={key}
                      disabled={!editMode}
                      defaultValue={address[key] || address?.address[key]}
                      data-test="details-input"
                      invalid={alerts[key]}
                      onChange={onHandleChange}
                    />
                    <div>
                      {alerts[key] && (
                        <StyledErrorMessage>{alerts[key]}</StyledErrorMessage>
                      )}
                    </div>
                  </>
                )}
              </StyledColumn>
            </InfoItem>
          ))}
          <InfoItem>
            <ButtonContainer>
              {editMode ? (
                <Button
                  onClick={() => onClick()}
                  accent
                  className="btn-save-address"
                  data-test="save-address-btn"
                  disabled={emailEmpty || isLoading}
                >
                  {isLoading ? t("Saving...") : t("Save")}
                </Button>
              ) : (
                <Button
                  onClick={() => setEditMode(true)}
                  accent
                  className="btn-edit-address"
                  data-test="edit-address-btn"
                >
                  {t("Edit")}
                </Button>
              )}
            </ButtonContainer>
          </InfoItem>
        </StyledInfoDetails>
        <RequiredText>{t("addressSection.requiredText")}</RequiredText>
      </StyledSubSection>
    </StyledContainer>
  ) : null;
};
