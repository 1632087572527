import { LabelsComponent } from "../../../components/labels/labelsComponent";
import { useGetRelease } from "../../../context/releases/useGetRelease";
import { RELEASE_STATUSES } from "../../../static/constants/constants";
import { useReleaseLabels } from "../hooks/useReleaseLabels";

export const ReleaseLabels = ({ isShared }) => {
  const { release, getRelease } = useGetRelease();
  const { labels, addNewLabel, removeLabel } = useReleaseLabels(
    release,
    getRelease
  );

  return (
    <LabelsComponent
      labels={labels}
      isShared={isShared}
      getContent={getRelease}
      addNewLabel={addNewLabel}
      removeLabel={removeLabel}
      edit={release.status === RELEASE_STATUSES.OPEN}
    />
  );
};
