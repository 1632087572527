import styled, { css } from "styled-components";

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: ${({ row, column }) =>
    (row && "row") || (column && "column") || "column"};
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ theme, fullWidth, fullHeight, full }) =>
    css`
      ${(fullWidth || full) && `width: ${theme?.sizing?.full};`};
      ${(fullHeight || full) && `width: ${theme?.sizing?.full};`};
    `}
`;
