import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useEditSessionSummary = (sessionId, getRun) => {
  const { patch, status } = useFetch();
  const { createNotification, types } = useNotifications();

  const editSummary = e =>
    patch(
      {
        ...ENDPOINTS.SESSION_SUMMARY,
        path: ENDPOINTS.SESSION_SUMMARY.path.replace(":sessionId", sessionId),
      },
      e
    );

  useEffect(() => {
    if (status.isResolved) getRun();
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.editSessionSummaryError"
      );
  }, [status.isResolved, status.isRejected]);

  return {
    editSummary,
  };
};
