import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .refresh-view {
    height: 100%;
    width: 100%;
  }
`;

export const HorizontalContainer = styled.div`
  display: flex;
  height: calc(100% - ${({ theme }) => theme.sizing.headerHeight});
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: flex-start;
  align-content: flex-start;
  gap: ${({ theme }) => theme.spacing.padding};
  width: ${({ theme, navIsOpen }) => theme.isMobile && navIsOpen && "0"};
  max-width: ${({ theme, navIsOpen }) => theme.isMobile && navIsOpen && "0"};
  padding: ${({ isNotDesktop, theme, navIsOpen }) =>
    isNotDesktop && navIsOpen ? "0" : theme.spacing.padding};
  height: 100%;
  overflow: auto;
`;
