import { useEffect } from "react";
import { useFetch } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useReleaseSharingToken } from "./useReleaseSharingToken";

export const useEnableReleaseSharing = () => {
  const { post, body, status } = useFetch();
  const { setReleaseSharingToken } = useReleaseSharingToken();

  const enableReleaseSharing = releaseId =>
    post({
      ...ENDPOINTS.ENABLE_RELEASE_SHARING,
      path: ENDPOINTS.ENABLE_RELEASE_SHARING.path.replace(
        ":releaseId",
        releaseId
      ),
    });

  useEffect(() => {
    if ((status.isResolved, body)) setReleaseSharingToken(body);
  }, [status.isResolved, body]);

  return {
    enableReleaseSharing,
  };
};
