import { useEffect, useState } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";

export const useJira = () => {
  const { get, post, status, body, method } = useFetch();
  const [jiraProjects, setJiraProjects] = useState(null);
  const [jiraCreated, setJiraCreated] = useState({});

  const getJiraProjects = () => get(ENDPOINTS.JIRA_PROJECTS);

  const createIssue = payload => post(ENDPOINTS.JIRA_ISSUE, payload);

  useEffect(() => {
    if (status.isResolved && body && method === "GET") setJiraProjects(body);
    if (status.isResolved && body && method === "POST") setJiraCreated(body);
  }, [status.isResolved, body]);

  return {
    createIssue,
    jiraCreated,
    jiraProjects,
    getJiraProjects,
    createIssueLoading: status.isLoading && method === "POST",
  };
};
