import { useEffect } from "react";
import { useOrganisationUsers } from "../organisationUsers.provider";
import { findObjectDifference } from "../utils";

export const useActiveUsers = () => {
  const { activeUsers, initialActiveUsers, users, dispatch } =
    useOrganisationUsers();

  const setActiveUsers = payload =>
    dispatch({ type: "SET_ACTIVE_USERS", payload: payload });

  const setInitialActiveUsers = payload =>
    dispatch({ type: "SET_INITIAL_ACTIVE_USERS", payload: payload });

  const setUpdatedActiveUsers = payload =>
    dispatch({ type: "SET_UPDATED_ACTIVE_USERS", payload: payload });

  const extractIsUserActive = (list, usr) => ({
    ...list,
    [usr.id]: usr.active,
  });

  const extractActiveUsers = () => {
    const extractedUsers = users?.reduce(extractIsUserActive, {});
    setActiveUsers(extractedUsers);
  };

  const extractInitialActiveUsers = () => {
    const extractedUsers = users?.reduce(extractIsUserActive, {});
    setInitialActiveUsers(extractedUsers);
  };

  const toggleUserActive = usr => {
    const updatedUsers = { ...activeUsers, [usr.id]: !activeUsers[usr.id] };
    setActiveUsers(updatedUsers);
  };

  const checkForUpdatedUsers = () => {
    const updatedUsers = findObjectDifference(initialActiveUsers, activeUsers);
    setUpdatedActiveUsers(updatedUsers);
  };

  const extractAllUsers = () => {
    if (users && users.length) {
      extractActiveUsers();
      extractInitialActiveUsers();
    }
  };

  useEffect(extractAllUsers, [users]);

  useEffect(checkForUpdatedUsers, [activeUsers, initialActiveUsers]);

  return {
    activeUsers,
    toggleUserActive,
    extractActiveUsers,
    isUserActive: user => activeUsers[user.id],
  };
};
