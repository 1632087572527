import {
  PLATFORM,
  PLATFORM_LINUX,
  PLATFORM_MAC,
  PLATFORM_WINDOWS,
} from "../../static/constants/constants";

export const getOS = () => {
  const userAgent = window.navigator.userAgent;

  if (PLATFORM.MAC.some(v => userAgent.includes(v))) return PLATFORM_MAC;
  if (PLATFORM.WINDOWS.some(v => userAgent.includes(v)))
    return PLATFORM_WINDOWS;
  if (PLATFORM.LINUX.some(v => userAgent.includes(v))) return PLATFORM_LINUX;

  return null;
};
