import {
  EntryHeader,
  StyledIconContainer,
} from "../testevolveExploratoryRunView.styles";
import { ReactComponent as BugIcon } from "../../../../static/assets/icon-bug.svg";
import { ReactComponent as MapIcon } from "../../../../static/assets/icon-map.svg";
import { ReactComponent as PencilIcon } from "../../../../static/assets/icon-pencil.svg";
import { RightChevron } from "../../../../components/NavigationPane/navigation/navigationItem/navigationItem.styles";
import { assets } from "../../../../static/assets/assets";
import { ENTRY_TYPES } from "../../../../static/constants/constants";

export const EntryType = ({ type, entryRef, onClick, isMenu }) => {
  return (
    <EntryHeader
      isMenu={isMenu}
      ref={entryRef || null}
      onClick={onClick || null}
    >
      <StyledIconContainer>
        {type === ENTRY_TYPES.BUG ? (
          <BugIcon />
        ) : type == ENTRY_TYPES.NOTE ? (
          <PencilIcon />
        ) : type === ENTRY_TYPES.OBSERVATION ? (
          <MapIcon />
        ) : null}
      </StyledIconContainer>
      <p>{type.toLowerCase()}</p>
      {isMenu ? <RightChevron isOpen {...assets.icons.navChevron} /> : null}
    </EntryHeader>
  );
};
