import { useTheme } from "styled-components";
import { OpenMenuIcon } from "./openMenuButton.styles";

export const OpenMenuButton = ({ r, anchorProps, menuOpen, onClick }) => {
  const { colours } = useTheme();

  return (
    <OpenMenuIcon
      onMouseDown={anchorProps.onMouseDown}
      isOpen={menuOpen}
      ref={r}
      onClick={e => {
        if (onClick) onClick();
        anchorProps.onClick();
        e.stopPropagation();
      }}
      fill={colours.mainText}
      data-test="open-menu"
    />
  );
};
