import React from "react";
import { theme } from "../../theme/theme";
import styled from "styled-components";

const PassingGradient = () => (
  <linearGradient id="gradientPass" gradientTransform="rotate(90)">
    <stop offset="0%" stopColor={theme.gradients.passing_start} />
    <stop offset="74%" stopColor={theme.gradients.passing_end} />
  </linearGradient>
);

const NewGradient = () => (
  <linearGradient id="newPass" gradientTransform="rotate(90)">
    <stop offset="0%" stopColor={theme.gradients.new_start} />
    <stop offset="74%" stopColor={theme.gradients.new_end} />
  </linearGradient>
);

const DullPassingGradient = () => (
  <linearGradient id="dullGradientPass" gradientTransform="rotate(90)">
    <stop
      offset="0%"
      stopColor={theme.gradients.passing_start}
      stopOpacity={0.3}
    />
    <stop
      offset="74%"
      stopColor={theme.gradients.passing_end}
      stopOpacity={0.3}
    />
  </linearGradient>
);

const FailingGradient = () => (
  <linearGradient id="gradientFail" gradientTransform="rotate(90)">
    <stop offset="0%" stopColor={theme.gradients.error_start} />
    <stop offset="100%" stopColor={theme.gradients.error_end} />
  </linearGradient>
);

const DullFailingGradient = () => (
  <linearGradient id="dullGradientFail" gradientTransform="rotate(90)">
    <stop
      offset="0%"
      stopColor={theme.gradients.error_start}
      stopOpacity={0.3}
    />
    <stop
      offset="100%"
      stopColor={theme.gradients.error_end}
      stopOpacity={0.3}
    />
  </linearGradient>
);

const TotalScenariosGradient = () => (
  <linearGradient id="gradientTotal" gradientTransform="rotate(90)">
    <stop offset="0%" stopColor={theme.gradients.total_scenarios_start} />
    <stop offset="100%" stopColor={theme.gradients.total_scenarios_end} />
  </linearGradient>
);

const CriticalGradient = () => (
  <linearGradient id="gradientCritical" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.critical_start} />
    <stop offset="100%" stopColor={theme.gradients.critical_end} />
  </linearGradient>
);

const HighGradient = () => (
  <linearGradient id="gradientHigh" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.error_start} />
    <stop offset="100%" stopColor={theme.gradients.error_end} />
  </linearGradient>
);

const SeriousGradient = () => (
  <linearGradient id="gradientSerious" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.error_start} />
    <stop offset="100%" stopColor={theme.gradients.error_end} />
  </linearGradient>
);

const ModerateGradient = () => (
  <linearGradient id="gradientModerate" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.moderate_start} />
    <stop offset="100%" stopColor={theme.gradients.moderate_end} />
  </linearGradient>
);

const DullModerateGradient = () => (
  <linearGradient id="dullGradientModerate" gradientTransform="rotate(100)">
    <stop
      offset="0%"
      stopColor={theme.gradients.moderate_start}
      stopOpacity={0.3}
    />
    <stop
      offset="100%"
      stopColor={theme.gradients.moderate_end}
      stopOpacity={0.3}
    />
  </linearGradient>
);

const LowGradient = () => (
  <linearGradient id="gradientLow" gradientTransform="rotate(120)">
    <stop offset="0%" stopColor={theme.gradients.low_start} />
    <stop offset="100%" stopColor={theme.gradients.low_end} />
  </linearGradient>
);

const InfoGradient = () => (
  <linearGradient id="gradientInfo" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.info_start} />
    <stop offset="100%" stopColor={theme.gradients.info_end} />
  </linearGradient>
);

const MinorGradient = () => (
  <linearGradient id="gradientMinor" gradientTransform="rotate(120)">
    <stop offset="0%" stopColor={theme.gradients.low_start} />
    <stop offset="100%" stopColor={theme.gradients.low_end} />
  </linearGradient>
);

const ErrorGradient = () => (
  <linearGradient id="gradientError" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.critical_start} />
    <stop offset="100%" stopColor={theme.gradients.critical_end} />
  </linearGradient>
);

const SkippedGradient = () => (
  <linearGradient id="gradientSkipped" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.skipped_start} />
    <stop offset="100%" stopColor={theme.gradients.skipped_end} />
  </linearGradient>
);

const PassedCheckGradient = () => (
  <linearGradient id="gradientPassedCheck" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.passing_check_start} />
    <stop offset="100%" stopColor={theme.gradients.passing_check_end} />
  </linearGradient>
);

const FailedCheckGradient = () => (
  <linearGradient id="gradientFailedCheck" gradientTransform="rotate(100)">
    <stop offset="0%" stopColor={theme.gradients.failed_check_start} />
    <stop offset="100%" stopColor={theme.gradients.failed_check_end} />
  </linearGradient>
);

const PurpleGradient = () => (
  <linearGradient id="gradientPurple" gradientTransform="rotate(90)">
    <stop offset="0%" stopColor={theme.gradients.purple_start} />
    <stop offset="100%" stopColor={theme.gradients.purple_end} />
  </linearGradient>
);

const PurpleGradientTwo = () => (
  <linearGradient id="gradientPurpleTwo" gradientTransform="rotate(90)">
    <stop
      offset="0%"
      stopColor={theme.gradients.purple_start}
      stopOpacity={0.75}
    />
    <stop
      offset="100%"
      stopColor={theme.gradients.purple_end}
      stopOpacity={0.75}
    />
  </linearGradient>
);

const PurpleGradientThree = () => (
  <linearGradient id="gradientPurpleThree" gradientTransform="rotate(90)">
    <stop
      offset="0%"
      stopColor={theme.gradients.purple_start}
      stopOpacity={0.5}
    />
    <stop
      offset="100%"
      stopColor={theme.gradients.purple_end}
      stopOpacity={0.5}
    />
  </linearGradient>
);

const PurpleGradientFour = () => (
  <linearGradient id="gradientPurpleFour" gradientTransform="rotate(90)">
    <stop
      offset="0%"
      stopColor={theme.gradients.purple_start}
      stopOpacity={0.25}
    />
    <stop
      offset="100%"
      stopColor={theme.gradients.purple_end}
      stopOpacity={0.25}
    />
  </linearGradient>
);

const StyledSVG = styled.svg`
  height: 0;
  z-index: 0;
  position: absolute;
`;

export const Gradients = () => (
  <StyledSVG width={0} height={0}>
    <defs width={0} height={0}>
      <PassingGradient />
      <FailingGradient />
      <SkippedGradient />
      <ModerateGradient />
      <DullPassingGradient />
      <DullModerateGradient />
      <DullFailingGradient />
      <TotalScenariosGradient />
      <CriticalGradient />
      <HighGradient />
      <LowGradient />
      <InfoGradient />
      <PassedCheckGradient />
      <FailedCheckGradient />
      <PurpleGradient />
      <PurpleGradientTwo />
      <PurpleGradientThree />
      <PurpleGradientFour />
      <NewGradient />
      <SeriousGradient />
      <MinorGradient />
      <ErrorGradient />
    </defs>
  </StyledSVG>
);
