import {
  OpenMenuIcon,
  MenuContainer,
  StyledMenuItem,
  StyledMenu,
} from "../../../../components/NavigationPane/navigation/navigationParent/navigationParent.styles";
import React from "react";
import {
  Container,
  StyledRow,
  StyledTable,
  VulnerabilitiesContainer,
} from "./runHistory.styles";
import { useTranslation } from "react-i18next";
import { useTime } from "../../../../context/hooks/time/useTime";
import { useNodeView } from "../../../nodeView/hooks/useNodeView";
import { useQueryParams } from "../../../../context/hooks";
import {
  LATEST,
  PAGE,
  TEST_CATEGORIES,
  npmAuditRunHistoryHeaders,
} from "../../../../static/constants/constants";
import { Vulnerabilities } from "../../../nodeView/nodeCardContainer/nodeCardNPMAudit/vulnerabilities/vulnerabilities";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { useTheme } from "styled-components";
import { useDeleteRun } from "../../hooks/useDeleteRun";
import { usePermissions } from "../../../../context/hooks";
import { routes } from "../../../../router/routes";
import { FlexTd } from "../../testEvolve/runHistory/runHistory.styles";
import { FreezeIcon } from "../../runDetails/runDetails.styles";
import { useParams } from "react-router-dom";

export const RunHistory = () => {
  const history = useHistoryWithParams();
  const { t } = useTranslation();
  const { runTrends, nodeId } = useNodeView();
  const { getReadableTimeDifference } = useTime();
  const { getParam } = useQueryParams();
  const { colours } = useTheme();
  const { openDeleteRunModal } = useDeleteRun();
  const { isAdmin } = usePermissions();
  const { runId } = useParams();

  const MenuComponent = ({ run }) => (
    <MenuContainer>
      <StyledMenu
        menuButton={
          <OpenMenuIcon
            fill={colours.mainText}
            onClick={e => e.stopPropagation()}
            data-test={`open-menu-icon-${run.id}`}
          />
        }
        transition
        position="auto"
        align="start"
      >
        <StyledMenuItem
          onClick={openDeleteRunModal}
          data-test={`delete-run-${run.id}`}
        >
          {t("runHistory.deleteRun")}
        </StyledMenuItem>
      </StyledMenu>
    </MenuContainer>
  );

  const handleRowClick = (e, run) => {
    const { cellIndex, textContent } = e.target;
    const isDeleteButton = textContent === t("runHistory.deleteRun");

    if (isDeleteButton || cellIndex === e.currentTarget.cells.length - 1) {
      return;
    }

    history.push(
      `${routes.RUN_VIEW.path}?page=${getParam(PAGE)}`
        .replace(":nodeId", nodeId)
        .replace(":runType", TEST_CATEGORIES.NPM_AUDIT)
        .replace(":label", encodeURI(encodeURIComponent(run.label)))
        .replace(":runId", run.id)
    );
  };

  return (
    <Container>
      <h1 data-test="run-history-title">{t("runHistory.title")}</h1>
      <StyledTable data-test="run-history-title">
        <tr>
          {npmAuditRunHistoryHeaders.map(header => (
            <th data-test="run-history-header" key={`header-${header}`}>
              {t(`runHistory.${header}`)}
            </th>
          ))}
        </tr>
        {runTrends.map((run, i) => (
          <StyledRow
            key={`row-${run.id}`}
            onClick={e => handleRowClick(e, run)}
            isSelected={
              run.id.toString() === runId.toString() ||
              (runId === LATEST && i === 0 && getParam(PAGE) === "1")
            }
          >
            <td>
              <FlexTd data-test={`${run.id}-run-id`}>
                {run.id}
                {run?.releases?.length ? <FreezeIcon /> : null}
              </FlexTd>
            </td>
            <VulnerabilitiesContainer data-test="run-vulnerabilities">
              <Vulnerabilities data={run.data.vulnerabilities} />
            </VulnerabilitiesContainer>
            <td data-test="run-time-ago">
              {getReadableTimeDifference(run.finish_time ?? run.updated_time)}{" "}
              {t("time.ago")}
            </td>
            {isAdmin() ? (
              <td data-test={`${run.id}-options`}>
                <MenuComponent key={run.id} run={run} />
              </td>
            ) : null}
          </StyledRow>
        ))}
      </StyledTable>
    </Container>
  );
};
