import { usePolling } from "../../context/hooks/poll/usePolling";
import { RELEASE_VIEW_POLLING_INTERVAL } from "../../static/constants/constants";
import { ReleaseViewContainer } from "../releaseView/release.styles";
import { ReleaseDetails } from "../releaseView/releaseDetails/releaseDetails";
import { ReleaseLabels } from "../releaseView/releaseLabels/releaseLabels";
import { ReleaseRuns } from "../releaseView/releaseRuns/releaseRuns";
import { useSharedReleaseView } from "./hooks/useSharedReleaseView";
import { Loading } from "../../components";
import {
  Container,
  Page,
  PageContent,
  WhiteContainer,
} from "../sharedRunView/sharedRunView.styles";
import { Header } from "../sharedRunView/sharedHeader";
import { useTranslation } from "react-i18next";

export const SharedReleaseView = () => {
  const { unshared, getSharedRelease, release } = useSharedReleaseView();
  const { t } = useTranslation();

  usePolling(() => {
    if (!unshared) getSharedRelease();
  }, RELEASE_VIEW_POLLING_INTERVAL);

  const renderReleaseView = () => (
    <ReleaseViewContainer>
      <ReleaseDetails isShared={true} />
      <ReleaseLabels isShared={true} />
      <ReleaseRuns isShared={true} />
    </ReleaseViewContainer>
  );

  return (
    <Page id="full-screen-container">
      <Header />
      <PageContent>
        {unshared ? (
          <Container>
            <WhiteContainer>
              <p>{t("messaging.unsharedReleaseDashboard")}</p>
            </WhiteContainer>
          </Container>
        ) : release ? (
          renderReleaseView()
        ) : (
          <Loading />
        )}
      </PageContent>
    </Page>
  );
};
