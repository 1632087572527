import styled from "styled-components";

export const StyledMessage = styled.p`
  color: ${({ theme }) => theme.colours.foreground};
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${({ theme, error, success, info }) =>
    (error && theme.colours.error) ||
    (info && theme.colours.info) ||
    (success && theme.colours.success)};
  padding: 12px 16px;
  border-radius: 8px;
`;

export const Message = props => (
  <StyledDiv {...props}>
    <StyledMessage {...props}>{props.children}</StyledMessage>
  </StyledDiv>
);
