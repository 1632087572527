import React from "react";
import styled from "styled-components";
import { Dot } from "../../../components/dot/dot";
import { mediumDateTime } from "../../../utils/dates/dateUtils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${({ theme }) => theme.sizing.nodeFooterHeight};
  align-items: flex-end;
  width: 100%;

  p {
    font-size: 14px;
  }
`;

const LeftDiv = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const NodeFooter = ({ startTime, status, statusText }) => {
  return (
    <Container>
      {status && statusText ? (
        <LeftDiv data-test="run-status">
          <Dot colour={status.toLowerCase()} />
          <p>{statusText}</p>
        </LeftDiv>
      ) : (
        <p />
      )}
      {startTime ? (
        <p data-test="time-ago">{mediumDateTime(startTime)}</p>
      ) : (
        <p />
      )}
    </Container>
  );
};
