import { StatusContainer, StyledRobotIcon } from "./leftWidget.styles";
import { useQueryParams } from "../../../../context/hooks";
import { ReactComponent as PencilIcon } from "../../../../static/assets/icon-pencil.svg";
import {
  BottomContainer,
  SuccessCriteriaContainer,
  TopContainer,
} from "../testEvolveAccessiblityRunView.styles";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { ACCESSIBILITY_STATUSES } from "../../../../static/constants/constants";
import { useTranslation } from "react-i18next";
import { Spacer } from "../../../runReportView/components/bug.styles";

export const SuccessCriteria = ({ successCriteria }) => {
  const history = useHistoryWithParams();
  const { getParam } = useQueryParams();
  const successCriteriaRef = getParam("criteria");
  const { t } = useTranslation();

  const changeCriteria = s =>
    history.push(`${location.pathname}?${"criteria"}=${s}`);

  return (
    <SuccessCriteriaContainer
      data-test="section-success-criteria"
      isSelected={successCriteriaRef === successCriteria.ref_id}
      onClick={() => changeCriteria(successCriteria.ref_id)}
    >
      <TopContainer>
        <p>{t("runView.testevolveaccessibility.successCriteria")}</p>
      </TopContainer>
      <BottomContainer>
        {successCriteria.automation_coverage ? <StyledRobotIcon /> : null}
        <h3>
          {successCriteria.ref_id}. {successCriteria.title}
        </h3>
        <Spacer />
        {successCriteria.notes && successCriteria.assessment_notes?.length ? (
          <PencilIcon />
        ) : null}
        {successCriteria.status ? (
          <StatusContainer
            status={ACCESSIBILITY_STATUSES[successCriteria.status.status]}
          >
            {t(
              `runView.testevolveaccessibility.${
                ACCESSIBILITY_STATUSES[successCriteria.status.status]
              }`
            )}
          </StatusContainer>
        ) : null}
      </BottomContainer>
    </SuccessCriteriaContainer>
  );
};
