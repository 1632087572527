import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useReleases } from "../../../context/releases/releases.provider";

export const useDeleteRelease = toggleModal => {
  const { deleteApi, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const { removeRelease } = useReleases();

  const deleteRelease = id => {
    deleteApi({
      ...ENDPOINTS.RELEASE,
      path: ENDPOINTS.RELEASE.path.replace(":id", id),
    });
  };

  useEffect(() => {
    if (status.isResolved && body && body.id) {
      createNotification(types.SUCCESS, "releases.deleteModal.success");
      toggleModal();
      removeRelease(body.id);
    }
    if (status.isRejected && error)
      createNotification(types.ERROR, "releases.deleteModal.error");
  }, [status.isResolved, status.isRejected, status.isLoading, error, body]);

  return {
    deleteRelease,
    deleteNodeLoading: status.isLoading,
    isError: status.isRejected,
  };
};
