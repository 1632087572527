import styled, { css } from "styled-components";
import { TestEvolveLogo } from "../../../components/TestEvolveLogo/TestEvolveLogo";
import { JiraLogo } from "../../organisationSettings/tabs/integrations/elements/logo/JiraLogo";
import { Arrow } from "../../organisationSettings/tabs/integrations/elements/arrow/Arrow";
import { StyledSelect } from "../../organisationSettings/tabs/organisation/projectsPicker.styles";
import { CustomLink } from "../../../components/Link/StyledLink";

export const ProjectSelect = styled(StyledSelect)`
  width: 100%;
  z-index: 99;
`;

export const PrioritySelect = styled(ProjectSelect)`
  z-index: 98;
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.padding};
  margin-top: ${({ theme }) => theme.spacing.paddingMD};
  align-items: center;
`;

export const StyledContainer = styled.div`
  min-width: 7rem;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.padding};
  overflow-y: auto;
`;

export const SectionContainer = styled.div`
  height: ${({ theme }) => theme.sizing.screenshotSectionHeight};
  max-height: ${({ theme }) => theme.sizing.screenshotSectionHeight};
  min-height: ${({ theme }) => theme.sizing.screenshotSectionHeight};
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: auto;
`;

export const StyledLabel = styled.label`
  text-align: left;
  display: ${({ hideLabels }) => (hideLabels && "none") || "block"};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const StyledTextArea = styled.textarea`
  padding-bottom: 0.6rem;
  background-color: ${({ theme }) => theme.colours.background};

  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 160px;

  appearance: none;
  @media all and (-ms-high-contrast: none) {
    & {
      box-sizing: content-box;
    }
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.padding};
    height: 80px;
    line-height: inherit;
    border: ${({ theme }) => `1px solid ${theme.colours.foreground}`};
    border-radius: ${theme.borders.radius};
    color: ${theme.colours.mainText};
    border-color: ${({ theme, invalid }) => invalid && theme.colours.error};

    &::-moz-placeholder {
      /* Firefox 19+ */
      line-height: 0;
    }
  `};
`;
export const TitleContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.padding};
  min-width: 20rem;
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
`;

export const ConfigFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const StyledFieldContainer = styled.div`
  width: 100%;
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  align-self: auto;
  border: 0;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StyledMainContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  flex-direction: row;
`;

export const HighlightingMainContainer = styled(StyledMainContainer)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colours.foreground};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colours.foreground};
  }
`;

export const StyledBugLink = styled(CustomLink)`
  color: ${({ theme }) => theme.colours.accent};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  width: 100%;
  text-align: center;
`;

export const StyledLogo = styled(TestEvolveLogo)`
  width: 100%;
  height: auto;
  display: flex;
  max-width: ${({ theme }) => theme.sizing.logoSize};
  padding: ${({ theme }) => `${theme.spacing.gutter} 0`};
`;

export const StyledJiraLogo = styled(JiraLogo)`
  ${({ theme, size }) => css`
    height: auto;
    display: flex;
    max-width: ${size === "small" && theme.sizing.logoSize};
    max-width: ${size === undefined && theme.sizing.logoSize};
    max-width: ${size === "large" && theme.sizing.logoSizeLarge};
    padding: ${({ theme }) => `${theme.spacing.gutter} 0`};
  `}
`;

export const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 33%;
  padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  justify-content: center;
  align-items: center;
`;

export const StyledArrow = styled(Arrow)`
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ theme }) => theme.sizing.logoSize};
  padding: ${({ theme }) => `${theme.spacing.gutter} 0`};
`;

export const ScreenshotsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const ScreenshotContainer = styled.div``;

export const ScreenshotTabs = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const UpgradeScreenshotMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  padding: ${({ theme }) => theme.spacing.padding};
  margin: ${({ theme }) => theme.spacing.paddingSM} 0;
  border-radius: ${({ theme }) => theme.borders.radius};
  background: ${({ theme }) =>
    `linear-gradient(90deg,${theme.gradients.purple_end},${theme.gradients.purple_start})`};
  color: ${({ theme }) => theme.colours.white};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  svg {
    height: ${({ theme }) => theme.sizing.svgHeightMDLG};
    width: ${({ theme }) => theme.sizing.svgHeightMDLG};
  }
`;

export const JiraImage = styled.img`
  height: 100%;
  min-height: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  outline: ${({ selected, theme }) =>
    selected
      ? `${theme.spacing.paddingXXSM} solid ${theme.colours.haloAccent}`
      : "none"};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  &:hover {
    outline: ${({ theme }) =>
      `${theme.spacing.paddingXXSM} solid ${theme.colours.haloAccent}`};
    cursor: pointer;
  }
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.paddingXSM}
    ${({ theme }) => theme.spacing.paddingXXSM};
  padding-bottom: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};
  overflow-x: scroll;
  height: ${({ theme }) => theme.sizing.screenshotSectionHeight};
  max-height: ${({ theme }) => theme.sizing.screenshotSectionHeight};

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colours.background};
      height: ${({ theme }) => theme.spacing.paddingSM};
      border-radius: ${({ theme }) => theme.borders.radius};
    }

    &::-webkit-scrollbar {
      height: ${({ theme }) => theme.spacing.paddingSM};
      border-radius: ${({ theme }) => theme.borders.radius};
    }
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    height: ${({ theme }) => theme.spacing.paddingSM};
    border-radius: ${({ theme }) => theme.borders.radius};
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    height: ${({ theme }) => theme.spacing.paddingSM};
    border-radius: ${({ theme }) => theme.borders.radius};
  }
`;
