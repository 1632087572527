import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useReleases } from "../../../context/releases/releases.provider";
import { convertDateToSQL } from "../../../utils/dates/dateUtils";

export const useCreateRelease = toggleCreateReleaseOpen => {
  const { post, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const { addRelease } = useReleases();

  const createRelease = release => {
    if (release.open_date)
      release.open_date = convertDateToSQL(release.open_date);
    if (release.close_date)
      release.close_date = convertDateToSQL(release.close_date);
    if (!release.description?.length) release.description = null;
    post(ENDPOINTS.RELEASES, release);
  };

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(types.SUCCESS, "releases.createModal.success");
      toggleCreateReleaseOpen();
      addRelease({ ...body, labels: body.labels || [] });
    }

    if (status.isRejected && error)
      createNotification(types.ERROR, `releases.createModal.${error.code}`);
  }, [status.isResolved, status.isRejected, body]);

  return {
    submit: createRelease,
    createNodeLoading: status.isLoading,
    isError: status.isRejected,
    isLoading: status.isLoading,
  };
};
