import { useEffect } from "react";
import { useFetch } from "../hooks";
import { useAuth } from "../auth/auth.provider";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { organisationReducer } from "./organisation.reducer";
import { ENDPOINTS } from "../../static/constants/routeConfig";

const initialState = { name: null, active: "loading", partner: null };

const [useHook, OrganisationProvider] = createState(
  "organisation",
  initialState,
  organisationReducer
);

const useOrganisation = () => {
  const { active, name, partner, dispatch, ...organisation } = useHook();
  const { get, status, body } = useFetch();
  const { setUnAuthenticated } = useAuth();

  const getOrganisation = () => get(ENDPOINTS.ORGANISATION);

  const setOrganisation = body =>
    dispatch({ type: ACTIONS.GET_ORGANISATION_SUCCESS, payload: body });

  const getOrganisationFailure = () =>
    dispatch({ type: ACTIONS.GET_ORGANISATION_FAILURE });

  useEffect(() => {
    if (status.isResolved && body) {
      setOrganisation(body);
    }
    if (status.isRejected) setUnAuthenticated();
  }, [status.isResolved]);

  return {
    organisationName: name,
    organisation,
    setOrganisation,
    getOrganisation,
    getOrganisationFailure,
    organisationActive: active,
    isPartner: partner,
  };
};

export { useOrganisation, OrganisationProvider };
