import { useEffect, useState } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { API_STATUS } from "../../../../static/constants/apiConstants";
import { PROCESSING_STATUS } from "../../../../static/constants/constants";

const PROCESSING_ERROR = "attachment_not_found_in_storage_service";

export const useGetAttachment = () => {
  const { get, status, body, response, error } = useFetch();
  const [attachmentObj, setAttachmentObj] = useState({});

  const getAttachment = attachmentId =>
    get({
      ...ENDPOINTS.GET_ATTACHMENT,
      path: ENDPOINTS.GET_ATTACHMENT.path.replace(
        ":attachmentId",
        attachmentId
      ),
    });

  const getAttachments = attachments =>
    attachments.forEach(att => getAttachment(att));

  const createAndSetBlob = res => {
    const splitUrl = res.url.split("/");
    const id = splitUrl[splitUrl.length - 1];

    setAttachmentObj(e => ({ ...e, [id]: { attachment: body } }));
  };

  const setError = (res, err) => {
    const splitUrl = res.url.split("/");
    const id = splitUrl[splitUrl.length - 1];

    setAttachmentObj(e => ({
      ...e,
      [id]: {
        status:
          err.text === PROCESSING_ERROR
            ? PROCESSING_STATUS
            : API_STATUS.REJECTED,
      },
    }));
  };

  useEffect(() => {
    if (status.isResolved && response && body) createAndSetBlob(response, body);
    if ((status.isRejected && response, error)) setError(response, error);
  }, [status.isResolved, status.isRejected, body, error]);

  return {
    getAttachment,
    getAttachments,
    attachmentObj,
    error,
  };
};
