import { useTranslation } from "react-i18next";
import { Modal } from "../../modal";
import { useDeleteRelease } from "../../../releases/deleteRelease/useDeleteRelease";
import { StyledName } from "../../../releases/releases.styles";

export const DeleteReleaseModal = ({ release, closeModal }) => {
  const { t } = useTranslation();
  const { deleteRelease, deleteNodeLoading } = useDeleteRelease(closeModal);

  return (
    <Modal
      title={t("releases.deleteModal.title")}
      primary={{
        onClick: () => deleteRelease(release.id),
        text: t("btnDelete"),
        loader: true,
        loading: deleteNodeLoading,
        props: {
          danger: true,
        },
      }}
      secondary={{
        onClick: closeModal,
        text: t("btnCancel"),
      }}
    >
      <p>{t("releases.deleteModal.deleteMessage")}</p>
      <StyledName>{release.name}</StyledName>
      <p>{t("releases.deleteModal.areYouSure")}</p>
    </Modal>
  );
};
