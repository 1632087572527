import React, { useEffect, useState } from "react";
import { StyledCardWrapper, Container } from "./partnerCustomersTab.styles";
import {
  EVALUATION,
  FREE,
  PARTNER,
} from "../../../../static/constants/constants";
import { useTranslation } from "react-i18next";
import { useSubscriptionDetails } from "../billing/subscriptionDetails/hooks/useSubscriptionDetails";
import { useCustomers } from "./hooks/useCustomers";
import { CustomerList } from "./CustomerList";
import { CreateCustomerForm } from "./forms/CreateCustomerForm";
import { useApp } from "../../../../context/app/app.provider";

export const PartnerCustomersTab = () => {
  const { t } = useTranslation();
  const { subscriptionDetails, getSubscriptionDetails } =
    useSubscriptionDetails();
  const { customerOrganisations, getCustomerOrganisations } = useCustomers();
  const [isUpdate, setIsUpdate] = useState(false);
  const { isNotDesktop } = useApp();

  const setUpdated = () => setIsUpdate(true);

  useEffect(() => {
    getCustomerOrganisations();
    getSubscriptionDetails();
  }, []);

  useEffect(() => {
    if (isUpdate) {
      getCustomerOrganisations();
      getSubscriptionDetails();
      setIsUpdate(false);
    }
  }, [isUpdate]);

  return (
    <Container regular gutter isNotDesktop={isNotDesktop}>
      {(subscriptionDetails?.subscription_id === FREE ||
        subscriptionDetails?.subscription_id === EVALUATION) && (
        <StyledCardWrapper>
          {t("customer.message.missingPaymentDetails")}
        </StyledCardWrapper>
      )}
      {subscriptionDetails?.subscription_id === PARTNER && (
        <>
          <StyledCardWrapper>
            <CreateCustomerForm
              setUpdated={setUpdated}
              updated={isUpdate}
              createdCustomer={{}}
              customerOrganisations={customerOrganisations}
            />
          </StyledCardWrapper>
          {customerOrganisations.length ? (
            <StyledCardWrapper>
              <CustomerList
                customerOrganisations={customerOrganisations}
                subscriptionDetails={subscriptionDetails}
                setUpdated={setUpdated}
              />
            </StyledCardWrapper>
          ) : null}
        </>
      )}
    </Container>
  );
};
