import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch, usePermissions } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useProject } from "../../../../../context/project/project.provider";
import { useNotifications } from "../../../../../context/hooks/useNotifications/useNotifications";
import { usePatchProjectRoles } from "./patchProjectRoles";
import { roles } from "../../../../../static/constants/constants";
import { useUser } from "../../../../../context/user/user.provider";

const PROJECT_USER_OBJ = { key: "PROJECT_USER", type: "PROJECT" };
const PROJECT = "PROJECT";

export const useProjectRoles = () => {
  const { t } = useTranslation();
  const { project } = useProject();
  const { createNotification, types } = useNotifications();
  const { status, origin, error, body, get } = useFetch();
  const { isUserAdmin } = usePermissions();
  const { getUser } = useUser();

  const [projectUsers, setProjectUsers] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [initialProjectUsers, setInitialProjectUsers] = useState([]);

  const getProjectUsers = () =>
    get({
      ...ENDPOINTS.USERS_LIST,
      path: `${ENDPOINTS.USERS_LIST.path}/${project.id}`,
    });

  const { patchProjectRole } = usePatchProjectRoles(getProjectUsers);

  const handleSelect = (userId, e) => {
    const newRoles = e.map(role => ({ key: role.value, type: PROJECT }));

    setProjectUsers(prev => {
      let users = prev.map(usr => usr);
      const index = users.findIndex(usr => usr.id === userId);
      users[index].project_roles = newRoles;
      return users;
    });
  };

  const userRolesEqual = (a, b) => {
    let eq = true;

    a.forEach((usr1, key) => {
      const userA = usr1.project_roles?.map(role => role.key) || [];
      const userB = b[key].project_roles?.map(role => role.key) || [];

      if (!(JSON.stringify(userA) === JSON.stringify(userB))) eq = false;
    });
    return eq;
  };

  const handleSubmit = () => {
    projectUsers.forEach((user, key) => {
      const userRoles = user.project_roles.map(role => role.key);
      const initialUserRoles =
        initialProjectUsers[key].project_roles.map(role => role.key) || [];

      const isChange =
        JSON.stringify(userRoles) !== JSON.stringify(initialUserRoles);
      let payload = userRoles.map(role => ({ key: role, type: PROJECT }));

      if (!isUserAdmin(user)) payload = [...payload, PROJECT_USER_OBJ];
      if (isChange) patchProjectRole(user.id, payload);
    });
  };

  useEffect(() => {
    setBtnDisabled(userRolesEqual(projectUsers, initialProjectUsers));
  }, [projectUsers]);

  const mapUsers = users =>
    users?.map(usr => ({
      ...usr,
      project_roles: usr.project_roles
        .find(prj => prj.project.id === project.id)
        ?.roles?.filter(r => r.key !== roles.PROJECT_USER),
    }));

  useEffect(() => {
    if (status.isResolved && body) {
      setProjectUsers(mapUsers(body));
      setInitialProjectUsers(mapUsers(body));
      getUser();
    }
    if (status.isRejected && error)
      createNotification(types.ERROR, t("errors.default"));
  }, [status.isResolved, status.isRejected, origin, error]);

  return {
    btnDisabled,
    handleSelect,
    handleSubmit,
    getProjectUsers,
    projectUsers,
    setProjectUsers,
  };
};
