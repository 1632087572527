import { HomeReducer } from "./home.reducer";
import { ACTIONS } from "../../../static/constants/actions";
import { useLatestRuns } from "../latestRuns/hooks/useLatestRuns";
import { useInProgressRuns } from "../inProgressRuns/hooks/useInProgressRuns";
import { createState } from "../../../utils/context";

const initialState = {
  latestRuns: [],
  inProgressRuns: [],
  showGettingStarted: false,
  gettingStartedStep: 0,
  hasData: false,
  loadingInProgressRuns: true,
  loadingLatestRuns: true,
};

const [useHook, HomeProvider] = createState("home", initialState, HomeReducer);

const useHome = () => {
  const {
    latestRuns,
    inProgressRuns,
    showGettingStarted,
    gettingStartedStep,
    loadingLatestRuns,
    loadingInProgressRuns,
    hasData,
    dispatch,
  } = useHook();

  const setLatestRuns = payload =>
    dispatch({ type: ACTIONS.SET_LATEST_RUNS, payload: payload });

  const setInProgressRuns = payload =>
    dispatch({ type: ACTIONS.SET_IN_PROGRESS_RUNS, payload: payload });

  const setStep = payload =>
    dispatch({ type: ACTIONS.SET_STEP, payload: payload });

  const hideGuide = () => dispatch({ type: ACTIONS.HIDE_GUIDE });

  const { getLatestRuns } = useLatestRuns(setLatestRuns);
  const { getInProgressRuns } = useInProgressRuns(setInProgressRuns);

  const clearHomePage = () =>
    dispatch({ type: ACTIONS.CLEAR_HOME, payload: initialState });

  return {
    latestRuns,
    inProgressRuns,
    getLatestRuns,
    getInProgressRuns,
    loadingLatestRuns,
    loadingInProgressRuns,
    showGettingStarted,
    step: gettingStartedStep,
    clearHomePage,
    setStep,
    hasData,
    hideGuide,
    isLoading: loadingLatestRuns || loadingInProgressRuns,
  };
};

export { useHome, HomeProvider };
