import Cookies from "js-cookie";
import { useTenant } from "../hooks";
import { authReducer } from "./auth.reducer";
import { createState } from "../../utils/context";
import { ACTIONS } from "../../static/constants/actions";
import { useModal } from "../modal/modal.provider";

const initialState = { isAuthenticated: false, sessionExpired: false };

const [useHook, AuthProvider] = createState("auth", initialState, authReducer);

const useAuth = isPrivateRoute => {
  const { tenant } = useTenant();
  const { isAuthenticated, sessionExpired, dispatch } = useHook();
  const { openModal, MODALS, modal } = useModal();

  const setAuthenticated = () => dispatch({ type: ACTIONS.SIGNIN_SUCCESS });

  const setUnAuthenticated = () => {
    Cookies.remove("SCC", {
      path: "/",
      domain: window.location.hostname.replace(tenant, ""),
    });
    dispatch({ type: ACTIONS.SIGNIN_FAILURE });
    sessionStorage.clear();
    localStorage.clear();
  };

  const setSessionExpired = () =>
    dispatch({ type: ACTIONS.SET_SESSION_EXPIRED });

  const setSessionNotExpired = () =>
    dispatch({ type: ACTIONS.SET_SESSION_NOT_EXPIRED });

  if (sessionExpired && modal !== MODALS.SESSION_EXPIRED && isPrivateRoute)
    openModal(MODALS.SESSION_EXPIRED, {});

  return {
    sessionExpired,
    isAuthenticated,
    setAuthenticated,
    setSessionExpired,
    setUnAuthenticated,
    setSessionNotExpired,
  };
};

export { useAuth, AuthProvider };
