import React from "react";
import { useTheme } from "../../context/theme/theme.provider";
import { ReactComponent as Logo } from "../../static/assets/halo-logo.svg";
import { ReactComponent as LogoLight } from "../../static/assets/halo-logo-light.svg";

export const HaloLogo = ({ light, dark, ...other }) => {
  const { theme } = useTheme();

  if (light) return <LogoLight {...other} />;
  if (dark) return <Logo {...other} />;

  return theme === "dark" ? (
    <LogoLight data-test="light-logo" {...other} />
  ) : (
    <Logo data-test="dark-logo" {...other} />
  );
};
