import React, { useEffect, useState } from "react";
import {
  StyledContainer,
  StyledJiraLogo,
  ConfigFormContainer,
  TitleContainer,
  StyledField,
} from "./styles";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components";
import { useJiraConfig } from "../../../../../context/jiraConfig/jiraConfig.provider";
import { useApp } from "../../../../../context/app/app.provider";

export const JiraConfigForm = () => {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [apiKey, setApiKey] = useState("");

  const { isNotDesktop } = useApp();
  const { jiraConfig, createConfig, deleteConfig, getConfig } = useJiraConfig();

  useEffect(() => {
    getConfig();
  }, []);

  const configExists = Object.keys(jiraConfig)?.length !== 0;

  const buttonHandler = () => {
    if (configExists) deleteConfig();
    else createConfig({ email: email, api_key: apiKey, url: url });
  };

  useEffect(() => {
    if (jiraConfig.email) {
      setEmail(jiraConfig.email);
      setUrl(jiraConfig.url);
    } else {
      setEmail("");
      setUrl("");
      setApiKey("");
    }
  }, [jiraConfig]);

  return (
    <StyledContainer isNotDesktop={isNotDesktop}>
      <StyledJiraLogo isNotDesktop={isNotDesktop} size={"large"} />
      <TitleContainer isNotDesktop={isNotDesktop}>
        {t("jira.title.manageConfig")}
      </TitleContainer>
      <ConfigFormContainer
        className={"Form"}
        data-testid="form-container"
        isNotDesktop={isNotDesktop}
      >
        <StyledField
          name={"url"}
          placeholder={t("jira.placeholder.url")}
          type={"text"}
          aria-labelledby={"url"}
          disabled={configExists}
          value={url}
          onChange={e => setUrl(e.target.value)}
        />
        {!configExists && (
          <StyledField
            name={"api_key"}
            placeholder={t("jira.placeholder.apiKey")}
            type={"text"}
            aria-labelledby={"apiKey"}
            value={apiKey}
            onChange={e => setApiKey(e.target.value)}
          />
        )}
        <StyledField
          name={"email"}
          placeholder={t("jira.placeholder.email")}
          type={"text"}
          aria-labelledby={"email"}
          disabled={configExists}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button
          className="submitButton"
          disabled={
            configExists ? null : !(email.length && url.length && apiKey.length)
          }
          data-test="btn-saveJiraConfig"
          accent={!configExists}
          danger={configExists}
          onClick={() => buttonHandler()}
        >
          {t(configExists ? "jira.button.delete" : "jira.button.save")}
        </Button>
      </ConfigFormContainer>
    </StyledContainer>
  );
};
