import { ACTIONS } from "../../static/constants/actions";

export const treeReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_TREE:
      return {
        ...state,
        tree: [payload],
      };
    default:
      return state;
  }
};
