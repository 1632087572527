import styled from "styled-components";

export const StyledContainer = styled.div`
  min-width: 7rem;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${({ theme }) => theme.spacing.paddingMD};
  min-width: 20rem;
  color: ${({ theme }) => theme.colours.mainText};
`;

export const CustomerColumnContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colours.foreground};
  }
`;

export const CustomerColumnHeader = styled.div`
  color: ${({ theme }) => theme.colours.mainText};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  flex: 1 1 0;
  width: 14.28%;
  padding: 0 16px;
`;
