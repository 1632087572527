import styled, { css, keyframes } from "styled-components";

export const StyledMinimalRunCard = styled.div`
  cursor: pointer;
  width: ${({ theme }) => theme.sizing.nodeCardWidth};
  min-width: ${({ theme }) => theme.sizing.nodeCardWidth};

  height: ${({ theme }) => theme.sizing.releaseCardHeight};
  align-items: flex-start;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};

  padding: ${({ theme }) => theme.spacing.padding};

  display: flex;
  flex-direction: column;

  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  transform: ${({ isActive }) => (isActive ? "scale(1.02)" : "none")};

  color: ${({ theme }) => theme.colours.mainText};

  &:hover {
    transition: transform 0.2s;
    transform: scale(1.02);
    z-index: 98;
  }

  ${({ inProgress, theme }) =>
    inProgress &&
    css`
    color: ${theme.colours.mainText}
    --border-angle: 0turn;
    --main-bg: conic-gradient(
        from var(--border-angle),
        ${theme.colours.foreground}, 
        ${theme.colours.foreground} 95%
      );
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, ${theme.colours.haloAccent} , ${theme.colours.haloAccentLight} 99%, transparent);
    
    border: solid 3px transparent;
    
    background: 
      var(--main-bg) padding-box,
      var(--gradient-border) border-box, 
      var(--main-bg) border-box;
    
    background-position: center center;

    animation: ${bgSpin} 3s linear infinite;
    -webkit-animation: ${bgSpin} 3s linear infinite;
    -moz-animation: ${bgSpin} 3s linear infinite;

    &:hover {
      transition: transform 0.2s;
      transform: scale(1.02);
      z-index: 98;
      animation-play-state: paused;
    }

    @property --border-angle {
      syntax: "<angle>";
      inherits: true;
      initial-value: 0turn;
    }
  `}
`;

const bgSpin = keyframes`
  to {
    --border-angle: 1turn;
  }
`;

export const PassBar = styled.div`
  background-color: ${({ theme }) => theme.colours.freezeButtonText};

  height: 12px;
  border-radius: 12px;
  flex-grow: ${({ width }) => width};
`;

export const ProgressElement = styled.div`
  background-image: ${({ theme, type }) =>
    (type === "passed" &&
      `linear-gradient(270deg, ${theme.gradients.passing_start}, ${theme.gradients.passing_end})`) ||
    (type === "failed" &&
      `linear-gradient(90deg, ${theme.gradients.error_start}, ${theme.gradients.error_end})`)};
  height: 100%;
  width: ${({ value }) => `${value}%`};
  transition: all 1s ease-in-out;
  border-radius: ${({ theme }) => theme.borders.radius};
`;

export const TotalProgress = styled.div`
  width: ${({ percent, animate, animated }) =>
    (percent && animate && !animated && "0") ||
    (percent && animate && animated && `${percent}%`) ||
    (percent && `${percent}%`)};
  background-color: ${({ theme }) => theme.colours.background};
  height: 100%;
  display: flex;
  transition: all 1s ease-in-out;
  border-radius: ${({ theme }) => theme.borders.radius};
  height: 12px;

  ${ProgressElement} {
    &:first-child {
      margin-right: 4px;
    }
    &:last-child {
      margin-left: 4px;
    }
    &:only-child {
      margin: 0;
    }
  }
`;

export const GraphContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.colours.minimalGraphHeight};
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme }) => theme.colours.background};
`;

export const LabelContainer = styled.div`
  max-height: ${({ theme }) => theme.sizing.minimalRunCardLabelHeight};
  min-height: ${({ theme }) => theme.sizing.minimalRunCardLabelHeight};
  height: ${({ theme }) => theme.sizing.minimalRunCardLabelHeight};
  width: ${({ theme }) => theme.sizing.minimalRunCardLabelWidth};
  min-width: 100%;
  container-type: inline-size;
  display: flex;
  align-items: center;
`;

export const StickerContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.paddingSM} 0;
  height: ${({ theme }) => theme.sizing.minimalStickerContainer};
  max-height: ${({ theme }) => theme.sizing.minimalStickerContainer};
  width: 100%;
`;

export const CompletionTimeContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  font-size: ${({ theme }) => theme.fontSize.large};

  p {
    color: ${({ theme }) => theme.colours.bulletColour};
    font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  svg {
    height: ${({ theme }) => theme.sizing.svgHeight};
  }
`;

export const Spacer = styled.div`
  height: ${({ theme }) => theme.sizing.svgHeight};
  width: ${({ theme }) => theme.sizing.svgHeight};
`;

const shine = keyframes`
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 0.3s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
`;

export const FakeMinimalRunCard = styled.div`
  width: ${({ theme }) => theme.sizing.nodeCardWidth};
  min-width: ${({ theme }) => theme.sizing.nodeCardWidth};
  height: ${({ theme }) => theme.sizing.releaseCardHeight};
  background: ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  position: relative;
  overflow: hidden;
  display: inline-block;
  
  &:after {
    animation: ${shine} 3s ease-in-out  infinite;
    animation-fill-mode: forwards;  
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right, 
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0.0) 100%
    );
  }
}
`;
