import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, CenteredContainer, Divider } from "../../../components";
import { routes } from "../../../router/routes";
import { FormContainer, ErrorMessage, StyledLink } from "../signIn.styles";

const sp = /[!@#$£%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
const ENTER = "Enter";

export const UnTenantedForm = () => {
  const { t } = useTranslation();
  const [tenant, setTenant] = useState("");
  const [valid, setValid] = useState(true);

  useEffect(() => {
    setValid(!sp.test(tenant));
    if (tenant.includes(" ")) setValid(false);
  }, [tenant]);

  document.addEventListener("keyup", event => {
    var name = event.key;
    var code = event.code;

    let v = !sp.test(tenant);
    if (tenant.includes(" ")) v = false;

    if (name === ENTER && code === ENTER && tenant && valid && v) {
      window.location.href = `${window.location.protocol}//${tenant}.${window.location.host}${routes.SIGNIN.path}`;
    }
  });

  return (
    <FormContainer data-test="untenanted-form">
      <Input
        id="org-input"
        type="text"
        aria-label="organisation-id"
        label={t("signin.orgLabel")}
        data-test="organisation-id-input"
        onChange={e => setTenant(e.target.value)}
        value={tenant}
        align="center"
      />
      <ErrorMessage data-test="error-message">
        {valid ? "" : t("signin.validationError")}
      </ErrorMessage>
      <br />
      <br />
      <CenteredContainer fullWidth>
        <StyledLink
          data-test="signin-link"
          disabled={true}
          rel="noreferrer"
          href={`${window.location.protocol}//${tenant}.${window.location.host}${routes.SIGNIN.path}`}
        >
          <Button data-test="fake-button" disabled={!(tenant && valid)}>
            {t("btnConfirm")}
          </Button>
        </StyledLink>
        <>
          <Divider>{t("signin.or")}</Divider>
          <Button secondary to="/signup" data-test="sign-up-button">
            {t("signin.signUp")}
          </Button>
        </>
      </CenteredContainer>
    </FormContainer>
  );
};
