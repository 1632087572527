import styled from "styled-components";
import { mediaQuery } from "../../../../utils/theme/mediaQuery";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.paddingSM};

  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  background-color: ${({ theme }) => theme.colours.foreground};
  flex-grow: 1;
  height: ${({ theme }) =>
    theme.isNotDesktop
      ? theme.sizing.runTrendsHeightMobile
      : theme.sizing.runTrendsHeight};
  min-height: ${({ theme }) =>
    theme.isNotDesktop
      ? theme.sizing.runTrendsHeightMobile
      : theme.sizing.runTrendsHeight};

  ${mediaQuery.lessThan("XL")`
    width: 100%;
  `}
`;

export const GraphContainer = styled.div`
  flex-grow: 1;
`;

export const TrendsTitle = styled.h1`
  padding-left: ${({ theme }) => theme.spacing.padding};
`;
