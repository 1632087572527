import { DateTime } from "luxon";
import { DATE_FORMAT_YYYMMDD } from "../../static/constants/constants";
import { t } from "i18next";

const DAYS = "days";
const HOURS = "hours";
const MINUTES = "minutes";
const STRING = "string";
const NUMBER = "number";

export const todaysDate = DateTime.now();

export const isSQLDate = date =>
  typeof date === STRING &&
  !date.includes(":") &&
  date.match(/-/g)?.length === 2;

export const isISODate = date =>
  typeof date === STRING && date.includes(":") && date.includes("-");

export const isJSDate = date => date instanceof Date;

export const isLuxonDate = date => date instanceof DateTime;

export const isTimestampDate = date => typeof date === NUMBER;

export const convertDateToLuxonDate = date => {
  if (!date) return null;
  if (isLuxonDate(date)) return date;
  if (isTimestampDate(date)) return DateTime.fromMillis(date);
  if (isSQLDate(date)) return DateTime.fromSQL(date);
  if (isJSDate(date)) return DateTime.fromJSDate(date);
  if (isISODate(date)) return DateTime.fromISO(date);
};

export const convertDateToTs = date => convertDateToLuxonDate(date)?.ts;

export const convertDateToSQL = date =>
  convertDateToLuxonDate(date)?.toFormat(DATE_FORMAT_YYYMMDD);

export const isFutureDate = date =>
  convertDateToTs(date) > convertDateToTs(todaysDate);

export const isPastDate = date =>
  convertDateToTs(date) < convertDateToTs(todaysDate);

export const dateDifference = (date1, date2, format) => {
  const ld1 = convertDateToLuxonDate(date1);
  const ld2 = convertDateToLuxonDate(date2);

  return ld1.diff(ld2, format);
};

export const daysAgo = date =>
  Math.ceil(dateDifference(date, DateTime.now(), DAYS).days);

export const hoursAgo = date =>
  Math.ceil(dateDifference(date, DateTime.now(), HOURS).hours);

export const minutesAgo = date =>
  Math.ceil(dateDifference(date, DateTime.now(), MINUTES).minutes);

export const timeAgo = date => {
  const days = Math.abs(daysAgo(date));

  if (days >= 1) return `${days} ${t("timeAgo.days")}`;

  const hours = Math.abs(hoursAgo(date));
  if (hours >= 1) return `${hours} ${t("timeAgo.hours")}`;

  const minutes = Math.abs(minutesAgo(date));
  if (minutes >= 1) return `${minutes} ${t("timeAgo.minutes")}`;

  return t("timeAgo.lessThanAMinute");
};

export const daysUntil = date =>
  Math.floor(dateDifference(todaysDate, date, DAYS).days);

export const isTodaysDate = date => {
  const formattedDate = convertDateToLuxonDate(date);
  return (
    todaysDate.day === formattedDate.day &&
    todaysDate.month === formattedDate.month &&
    todaysDate.year === formattedDate.year
  );
};

export const mediumDateTime = date =>
  convertDateToLuxonDate(date)?.toLocaleString(DateTime.DATETIME_MED);
