import { useParams } from "react-router-dom";
import { TestevolveRunReport } from "./testevolve/testevolveRunReport";
import { useRunReportView } from "./testevolve/hooks/useRunReportView";
import { useEffect } from "react";
import { useJiraConfig } from "../../context/jiraConfig/jiraConfig.provider";
import { Loading } from "../../components";
import { ErrorPage } from "../../components/errorPage/errorPage";
import { useLocation } from "react-router-dom";
import { useQueryParams } from "../../context/hooks";
import { PlaywrightRunReport } from "./playwright/playwrightRunReport";
import { CypressRunReport } from "./cypress/cypressRunReport";
import { TEST_CATEGORIES } from "../../static/constants/constants";

export const RunReportView = () => {
  const { hash } = useLocation();
  const { runType, runId } = useParams();
  const {
    run,
    error,
    getRunWithParams,
    pageData,
    runFeatures,
    resetRunFeatures,
    resetRunSuites,
    isFetching,
    resetPageData,
    runSuites,
    isResolved,
    scenariosLength,
  } = useRunReportView();
  const { getConfig, isJiraConfigured, jiraLoading } = useJiraConfig();
  const { getAllParams } = useQueryParams();

  useEffect(() => {
    getConfig();

    const body = getAllParams();
    if (hash) body.feature = hash?.replace("#", "");
    if (body.status) body.statusFilter = [body.status];
    if (body.tags) body.tags = [body.tags];

    getRunWithParams(runId, body);
  }, [runId]);

  if (error) return <ErrorPage code={error.code} />;

  if (run && !jiraLoading) {
    switch (runType) {
      case TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL:
        return (
          <TestevolveRunReport
            run={run}
            scenariosLength={scenariosLength}
            isResolved={isResolved}
            isFetching={isFetching}
            resetPageData={resetPageData}
            runFeatures={runFeatures}
            resetRunFeatures={resetRunFeatures}
            runId={runId}
            runType={runType}
            pageData={pageData}
            getRunWithParams={getRunWithParams}
            isJiraConfigured={isJiraConfigured}
            error={error}
            hash={hash}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE:
        return (
          <TestevolveRunReport
            run={run}
            scenariosLength={scenariosLength}
            isResolved={isResolved}
            isFetching={isFetching}
            resetPageData={resetPageData}
            runId={runId}
            pageData={pageData}
            runFeatures={runFeatures}
            resetRunFeatures={resetRunFeatures}
            getRunWithParams={getRunWithParams}
            runType={runType}
            axe={true}
            isJiraConfigured={isJiraConfigured}
            error={error}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_AUDIT_LIGHTHOUSE:
        return (
          <TestevolveRunReport
            run={run}
            scenariosLength={scenariosLength}
            isResolved={isResolved}
            isFetching={isFetching}
            pageData={pageData}
            resetPageData={resetPageData}
            getRunWithParams={getRunWithParams}
            runId={runId}
            runFeatures={runFeatures}
            resetRunFeatures={resetRunFeatures}
            runType={runType}
            lighthouse={true}
            isJiraConfigured={isJiraConfigured}
            error={error}
          />
        );
      case TEST_CATEGORIES.PLAYWRIGHT_FUNCTIONAL:
        return (
          <PlaywrightRunReport
            run={run}
            scenariosLength={scenariosLength}
            isResolved={isResolved}
            isFetching={isFetching}
            pageData={pageData}
            resetPageData={resetPageData}
            getRunWithParams={getRunWithParams}
            runId={runId}
            runSuites={runSuites}
            resetRunSuites={resetRunSuites}
            runType={runType}
            isJiraConfigured={isJiraConfigured}
            error={error}
          />
        );
      case TEST_CATEGORIES.CYPRESS_FUNCTIONAL:
        return (
          <CypressRunReport
            run={run}
            scenariosLength={scenariosLength}
            isResolved={isResolved}
            isFetching={isFetching}
            pageData={pageData}
            resetPageData={resetPageData}
            getRunWithParams={getRunWithParams}
            runId={runId}
            runSuites={runSuites}
            resetRunSuites={resetRunSuites}
            runType={runType}
            isJiraConfigured={isJiraConfigured}
            error={error}
          />
        );
      default:
        return null;
    }
  } else return <Loading />;
};
