import { ACTIONS } from "../../../static/constants/actions";

export const UserOptionsReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.GET_USER_OPTIONS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
