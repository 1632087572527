const API_PREFIX = "/api";

export const ENDPOINTS = {
  AUTH_SIGNIN: {
    path: `${API_PREFIX}/auth/login`,
  },
  AUTH_SIGNOUT: {
    path: `${API_PREFIX}/auth/logout`,
  },
  AUTH_SIGNUP: {
    path: `${API_PREFIX}/register`,
    version: "1",
  },
  API_KEYS: {
    path: `${API_PREFIX}/keys`,
    version: "1",
  },
  USER: {
    path: `${API_PREFIX}/user`,
    version: "1",
  },
  REPORT_CONFIGS: {
    path: `${API_PREFIX}/report`,
    version: "1",
  },
  REPORT_CONFIG: {
    path: `${API_PREFIX}/report/:id`,
    version: "1",
  },
  REPORT_PREVIEW: {
    path: `${API_PREFIX}/report/preview`,
    version: "1",
  },
  CONFIG: {
    path: `${API_PREFIX}/config`,
    version: "1",
  },
  SUBSCRIPTION_PREVIEW: {
    path: `${API_PREFIX}/subscription/preview`,
    version: "1",
  },
  PAYMENT_INTENT: {
    path: `${API_PREFIX}/subscription/setup-intent`,
    version: "1",
  },
  BILLING_INVOICE: {
    path: `${API_PREFIX}/subscription/invoice`,
    version: "1",
  },
  BILLING_DETAILS: {
    path: `${API_PREFIX}/subscription/billing-details`,
    version: "1",
  },
  ORGANISATION: {
    path: `${API_PREFIX}/organisation`,
    version: "1",
  },
  RESET_PASSWORD: {
    path: `${API_PREFIX}/user/password/reset`,
    version: "1",
  },
  PASSWORD: {
    path: `${API_PREFIX}/user/password`,
    version: "1",
  },
  PROJECTS: {
    path: `${API_PREFIX}/project`,
    version: "1",
  },
  USERS_LIST: {
    path: `${API_PREFIX}/user/list`,
    version: "1",
  },
  USER_STATUS: {
    path: `${API_PREFIX}/user/:id`,
    version: "1",
  },
  USER_GLOBAL_ROLES: {
    path: `${API_PREFIX}/user/:id/roles/global`,
    version: "1",
  },
  USER_PROJECT_ROLES: {
    path: `${API_PREFIX}/user/:id/roles/project`,
    version: "1",
  },
  UNLOCK_USER: {
    path: `${API_PREFIX}/user/unlock`,
    version: "1",
  },
  USER_INVITE: {
    path: `${API_PREFIX}/user/invite`,
    version: "1",
  },
  USER_RESEND_INVITE: {
    path: `${API_PREFIX}/user/invite/resend`,
    version: "1",
  },
  TREE: {
    path: `${API_PREFIX}/node/tree`,
    version: "1",
  },
  NODE_SUMMARY: {
    path: `${API_PREFIX}/node/:nodeId`,
    version: "1",
  },
  RUN: {
    path: `${API_PREFIX}/run/:provider/:id`,
    version: "2",
  },
  EDIT_LABEL: {
    path: `${API_PREFIX}/run/:provider/:id/label`,
    version: "2",
  },
  APPROVE_RUN: {
    path: `${API_PREFIX}/run/:provider/:id/approve`,
    version: "2",
  },
  RUN_DETAILS: {
    path: `${API_PREFIX}/run/:provider/:category/labelled/:label?page=:page&pageSize=10`,
    version: "2",
  },
  NODE_DETAILS: {
    path: `${API_PREFIX}/node/:nodeId/details`,
    version: "1",
  },
  ADD_NODE: {
    path: `${API_PREFIX}/node`,
    version: "1",
  },
  DELETE_NODE: {
    path: `${API_PREFIX}/node/:id`,
    version: "1",
  },
  RENAME_NODE: {
    path: `${API_PREFIX}/node/:id`,
    version: "1",
  },
  DELETE_PROJECT: {
    path: `${API_PREFIX}/project/:id`,
    version: "1",
  },
  RENAME_PROJECT: {
    path: `${API_PREFIX}/project/:id`,
    version: "1",
  },
  CREATE_PROJECT: {
    path: `${API_PREFIX}/project`,
    version: "1",
  },
  MOVE_RUN: {
    path: `${API_PREFIX}/node/:nodeId/run/:category/labelled/:label`,
    version: "1",
  },
  DELETE_RUN: {
    path: `${API_PREFIX}/run/:provider/:category/labelled/:label`,
    version: "2",
  },
  EDIT_RUN: {
    path: `${API_PREFIX}/run/:provider/:runId`,
    version: "2",
  },
  PAYMENT_METHOD: {
    path: `${API_PREFIX}/subscription/payment-method`,
    version: "1",
  },
  SUBSCRIPTION: {
    path: `${API_PREFIX}/subscription`,
    version: "1",
  },
  SUBSCRIPTION_CURRENT: {
    path: `${API_PREFIX}/subscription/current`,
    version: "1",
  },
  SUBSCRIPTION_PLANS: {
    path: `${API_PREFIX}/subscription/plans/:currency`,
    version: "1",
  },
  CUSTOMER_ORGANISATIONS: {
    path: `${API_PREFIX}/partner/organisation`,
    version: "1",
  },
  CUSTOMER_ORGANISATION: {
    path: `${API_PREFIX}/partner/organisation/:org_id`,
    version: "1",
  },
  DISASSOCIATE_CUSTOMER_ORGANISATION: {
    path: `${API_PREFIX}/partner/organisation/:org_id/disassociate`,
    version: "1",
  },
  PREVIEW_CREATE_CUSTOMER_ORGANISATION: {
    path: `${API_PREFIX}/partner/organisation/preview?product=:product&orgId=:org_id&name=:name`,
    version: "1",
  },
  PREVIEW_UPDATE_CUSTOMER_ORGANISATION: {
    path: `${API_PREFIX}/partner/organisation/:org_id/preview?product=:product`,
    version: "1",
  },
  JIRA_CONFIG: {
    path: `${API_PREFIX}/jira/config`,
    version: "1",
  },
  JIRA_PRIORITIES: {
    path: `${API_PREFIX}/jira/priorities`,
    version: "1",
  },
  JIRA_PROJECTS: {
    path: `${API_PREFIX}/jira/projects`,
    version: "1",
  },
  JIRA_ISSUE: {
    path: `${API_PREFIX}/jira/issue`,
    version: "1",
  },
  JIRA_BUGS: {
    path: `${API_PREFIX}/jira/run/:runId`,
    version: "1",
  },
  LABELS: {
    path: `${API_PREFIX}/node/labels`,
    version: "1",
  },
  NODE_LABELS: {
    path: `${API_PREFIX}/node/:nodeId/labels`,
    version: "1",
  },
  UNASSIGNED_LABELS_WITH_RUNS: {
    path: `${API_PREFIX}/node/labels/runs?page=:page&pageSize=10`,
    version: "1",
  },
  ENABLE_SHARING: {
    path: `${API_PREFIX}/run/:provider/enable-sharing`,
    version: "2",
  },
  DISABLE_SHARING: {
    path: `${API_PREFIX}/run/:provider/disable-sharing/:runSharingToken`,
    version: "2",
  },
  QUERY_RUN_SHARING_TOKEN: {
    path: `${API_PREFIX}/run/:provider/kiosk-mode/query`,
    version: "2",
  },
  GET_SHARED_RUN: {
    path: `${API_PREFIX}/run/:provider/kiosk-mode/:runSharingToken`,
    version: "2",
  },
  GET_INSIGHTS: {
    path: `${API_PREFIX}/insights/:projectId/:label/:category`,
    version: "1",
  },
  GET_RUN_INSIGHTS: {
    path: `${API_PREFIX}/runInsights/:projectId/:label/:category`,
    version: "1",
  },
  DOWNLOAD_RUNS: {
    path: `${API_PREFIX}/run/:provider/:category/labelled/:label/export?startDate=:startDate&endDate=:endDate`,
    version: "2",
  },
  DOWNLOAD_REPORT: {
    path: `${API_PREFIX}/failureScenario/:runId/export`,
    version: "1",
  },
  SCREENSHOT: {
    path: `${API_PREFIX}/screenshot/:id`,
  },
  RELEASES: {
    path: `${API_PREFIX}/release`,
  },
  RELEASE: {
    path: `${API_PREFIX}/release/:id`,
  },
  QUERY_RELEASE_SHARING_TOKEN: {
    path: `${API_PREFIX}/release/releaseId/kiosk-mode/query`,
  },
  ENABLE_RELEASE_SHARING: {
    path: `${API_PREFIX}/release/:releaseId/enable-sharing`,
  },
  DISABLE_RELEASE_SHARING: {
    path: `${API_PREFIX}/release/disable-sharing/:releaseSharingToken`,
  },
  GET_SHARED_RELEASE: {
    path: `${API_PREFIX}/release/kiosk-mode/:releaseSharingToken`,
  },
  RELEASE_LABELS: {
    path: `${API_PREFIX}/release/:releaseId/label`,
  },
  CLOSE_RELEASE: {
    path: `${API_PREFIX}/release/:releaseId/close`,
  },
  DUPLICATE_RELEASE: {
    path: `${API_PREFIX}/release/:releaseId/copy`,
  },
  OPEN_RELEASE: {
    path: `${API_PREFIX}/release/:releaseId/open`,
  },
  FREEZE_RUN: {
    path: `${API_PREFIX}/run/:provider/:runId/release/:releaseId/freeze`,
    version: "2",
  },
  UNFREEZE_RUN: {
    path: `${API_PREFIX}/run/:provider/:runId/release/:releaseId/unfreeze`,
    version: "2",
  },
  LATEST_RUNS: {
    path: `${API_PREFIX}/run/recent`,
    version: "2",
  },
  IN_PROGRESS_RUNS: {
    path: `${API_PREFIX}/run/in-progress`,
    version: "2",
  },
  EXPLORATORY_RUN: {
    path: `${API_PREFIX}/run/manual`,
    version: "2",
  },
  CREATE_SESSION: {
    path: `${API_PREFIX}/run/manual/:runId/session`,
    version: "2",
  },
  CREATE_ENTRY: {
    path: `${API_PREFIX}/run/manual/session/:sessionId`,
    version: "2",
  },
  EDIT_ENTRY: {
    path: `${API_PREFIX}/run/manual/entry/:entryId`,
    version: "2",
  },
  OPEN_MANUAL_RUN: {
    path: `${API_PREFIX}/run/manual/:runId/open`,
    version: "2",
  },
  CLOSE_MANUAL_RUN: {
    path: `${API_PREFIX}/run/manual/:runId/close`,
    version: "2",
  },
  OPEN_SESSION: {
    path: `${API_PREFIX}/run/manual/session/:sessionId/open`,
    version: "2",
  },
  SESSION_SUMMARY: {
    path: `${API_PREFIX}/run/manual/session/:sessionId`,
    version: "2",
  },
  CLOSE_SESSION: {
    path: `${API_PREFIX}/run/manual/session/:sessionId/close`,
    version: "2",
  },
  DELETE_SESSION: {
    path: `${API_PREFIX}/run/manual/session/:sessionId`,
    version: "2",
  },
  ADD_ENTRY_ATTACHMENT: {
    path: `${API_PREFIX}/run/manual/entry/:entryId`,
    version: "2",
  },
  GET_ATTACHMENT: {
    path: `${API_PREFIX}/run/manual/attachment/:attachmentId`,
    version: "2",
  },
  PATCH_SUCCESS_CRITERIA: {
    path: `${API_PREFIX}/run/manual/:runId/refId/:refId`,
    version: "2",
  },
};
