import { useEffect, useState } from "react";
import { useUpdate } from "../../../../../../context/billingUpdate/billingUpdate.provider";
import { useFetch } from "../../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";

const defaultAddress = {
  name: null,
  email: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    postal_code: "",
    state: "",
    country: null,
  },
  phone: "",
  vat_number: "",
  company_name: "",
};

export const useBillingDetails = () => {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState(defaultAddress);
  const [billingDetailsLoaded, setBillingDetailsLoaded] = useState(false);
  const { setBillingUpdate } = useUpdate();
  const { get, put, status, error, body, method } = useFetch();

  const getBillingDetails = () => get(ENDPOINTS.BILLING_DETAILS);

  const putBillingDetails = payload => put(ENDPOINTS.BILLING_DETAILS, payload);

  useEffect(() => {
    if (status.isResolved && body && method === "GET") {
      setBillingDetailsLoaded(true);
      setBillingDetails(prev => ({ ...prev, ...body }));
    }

    if (status.isResolved && method === "PUT") {
      setEditMode(false);
      setIsLoading(false);
      setBillingUpdate();
    }
  }, [status.isResolved, status.isRejected, error, body]);

  return {
    billingDetails,
    getBillingDetails,
    putBillingDetails,
    defaultAddress,
    isLoading,
    editMode,
    setIsLoading,
    setEditMode,
    error,
    billingDetailsPending: status.isLoading,
    billingDetailsLoaded,
  };
};
