import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import {
  LabelContainer,
  RunDetailsContainer,
  InfoContainer,
  StyledLinkIcon,
} from "./runDetails.styles";
import { Button } from "../../../components";
import { routes } from "../../../router/routes";
import { useQueryParams } from "../../../context/hooks";

export const RunDetailsContent = ({ name, run }) => {
  const { t } = useTranslation();
  const { runType } = useParams();
  const history = useHistory();

  const { getParam } = useQueryParams();
  const project = getParam("project");

  const redirectUrl = `${routes.RUN_VIEW.path}?project=${project}`
    .replace(":nodeId", "null")
    .replace(":runType", run?.category)
    .replace(":label", encodeURI(encodeURIComponent(run?.label)))
    .replace(":runId", run?.id);

  return (
    <RunDetailsContainer>
      <InfoContainer>
        <LabelContainer>
          <h1 data-test="title">{name}</h1>
        </LabelContainer>
        <p data-test="title">{t(`runView.runDetails.${runType}`)}</p>
      </InfoContainer>
      <Button onClick={() => history.push(redirectUrl)}>
        <StyledLinkIcon />
        {t("sharedRunView.fullRunView")}
      </Button>
    </RunDetailsContainer>
  );
};

export const SharedRunDetails = props => <RunDetailsContent {...props} />;
