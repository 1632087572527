import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";
import { useFetch } from "../../../../../../context/hooks";
import { useEffect, useRef } from "react";
import { useTree } from "../../../../../../context/tree/tree.provider";
import { useNotifications } from "../../../../../../context/hooks/useNotifications/useNotifications";
import {
  CLICK,
  ESCAPE,
  KEYDOWN,
  KEYS,
} from "../../../../../../static/constants/constants";

export const useRenameNode = closeRenameNode => {
  const renameRef = useRef(null);

  const { put, status, body, error } = useFetch();
  const { getTree, getTreeSuccess } = useTree();
  const { createNotification, types } = useNotifications();

  const handleKeyboard = ({ key }) => {
    if (key === ESCAPE) closeRenameNode();
  };

  const handleClick = e => {
    if (renameRef?.current && renameRef.current !== e.target) closeRenameNode();
  };

  useEffect(() => {
    document.addEventListener(KEYDOWN, handleKeyboard, true);
    document.addEventListener(CLICK, handleClick, true);

    return () => {
      document.removeEventListener(KEYDOWN, handleKeyboard);
      document.removeEventListener(CLICK, handleClick);
    };
  }, [renameRef]);

  useEffect(() => {
    if (getTreeSuccess) closeRenameNode();
  }, [getTreeSuccess]);

  useEffect(() => {
    if (status.isRejected)
      createNotification(
        types.ERROR,
        `errors.addNode.${
          Array.isArray(error.text) ? error.text[0] : error.text
        }`
      );
    if (status.isResolved && body) {
      getTree();
      createNotification(types.SUCCESS, "success.addNode");
    }
  }, [status.isRejected, status.isResolved, body]);

  const submitHandler = (e, id) => {
    if (e.key === KEYS.ENTER || e.keyCode === 13)
      put(
        {
          ...ENDPOINTS.RENAME_NODE,
          path: ENDPOINTS.RENAME_NODE.path.replace(":id", id),
        },
        { name: e.target.value }
      );
    if (e.key === KEYS.ESCAPE) closeRenameNode();
  };

  const onBlurHandler = (e, id) => {
    if (e.target.value?.length)
      put(
        {
          ...ENDPOINTS.RENAME_NODE,
          path: ENDPOINTS.RENAME_NODE.path.replace(":id", id),
        },
        { name: e.target.value }
      );
    closeRenameNode();
  };

  return {
    submitHandler,
    onBlurHandler,
    renameRef,
  };
};
