import React from "react";
import { useTranslation } from "react-i18next";
import { useProjectView } from "../hooks/useProjectView";
import { useTime } from "../../../context/hooks/time/useTime";
import { Container, ProjectId, Spacer } from "./projectCard.styles";
import { ProjectCardContextMenu } from "../../../components/menus/menus";

export const ProjectCard = ({ project, getProjects }) => {
  const { t } = useTranslation();

  const { getReadableTimeDifference } = useTime();
  const { projectCardHandler } = useProjectView();

  const timeAgo = () =>
    `${getReadableTimeDifference(project.date_created)} ${t("time.ago")}` ||
    "N/A";

  return project ? (
    <Container
      onClick={() => projectCardHandler(project)}
      data-test="project-card"
    >
      <ProjectId>
        {t("projectView.id")} {project.id}
        <ProjectCardContextMenu project={project} getProjects={getProjects} />
      </ProjectId>
      <h1 data-test="project-name">{project.name}</h1>
      <p>{project?.description}</p>
      <Spacer />
      {project.date_created ? (
        <p>
          {t("projectView.created")} {timeAgo()}
        </p>
      ) : null}
    </Container>
  ) : null;
};
