import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import {
  CSV_HEADERS,
  EXPORT_REPORT_FILE_NAME,
} from "../../../../static/constants/constants";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";

export const useDownloadReport = () => {
  const { t } = useTranslation();
  const { getFile, status, body } = useFetch();
  const { createNotification, types } = useNotifications();
  const downloadReport = (runId, label) =>
    getFile(
      {
        ...ENDPOINTS.DOWNLOAD_REPORT,
        path: ENDPOINTS.DOWNLOAD_REPORT.path.replace(":runId", runId),
      },
      null,
      CSV_HEADERS,
      EXPORT_REPORT_FILE_NAME.replace(":label", label)
    );

  useEffect(() => {
    if (status.isResolved)
      createNotification(types.SUCCESS, "runView.export.successMessage");
    if (status.isRejected)
      createNotification(types.ERROR, "runView.runFailuresError");
  }, [status.isRejected, status.isResolved, body]);

  const DownloadButton = ({ runId, label }) => (
    <Button accent={1} onClick={() => downloadReport(runId, label)}>
      {t("btnDownloadInsights")}
    </Button>
  );

  return {
    DownloadButton,
  };
};
