import { useParams } from "react-router-dom";
import { ReleaseLabels } from "./releaseLabels/releaseLabels";
import { ReleaseDetails } from "./releaseDetails/releaseDetails";
import { ReleaseViewContainer } from "./release.styles";
import { useGetRelease } from "../../context/releases/useGetRelease";
import { ReleaseRuns } from "./releaseRuns/releaseRuns";
import { useReleases } from "../../context/releases/releases.provider";
import { Loading } from "../../components";
import { usePolling } from "../../context/hooks/poll/usePolling";
import { RELEASE_VIEW_POLLING_INTERVAL } from "../../static/constants/constants";

export const ReleaseView = () => {
  const { releaseId } = useParams();
  const { getRelease } = useGetRelease();
  const { release } = useReleases();

  usePolling(() => {
    getRelease(releaseId);
  }, RELEASE_VIEW_POLLING_INTERVAL);

  if (release?.id?.toString() !== releaseId?.toString()) return <Loading />;

  return (
    <ReleaseViewContainer>
      <ReleaseDetails />
      <ReleaseLabels />
      <ReleaseRuns />
    </ReleaseViewContainer>
  );
};
