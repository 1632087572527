import React from "react";
import { useTranslation } from "react-i18next";
import { useApp } from "../../context/app/app.provider";
import { DropdownMenu } from "./sharedDropdown";
import {
  Logo,
  StyledHeader,
  LogoContainer,
} from "../../components/header/header.styles";
import { useDropdown } from "../../components/header/dropdownMenu/hooks/useDropdown";

export const Header = () => {
  const { t } = useTranslation();
  const { toggleOpen } = useDropdown();
  const { isMobile } = useApp();
  const { isNotDesktop } = useApp();

  return (
    <StyledHeader isNotDesktop={isNotDesktop}>
      <LogoContainer>
        <Logo $ismobile={isMobile} />
        {!isMobile ? <p>{t("header.logoSupportText")}</p> : null}
      </LogoContainer>
      <DropdownMenu toggleOpen={() => toggleOpen()} />
    </StyledHeader>
  );
};
