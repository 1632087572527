import { useEffect } from "react";
import { useFetch } from "../hooks";
import { ENDPOINTS } from "../../static/constants/routeConfig";
import { useReleases } from "./releases.provider";

export const useGetRelease = () => {
  const { release, setRelease } = useReleases();
  const { get, status, body } = useFetch();

  useEffect(() => {
    if (status.isResolved && body) setRelease(body);
  }, [status.isResolved, status.isRejected]);

  const getRelease = id =>
    get({
      ...ENDPOINTS.RELEASE,
      path: ENDPOINTS.RELEASE.path.replace(":id", id),
    });

  return {
    release,
    getRelease,
    releaseLoading: status.isLoading,
  };
};
