import React from "react";
import { useTable } from "react-table";
import {
  StyledCardWrapper,
  StyledCard,
  NoDataMessage,
  StyledTableContainer,
  StyledRowContainer,
  StyledHeaderRowContainer,
  StyledHeaderTableContainer,
  StyledFieldContainer,
  StyledTable,
} from "./AdminLogsTableStyles";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const Table = ({ data, columns }) => {
  const { t } = useTranslation();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data,
      columns,
    });

  const localesLabels = t("auditLogs.tableLabels", {
    returnObjects: true,
  });

  return (
    <StyledCard>
      <StyledCardWrapper>
        {!data ||
          (data.length < 1 && (
            <NoDataMessage> {t("auditLogs.noDataMessage")}</NoDataMessage>
          ))}

        <StyledTable {...getTableProps()} data-test="audit-table">
          <thead>
            {headerGroups.map((headerGroup, outer_index) => {
              if (outer_index === 0) {
                return null;
              }
              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={outer_index}>
                  <StyledHeaderRowContainer>
                    {headerGroup.headers.map((column, inner_index) => (
                      <StyledHeaderTableContainer key={inner_index}>
                        <td {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </td>
                      </StyledHeaderTableContainer>
                    ))}
                  </StyledHeaderRowContainer>
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, outer_index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={outer_index}>
                  <StyledRowContainer>
                    {row.cells.map((cell, inner_index) => {
                      return (
                        <StyledTableContainer key={inner_index}>
                          <td
                            {...cell.getCellProps()}
                            data-test={`cell-${inner_index}`}
                          >
                            {inner_index === 0 && (
                              <StyledFieldContainer>
                                {localesLabels[0]}
                              </StyledFieldContainer>
                            )}
                            {inner_index === 1 && (
                              <StyledFieldContainer>
                                {localesLabels[1]}
                              </StyledFieldContainer>
                            )}
                            {inner_index === 2 && (
                              <StyledFieldContainer>
                                {localesLabels[2]}
                              </StyledFieldContainer>
                            )}
                            {inner_index === 3 && (
                              <StyledFieldContainer>
                                {localesLabels[3]}
                              </StyledFieldContainer>
                            )}
                            {inner_index === 4 && (
                              <StyledFieldContainer>
                                {localesLabels[4]}
                              </StyledFieldContainer>
                            )}
                            {cell.render("Cell")}
                          </td>
                        </StyledTableContainer>
                      );
                    })}
                  </StyledRowContainer>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </StyledCardWrapper>
    </StyledCard>
  );
};

export const TableComponent = ({ adminLogs }) => {
  const { t } = useTranslation();

  const convertDates = props => {
    props.sort((a, b) => {
      return b.created_time - a.created_time;
    });

    return props.map(log => {
      const copyOfLog = { ...log };
      const dateObject = DateTime.fromMillis(log.created_time);
      copyOfLog.created_time = DateTime.fromObject(dateObject.c).toLocaleString(
        DateTime.DATETIME_SHORT
      );
      return copyOfLog;
    });
  };

  const localesLabels = t("auditLogs.tableLabels", {
    returnObjects: true,
  });

  const data = React.useMemo(() => convertDates(adminLogs), [adminLogs]);

  const columns = React.useMemo(
    () => [
      {
        id: 1,
        columns: [
          {
            Header: `${localesLabels[0]}`,
            accessor: "username",
          },
          {
            Header: `${localesLabels[1]}`,
            accessor: "action",
          },
          {
            Header: `${localesLabels[2]}`,
            accessor: "entity",
          },
          {
            Header: `${localesLabels[3]}`,
            accessor: "context",
          },
          {
            Header: `${localesLabels[4]}`,
            accessor: "created_time",
          },
        ],
      },
    ],
    [localesLabels]
  );

  return <Table data={data} columns={columns} />;
};
