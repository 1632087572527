import { useRun } from "../../../hooks/useRun";
import { useTranslation } from "react-i18next";
import { useTime } from "../../../../../context/hooks/time/useTime";
import { useNodeView } from "../../../../nodeView/hooks/useNodeView";

export const useLatestRun = () => {
  const { t } = useTranslation();
  const { selectedRun } = useRun();
  const { npmAudit } = useNodeView();
  const { getReadableTimeDifference } = useTime();
  const run = selectedRun || npmAudit.latest_run;

  const timeAgo =
    `${getReadableTimeDifference(run.finish_time ?? run.updated_time)} ${t(
      "time.ago"
    )}` || "N/A";

  const vulnerabilities = run.data.vulnerabilities;

  const totalVulnerabilities =
    vulnerabilities.critical +
    vulnerabilities.high +
    vulnerabilities.moderate +
    vulnerabilities.low +
    vulnerabilities.info;
  const chartData = [
    {
      name: t("runCard.npmaudit.vulnerabilities.critical"),
      count: vulnerabilities.critical,
    },
    {
      name: t("runCard.npmaudit.vulnerabilities.high"),
      count: vulnerabilities.high,
    },
    {
      name: t("runCard.npmaudit.vulnerabilities.moderate"),
      count: vulnerabilities.moderate,
    },
    {
      name: t("runCard.npmaudit.vulnerabilities.low"),
      count: vulnerabilities.low,
    },
    {
      name: t("runCard.npmaudit.vulnerabilities.info"),
      count: vulnerabilities.info,
    },
  ];

  return {
    timeAgo,
    chartData,
    totalVulnerabilities,
    dependencies: run.data.dependencies,
    devDependencies: run.data.devDependencies,
    optionalDependencies: run.data.optionalDependencies,
    totalDependencies: run.data.totalDependencies,
  };
};
