import { useTheme } from "styled-components";
import { Input, Modal } from "../../../../components";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "../../../../views/projectView/hooks/styles";
import { useEffect, useState } from "react";
import { useUpdateLabel } from "../../../../views/runView/testEvolve/latestRunCard/hooks/useUpdateLabel";
import {
  CheckboxContainer,
  CheckboxTitle,
  MyCheckbox,
} from "./updateLabelModal.styled";

const MIN_LABEL_LENGTH = 1;
const MAX_LABEL_LENGTH = 50;

export const UpdateLabelModal = ({
  closeModal,
  runLabel,
  runCategory,
  run,
}) => {
  const { isDark } = useTheme();
  const { t } = useTranslation();
  const { patchLabel, updateLabelFail } = useUpdateLabel(runCategory);
  const [checked, setChecked] = useState(false);
  const [labelValue, setLabelValue] = useState(null);
  const [formError, setFormError] = useState({});

  const handleSubmit = () => {
    if (
      labelValue === null ||
      labelValue.length < MIN_LABEL_LENGTH ||
      labelValue.length > MAX_LABEL_LENGTH
    ) {
      setFormError({ error: true, reason: "validationError" });
    } else if (labelValue == runLabel) {
      setFormError({ error: true, reason: "sameLabelError" });
    } else {
      patchLabel(run, labelValue, checked);
    }
  };

  useEffect(() => {
    resetFields();
  }, [updateLabelFail]);

  const resetFields = () => {
    setChecked(false);
    setLabelValue(null);
  };

  return (
    <Modal
      size="small"
      title={t("runView.updateLabel.modalTitle")}
      primary={{
        text: t("btnConfirm"),
        onClick: handleSubmit,
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <Input
        data-test="label-input"
        type="text"
        autoFocus={true}
        label={t("runView.updateLabel.labelInputTitle")}
        placeholder={runLabel}
        value={labelValue}
        onChange={e => setLabelValue(e.target.value)}
        onFocus={() => setFormError({})}
        maxLength={MAX_LABEL_LENGTH}
      />
      <CheckboxContainer>
        <MyCheckbox
          data-test="all-runs-check"
          checked={checked}
          className="allRunsCheck"
          dark={isDark}
          onChange={() => setChecked(f => !f)}
        />
        <CheckboxTitle>{t("runView.updateLabel.modalText")}</CheckboxTitle>
      </CheckboxContainer>

      {formError.error ? (
        <ErrorMessage>
          {t(`runView.updateLabel.${formError.reason}`)}
        </ErrorMessage>
      ) : (
        <p> </p>
      )}
    </Modal>
  );
};
