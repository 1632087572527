import React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../router/routes";
import { useQueryParams } from "../../../../context/hooks";
import { useApp } from "../../../../context/app/app.provider";
import { useNodeView } from "../../../nodeView/hooks/useNodeView";
import { useRunHistoryPagination } from "../../hooks/useRunHistoryPagination";
import {
  Container,
  StyledTable,
  StyledRow,
  TableFooter,
  TableContainer,
  BannerCell,
  StyledBanner,
  FlexContainer,
  FlexTd,
} from "../../testEvolve/runHistory/runHistory.styles";
import {
  PAGE,
  TEST_CATEGORIES,
  NO_UPGRADE_SUBS,
  LATEST,
  DATE_OPTIONS,
  RELEASE_STATUSES,
  RUN_STATUSES,
} from "../../../../static/constants/constants";
import { useHistoryWithParams } from "../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { usePermissions } from "../../../../context/hooks";
import { approvalUtils } from "../../hooks/approval/approvalUtils";
import { ApprovedContainerRunHistory } from "../../../../components/modal/modals/runApproveModal/approval.styles";
import { useSubscription } from "../../../../context/subscription/subscription.provider";
import { FreezeIcon } from "../../runDetails/runDetails.styles";
import { RunHistoryContextMenu } from "../../../../components/menus/runHistoryContextMenu/runHistoryContextMenu";
import { useParams } from "react-router-dom";
import { useTime } from "../../../../context/hooks/time/useTime";
import { StatusSticker } from "../../../releaseView/release.styles";
import { useTheme } from "styled-components";

export const TestEvolveExploratoryRunHistory = ({
  runDetails,
  getRunDetails,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistoryWithParams();
  const { nodeId } = useNodeView();
  const { getReadableTimeDifference } = useTime();
  const { RunHistoryPaginationComponent } = useRunHistoryPagination(
    runDetails,
    getRunDetails
  );
  const { isNotDesktop } = useApp();
  const { getParam } = useQueryParams();
  const { isAdmin } = usePermissions();
  const { subscription } = useSubscription();
  const { approvalIcon, formatApproved, getApprovedStatus } = approvalUtils();
  const { runId } = useParams();

  const handleRowClick = (e, run) => {
    const { cellIndex, textContent } = e.target;
    const isDeleteButton = textContent === t("runHistory.deleteRun");
    const isApproveButton = textContent === t("runHistory.approveRun");

    if (
      isApproveButton ||
      isDeleteButton ||
      cellIndex === e.currentTarget.cells.length - 1
    ) {
      return;
    }

    history.push(
      `${routes.RUN_VIEW.path}?page=${getParam(PAGE)}`
        .replace(":nodeId", nodeId)
        .replace(":runType", TEST_CATEGORIES.TEST_EVOLVE_EXPLORATORY)
        .replace(":label", encodeURI(encodeURIComponent(run.label)))
        .replace(":runId", run.id)
    );
  };

  return (
    <>
      <Container>
        <h1>{t("runHistory.title")}</h1>
        <TableContainer isNotDesktop={isNotDesktop}>
          <StyledTable>
            <thead>
              <tr>
                <th key={"th-runId"}>{t("runHistory.runId")}</th>
                <th key={"th-approved"}>{t("runHistory.approved")}</th>
                <th key={"th-status"}>{t("Status")}</th>
                <th key={"bugs"}>{t("Bugs")}</th>
                <th key={"th-notes"}>{t("Notes")}</th>
                <th key={"th-observations"}>{t("Observations")}</th>
                <th key={"th-sessions"}>{t("Sessions")}</th>
                <th key={"th-desc"}>{t("Description")}</th>
                <th key={"th-finishTime"}>{t("runHistory.finishTime")}</th>
                <th key={"th-timeUpdated"}>{t("runHistory.timeUpdated")}</th>
                {isAdmin() ? (
                  <th key={"th-options"}>{t("runHistory.options")}</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {runDetails?.run_history?.map((run, i) => {
                const date = new Date(Date.parse(run.updated_time));

                return (
                  <>
                    <StyledRow
                      key={`row-${run.id}`}
                      onClick={e => handleRowClick(e, run)}
                      isSelected={
                        run.id.toString() === runId.toString() ||
                        (runId === LATEST && i === 0)
                      }
                    >
                      <td>
                        <FlexTd data-test={`${run.id}-run-id`}>
                          {run.id}
                          {run?.releases?.length ? <FreezeIcon /> : null}
                        </FlexTd>
                      </td>
                      <td data-test={`${run.id}-approved`}>
                        {run.approved ? (
                          <ApprovedContainerRunHistory
                            title={formatApproved(run.approved)}
                          >
                            {approvalIcon(run.approved)}
                            {getApprovedStatus(run.approved)}
                          </ApprovedContainerRunHistory>
                        ) : null}
                      </td>
                      <td data-test={`${run.id}-status`}>
                        <StatusSticker
                          w={theme.sizing.exploratoryStatusStickerWidthSmall}
                          status={run.status}
                          isClosed={
                            run.status?.toLowerCase() === RUN_STATUSES.CLOSED
                          }
                        >
                          {t(`releases.${RELEASE_STATUSES[run.status]}`)}
                        </StatusSticker>
                      </td>
                      <td data-test={`${run.id}-bugs`}>{run.total_bugs}</td>
                      <td data-test={`${run.id}-notes`}>{run.total_notes}</td>
                      <td data-test={`${run.id}-obv`}>
                        {run.total_observations}
                      </td>
                      <td data-test={`${run.id}-sessions`}>
                        {run.total_sessions}
                      </td>
                      <td data-test={`${run.id}-desc`}>{run.description}</td>

                      <td data-test={`${run.id}-last-updated`}>
                        {getReadableTimeDifference(
                          run.finish_time ?? run.updated_time
                        )}{" "}
                        {t("time.ago")}
                      </td>
                      <td data-test={`${run.id}-date`}>
                        {date.toLocaleString("en-GB", DATE_OPTIONS)}
                      </td>
                      {isAdmin() ? (
                        <td data-test={`${run.id}-options`}>
                          <RunHistoryContextMenu
                            key={run.id}
                            run={run}
                            hideApproval={
                              run.status.toLowerCase() !== RUN_STATUSES.CLOSED
                            }
                          />
                        </td>
                      ) : null}
                    </StyledRow>
                  </>
                );
              })}
              {runDetails?.display_banner ? (
                <StyledRow>
                  <BannerCell colSpan="100">
                    <FlexContainer>
                      <StyledBanner>
                        {t(
                          NO_UPGRADE_SUBS.includes(subscription.subscription_id)
                            ? "nodeView.upgradeMessage2"
                            : "nodeView.upgradeMessage",
                          {
                            months: subscription.run_retention,
                          }
                        )}
                      </StyledBanner>
                    </FlexContainer>
                  </BannerCell>
                </StyledRow>
              ) : null}
            </tbody>
          </StyledTable>
        </TableContainer>
        <TableFooter>
          <RunHistoryPaginationComponent />
        </TableFooter>
      </Container>
    </>
  );
};
