import styled from "styled-components";

export const Divider = styled.div`
  display: flex;
  line-height: 1em;
  color: ${({ theme }) => theme.colours.mainText};
  width: 100%;
  margin: ${({ theme, margin }) =>
    margin ? `${margin} 0` : `${theme.spacing.padding} 0`};

  &::before,
  &::after {
    content: "";
    display: inline-block;
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colours.mainText};
    height: 1px;
    margin: 0 ${({ theme }) => theme.spacing.padding};
    margin-top: 0.5em;
    width: 100%;
  }
`;
