import { ACTIONS } from "../../static/constants/actions";

export const subscriptionReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_SUBSCRIPTION:
      return { ...state, subscription: payload };
    default:
      return state;
  }
};
