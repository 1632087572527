import { useEffect, useState } from "react";
import { useFetch } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useNotifications } from "../../../context/hooks/useNotifications/useNotifications";
import { useProject } from "../../../context/project/project.provider";
import { useModal } from "../../../context/modal/modal.provider";

export const useDeleteProject = () => {
  const { deleteApi, status, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const [projectToDelete, setProjectToDelete] = useState(null);
  const { removeProject } = useProject();
  const { closeModal, openModal, MODALS } = useModal();

  useEffect(() => {
    if (projectToDelete && status.isResolved) {
      createNotification(types.SUCCESS, "success.deleteProject");
      removeProject(projectToDelete);
      closeModal();
    }
    if (status.isRejected && error)
      createNotification(types.ERROR, `errors.deleteProject.${error.code}`);
  }, [status.isRejected, status.isResolved, error, projectToDelete]);

  const handleDeleteProject = (e, project) => {
    openModal(MODALS.DELETE_PROJECT, { project });
    e.syntheticEvent.stopPropagation();
  };

  const deleteProject = id => {
    setProjectToDelete(id);

    deleteApi({
      ...ENDPOINTS.DELETE_PROJECT,
      path: ENDPOINTS.DELETE_PROJECT.path.replace(":id", id),
    });
  };

  return {
    handleDeleteProject,
    deleteProject,
  };
};
