import { useEffect, useState } from "react";
import { useFetch } from "../hooks";
import { useProject } from "./project.provider";
import { ENDPOINTS } from "../../static/constants/routeConfig";

export const useGetProjects = () => {
  const { get, body, error, status, reset } = useFetch();
  const { projects, setProjects } = useProject();
  const [projectsPending, setProjectsPending] = useState(true);

  const getProjects = () => get({ path: ENDPOINTS.PROJECTS.path });

  useEffect(() => {
    if (status.isResolved && body) {
      setProjects(body);
      reset();
    }
    if (status.isResolved || status.isRejected) setProjectsPending(false);
  }, [body, error, status]);

  return {
    getProjects,
    projects,
    projectsPending,
    projectsSuccess: status.isResolved,
  };
};
