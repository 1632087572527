import Select from "react-select";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  width: ${({ isNotDesktop, theme }) =>
    isNotDesktop ? "100%" : theme.sizing.selectWidth};
  color: ${({ theme }) => theme.colours.mainText};
  height: ${({ theme }) => theme.sizing.btnHeight};

  & .Select__control {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    box-shadow: none;
    color: ${({ theme }) => theme.colours.mainText};

    &:hover {
      border-color: ${({ theme }) => theme.colours.haloAccentLight};
    }
  }

  & .Select__control--is-focused {
    border-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__single-value {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .Select__menu {
    background-color: ${({ theme }) => theme.colours.background};
    color: ${({ theme }) => theme.colours.mainText};
    cursor: pointer;
    border-radius: ${({ theme }) => theme.borders.radius};
    z-index: 999 !important;
  }

  & .Select__multi-value {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value__label {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    color: ${({ theme }) => theme.colours.mainText};
    border-radius: ${({ theme }) => theme.borders.radius};
  }

  & .Select__multi-value--is-disabled {
    padding: 0 ${({ theme }) => theme.spacing.projectPicker};
    padding-left: 0;
  }

  & .Select__multi-value__remove {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
    border-top-right-radius: ${({ theme }) => theme.borders.radius};
    border-bottom-right-radius: ${({ theme }) => theme.borders.radius};
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
    padding-left: 0;
  }

  & .Select__option {
    background-color: ${({ theme }) => theme.colours.background};
    cursor: pointer;
  }

  & .Select__option--is-focused {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__option--is-selected {
    background-color: ${({ theme }) => theme.colours.haloAccentLight};
  }

  & .Select__indicators {
    display: ${({ isDisabled }) => (isDisabled ? "none" : "flex")};
  }

  & .Select__placeholder {
    color: ${({ theme }) => theme.colours.secondaryText};
  }

  & .Select__input {
    color: ${({ theme }) => theme.colours.mainText};
  }

  & .Select__input-container {
    color: ${({ theme }) => theme.colours.mainText};
  }
`;
