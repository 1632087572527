import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../..";
import { useDeleteCustomer } from "../../../../views/organisationSettings/tabs/partnerCustomers/hooks/deleteCustomer";
import {
  ContainerMsg,
  OrganisationDetailsContainer,
  OrganisationDetailsRow,
} from "../../../../views/organisationSettings/tabs/partnerCustomers/modals/modal.styles";

export const DeleteCustomerModal = ({
  closeModal,
  orgId,
  orgName,
  setUpdated,
}) => {
  const { t } = useTranslation();
  const { deleteCustomerOrganisation } = useDeleteCustomer(
    closeModal,
    setUpdated
  );
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setDeleting(false);
  }, [orgId]);

  const confirm = () => {
    setDeleting(true);
    deleteCustomerOrganisation(orgId);
  };

  return (
    <Modal
      title={t("customer.title.deleteOrganisation")}
      size="small"
      secondary={{
        text: t("customer.button.cancel"),
        onClick: closeModal,
      }}
      primary={{
        text: deleting
          ? t("customer.button.deleting")
          : t("customer.button.delete"),
        onClick: confirm,
        props: {
          danger: true,
          disabled: deleting,
        },
      }}
    >
      <OrganisationDetailsContainer>
        <OrganisationDetailsRow>
          <div>{t("customer.label.organisationId")}</div>
          <div>{orgId}</div>
        </OrganisationDetailsRow>
        <OrganisationDetailsRow>
          <div>{t("customer.label.organisationName")}</div>
          <div>{orgName}</div>
        </OrganisationDetailsRow>
      </OrganisationDetailsContainer>
      <ContainerMsg>
        <p data-testid={"confirm-message"}>
          {t("customer.delete.text.confirm")}
        </p>
      </ContainerMsg>
    </Modal>
  );
};
