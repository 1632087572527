import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { usePermissions } from "../../../../context/hooks";
import { useRemoveUser } from "./hooks/removeUser";

export const RemoveUserButton = ({ user, getProjectUsers }) => {
  const { t } = useTranslation();
  const { isUserAdmin, isPartner } = usePermissions();
  const { patchProjectRole } = useRemoveUser(getProjectUsers);

  return (
    <Button
      disabled={isUserAdmin(user) || isPartner(user)}
      onClick={() => patchProjectRole(user.id)}
    >
      {t("btnRemove")}
    </Button>
  );
};
