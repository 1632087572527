import styled from "styled-components";
import { PieChart } from "react-minimal-pie-chart";

export const GraphContainer = styled.div`
  height: ${({ theme, large }) =>
    (large && theme.sizing.graphContainerML) || theme.sizing.graphContainerXSM};
  width: ${({ theme, large }) =>
    (large && theme.sizing.graphContainerML) || theme.sizing.graphContainerXSM};
  border-radius: ${({ theme }) => theme.borders.radius};
  position: relative;
  svg {
    top: 0 !important;
    height: 100%;
  }
`;

export const StyledPieChart = styled(PieChart)`
  position: relative;
  height: 100%;
  width: 100%;
  top: ${({ theme }) => theme.spacing.paddingMD};
  display: flex;
  justify-content: center;
`;

export const OuterGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};
  gap: ${({ theme }) => theme.spacing.paddingSM};

  p {
    width: 100%;
    margin-right: 0;
  }
`;

export const ValueContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  p {
    text-align: center;
  }
`;

export const ValueText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.larger};
  line-height: 2rem;
`;

export const ValueLabel = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.medium};
  white-space: nowrap;
  line-height: ${({ theme }) => theme.fontSize.largeMiddler};
`;

export const DataContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  justify-content: center;
  align-items: center;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.paddingSM};
  align-items: center;
  width: ${({ theme }) => theme.sizing.summaryWidth};

  h1 {
    font-size: ${({ theme }) => theme.fontSize.larger};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  h2 {
    font-size: ${({ theme }) => theme.fontSize.smallerMedium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-align: center;
  }
`;

export const LevelContainer = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.paddingXSM};
  h1 {
    font-size: ${({ theme }) => theme.fontSize.larger};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  h2 {
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-align: center;
  }
`;
