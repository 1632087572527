import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  flex-grow: 1;
  height: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};

  width: 100%;
  max-width: 100%;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  border: none;
  outline: none;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-between;

  background-color: ${({ theme }) =>
    theme.isDark ? theme.colours.background : theme.colours.foreground};
  border: 4px solid ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  padding: ${({ theme }) => theme.spacing.padding};
  height: ${({ theme }) => theme.sizing.homeSectionHeight};
`;

export const SectionTitle = styled.h2`
  text-wrap: nowrap;
`;

export const SectionContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};

  width: 100%:
  max-width: 100%;
  overflow-y: hidden;
  padding: ${({ theme }) => theme.spacing.paddingSM} 0;
  overflow-x: auto;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const SubscriptionDetailsContainer = styled.div`
  width: fit-content;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.padding};

  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  justify-content: space-between;
  align-items: center;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const SubscriptionType = styled.div``;

export const GaugeContainer = styled.div`
  width: ${({ theme }) => theme.sizing.graphLG};
  height: ${({ theme }) => theme.sizing.graphLG};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.padding}
    ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding-bottom: 0;
`;

export const Value = styled.p`
  text-align: center;
  position: relative;
  top: -35%;
  padding: ${({ theme }) => theme.spacing.paddingXSM};
  font-size: ${({ theme }) => theme.fontSize.larger};
  line-height: ${({ theme }) => theme.spacing.paddingLG};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Label = styled.p`
  position: relative;
  top: -20%;
`;

export const EmptyContentContainer = styled.div`
  height: ${({ theme }) => theme.sizing.minimalRunCardHeight};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.paddingSM};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  img {
    opacity: 0.6;
    height: 70%;
  }
`;

export const SectionDetailsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  flex-direction: column;
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  p {
    font-size: ${({ theme }) => theme.fontSize.medium};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colours.haloAccent};
    }
  }
`;
