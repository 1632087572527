import { useTranslation } from "react-i18next";
import { Input } from "../../../Input/Input";
import { Modal } from "../../modal";
import { useState } from "react";
import { ErrorMessage } from "../../../../views/projectView/hooks/styles";

export const CreateProjectModal = ({ closeModal, createProject }) => {
  const { t } = useTranslation();
  const [projectName, setProjectName] = useState("");
  const [formError, setFormError] = useState(false);

  const handleClick = () => {
    if (projectName.length < 1 || projectName.length > 50) setFormError(true);
    else createProject(projectName);
  };

  return (
    <Modal
      title={t("projectView.createProject.title")}
      text={t("projectView.createProject.supportingText")}
      primary={{
        text: t("btnCreate"),
        onClick: handleClick,
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <Input
        autoFocus={true}
        onChange={e => setProjectName(e.target.value)}
        value={projectName}
      />
      {formError ? (
        <ErrorMessage>
          {t("projectView.createProject.validationError")}
        </ErrorMessage>
      ) : (
        <p> </p>
      )}
    </Modal>
  );
};
