import { useEffect, useState } from "react";
import { useFetch } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { CATEGORY_PROVIDER_MAP } from "../../../static/constants/constants";
import { useParams } from "react-router-dom";

export const useRunData = () => {
  const { get, body, status, error } = useFetch();
  const [runData, setRunData] = useState(null);
  const { runType } = useParams();

  const getRunData = runId =>
    get({
      ...ENDPOINTS.RUN,
      path: ENDPOINTS.RUN.path
        .replace(":id", runId)
        .replace(":provider", CATEGORY_PROVIDER_MAP[runType]),
    });

  useEffect(() => {
    if (status.isResolved && body) {
      const totalScenarios = body.features.reduce(
        (accumulator, feature) => accumulator + feature.scenarios.length,
        0
      );
      setRunData({ ...body, totalScenarios });
    }
  }, [status.isResolved, status.isRejected, error, body]);

  return {
    runData,
    getRunData,
  };
};
