import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "../../../datePicker/datePicker";
import { ErrorMessage } from "../../../../views/projectView/hooks/styles";
import { Modal } from "../../modal";
import { useRunExport } from "../../../../views/runView/hooks/runExport/useRunExport";
import { SupportText } from "./runExportModal.styles";

export const RunExportModal = ({ run, closeModal }) => {
  const [noRuns, setNoRuns] = useState(false);
  const { t } = useTranslation();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const { getRunExport } = useRunExport();

  const resetForm = () => setNoRuns(false);

  return (
    <Modal
      title={t("runView.export.modalTitle")}
      text={t("runView.export.modalText")}
      primary={{
        text: t("btnDownload"),
        onClick: () =>
          getRunExport(startDate, endDate, run.category, run.label),
        disabled: !(startDate && endDate),
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <SupportText>{t("runView.export.modalText2")}</SupportText>
      <div>
        <label>{t("runView.export.startDate")}</label>
        <DatePicker
          name="start_date"
          placeholder={t("placeholder.reportStartDate")}
          onChange={setStartDate}
          onSelect={resetForm}
          value={startDate}
          maxDate={endDate}
          past={endDate ? false : true}
          present={true}
        />
      </div>
      <div>
        <label>{t("runView.export.endDate")}</label>
        <DatePicker
          name="end_date"
          placeholder={t("placeholder.reportStartDate")}
          onChange={setEndDate}
          onSelect={resetForm}
          value={endDate}
          minDate={startDate}
          past={true}
          present={true}
        />
      </div>
      {noRuns ? <ErrorMessage>{t("runView.export.404")}</ErrorMessage> : null}
    </Modal>
  );
};
