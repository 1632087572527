import { usePermissions } from "../../../../context/hooks";
import { RunStatusContextMenu } from "../../runDetails/runStatusContextMenu";
import {
  ButtonContainer,
  DetailKey,
  DetailValue,
  RunDetail,
  RunDetailsContainer,
} from "../../testevolveExploratory/testevolveExploratoryRunView.styles";
import { StatusStickerContainer } from "../../../nodeView/nodeCardContainer/nodeCardTestEvolveManual/nodeCardTestEvolveManual.styles";
import {
  CLICK,
  EXPLORATORY_PROPS,
} from "../../../../static/constants/constants";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components";
import { mediumDateTime, timeAgo } from "../../../../utils/dates/dateUtils";

export const AccessibilityRunDetails = ({ run, getRun, editField }) => {
  const barRef = useRef(null);
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();
  const [copied, setCopied] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [barHeight, setBarHeight] = useState(null);
  const [editValue, setEditValue] = useState(false);

  useEffect(() => {
    if (!editMode && barRef?.current)
      setBarHeight(barRef.current.getBoundingClientRect().height);
  }, [editMode, barRef]);

  const handleFieldChange = (key, e) => {
    if (e.key === "Enter") setEditMode(false);
    setEditValue(v => ({ ...v, [key.toLowerCase()]: e.target.value }));
  };

  useEffect(() => {
    setEditValue(false);
  }, [editMode]);

  useEffect(() => {
    setEditMode(false);
  }, [run]);

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 3000);
  }, [copied]);

  const handleClick = e => {
    if (!e.target.id.includes("target")) setEditMode(false);
  };

  useEffect(() => {
    document.addEventListener(CLICK, handleClick, true);

    return () => {
      document.removeEventListener(CLICK, handleClick);
    };
  }, []);

  const handleSaveButton = () => {
    editField(editValue);
    setEditMode(false);
  };

  return (
    <RunDetailsContainer ref={barRef} h={editMode ? barHeight : null}>
      <StatusStickerContainer>
        <RunStatusContextMenu
          admin={isAdmin()}
          run={run}
          editField={editField}
          getRun={getRun}
          disabled={run.releases?.length}
        />
      </StatusStickerContainer>
      <RunDetail>
        <DetailKey>
          {t("runView.testevolveaccessibility.details.passing")}
        </DetailKey>
        <DetailValue>
          {Math.round((run.criteria.passed / run.criteria.total) * 100)}%
        </DetailValue>
      </RunDetail>
      <RunDetail>
        <DetailKey>
          {t("runView.testevolveaccessibility.details.failed")}
        </DetailKey>
        <DetailValue>{run.criteria.failed}</DetailValue>
      </RunDetail>
      <RunDetail>
        <DetailKey>
          {t("runView.testevolveaccessibility.details.standard")}
        </DetailKey>
        <DetailValue>
          {t("runView.testevolveaccessibility.details.wcag")} {run.standard}
        </DetailValue>
      </RunDetail>
      <RunDetail>
        <DetailKey>
          {t("runView.testevolveaccessibility.details.level")}
        </DetailKey>
        <DetailValue>{run.level}</DetailValue>
      </RunDetail>
      <RunDetail
        desc
        onClick={() => setEditMode(EXPLORATORY_PROPS.DESCRIPTION)}
        editMode={editMode === EXPLORATORY_PROPS.DESCRIPTION}
        editHeight
      >
        <DetailKey>
          {t("runView.testevolveexploratory.runDetails.description")}
        </DetailKey>
        {editMode === EXPLORATORY_PROPS.DESCRIPTION ? (
          <>
            <textarea
              id="text-area-target"
              type="text"
              autoFocus
              onFocus={e => e.target.select()}
              onKeyUp={e => handleFieldChange(EXPLORATORY_PROPS.DESCRIPTION, e)}
              defaultValue={run.description}
            />
            <ButtonContainer>
              <Button secondary onClick={null}>
                {t("btnCancel")}
              </Button>
              <Button buttonId="save-target" onClick={handleSaveButton}>
                {t("btnSave")}
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <DetailValue desc>{run.description}</DetailValue>
        )}
      </RunDetail>
      <RunDetail>
        <DetailKey>
          {t("runView.testevolveexploratory.runDetails.started")}
        </DetailKey>
        <DetailValue>{mediumDateTime(run.start_time)}</DetailValue>
      </RunDetail>
      <RunDetail>
        <DetailKey>
          {t("runView.testevolveexploratory.runDetails.lastUpdated")}
        </DetailKey>
        <DetailValue>{timeAgo(run.updated_time)}</DetailValue>
      </RunDetail>
    </RunDetailsContainer>
  );
};
