import { useHistory } from "react-router-dom";
import { Container } from "./sharedRunModal.styles";
import { Divider, Modal, Button } from "../../..";
import { routes } from "../../../../router/routes";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../../../../context/hooks";

export const SharedRunModal = ({ closeModal, run }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { getParam } = useQueryParams();
  const project = getParam("project");

  const redirectUrl = `${routes.RUN_VIEW.path}?project=${project}`
    .replace(":nodeId", "null")
    .replace(":runType", run?.category)
    .replace(":label", encodeURI(encodeURIComponent(run?.label)))
    .replace(":runId", run?.id);

  return (
    <Modal
      secondary={{
        onClick: closeModal,
        text: t("btnCancel"),
      }}
      text={t("sharedRunView.haveAnAccount")}
      title={t("sharedRunView.notSignedIn")}
    >
      <Container>
        <Button onClick={() => history.push(redirectUrl)}>
          {t("sharedRunView.toSignIn")}
        </Button>
        <Divider>{t("signin.or")}</Divider>
        <p>{t("sharedRunView.requestAccess")}</p>
      </Container>
    </Modal>
  );
};
