import { useTranslation } from "react-i18next";
import { Modal } from "../../..";
import { countryData } from "../../../../static/constants/countryCodes";
import { StyledSelect } from "../../../../views/organisationSettings/tabs/billing/billingTab.styles";
import { useState } from "react";
import { useCountry } from "../../../../views/organisationSettings/tabs/billing/paymentDetails/hooks/useCountry";

export const SelectCountryModal = ({ closeModal }) => {
  const { t } = useTranslation();
  const { saveCountry } = useCountry();
  const [selectedValue, setSelectedValue] = useState(null);

  const buttonHandler = () => saveCountry(selectedValue);

  return (
    <Modal
      title={t("subscriptionCreate.selectCountryTitle")}
      primary={{
        text: t("btnConfirm"),
        onClick: buttonHandler,
        disabled: !selectedValue,
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <StyledSelect
        options={countryData}
        value={selectedValue}
        classNamePrefix={"Select"}
        onChange={e => setSelectedValue(e)}
      />
    </Modal>
  );
};
