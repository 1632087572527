import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useRunSharingToken } from "./useRunSharingToken";
import { CATEGORY_PROVIDER_MAP } from "../../../../static/constants/constants";

export const useDisableRunSharing = () => {
  const { post, body, status } = useFetch();
  const { clearRunSharingToken } = useRunSharingToken();
  const { createNotification, types } = useNotifications();

  const disableRunSharing = (category, token) =>
    post({
      ...ENDPOINTS.DISABLE_SHARING,
      path: ENDPOINTS.DISABLE_SHARING.path
        .replace(":runSharingToken", token)
        .replace(":provider", CATEGORY_PROVIDER_MAP[category]),
    });

  useEffect(() => {
    if (status.isResolved) {
      clearRunSharingToken(null);
      createNotification(types.SUCCESS, "messaging.unsharedTestRunDashboard");
    }
  }, [status.isResolved, body]);

  return {
    disableRunSharing,
  };
};
