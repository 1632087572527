import { useTranslation } from "react-i18next";
import { TestEvolveLogo } from "../TestEvolveLogo/TestEvolveLogo";
import {
  Container,
  ErrorSupport,
  ErrorTitle,
  LogoContainer,
} from "./errorPage.styles";

export const ErrorPage = ({ code }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ErrorTitle>{code}</ErrorTitle>
      <ErrorSupport>{t(`errorPage.support.${code}`)}</ErrorSupport>
      <LogoContainer>
        <TestEvolveLogo />
      </LogoContainer>
    </Container>
  );
};
