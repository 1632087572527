import { useEffect } from "react";
import { Loading } from "../../components";
import { useParams } from "react-router-dom";
import { usePolling } from "../../context/hooks/poll/usePolling";
import {
  POLLING_INTERVAL,
  TEST_CATEGORIES,
} from "../../static/constants/constants";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useQueryParams } from "../../context/hooks";
import { TestEvolveRunView } from "../runView/testEvolve/testEvolveRunView";
import { TestEvolveLighthouseRunView } from "../runView/testEvolveLighthouse/testEvolveLighthouseRunView";
import { TestEvolveVisualRunView } from "../runView/testEvolveVisual/testEvolveVisualRunView";
import { TestEvolveAxeRunView } from "../runView/testEvolveAxe/testEvolveAxeRunView";
import { NPMAuditRunView } from "../runView/npmAudit/npmAuditRunView";
import { useSharedRunview } from "./hooks/useSharedRunview";
import {
  Container,
  Page,
  PageContent,
  WhiteContainer,
} from "./sharedRunView.styles";
import { Header } from "./sharedHeader";

const PAGE = "page";

export const SharedRunView = () => {
  const { runType, runId } = useParams();
  const { unshared, run, getRun } = useSharedRunview();

  const { getParam } = useQueryParams();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    getParam(PAGE);
  }, [location.pathname, location.search]);

  useEffect(() => {
    getRun();
  }, [runId]);

  usePolling(() => {
    if (!unshared) getRun();
  }, POLLING_INTERVAL);

  const renderRunView = () => {
    switch (runType) {
      case TEST_CATEGORIES.TEST_EVOLVE_FUNCTIONAL:
        return (
          <TestEvolveRunView
            run={run.latest_run}
            runDetails={run}
            isShared={true}
          />
        );
      case TEST_CATEGORIES.NPM_AUDIT:
        return (
          <NPMAuditRunView
            run={run.latest_run}
            runDetails={run}
            isShared={true}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_AUDIT_AXE:
        return (
          <TestEvolveAxeRunView
            run={run.latest_run}
            runDetails={run}
            isShared={true}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_VISUAL:
        return (
          <TestEvolveVisualRunView
            run={run.latest_run}
            runDetails={run}
            isShared={true}
          />
        );
      case TEST_CATEGORIES.TEST_EVOLVE_AUDIT_LIGHTHOUSE:
        return (
          <TestEvolveLighthouseRunView
            run={run.latest_run}
            runDetails={run}
            isShared={true}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Page id="full-screen-container">
      <Header />
      <PageContent>
        {run && run?.latest_run ? (
          renderRunView()
        ) : unshared ? (
          <Container>
            <WhiteContainer>
              <p>{t("messaging.unsharedTestRunDashboard")}</p>
            </WhiteContainer>
          </Container>
        ) : (
          <Loading />
        )}
      </PageContent>
    </Page>
  );
};
