import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch, useFormValidation } from "../../../context/hooks";
import { useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

export const useChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { validateForm, validation, isValid, isInvalid, resetValidation } =
    useFormValidation();

  const { t } = useTranslation();
  const { put, error, status } = useFetch();
  const { createNotification, types } = useNotifications();

  const clearFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const buttonHandler = () => {
    validateForm([
      {
        name: "passwordLength",
        value: newPassword,
        validate: ["length"],
        params: { min: 12 },
      },
      {
        name: "confirmPassword",
        value: confirmPassword,
        validate: ["length"],
        params: { min: 12 },
      },
      {
        name: "inequality",
        value: [newPassword, confirmPassword],
        validate: ["equal"],
      },
    ]);
  };

  useEffect(() => {
    if (isInvalid) clearFields();
    if (isValid) changePassword();
  }, [isInvalid, isValid, validation]);

  const changePassword = () =>
    put(ENDPOINTS.PASSWORD, {
      current_password: currentPassword,
      password: newPassword,
    });

  useEffect(() => {
    if (status.isResolved)
      createNotification(types.SUCCESS, t("success.changePassword"));
    if (status.isRejected && error)
      createNotification(
        types.ERROR,
        t(`errors.changePassword.${error.code}.${error.text}`)
      );
    clearFields();
  }, [error, status.isRejected, status.isResolved]);

  return {
    setCurrentPassword,
    changePassword,
    newPassword,
    buttonHandler,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    validationError: isInvalid,
    validation,
    resetValidation,
    currentPassword,
  };
};
