import styled from "styled-components";

export const PriorityAndImpactContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: ${({ theme }) => (theme.isDark ? "1px" : "2px")} solid
    ${({ theme }) =>
      theme.isDark ? theme.colours.white : theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};

  width: 100%;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
`;
