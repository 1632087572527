import styled, { keyframes } from "styled-components";

export const ProgressBar = styled.div`
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  height: ${({ theme }) => theme.sizing.minimalGraphHeight};
  width: ${({ isCompleted }) => (isCompleted ? "100%" : 0)};
  background-color: ${({ isCompleted, theme }) =>
    isCompleted ? theme.colours.success : ""};
  -webkit-transition: width 0.5s ease-out;
  -moz-transition: width 0.5s ease-out;
  -o-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
`;

export const GettingStartedContainer = styled.div`
  height: ${({ theme }) =>
    theme.isMobile ? "" : theme.sizing.gettingStartedHeight};

  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-between;

  background-color: ${({ theme }) =>
    theme.isDark ? theme.colours.background : theme.colours.foreground};
  border: 4px solid ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const ProgressBarContainer = styled.div`
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  height: ${({ theme }) => theme.sizing.minimalGraphHeight};
  width: 100%;
  background-color: ${({ theme }) => theme.colours.background};
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: ${({ theme }) => theme.spacing.paddingSM} 0;
  overflow-x: auto;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border 5px solid ${({ theme, isCompleted }) =>
    isCompleted ? theme.colours.success : theme.colours.bulletColour};
  color: ${({ theme, isCompleted }) =>
    isCompleted ? theme.colours.success : theme.colours.bulletColour};
  border-radius: 100%;
  width: ${({ theme }) => theme.sizing.circleSize};
  height: ${({ theme }) => theme.sizing.circleSize};

  font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  font-size: ${({ theme }) => theme.fontSize.largest};
`;

const drawAround = keyframes`
  0% {
    stroke-dashoffset: 640;
  }
  10% {
    stroke-dashoffset: 640;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

export const Checkmark = styled.div`
  width: ${({ theme }) => theme.sizing.checkMarkSize};
  height: ${({ theme }) => theme.sizing.checkMarkSize};

  svg {
    stroke-dasharray: 640;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-animation: ${drawAround} 0.5s linear;
    -ms-animation: ${drawAround} 0.5s linear;
    animation: ${drawAround} 0.5s linear;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Cell = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  max-width: ${({ theme }) => (!theme.isMobile ? "calc(100%/3)" : "100%")};
  width: ${({ theme }) => (!theme.isMobile ? "calc(100%/3)" : "100%")};
  min-width: ${({ theme }) => (!theme.isMobile ? "calc(100%/3)" : "100%")};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  height: 100%;

  p {
    text-align: center;
    padding: 0 ${({ theme }) => theme.spacing.paddingSM};
  }

  a {
    text-align: center;
  }

  h3 {
    text-align: center;
  }
`;
