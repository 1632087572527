import { LighthouseScoreGauge } from "../../../../components/graph/graph";
import { Audit, ScoreKey } from "../accessibilitySection/acessibilitySection";
import {
  Anchor,
  GaugeContainer,
  ScoreSection,
  SectionContainer,
  SmallText,
  Violation,
  ViolationsSection,
} from "../lighthouseSection.styles";

export const BestPracticesSection = ({ data, graphData, scrollRef }) => {
  return (
    <SectionContainer>
      <Anchor ref={scrollRef} />
      <ScoreSection>
        <GaugeContainer>
          <LighthouseScoreGauge
            large={true}
            data={graphData}
            passed={graphData.find(d => d.name === "Best Practices")?.value}
          />
        </GaugeContainer>
        <ScoreKey />
      </ScoreSection>
      <ViolationsSection>
        {data.groups.map(vio => (
          <Violation key={vio}>
            <h3>{vio.title}</h3>
            {vio.audits.map(audit => (
              <Audit key={audit.id} audit={audit} />
            ))}
            <SmallText>{vio.description}</SmallText>
          </Violation>
        ))}
      </ViolationsSection>
    </SectionContainer>
  );
};
