import { useEffect } from "react";
import { useFetch } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useNotifications } from "../../../context/hooks/useNotifications/useNotifications";
import { useNode } from "../../../context/nodes/nodes.provider";
import { useLocation } from "react-router-dom";
import { routes } from "../../../router/routes";
import { useModal } from "../../../context/modal/modal.provider";
import { CATEGORY_PROVIDER_MAP } from "../../../static/constants/constants";

export const useDeleteRunHistory = (nodeId, getLabels) => {
  const { getNodeSummary } = useNode();

  const { deleteApi, error, status } = useFetch();
  const { createNotification, types } = useNotifications();
  const { closeModal } = useModal();

  const { pathname } = useLocation();

  const deleteRuns = run => {
    const endpoint = {
      ...ENDPOINTS.DELETE_RUN,
      path: ENDPOINTS.DELETE_RUN.path
        .replace(":category", run.category)
        .replace(":label", encodeURI(encodeURIComponent(run.label)))
        .replace(":provider", CATEGORY_PROVIDER_MAP[run.category]),
    };
    deleteApi(endpoint);
  };

  useEffect(() => {
    if (status.isResolved) {
      pathname.includes(routes.UNASSIGNED_LABELS.path)
        ? getLabels()
        : getNodeSummary(nodeId);
      createNotification(types.SUCCESS, "success.deleteRun");
      closeModal();
    }
    if (status.isRejected && error) {
      createNotification(types.ERROR, `errors.deleteRun.${error.status}`);
    }
  }, [error, status.isRejected, status.isResolved]);

  return {
    deleteRuns,
  };
};
