import { useTranslation } from "react-i18next";
import { Modal } from "../../..";
import { useDeleteProject } from "../../../../views/projectView/hooks/useDeleteProject";

export const DeleteProjectModal = ({ closeModal, project }) => {
  const { t } = useTranslation();
  const { deleteProject } = useDeleteProject();

  return (
    <Modal
      title={t("deleteProject.title")}
      text={t("deleteProject.supportingText1")}
      primary={{
        text: t("btnDelete"),
        onClick: () => deleteProject(project.id),
        props: { danger: true },
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <p>{project.name}</p>
    </Modal>
  );
};
