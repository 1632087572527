import { useTheme } from "styled-components";
import {
  OpenMenuIcon,
  MenuContainer,
  StyledMenuItem,
  StyledMenu,
} from "./runHistoryContextMenu.styles";
import { useApproveRun } from "../../../views/runView/hooks/approval/useApproveRun";
import { useAddLabelToRelease } from "../../releases/addLabelToRelease/useAddLabelToRelease";
import { useTranslation } from "react-i18next";
import { useDeleteRun } from "../../../views/runView/hooks/useDeleteRun";

export const RunHistoryContextMenu = ({ run, hideApproval }) => {
  const { colours } = useTheme();
  const { t } = useTranslation();

  const { openApproveModal } = useApproveRun();
  const { openDeleteRunModal } = useDeleteRun();
  const { openAddLabelModal } = useAddLabelToRelease();

  return (
    <MenuContainer>
      <StyledMenu
        menuButton={
          <OpenMenuIcon
            fill={colours.mainText}
            onClick={e => e.stopPropagation()}
            data-test={`open-menu-icon-${run.id}`}
          />
        }
        transition
        position="auto"
        align="start"
      >
        {hideApproval ? null : (
          <StyledMenuItem
            onClick={e => openApproveModal(e, run)}
            data-test={`approve-run-${run.id}`}
          >
            {t("runHistory.approveRun")}
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={e => openAddLabelModal(e, run.label)}
          data-test="add-label-release"
        >
          <p>{t("releases.addLabelToRelease.dropdownText")}</p>
        </StyledMenuItem>
        <StyledMenuItem
          error
          onClick={() => openDeleteRunModal(run)}
          data-test={`delete-run-${run.id}`}
        >
          {t("runHistory.deleteRun")}
        </StyledMenuItem>
      </StyledMenu>
    </MenuContainer>
  );
};
