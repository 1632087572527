import { useEffect, useState } from "react";
import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { Input } from "../../../Input/Input";
import { Modal } from "../../modal";
import { useTranslation } from "react-i18next";

export const InsertTableModal = ({ closeModal, activeEditor }) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState("5");
  const [columns, setColumns] = useState("5");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const row = Number(rows);
    const column = Number(columns);
    if (row && row > 0 && row <= 500 && column && column > 0 && column <= 50) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [rows, columns]);

  const onClick = () => {
    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns,
      rows,
    });

    closeModal();
  };

  return (
    <Modal
      title={t("richTextEditor.toolbar.insertTable")}
      toggleModal={() => closeModal()}
      primary={{
        text: t("btnConfirm"),
        onClick: onClick,
        disabled: isDisabled,
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <Input
        placeholder={t("richTextEditor.toolbar.tableRowPlaceholder")}
        label={t("richTextEditor.toolbar.tableRows")}
        onChange={e => setRows(e.target.value)}
        value={rows}
        data-test-id="table-modal-rows"
        type="number"
      />
      <Input
        placeholder={t("richTextEditor.toolbar.tableColumnPlaceholder")}
        label={t("richTextEditor.toolbar.tableColumns")}
        onChange={e => setColumns(e.target.value)}
        value={columns}
        data-test-id="table-modal-columns"
        type="number"
      />
    </Modal>
  );
};
