import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch, useNotifications } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useModal } from "../../../../context/modal/modal.provider";
import { CATEGORY_PROVIDER_MAP } from "../../../../static/constants/constants";

export const useApproveRun = () => {
  const { t } = useTranslation();
  const { patch, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const [checked, setChecked] = useState(true);
  const { openModal, closeModal, MODALS } = useModal();

  useEffect(() => {
    if (status.isResolved) {
      createNotification(
        types.SUCCESS,
        checked ? t("success.approveRun") : t("success.reviewRun")
      );
      closeModal(false);
    }
    if (status.isRejected)
      createNotification(types.ERROR, t(`errors.approveRun.${error.code}`));
  }, [status.isRejected, status.isResolved, body, error]);

  const approveRun = (run, approveChecked, description) => {
    setChecked(approveChecked);

    patch(
      {
        ...ENDPOINTS.APPROVE_RUN,
        path: ENDPOINTS.APPROVE_RUN.path
          .replace(":id", run.id)
          .replace(":provider", CATEGORY_PROVIDER_MAP[run.category]),
      },
      { approve: approveChecked, message: description }
    );
  };

  const openApproveModal = (e, run) => {
    e?.syntheticEvent?.stopPropagation();
    e?.syntheticEvent?.preventDefault();
    openModal(MODALS.RUN_APPROVE, { run, approveRun });
  };

  return {
    approveRun,
    toggleRunApproveModal: openApproveModal,
    openApproveModal,
  };
};
