import React from "react";
import { Container } from "./nodeView.styles";
import { NodeDetails } from "./nodeDetails/nodeDetails";
import { NodeCardContainer } from "./nodeCardContainer/nodeCardContainer";
import { NodeProvider, useNode } from "../../context/nodes/nodes.provider";
import { useParams } from "react-router";
import { NodeLabels } from "./nodeLabels/nodeLabels";
import { usePolling } from "../../context/hooks/poll/usePolling";
import { POLLING_INTERVAL } from "../../static/constants/constants";
import { Redirect } from "react-router-dom";
import { useTree } from "../../context/tree/tree.provider";
import { routes } from "../../router/routes";
import { Loading } from "../../components";

export const NodeViewContent = () => {
  const { tree } = useTree();
  const { nodeId } = useParams();
  const { nodeSummary, getNodeSummary, nodeSummaryErr } = useNode();

  usePolling(() => {
    getNodeSummary(nodeId);
  }, POLLING_INTERVAL);

  return !nodeSummaryErr ? (
    nodeSummary && nodeSummary.id ? (
      <Container>
        <NodeDetails />
        <NodeLabels />
        <NodeCardContainer />
      </Container>
    ) : (
      <Loading />
    )
  ) : (
    <Redirect to={routes.NODE_VIEW.path.replace(":nodeId", tree[0].id)} />
  );
};

export const NodeView = () => (
  <NodeProvider>
    <NodeViewContent />
  </NodeProvider>
);
