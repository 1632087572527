import styled, { css } from "styled-components";

export const BugComponent = styled.div`
  display: flex;
  justify-content: ${({ large }) => (large ? "left" : "center")};
  align-items: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};

  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.padding};
  background-color: ${({ theme, large }) =>
    large ? theme.colours.foreground : theme.colours.background};
  width: ${({ large }) => (large ? "100%" : "fit-content")};
  border-radius: ${({ theme }) => theme.spacing.paddingSM};
  border: 1px solid ${({ theme }) => theme.colours.highlight};
  color: ${({ theme }) => theme.colours.mainText};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colours.haloAccent};
  }

  svg {
    width: ${({ theme }) => theme.sizing.iconSmall};
    min-width: ${({ theme }) => theme.sizing.iconSmall};
    height: auto;
  }
  text-decoration: none;

  ${({ large, theme }) =>
    large &&
    css`
      box-shadow: ${theme.decoration.cardShadow};
    `}
`;

export const DisplayName = styled.p`
  text-decoration: none;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
`;

export const BugContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: ${({ theme }) => theme.spacing.padding};
  padding: ${({ theme }) => theme.spacing.paddingSM};
  margin-bottom: ${({ theme }) => theme.spacing.paddingXSM};
`;

export const StatusSticker = styled.div`
  width: fit-content;
  min-width: fit-content;

  background-color: ${({ theme, status }) =>
    theme.colours.jira[status].background};
  color: ${({ theme, status }) => theme.colours.jira[status].text};
  padding: ${({ theme }) => theme.spacing.paddingXSM}
    ${({ theme }) => theme.spacing.paddingSM};
  border-radius: ${({ theme }) => theme.spacing.paddingXSM};
  font-weight: ${({ theme }) => theme.fontWeight.mediumBold};
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const IssueKey = styled.div`
  width: fit-content;
  min-width: fit-content;
`;
