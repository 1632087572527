import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import { CustomLink } from "../../../components/Link/StyledLink";
import {
  APP_DOCUMENTATION_LINK,
  HALO_CLI_DOWNLOADS,
  PLATFORM_LINUX,
  PLATFORM_MAC,
  PLATFORM_WINDOWS,
} from "../../../static/constants/constants";
import {
  DownloadButton,
  IconContainer,
  OSCard,
  OSCardContainer,
  StyledSecondaryText,
  StyledTitle,
} from "../styles";
import { ReactComponent as AppleLogo } from "../../../static/assets/icon-apple.svg";
import { useApp } from "../../../context/app/app.provider";
import { ReactComponent as WindowsLogo } from "../../../static/assets/icon-windows.svg";
import { ReactComponent as LinuxLogo } from "../../../static/assets/icon-linux.svg";

export const HaloCliSection = ({ version }) => {
  const i18n = useTranslation();
  const { isNotDesktop } = useApp();

  return (
    <>
      <CustomLink accent small={true} to={APP_DOCUMENTATION_LINK}>
        <Button large onClick={() => {}}>
          {i18n.t("downloads.viewDocumentation")}
        </Button>
      </CustomLink>
      <br />
      <StyledTitle>{i18n.t("downloads.chooseOs")}</StyledTitle>
      <OSCardContainer isNotDesktop={isNotDesktop}>
        {HALO_CLI_DOWNLOADS.map(download => (
          <OSCard key={`${download.name}`}>
            <StyledTitle>
              {i18n.t(`downloads.title.${download.name}`)}
            </StyledTitle>
            <StyledSecondaryText>
              {i18n.t(
                download.subtext ? `downloads.subtext.${download.subtext}` : ""
              )}
            </StyledSecondaryText>
            <IconContainer>
              {download.os === PLATFORM_MAC ? <AppleLogo /> : null}
              {download.os === PLATFORM_LINUX ? <LinuxLogo /> : null}
              {download.os === PLATFORM_WINDOWS ? <WindowsLogo /> : null}
            </IconContainer>
            <CustomLink button accent small to={download.link(version)}>
              <DownloadButton>{i18n.t("btnDownload")}</DownloadButton>
            </CustomLink>
          </OSCard>
        ))}
      </OSCardContainer>
    </>
  );
};
