import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useOpenRun = (runId, getRun) => {
  const { patch, status, body } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(
        types.SUCCESS,
        "runView.testevolveexploratory.openRunSuccess"
      );
      getRun();
    }
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.openRunError"
      );
  }, [status.isRejected, status.isResolved, body]);

  const openRun = () =>
    patch({
      ...ENDPOINTS.OPEN_MANUAL_RUN,
      path: ENDPOINTS.OPEN_MANUAL_RUN.path.replace(":runId", runId),
    });

  return {
    openRun,
  };
};
