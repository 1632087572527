import styled, { css } from "styled-components";
import { ReactComponent as Icon } from "../../../static/assets/chevron-down.svg";
import { theme } from "../../../theme/theme";

export const GraphContainer = styled.div`
  display: flex;

  flex-wrap: ${({ theme }) => theme.isMobile && "wrap"};
`;

export const GaugeContainer = styled.div`
  width: ${({ theme }) => theme.sizing.graphLG};
  display: flex;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
  padding-top: ${({ theme }) => theme.spacing.paddingLG};
`;

export const ScoreSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingMD};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const MetricsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const OpportunitiesSection = styled.div``;

export const Metric = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding: ${({ theme }) => theme.spacing.padding};
  flex-grow: 1;
  outline: ${({ theme }) => `1px solid ${theme.colours.highlight}`};

  h3 {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  h2 {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ score }) =>
      (score > 0.89 && theme.gradients.passing_start) ||
      (score > 0.49 && score < 0.9 && theme.gradients.moderate_start) ||
      theme.gradients.error_start};
  }
`;

export const Opportunity = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const OpportunityTable = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const TableColumn = styled.div``;

export const TableRow = styled.div`
  height: ${({ theme }) => theme.sizing.graphContainerXSM};
`;

export const TableHeader = styled.div``;

export const ViolationsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const AuditsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding: ${({ theme }) => theme.spacing.padding};

  background-color: ${({ theme }) => theme.colours.bar};

  &:hover {
    box-shadow: ${({ theme }) => theme.decoration.cardShadowHover};
    background-color: ${({ theme }) => theme.colours.background};
  }
`;

export const SmallText = styled.p`
  color: gray;
  font-size: ${({ theme }) => theme.fontSize.smaller};
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const Violation = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const Arrow = styled(Icon)`
  height: ${({ theme }) => theme.sizing.svgHeightMD};
  width: ${({ theme }) => theme.sizing.svgHeightMD};

  ${({ isOpen }) =>
    isOpen &&
    css`
      -moz-transform: scale(1, -1);
      -webkit-transform: scale(1, -1);
      -o-transform: scale(1, -1);
      -ms-transform: scale(1, -1);
      transform: scale(1, -1);
    `};
`;

export const AuditDropDown = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};
  padding: ${({ theme }) => theme.spacing.padding};
  margin: 0 ${({ theme }) => theme.spacing.padding};

  p {
    font-size: ${({ theme }) => theme.fontSize.meddium};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  table {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colours.highlight};
    border-radius: ${({ theme }) => theme.borders.radius};

    thead {
      background-color: ${({ theme }) => theme.colours.background};
      border-radius: ${({ theme }) => theme.borders.radius};
    }

    th {
      border-radius: ${({ theme }) => theme.borders.radius};
      padding: ${({ theme }) => theme.spacing.paddingSM};
      text-align: left;
    }

    tbody {
      border-radius: ${({ theme }) => theme.borders.radius};
    }

    tr:nth-child(even) {
      border-radius: ${({ theme }) => theme.borders.radius};
      background-color: ${({ theme }) => theme.colours.background};
      td {
        border-radius: ${({ theme }) => theme.borders.radius};
        background-color: ${({ theme }) => theme.colours.background};
      }
    }

    tr {
      text-align: left;
      border-radius: ${({ theme }) => theme.borders.radius};
    }

    td {
      padding: ${({ theme }) => theme.spacing.paddingSM};
      border-radius: ${({ theme }) => theme.borders.radius};
      font-size: ${({ theme }) => theme.fontSize.medium};
      word-break: break-all;
    }
  }
`;

export const StyledCode = styled.code`
  color: ${({ theme, noColour }) =>
    noColour ? null : theme.colours.bulletColour};
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const AuditTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  flex-wrap: wrap;

  code {
    color: ${({ theme }) => theme.colours.bulletColour};
    margin: 0;
    padding: 0;
  }
`;

export const AuditDescription = styled.p`
  line-height: ${({ theme }) => theme.fontSize.larger};
`;

export const SubItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const UrlContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingXSM};
  flex-wrap: wrap;

  p {
    padding: 0;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const MetricsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: ${({ theme }) => theme.sizing.shapeSize} solid transparent;
  border-right: ${({ theme }) => theme.sizing.shapeSize} solid transparent;

  border-bottom: ${({ theme }) => theme.sizing.shapeSizeLG} solid
    ${({ theme }) => theme.gradients.error_start};
`;

export const Square = styled.div`
  width: 0;
  height: 0;
  border: ${({ theme }) => theme.sizing.shapeSize} solid
    ${({ theme }) => theme.gradients.moderate_start};
`;

export const Circle = styled.div`
  width: 0;
  height: 0;
  border: ${({ theme }) => theme.sizing.shapeSize} solid
    ${({ theme }) => theme.gradients.passing_start};
  border-radius: 100%;
`;

export const Ring = styled.div`
  border: ${({ theme }) => theme.sizing.ringDiameter} solid
    ${({ theme }) => theme.colours.mainText};
  border-radius: ${({ theme }) => theme.sizing.ring};
  height: ${({ theme }) => theme.sizing.ring};
  width: ${({ theme }) => theme.sizing.ring};
  opacity: 0.6;
`;

export const DescriptionSection = styled.div`
  display: block;
  max-width: ${({ theme }) => theme.sizing.selectWidth};
  text-align: center;
`;

export const KeyContainer = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  justify-content: center;
  align-items: center;
  p {
    text-align: center;
  }
`;

export const Anchor = styled.div`
  width: 0;
  height: 0;
  position: relative;
  top: -${({ theme }) => theme.sizing.scrollOffset};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    margin-left: ${({ theme }) => theme.sizing.paddingXSM};
  }
`;

export const SubItemContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.paddingSM};
`;
