import styled from "styled-components";

export const ReleaseOption = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.paddingSM}
    ${({ theme }) => theme.spacing.padding};
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colours.haloAccent : theme.colours.foreground};
  color: ${({ theme, selected }) =>
    selected ? theme.colours.white : theme.colours.mainText};
  height: ${({ theme }) => theme.sizing.dropdownRowHeight};
  font-size: ${({ theme }) => theme.spacing.padding};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colours.haloAccent};
    color: ${({ theme }) => theme.colours.white};
  }
`;

export const ReleaseOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  max-height: ${({ theme }) =>
    theme.isNotDesktop ? "100%" : theme.sizing.labelToReleaseContainerHeight};
  overflow-y: auto;

  p {
    width: 100%;
    text-align: center;
  }
`;
