import { UsersList } from "./usersList";
import { AddUserToProject } from "./addUserToProject";
import { TabContainer, Section } from "./userSettings.styles";
import { useProjectRoles } from "./hooks/useProjectRoles";
import { OrganisationUsersProvider } from "../../../../context/organisationUsers/organisationUsers.provider";

export const UserSettings = () => {
  const projectRoles = useProjectRoles();

  return (
    <TabContainer>
      <OrganisationUsersProvider>
        <Section>
          <AddUserToProject useProjectRoles={projectRoles} />
        </Section>
        <Section>
          <UsersList useProjectRoles={projectRoles} />
        </Section>
      </OrganisationUsersProvider>
    </TabContainer>
  );
};
