import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTime } from "../../../../../context/hooks/time/useTime";
import {
  RUN_PROPERTIES,
  RUN_STATUSES,
} from "../../../../../static/constants/constants";

export const useLatestRun = run => {
  const { t } = useTranslation();
  const { getReadableTimeDifference, getTimeBetween } = useTime();
  const [timer, setTimer] = useState(0);

  const setTime = () => {
    const start = DateTime.fromISO(run.start_time);
    let end = DateTime.local();
    let time = getTimeBetween(start, end);
    setTimer(time);
  };

  const totalScenarios = (run.failed + run.pending + run.passed) / 100;

  const data = [
    {
      name: "Passed",
      value: run.passed / totalScenarios,
      color: "url(#gradientPass)",
    },
    {
      name: "Failed",
      value: run.failed / totalScenarios,
      color: "url(#gradientFail)",
    },
    {
      name: "Other",
      value: run.pending + run.undefined + run.skipped / totalScenarios,
      color: "url(#gradientSkipped)",
    },
  ];

  const runStatuses = [
    {
      heading: t("runView.testevolve.passed"),
      value: run.passed,
    },
    {
      heading: t("runView.testevolve.failed"),
      value: run.failed,
    },
    {
      heading: t("runView.testevolve.total_scenarios"),
      value: run.total_scenarios,
    },
    {
      heading: t("runView.testevolve.progress"),
      value: `${run.progress_percentage}%`,
    },
    {
      heading: t("runView.testevolve.timeTaken"),
      value:
        run.status === RUN_STATUSES.IN_PROGRESS
          ? timer
          : getTimeBetween(run.start_time, run.finish_time ?? run.updated_time),
    },
    {
      heading: t("runView.testevolve.avgScenarioTime"),
      value: `${run.average_scenario_time} s`,
    },
  ];

  const status = run.status;

  const runStatus = t(`runCard.testevolve.status.${status}`) || "N/A";

  const endTime =
    status === RUN_STATUSES.COMPLETE ? run.finish_time : run.updated_time;

  const timeAgo =
    `${getReadableTimeDifference(endTime)} ${t("time.ago")}` || "N/A";

  const runProps = RUN_PROPERTIES[run.test_type]?.map(prop => [
    run.run_properties[prop],
    prop,
  ]);

  return {
    run,
    data,
    status,
    setTime,
    timeAgo,
    runProps,
    runStatus,
    runStatuses,
    startTime: run.start_time,
    runProperties: run.run_properties,
    passPercent: run.passed_percentage,
  };
};
