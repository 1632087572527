import { BillingTab } from "./billing/billingTab";
import { OrganisationTab } from "./organisation/organisationTab";
import { AdminLogsContainer } from "./logs/AdminLogsContainer";
import { PartnerCustomersTab } from "./partnerCustomers/partnerCustomersTab";
import { IntegrationsTab } from "./integrations/integrationsTab";

export const tabs = {
  organisation: <OrganisationTab />,
  billing: <BillingTab />,
  logs: <AdminLogsContainer />,
  customers: <PartnerCustomersTab />,
  integrations: <IntegrationsTab />,
};
