import React from "react";
import Line from "./line/Line";

export const Progress = ({
  percent,
  data,
  label,
  percentageLabelValue,
  showPercentageLabel,
  showPercentageLabelLeft,
  labelAlignLeft,
  largeLine,
  largerLine,
  thinLine,
  animate,
  animated,
  rounded,
}) => (
  <Line
    data={data}
    percent={percent}
    label={label}
    percentageLabelValue={percentageLabelValue}
    showPercentageLabel={showPercentageLabel}
    showPercentageLabelLeft={showPercentageLabelLeft}
    thinLine={thinLine}
    largeLine={largeLine}
    largerLine={largerLine}
    labelAlignLeft={labelAlignLeft}
    animate={animate}
    animated={animated}
    rounded={rounded}
  />
);
