import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { CATEGORY_PROVIDER_MAP } from "../../../static/constants/constants";

export const useFreezeRun = () => {
  const { post, status, reset } = useFetch();
  const { createNotification, types } = useNotifications();
  const { t } = useTranslation();
  const [selectedRelease, setSelectedRelease] = useState(null);

  const freezeRun = (run, release) => {
    setSelectedRelease(release);
    post({
      ...ENDPOINTS.FREEZE_RUN,
      path: ENDPOINTS.FREEZE_RUN.path
        .replace(":runId", run.id)
        .replace(":releaseId", release.id)
        .replace(":provider", CATEGORY_PROVIDER_MAP[run.category]),
    });
  };

  useEffect(() => {
    if (status.isRejected)
      createNotification(types.ERROR, "releases.freezeRun.error");

    if (status.isResolved && selectedRelease && selectedRelease?.name)
      createNotification(
        types.SUCCESS,
        t("releases.freezeRun.frozenSuccess", { release: selectedRelease.name })
      );
  }, [status.isRejected, status.isResolved, selectedRelease]);

  return {
    freezeRun,
    runFrozen: status.isResolved,
    freezeRunSuccess: status.isResolved,
    freezeRunReset: reset,
  };
};
