import { Button, Input } from "../../../../components";
import { useTranslation } from "react-i18next";
import { useInviteUser } from "./hooks/inviteUser";
import { ProjectsPicker } from "./projectsPicker";
import { useProject } from "../../../../context/project/project.provider";
import { InputsContainer } from "./usersList.styles";
import {
  StyledInputContainer,
  StyledLabel,
} from "../../../../components/Input/Input";
import { useApp } from "../../../../context/app/app.provider";
import { routes } from "../../../../router/routes";
import { OrgSettingsLink } from "../../../settings/tabs/organisation/addUserToProject.styles";

export const InviteUserSection = () => {
  const { t } = useTranslation();
  const {
    users,
    error,
    formRef,
    maxUsers,
    inputText,
    setInputText,
    buttonHandler,
    selectedProjects,
    setSelectedProjects,
  } = useInviteUser();
  const { projects } = useProject();
  const { isNotDesktop } = useApp();

  const isEmail = email =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  return users !== maxUsers ? (
    <>
      <h2>{t("settings.organisation.newUserTitle")}</h2>
      <InputsContainer isNotDesktop={isNotDesktop}>
        <Input
          type="email"
          ref={formRef}
          value={inputText}
          name="user_email"
          data-test="user-input"
          label={t("signin.email")}
          onChange={e => setInputText(e.target.value)}
          error={error}
        />
        <StyledInputContainer>
          <StyledLabel>{t("settings.organisation.selectProjects")}</StyledLabel>
          <div data-test="select-container">
            <ProjectsPicker
              options={projects.map(prj => ({ label: prj.name, value: prj }))}
              selected={selectedProjects}
              handleSelect={(_, e) => setSelectedProjects(e)}
              disabled={false}
              fullWidth={true}
            />
          </div>
        </StyledInputContainer>
      </InputsContainer>
      <Button
        data-test="add-user-btn"
        onClick={buttonHandler}
        disabled={
          !(selectedProjects.length && inputText.length && isEmail(inputText))
        }
      >
        {t("settings.organisation.addUser")}
      </Button>
      <p>{t("settings.organisation.infoMessage")}</p>
    </>
  ) : (
    <>
      <h2>{t("settings.organisation.maxUsersTitle")}</h2>
      <p>
        {t("settings.organisation.maxUsersText1")}
        <OrgSettingsLink to={routes.ORGANISATION_BILLING.path}>
          {t("settings.organisation.upgrade")}
        </OrgSettingsLink>
        {t("settings.organisation.maxUsersText2")}
      </p>
    </>
  );
};
