import { useEffect, useRef } from "react";
import { useFetch, useNotifications } from "../../../hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useOrganisationUsers } from "../../organisationUsers.provider";

export const useGlobalRoles = () => {
  const functionQueue = useRef({ current: [] });

  const { status, patch, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const { getOrganisationUsers } = useOrganisationUsers();

  const setFunctionQueue = payload => (functionQueue.current = payload);

  const addFunctionToQueue = item => {
    const isEmpty = !functionQueue.current.length;
    setFunctionQueue(isEmpty ? [item] : [...functionQueue.current, item]);
  };

  const executeQueue = () => {
    if (functionQueue.current.length) {
      const [firstQueueItem, ...restOfQueue] = functionQueue.current;
      setFunctionQueue(restOfQueue);
      firstQueueItem();
    }
  };

  const patchGlobalRole = (userId, payload) =>
    patch(
      {
        ...ENDPOINTS.USER_GLOBAL_ROLES,
        path: ENDPOINTS.USER_GLOBAL_ROLES.path.replace(":id", userId),
      },
      payload
    );

  useEffect(() => {
    if (status.isResolved) {
      createNotification(types.SUCCESS, "settings.organisation.successfulEdit");
      executeQueue();
      if (functionQueue.current.length === 0) getOrganisationUsers();
    }
    if (status.isRejected && error) {
      createNotification(
        types.ERROR,
        error?.text === "invalid_number_of_admins"
          ? "settings.organisation.invalid_number_of_admins"
          : error?.text === "invalid_number_of_partner_admins"
          ? "settings.organisation.invalid_number_of_partner_admins"
          : "errors.default"
      );
      executeQueue();
      if (functionQueue.current.length === 0) getOrganisationUsers();
    }
  }, [status.isResolved, status.isRejected, error]);

  return {
    patchGlobalRole,
    addFunctionToQueue,
    executeQueue,
  };
};
