import styled from "styled-components";
import { readableColor } from "polished";
import { ResponsiveContainer } from "recharts";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 50%;
  padding: ${({ theme }) => theme.spacing.paddingSM};
`;

export const GraphOuter = styled.div`
  height: ${({ theme }) => theme.sizing.graphContainerL};
`;

export const GraphContainer = styled(ResponsiveContainer)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  g {
    fill: ${({ theme }) => theme.colours.haloAccent};
  }

  tspan {
    fill: ${({ theme }) => theme.colours.mainText};
  }

  .recharts-label {
    tspan {
      fill: ${({ theme }) => theme.colours.white};
    }
  }
`;

export const StatusTable = styled.table`
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.cardRadius};
  color: ${({ theme }) => readableColor(theme.colours.background)};
  border-spacing: 0;
  font-size: 12px;

  th {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    background-color: ${({ theme }) => theme.colours.background};
  }

  td,
  th {
    text-align: left;
    padding: 0 ${({ theme }) => theme.spacing.paddingSM};
    height: ${({ theme }) => theme.spacing.paddingMD};
  }

  tr {
    border-radius: ${({ theme }) => theme.borders.cardRadius};
    background-color: ${({ theme }) => theme.colours.background};
  }

  tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.colours.foreground};
    color: ${({ theme }) => readableColor(theme.colours.background)};
  }

  tr {
    color: ${({ theme }) => readableColor(theme.colours.background)};
    border-radius: ${({ theme }) => theme.borders.cardRadius};

    th:first-child,
    td:first-child {
      border-bottom-left-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:last-child,
    td:last-child {
      border-bottom-right-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:first-child,
    td:first-child {
      border-top-left-radius: ${({ theme }) => theme.borders.cardRadius};
    }

    th:last-child,
    td:last-child {
      border-top-right-radius: ${({ theme }) => theme.borders.cardRadius};
    }
  }
`;

export const ColourKey = styled.td`
  background-color: ${({ color }) => color};
`;
