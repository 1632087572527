const DEV_AND_TEST_SUFFIX = ".testevolve.net";
const PROD_SUFFIX = ".testevolve.io";

export const useTenant = () => {
  const host = window.location.hostname.replace(/:.*/, "");
  let env = "prod";
  let tenant = null;

  if (host.endsWith(DEV_AND_TEST_SUFFIX)) {
    const vars = host.replace(DEV_AND_TEST_SUFFIX, "").split(".");

    if (vars.length === 2) {
      tenant = vars[0];
      env = vars[1];
    } else {
      env = vars[0];
    }
  } else if (host.endsWith(PROD_SUFFIX)) {
    const vars = host.split(".");
    tenant = vars[0];
  } else {
    env = null;
    tenant = null;
  }

  const setTenant = (tenant, path) => {
    if (tenant)
      window.location.replace(
        `${window.location.protocol}//${tenant}.${window.location.host}/${path}`
      );
  };

  return {
    env,
    tenant,
    setTenant,
  };
};
