import { Stripe, StripeFormContent } from "../../../stripe/Stripe";
import { formatCurrency } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/formatCurrency";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../..";
import { useCreateSubscription } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/hooks/createSubscription";
import { useConfig } from "../../../../context/config/config.provider";
import {
  Details,
  StyledWrapper,
  GreenText,
  ErrorMessage,
  ContainerMsg,
} from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/modals/modals.styles";

export const CheckoutForm = ({
  closeModal,
  productSelected,
  activePlan,
  price,
  isDarkMode,
  country,
  paymentIntent,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const { config } = useConfig();
  const { subscribing, setSubscribing, createSubscriptionAction } =
    useCreateSubscription(stripe, closeModal);

  const [tax, setTax] = useState(0);
  const [email, setEmail] = useState("");
  const [total, setTotal] = useState(price);
  const [cardBrand, setCardBrand] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errorToDisplay, setErrorToDisplay] = useState("");

  useEffect(() => {
    let taxRate = config.taxRates[country.code.toLowerCase()] || 0;
    let tax = (price * taxRate) / 100;
    setTax(tax);
    setTotal(price + tax);
  }, [country.code, config.taxRates, price]);

  useEffect(() => {
    if (paymentIntent?.setup_intent) confirmIntent();
  }, [paymentIntent]);

  const confirmIntent = async () => {
    let result = await stripe.confirmCardSetup(paymentIntent.setup_intent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            postal_code: postalCode,
            country: country.code,
          },
          email: email,
        },
      },
    });

    if (result.error) {
      setSubscribing(false);
      setErrorToDisplay(result.error.message);
    } else {
      createSubscription(result.setupIntent.payment_method, vatNumber);
    }
  };

  const handleSubmit = async () => {
    setErrorToDisplay("");
    setSubscribing(true);

    if (postalCode === "") {
      setSubscribing(false);
      setErrorToDisplay(t("subscriptionCreate.postalCodeRequired"));
      return;
    }
    if (!["visa", "amex", "mastercard"].includes(cardBrand)) {
      setSubscribing(false);
      setErrorToDisplay(t("subscriptionCreate.unsupportedCard"));
      return;
    }
    createPaymentMethod();
  };

  const createPaymentMethod = async () => {
    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        address: {
          postal_code: postalCode,
          country: country.code,
        },
        email: email,
      },
    });

    if (error) {
      setSubscribing(false);
      setErrorToDisplay(error && error.message);
      return;
    }

    createSubscription(paymentMethod.id, vatNumber);
  };

  const createSubscription = paymentMethodId => {
    const payload = {
      payment_method_id: paymentMethodId,
      price_id: productSelected.id,
      vat_number: vatNumber && country.code === "GB" ? vatNumber : null,
    };
    createSubscriptionAction(payload);
  };

  return (
    <Modal
      data-test="modal-create-subscription"
      title={t("createSubscription.modalTitle")}
      size="small"
      secondary={{
        text: t("paymentUpdate.cancel"),
        onClick: closeModal,
      }}
      primary={{
        text: subscribing
          ? t("subscriptionUpdate.subscribing")
          : t("subscriptionUpdate.subscribe"),
        onClick: handleSubmit,
        props: {
          disabled: subscribing || country.code === "",
        },
      }}
    >
      <StyledWrapper>
        <Details data-test="details">
          <div data-test="container-period">
            <b>{t("subscriptionUpdate.Period")}</b>
            <br />
            {activePlan}
          </div>
          <div data-test="container-price">
            <b>{t("subscriptionUpdate.Price")}</b>
            <br />
            {formatCurrency("en", country.currencyIsoCode, price)}
          </div>
          {tax ? (
            <>
              <div data-test="container-tax">
                <b>{t("subscriptionUpdate.Tax")}</b>
                <br />
                {formatCurrency("en", country.currencyIsoCode, tax)}
              </div>
              <div data-test="container-total">
                <b>{t("subscriptionUpdate.Total")}</b>
                <br />
                <GreenText>
                  {formatCurrency("en", country.currencyIsoCode, total)}
                </GreenText>
              </div>
            </>
          ) : (
            ""
          )}
        </Details>
        <StripeFormContent
          handleEmail={e => setEmail(e.target.value)}
          handlePostalCode={e => setPostalCode(e.target.value)}
          handleVatNumber={e => setVatNumber(e.target.value)}
          handleCardNumber={e => setCardBrand(e.brand)}
          isDarkMode={isDarkMode}
          country={country}
          isInitialPayment={true}
        />
        <ErrorMessage data-test="error-message">{errorToDisplay}</ErrorMessage>
        <ContainerMsg>
          <p data-testid={"mandate"}>{t("createSubscription.mandate")}</p>
        </ContainerMsg>
      </StyledWrapper>
    </Modal>
  );
};

const CreateSubscriptionModalUnMemo = props => {
  const { config } = useConfig();

  return (
    <Stripe apiKey={config.stripeApiKey}>
      <CheckoutForm {...props} />
    </Stripe>
  );
};

export const CreateSubscriptionModal = memo(
  CreateSubscriptionModalUnMemo,
  (prev, curr) => {
    return (
      prev.price === curr.price &&
      prev.activePlan === curr.activePlan &&
      prev.currencyIsoCode === curr.currencyIsoCode &&
      prev.productSelected.id === curr.productSelected.id
    );
  }
);
