import { ApiKeyForm } from "./apiKeyForm";
import { useEffect } from "react";
import { ApiKeysList } from "./apiKeysList";
import { Section, TabContainer } from "./apiKeysTab.styles";
import { useApiKeys } from "../../../../context/admin/apiKeys/apiKeys.provider";
import { useModal } from "../../../../context/modal/modal.provider";

export const ApiKeysTab = () => {
  const {
    apiKeys,
    getApiKeys,
    deleteApiKey,
    createApiKey,
    alias,
    setAlias,
    body,
  } = useApiKeys();
  const { openModal, MODALS } = useModal();

  useEffect(() => {
    getApiKeys();
  }, []);

  useEffect(() => {
    if (body && body?.api_key && body?.api_key_identifier)
      openModal(MODALS.API_KEY, {
        apiKey: body.api_key,
        keyId: body.api_key_identifier,
        setAlias,
        getApiKeys,
      });
  }, [body]);

  return (
    <>
      <TabContainer>
        <Section>
          <ApiKeyForm
            alias={alias}
            setAlias={setAlias}
            createApiKey={createApiKey}
          />
        </Section>
        <Section>
          <ApiKeysList keys={apiKeys} deleteKey={deleteApiKey} />
        </Section>
      </TabContainer>
    </>
  );
};
