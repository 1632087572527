import { useTranslation } from "react-i18next";
import {
  DurationGraph,
  TestEvolveLighthouseRunTrendsGraph,
} from "../../../../components/graph/graph";
import { Container, GraphContainer, TrendsTitle } from "./runTrends.styles";
import { useRunDurations } from "../../hooks/useDurations";
import { useState } from "react";
import { DURATION, SCORES } from "../../../../static/constants/constants";
import { RunTrendsHeaderContainer } from "../../testEvolve/runTrends/runTrends.styles";
import { ComparisonSelect } from "../../testEvolve/rightWidget/runInsights/runInsights.styles";

export const RunTrends = ({ runDetails }) => {
  const { t } = useTranslation();
  const { durationsMap } = useRunDurations();
  const [toggleValue, setToggleValue] = useState(SCORES);

  return (
    <Container>
      <RunTrendsHeaderContainer>
        <TrendsTitle>
          {t("runView.testevolvelighthouse.runTrendsTitle")}
        </TrendsTitle>
        <ComparisonSelect
          value={toggleValue}
          onChange={e => setToggleValue(e.target.value)}
          title="select-testevolve-lighthouse-title"
          aria-label="Showing Graph:"
        >
          <option value={SCORES}>{t("runView.runTrends.scores")}</option>
          <option value={DURATION}>{t("runView.runTrends.duration")}</option>
        </ComparisonSelect>
      </RunTrendsHeaderContainer>
      {toggleValue === SCORES ? (
        runDetails?.run_history?.length ? (
          <GraphContainer data-test="te-lighthouse-runtrends-graph">
            <TestEvolveLighthouseRunTrendsGraph
              data={runDetails?.run_history}
            />
          </GraphContainer>
        ) : null
      ) : (
        <DurationGraph data={durationsMap(runDetails?.run_history)} />
      )}
    </Container>
  );
};
