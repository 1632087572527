import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useOrganisationUsers } from "../../../../../context/organisationUsers/organisationUsers.provider";
import { useSubscription } from "../../../../../context/subscription/subscription.provider";
import { useModal } from "../../../../../context/modal/modal.provider";

export const useDeleteUser = () => {
  const { getSubscription } = useSubscription();
  const { deleteApi, status, body } = useFetch();
  const { getOrganisationUsers } = useOrganisationUsers();
  const { createNotification, types } = useNotifications();
  const { closeModal } = useModal();

  useEffect(() => {
    if (status.isResolved) {
      createNotification(
        types.SUCCESS,
        "organisationSettings.deleteUser.success"
      );
      getOrganisationUsers();
      getSubscription();
      closeModal();
    }
    if (status.isRejected)
      createNotification(types.ERROR, "organisationSettings.deleteUser.error");
  }, [status.isRejected, status.isResolved, body]);

  const deleteUser = userId =>
    deleteApi({
      ...ENDPOINTS.USER,
      path: `${ENDPOINTS.USER.path}/${userId}`,
    });

  return {
    deleteUser,
  };
};
