import { useTranslation } from "react-i18next";
import { Modal } from "../../modal";
import { useState } from "react";
import { TextArea } from "../../../textArea/textArea";
import { CloseSessionContainer } from "../../../../views/runView/testevolveExploratory/testevolveExploratoryRunView.styles";
import { useApp } from "../../../../context/app/app.provider";

export const EditSessionSummaryModal = ({
  closeModal,
  session,
  editSummary,
  closeSession,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useApp();
  const [summaryValue, setSummaryValue] = useState(session?.summary);

  return (
    <Modal
      title={t("runView.testevolveexploratory.editSessionModal.title")}
      text={t("runView.testevolveexploratory.editSessionModal.supportText")}
      primary={{
        text: t("btnConfirm"),
        onClick: () => {
          closeSession();
          editSummary({ summary: summaryValue });
        },
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <CloseSessionContainer>
        <p>{t("runView.testevolveexploratory.editSessionModal.list1")}</p>
        <p>{t("runView.testevolveexploratory.editSessionModal.list2")}</p>
        <p>{t("runView.testevolveexploratory.editSessionModal.list3")}</p>
      </CloseSessionContainer>
      <TextArea
        onFocus={e => e.target.select()}
        defaultValue={session.summary}
        label={t(
          "runView.testevolveexploratory.editSessionModal.sessionSummary"
        )}
        setter={setSummaryValue}
        autoFocus
        minWidth={isDesktop ? "30vw" : null}
        minHeight={isDesktop ? "30vh" : null}
      />
    </Modal>
  );
};
