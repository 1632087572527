import { useEffect, useState } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { PNG_HEADERS } from "../../../static/constants/constants";
import { useSubscription } from "../../../context/subscription/subscription.provider";
import {
  ScreenshotContainer,
  ScreenshotTabs,
  ScreenshotsSection,
  UpgradeScreenshotMessage,
} from "../testevolve/styles";
import { Button } from "../../../components";
import { ReactComponent as LockIcon } from "../../../static/assets/icon-lock.svg";
import { useTranslation } from "react-i18next";

export const useScreenshot = () => {
  const { t } = useTranslation();

  const [screenshotImg, setSceenshotImg] = useState(null);
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);

  const { isPaid } = useSubscription();
  const { get, body, status, response } = useFetch();
  const { createNotification, types } = useNotifications();

  const getRandomInt = max => Math.floor(Math.random() * max);

  const showScreenshot = id => {
    if (selectedScreenshot !== id) getScreenshot(id);
    setSelectedScreenshot(oldId => (oldId === id ? null : id));
  };

  const getScreenshot = id =>
    get(
      {
        ...ENDPOINTS.SCREENSHOT,
        path: ENDPOINTS.SCREENSHOT.path.replace(":id", id),
      },
      null,
      PNG_HEADERS
    );

  useEffect(() => {
    if (status.isResolved && body) setSceenshotImg(body);
    if (status.isRejected)
      createNotification(types.ERROR, "runReport.screenshotError");
  }, [body, status.isRejected, status.isResolved]);

  const ScreenshotSection = ({ step }) =>
    isPaid ? (
      step.screenshots && step.screenshots?.length ? (
        <ScreenshotsSection>
          <ScreenshotTabs>
            {step.screenshots?.map(screenshot => (
              <Button
                onClick={() => showScreenshot(screenshot.id)}
                secondary={screenshot.id !== selectedScreenshot}
                key={screenshot.id}
              >
                {screenshot.name}
              </Button>
            ))}
          </ScreenshotTabs>
          {selectedScreenshot ? (
            <ScreenshotContainer>
              <img src={screenshotImg} />
            </ScreenshotContainer>
          ) : null}
        </ScreenshotsSection>
      ) : null
    ) : (
      <UpgradeScreenshotMessage>
        <LockIcon />
        {t(`runReport.upgradeForScreenshot.${getRandomInt(3)}`)}
      </UpgradeScreenshotMessage>
    );

  return {
    status,
    response,
    screenshotImg,
    getScreenshot,
    ScreenshotSection,
  };
};
