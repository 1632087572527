import React, { useEffect, useState } from "react";
import {
  ContainerMsg,
  OrganisationDetailsContainer,
  OrganisationDetailsRow,
  PurpleSpan,
} from "../../../../views/organisationSettings/tabs/partnerCustomers/modals/modal.styles";
import { formatCurrency } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/formatCurrency";
import { Modal } from "../../..";
import { useTranslation } from "react-i18next";
import { usePreviewUpdateCustomer } from "../../../../views/organisationSettings/tabs/partnerCustomers/hooks/previewUpdateCustomer";
import { useUpdateCustomer } from "../../../../views/organisationSettings/tabs/partnerCustomers/hooks/updateCustomer";
import { Stripe } from "../../../stripe/Stripe";

export const UnWrappedPreviewUpdateCustomerModal = ({
  closeModal,
  payload,
  existingProductLabel,
  updatedProductLabel,
  orgName,
  setUpdated,
}) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const { getPreview, preview } = usePreviewUpdateCustomer();
  const { updateCustomer } = useUpdateCustomer(closeModal, setUpdated);

  useEffect(() => {
    getPreview(payload);
  }, []);

  useEffect(() => {
    setUpdating(false);
  }, [payload]);

  const update = () => {
    setUpdating(true);
    updateCustomer(payload);
  };

  let proratedCost = t("loading");
  if (preview?.prorated_invoice) {
    proratedCost = formatCurrency(
      "en",
      preview.prorated_invoice.currency,
      preview.prorated_invoice.total
    );
    if (preview.prorated_invoice.total > preview.prorated_invoice.sub_total) {
      proratedCost = `${proratedCost} ${t("includingVat")}`;
    }
  }
  let ongoingCost = t("loading");
  if (preview?.next_invoice) {
    ongoingCost = formatCurrency(
      "en",
      preview.next_invoice.currency,
      preview.next_invoice.total
    );
    if (preview.next_invoice.total > preview.next_invoice.sub_total) {
      ongoingCost = `${ongoingCost} ${t("includingVat")}`;
    }
  }

  return (
    <Modal
      title={t("customer.title.updateOrganisation")}
      toggleModal={() => closeModal()}
      size="small"
      secondary={{
        text: t("customer.button.cancel"),
        onClick: () => closeModal(),
      }}
      primary={{
        text: updating
          ? t("customer.button.saving")
          : t("customer.button.save"),
        onClick: () => update(),
        props: {
          disabled: updating || preview?.next_invoice === undefined,
        },
      }}
    >
      <OrganisationDetailsContainer>
        <OrganisationDetailsRow>
          <div>{t("customer.label.organisationId")}</div>
          <div>{payload.org_id}</div>
        </OrganisationDetailsRow>
        <OrganisationDetailsRow>
          <div>{t("customer.label.organisationName")}</div>
          <div>{orgName}</div>
        </OrganisationDetailsRow>
        <OrganisationDetailsRow>
          <div>{t("customer.label.existingSeats")}</div>
          <div>{existingProductLabel}</div>
        </OrganisationDetailsRow>
        <OrganisationDetailsRow>
          <div>{t("customer.label.updatedSeats")}</div>
          <div>{updatedProductLabel}</div>
        </OrganisationDetailsRow>
      </OrganisationDetailsContainer>
      <ContainerMsg>
        {preview.prorated_invoice && (
          <>
            {preview.prorated_invoice.total > 0 && (
              <p data-testid={"prorated-cost"}>
                {t("customer.update.text.proratedCost")}{" "}
                <PurpleSpan data-test="preview"> {proratedCost}</PurpleSpan>
              </p>
            )}
            {preview.prorated_invoice.total < 0 && (
              <p data-testid={"downgrade-message"}>
                {t("customer.update.text.downgradeSeats")}
              </p>
            )}
          </>
        )}

        <p data-testid={"immediate-cost"}>
          {t("customer.update.text.ongoingCost")}{" "}
          <PurpleSpan data-test="preview"> {ongoingCost}</PurpleSpan>
        </p>
        <p data-testid={"mandate"}>{t("customer.create.text.mandate")}</p>
      </ContainerMsg>
    </Modal>
  );
};

export const PreviewUpdateCustomerModal = props => (
  <Stripe>
    <UnWrappedPreviewUpdateCustomerModal {...props} />
  </Stripe>
);
