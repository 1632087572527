import { useEffect, useState } from "react";
import { useGetReleases } from "../../../context/releases/getReleases";
import { useReleases } from "../../../context/releases/releases.provider";

export const useSortingAndFiltering = () => {
  const { getReleases } = useGetReleases();
  const { page } = useReleases();

  const ASCENDING = "ASC";
  const DESCENDING = "DESC";
  const OPEN = "open";
  const CLOSED = "closed";

  const [sortMethod, setSortMethod] = useState("closeDate");
  const [sortDirection, setSortDirection] = useState(ASCENDING);
  const [filter, setFilter] = useState([OPEN]);

  useEffect(() => {
    getReleases({
      sort: sortMethod,
      direction: sortDirection,
      status: filter.join(","),
    });
  }, [sortMethod, sortDirection, filter]);

  const getNextPage = () =>
    getReleases({
      sort: sortMethod,
      direction: sortDirection,
      status: filter.join(","),
      page: page + 1,
    });

  const toggleFilter = toFilter =>
    setFilter(e =>
      e.includes(toFilter)
        ? [...e.filter(f => f !== toFilter)]
        : [...filter, toFilter]
    );

  const toggleSortDirection = () =>
    setSortDirection(e => (e === DESCENDING ? ASCENDING : DESCENDING));

  return {
    toggleFilter,
    showOpen: filter.includes(OPEN),
    toggleOpen: () => toggleFilter(OPEN),
    showClosed: filter.includes(CLOSED),
    toggleClosed: () => toggleFilter(CLOSED),
    toggleSortDirection,
    setSortMethod,
    getNextPage,
    isSortedAsc: sortDirection === ASCENDING,
    isSortedDesc: sortDirection === DESCENDING,
  };
};
