import { useEffect } from "react";
import { ENDPOINTS } from "../../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../../context/hooks";

export const useDeleteEntry = getRun => {
  const { deleteApi, status } = useFetch();
  const { createNotification, types } = useNotifications();

  useEffect(() => {
    if (status.isResolved) getRun();
    if (status.isRejected)
      createNotification(
        types.ERROR,
        "runView.testevolveexploratory.deleteEntryError"
      );
  }, [status.isRejected, status.isResolved]);

  const deleteEntry = entryId =>
    deleteApi({
      ...ENDPOINTS.EDIT_ENTRY,
      path: ENDPOINTS.EDIT_ENTRY.path.replace(":entryId", entryId),
    });

  return {
    deleteEntry,
    loadingDelete: status.isLoading,
  };
};
