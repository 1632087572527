import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../../static/locales/en.json";
import moment from "moment";

const resources = {
  en: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
    format: function (value, format) {
      if (value instanceof Date) {
        return moment(value).format(format);
      }
      return value;
    },
  },
});

export default i18n;
