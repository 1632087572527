import { useEffect, useState } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";

export const useInProgressRuns = setInProgressRuns => {
  const [isLoading, setIsLoading] = useState(true);
  const { get, body, status } = useFetch();

  useEffect(() => {
    if (status.isResolved && body) {
      setIsLoading(false);
      setInProgressRuns(body.run_summaries);
    }
  }, [status.isResolved, body]);

  const getInProgressRuns = () => get(ENDPOINTS.IN_PROGRESS_RUNS);

  return {
    loadingInProgressRuns: isLoading,
    getInProgressRuns,
  };
};
