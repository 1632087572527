import React from "react";
import { useTranslation } from "react-i18next";
import { StyledSelect } from "./projectRolesPicker.styles";
import { roles } from "../../../../static/constants/constants";

const buildOptions = (options, t) => {
  if (!options) return;
  return options.project
    .filter(role => role.key !== roles.PROJECT_USER)
    .map(option => ({
      label: t(`roles.${option.key}`),
      value: option.key,
    }));
};

const buildValues = (user, t) =>
  user.project_roles?.map(r => ({ value: r.key, label: t(`roles.${r.key}`) }));

export const ProjectRolesPicker = ({
  user,
  roles,
  disabled,
  handleSelect,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <StyledSelect
      data-test="select-component"
      isMulti={true}
      value={buildValues(user, t)}
      onChange={e => handleSelect(user.id, e)}
      options={buildOptions(roles, t)}
      placeholder={disabled ? t("noProjectRoles") : t("selectProjectRoles")}
      isClearable={false}
      classNamePrefix={"Select"}
      isDisabled={disabled}
      {...props}
    />
  );
};
