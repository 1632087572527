import { useTranslation } from "react-i18next";
import { useApp } from "../../../context/app/app.provider";
import { useTheme } from "../../../context/theme/theme.provider";
import {
  FilterOption,
  FiltersContainer,
  FilterOptionsWrapper,
  FilterSectionContainer,
  SmallContainer,
  StyledCautionIcon,
  InProgressWarning,
} from "../testevolve/testevolveRunReport.styles";
import { Button, Checkbox } from "../../../components";
import { StyledSelect } from "../../organisationSettings/tabs/organisation/projectsPicker.styles";
import { useDownloadReport } from "../testevolve/download/downloadReport";

export const FilterSection = ({
  run,
  tagsFilter,
  totalScenarioCount,
  statusFilter,
  setTagsFilter,
  attemptsFilter,
  setStatusFilter,
  setAttemptsFilter,
  tagsFilterOptions,
  statusFilterOptions,
  showDownloadButton,
  showFilterOptions,
  setShowFilterOptions,
  scenariosLength,
  isInProgress,
}) => {
  const { isNotDesktop } = useApp();
  const { t } = useTranslation();
  const { isDark } = useTheme();
  const { DownloadButton } = useDownloadReport();

  return (
    <FilterSectionContainer>
      <SmallContainer>
        <div>
          <Button
            secondary
            disabled={isInProgress}
            onClick={() => setShowFilterOptions(e => !e)}
          >
            {t("runReport.filters.title")}
          </Button>
          {isInProgress ? null : (
            <p>
              {t("runReport.filters.displayingScenariosMessage", {
                count: scenariosLength,
                total: totalScenarioCount,
              })}
            </p>
          )}
        </div>
        {showDownloadButton() && run.passed_percentage !== 100 ? (
          <DownloadButton runId={run.id} label={run.label} />
        ) : null}
      </SmallContainer>
      {isInProgress ? (
        <InProgressWarning>
          <StyledCautionIcon />
          <p>{t("runReport.inProgressRun")}</p>
        </InProgressWarning>
      ) : (
        showFilterOptions && (
          <FiltersContainer>
            <FilterOptionsWrapper>
              <FilterOption>
                <h4>{t("runReport.filters.status")}</h4>
                <StyledSelect
                  data-test="status-filter"
                  isMulti={true}
                  value={statusFilter.map(e => ({ value: e, label: e }))}
                  onChange={e => setStatusFilter(e.map(f => f.value))}
                  options={statusFilterOptions}
                  isClearable={false}
                  classNamePrefix={"Select"}
                  isNotDesktop={isNotDesktop}
                />
              </FilterOption>
              <FilterOption>
                <h4>{t("runReport.filters.tags")}</h4>
                <StyledSelect
                  data-test="tags-filter"
                  isMulti={true}
                  value={tagsFilter.map(e => ({ value: e, label: e }))}
                  onChange={e => setTagsFilter(e.map(f => f.value))}
                  options={tagsFilterOptions}
                  isClearable={false}
                  classNamePrefix={"Select"}
                  isNotDesktop={isNotDesktop}
                />
              </FilterOption>
              <FilterOption>
                <h4>{t("runReport.filters.multipleAttempts")}</h4>
                <Checkbox
                  data-test="attempts-filter"
                  checked={attemptsFilter}
                  className="multipleAttemptsCheck"
                  onChange={() => setAttemptsFilter(f => !f)}
                  dark={isDark}
                />
              </FilterOption>
            </FilterOptionsWrapper>
          </FiltersContainer>
        )
      )}
    </FilterSectionContainer>
  );
};
