import { useApp } from "../../../context/app/app.provider";
import { RUN_STATUSES } from "../../../static/constants/constants";
import { RunDetails } from "../runDetails/runDetails";
import {
  HorizontalWidgetContainer,
  WidgetContainer,
} from "../testEvolve/testEvolveRunView.styles";
import { useEditRun } from "../testevolveExploratory/hooks/run/useEditRun";
import { Container } from "../testevolveExploratory/testevolveExploratoryRunView.styles";
import { AccessibilityRunDetails } from "./accessibilityRunDetails/accessibilityRunDetails";
import { LeftWidget } from "./leftWidget/leftWidget";
import { RightWidget } from "./rightWidget/rightWidget";
import { TestEvolveAccessibilityRunHistory } from "./runHistory/runHistory";

export const TestEvolveAccessiblityRunView = ({
  run,
  getRun,
  release,
  isShared,
  scrollRef,
  runDetails,
  nodeDetails,
  setIsPolling,
  getRunDetails,
}) => {
  const { isNotDesktop } = useApp();
  const { editRun } = useEditRun(run.id, getRun);

  return (
    <Container ref={scrollRef} isShared={isShared}>
      <WidgetContainer isNotDesktop={isNotDesktop}>
        <RunDetails
          isShared={isShared}
          name={run.label}
          runApproved={run.approved}
          runId={run.id}
          run={run}
          nodeDetails={nodeDetails}
          runDetails={runDetails}
          setIsPolling={setIsPolling}
          release={release}
          hideFreeze={run.status.toLowerCase() !== RUN_STATUSES.CLOSED}
        />
        <AccessibilityRunDetails
          getRun={getRun}
          editField={editRun}
          run={run}
        />
        <HorizontalWidgetContainer isNotDesktop={isNotDesktop}>
          <LeftWidget run={run} />
          <RightWidget run={run} getRun={getRun} />
        </HorizontalWidgetContainer>
      </WidgetContainer>
      <TestEvolveAccessibilityRunHistory
        runDetails={runDetails}
        getRunDetails={getRunDetails}
      />
    </Container>
  );
};
