import { ACTIONS } from "../../static/constants/actions";

export const modalReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.OPEN_MODAL:
      return {
        ...state,
        modal: payload.modal,
        data: payload.data,
      };
    case ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        modal: false,
        data: null,
      };
    default:
      return state;
  }
};
