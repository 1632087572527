import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  padding: 0;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const OrganisationText = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.larger};
  word-break: break-word;
  text-align: center;
`;

export const ProjectIdText = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.large};
`;
