import { useModal } from "../../../../../../context/modal/modal.provider";
import { createState } from "../../../../../../utils/context";
import { ACTIONS } from "../../../../../../static/constants/actions";
import {
  CURRENCY_ISO_CODES,
  GB_COUNTRY_CODE,
} from "../../../../../../static/constants/constants";

const initialState = { code: null, currencyIsoCode: null };

const countryReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_COUNTRY:
      return {
        ...state,
        code: payload.value,
        currencyIsoCode:
          payload.value === GB_COUNTRY_CODE
            ? CURRENCY_ISO_CODES.GBP
            : CURRENCY_ISO_CODES.USD,
      };
    default:
      return state;
  }
};

const [useHook, CountryProvider] = createState(
  "country",
  initialState,
  countryReducer
);

const useCountry = () => {
  const { dispatch, ...country } = useHook();
  const { openModal, MODALS } = useModal();

  const saveCountry = val =>
    dispatch({ type: ACTIONS.SET_COUNTRY, payload: val });

  const setPartnerModalOpen = () => openModal(MODALS.ENABLE_PARTNER, {});

  const openCountryModal = toggleEditSubscriptionSection =>
    openModal(MODALS.SELECT_COUNTRY, {
      setPartnerModalOpen,
      toggleEditSubscriptionSection,
    });

  return {
    openCountryModal,
    saveCountry,
    country,
  };
};

export { useCountry, CountryProvider };
