import { useEffect } from "react";
import { useFetch, useNotifications } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useReleases } from "../../../context/releases/releases.provider";

export const useOpenRelease = () => {
  const { patch, status, body, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const { modifyRelease } = useReleases();

  const openRelease = id =>
    patch({
      ...ENDPOINTS.OPEN_RELEASE,
      path: ENDPOINTS.OPEN_RELEASE.path.replace(":releaseId", id),
    });

  useEffect(() => {
    if (status.isResolved && body) {
      createNotification(types.SUCCESS, "releases.openRelease.success");
      modifyRelease(body.id, body);
    }
    if (status.isRejected)
      createNotification(types.ERROR, "releases.openRelease.error");
  }, [status.isResolved, error, status.isRejected, body]);

  return {
    openRelease,
    isError: status.isRejected,
    isLoading: status.isLoading,
  };
};
