import { useEffect, useRef, useState } from "react";
import { useFetch, useFormValidation } from "../../../context/hooks";
import { ENDPOINTS } from "../../../static/constants/routeConfig";

const ORGANISATION_NAME = "Organisation name";
const ORGANISATION_ID = "Organisation id";
const EMAIL_ADDRESS = "Email address";

const ORG_ID = "orgId";
const ORG_NAME = "orgName";
const EMAIL = "email";

export const useSignUp = formRef => {
  const { post, status, origin, reset, error } = useFetch();
  const orgIdRef = useRef(null);
  const orgNameRef = useRef(null);
  const emailRef = useRef(null);
  const captchaRef = useRef(null);

  const [token, setToken] = useState(null);
  const [formErrors, setFormErrors] = useState([]);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputData, setInputData] = useState({
    orgId: "",
    orgName: "",
    email: "",
  });

  useEffect(() => {
    setFormSubmitted(false);
  }, []);

  const { isValid, isInvalid, validation, validateForm } = useFormValidation();

  useEffect(() => {
    if (status.isResolved) setSignupSuccess(true);
    if (status.isRejected) formRef.current?.reset();
  }, [status, origin]);

  useEffect(() => {
    if (isValid && token && formSubmitted) {
      setFormSubmitted(false);

      post(ENDPOINTS.AUTH_SIGNUP, {
        org_id: inputData.orgId,
        name: inputData.orgName,
        admin_username: inputData.email,
        token: token,
      });
    }
  }, [isValid, isInvalid, token, validation, formSubmitted]);

  const changeFormErrors = input => {
    let newErrors = [];
    let unique = [];

    input.map(i => {
      newErrors = [
        ...new Set([...formErrors.filter(f => f.name !== i[0]), ...i[1]]),
      ];

      unique = newErrors.filter(
        (v, i, a) => a.findIndex(v2 => v2.text === v.text) === i
      );
    });

    if (unique.length !== newErrors.length) setFormErrors(unique);
    else setFormErrors(newErrors);
  };

  const onFocus = () => reset();

  const validateOrgName = val => {
    const res = validateForm([
      {
        name: ORGANISATION_NAME,
        value: val,
        validate: ["length"],
        params: { min: 2, max: 45 },
      },
    ]);
    changeFormErrors([[ORGANISATION_NAME, res]]);
  };

  const validateEmail = val => {
    const res = validateForm([
      { name: EMAIL_ADDRESS, value: val, validate: ["email"] },
    ]);
    changeFormErrors([[EMAIL_ADDRESS, res]]);
  };

  const validateOrgId = val => {
    const res = validateForm([
      {
        name: ORGANISATION_ID,
        value: val,
        validate: ["length", "special"],
        params: { min: 3, max: 63 },
      },
    ]);
    changeFormErrors([[ORGANISATION_ID, res]]);
  };

  const onOrgNameChange = e => {
    setInputData(d => ({ ...d, orgName: e.target.value }));
  };

  const onEmailChange = e => {
    setInputData(d => ({ ...d, email: e.target.value }));
  };

  const onOrgIdChange = e => {
    setInputData(d => ({ ...d, orgId: e.target.value?.toLowerCase() }));
  };

  useEffect(() => {
    if (orgIdRef?.current?.value) validateOrgId(orgIdRef?.current?.value);
  }, [orgIdRef, orgIdRef?.current, orgIdRef?.current?.value]);

  useEffect(() => {
    if (emailRef?.current?.value) validateEmail(emailRef?.current?.value);
  }, [emailRef, emailRef?.current, emailRef?.current?.value]);

  useEffect(() => {
    if (orgNameRef?.current?.value) validateOrgName(orgNameRef?.current?.value);
  }, [orgNameRef, orgNameRef?.current, orgNameRef?.current?.value]);

  const formAction = async e => {
    reset();
    e?.preventDefault();
    const form = new FormData(e.target);
    const orgId = form.get(ORG_ID)?.toLowerCase();
    const orgName = form.get(ORG_NAME);
    const email = form.get(EMAIL);

    const orgIdRes = validateForm([
      {
        name: ORGANISATION_ID,
        value: orgId,
        validate: ["length", "special"],
        params: { min: 3, max: 63 },
      },
    ]);

    const orgNameRes = validateForm([
      {
        name: ORGANISATION_NAME,
        value: orgName,
        validate: ["length"],
        params: { min: 2, max: 45 },
      },
    ]);

    const emailRes = validateForm([
      { name: EMAIL_ADDRESS, value: email, validate: ["email"] },
    ]);

    const results = [
      [EMAIL, emailRes],
      [ORGANISATION_NAME, orgNameRes],
      [ORGANISATION_ID, orgIdRes],
    ];

    if (orgNameRes.length || emailRes.length || orgIdRes.length)
      changeFormErrors(results);
    else {
      const { response } = await captchaRef.current.execute({ async: true });
      setToken(response);
    }
    setFormSubmitted(true);
  };

  return {
    error,
    onFocus,
    inputData,
    orgIdRef,
    orgNameRef,
    captchaRef,
    emailRef,
    formErrors,
    formAction,
    onEmailChange,
    signupSuccess,
    isLoading: status?.isLoading,
    onOrgIdChange,
    orgId: inputData?.orgId,
    onOrgNameChange,
    setToken,
    emptyInputs:
      !inputData?.orgId?.length ||
      !inputData?.orgName?.length ||
      !inputData?.email?.length,
  };
};
