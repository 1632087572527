import { useEffect, useState } from "react";
import {
  UNLIMITED,
  MONTHLY,
  PARTNER,
  PLAN_INTERVALS,
} from "../../../../static/constants/constants";
import { useSubscriptionPlans } from "../../../../context/subscriptionPlans/subscriptionPlans.provider";
import { Modal } from "../../..";
import { useTranslation } from "react-i18next";
import { StyledContainer } from "../../../../views/organisationSettings/tabs/billing/subscriptionDetails/modals/modals.styles";
import { useModal } from "../../../../context/modal/modal.provider";
import { useCountry } from "../../../../views/organisationSettings/tabs/billing/paymentDetails/hooks/useCountry";

export const EnablePartnerSubscriptionModal = ({ closeModal }) => {
  const [partnerPlanId, setPartnerPlanId] = useState("");

  const { t } = useTranslation();
  const { openModal, MODALS } = useModal();
  const { getSubscriptionPlans, subscriptionPlans } = useSubscriptionPlans();
  const { country } = useCountry();

  const openCreateSubscriptionModal = () =>
    openModal(MODALS.CREATE_SUBSCRIPTION, {
      price: 0,
      country: country,
      activePlan: MONTHLY,
      maxSeatsInput: UNLIMITED,
      productSelected: { id: partnerPlanId },
    });

  useEffect(() => {
    if (country.currencyIsoCode) getSubscriptionPlans(country.currencyIsoCode);
  }, [country.currencyIsoCode]);

  useEffect(() => {
    let ppid = null;
    if (subscriptionPlans?.products) {
      const product = subscriptionPlans.products.find(
        product => product.kyuri_id === PARTNER
      );
      if (product?.plans) {
        ppid = product.plans.find(
          plan => plan.interval === PLAN_INTERVALS.MONTH
        ).id;
      }
    }
    setPartnerPlanId(ppid);
  }, [subscriptionPlans]);

  return (
    <Modal
      secondary={{
        text: t("partner.button.cancel"),
        onClick: closeModal,
        style: "cancel",
      }}
      primary={{
        text: t("partner.button.proceed"),
        onClick: openCreateSubscriptionModal,
        style: "accent",
        props: {
          disabled: !partnerPlanId,
        },
      }}
      size="small"
      title={t("partner.title.enableSubscription")}
    >
      <StyledContainer>
        <p>{t("partner.message.enableSubscription1")}</p>
        <p>{t("partner.message.enableSubscription2")}</p>
      </StyledContainer>
    </Modal>
  );
};
