import { useProject } from "../../project/project.provider";
import { useUser } from "../../user/user.provider";
import { roles, permissions } from "../../../static/constants/constants";

export const usePermissions = () => {
  const { user } = useUser();
  const { project } = useProject();

  const hasGlobalPermission = (permissionToFind, usr) => {
    const permissions = usr?.global_roles?.map(role => role.permissions).flat();
    return permissions?.map(p => p.key).includes(permissionToFind);
  };

  const hasProjectPermission = (permissionToFind, specifiedUser) => {
    let usr = specifiedUser || user;

    if (!usr?.project_roles?.length) return false;

    const permissions = usr?.project_roles?.filter(
      p => p.project?.id === project?.id
    );
    const r = permissions[0]?.roles;
    return r?.map(p => p.key).includes(permissionToFind);
  };

  const hasPermission = (permissionToFind, specifiedUser) => {
    let usr = specifiedUser || user;
    let projectPermissionFound,
      globalPermissionFound = false;

    if (project && project?.id)
      projectPermissionFound = hasProjectPermission(permissionToFind, usr);
    globalPermissionFound = hasGlobalPermission(permissionToFind, usr);

    return projectPermissionFound || globalPermissionFound;
  };

  const isUserAdmin = specifiedUser => {
    let usr = specifiedUser || user;
    return !!usr.global_roles.find(role => role.key === roles.USER_ADMIN);
  };

  const isPartnerAdmin = specifiedUser => {
    let usr = specifiedUser || user;
    return !!usr.global_roles.find(role => role.key === roles.PARTNER_ADMIN);
  };

  const isCustomerAdmin = specifiedUser => {
    let usr = specifiedUser || user;
    return !!usr.global_roles.find(role => role.key === roles.CUSTOMER_ADMIN);
  };

  const isPartnerUser = specifiedUser => {
    let usr = specifiedUser || user;
    return !!usr.global_roles.find(role => role.key === roles.PARTNER_USER);
  };

  return {
    hasPermission,
    permissions,
    roles,
    hasProjectPermission,
    hasGlobalPermission,
    isUserAdmin,
    isPartnerAdmin,
    isPartnerUser,
    isCustomerAdmin,
    isPartner: usr => isPartnerUser(usr) || isPartnerAdmin(usr),
    isApiAdmin: usr => hasProjectPermission(roles.API_ADMIN, usr),
    isAdmin: usr => isUserAdmin(usr) || isPartnerAdmin(usr),
  };
};
