import { ACTIONS } from "../../static/constants/actions";

export const themeReducer = (state, { type }) => {
  switch (type) {
    case ACTIONS.LIGHT_MODE:
      return { ...state, theme: "light" };
    case ACTIONS.DARK_MODE:
      return { ...state, theme: "dark" };
    default:
      return state;
  }
};
