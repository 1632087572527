import styled from "styled-components";
import { HaloLogo, TestEvolveLogo, CenteredContainer } from "../../components";
import { StyledLink as Link } from "../../components/Link/StyledLink";
import { mediaQuery } from "../../utils/theme/mediaQuery";

export const SignInContainer = styled.div`
  display: flex;
  width: ${({ theme }) => theme.sizing.full};
  max-width: ${({ theme }) => theme.sizing.full};
  background-color: ${({ theme }) => theme.colours.background};
  padding: ${({ theme }) => theme.spacing.padding};
  height: 100%;
  overflow: auto;
  align-items: center;

  ${mediaQuery.lessThan("LG")`
    align-items: flex-start;
  `}
  justify-content: center;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  text-align: center;

  background-color: ${({ theme }) => theme.colours.foreground};

  border-radius: ${({ theme }) => theme.borders.radius};
  box-shadow: ${({ theme }) => theme.decoration.cardShadow};

  width: ${({ theme }) => theme.sizing.full};
  max-width: 640px;
  min-width: 240px;

  padding: ${({ theme }) =>
    `${theme.spacing.paddingLG} ${theme.spacing.padding}`};
  gap: ${({ theme }) => theme.spacing.paddingLG};

  h1 {
    padding-bottom: ${({ theme }) => theme.spacing.padding};
  }
`;

export const StyledAccountText = styled.p``;

export const PasswordContainer = styled(CenteredContainer)`
  align-items: flex-end;
  width: ${({ theme }) => theme.sizing.full};
  margin-bottom: ${({ theme }) => theme.spacing.paddingSM};
`;

export const FormContainer = styled(CenteredContainer)`
  justify-content: space-between;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  max-width: ${({ theme }) => theme.sizing.nodeCardWidth};
  width: 80%;
`;

export const ProductLogo = styled(HaloLogo)`
  height: auto;
  min-height: 40px;
  max-width: 200px;
`;

export const CompanyLogo = styled(TestEvolveLogo)`
  height: auto;
  min-height: ${({ theme }) => theme.sizing.teLogoHeight};
  width: ${({ theme }) => theme.sizing.teLogoWidth};
`;

export const SignUpContainer = styled(CenteredContainer)`
  justify-content: space-between;
`;

export const OrgContainer = styled(CenteredContainer)`
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const InputContainer = styled.div`
  text-align: left;
  width: 100%;
  p {
    color: ${({ theme }) => theme.colours.error};
  }
`;

export const BackLinkContainer = styled.div`
  position: relative;

  a {
    color: ${({ theme }) => theme.colours.mainText};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colours.haloAccent};
    }
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colours.mainText};
  text-decoration: none;

  &:active {
    div {
      background-color: ${({ theme }) => theme.colours.secondaryButton};
    }
  }
`;

export const ForgotPasswordLink = styled(Link)`
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.error};
  width: 100%;
  text-align: left;
  padding-left: ${({ theme }) => theme.spacing.paddingSM};
  height: ${({ theme }) => theme.spacing.paddingSM};
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const ReportsMessage = styled.p`
  a {
    color: ${({ theme }) => theme.colours.haloAccent};
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.625;
    width: 100%;
  }

  font-size: ${({ theme }) => theme.fontSize.medium};

  padding-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const TermsMessage = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-text: center;
  align-items: center;
  justify-content: center;

  a {
    color: ${({ theme }) => theme.colours.haloAccent};
    line-height: 1.625;
    white-space: nowrap;
    padding: 0 ${({ theme }) => theme.spacing.paddingXSM};
    text-decoration: none;
  }

  white-space: nowrap;

  font-size: ${({ theme }) => theme.fontSize.medium};
  padding-bottom: ${({ theme }) => theme.spacing.padding};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.paddingSM};
  width: 100%;
  p {
    text-align: left;
  }
`;

export const RequirementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const SuccessContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.padding};
  flex-direction: column;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;
