import { useTheme } from "styled-components";
import { RunStatusContainer } from "../testEvolveAccessiblityRunView.styles";
import { GraphKey, GraphKeyComponent, KeyColour } from "./leftWidget.styles";
import { RunStatusGraph } from "./runStatusGraph";
import { useTranslation } from "react-i18next";

export const RunStatusSection = ({ run }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <RunStatusContainer>
      <h1>{t("runView.testevolveaccessibility.successCriteria")}</h1>
      <RunStatusGraph criteria={run.criteria} />
      <GraphKey>
        <GraphKeyComponent>
          <KeyColour
            colour={`linear-gradient(90deg, ${theme.gradients.passing_end}, ${theme.gradients.passing_start})`}
          />
          <p>{t("runView.testevolveaccessibility.passed")}</p>
        </GraphKeyComponent>
        <GraphKeyComponent>
          <KeyColour
            colour={`linear-gradient(90deg, ${theme.gradients.failed_check_start}, ${theme.gradients.failed_check_end})`}
          />
          <p>{t("runView.testevolveaccessibility.failed")}</p>
        </GraphKeyComponent>
        <GraphKeyComponent>
          <KeyColour
            colour={`linear-gradient(90deg, ${theme.gradients.skipped_end}, ${theme.gradients.skipped_start})`}
          />
          <p>{t("runView.testevolveaccessibility.not_started")}</p>
        </GraphKeyComponent>
      </GraphKey>
    </RunStatusContainer>
  );
};
