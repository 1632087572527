import { useTranslation } from "react-i18next";
import {
  RUN_PROPERTIES,
  RUN_STATUSES,
} from "../../../../../static/constants/constants";
import { useTime } from "../../../../../context/hooks/time/useTime";

export const useLatestRun = run => {
  const { t } = useTranslation();
  const { getReadableTimeDifference } = useTime();

  const status = run.status;

  const runStatus =
    t(`runCard.testevolve.status.${status}`) ||
    t("runCard.testevolveexploratory.n/a");

  const endTime =
    status === RUN_STATUSES.COMPLETE ? run.finish_time : run.updated_time;

  const timeAgo =
    `${getReadableTimeDifference(endTime)} ${t("time.ago")}` ||
    t("runCard.testevolveexploratory.n/a");

  const runProps = RUN_PROPERTIES[run.test_type]?.map(prop => [
    run.run_properties[prop],
    prop,
  ]);

  return {
    run,
    status,
    timeAgo,
    runProps,
    runStatus,
    startTime: run.start_time,
    runProperties: run.run_properties,
    passPercent: run.passed_percentage,
  };
};
