import styled from "styled-components";
import { StyledLink } from "../../components";

export const ContentContainer = styled.div`
  justify-content: space-around;

  border-radius: ${({ theme }) => theme.borders.radius};

  width: ${({ theme }) => theme.sizing.full};
  height: ${({ theme }) => theme.sizing.full};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({ theme }) => theme.sizing.btnHeight};
  min-height: ${({ theme }) => theme.sizing.btnHeight};

  overflow-x: auto;
  overflow-y: hidden;

  padding-bottom: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

export const Tab = styled(StyledLink)`
  cursor: pointer;
  height: ${({ theme }) => theme.sizing.btnHeight};
  width: ${({ theme }) => theme.sizing.tabLegth};
  min-width: ${({ theme }) => theme.sizing.tabLegth};
  border-radius: ${({ theme }) => theme.borders.radius};
  background-color: ${({ theme, $active }) =>
    $active ? theme.colours.haloAccentLight : theme.colours.foreground};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colours.mainText};

  &:hover,
  &:focus {
    color: ${({ theme, $active }) =>
      $active ? theme.colours.mainText : theme.colours.haloAccent};
  }
`;
