import { RunTrends } from "./runTrends/runTrends";
import { RunHistory } from "./runHistory/runHistory";
import { RunDetails } from "../runDetails/runDetails";
import { LatestRunCard } from "./latestRunCard/latestRunCard";
import {
  Container,
  RightContainer,
  LeftContainer,
  WidgetContainer,
  HorizontalWidgetContainer,
} from "./playwrightRunView.styles";
import { Loading } from "../../../components";
import { useApp } from "../../../context/app/app.provider";
import { RightWidget } from "./rightWidget/rightWidget";

export const PlaywrightRunView = ({
  scrollRef,
  run,
  nodeDetails,
  runDetails,
  getRunDetails,
  isShared,
  isLatest,
  setIsPolling,
  release,
  isMostRecent,
}) => {
  const { isNotDesktop } = useApp();

  return run ? (
    <Container ref={scrollRef} isShared={isShared}>
      <WidgetContainer isNotDesktop={isNotDesktop}>
        <RunDetails
          isShared={isShared}
          name={run.label}
          runApproved={run.approved}
          runId={run.id}
          run={run}
          nodeDetails={nodeDetails}
          runDetails={runDetails}
          setIsPolling={setIsPolling}
          release={release}
          noExport={true}
        />
        <HorizontalWidgetContainer isNotDesktop={isNotDesktop}>
          <LeftContainer isNotDesktop={isNotDesktop}>
            <LatestRunCard
              run={run}
              isShared={isShared}
              runDetails={runDetails}
              isLatest={isLatest}
              nodeDetails={nodeDetails}
            />
            {!isNotDesktop && <RunTrends runDetails={runDetails} />}
          </LeftContainer>
          <RightContainer isNotDesktop={isNotDesktop}>
            <RightWidget
              run={run}
              isShared={isShared}
              isLatest={isLatest}
              nodeDetails={nodeDetails}
              isMostRecent={isMostRecent}
            />
          </RightContainer>
          {isNotDesktop && <RunTrends runDetails={runDetails} />}
        </HorizontalWidgetContainer>
      </WidgetContainer>
      {isShared ? null : (
        <RunHistory runDetails={runDetails} getRunDetails={getRunDetails} />
      )}
    </Container>
  ) : (
    <Loading />
  );
};
