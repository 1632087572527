import { useNodeLabels } from "./hooks/useNodeLabels";
import { LabelsComponent } from "../../../components/labels/labelsComponent";

export const NodeLabels = () => {
  const { labels, addNewLabel, removeLabel } = useNodeLabels();

  return (
    <LabelsComponent
      labels={labels}
      addNewLabel={addNewLabel}
      removeLabel={removeLabel}
      edit={true}
    />
  );
};
