import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import { CustomLink } from "../../../components/Link/StyledLink";
import {
  APP_DOCUMENTATION_LINK,
  APP_DOWNLOAD_APP_IMAGE,
  APP_DOWNLOAD_DMG,
  APP_DOWNLOAD_MSI,
} from "../../../static/constants/constants";
import {
  DownloadButton,
  IconContainer,
  OSCard,
  OSCardContainer,
  StyledSecondaryText,
  StyledTitle,
} from "../styles";
import { ReactComponent as AppleLogo } from "../../../static/assets/icon-apple.svg";
import { useApp } from "../../../context/app/app.provider";
import { ReactComponent as WindowsLogo } from "../../../static/assets/icon-windows.svg";
import { ReactComponent as LinuxLogo } from "../../../static/assets/icon-linux.svg";

export const FlareSection = ({ version }) => {
  const i18n = useTranslation();
  const { isNotDesktop } = useApp();

  return (
    <>
      <CustomLink accent small={true} to={APP_DOCUMENTATION_LINK}>
        <Button large onClick={() => {}}>
          {i18n.t("downloads.viewDocumentation")}
        </Button>
      </CustomLink>
      <br />
      <StyledTitle>{i18n.t("downloads.chooseOs")}</StyledTitle>
      <OSCardContainer isNotDesktop={isNotDesktop}>
        <OSCard>
          <StyledTitle>{i18n.t("Linux")}</StyledTitle>
          <StyledSecondaryText>{i18n.t(".AppImage")}</StyledSecondaryText>
          <LinuxLogo />
          <CustomLink button accent small to={APP_DOWNLOAD_APP_IMAGE(version)}>
            <DownloadButton>{i18n.t("btnDownload")}</DownloadButton>
          </CustomLink>
        </OSCard>
        <OSCard>
          <StyledTitle>{i18n.t("Mac OS")}</StyledTitle>
          <StyledSecondaryText>{i18n.t(".dmg")}</StyledSecondaryText>
          <IconContainer>
            <AppleLogo />
          </IconContainer>
          <CustomLink button accent small to={APP_DOWNLOAD_DMG(version)}>
            <DownloadButton>{i18n.t("btnDownload")}</DownloadButton>
          </CustomLink>
        </OSCard>
        <OSCard>
          <StyledTitle>{i18n.t("Windows")}</StyledTitle>
          <StyledSecondaryText>{i18n.t(".exe")}</StyledSecondaryText>
          <WindowsLogo />
          <CustomLink button accent small to={APP_DOWNLOAD_MSI(version)}>
            <DownloadButton>{i18n.t("btnDownload")}</DownloadButton>
          </CustomLink>
        </OSCard>
      </OSCardContainer>
    </>
  );
};
