import { useEffect, useState } from "react";
import { useFetch } from "../../../../context/hooks";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";

export const useJiraBugs = () => {
  const [jiraBugs, setJiraBugs] = useState(null);
  const { get, status, body } = useFetch();

  const getJiraBugs = runId =>
    get({
      ...ENDPOINTS.JIRA_BUGS,
      path: ENDPOINTS.JIRA_BUGS.path.replace(":runId", runId),
    });

  useEffect(() => {
    if (status.isResolved && body) setJiraBugs(body);
  }, [status.isResolved, body]);

  return {
    getJiraBugs,
    jiraBugs,
    loading: status.isLoading,
  };
};
