import { useTranslation } from "react-i18next";
import {
  Container,
  GraphContainer,
  RunStatusContainer,
  RunStatusSticker,
  RunStatusStickerBox,
  RunStatusTitle,
  RunStatusValue,
  StickersContainer,
  Section,
} from "./checksSection.styles";
import { CheckLabelTreemap } from "../../../../../components/graph/graph";
import { GraphOuter } from "../ScenariosSection/scenariosSection.styles";

export const ChecksSection = ({
  checksPerformed,
  totalFailedChecks,
  checkLabels,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Section>
        <StickersContainer>
          <RunStatusStickerBox>
            <RunStatusSticker>
              <RunStatusContainer data-test="te-visual-checks-sticker">
                <RunStatusTitle>
                  {t("runView.testevolvevisual.numberOfChecksTitle")}
                </RunStatusTitle>
                <RunStatusValue>{checksPerformed}</RunStatusValue>
              </RunStatusContainer>
            </RunStatusSticker>
          </RunStatusStickerBox>
          <RunStatusStickerBox>
            <RunStatusSticker>
              <RunStatusContainer data-test="te-visual-violations-sticker">
                <RunStatusTitle>
                  {t("runView.testevolvevisual.totalFailedChecks")}
                </RunStatusTitle>
                <RunStatusValue>{totalFailedChecks}</RunStatusValue>
              </RunStatusContainer>
            </RunStatusSticker>
          </RunStatusStickerBox>
        </StickersContainer>
      </Section>
      <Section>
        <GraphOuter>
          <GraphContainer>
            <CheckLabelTreemap data={checkLabels} />
          </GraphContainer>
        </GraphOuter>
      </Section>
    </Container>
  );
};
