import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../..";
import { useDisassociateCustomer } from "../../../../views/organisationSettings/tabs/partnerCustomers/hooks/disassociateCustomer";
import {
  ContainerMsg,
  OrganisationDetailsContainer,
  OrganisationDetailsRow,
} from "../../../../views/organisationSettings/tabs/partnerCustomers/modals/modal.styles";

export const DisassociateCustomerModal = ({
  closeModal,
  orgId,
  orgName,
  setUpdated,
}) => {
  const { disassociateCustomer } = useDisassociateCustomer(
    closeModal,
    setUpdated
  );
  const [disassociating, setDisassociating] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setDisassociating(false);
  }, [orgId]);

  const confirm = () => {
    setDisassociating(true);
    disassociateCustomer(orgId);
  };

  return (
    <Modal
      title={t("customer.title.disassociateOrganisation")}
      toggleModal={() => closeModal()}
      size="small"
      secondary={{
        text: t("customer.button.cancel"),
        onClick: () => closeModal(),
      }}
      primary={{
        text: disassociating
          ? t("customer.button.disassociating")
          : t("customer.button.disassociate"),
        onClick: () => confirm(),
        props: {
          danger: true,
          disabled: disassociating,
        },
      }}
    >
      <OrganisationDetailsContainer>
        <OrganisationDetailsRow>
          <div>{t("customer.label.organisationId")}</div>
          <div>{orgId}</div>
        </OrganisationDetailsRow>
        <OrganisationDetailsRow>
          <div>{t("customer.label.organisationName")}</div>
          <div>{orgName}</div>
        </OrganisationDetailsRow>
      </OrganisationDetailsContainer>
      <ContainerMsg>
        <p data-testid={"confirm-message-1"}>
          {t("customer.disassociate.text.confirm1")}
        </p>
        <p data-testid={"confirm-message-2"}>
          {t("customer.disassociate.text.confirm2")}
        </p>
      </ContainerMsg>
    </Modal>
  );
};
