import styled from "styled-components";

export const StyledCardWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.padding};
  border-top-left-radius: 0;
  @media (min-width: 900px) {
    width: 100%;
    font-size: ${({ theme }) => theme.typography.minFontSize};
  }
  font-size: ${({ theme }) => theme.typography.mobileFontSize};
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  width: 100%;
`;

export const MessageContainer = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme, isNotDesktop }) =>
    isNotDesktop && `${theme.spacing.paddingSM} ${theme.spacing.padding}`};
  gap: ${({ theme }) => theme.spacing.padding};
`;
