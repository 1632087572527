export const compareArray = (arr1, arr2) => {
  const array2Sorted = arr2.slice().sort();
  if (arr1.length === 0 && arr2.length === 0) return true;
  return (
    arr1.length === arr2.length &&
    arr1
      .slice()
      .sort()
      .every((value, index) => value.id === array2Sorted[index].id)
  );
};
