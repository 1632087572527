import styled from "styled-components";
import { Input } from "../../../../components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isNotDesktop }) => isNotDesktop && "center"};
  justify-content: ${({ isNotDesktop }) => isNotDesktop && "center"};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const ApiKeysInput = styled(Input)``;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};
  padding: ${({ theme }) => theme.spacing.padding};
  gap: ${({ theme }) => theme.spacing.padding};
`;

export const ApiKeyRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isNotDesktop }) => (isNotDesktop ? "column" : "row")};
  gap: ${({ isNotDesktop, theme }) =>
    isNotDesktop ? theme.spacing.padding : null};
  align-items: center;
  padding: ${({ theme }) => theme.spacing.padding};
`;

export const ListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.padding};
  border-bottom: 1px solid ${({ theme }) => theme.colours.background};
`;

export const NoKeysText = styled.p`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.paddingLG};
`;
