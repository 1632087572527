import styled from "styled-components";
import { theme } from "../../../../../theme/theme";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.foreground};
  padding: ${({ theme }) => theme.spacing.padding};
  border: 1px solid ${({ theme }) => theme.colours.foreground};
  border-radius: ${({ theme }) => theme.borders.radius};

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.padding};
  }
  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

export const StyledSubSectionLarge = styled.div`
  padding: ${({ theme }) => theme.spacing.paddingSM};
  border: 1px solid ${({ theme }) => theme.colours.background};
  border-radius: ${({ theme }) => theme.borders.radius};

  @media (min-width: 900px) {
    width: 100%;
  }
`;

export const StyledHeader = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.colours.background};

  padding: ${({ theme }) => theme.spacing.padding} 0;
  @media (min-width: 900px) {
    padding: ${({ theme }) => theme.spacing.padding} 0;
  }
  border-radius: ${({ theme }) => theme.borders.radiusSM};
`;

export const StyledInfoDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.padding};
  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: space-around;
    padding: ${({ theme }) => theme.spacing.padding} 0;
    flex-wrap: wrap;
  }
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableHeading = styled.th`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  height: ${({ theme }) => theme.sizing.btnHeight};
`;

export const TableData = styled.td`
  height: ${({ theme }) => theme.sizing.btnHeight};
  text-align: center;
`;

export const StyledTable = styled.table`
  width: 100%;
`;

export const iconStyle = {
  width: theme.sizing.iconSize,
  height: theme.sizing.iconSize,
  opacity: 0.6,
};

export const Image = styled.img`
  display: block;
  max-height: ${({ theme }) => theme.sizing.btnHeight};
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
