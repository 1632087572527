import { useTranslation } from "react-i18next";
import { Modal } from "../../modal";

export const DeleteSessionModal = ({
  closeModal,
  session,
  deleteSession,
  isMine,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("runView.testevolveexploratory.deleteSessionModal.title")}
      text={t("runView.testevolveexploratory.deleteSessionModal.text", {
        owner: isMine
          ? t("runView.testevolveexploratory.deleteSessionModal.your")
          : `${session.owner}'s`,
      })}
      primary={{
        text: t("btnDelete"),
        onClick: () => deleteSession(),
        props: {
          danger: true,
        },
      }}
      secondary={{
        text: t("btnCancel"),
        onClick: closeModal,
      }}
    >
      <p>{t("runView.testevolveexploratory.deleteSessionModal.areYouSure")}</p>
    </Modal>
  );
};
