import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useFetch } from "../../../context/hooks/fetch/useFetch";
import { ENDPOINTS } from "../../../static/constants/routeConfig";
import { useReleases } from "../../../context/releases/releases.provider";

export const useSharedReleaseView = () => {
  const { release, setRelease } = useReleases();

  const [unshared, setUnshared] = useState(false);
  const { token } = useParams();
  const { get, status, body, error, origin } = useFetch();

  const getSharedRelease = () => {
    let url = ENDPOINTS.GET_SHARED_RELEASE.path.replace(
      ":releaseSharingToken",
      token
    );

    get({
      ...ENDPOINTS.GET_SHARED_RELEASE,
      path: url,
    });
  };

  useEffect(() => {
    if (status.isResolved && body) setRelease(body);
    if (status.isRejected && error.code === 404) setUnshared(true);
  }, [status.isResolved, status.isRejected, body, error, origin]);

  return {
    getSharedRelease,
    release,
    unshared,
  };
};
