import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isNotDesktop ? "column" : "row")};
  padding: ${({ theme }) => theme.spacing.paddingXSM} 0;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spacing.paddingSM};
`;

const TotalProgress = styled.div`
  width: ${({ percent, animate, animated }) =>
    (percent && animate && !animated && "0") ||
    (percent && animate && animated && `${percent}%`) ||
    (percent && `${percent}%`)};
  background: ${({ shouldShowProgress, theme }) =>
    shouldShowProgress && theme.colours.success};
  height: 100%;
  display: flex;
  transition: all 1s ease-in-out;
  border-radius: ${({ theme }) => theme.borders.radius};
`;

const ProgressElement = styled.div`
  background-image: ${({ theme, type }) =>
    (type === "passed" &&
      `linear-gradient(270deg, ${theme.gradients.passing_start}, ${theme.gradients.passing_end})`) ||
    (type === "failed" &&
      `linear-gradient(90deg, ${theme.gradients.error_start}, ${theme.gradients.error_end})`)};
  height: 100%;
  width: ${({ value }) => `${value}%`};
  transition: all 1s ease-in-out;
  border-radius: ${({ theme }) => theme.borders.radius};
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${({ labelAlignLeft, theme }) =>
    !theme.isNotDesktop && labelAlignLeft ? "50%" : "100%"};
`;

const ProgressContainer = styled.div`
  ${({ theme, labelAlignLeft }) => css`
    height: 12px;
    width: 100%;
    display: ${labelAlignLeft && "flex"};
    position: relative;
    border-radius: ${theme.borders.radius};

    ${ProgressElement} {
      &:first-child {
        margin-right: 4px;
      }
      &:last-child {
        margin-left: 4px;
      }
      &:only-child {
        margin: 0;
      }
    }
  `}
`;

const Label = styled.p`
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: ${({ theme }) => theme.spacing.padding};
  color: ${({ theme }) => theme.colours.primary};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: ${({ theme }) => theme.spacing.paddingMD};
`;

const PercentageContainer = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borders.radius};
  width: ${({ theme }) => theme.sizing.labelWidth};
`;

const PercentageLabel = styled.p`
  color: ${({ theme }) => theme.colours.mainText};
  margin: 0;
  width: 100%;
  text-align: right;
`;

const Line = ({
  data,
  percent,
  label,
  labelAlignLeft,
  percentageLabelValue,
  showPercentageLabel,
  showPercentageLabelLeft,
  thinLine,
  largeLine,
  largerLine,
  animate,
  animated,
  rounded,
}) => {
  const renderProgressData = data => {
    if (data.value > 0) {
      return (
        <ProgressElement
          value={data.value}
          key={data.type}
          type={data.type}
          data-test={`progress-${data.type}`}
        />
      );
    }
  };

  return (
    <Container displayFlex>
      {label && (
        <LabelContainer labelAlignLeft={labelAlignLeft}>
          <Label data-test="progress-label" labelAlignLeft={labelAlignLeft}>
            {label}
          </Label>
        </LabelContainer>
      )}
      <StyledContainer
        showPercentageLabelLeft={showPercentageLabelLeft}
        labelAlignLeft={labelAlignLeft}
      >
        {showPercentageLabel && (
          <PercentageContainer
            thin={thinLine}
            large={largeLine}
            larger={largerLine}
            showPercentageLabelLeft={showPercentageLabelLeft}
          >
            <PercentageLabel data-test="progress-percentage-label">{`${percentageLabelValue}%`}</PercentageLabel>
          </PercentageContainer>
        )}
        <ProgressContainer
          thin={thinLine}
          large={largeLine}
          larger={largerLine}
          labelAlignLeft={labelAlignLeft}
          rounded={rounded}
        >
          <TotalProgress
            percent={percent}
            shouldShowProgress={!data.length}
            animate={animate}
            animated={animated}
            rounded={rounded}
          >
            {data.map(d => renderProgressData(d))}
          </TotalProgress>
        </ProgressContainer>
      </StyledContainer>
    </Container>
  );
};

export default Line;
