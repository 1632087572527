import { useEffect } from "react";
import { Section } from "../../tabs.styles";
import { Button } from "../../../../../components";
import { SectionContainer, StyledInfoSpan } from "../billingTab.styles";
import {
  ButtonContainer,
  DetailsContainer,
  EntriesContainer,
  Entry,
  StyledKey,
  TitleContainer,
} from "../subscriptionDetails/subscriptionDetails.styles";
import { formatCurrency } from "../subscriptionDetails/formatCurrency";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { FREE, BUSINESS } from "../../../../../static/constants/constants";
import { useApp } from "../../../../../context/app/app.provider";
import { CardIcon } from "./cardIcon";
import { useModal } from "../../../../../context/modal/modal.provider";

export const PaymentDetails = ({
  subscriptionDetails,
  getPaymentDetails,
  paymentDetails,
}) => {
  const { t } = useTranslation();
  const { openModal, MODALS } = useModal();
  const { isNotDesktop, isMobile } = useApp();

  const {
    cancel_at_end_of_period,
    next_payment_amount,
    subscription_validity,
    currency_iso_code,
    subscription_id,
  } = subscriptionDetails;
  const { last4, id, brand, exp_month, exp_year } = paymentDetails;

  useEffect(() => {
    getPaymentDetails();
  }, []);

  const getExpirationDate = () => {
    if (subscription_id === FREE) {
      return t("subscriptionInfo.n/a");
    }
    return subscription_validity > 0
      ? DateTime.fromMillis(subscription_validity).toFormat("LLL d, yyyy")
      : null;
  };

  const openEditPaymentModal = () => openModal(MODALS.EDIT_PAYMENT, {});
  const openDeletePaymentModal = paymentId =>
    openModal(MODALS.DELETE_PAYMENT, { paymentId });

  return id || subscription_id === BUSINESS ? (
    <Section>
      <SectionContainer isNotDesktop={isNotDesktop}>
        <DetailsContainer grow={4} isNotDesktop={isNotDesktop}>
          <TitleContainer>
            <p>{t("subscriptionUpdate.paymentMethodTitle")}</p>
          </TitleContainer>
          <EntriesContainer isNotDesktop={isNotDesktop} isMobile={isMobile}>
            <Entry>
              <ButtonContainer>
                <Button onClick={openEditPaymentModal}>{t("btnEdit")}</Button>
                {id && subscription_id === BUSINESS ? (
                  <Button
                    secondary
                    onClick={() => openDeletePaymentModal(paymentDetails.id)}
                  >
                    {t("btnDelete")}
                  </Button>
                ) : null}
              </ButtonContainer>
            </Entry>
            <Entry>
              <StyledKey>{t("subscriptionUpdate.cardType")}</StyledKey>
              <StyledInfoSpan data-test="card-type">
                <CardIcon cardType={brand} />
              </StyledInfoSpan>
            </Entry>
            <Entry>
              <StyledKey>{t("subscriptionUpdate.cardNumber")}</StyledKey>
              <span>
                {last4 ? `XXXX XXXX XXXX ${last4}` : t("subscriptionInfo.n/a")}
              </span>
            </Entry>
            <Entry>
              <StyledKey>{t("subscriptionUpdate.expirationDate")}</StyledKey>
              <span>
                {exp_month && exp_year
                  ? `${exp_month}/${exp_year}`
                  : t("subscriptionInfo.n/a")}
              </span>
            </Entry>
          </EntriesContainer>
        </DetailsContainer>
        <DetailsContainer grow={2} isNotDesktop={isNotDesktop}>
          <TitleContainer>
            {t("subscriptionUpdate.paymentDetails")}
          </TitleContainer>
          <EntriesContainer isNotDesktop={isNotDesktop} isMobile={isMobile}>
            <Entry>
              <StyledKey>{t("subscriptionUpdate.nextPaymentDate")}</StyledKey>
              <span>
                {cancel_at_end_of_period
                  ? t("subscriptionInfo.n/a")
                  : cancel_at_end_of_period !== undefined
                  ? getExpirationDate()
                  : t("subscriptionInfo.n/a")}
              </span>
            </Entry>
            <Entry>
              <StyledKey>{t("subscriptionUpdate.nextPaymentAmount")}</StyledKey>
              <span>
                {next_payment_amount && currency_iso_code
                  ? formatCurrency("en", currency_iso_code, next_payment_amount)
                  : t("subscriptionInfo.n/a")}
              </span>
            </Entry>
          </EntriesContainer>
        </DetailsContainer>
      </SectionContainer>
    </Section>
  ) : null;
};
