import { useTranslation } from "react-i18next";

const FAILED = "Failed";

export const useLighthouseData = data => {
  const { t } = useTranslation();

  const gradientFromScore = (score, dull) => {
    if (score <= 49) {
      return dull ? "url(#dullGradientFail" : "url(#gradientFail)";
    }
    if (score <= 89) {
      return dull ? "url(#dullGradientModerate" : "url(#gradientModerate)";
    } else {
      return dull ? "url(#dullGradientPass" : "url(#gradientPass)";
    }
  };

  const performanceData = [
    {
      name: t("runView.testevolvelighthouse.scores.performance"),
      value: data.performance.score,
      color: gradientFromScore(data.performance.score),
    },
    {
      name: FAILED,
      value: 100 - data.performance.score,
      color: gradientFromScore(data.performance.score, true),
    },
  ];
  const accessibilityData = [
    {
      name: t("runView.testevolvelighthouse.scores.accessibility"),
      value: data.accessibility.score,
      color: gradientFromScore(data.accessibility.score),
    },
    {
      name: FAILED,
      value: 100 - data.accessibility.score,
      color: gradientFromScore(data.accessibility.score, true),
    },
  ];
  const bestPracticesData = [
    {
      name: t("runView.testevolvelighthouse.scores.bestPractices"),
      value: data.best_practices.score,
      color: gradientFromScore(data.best_practices.score),
    },
    {
      name: FAILED,
      value: 100 - data.best_practices.score,
      color: gradientFromScore(data.best_practices.score, true),
    },
  ];
  const seoData = [
    {
      name: t("runView.testevolvelighthouse.scores.seo"),
      value: data.seo.score,
      color: gradientFromScore(data.seo.score),
    },
    {
      name: FAILED,
      value: 100 - data.seo.score,
      color: gradientFromScore(data.seo.score, true),
    },
  ];

  const graphData = {
    performance: performanceData,
    accessibility: accessibilityData,
    bestPractices: bestPracticesData,
    seo: seoData,
  };

  return {
    graphData,
  };
};
