import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../components";
import { useApp } from "../../context/app/app.provider";
import { usePagination } from "./hooks/usePagination";
import { useUnassignedLabels } from "./hooks/useUnassignedLabels";
import { LabelRow } from "./labelRow";
import {
  PageContainer,
  TitleContainer,
  MainContainer,
  InsideContainer,
} from "./unassignedLabels.styles";
import { useModal } from "../../context/modal/modal.provider";
import { NewRunButton } from "./newRunButton";

export const UnassignedLabels = () => {
  const { isNotDesktop } = useApp();
  const scrollRef = useRef(null);
  const { t } = useTranslation();
  const { openModal, MODALS } = useModal();
  const { labels, labelCount, getLabels, loading } = useUnassignedLabels();
  const { PaginationComponent } = usePagination(
    labelCount,
    getLabels,
    scrollRef
  );

  return (
    <PageContainer isNotDesktop={isNotDesktop} ref={scrollRef}>
      <TitleContainer isNotDesktop={isNotDesktop}>
        <InsideContainer>
          <h1>{t("unassignedLabels.title")}</h1>
          <p>{t("unassignedLabels.supportText")}</p>
        </InsideContainer>
        <NewRunButton getLabels={getLabels} />
      </TitleContainer>
      <MainContainer isNotDesktop={isNotDesktop}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {labels?.map(({ label, runs }) => (
              <LabelRow
                key={`row-${label}`}
                label={label}
                runs={runs}
                getLabels={getLabels}
                t={t}
                isNotDesktop={isNotDesktop}
                openAssignLabel={() =>
                  openModal(MODALS.ASSIGN_LABEL, { label, getLabels })
                }
              />
            ))}
            {labels && labelCount ? <PaginationComponent /> : null}
          </>
        )}
      </MainContainer>
    </PageContainer>
  );
};
