import { useTranslation } from "react-i18next";
import {
  StatisticHeading,
  StatisticValue,
  StatisticsCard,
  StatisticsContainer,
} from "../runInsights.styles";
import { routes } from "../../../../../../router/routes";
import { useHistoryWithParams } from "../../../../../../context/hooks/historyWithParams/useHistoryWithParams";

export const LatestStatistics = ({ runInsights, run, nodeDetails }) => {
  const { t } = useTranslation();
  const history = useHistoryWithParams();

  const reportUrl = `${routes.RUN_VIEW_REPORT.path
    .replace(":nodeId", nodeDetails?.id)
    .replace(":runType", run?.category)
    .replace(":label", encodeURI(encodeURIComponent(run?.label)))
    .replace(":runId", run?.id)}`;

  return (
    <StatisticsContainer>
      <StatisticsCard>
        <StatisticHeading>{t("runInsights.newFailures")}</StatisticHeading>
        <StatisticValue>{runInsights.newFailures}</StatisticValue>
      </StatisticsCard>
      <StatisticsCard>
        <StatisticHeading>
          {t("runInsights.recurringFailures")}
        </StatisticHeading>
        <StatisticValue>{runInsights.recurringFailures}</StatisticValue>
      </StatisticsCard>
      <StatisticsCard
        clickable={true}
        onClick={() =>
          history.pushWithHash(`${reportUrl}?attempts=true&status=passed`)
        }
      >
        <StatisticHeading>{t("runInsights.flakyTests")}</StatisticHeading>
        <StatisticValue>{runInsights.flakyTests}</StatisticValue>
      </StatisticsCard>
    </StatisticsContainer>
  );
};
