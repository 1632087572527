import { useEffect, useState } from "react";
import { useFetch } from "../../../../../../context/hooks";
import { ENDPOINTS } from "../../../../../../static/constants/routeConfig";

export const useBillingInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const { get, status, body, error } = useFetch();

  const getBillingInvoices = () => get(ENDPOINTS.BILLING_INVOICE);

  useEffect(() => {
    if (status.isResolved && body) setInvoices(body);
  }, [status.isResolved, body, error]);

  return {
    invoices,
    getBillingInvoices,
    billingInvoicesPending: status.isLoading,
  };
};
