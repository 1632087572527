import { useEffect } from "react";
import { ENDPOINTS } from "../../../../static/constants/routeConfig";
import { useFetch, useNotifications } from "../../../../context/hooks";
import {
  CATEGORY_PROVIDER_MAP,
  CSV_HEADERS,
  EXPORT_FILE_NAME,
} from "../../../../static/constants/constants";
import { useModal } from "../../../../context/modal/modal.provider";
import { convertDateToSQL } from "../../../../utils/dates/dateUtils";

export const useRunExport = () => {
  const { getFile, status, body, reset, error } = useFetch();
  const { createNotification, types } = useNotifications();
  const { openModal, closeModal, MODALS } = useModal();

  const getRunExport = (startDate, endDate, category, label) => {
    const path = ENDPOINTS.DOWNLOAD_RUNS.path
      .replace(":startDate", convertDateToSQL(startDate))
      .replace(":endDate", convertDateToSQL(endDate))
      .replace(":category", category)
      .replace(":provider", CATEGORY_PROVIDER_MAP[category])
      .replace(":label", encodeURI(label));
    getFile(
      { ...ENDPOINTS.DOWNLOAD_RUNS, path: path },
      null,
      CSV_HEADERS,
      EXPORT_FILE_NAME.replace(":label", decodeURI(label))
    );
  };

  useEffect(() => {
    if (status.isResolved) {
      reset();
      createNotification(types.SUCCESS, "runView.export.successMessage");
      closeModal();
    }
    if (status.isRejected && error?.code === 400) {
      createNotification(types.ERROR, "runView.export.400");
    }
    if (status.isRejected && error?.code === 404) {
      createNotification(types.ERROR, "runView.export.404");
    }
  }, [status, body, error]);

  const openExportModal = run => openModal(MODALS.EXPORT_RUN, { run });

  return {
    getRunExport,
    openExportModal,
  };
};
