import { sp } from "../utils/theme/baseLineSpacing";

export const FONT_FAMILY = "'Poppins', sans-serif ";

export const FONT_WEIGHT = {
  THIN: 100,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  MEDIUM_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 900,
};

export const BREAKPOINTS = {
  XS: "420px",
  SM: "576px",
  MD: "768px",
  LG: "992px",
  XL: "1200px",
  MIN_HEIGHT: "560px",
};

export const BASELINE = {
  SP0: sp(0), // 0px
  SP0_125: sp(0.125), // 1px
  SP0_25: sp(0.25), // 2px
  SP0_5: sp(0.5), // 4px
  SP01: sp(1), // 8px
  SP02: sp(2), // 16px
  SP03: sp(3), // 24px
  SP04: sp(4), // 32px
  SP06: sp(6), // 48px
  SP08: sp(8), // 64px
  SP09: sp(9), // 72px
};

export const PALETTE = {
  white: "#ffffff",
  black: "#000000",

  neutrals: {
    normal: "#EEECEC",
    dark: "#595959",
    lightGrey: "#E4E8EB",
    middleGrey: "1D344A",
    skipped_start: "#888888",
    skipped_end: "#666666",
    faded_grey: "#595959",
    faded_darker_grey: "#87939f",
  },

  purples: {
    normal: "#761FFF",
    tint: "#6060ff",
    light: "#9797FC",
    openBackground: "#E2DAFF",
  },

  blues: {
    evolve: "#010D21",
    muted: "#142433",
    smoke: "#3F617D",
    info_dark: "#7db5d3",
    info_light: "#257dac",
    info_start: "#64d8ff",
    info_end: "#39c7fb",
    check_start: "#3f617d",
    check_end: "#395774",
    new_start: "#92D6FF",
    new_end: "#1faaff",
    freeze_light: "#e5eef8",
    freeze_dark: "#406da9",
  },

  greens: {
    evolve: "#16EF91",
    dark: "#008940",
    success: "#2ECC71",
    success_start: "#0cbe6f",
    succes_end: "#07653b",
    50: "#0fe787",
    pass: "#1F883D",
    closedBackground: "#c2f1d6",
    closedForeground: "#09653B",
  },

  reds: {
    critical: "#780000",
    high: "#dc0000",
    error: "#d32d27",
    error_light: "#ce4548",
    error_dark: "#d76669",
    error_start: "#ff6961",
    error_end: "#cb4741",
    critical_start: "#b8413a",
    critical_end: "#be1910",
    50: "#EDC7C2",
  },

  oranges: {
    moderate_start: "#ffbf64",
    moderate_end: "#fbae39",
  },

  yellows: {
    moderate: "#fd8c00",
    low: "#fdc500",
    low_start: "#fff964",
    low_end: "#fbe339",
  },
};
