import React from "react";
import { useTranslation } from "react-i18next";
import { StyledContainer } from "./customerList.styles";
import { ManageCustomerForm } from "./forms/ManageCustomerForm";
import { TitleContainer } from "./forms/manageCustomerForm.styles ";

export const CustomerList = ({
  customerOrganisations,
  subscriptionDetails,
  setUpdated,
}) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <TitleContainer>
        <h1>{t("customer.title.manageOrganisation")}</h1>
      </TitleContainer>
      {customerOrganisations &&
        customerOrganisations.map((customer, key) => (
          <ManageCustomerForm
            first={key === 0}
            key={key}
            index={key}
            customer={customer}
            currencyIsoCode={subscriptionDetails.currency_iso_code}
            setUpdated={setUpdated}
            customers={customerOrganisations}
          />
        ))}
    </StyledContainer>
  );
};
