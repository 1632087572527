import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";

import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { useLexicalEditable } from "@lexical/react/useLexicalEditable";
import { useState } from "react";

import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";

import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";

import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";

import LinkPlugin from "./plugins/LinkPlugin";

import TableCellResizer from "./plugins/TableCellResizer";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ContentEditable from "./ui/ContentEditable";
import {
  Container,
  EditorContainer,
  StyledEditor,
  StyledEditorScroller,
} from "./richTextEditor.styles";

export const Editor = ({ setter, setEditorState, readOnly }) => {
  const tableCellMerge = true;
  const isEditable = useLexicalEditable();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);

  const onRef = _floatingAnchorElem => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const onChange = e => {
    if (!setter || readOnly) return;
    const editorStateJSON = e?.toJSON();
    setEditorState(JSON.stringify(editorStateJSON));
    setter(JSON.stringify(editorStateJSON));
  };

  return (
    <Container id="rte-container">
      {readOnly ? null : (
        <ToolbarPlugin setIsLinkEditMode={setIsLinkEditMode} />
      )}
      <EditorContainer readOnly={readOnly} id="editor-container">
        <OnChangePlugin onChange={onChange} />
        <RichTextPlugin
          contentEditable={
            <StyledEditorScroller>
              <StyledEditor ref={onRef}>
                <ContentEditable />
              </StyledEditor>
            </StyledEditorScroller>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
        <CheckListPlugin />
        <TablePlugin
          hasCellMerge={tableCellMerge}
          hasCellBackgroundColor={false}
        />
        <TableCellResizer />
        <HorizontalRulePlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <ClickableLinkPlugin disabled={isEditable} />
        <HistoryPlugin />
        {floatingAnchorElem && (
          <>
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
            <TableCellActionMenuPlugin
              anchorElem={floatingAnchorElem}
              cellMerge={true}
            />
          </>
        )}
      </EditorContainer>
    </Container>
  );
};
