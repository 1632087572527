import React from "react";
import { t } from "i18next";
import {
  Chevron,
  ScenarioCard,
  ChecksSticker,
  SeveritySticker,
  ScenarioNameData,
} from "./scenario.styles";
import { useHistoryWithParams } from "../../../../../../context/hooks/historyWithParams/useHistoryWithParams";
import { routes } from "../../../../../../router/routes";
import { assets } from "../../../../../../static/assets/assets";
import { useModal } from "../../../../../../context/modal/modal.provider";

export const Scenario = ({
  name,
  checksPerformed,
  critical,
  serious,
  moderate,
  low,
  run,
  nodeId,
  isShared,
}) => {
  const history = useHistoryWithParams();
  const { openModal, MODALS } = useModal();

  return (
    <ScenarioCard
      onClick={() =>
        isShared
          ? openModal(MODALS.SHARE_RUN, { run })
          : history.push(
              `${routes.RUN_VIEW_REPORT.path
                .replace(":nodeId", nodeId)
                .replace(":runType", run.category)
                .replace(":label", run.label)
                .replace(":runId", run.id)}`
            )
      }
    >
      <ScenarioNameData>{name}</ScenarioNameData>
      <td>
        {(checksPerformed || checksPerformed === 0) && (
          <ChecksSticker>
            <h1>{checksPerformed}</h1>
            <p>
              {checksPerformed === 1
                ? t("runView.testevolveaxe.check")
                : t("runView.testevolveaxe.checks")}
            </p>
          </ChecksSticker>
        )}
      </td>
      <td>
        {(critical || critical === 0) && (
          <SeveritySticker>
            <Chevron
              {...assets.icons.navChevron}
              color={({ theme }) => theme.colours.severity.highest}
            />
            <p>{critical}</p>
          </SeveritySticker>
        )}
      </td>
      <td>
        {(serious || serious === 0) && (
          <SeveritySticker>
            <Chevron
              {...assets.icons.navChevron}
              color={({ theme }) => theme.colours.severity.high}
              rotate="45"
            />
            <p>{serious}</p>
          </SeveritySticker>
        )}
      </td>
      <td>
        {(moderate || moderate === 0) && (
          <SeveritySticker>
            <Chevron
              {...assets.icons.navChevron}
              color={({ theme }) => theme.colours.severity.medium}
              rotate="90"
            />
            <p>{moderate}</p>
          </SeveritySticker>
        )}
      </td>
      <td>
        {(low || low === 0) && (
          <SeveritySticker>
            <Chevron
              {...assets.icons.navChevron}
              color={({ theme }) => theme.colours.severity.low}
              rotate="180"
            />
            <p>{low}</p>
          </SeveritySticker>
        )}
      </td>
    </ScenarioCard>
  );
};
